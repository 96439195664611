










































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import Multiselect from "vue-multiselect";
import {required, requiredIf} from "vuelidate/lib/validators";
import {getRolesForRole, Role, RoleDefinition} from '@/ts/permissions/Role';
import {namespace} from "vuex-class";
import User from "@/models/User";
import {Customer} from "@/models/customers/Customer";
import {Validations} from "@/ts/validation-decorator";
import {Attribute, AttributeMap} from "@/ts/permissions/Attribute";

const authNamespace = namespace('auth');

/**
 * Renders a form for capturing the user's role and customer assignment
 */
@Component({
    components: {
        Multiselect
    },
})
export default class UserPermissions extends Vue {
    /**
     * Currently selected user role
     */
    @Prop() userRole?: any;

    /**
     * Currently selected customer
     */
    @Prop({default: null}) customer!: Customer;

    /**
     * List of customers
     */
    @Prop({default: () => []}) customerList!: Customer[];

    /**
     * Map of disabled fields
     */
    @Prop({default: function(){return {}}}) disabledFields!: {[index: string]: boolean};

    /**
     * Whether the card is being used to edit a user
     */
    @Prop({default: false}) editing!: boolean;

    @authNamespace.State('loggedInUser') loggedInUser!: User;

    roles = Role;
    roleDefinitions: RoleDefinition[] = [];
    attributeMap = AttributeMap;
    attribute = Attribute;

    mounted(){
        if(this.editing && this.userRole != null) {
            this.roleDefinitions = this.getEditableRolesForRole(this.loggedInUser.role, this.userRole.id);
        } else {
            this.roleDefinitions = getRolesForRole(this.loggedInUser.role);
        }

        if(this.disabledFields.hasOwnProperty('userRole') && this.disabledFields.userRole) {
          this.roleDefinitions = [this.userRole]
        }

    }

    /**
     * Form field validations
     */
    @Validations()
    validations(){
        const rootVm = this;
        return {
            userRole: {
                required
            },
            customer: {
                requiredIf: requiredIf((vm: UserPermissions) => {
                    return !!(rootVm.userRole != null && AttributeMap[rootVm.userRole.id].includes(Attribute.REQUIRES_CUSTOMER));
                })
            }
        }
    }

    /**
     * Whether the customer field should be shown based on the logged in user's role and
     * the new user's role
     */
    get shouldShowCustomerField(){
        return (this.userRole != null && AttributeMap[this.userRole.id].includes(Attribute.REQUIRES_CUSTOMER)) &&
            !AttributeMap[this.loggedInUser.role].includes(Attribute.REQUIRES_CUSTOMER);
    }

    /**
     * Updates the form field validations when the selected user changes
     */
    @Watch('userRole')
    userRoleChanged(){
        if(this.$v.userRole != undefined){
            this.$v.userRole.$touch();
            this.$emit('invalid', this.$v.$invalid);
        }
    }

    /**
     * Updates the form field validations when the selected customer changes
     */
    @Watch('customer')
    customerChanged(){
        if(this.$v.customer != undefined){
            this.$v.customer.$touch();
            this.$emit('invalid', this.$v.$invalid);
        }

    }

    /**
     * Emits an event when the value of a form field changes
     * @param field
     * @param data
     */
    emitEvent(field: string, data: any){
        this.$emit(field, data);
    }

    /**
     * Returns whether a specific field is disabled
     * @param field
     */
    isFieldDisabled(field: string){
        if(this.disabledFields.hasOwnProperty(field)){
            return this.disabledFields[field];
        }

        return false;
    }

    /**
     * Returns the list of user roles this user can be assigned to when their role is edited
     * @param loggedInRole Logged in user's role
     * @param targetRole Target role for the new user
     */
    getEditableRolesForRole(loggedInRole: string, targetRole: string): RoleDefinition[] {
        const isNewRoleCustomer = AttributeMap[targetRole].includes(Attribute.REQUIRES_CUSTOMER);
        const roles: RoleDefinition[] = getRolesForRole(loggedInRole);
        return roles.filter((role) => AttributeMap[role.id].includes(Attribute.REQUIRES_CUSTOMER) === isNewRoleCustomer && role.id !== Role.SUPER);
    }
}
