import {AsyncNullSuccess, Either, Failure, nullSuccess, Success} from '@/errors/Either';
import {Error} from '@/errors/Error';
import axios from 'axios';
import {MonitorPumpResponse} from '@/models/MARRS/MonitorMode/MonitorModeData';


export default class ConnectMonitorModeService {
    /**
     * Sends the request to start a monitor mode session for a specific pump
     * @param pumpId ID of the pump to start monitoring
     */
    public async startMonitorMode(pumpId: number): AsyncNullSuccess<Error> {
        try {
            // tslint:disable-next-line:max-line-length
            await axios.post(process.env.VUE_APP_BACKEND_URI + '/devices/marrs/' + pumpId + '/monitor-mode');
            return nullSuccess();
        } catch (e) {
            return new Failure({
                message: 'There was an error starting monitor mode',
            });
        }
    }

    /**
     * Sends the request to stop monitoring a specific pump
     * @param pumpId ID of the pump to stop monitoring
     */
    public async stopMonitorMode(pumpId: number): AsyncNullSuccess<Error> {
        try {
            // tslint:disable-next-line:max-line-length
            await axios.delete(process.env.VUE_APP_BACKEND_URI + '/devices/marrs/' + pumpId + '/monitor-mode');
            return nullSuccess();
        } catch (e) {
            return new Failure({
                message: 'There was an error stopping monitor mode',
            });
        }
    }

    /**
     * Sends the request to fetch the latest monitor mode information
     * @param pumpId ID of the pump to fetch
     */
    public async getLatestMonitorRecord(pumpId: number): Promise<Either<MonitorPumpResponse, Error>> {
        try {
            // tslint:disable-next-line:max-line-length
            const {data}: {data: MonitorPumpResponse} = await axios.get(process.env.VUE_APP_BACKEND_URI + '/devices/marrs/' + pumpId + '/monitor-mode');
            return new Success(data);
        } catch (e) {
            return new Failure({
                message: 'There was an error getting the latest record',
            });
        }
    }
}
