











import {Component, Vue} from "vue-property-decorator";
import {namespace} from "vuex-class";
import User from "@/models/User";
import {Permission} from "@/ts/permissions/Permission";
import SideNavData from "@/components/navigation/SideNavData";
import {AuthMutations} from '@/vuex/auth';
import {InactivityActions} from '@/vuex/inactivity';

const auth = namespace('auth');
const oidc = namespace('oidcStore');
const inactivity = namespace('inactivity');

/**
 * Creates the side bar navigation
 */
@Component
export default class SideBarNav extends Vue {

    @auth.State('loggedInUser') loggedInUser!: User;
    @oidc.Action('signOutOidc') signOut!: any;
    @auth.Mutation(AuthMutations.LOG_USER_OUT) clearUser!: () => void;
    @inactivity.Action(InactivityActions.CANCEL_TIMER) cancelInactivityTimer!: () => void;

    /**
     * Creates the nav item for the dashboard
     */
    get dashboard(): SideNavData{
        const vm = this;
        return {
            label: 'Dashboard',
            children: [],
            routeKey: 'dashboard',
            clickHandler: function(e: any){
                vm.$router.push({name: 'dashboard'})
            }
        }
    }

    /**
     * Creates the nav item for the patients list
     */
    get patients(): SideNavData{
        const vm = this;
        return {
            label: 'Patient list',
            children: [],
            routeKey: 'patients',
            clickHandler: function(e: any){
                vm.$router.push({name: 'patient-list'})
            }
        }
    }

    /**
     * Creates the nav item for the admin panel and its children
     */
    get adminPanel(): SideNavData | null{
        if(!this.$isAllowed(Permission.TEAM_LIST) && !this.$isAllowed(Permission.USER_LIST) && !this.$isAllowed(Permission.CUSTOMER_VIEW)){
            return null;
        }


        const vm = this;
        const adminPanel: SideNavData = {
            label: 'Admin Panel',
            children: [],
            childRouteKeys: []
        };

        if(this.$isAllowed(Permission.TEAM_LIST)){
            adminPanel.children.push({
                label: 'Teams',
                children: [],
                routeKey: 'teams',
                clickHandler: function(e: any){
                    vm.$router.push({name: 'team-list'})
                }
            });
            (adminPanel.childRouteKeys as string[]).push('teams');
        }

        if(this.$isAllowed(Permission.USER_LIST)){
            adminPanel.children.push( {
                label: 'User list',
                children: [],
                routeKey: 'users',
                clickHandler: function(e: any){
                    vm.$router.push({name: 'user-list'})
                }
            });
            (adminPanel.childRouteKeys as string[]).push('users');
        }

        if(this.$isAllowed(Permission.CUSTOMER_VIEW)){
            adminPanel.children.push({
                label: 'Customer list',
                children: [],
                routeKey: 'customers',
                clickHandler: function(e: any){
                    vm.$router.push({name: 'customer-list'})
                }
            });
            (adminPanel.childRouteKeys as string[]).push('customers');
        }

        return adminPanel;
    }

    /**
     * Creates the nav item for the devices item and its children
     */
    get devices(): SideNavData {
        const vm = this;
        const children: SideNavData[] = [];
        if(this.$isAllowed(Permission.DEVICE_VIEW)) {
            children.push({
                label: 'Enteral pump list',
                children: [],
                routeKey: 'enteralPumps',
                clickHandler: function(e: any){
                    vm.$router.push({name: 'connect-pump-list'})
                }
            });
        }

        if(this.$isAllowed(Permission.WIRELESS_HUB_VIEW)) {
            children.push({
                label: 'Wireless hub list',
                children: [],
                routeKey: 'hubs',
                clickHandler: function(e: any){
                    vm.$router.push({name: 'hub-list'})
                }
            });
        }
        return {
            label: 'Devices',
            children,
            childRouteKeys: ['enteralPumps', 'hubs']
        }
    }

    /**
     * Creates the nav item for the service panel item
     */
    get servicePanel(): SideNavData{
        return {
            label: 'Service Panel',
            children: [],
            routeKey: 'service',
            clickHandler: (event: any) => {
                this.$router.push({name: 'service-panel'})
            }
        }
    }

    /**
     * Creates the nav item for the profile item
     */
    get profile(): SideNavData {
        return {
            label: 'Profile',
            children: [],
            routeKey: 'profile',
            clickHandler: (event: any) => {
                this.$router.push({name: 'user-profile'})
            }
        }
    }

    /**
     * Creates the nav item for the logout action
     */
    get logout(): SideNavData{
        return {
            label: 'Logout',
            children: [],
            routeKey: 'logout',
            clickHandler: (event: any) => {
                this.cancelInactivityTimer();
                this.signOut();
                //this.clearUser();
            }
        }
    }

    /**
     * The list of nav items visible to the user based on their permissions
     */
    get navItems(){
        const vm = this;

        const navItems = [];
        if(this.$isAllowed(Permission.DASHBOARD)){
            navItems.push(this.dashboard)
        }
        if(this.$isAllowed(Permission.PATIENT_VIEW)) {
            navItems.push(this.patients);
        }

        if(this.adminPanel != null){
            navItems.push(this.adminPanel)
        }

        if(this.$isAllowed(Permission.DEVICE_VIEW) || this.$isAllowed(Permission.WIRELESS_HUB_VIEW)) {
            navItems.push(this.devices);
        }


        if(this.$isAllowed(Permission.SERVICE_TOOLS)) {
            navItems.push(this.servicePanel);
        }

        navItems.push(this.profile, this.logout);

        return navItems;
    }

}
