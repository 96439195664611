





































import {Component, Vue} from 'vue-property-decorator';
import Modal from '../modal/Modal.vue';
import {namespace} from 'vuex-class';
import {Patient, Weight} from '@/models/patients/Patient';
import {NumericalMap} from '@/models/MARRS/MonitorMode/ScreenInfo';
import Table from '@/components/table/Table.vue';
import {TableCellData, TableCellType, TableHeaderData, TableRowData} from '@/components/table/cells/TableCellData';
import FormInput from '@/components/form/FormInput.vue';
import moment, {Moment} from 'moment';
import {convertToMomentTimezone, formatMomentDateOnly} from '@/ts/TimezoneUtils';
import {decimal, minValue, required} from 'vuelidate/lib/validators';
import {ButtonType} from '@/components/form/FormTypes';
import {PatientMutations} from '@/vuex/patients';
import {AuthGetters} from '@/vuex/auth';
import InnerTableDeleteConfirmation from '@/components/table/InnerTableDeleteConfirmation.vue';
import WeightService from '@/services/patients/WeightService';

const patients = namespace('patients');
const auth = namespace('auth');

/**
 * Renders a modal for managing a patient's weight
 */
@Component({
    components: {
        Modal,
        Table
    },
    validations: {
        inProgressDate: {
            required
        },
        inProgressWeight: {
            required,
            decimal,
            minValue: minValue(0)
        }
    }
})
export default class EditWeightHistoryModal extends Vue {
    @patients.State(state => state.homePage.patient) patient!: Patient;
    @patients.Mutation(PatientMutations.ADD_WEIGHT) addWeight!: (weight: Weight) => void;
    @patients.Mutation(PatientMutations.UPDATE_WEIGHT) updateWeight!: (weight: Weight) => void;
    @patients.Mutation(PatientMutations.REMOVE_WEIGHT) removeWeight!: (weightId: number) => void;
    @auth.Getter(AuthGetters.MEASUREMENT_UNIT) measurementUnit!: string;

    /**
     * Current patient weights
     */
    weights: Weight[] = [];

    /**
     * Map for tracking which weight entries are being edited
     */
    editingMap: NumericalMap<boolean> = {};

    /**
     * List of dates that are already in use
     */
    disabledDates: Date[] = [];
    weightService = new WeightService(this.$store.state.auth.loggedInUser.timezone);

    /**
     * Currently selected weight date
     */
    inProgressDate: null | Date = null;

    /**
     * Current weight value
     */
    inProgressWeight: null | number = null;
    buttonType = ButtonType;

    /**
     * Selected weight to be removed
     */
    weightToRemove: Weight | null = null;

    mounted(){
        this.patient.weights.forEach((weight) => {
            this.weights.push({
                ... weight
            });
            this.$set(this.editingMap, weight.id, false);
        });
        this.setTodayIfAvailable();
    }

    /**
     * Sets the new weight date to today if today doesn't have a value
     */
    private setTodayIfAvailable() {
        const disabledDates = this.getDisabledDates(0);
        const today = moment.tz(this.$store.state.auth.loggedInUser.timezone);
        const disabledToday = disabledDates.find((date) => {
            return today.isSame(date, 'day');
        });

        if (disabledToday === undefined) {
            this.inProgressDate = today.toDate();
        } else {
            this.inProgressDate = null;
        }
    }

    /**
     * Updates the value of the in progress date and any validations
     * @param data
     */
    updateInProgressDate(data: any) {
        this.inProgressDate = data;
        if(this.$v.inProgressDate !== undefined) {
            this.$v.inProgressDate.$touch()
        }
    }

    /**
     * Updates the in progress weight value
     * @param inProgressWeight
     */
    updateInProgressWeight(inProgressWeight: number) {
        this.inProgressWeight = inProgressWeight;
        if(this.$v.inProgressWeight != null) {
            this.$v.inProgressWeight.$touch()
        }
    }

    /**
     * Gathers the list of dates that already have weights associated with them
     * Ignores the weight matching the ID passed in
     * @param id
     */
    getDisabledDates(id: number){
        const dates: Date[] = [];
        this.patient.weights.forEach((weight) => {
            if(weight.id !== id){
                dates.push((weight.date as Moment).toDate());
            }
        });
        return dates;
    }

    /**
     * Table header definition
     */
    get tableHeader(): TableHeaderData[] {
        return [
            {
                sortable: false,
                value: "Date",
                widthClass: "w-2/5",
                sortKey: 'firstName'
            },
            {
                sortable: false,
                value: "Weight",
                widthClass: "w-2/5",
            },
            {
                sortable: false,
                value: "Actions",
                widthClass: "w-1/5",
            },
        ]
    }

    /**
     * Transforms an array of weights into an array of table rows
     * If a weight is being edited, this will include a row with editable form controls
     * If a weight is being removed, this will include a confirmation row
     */
    get tableData(): TableRowData[]{
        return this.weights.flatMap((weight) => {
            const cells: TableCellData[] = [];
            const rows: TableRowData[] = [
                {
                    cells,
                    highlighted: false,
                    indexKey: weight.id
                }
            ];
            if(this.editingMap[weight.id]){
                cells.push(
                    {
                        type: TableCellType.SLOT_LIST,
                        primaryValue: '',
                        components: [
                            {
                                primaryValue: FormInput,
                                componentOptions: {
                                    class: 'w-full z-150',
                                    label: 'Date',
                                    type: 'date',
                                    calendarOpts: {
                                        inline: false,
                                        popover: {
                                            placement: 'right',
                                            visibility: 'hover-focus'
                                        },
                                        disabledDates: this.getDisabledDates(weight.id)
                                    },
                                    value: (weight.date as Moment).toDate()
                                },
                                events: {
                                    input: (data: Date) => {
                                        weight.date = moment(data)
                                    }
                                }
                            }
                        ],
                    },
                    {
                        type: TableCellType.SLOT_LIST,
                        primaryValue: '',
                        components: [
                            {
                                primaryValue: FormInput,
                                componentOptions: {
                                    class: 'w-full',
                                    label: 'Weight',
                                    value: weight.weight
                                },
                                events: {
                                    input: (data: number) => {
                                        weight.weight = data
                                    }
                                }
                            }
                        ],
                    },
                    {
                        type: TableCellType.ICON,
                        primaryValue: '',
                        icons: [
                            {
                                name: 'check',
                                tooltip: 'Save changes',
                                action: () => {
                                    this.handleWeightUpdate(weight)
                                }
                            },
                            {
                                name: 'times',
                                tooltip: 'Cancel changes',
                                action: () => {
                                    const originalWeight = this.patient.weights.find((w) => w.id === weight.id);
                                    if(originalWeight !== undefined) {
                                        weight.weight = originalWeight.weight;
                                        weight.date = originalWeight.date;
                                    }
                                    this.editingMap[weight.id] = false;
                                }
                            }
                        ]
                    }
                )
            } else if(this.weightToRemove !== null && weight.id === this.weightToRemove.id) {
                cells.push(
                    {
                        type: TableCellType.NORMAL,
                        primaryValue: formatMomentDateOnly(weight.date as Moment)
                    },
                    {
                        type: TableCellType.NORMAL,
                        primaryValue: weight.weight.toString() + " " + this.measurementUnit
                    },
                    {
                        type: TableCellType.ICON,
                        primaryValue: '',
                        icons: [
                            // {
                            //     name: 'pen',
                            //     action: () => {
                            //         this.editingMap[weight.id] = true;
                            //     }
                            // },
                            // {
                            //     name: 'trash',
                            //     action: () => {
                            //         this.weightToRemove = weight
                            //     }
                            // }
                        ]
                    },
                );
                rows[0].class = 'z-20 bg-white';
                rows.push({
                    highlighted: false,
                    class: 'z-20 shadow-md',
                    cells: [{
                        class: 'w-full px-0 py-0',
                        primaryValue: '',
                        type: TableCellType.SLOT_LIST,
                        components: [
                            {
                                primaryValue: InnerTableDeleteConfirmation,
                                componentOptions: {
                                    mainTextValue: `Delete ${formatMomentDateOnly(this.weightToRemove.date as Moment)} Weight?`,
                                    secondaryTextValue: 'Are you sure you wish to permanently delete this weight entry?'
                                },
                                events: {
                                    delete: () => {
                                        if(this.weightToRemove === null) {
                                            return;
                                        }
                                        this.handleWeightDelete(this.weightToRemove.id)
                                        this.weightToRemove = null;
                                    },
                                    cancel: () => {
                                        this.weightToRemove = null;
                                    }
                                }
                            }
                        ]
                    }]
                })
            } else {
                cells.push(
                    {
                        type: TableCellType.NORMAL,
                        primaryValue: formatMomentDateOnly(weight.date as Moment)
                    },
                    {
                        type: TableCellType.NORMAL,
                        primaryValue: weight.weight.toString() + " " + this.measurementUnit
                    },
                    {
                        type: TableCellType.ICON,
                        primaryValue: '',
                        icons: [
                            {
                                name: 'pen',
                                tooltip: 'Edit weight',
                                action: () => {
                                    this.editingMap[weight.id] = true;
                                }
                            },
                            {
                                name: 'trash',
                                tooltip: 'Delete weight',
                                action: () => {
                                    this.weightToRemove = weight
                                }
                            }
                        ]
                    },
                )
            }
            return rows
        })
    }

    /**
     * Sends the request to create a new weight
     */
    async createWeight(){
        if(this.inProgressDate === null || this.inProgressWeight === null){
            return;
        }

        const response = await this.weightService.createWeight(this.patient.id as number, {
            weight: this.inProgressWeight,
            date: moment.tz(this.inProgressDate, this.$store.state.auth.loggedInUser.timezone)
        });

        if(response.isSuccess()){
            response.map((weight) => {
                weight.date = convertToMomentTimezone(this.$store.state.auth.loggedInUser.timezone, weight.date as string);
                this.addWeight(weight);
                this.setTodayIfAvailable();
                this.inProgressWeight = null;
                this.$v.$reset();
                this.updateWeightArray();
                this.$set(this.editingMap, weight.id, false);
                this.$addSnackbarMessage({
                  message: 'Weight added successfully'
                })
            })
        }
    }

    /**
     * Updates the patients weights
     */
    private updateWeightArray() {
        this.weights = [];
        this.patient.weights.forEach((weight) => {
            this.weights.push({
                ...weight
            });
        });
    }

    /**
     * Sends the request to update a weight
     * @param weight
     */
    async handleWeightUpdate(weight: Weight) {
        const response = await this.weightService.updateWeight(this.patient.id as number, weight);
        if(response.isSuccess()) {
            this.updateWeight(weight);
            this.editingMap[weight.id] = false;
        }
    }

    /**
     * Sends a request to remove a weight
     * @param weightId
     */
    async handleWeightDelete(weightId: number) {
        const response = await this.weightService.deleteWeight(this.patient.id as number, weightId);
        if(response.isSuccess()) {
            this.removeWeight(weightId);
            this.updateWeightArray()
        }
    }
}
