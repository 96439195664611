







import { Component, Vue } from 'vue-property-decorator';
// @ is an alias to /src
import { namespace } from 'vuex-class';
import {Role} from '@/ts/permissions/Role';
import User from '@/models/User';

const authModule = namespace('auth');

@Component
export default class Home extends Vue {
  @authModule.State('loggedInUser') loggedInUser!: User;

  mounted() {
      const dashboardRoles = [Role.ADMIN, Role.CLINICAL_VIEWER, Role.CLINICAL_SUPPORT_VIEWER];
      if(dashboardRoles.includes(<Role> this.loggedInUser.role)){
          return this.$router.push({name: 'dashboard'});
      } else if (this.loggedInUser.role === Role.CUSTOMER_SERVICE || this.loggedInUser.role === Role.MARKETING_SALES) {
          return this.$router.push({name: 'customer-list'});
      }

      return this.$router.push({name: 'connect-pump-list'});
  }
}
