









import { Component, Vue } from 'vue-property-decorator';
import MARRSPumpScreen from '@/components/MARRS/monitor-mode/MARRSPumpScreen.vue';
import {MonitorModeRecord} from "@/models/MARRS/MonitorMode/MonitorModeData";
import {ButtonType} from "@/components/form/FormTypes";
import {namespace} from "vuex-class";
import {Either} from '@/errors/Either';
import Modal from '@/components/modal/Modal.vue';
import MonitorPump from '@/components/devices/marrs/MonitorPump.vue';

const marrs = namespace('marrsPump');

/**
 * Shows the page for performing monitor mode on a specific pump
 */
@Component({
    components: {
       MonitorPump,
    }
})
export default class MonitorPumpPage extends Vue {

    @marrs.State(state => state.homePage.connected) connected!: boolean;
    @marrs.State(state => state.homePage.connectionPending) connectionPending!: boolean;

    get deviceId(){
        return Number.parseInt(this.$route.params.id);
    }
}
