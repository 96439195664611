import {AxiosResponse} from 'axios';
import {Moment} from 'moment';

/**
 * Used to sort an array by a specific object property
 * @param prop Property to sort by
 * @param asc Whether they should be sorted ascending
 */
export function sortBy(prop: string, asc: boolean) {
    return (first: any, second: any) => {
        if (typeof first[prop] === 'string' && typeof second[prop] === 'string') {
            return (asc ? 1 : -1 ) * (first[prop] as string).localeCompare((second[prop] as string));
        }

        if (first[prop] < second[prop]) {
            return asc ? -1 : 1;
        } else if (first[prop] > second[prop]) {
            return asc ? 1 : -1;
        }

        return 0;
    };
}

/**
 * Compares two strings and returns the appropriate number for sorting
 * @param firstProp First string
 * @param secondProp Second string
 * @param asc Whether they should be sorted ascending
 */
function compareStrings(firstProp: string, secondProp: string, asc: boolean) {
    return (asc ? 1 : -1 ) * (firstProp).localeCompare(secondProp);
}

function isMoment(data: any): data is Moment {
    return (data as Moment).diff !== undefined;
}

/**
 * Sorts an array by a custom property
 * This sort performs a nulls last sort
 * @param keyGetter Function that takes in an array element and returns the key for comparison
 * @param asc Whether the sort should be ascending
 */
export function sortByProp<T, K>(keyGetter: (item: K) => T, asc: boolean) {
    return (first: K, second: K) => {
        const firstProp = keyGetter(first);
        const secondProp = keyGetter(second);
        if (firstProp === secondProp) {
            return 0;
        } else if (firstProp === null) {
            return 1;
        } else if (secondProp === null) {
            return -1;
        } else if (typeof firstProp === 'string' && typeof secondProp === 'string') {
            return compareStrings(firstProp, secondProp, asc);
        } else if (typeof firstProp === 'number' && typeof secondProp === 'number') {
            return (asc ? 1 : -1 ) * (firstProp < secondProp ? -1 : 1);
        } else if (isMoment(firstProp) && isMoment(secondProp)) {
            return (asc ? 1 : -1 ) * (firstProp.isBefore(secondProp) ? -1 : 1);
        } else if (asc) {
            return firstProp < secondProp ? -1 : 1;
        } else {
            return firstProp > secondProp ? 1 : -1;
        }
    };
}

/**
 * Groups an array by a property
 * @param list
 * @param keyGetter
 */
export function groupBy(list: any[], keyGetter: (item: any) => any) {
    const map = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
}

/**
 * Sums up an array by a specific value
 * @param list
 * @param valueGetter
 */
export function sumBy(list: any[], valueGetter: (item: any) => number) {
    let sum = 0;
    for (const listItem of list) {
        sum += valueGetter(listItem);
    }
    return sum;
}

/**
 * Saves a downloaded file
 * @param response Response containing the file to download
 * @param defaultFileName Default file name to use if one is not provided in the response
 */
export function saveDownloadedFile(response: AxiosResponse, defaultFileName: string) {
    let fileName = response.headers['content-disposition'] !== undefined ?
        response.headers['content-disposition'].split('=')[1] : defaultFileName;
    fileName = fileName.replace(new RegExp('\"', 'g'), '');
    if (navigator.msSaveBlob) {
        navigator.msSaveBlob(response.data, fileName);
    } else {
        const link = document.createElement('a');
        if (link.download !== undefined) {
            const url = URL.createObjectURL(response.data);
            link.setAttribute('href', url);
            link.setAttribute('download', fileName);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}

export function hasKey<O>(obj: O, key: keyof any): key is keyof O {
    return key in obj;
}

export type NullablePick<T, K extends keyof T> = {
    [P in K]: T[P] | null;
};

export type Optional<T extends object, K extends keyof T = keyof T> = Omit<
    T,
    K
    > &
    Partial<Pick<T, K>>;

export type NullableOptional<T extends object, K extends keyof T = keyof T> = Omit<
    T,
    K
    > &
    NullablePick<T, K>;
