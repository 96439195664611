















    import {Component, Prop, Vue} from "vue-property-decorator";
import Modal from '../modal/Modal.vue';
    import {CustomerDeviceAssociation} from '@/models/customers/CustomerDeviceAssociation';
    import {formatMomentDateOnly} from "@/ts/TimezoneUtils";
    import {Moment} from 'moment';

/**
 * Creates a modal displaying the list of customers who have claimed this device
 * and over which periods of time the customer claimed the device
 */
@Component({
    components: {
        Modal,
    }
})
export default class CustomerDeviceClaimHistoryModal extends Vue {
    /**
     * The list of customer device associations for this specific device
     */
    @Prop() customerClaimHistory!: CustomerDeviceAssociation[];

    /**
     * Formats the start and end dates for a specific customer device association
     * If the association has not ended, Now will be used in place of the end date
     * @param claim Association to format date range of
     */
    formatDateRange(claim: CustomerDeviceAssociation){
        if(claim.endDate === null){
            return formatMomentDateOnly(claim.id.startDate as Moment) + " - Now";
        }

        return formatMomentDateOnly(claim.id.startDate as Moment) + " - " + formatMomentDateOnly(claim.endDate as Moment);
    }

    /**
     * Creates a key to be used in list iteration
     * @param claim Association to create key for
     */
    getKey(claim: CustomerDeviceAssociation){
        return (claim.id.startDate as Moment).valueOf();
    }
}
