






































    import {Component, Vue} from "vue-property-decorator";
    import {ButtonType} from "@/components/form/FormTypes";
    import {TableCellData, TableCellType, TableHeaderData, TableRowData} from '@/components/table/cells/TableCellData';
    import Table from "@/components/table/Table.vue";
    import HubQueueStatus, {QueueState} from "@/models/Devices/Hubs/QueueStatus";
    import axios from "axios";

    @Component({
 components: {
     Table
 }
})
 export default class HubClaim extends Vue {

     ButtonType = ButtonType;
     currentSerialNumber = "";

     pendingRegistrations: HubQueueStatus[] = [];

     timerId: any = null;

     tableHeader: TableHeaderData[] = [
         {
             sortable: false,
             value: "Serial Number",
             widthClass: "w-1/5",
         },
         {
             sortable: false,
             value: "Wireless ID",
             widthClass: "w-1/5"
         },
         {
             sortable: false,
             value: "Connected",
             widthClass: "w-1/5",
         },
         {
             sortable: false,
             value: "",
             widthClass: "w-1/5",
         },
         {
             sortable: false,
             value: "",
             widthClass: "w-1/5",
         }
     ];

     async addToList(){
         try{
             const { data } = await axios.post(process.env.VUE_APP_BACKEND_URI + '/devices/hubs/add-to-queue', {serialNumber: this.currentSerialNumber});
             (data as HubQueueStatus).state = QueueState.WAITING_FOR_CONNECTION;
             this.pendingRegistrations.push(data);
         }catch(e){
             console.log(e);
         }

         if(this.timerId === null){
             this.beginFetchingUpdates();
         }

     }

     beginFetchingUpdates(){
        this.timerId = setInterval(this.fetchUpdates, 20000);
     }

     async fetchUpdates(){
         try{
             const connectionIds = [];
             for(const registrationStatus of this.pendingRegistrations){
                 if(registrationStatus.state != QueueState.REGISTERED){
                     connectionIds.push(registrationStatus.connectionId);
                 }
             }
             const { data } = await axios.post(process.env.VUE_APP_BACKEND_URI + '/devices/hubs/registration-status', connectionIds);
             for(const registrationStatus of this.pendingRegistrations){
                 if(data.hasOwnProperty(registrationStatus.connectionId) && registrationStatus.state != QueueState.REGISTERED){
                     registrationStatus.isConnected = data[registrationStatus.connectionId].isConnected;
                     if(registrationStatus.state != QueueState.ERROR){
                         registrationStatus.state = registrationStatus.isConnected ? QueueState.READY_FOR_REGISTRATION : QueueState.WAITING_FOR_CONNECTION;
                     }

                 }
             }
         }catch(e){
             console.log(e);
         }
     }

     beforeDestroy(){
        if(this.timerId != null){
            clearInterval(this.timerId);
        }
     }

     get tableData(): TableRowData[]{
         return this.pendingRegistrations.map((registration) => {
             let connectionIdParts = registration.connectionId.split("-");
             const cells = [
                 {
                     type: TableCellType.NORMAL,
                     primaryValue: registration.serialNumber
                 },
                 {
                     type: TableCellType.NORMAL,
                     primaryValue: connectionIdParts[2] + "-" + connectionIdParts[3]
                 },
                 {
                     type: TableCellType.NORMAL,
                     primaryValue: registration.isConnected ? "Connected" : "Disconnected"
                 },
                 this.createTableButton(registration),
                 this.createRetryRemoveButton(registration)
             ];

             return {
                 cells,
                 indexKey: registration.serialNumber
             }
         })
     }

     createTableButton(registration: HubQueueStatus): TableCellData{

         switch(registration.state){
             case QueueState.REGISTERED:
                 return {
                     type: TableCellType.NORMAL,
                     primaryValue: "Registered",
                     class: 'text-green-400'
                 };

             case QueueState.ERROR:
                 return {
                     type: TableCellType.NORMAL,
                     primaryValue: registration.error as string,
                     class: 'text-red-400'
                 };

             case QueueState.WAITING_FOR_CONNECTION:
                 return {
                     type: TableCellType.NORMAL,
                     primaryValue: "Waiting for connection",
                 };

             case QueueState.READY_FOR_REGISTRATION:
                 return {
                     type: TableCellType.SLOT,
                     primaryValue: `Button`,
                     secondaryValue: `Register`,
                     componentOptions: {
                         name: "",
                         type: ButtonType.PRIMARY,
                         class: '',
                         events: {
                             click:() => {this.registerDevice(registration.connectionId)}
                         }
                     }
                 };
         }
     }

     createRetryRemoveButton(registration: HubQueueStatus){
         if(registration.state === QueueState.ERROR){
             return {
                 type: TableCellType.SLOT_LIST,
                 primaryValue: ``,
                 secondaryValue: ``,
                 components: [
                     {
                         primaryValue: `Button`,
                         secondaryValue: `Retry`,
                         componentOptions: {
                             name: "",
                             type: ButtonType.PRIMARY,
                             class: 'mr-2',
                         },
                         events: {
                             click:() => {this.registerDevice(registration.connectionId)}
                         }
                     },
                     {
                         primaryValue: `Button`,
                         secondaryValue: ``,
                         componentOptions: {
                             name: "",
                             iconName: 'times',
                             type: ButtonType.PRIMARY,
                             class: '',
                         },
                         events: {
                             click:() => {this.removeFromQueue(registration)}
                         }
                     },
                 ],
             };
         } else {
             return {
                 type: TableCellType.SLOT,
                 primaryValue: `Button`,
                 secondaryValue: ``,
                 componentOptions: {
                     name: "",
                     iconName: 'times',
                     type: ButtonType.PRIMARY,
                     class: '',
                     events: {
                         click:() => {this.removeFromQueue(registration)}
                     }
                 }
             };
         }
     }

     removeFromQueue(registration: HubQueueStatus){
         const index = this.pendingRegistrations.indexOf(registration);
         if(index >= 0){
             this.pendingRegistrations.splice(index, 1);
             if(this.pendingRegistrations.length == 0 && this.timerId != null){
                 clearInterval(this.timerId);
                 this.timerId = null;
             }
         }
     }

     async registerDevice(connectionId: string){
         try {
            const { data } = await axios.post(process.env.VUE_APP_BACKEND_URI + '/devices/hubs/register', {serialNumber: connectionId});
            for(const registrationStatus of this.pendingRegistrations){
                if(registrationStatus.connectionId === connectionId){
                    registrationStatus.state = QueueState.REGISTERED;
                    break;
                }
            }
         }catch(e) {
             for(const registrationStatus of this.pendingRegistrations){
                 if(registrationStatus.connectionId === connectionId){
                     registrationStatus.state = QueueState.ERROR;
                     registrationStatus.error = e.response.data.errors[0];
                     break;
                 }
             }
         }
     }
 }
