







































































import {Component, Prop, Watch} from 'vue-property-decorator';
import {maxLength, required} from 'vuelidate/lib/validators';
import Multiselect from 'vue-multiselect';
import {getRegions} from '@/ts/Region';
import {isUnique} from '@/validations/Validator';
import {mixins} from 'vue-class-component';
import {validationMixin} from 'vuelidate';
import {Validations} from '@/ts/validation-decorator';
import {Permission} from '@/ts/permissions/Permission';

/**
 * Creates a form used to capture some basic information about a customer
 */
 @Component({
     components: {
         Multiselect
     },
 })
 export default class CustomerInfo extends mixins(validationMixin) {
    /**
     * Current value of the customer name
     */
     @Prop() name!: string;

     /**
     * Current value of the customer number
     */
     @Prop() customerNumber!: string;

     /**
     * Current value of the customer region
     */
     @Prop() region!: any;

    /**
     * Current value of the customer name
     */
     @Prop({default: ''}) notes!: string;

     /**
     * Current value of the customer name
     */
     @Prop() customerId?: number;

     regions: any[] = [];

     @Prop({default: false}) editingMode!: boolean;

    /**
     * Defines the validation rules for use in the form
     */
    @Validations()
     validations(){
         return {
             name: {
                 required,
                 maxLength: maxLength(30),
             },
             customerNumber: {
                 required,
                 isUnique: isUnique(process.env.VUE_APP_BACKEND_URI + '/customers/customer-number-check', this.customerId),
                 maxLength: maxLength(30)
             },
             region: {
                 required
             }
         }

     }

    /**
     * Gets the full list of customer regions on component create
     */
    mounted(){
         this.regions = getRegions();
    }

    /**
     * When a field value changes, this function is called to notify the parent with the new value
     * Additionally, the validations for that field are refreshed
     * @param field Name of the field that was updated
     * @param data New value for the field
     */
     emitEvent(field: string, data: string){
         this.$emit(field, data);
         this.touchField(field)
     }

    /**
     * When the currently selected region is updated, this function
     * updates the validations for the region field
     */
    @Watch('region')
     regionChanged(){
         if(this.$v.region != undefined){
             this.$v.region.$touch();
             this.$emit('invalid', this.$v.$invalid);
         }
     }

    /**
     * Updates the value of the the current form is valid by waiting
     * for any async validations to complete
     */
    waitForValidations(){
         return new Promise(resolve => {

             if(!this.$v.$pending){
                 resolve(this.$v.$invalid);
                 return;
             }

             const unwatch = this.$watch(() => !this.$v.$pending, (isNotPending) => {
                 if(isNotPending && unwatch){
                     unwatch();
                     resolve(this.$v.$invalid);
                 }
             })
         })
     }

    /**
     * Updates the form field validations for a specific field
     * This function will wait for any async validations to complete before
     * notifying the parent component
     * @param field Name of the field to re-validate
     */
     async touchField(field: string){
         if((this.$v as Object).hasOwnProperty(field)) {
           this.$nextTick(async () => {
             this.$v[field].$touch();
             const invalid = await this.waitForValidations();
             this.$emit('invalid', invalid);
           })
         }
     }

     get isDisabled() {
         if(this.editingMode) {
             return !this.$isAllowed(Permission.CUSTOMER_EDIT)
         }
         return !this.$isAllowed(Permission.CUSTOMER_CREATE)
     }
 }
