import {NullableOptional} from '@/ts/utilities';
import {FeedPrescription} from '@/models/patients/Patient';
import {AsyncEither, AsyncNullSuccess, Either, Failure, nullSuccess, Success} from '@/errors/Either';
import {Error} from '@/errors/Error';
import {Moment} from 'moment';
import axios from 'axios';
import {convertToMomentTimezone} from '@/ts/TimezoneUtils';


export default class NutritionalPrescriptionService {
    private readonly timezone: string;

    constructor(timezone: string) {
        this.timezone = timezone;
    }

    /**
     * create a new nutritional prescription for a patient
     * @param patientId ID of patient
     * @param prescription Prescription info
     */
    // tslint:disable-next-line:max-line-length
    public async createNewPrescription(patientId: number, prescription: NullableOptional<FeedPrescription>): AsyncEither<FeedPrescription, Error> {
        try {
            if (prescription.startDate !== null) {
                prescription.startDate = (prescription.startDate as Moment).milliseconds(0).utc();
            }
            // tslint:disable-next-line:max-line-length
            const {data}: {data: FeedPrescription} = await axios.post(process.env.VUE_APP_BACKEND_URI + '/patients/' + patientId + '/feed-prescriptions', prescription);
            data.startDate = convertToMomentTimezone(this.timezone, data.startDate as string);
            return new Success(data);
        } catch (e) {
            return new Failure({
                message: 'Unable to create patient notes',
            });
        }
    }

    /**
     * Updates an existing nutritional prescription
     * @param patientId ID of the patient
     * @param prescription Updated prescription info
     */
    // tslint:disable-next-line:max-line-length
    public async updatePrescription(patientId: number, prescription: NullableOptional<FeedPrescription>): AsyncNullSuccess<Error> {
        try {
            if (prescription.startDate !== null) {
                prescription.startDate = (prescription.startDate as Moment).milliseconds(0).utc();
            }
            // tslint:disable-next-line:max-line-length
            await axios.patch(process.env.VUE_APP_BACKEND_URI + '/patients/' + patientId + '/feed-prescriptions/' + prescription.id, prescription);
            return nullSuccess();
        } catch (e) {
            return new Failure({
                message: 'Unable to create patient notes',
            });
        }
    }

    /**
     * Removes a prescription from a patient
     * @param patientId ID of the patient
     * @param prescriptionId ID of the prescription
     */
    public async deletePrescription(patientId: number, prescriptionId: number): AsyncNullSuccess<Error> {
        try {
            // tslint:disable-next-line:max-line-length
            await axios.delete(process.env.VUE_APP_BACKEND_URI + '/patients/' + patientId + '/feed-prescriptions/' + prescriptionId);
            return nullSuccess();
        } catch (e) {
            return new Failure({
                message: 'Unable to delete prescription',
            });
        }
    }
}
