























import {Component, Prop, Vue} from "vue-property-decorator";
import Table from '../../table/Table.vue';
import {TableCellData, TableCellType, TableHeaderData, TableRowData} from '../../table/cells/TableCellData';
import {ConnectionSession, Hub} from "@/models/Devices/Hubs/Hub";
import {HubActions} from '@/vuex/hubs';
import Spinner from '@/components/form/Spinner.vue';

/**
 * Renders a list of hub connections
 */
@Component({
    components: {
        Table,
        Spinner
    }
})
export default class ConnectionHistory extends Vue {

    /**
     * Hub to generate table for
     */
    @Prop({type: Object}) hub!: Hub;

    /**
     * Table header definition
     */
    tableHeader: TableHeaderData[] = [
        {
            sortable: false,
            value: "Connected",
            widthClass: "w-1/2"
        },
        {
            sortable: false,
            value: "Disconnected",
            widthClass: "w-1/2",
        },
    ];

    /**
     * Whether the request to fetch the list of connection sessions is currently pending
     */
    isLoading = true;

    /**
     * Fetches the list of hub connections on component load
     */
    async mounted(){
        await this.$store.dispatch('hubs/' + HubActions.FETCH_CONNECTION_HISTORY, this.hub.id);
        this.isLoading = false;
    }

    /**
     * Transforms the list of hub connections into an array to table rows
     */
    get tableCellData(): TableRowData[] {
        return this.hub.sessions.map((hubSession: ConnectionSession) => {
           const cells = [
               {
                   type: TableCellType.NORMAL,
                   primaryValue: hubSession.connectTime
               },
               {
                   type: TableCellType.NORMAL,
                   primaryValue: hubSession.disconnectTime
               },
           ] as TableCellData[];

            return {
               cells,
                indexKey: hubSession.connectTime + hubSession.disconnectTime
            }
        })
    }
}
