import {Role, RoleNames} from '@/ts/permissions/Role';

export enum Permission {
    DEVICE_VIEW,
    USER_CREATE,
    USER_EDIT,
    USER_VIEW,
    USER_LIST,
    PATIENT_CREATE,
    PATIENT_EDIT,
    PATIENT_VIEW,
    PATIENT_DELETE,
    PATIENT_LIST,
    PRESCRIPTION_CREATE,
    PRESCRIPTION_EDIT,
    PRESCRIPTION_DELETE,
    PRESCRIPTION_VIEW,
    WEIGHT_CREATE,
    WEIGHT_EDIT,
    WEIGHT_DELETE,
    WEIGHT_VIEW,
    ACCESS_GROUP_VIEW,
    CUSTOMER_VIEW,
    CUSTOMER_CREATE,
    CUSTOMER_EDIT,
    CUSTOMER_DELETE,
    ASSIGN_DEVICE_TO_PATIENT,
    UNASSIGN_DEVICE_FROM_PATIENT,
    PATIENT_DEVICE_ASSIGNMENT_DELETE,
    HEIGHT_CREATE,
    HEIGHT_EDIT,
    HEIGHT_DELETE,
    HEIGHT_VIEW,
    GOAL_WEIGHT_CREATE,
    GOAL_WEIGHT_EDIT,
    GOAL_WEIGHT_VIEW,
    NOTES_CREATE,
    NOTES_EDIT,
    NOTES_VIEW,
    PATIENT_FILE_CREATE,
    PATIENT_FILE_REMOVE,
    PATIENT_FILE_VIEW,
    WEIGHT_HISTORY_REPORT,
    PUMP_ERROR_REPORT,
    CERTIFICATION_VIEW,
    FIRMWARE_VERSION_VIEW,
    CERTIFY_DEVICE,
    WIRELESS_DIAGNOSTICS,
    MONITOR_MODE,
    FEED_HISTORY_REPORT,
    PUMP_SETTINGS_REPORT,
    TEAM_CREATE,
    TEAM_EDIT,
    TEAM_REMOVE,
    TEAM_VIEW,
    TEAM_LIST,
    WIRELESS_HUB_VIEW,
    WIRELESS_HUB_LIST,
    WIRELESS_HUB_CLAIM,
    WIRELESS_HUB_UPDATE_DEVICE_DETAILS,
    WIRELESS_HUB_MANAGE_WIRELESS,
    MANAGE_MAINTENANCE_MODE,
    UPDATE_FIRMWARE,
    CANCEL_FIRMWARE_UPDATE,
    DASHBOARD,
    DEVICE_NOTES_VIEW,
    DEVICE_NOTES_EDIT,
    PUMP_DIAGNOSTICS,
    PATIENT_ADHERENCE_REPORT,
    PUMP_REGISTRATION_QUEUE_VIEW,
    PUMP_REGISTRATION_QUEUE_ADD,
    PUMP_REGISTRATION_QUEUE_VIEW_ALL,
    PUMP_REGISTRATION_QUEUE_REMOVE,
    PUMP_REGISTRATION_QUEUE_BYPASS,
    WIRELESS_HUB_CLAIM_BYPASS,
    SERVICE_TOOLS,
}

const PermissionRoleMap = {
    [Role.SUPER]: [
        Permission.DEVICE_VIEW,
        Permission.USER_CREATE,
        Permission.USER_EDIT,
        Permission.USER_VIEW,
        Permission.USER_LIST,
        Permission.CUSTOMER_VIEW,
        Permission.CUSTOMER_CREATE,
        Permission.CUSTOMER_EDIT,
        Permission.CUSTOMER_DELETE,
        Permission.PUMP_ERROR_REPORT,
        Permission.CERTIFICATION_VIEW,
        Permission.FIRMWARE_VERSION_VIEW,
        Permission.CERTIFY_DEVICE,
        Permission.WIRELESS_DIAGNOSTICS,
        Permission.MONITOR_MODE,
        Permission.FEED_HISTORY_REPORT,
        Permission.PUMP_SETTINGS_REPORT,
        Permission.WIRELESS_HUB_VIEW,
        Permission.WIRELESS_HUB_LIST,
        Permission.WIRELESS_HUB_UPDATE_DEVICE_DETAILS,
        Permission.WIRELESS_HUB_MANAGE_WIRELESS,
        Permission.MANAGE_MAINTENANCE_MODE,
        Permission.SERVICE_TOOLS,
        Permission.PUMP_DIAGNOSTICS,
        Permission.PUMP_REGISTRATION_QUEUE_VIEW_ALL,
        Permission.PUMP_REGISTRATION_QUEUE_REMOVE,
        Permission.PUMP_REGISTRATION_QUEUE_BYPASS,
        Permission.PUMP_REGISTRATION_QUEUE_VIEW,
        Permission.WIRELESS_HUB_CLAIM_BYPASS,
        Permission.SERVICE_TOOLS,
    ],
    [Role.MARKETING_SALES]: [
        Permission.USER_CREATE,
        Permission.USER_EDIT,
        Permission.USER_VIEW,
        Permission.USER_LIST,
        Permission.CUSTOMER_VIEW,
        Permission.CUSTOMER_CREATE,
        Permission.CUSTOMER_EDIT,
        Permission.CUSTOMER_DELETE,
    ],
    [Role.CUSTOMER_SERVICE]: [
        Permission.DEVICE_VIEW,
        Permission.USER_CREATE,
        Permission.USER_EDIT,
        Permission.USER_VIEW,
        Permission.USER_LIST,
        Permission.CUSTOMER_VIEW,
        Permission.CUSTOMER_CREATE,
        Permission.WIRELESS_DIAGNOSTICS,
        Permission.MONITOR_MODE,
        Permission.WIRELESS_HUB_VIEW,
        Permission.WIRELESS_HUB_LIST,
        Permission.SERVICE_TOOLS,
        Permission.PUMP_DIAGNOSTICS,
        Permission.PUMP_REGISTRATION_QUEUE_VIEW_ALL,
        Permission.PUMP_REGISTRATION_QUEUE_REMOVE,
        Permission.PUMP_REGISTRATION_QUEUE_BYPASS,
        Permission.PUMP_REGISTRATION_QUEUE_VIEW,
        Permission.WIRELESS_HUB_CLAIM_BYPASS,
        Permission.SERVICE_TOOLS,
        Permission.CERTIFICATION_VIEW,
        Permission.FEED_HISTORY_REPORT,
        Permission.PUMP_SETTINGS_REPORT,
        Permission.PUMP_ERROR_REPORT,
        Permission.WIRELESS_HUB_UPDATE_DEVICE_DETAILS,
        Permission.WIRELESS_HUB_MANAGE_WIRELESS
    ],
    [Role.SERVICE]: [
        Permission.DEVICE_VIEW,
        Permission.PUMP_ERROR_REPORT,
        Permission.CERTIFICATION_VIEW,
        Permission.FIRMWARE_VERSION_VIEW,
        Permission.CERTIFY_DEVICE,
        Permission.WIRELESS_DIAGNOSTICS,
        Permission.WIRELESS_HUB_VIEW,
        Permission.WIRELESS_HUB_LIST,
        Permission.WIRELESS_HUB_UPDATE_DEVICE_DETAILS,
        Permission.WIRELESS_HUB_MANAGE_WIRELESS,
        Permission.SERVICE_TOOLS,
        Permission.PUMP_DIAGNOSTICS,
        Permission.SERVICE_TOOLS,
        Permission.USER_CREATE,
        Permission.USER_EDIT,
        Permission.USER_VIEW,
        Permission.USER_LIST,
    ],
    [Role.ADMIN]: [
        Permission.DEVICE_VIEW,
        Permission.USER_CREATE,
        Permission.USER_EDIT,
        Permission.USER_VIEW,
        Permission.USER_LIST,
        Permission.PATIENT_CREATE,
        Permission.PATIENT_EDIT,
        Permission.PATIENT_VIEW,
        Permission.PATIENT_DELETE,
        Permission.PATIENT_LIST,
        Permission.PRESCRIPTION_CREATE,
        Permission.PRESCRIPTION_EDIT,
        Permission.PRESCRIPTION_DELETE,
        Permission.PRESCRIPTION_VIEW,
        Permission.WEIGHT_CREATE,
        Permission.WEIGHT_EDIT,
        Permission.WEIGHT_DELETE,
        Permission.WEIGHT_VIEW,
        Permission.ASSIGN_DEVICE_TO_PATIENT,
        Permission.UNASSIGN_DEVICE_FROM_PATIENT,
        Permission.PATIENT_DEVICE_ASSIGNMENT_DELETE,
        Permission.HEIGHT_CREATE,
        Permission.HEIGHT_EDIT,
        Permission.HEIGHT_DELETE,
        Permission.HEIGHT_VIEW,
        Permission.GOAL_WEIGHT_CREATE,
        Permission.GOAL_WEIGHT_EDIT,
        Permission.GOAL_WEIGHT_VIEW,
        Permission.NOTES_CREATE,
        Permission.NOTES_EDIT,
        Permission.NOTES_VIEW,
        Permission.PATIENT_FILE_CREATE,
        Permission.PATIENT_FILE_REMOVE,
        Permission.PATIENT_FILE_VIEW,
        Permission.WEIGHT_HISTORY_REPORT,
        Permission.PUMP_ERROR_REPORT,
        Permission.CERTIFICATION_VIEW,
        Permission.FIRMWARE_VERSION_VIEW,
        Permission.CERTIFY_DEVICE,
        Permission.WIRELESS_DIAGNOSTICS,
        Permission.MONITOR_MODE,
        Permission.FEED_HISTORY_REPORT,
        Permission.PUMP_SETTINGS_REPORT,
        Permission.TEAM_CREATE,
        Permission.TEAM_EDIT,
        Permission.TEAM_VIEW,
        Permission.TEAM_LIST,
        Permission.TEAM_REMOVE,
        Permission.WIRELESS_HUB_VIEW,
        Permission.WIRELESS_HUB_LIST,
        Permission.WIRELESS_HUB_MANAGE_WIRELESS,
        Permission.WIRELESS_HUB_CLAIM,
        Permission.DASHBOARD,
        Permission.DEVICE_NOTES_VIEW,
        Permission.DEVICE_NOTES_EDIT,
        Permission.PUMP_REGISTRATION_QUEUE_VIEW,
        Permission.PUMP_REGISTRATION_QUEUE_REMOVE,
        Permission.PUMP_REGISTRATION_QUEUE_ADD,
    ],
    [Role.CLINICAL_VIEWER]: [
        Permission.DEVICE_VIEW,
        Permission.USER_CREATE,
        Permission.PATIENT_CREATE,
        Permission.PATIENT_EDIT,
        Permission.PATIENT_VIEW,
        Permission.PATIENT_DELETE,
        Permission.PATIENT_LIST,
        Permission.PRESCRIPTION_CREATE,
        Permission.PRESCRIPTION_EDIT,
        Permission.PRESCRIPTION_DELETE,
        Permission.PRESCRIPTION_VIEW,
        Permission.WEIGHT_CREATE,
        Permission.WEIGHT_EDIT,
        Permission.WEIGHT_DELETE,
        Permission.WEIGHT_VIEW,
        Permission.ASSIGN_DEVICE_TO_PATIENT,
        Permission.UNASSIGN_DEVICE_FROM_PATIENT,
        Permission.PATIENT_DEVICE_ASSIGNMENT_DELETE,
        Permission.HEIGHT_CREATE,
        Permission.HEIGHT_EDIT,
        Permission.HEIGHT_DELETE,
        Permission.HEIGHT_VIEW,
        Permission.GOAL_WEIGHT_CREATE,
        Permission.GOAL_WEIGHT_EDIT,
        Permission.GOAL_WEIGHT_VIEW,
        Permission.NOTES_CREATE,
        Permission.NOTES_EDIT,
        Permission.NOTES_VIEW,
        Permission.PATIENT_FILE_CREATE,
        Permission.PATIENT_FILE_REMOVE,
        Permission.PATIENT_FILE_VIEW,
        Permission.WEIGHT_HISTORY_REPORT,
        Permission.PUMP_ERROR_REPORT,
        Permission.CERTIFICATION_VIEW,
        Permission.FIRMWARE_VERSION_VIEW,
        Permission.CERTIFY_DEVICE,
        Permission.WIRELESS_DIAGNOSTICS,
        Permission.MONITOR_MODE,
        Permission.FEED_HISTORY_REPORT,
        Permission.PUMP_SETTINGS_REPORT,
        Permission.WIRELESS_HUB_VIEW,
        Permission.WIRELESS_HUB_LIST,
        Permission.WIRELESS_HUB_MANAGE_WIRELESS,
        Permission.WIRELESS_HUB_CLAIM,
        Permission.DASHBOARD,
        Permission.DEVICE_NOTES_VIEW,
        Permission.DEVICE_NOTES_EDIT,
        Permission.PUMP_REGISTRATION_QUEUE_VIEW,
        Permission.PUMP_REGISTRATION_QUEUE_REMOVE,
        Permission.PUMP_REGISTRATION_QUEUE_ADD,
    ],
    [Role.CLINICAL_SUPPORT_VIEWER]: [
        Permission.PATIENT_VIEW,
        Permission.PATIENT_LIST,
        Permission.PRESCRIPTION_VIEW,
        Permission.WEIGHT_VIEW,
        Permission.GOAL_WEIGHT_VIEW,
        Permission.NOTES_VIEW,
        Permission.PATIENT_ADHERENCE_REPORT,
        Permission.WEIGHT_HISTORY_REPORT,
        Permission.FEED_HISTORY_REPORT,
        Permission.PUMP_SETTINGS_REPORT,
        Permission.DASHBOARD,
        Permission.DEVICE_VIEW,
        Permission.CERTIFICATION_VIEW,
        Permission.FIRMWARE_VERSION_VIEW,
        Permission.WIRELESS_DIAGNOSTICS,
        Permission.MONITOR_MODE,
        Permission.DEVICE_NOTES_VIEW,
        Permission.PUMP_ERROR_REPORT,
        Permission.WIRELESS_HUB_LIST,
        Permission.WIRELESS_HUB_VIEW,
        Permission.PATIENT_FILE_VIEW,
    ],
    [Role.BIOMED_VIEWER]: [
        Permission.DEVICE_VIEW,
        Permission.PUMP_SETTINGS_REPORT,
        Permission.CERTIFICATION_VIEW,
        Permission.FIRMWARE_VERSION_VIEW,
        Permission.CERTIFY_DEVICE,
        Permission.WIRELESS_DIAGNOSTICS,
        Permission.WIRELESS_HUB_VIEW,
        Permission.WIRELESS_HUB_LIST,
        Permission.WIRELESS_HUB_MANAGE_WIRELESS,
        Permission.WIRELESS_HUB_CLAIM,
        Permission.PUMP_REGISTRATION_QUEUE_VIEW,
        Permission.PUMP_REGISTRATION_QUEUE_REMOVE,
        Permission.PUMP_REGISTRATION_QUEUE_ADD,
        Permission.MONITOR_MODE,
        Permission.DEVICE_NOTES_VIEW,
        Permission.DEVICE_NOTES_EDIT,
        Permission.FEED_HISTORY_REPORT,
        Permission.PUMP_ERROR_REPORT
    ],
} as {[index: string]: Permission[]};

export { PermissionRoleMap };
