import {AsyncNullSuccess, Either, Failure, Success} from '@/errors/Either';
import {Error} from '@/errors/Error';
import {UserFilter} from '@/models/User';
import axios from 'axios';
import {saveDownloadedFile} from '@/ts/utilities';


export default class UserService {
    private timezone: string;


    constructor(timezone: string) {
        this.timezone = timezone;
    }

    /**
     * Exports the list of users to an excel document
     * @param userFilter Filters to apply
     */
    public async exportList(userFilter: UserFilter): AsyncNullSuccess<Error> {
        try {
            const response = await axios.post(process.env.VUE_APP_BACKEND_URI + '/users/export', userFilter, {
                responseType: 'blob',
            });
            saveDownloadedFile(response, 'user-list-export.xls');
            return new Success(null);
        } catch (e) {
            return new Failure({
                message: 'Unable to export user list',
            });
        }
    }

    /**
     * Updates the last login time for a user
     */
    public async updateLastLogin(): AsyncNullSuccess<Error> {
        try {
            await axios.patch(process.env.VUE_APP_BACKEND_URI + '/users/last-login');
            return new Success(null);
        } catch (e) {
            return new Failure({
                message: 'Unable to update las login',
            });
        }
    }

    /**
     * Removes a user
     * @param userId ID of the user
     */
    public async removeUser(userId: number): AsyncNullSuccess<Error> {
        try {
            await axios.delete(process.env.VUE_APP_BACKEND_URI + '/users/' + userId);
            return new Success(null);
        } catch (e) {
            return new Failure({
                message: 'Unable to remove user',
            });
        }
    }
}
