














import {Component, Prop, Vue} from 'vue-property-decorator';
import Table from '../../table/Table.vue';
import moment, {Moment} from 'moment';
import {Patient, PatientDeviceAssignment} from '@/models/patients/Patient';
import {MARRSPump} from '@/models/Devices/MARRS/MARRSPump';
import PatientDeviceAssignmentService from '../../../services/patients/PatientDeviceAssignmentService';
import {TableCellData, TableCellType, TableRowData} from '../../table/cells/TableCellData';
import FormInput from '../../form/FormInput.vue';
import Checkbox from '../../form/Checkbox.vue';
import {formatMomentDateOnly} from '@/ts/TimezoneUtils';
import {Optional} from '@/ts/utilities';
import {CustomerDeviceAssociation} from '@/models/customers/CustomerDeviceAssociation';
import InnerTableDeleteConfirmation from '@/components/table/InnerTableDeleteConfirmation.vue';

interface DateOpt {
    start: Date | null;
    end: Date | null;
}

/**
 * Renders a table to edit assignments from a device
 */
@Component({
    components: {
        Table,
    }
})
export default class EditAssignmentTableFromDevice extends Vue {
    /**
     * ID of the assignment being edited
     */
    assignmentBeingEditedId = 0;

    /**
     * Start/end date of the assignment being edited
     */
    editingAssignmentOpt: DateOpt | null = null;

    /**
     * Start date of the assignment being edited
     */
    editingAssignmentDate: Moment | null = null;

    /**
     * Patient of the assignment being edited
     */
    editingPatient: Patient | null = null;

    /**
     * Assignment being removed
     */
    assignmentBeingRemoved: PatientDeviceAssignment | null = null;

    /**
     * Whether an end date is being set for the assignment
     */
    endingAssignment = false;

    /**
     * List of current assignments for that device
     */
    patientCurrentAssignments: PatientDeviceAssignment[] = [];

    /**
     * Pump assignments are for
     */
    @Prop() pump!: MARRSPump;

    /**
     * Array of available patients
     */
    @Prop() patients!: Patient[];

    /**
     * Array of customer device claims
     */
    @Prop() claims!: CustomerDeviceAssociation[];
    today = moment.tz(this.$store.state.auth.loggedInUser.timezone).startOf('day');

    assignmentService = new PatientDeviceAssignmentService(this.$store.state.auth.loggedInUser.timezone);

    mounted() {
        if(this.pump.assignments !== undefined && this.pump.assignments.length > 0) {
            this.patientCurrentAssignments.push(...this.pump.assignments)
        }
    }

    /**
     * Determines the date ranges to disable based on prior assignments
     */
    getDisabledDates(assignmentId: number) {
        const dates: DateOpt[] = [];
        this.patientCurrentAssignments.forEach((assignment) => {
            if(assignment.id !== assignmentId) {
                dates.push(this.processAssignment(assignment))
            }
        });

        if(this.assignmentsFromPatients.length > 0) {
            this.assignmentsFromPatients.forEach((assignment) => {
                if(assignment.id !== assignmentId) {
                    dates.push(this.processAssignment(assignment));
                }
            })
        }
        return dates;
    }

    /**
     * Converts the start/end date of assignments into a date range object
     * @param assignment Assignment to convert
     */
    processAssignment(assignment: PatientDeviceAssignment) {
        if(assignment.endDate !== null){
            return {
                start: (assignment.startDate as Moment).clone().toDate(),
                end: (assignment.endDate as Moment).clone().toDate()
            };
        } else {
            return {
                start: (assignment.startDate as Moment).clone().toDate(),
                end: this.today.clone().toDate()
            }
        }
    }

    /**
     * Returns the existing assignments for a given patient
     */
    get assignmentsFromPatients() {
        if(this.editingPatient === null) {
            return [];
        }

        const patient = this.patients.find((patients) => patients.id === (this.editingPatient as Patient).id);
        if(patient !== undefined) {
            return patient.assignments;
        }

        return [];
    }

    /**
     * Table header definition
     */
    get tableHeader() {
        return [
            {
                sortable: false,
                value: "Patient",
                widthClass: "w-2/5",
            },
            {
                sortable: false,
                value: "Assignment Range",
                widthClass: "w-2/5",
            },
            {
                sortable: false,
                value: "Actions",
                widthClass: "w-1/5",
            },
        ]
    }

    /**
     * Converts the array of assignments into an array of table rows
     * Creates specific rows based on whether the assignment is being edited or removed
     */
    get tableData(): TableRowData[] {
        if(this.pump.assignments === undefined) {
            return [];
        }
        return this.pump.assignments.flatMap((assignment) => {
            const cells: TableCellData[] = [];

            if(assignment.id === this.assignmentBeingEditedId) {
                return this.createEditingTableRow(assignment);
            } else if (this.assignmentBeingRemoved !== null && assignment.id === this.assignmentBeingRemoved.id) {
                return this.createRemovingTableRow(assignment);
            } else {
                return this.createNormalTableRow(assignment, true);
            }

        })
    }

    /**
     * Generates the table row data for a normal row (not editing)
     * @param assignment
     */
    createNormalTableRow(assignment: PatientDeviceAssignment, showIcons: boolean): TableRowData[] {
        if(assignment.patient === undefined) {
            return [];
        }
        const cells = [
            {
                type: TableCellType.NORMAL,
                primaryValue: assignment.patient.firstName + " " + assignment.patient.lastName,
                class: 'w-2/5',
            },
            {
                type: TableCellType.NORMAL,
                primaryValue: this.createDateRangeString(assignment),
                class: 'w-2/5',
            },
            {
                type: TableCellType.ICON,
                primaryValue: '',
                class: 'w-1/5',
                icons: showIcons ? [
                    {
                        name: 'pen',
                        tooltip: 'Edit assignment',
                        action: () => {
                            this.assignmentBeingEditedId = assignment.id;
                            this.editingPatient = assignment.patient as Patient;
                            this.endingAssignment = false;
                            if (assignment.endDate !== null) {
                                this.editingAssignmentOpt = {
                                    start: (assignment.startDate as Moment).toDate(),
                                    end: (assignment.endDate as Moment).toDate()
                                };
                                this.endingAssignment = true;
                            } else {
                                this.editingAssignmentDate = assignment.startDate as Moment;
                            }
                        }
                    },
                    {
                        name: 'trash',
                        tooltip: 'Delete assignment',
                        action: () => {
                            this.assignmentBeingRemoved = assignment;
                            this.editingPatient = assignment.patient as Patient;
                        }
                    }
                ] : []
            },
        ]

        return [{
            cells,
            indexKey: assignment.id
        }]
    }

    /**
     * Generates the table row for an assignment being edited
     * @param assignment
     */
    createEditingTableRow(assignment: PatientDeviceAssignment): TableRowData[] {
        const cells: TableCellData[] = [];
        if(assignment.patient === undefined) {
            return [];
        }


        const currentClaim = this.claims.find((claim) => claim.endDate === null);
        if(currentClaim === undefined) {
            return [];
        }
        let minDate = (currentClaim.id.startDate as Moment).toDate();

        const dateSelectorComponents: any[] = [
            {
                primaryValue: FormInput,
                componentOptions: {
                    class: 'w-full z-150',
                    label: (assignment.endDate !== null || this.endingAssignment) ? 'Assignment Date Range' : 'Assignment Start Date',
                    type: 'date',
                    calendarOpts: {
                        inline: false,
                        popover: {
                            placement: 'left',
                            visibility: 'hover-focus'
                        },
                        mode: (assignment.endDate !== null || this.endingAssignment) ? 'range' : 'single',
                        disabledDates: [...this.getDisabledDates(assignment.id)],
                        minDate
                    },
                    value: (assignment.endDate !== null || this.endingAssignment) ? this.editingAssignmentOpt : this.editingAssignmentDate?.toDate()
                },
                events: {
                    input: (data: DateOpt | Date) => {
                        if((assignment.endDate !== null || this.endingAssignment) && !this.isDate(data)) {
                            this.editingAssignmentOpt = data;
                        } else {
                            this.editingAssignmentDate = moment.tz(data as Date, this.$store.state.auth.loggedInUser.timezone);
                        }
                    }
                }
            }
        ];

        if(assignment.endDate === null) {
            dateSelectorComponents.push({
                primaryValue: Checkbox,
                componentOptions: {
                    label: 'End Assignment',
                    checked: this.endingAssignment
                },
                events: {
                    change: (data: boolean) => {
                        this.endingAssignment = data;
                        if (data) {
                            this.editingAssignmentOpt = {
                                start: (assignment.startDate as Moment).toDate(),
                                end: this.today.clone().toDate()
                            };
                        } else {
                            this.editingAssignmentDate = (assignment.startDate as Moment);
                        }
                    }
                }
            })
        }

        cells.push(
            {
                type: TableCellType.NORMAL,
                primaryValue: assignment.patient.firstName + " " + assignment.patient.lastName,
                class: 'w-2/5',
            },
            {
                type: TableCellType.SLOT_LIST,
                primaryValue: '',
                class: 'w-2/5',
                components: dateSelectorComponents
            },
            {
                type: TableCellType.ICON,
                primaryValue: '',
                class: 'w-1/5',
                icons: [
                    {
                        name: 'check',
                        tooltip: 'Save changes',
                        action: () => {
                            this.updateAssignment();
                        }
                    },
                    {
                        name: 'times',
                        tooltip: 'Cancel changes',
                        action: () => {
                            this.editingAssignmentOpt = null;
                            this.editingAssignmentDate = null;
                            this.assignmentBeingEditedId = 0;
                            this.editingPatient = null;
                            this.endingAssignment = false;
                        }
                    }
                ]
            },
        );

        return [{
            cells
        }];
    }

    /**
     * Creates the table row for removing an assignment
     * @param assignment Assignment to create table row for
     */
    createRemovingTableRow(assignment: PatientDeviceAssignment): TableRowData[] {
        if(assignment.patient === undefined) {
            return [];
        }
        const rows: TableRowData[] = []
        const normalRow = this.createNormalTableRow(assignment, false)[0]
        normalRow.class = 'z-20 bg-white'

        rows.push(normalRow)
        if(this.assignmentBeingRemoved === null) {
            return rows;
        }

        rows.push({
            highlighted: false,
            class: 'z-20 shadow-md',
            cells: [{
                class: 'w-full px-0 py-0',
                primaryValue: '',
                type: TableCellType.SLOT_LIST,
                components: [
                    {
                        primaryValue: InnerTableDeleteConfirmation,
                        componentOptions: {
                            mainTextValue: `Delete ${this.assignmentBeingRemoved.patient?.firstName} ${this.assignmentBeingRemoved.patient?.lastName} Assignment?`,
                            secondaryTextValue: 'Are you sure you wish to permanently delete this assignment entry?'
                        },
                        events: {
                            delete: () => {
                                if(this.assignmentBeingRemoved === null) {
                                    return;
                                }
                                this.deleteAssignment()
                            },
                            cancel: () => {
                                this.assignmentBeingRemoved = null;
                            }
                        }
                    }
                ]
            }]
        })
        return rows
    }

    /**
     * Converts the start/end date of an assignment into a string describing the date range
     * @param assignment Assignment to create string for
     */
    createDateRangeString(assignment: PatientDeviceAssignment) {
        if(assignment.endDate === null) {
            return formatMomentDateOnly(assignment.startDate as Moment) + " - Now";
        } else {
            return formatMomentDateOnly(assignment.startDate as Moment) + " - " + formatMomentDateOnly(assignment.endDate as Moment);
        }
    }

    /**
     * Sends the request to delete an assignment
     */
    async deleteAssignment() {
        if(this.assignmentBeingRemoved === null) {
            return;
        }

        const response = await this.assignmentService.deleteAssignment((this.editingPatient as Patient).id as number, this.assignmentBeingRemoved.id);
        if(response.isSuccess()){
            this.$emit('removeAssignment', this.assignmentBeingRemoved.id)
            this.$addSnackbarMessage({
                message: 'Assignment successfully removed',
            })
        } else {
            this.$addSnackbarMessage({
                message: 'There was an issue removing the assignment. Try again later or contact customer support.',
            });
        }
        this.assignmentBeingRemoved = null;
        this.editingPatient = null;
    }

    /**
     * Sends the request to update an assignment
     */
    async updateAssignment() {
        if((this.endingAssignment && this.editingAssignmentOpt === null) || (!this.endingAssignment && this.editingAssignmentDate === null)){
            return;
        }
        const timezone = this.$store.state.auth.loggedInUser.timezone;
        const assignment: Optional<PatientDeviceAssignment> = {
            id: this.assignmentBeingEditedId,
            startDate: moment.tz(this.endingAssignment ? (this.editingAssignmentOpt as DateOpt).start : this.editingAssignmentDate?.toDate(), timezone).startOf('day'),
            endDate: this.endingAssignment ? moment.tz((this.editingAssignmentOpt as DateOpt).end, timezone).endOf('day') : null
        };

        const response = await this.assignmentService.updateAssignment((this.editingPatient as Patient).id as number, assignment);
        if(response.isSuccess()){
            this.$emit('updateAssignment', assignment);
            this.assignmentBeingEditedId = -1;
            this.$addSnackbarMessage({
                message: 'Assignment successfully updated',
            })
        } else {
            this.$addSnackbarMessage({
                message: 'There was an issue updating the assignment. Try again later or contact customer support.',
            });
        }
    }

    /**
     * Type guard to determine if the passed in object is a Date
     */
    isDate(data: DateOpt | Date): data is Date {
        return (data as Date).getDate !== undefined;
    }
}
