import {Module} from 'vuex';
import {RootState} from '@/vuex/rootState';

const numMinutes = 28;
const numMillisecondsInMinute = 60000;

export interface InactivityState {
    inactivityTimerId: number | null;
    modalShowing: boolean;
}

export enum InactivityMutations {
    SET_TIMER_ID = 'setTimerId',
    UPDATE_MODAL_STATUS = 'updateModalStatus',
}

export enum InactivityActions {
    EXTEND_TIMER = 'extendTimer',
    CANCEL_TIMER = 'cancelTimer',
}

const inactivity: Module<InactivityState, RootState> = {
    namespaced: true,
    state: {
        inactivityTimerId: null,
        modalShowing: false,
    },
    mutations: {
        [InactivityMutations.SET_TIMER_ID](state, timerId: number | null) {
            state.inactivityTimerId = timerId;
        },
        [InactivityMutations.UPDATE_MODAL_STATUS](state, status: boolean) {
            state.modalShowing = status;
        },
    },
    actions: {
        [InactivityActions.EXTEND_TIMER](context) {
          if (context.state.inactivityTimerId !== null) {
              clearTimeout(context.state.inactivityTimerId);
          }
          const timerId = setTimeout(() => {
              context.commit(InactivityMutations.UPDATE_MODAL_STATUS, true);
          }, numMinutes * numMillisecondsInMinute);
          context.commit(InactivityMutations.SET_TIMER_ID, timerId);
        },
        [InactivityActions.CANCEL_TIMER](context) {
            if (context.state.inactivityTimerId !== null) {
                clearTimeout(context.state.inactivityTimerId);
                context.commit(InactivityMutations.SET_TIMER_ID, null);
            }
        },
    },
};

export default inactivity;
