





























































































import {Component, Vue} from "vue-property-decorator";
import BubbleLink from "@/components/navigation/BubbleLink.vue";
import PumpCertificationTable from "@/components/devices/marrs/PumpCertificationTable.vue";
import {PaginatedData} from "@/components/table/cells/TableCellData";
import {MARRSPumpWithDetails} from "@/models/Devices/MARRS/MARRSPump";
import axios from "axios";
import {convertToMomentTimezone} from "@/ts/TimezoneUtils";
import {namespace} from "vuex-class";
import User from "@/models/User";
import {Searchable} from "@/models/Searchable";
import PumpListTable from "@/components/devices/marrs/PumpListTable.vue";
import {PatientAssignmentStatus} from "@/models/patients/PatientAssignmentStatus";
import {CertificationFilter} from "@/models/Devices/MARRS/Certification";
import moment from "moment-timezone";
import PumpFirmwareDownloadTable from '@/components/devices/marrs/PumpFirmwareDownloadTable.vue';
import {FilterData} from "@/ts/Filter";
import {saveDownloadedFile} from "@/ts/utilities";
import FirmwareFile from "@/models/Devices/MARRS/FirmwareFile";
import HelpModal from '@/components/help/HelpModal.vue';
import {ButtonType} from '@/components/form/FormTypes';

const auth = namespace('auth');

enum SelectedView {
    PUMP_LIST,
    CERTIFICATIONS,
    SOFTWARE_DOWNLOAD
}

/**
 * Renders the list of pumps the user has access to
 */
@Component({
    components: {
        BubbleLink,
        PumpCertificationTable,
        PumpListTable,
        PumpFirmwareDownloadTable,
        HelpModal,
    },
    data: () =>  {
        return {
            dataLoading: false
        }
    }
})
export default class PumpListPage extends Vue {

    selectedViews = SelectedView.PUMP_LIST;
    viewEnum = SelectedView;


    /**
     * Whether the table is currently sorted ascending
     */
    currentSortedAsc = false;

    /**
     * Field the table is currently sorted by
     */
    currentSortKey = 'lastConnection';

    eventBus = new Vue();

    @auth.State('loggedInUser') loggedInUser!: User;

    /**
     * Current pump list data
     */
    pumpListData: PaginatedData<MARRSPumpWithDetails> = {totalElements: 0, totalPages: 0, data: []};

    /**
     * Cache of pages of pump data
     */
    pageCache: {[index: number]: MARRSPumpWithDetails[]} = {};

    certResultFilterPageData: MARRSPumpWithDetails[] = [];

    /**
     * Whether the request to fetch the list of firmware versions is pending
     */
    loadingVersions = true;

    /**
     * List of firmware versions for filtering
     */
    versionData: FilterData[] = [];

    pageSize = 25;

    /**
     * Whether the top list is showing
     */
    topListShowing = false;

    /**
     * Current search string value
     */
    searchString = "";
    buttonTypes = ButtonType;

    /**
     * Current field that is being searched
     */
    searchKey = Searchable.SERIAL_NUMBER;
    filterData = {
        patient: PatientAssignmentStatus.ASSIGNED_AND_UNASSIGNED,
        certificationStatus: CertificationFilter.PASS_AND_FAIL,
        certificationStart: null as null | Date,
        certificationEnd: null as null | Date,
        connectionStart: null as null | Date,
        connectionEnd: null as null | Date,
        swVersions: [] as number[],
        uiVersions: [] as number[],
        fwSteps: [] as number[],
        fwStatus: [] as number[],
        showOnlyCertErrors: false,
    };

    /**
     * List of available firmware files
     */
    firmwareFileListing: FirmwareFile[] = [];

    async mounted(){
        await Promise.all([
            this.fetchPage(1),
            this.fetchVersionData(),
            // this.fetchAvailableFirmwareFiles()
        ]);
        (this as any).dataLoading = true;
    }

    /**
     * Resets the page cache
     */
    clearPageCache(){
        this.$set(this, 'pageCache', {});
    }

    /**
     * Navigates to a specific page of data
     * @param index
     */
    handlePageChange(index: number){
        this.fetchPage(index);
    }

    /**
     * Updates the table sort and fetches the first page of data
     * @param message
     */
    handleSort(message: any){
        this.clearPageCache();
        this.currentSortKey = message.sortKey;
        this.currentSortedAsc = message.sortedAsc;
        this.fetchPage(1);
    }

    handlePageSizeChanged(newSize: number){
        if(newSize != this.pageSize){
            this.clearPageCache();
            this.pageSize = newSize;
            this.fetchPage(1);
        }
    }

    /**
     * Updates the table search info and fetches the first page of data
     * @param search
     * @param key
     */
    handleSearch({search, key}: {search: string, key: number}){
        this.clearPageCache();
        this.searchString = search;
        this.searchKey = key;
        this.fetchPage(1);
    }

    /**
     * Sends the request to fetch a page of data
     * @param page
     */
    async fetchPage(page: number){
        if(this.pageCache === undefined ) {
            return;
        }
        const {timezone, request} = this.createPumpListRequest(page);

        if(this.pageCache.hasOwnProperty(page)){
            await new Promise(function(resolve) {setTimeout(resolve, 1000)});
            this.pumpListData.data = this.pageCache[page];
            this.eventBus.$emit('dataLoaded');
            return;
        }

        try {
            const {data}: {data: PaginatedData<MARRSPumpWithDetails>} = await axios.post(process.env.VUE_APP_BACKEND_URI + '/devices/marrs', request,{
                responseType: 'json'
            });
            data.data.forEach(pumpWithDetails => {
                if(pumpWithDetails.latestCertification != null && pumpWithDetails.latestCertification.timestamp != null){
                    pumpWithDetails.latestCertification.timestamp = convertToMomentTimezone(timezone, pumpWithDetails.latestCertification.timestamp as string)
                }

                if(pumpWithDetails.firmwareInfo != null && pumpWithDetails.firmwareInfo.lastUpgrade != null){
                    pumpWithDetails.firmwareInfo.lastUpgrade = convertToMomentTimezone(timezone, pumpWithDetails.firmwareInfo.lastUpgrade as string)
                }

                if(pumpWithDetails.pump != null){
                    pumpWithDetails.pump.lastConnectionTime =  convertToMomentTimezone(timezone, pumpWithDetails.pump.lastConnectionTime as string);
                    pumpWithDetails.pump.firstUse =  convertToMomentTimezone(timezone, pumpWithDetails.pump.firstUse as string);
                    pumpWithDetails.pump.registrationTime =  convertToMomentTimezone(timezone, pumpWithDetails.pump.registrationTime as string)
                }
            });

            this.pumpListData.data = data.data;
            this.pumpListData.totalElements = data.totalElements;
            this.pumpListData.totalPages = data.totalPages;
            this.$set(this.pageCache, page, data.data);
        }catch(e){
            console.log(e);
        }

        page == 1 ? this.eventBus.$emit('dataReset') : this.eventBus.$emit('dataLoaded');
    }

    /**
     * Creates the request to fetch pump data based on current filters
     * @param page
     */
    private createPumpListRequest(page: number) {
        if(this.filterData === undefined) {
            return {timezone: '', request: null};
        }
        const timezone = this.loggedInUser.timezone as string;

        const request: any = {
            pageSize: this.pageSize,
            page: page - 1,
            sortKey: this.currentSortKey,
            sortedAsc: this.currentSortedAsc,
            certificationFilter: this.filterData.certificationStatus,
            uiVersions: null,
            swVersions: null,
            fwSteps: null,
            fwStatus: null,
            patientFilter: null
        };

        if (this.searchString != "") {
            request.search = this.searchString;
            request.searchable = this.searchKey;
        }

        if(this.filterData.patient !== null) {
            request.patientFilter = this.filterData.patient
        }

        if (this.filterData.connectionStart != null) {
            request.lastConnectionStart = moment(this.filterData.connectionStart as Date).toISOString();
            request.lastConnectionEnd = moment(this.filterData.connectionEnd as Date).hour(23).minute(59).second(59).toISOString();
        }

        if (this.filterData.certificationStart != null) {
            request.lastCertificationStart = moment(this.filterData.certificationStart as Date).toISOString();
            request.lastCertificationEnd = moment(this.filterData.certificationEnd as Date).hour(23).minute(59).second(59).toISOString();
        }

        if (this.filterData.uiVersions != null) {
            request.uiVersions = [...this.filterData.uiVersions];
        }

        if (this.filterData.swVersions != null) {
            request.swVersions = [...this.filterData.swVersions];
        }

        if (this.filterData.fwSteps.length > 0) {
            request.fwSteps = [...this.filterData.fwSteps];
        }

        if (this.filterData.fwStatus.length > 0) {
            request.fwStatus = [...this.filterData.fwStatus];
        }
        return {timezone, request};
    }

    /**
     * Updates the table currently being shown
     * @param value
     */
    async switchToView(value: SelectedView){
        this.selectedViews = value;
        await this.fetchPage(1);
    }

    get selectedViewTitle(){
        switch (this.selectedViews) {
            case SelectedView.PUMP_LIST:
                return "Pump List";
            case SelectedView.SOFTWARE_DOWNLOAD:
                return "Download Pump Software";
            case SelectedView.CERTIFICATIONS:
                return "Certify Pumps"
        }
    }

    /**
     * Fetches the first page of data on fitler change
     */
    handleFilter(){
        this.clearPageCache();
        this.fetchPage(1);
    }

    /**
     * Handles a filter on the patient column
     * @param data
     */
    handlePatientFilter(data: any){
        if(data.status.length == 1){
            this.filterData.patient = data.status[0];
        }else{
            this.filterData.patient = PatientAssignmentStatus.ASSIGNED_AND_UNASSIGNED;
        }
        this.handleFilter();
    }

    /**
     * Handles a filter on the last connection column
     * @param data
     */
    handleConnectionFilter(data: any){
        if(data.dateRange){
            this.filterData.connectionStart = data.dateRange.start;
            this.filterData.connectionEnd = data.dateRange.end;
        }
        this.handleFilter();
    }

    /**
     * Handles a filter on the certification column
     * @param data
     */
    handleCertificationFilter(data: any){
        if(data.result.length == 1){
            this.filterData.certificationStatus = data.result[0];
        }else{
            this.filterData.certificationStatus = CertificationFilter.PASS_AND_FAIL;
        }

        if(data.dateRange){
            this.filterData.certificationStart = data.dateRange.start;
            this.filterData.certificationEnd = data.dateRange.end;
        }
        this.handleFilter();
    }

    /**
     * Handles a filter on the version column
     * @param data
     */
    handleVersionFilter(data: any){
        console.log(data);
        if(data.swVersion){
            this.filterData.swVersions = [...data.swVersion];
        }

        if(data.uiVersion){
            this.filterData.uiVersions = [...data.uiVersion];
        }


        this.handleFilter();
    }

    /**
     * Handles a filter on the firmware download column
     * @param data
     */
    handleDownloadStatusFilter(data: any){
        console.log(data);
        if(data.step){
            this.filterData.fwSteps = [...data.step];
        }

        if(data.status){
            this.filterData.fwStatus = [...data.status];
        }

        this.handleFilter();
    }

    /**
     * Resets all of the filters to their default values
     */
    resetFilters(){
        this.filterData.patient = PatientAssignmentStatus.ASSIGNED_AND_UNASSIGNED;
        this.filterData.certificationStatus = CertificationFilter.PASS_AND_FAIL;
        this.filterData.certificationStart = null;
        this.filterData.certificationEnd = null;
        this.filterData.connectionStart = null;
        this.filterData.connectionEnd = null;
        this.filterData.uiVersions = [];
        this.filterData.swVersions = [];
        this.filterData.fwStatus = [];
        this.filterData.fwSteps = [];
        this.eventBus.$emit('asyncDataLoading');
        this.handleFilter();
    }

    /**
     * Updates a pump's latest certification after certifications are performed
     * @param data
     */
    handleCertResult(data: any){
        const timezone = this.loggedInUser.timezone as string;
        for(const key of Object.keys(data)){
            if(data.hasOwnProperty(key) && data[key].passed != null){
                const result = data[key];
                const pump = this.allPumps.find(pump => pump.pump.id === Number(key));
                if(pump != undefined){
                    pump.latestCertification.timestamp = convertToMomentTimezone(timezone, result.timestamp as string);
                    pump.latestCertification.id = result.certificationId;
                    pump.latestCertification.certificationResult = result.passed;
                }
            }
        }
    }

    /**
     * Returns an array of all pumps from all fetched pages
     */
    get allPumps(){
        const data: MARRSPumpWithDetails[] = [];
        for(const pageKey in this.pageCache){
            if(this.pageCache.hasOwnProperty(pageKey)){
                data.push(...this.pageCache[pageKey]);
            }
        }

        return data;
    }

    /**
     * Sends the request to fetch the available firmware versions
     * todo: convert to service
     */
    async fetchVersionData(){
        try {
            const {data}: {data: number[]} = await axios.get(process.env.VUE_APP_BACKEND_URI + "/devices/marrs/versions");
            data.forEach(num => {
                this.versionData.push({
                    value: num,
                    label: num.toFixed(2),
                    key: num.toFixed(2)
                });
            });
            this.versionData.sort((a, b): number => {
                let aVal: number = a.value, bVal: number = b.value;

                if(aVal < bVal){
                    return -1;
                }else if(aVal > bVal){
                    return 1
                }

                return 0;
            });
            this.loadingVersions = false;
        }catch(e){
            console.warn(e);
        }
    }

    /**
     * Wrapper around exporting the list of pumps based on which view is currently active
     */
    async exportList(){
        this.topListShowing = false;
        if(this.selectedViews === SelectedView.PUMP_LIST){
            await this.exportPumpList();
        }else if(this.selectedViews === SelectedView.CERTIFICATIONS){
            await this.exportPumpCertificationList();
        }else if(this.selectedViews === SelectedView.SOFTWARE_DOWNLOAD){
            await this.exportPumpFirmwareList();
        }


    }

    /**
     * Sends the request to export the base list of pumps
     * todo: convert to service
     */
    async exportPumpList(){
        const {timezone, request} = this.createPumpListRequest(1);
        try{
            this.$addSnackbarMessage({
                message: 'Pump list export is in progress.',
            });
            const response = await axios.post(process.env.VUE_APP_BACKEND_URI + '/devices/marrs/list/export', request,{
                responseType: 'blob'
            });
            saveDownloadedFile(response, 'pump-list-export.xlsx');
        }catch(e){
            this.$addSnackbarMessage({
                message: 'There was an issue exporting the list of devices. Try again later or contact customer support.',
            });
        }
    }

    /**
     * Sends the request to export the certification table of pumps
     * todo: convert to service
     */
    async exportPumpCertificationList(){
        const {timezone, request} = this.createPumpListRequest(1);
        if(this.filterData.showOnlyCertErrors){
            request.devices = this.certResultFilterPageData.map(pump => pump.pump.id);
        }
        try{
            this.$addSnackbarMessage({
                message: 'Pump list export is in progress.',
            });
            const response = await axios.post(process.env.VUE_APP_BACKEND_URI + '/devices/marrs/certification/export', request,{
                responseType: 'blob'
            });
            saveDownloadedFile(response, 'pump-certification-list-export.xlsx');
        }catch(e){
            this.$addSnackbarMessage({
                message: 'There was an issue exporting the list of devices. Try again later or contact customer support.',
            });
        }
    }

    /**
     * Sends the request to export the firmware download table of pumps
     * todo: convert to service
     */
    async exportPumpFirmwareList(){
        const {timezone, request} = this.createPumpListRequest(1);
        try{
            const response = await axios.post(process.env.VUE_APP_BACKEND_URI + '/devices/marrs/firmware/export', request,{
                responseType: 'blob'
            });
            saveDownloadedFile(response, 'pump-software-list-export.xlsx');
        }catch(e){
            this.$addSnackbarMessage({
                message: 'There was an issue exporting the list of devices. Try again later or contact customer support.',
            });
        }
    }
}
