import {Optional} from '@/ts/utilities';
import {GoalWeight} from '@/models/patients/Patient';
import {AsyncEither, AsyncNullSuccess, Either, Failure, Success} from '@/errors/Either';
import {Error} from '@/errors/Error';
import axios from 'axios';
import {convertToMomentTimezone} from '@/ts/TimezoneUtils';


export default class GoalWeightService {
    private readonly timezone: string;

    constructor(timezone: string) {
        this.timezone = timezone;
    }

    /**
     * Creates a new goal weight for a patient
     * NOTE: This is only used if a patient has no goal weight. If the have one @see {@link updateGoalWeight}
     * @param patientId ID of the patient
     * @param goalWeight goal weight info
     */
    // tslint:disable-next-line:max-line-length
    public async createGoalWeight(patientId: number, goalWeight: Optional<GoalWeight>): AsyncEither<GoalWeight, Error> {
        try {
            // tslint:disable-next-line:max-line-length
            const {data}: {data: GoalWeight} = await axios.post(process.env.VUE_APP_BACKEND_URI + '/patients/' + patientId + '/goal-weights', goalWeight);
            data.modifiedDate = convertToMomentTimezone(this.timezone, data.modifiedDate as string);
            return new Success(data);
        } catch (e) {
            return new Failure({
                message: 'Unable to create goal weight',
            });
        }
    }

    /**
     * Updates existing goal weight information
     * @param patientId ID of the patient to update
     * @param goalWeight Updated goal weight info
     */
    public async updateGoalWeight(patientId: number, goalWeight: GoalWeight): AsyncNullSuccess<Error> {
        try {
            // tslint:disable-next-line:max-line-length
            await axios.patch(process.env.VUE_APP_BACKEND_URI + '/patients/' + patientId + '/goal-weights/' + goalWeight.id, goalWeight);
            return new Success(null);
        } catch (e) {
            return new Failure({
                message: 'Unable to update goal weight',
            });
        }
    }
}
