import {MARRSPump} from '@/models/Devices/MARRS/MARRSPump';
import {Error} from '@/errors/Error';
import {Either, Failure, Success} from '@/errors/Either';
import axios from 'axios';
import {convertToMomentTimezone} from '@/ts/TimezoneUtils';
import {
    AssignableDeviceDto,
    CustomerPumpRegistrationViewResponse,
    PumpRegistrationQueueEntry, PumpRegistrationResponse
} from '@/models/Devices/Device';
import {Moment} from 'moment';

export class DeviceRegistrationService {
    timezone: string;

    constructor(timezone: string) {
        this.timezone = timezone;
    }

    /**
     * Fetches a list of devices that can be assigned to a patient
     */
    public async getAssignableDevices(): Promise<Either<AssignableDeviceDto[], Error>> {
        try {
            // tslint:disable-next-line:max-line-length
            const {data}: {data: AssignableDeviceDto[]} = await axios.get(process.env.VUE_APP_BACKEND_URI + '/devices/marrs/assignable');


            data.forEach((assignableDevice) => {
                // tslint:disable-next-line:max-line-length
                if (assignableDevice.device.assignments !== undefined  && assignableDevice.device.assignments.length > 0) {
                    assignableDevice.device.assignments.forEach((assignment) => {
                        assignment.startDate = convertToMomentTimezone(this.timezone, assignment.startDate as string);
                        if (assignment.endDate !== null) {
                            assignment.endDate = convertToMomentTimezone(this.timezone, assignment.endDate as string);
                        }
                    });
                    assignableDevice.device.assignments.sort((a, b) => {
                        return (b.startDate as Moment).diff(a.startDate as Moment);
                    });
                }

                // tslint:disable-next-line:max-line-length
                if (assignableDevice.device.customerDeviceAssociations !== undefined && assignableDevice.device.customerDeviceAssociations !== null && assignableDevice.device.customerDeviceAssociations.length > 0) {
                    assignableDevice.device.customerDeviceAssociations.forEach((claim) => {
                        claim.id.startDate = convertToMomentTimezone(this.timezone, claim.id.startDate as string);
                        if (claim.endDate !== null) {
                            claim.endDate = convertToMomentTimezone(this.timezone, claim.endDate as string);
                        }
                    });
                }

                // tslint:disable-next-line:max-line-length
                assignableDevice.earliestAssignableDate = convertToMomentTimezone(this.timezone, assignableDevice.earliestAssignableDate as string);
                assignableDevice.serialNumber = assignableDevice.device.serialNumber;
                assignableDevice.id = assignableDevice.device.id;
            });


            return new Success(data);
        } catch (e) {
            console.log(e);
            return new Failure({
                message: 'Failed to get device list',
            });
        }
    }

    /**
     * Fetches the list of pumps that are pending registration for the user's customer
     */
    public async getQueuedDevicesForCustomer(): Promise<Either<PumpRegistrationQueueEntry[], Error>> {
        try {
            // tslint:disable-next-line:max-line-length
            const {data}: {data: CustomerPumpRegistrationViewResponse} = await axios.get(process.env.VUE_APP_BACKEND_URI + '/devices/registration');
            const entries = data.unRegisteredDevices;
            entries.forEach((entry) => {
                entry.addedAt = convertToMomentTimezone(this.timezone, entry.addedAt as string);
                // tslint:disable-next-line:max-line-length
                entry.device.lastConnectionTime = convertToMomentTimezone(this.timezone, entry.device.lastConnectionTime as string);
            });

            return new Success(entries);
        } catch (e) {
            return new Failure({
                message: 'Unable to get queued devices',
            });
        }
    }

    /**
     * Fetches the list of all queued devices
     */
    public async getQueuedDevicesForAllCustomers(): Promise<Either<PumpRegistrationQueueEntry[], Error>> {
        try {
            // tslint:disable-next-line:max-line-length
            const {data}: {data: PumpRegistrationQueueEntry[]} = await axios.get(process.env.VUE_APP_BACKEND_URI + '/devices/registration/all');
            const entries = data;
            entries.forEach((entry) => {
                entry.addedAt = convertToMomentTimezone(this.timezone, entry.addedAt as string);
                // tslint:disable-next-line:max-line-length
                entry.device.lastConnectionTime = convertToMomentTimezone(this.timezone, entry.device.lastConnectionTime as string);
            });

            return new Success(entries);
        } catch (e) {
            return new Failure({
                message: 'Unable to get queued devices',
            });
        }
    }

    /**
     * Sends the request to add a pump to the user's customer device queue
     * @param serialNumber
     */
    public async queuePump(serialNumber: string): Promise<Either<PumpRegistrationResponse, Error>> {
        try {
            // tslint:disable-next-line:max-line-length
            const {data}: {data: PumpRegistrationResponse} = await axios.post(process.env.VUE_APP_BACKEND_URI + '/devices/registration', {
                serialNumber,
            });
            return new Success(data);
        } catch (e) {
            return new Failure({
                message: 'Unable to queue pump',
                statusCode: e.response.status,
            });
        }
    }

    /**
     * Assigns a device to a customer using the non-customer bypass
     * @param customerId ID of the to assign the device to
     * @param serialNumber Serial number of the device
     */
    public async addDeviceToCustomerWithBypass(customerId: number, serialNumber: string): Promise<Either<null, Error>> {
        try {
            await axios.post(process.env.VUE_APP_BACKEND_URI + '/devices/registration/' + customerId + '/bypass', {
                serialNumber,
            });
            return new Success(null);
        } catch (e) {
            return new Failure({
                message: 'Unable to add device to customer',
            });
        }
    }

    /**
     * Removes a device from the queue
     * @param deviceId
     */
    public async removeDeviceFromQueue(deviceId: number): Promise<Either<null, Error>> {
        try {
            await axios.delete(process.env.VUE_APP_BACKEND_URI + '/devices/registration/' + deviceId);
            return new Success(null);
        } catch (e) {
            return new Failure({
                message: 'Unable to remove device from queue',
            });
        }
    }
}
