






































    import {Component, Vue} from 'vue-property-decorator';
    import {TableCellData, TableCellType, TableHeaderData, TableRowData} from '@/components/table/cells/TableCellData';
    import Table from '@/components/table/Table.vue';
    import Filter, {FilterData, FilterType, SearchData, TableFilterData} from '@/ts/Filter';
    import {namespace} from 'vuex-class';
    import User from '@/models/User';
    import {UserActions, UserGetters, UserMutations} from '@/vuex/users';
    import {ButtonType} from '@/components/form/FormTypes';
    import {RawLocation, Route} from 'vue-router';
    import store from '@/store';
    import {AssignableToTeams, getRolesForRole, RoleDefinition, RoleNames} from '@/ts/permissions/Role';
    import AssignedTeamsChipDisplay from '@/components/teams/AssignedTeamsChipDisplay.vue';
    import UserService from '@/services/UserService';
    import {Searchable} from '@/models/Searchable';
    import TextDisplay from '@/components/utility/TextDisplay.vue';
    import HelpModal from '@/components/help/HelpModal.vue';

    const users = namespace('users');
const auth = namespace('auth');

enum TabOptions {
    ALL_USERS,
    TEAM,
    ALL_PATIENTS,
    NO_PATIENTS
}

enum TeamGroups {
    ALL_PATIENTS = 0,
    TEAM = 1,
    NO_PATIENTS = 2,
    N_A = 3
}
Component.registerHooks([
    'beforeRouteEnter',
    'beforeRouteLeave',
    'beforeRouteUpdate'
]);
@Component({
    components: {
        Table: Table,
        HelpModal,
    }
})
export default class UserList extends Vue {

    async beforeRouteEnter(to: Route, from: Route, next: ((to?: RawLocation | false | ((vm: UserList) => any) | void) => void)){
        await store.dispatch('users/'+UserActions.GET_USER_LIST);
        next();
    }

    async beforeRouteUpdate(to: Route, from: Route, next: ((to?: RawLocation | false | ((vm: UserList) => any) | void) => void)){
        await store.dispatch('users/'+UserActions.GET_USER_LIST);
        next();
    }

    searchString = {
        key: Searchable.PATIENT_NAME,
        search: ""
    };
    currentSortKey = 'lastName';
    sortedAsc = true;
    currentFilter: Filter | null = null;
    currentTab = TabOptions.ALL_USERS;
    tabOptionsEnum = TabOptions;
    buttonTypes = ButtonType;
    activeRoleFilters: any[] = [];
    teamGroupFilters: TeamGroups[] = [];
    teamsFilterList: number[] = [];
    topListShowing = false;
    userService = new UserService(this.$store.state.auth.loggedInUser.timezone);

    @users.State('list') userList!: User[];
    @users.Getter(UserGetters.FTILERED_SORTED_LIST) getSortedList: any;
    @users.Mutation(UserMutations.SET_EDIT_USER) setEditUser: any;
    @auth.State('loggedInUser') loggedInUser!: User;

    async exportList() {
        this.topListShowing = false;
        this.$addSnackbarMessage({
            message: 'User list export in progress',
        });
        const response = await this.userService.exportList({
            sortKey: this.currentSortKey,
            sortedAsc: this.sortedAsc,
            roles: this.activeRoleFilters,
            teams: this.teamsFilterList,
            search: this.searchString.search,
            searchable: this.searchString.key,
            teamGroupFilters: this.teamGroupFilters
        });

        if(response.isSuccess()) {
            this.$addSnackbarMessage({
                message: 'User list successfully exported'
            });
        } else {
            this.$addSnackbarMessage({
                message: 'There was an issue exporting the list of users. Try again later or contact customer support.',
            });
        }
    }

    get searchTypes(): SearchData[] {
        return [
            {
                key: Searchable.PATIENT_NAME,
                value: 'Name'
            },
            {
                key: Searchable.USERNAME,
                value: 'Username'
            },
            {
                key: Searchable.EMAIL,
                value: 'Email'
            }
        ]
    }

    filterUsersBySearch(user: User){
        const currentSearch = this.searchString.search.toLowerCase();

        if(this.searchString.key === Searchable.PATIENT_NAME) {
            return (user.firstName != undefined && user.firstName.toLowerCase().includes(currentSearch)) ||
                (user.lastName != undefined && user.lastName.toLowerCase().includes(currentSearch)) ||
                ((user.firstName !== undefined && user.lastName !== undefined) && (user.firstName.toLowerCase() + " " + user.lastName.toLowerCase()).includes(currentSearch));
        } else if (this.searchString.key === Searchable.USERNAME) {
            return user.username.toLowerCase().includes(currentSearch);
        } else {
            return (user.email != undefined && user.email.toLowerCase().includes(currentSearch))
        }
    }

    get tableHeader(): TableHeaderData[]{
        return [
            {
                sortable: true,
                value: "Name",
                widthClass: "w-3/10",
                sortKey: 'lastName'
            },
            {
                sortable: true,
                value: "Username",
                widthClass: "w-1/5",
                sortKey: 'username'
            },
            {
                sortable: false,
                value: "Role",
                widthClass: "w-1/5",
                filterable: true,
                filterKey: 'role',
                filters: [
                    this.filterableRoles
                ]
            },
            {
                sortable: false,
                value: "Assigned team(s)",
                widthClass: "w-3/10",
                filterable: true,
                filterKey: 'teamFilter',
                filters: this.teamsFilter
            }
        ];
    }

    get tableCellData(): TableRowData[]{
        const vm = this;
        return this.getSortedList(this.currentSortKey, this.sortedAsc, this.currentFilterFunc)
            .filter((user: User) => this.activeRoleFilters.length == 0 || this.activeRoleFilters.includes(user.role))
            .filter(this.filterUsersByTeam)
            .filter(this.filterUsersBySearch)
            .map(function(user: User): TableRowData {
            const cells = [
                    {
                        type: TableCellType.SLOT_LIST,
                        primaryValue: '',
                        components: [
                            {
                                primaryValue: TextDisplay,
                                componentOptions: {
                                    value: user.lastName + ", " + user.firstName,
                                    valueClickable: true
                                },
                                events: {
                                    valueClick: () => {
                                        vm.$router.push({name: 'user-edit', params: {id: (user.id as number).toString()}})
                                    }
                                }
                            },
                            {
                                primaryValue: TextDisplay,
                                componentOptions: {
                                    value: user.email,
                                    valueClickable: true,
                                    class: 'text-sm'
                                },
                                events: {
                                    valueClick: () => {
                                        vm.$router.push({name: 'user-edit', params: {id: (user.id as number).toString()}})
                                    }
                                }
                            }
                        ]
                    },
                {
                    type: TableCellType.SLOT_LIST,
                    primaryValue: '',
                    components: [
                        {
                            primaryValue: TextDisplay,
                            componentOptions: {
                                value: user.username,
                                valueClickable: true
                            },
                            events: {
                                valueClick: () => {
                                    vm.$router.push({name: 'user-edit', params: {id: (user.id as number).toString()}})
                                }
                            }
                        }
                    ]
                },
                    {
                        type: TableCellType.NORMAL,
                        primaryValue: RoleNames[user.role]
                    },
                    {
                        type: TableCellType.SLOT_LIST,
                        primaryValue:"",
                        components: [
                            {
                                primaryValue: AssignedTeamsChipDisplay,
                                componentOptions: {
                                    key: user.id,
                                    teams: user.teams,
                                    role: user.role
                                }
                            }
                        ]
                    }
                ] as TableCellData[];

            return {
                cells,
                indexKey: user.id
            }
        })
    }

    sortData(message: any){
        this.currentSortKey = message.sortKey;
        this.sortedAsc = message.sortedAsc;
    }

    get filterableRoles(): TableFilterData{
        const roles = getRolesForRole(this.loggedInUser.role) as RoleDefinition[];
        const rolesFilter: FilterData[] = roles.map(role => {
            return {
                value: role.id,
                key: role.id,
                label: role.display
            }
        });

        return {
            type: FilterType.MULTISELECT,
            key: 'role',
            active: this.activeRoleFilters.length > 0,
            enabled: true,
            displayText: 'Filter by User Role',
            data: {
                multiselect: true,
                activeItems: {
                    role: [...this.activeRoleFilters]
                },
                items: rolesFilter
            }
        }

    }

    get teamsFilter(): TableFilterData[] {

        let teamsFilter: FilterData[] = this.userList
            .filter(user => user.teams.length > 0)
            .filter(user => user.teams.find((team) => team.type == 1) != undefined)
            .flatMap((user) => user.teams)
            .map((team) => {
                return {
                    value: team.id,
                    key: team.id,
                    label: team.name
                }
            });

        teamsFilter = teamsFilter.filter((team, index, self)  => self.findIndex(t => t.key === team.key) === index);

        return [
            {
                type: FilterType.MULTISELECT,
                key: 'teamType',
                active: this.teamGroupFilters.length > 0,
                enabled: true,
                displayText: 'Filter by Access Type',
                data: {
                    multiselect: true,
                    activeItems: {
                        teamType: [...this.teamGroupFilters]
                    },
                    items: [
                        {
                            value: TeamGroups.ALL_PATIENTS,
                            label: 'All Patients',
                            key: TeamGroups.ALL_PATIENTS
                        },
                        {
                            value: TeamGroups.NO_PATIENTS,
                            label: 'No access to patients',
                            key: TeamGroups.NO_PATIENTS
                        },
                        {
                            value: TeamGroups.N_A,
                            label: 'N/A',
                            key: TeamGroups.N_A
                        }
                    ]
                }
            },
            {
                type: FilterType.MULTISELECT,
                key: 'team',
                active: this.teamsFilterList.length > 0,
                enabled: true,
                displayText: 'Filter by Team',
                data: {
                    multiselect: true,
                    activeItems: {
                        team: [...this.teamsFilterList]
                    },
                    items: teamsFilter
                }
            },

        ];
    }

    handleTeamsFilter(data: any){
        if(data.team.length > 0){
            this.teamsFilterList = [...data.team]
        }else{
            this.teamsFilterList = []
        }

        if(data.teamType.length > 0){
            this.teamGroupFilters = [...data.teamType]
        }else{
            this.teamGroupFilters = [];
        }
    }

    filterUsersByTeam(user: User): boolean {
        const vm = this;

        if(this.teamGroupFilters.includes(TeamGroups.N_A) && ((user.teams === null || user.teams.length === 0) && !AssignableToTeams.includes(user.role)) ) {
            return true;
        }

        if(this.teamGroupFilters.includes(TeamGroups.ALL_PATIENTS) && user.teams.findIndex((team) => team.type == 0) != -1){
            return true;
        }

        if(this.teamGroupFilters.includes(TeamGroups.NO_PATIENTS) && user.teams.length == 0 && AssignableToTeams.includes(user.role)){
            return true;
        }

        if(this.teamsFilterList.length > 0 && user.teams.filter((team) => vm.teamsFilterList.includes(team.id)).length > 0){
            return true;
        }

        return (this.teamGroupFilters.length == 0 && this.teamsFilterList.length == 0);
    }

    get currentFilterFunc(){
        return (user: User) => {
            return true;
        };
    }

    handleClearAllFilters(){
       this.activeRoleFilters = [];
       this.teamGroupFilters = [];
       this.teamsFilterList = [];
    }
}
