































































import { Component, Vue } from 'vue-property-decorator';
import CustomerTable from '../../components/customers/CustomerTable.vue';
import Button from '../../components/form/Button.vue';
import {ButtonType} from '@/components/form/FormTypes';
import {Permission} from "@/ts/permissions/Permission";
import axios from 'axios';
import {namespace} from 'vuex-class';
import Modal from '@/components/modal/Modal.vue';
import {CustomerMutations} from "@/vuex/customers";
import {convertToMomentTimezone} from "@/ts/TimezoneUtils";
import User from '@/models/User';
import moment from 'moment-timezone';
import {ModalMutations} from '@/vuex/modal';
import {CustomerListRequest, CustomerSortRequest} from '@/models/customers/Customer';
import CustomerService from '@/services/CustomerService';

const customers = namespace('customers');
const auth = namespace('auth');
const modal = namespace('modal');

/**
 * Renders a list of customers
 */
@Component({
    components:{
        CustomerTable,
        Button,
        Modal
    }
})
export default class CustomerList extends Vue {
    ButtonType = ButtonType;
    Permission = Permission;
    @customers.State('maintenanceModeActive') maintenanceModeActive!: boolean;
    @customers.Mutation(CustomerMutations.SET_MAINTENANCE_MODE_ACTIVE) setMaintenanceMode!: any;
    @auth.State('loggedInUser') loggedInUser!: User;
    @modal.Mutation(ModalMutations.CHANGE_MODAL_STATE) changeModalBackdropState!: (state: boolean) => void;

    /**
     * Whether the maintenance mode modal is showing
     */
    maintenanceModeModalShowing = false;

    /**
     * Maintenance mode text
     */
    customerMaintenanceText = '';
    customerMaintenanceDate: any = null;

    /**
     * Whether the top list is showing
     */
    topListShowing = false;

    /**
     * Data used to fetch the list of customers
     */
    customerRequest: CustomerListRequest | null = null;
    customerSortRequest: CustomerSortRequest = {sortKey: 'name', sortedAsc: true};
    customerService = new CustomerService(this.$store.state.auth.loggedInUser.timezone);



    get maintenanceModeButtonText(){
        return this.maintenanceModeActive ? "Disable customer maintenance mode" : "Enable customer maintenance mode"
    }

    /**
     * Shows the maintenance mode modal
     */
    showMaintenanceModeModal(){
        this.topListShowing = false;
        this.changeModalBackdropState(true);
        this.maintenanceModeModalShowing = true;
    }

    /**
     * Hides the maintenance mode modal
     */
    closeMaintenanceModeModal(){
        this.maintenanceModeModalShowing = false;
        this.changeModalBackdropState(false);
    }

    /**
     * Sends the request to update the maintenance mode status
     * todo: refactor to services
     */
    async handleMaintenanceModeUpdate(){
        try{
            if(this.maintenanceModeActive){
                const {status} = await axios.delete(process.env.VUE_APP_BACKEND_URI + '/maintenance-mode');
                this.setMaintenanceMode(false);
            }else{
                const {status} = await axios.post(process.env.VUE_APP_BACKEND_URI + '/maintenance-mode', {
                    message: this.customerMaintenanceText,
                    startDate: moment(this.customerMaintenanceDate as Date).toISOString()
                });
                this.setMaintenanceMode(true);
            }
        }catch(e){

        }
        this.closeMaintenanceModeModal();

    }

    /**
     * Sends the request to export the list of customers
     */
    async exportList() {
        if(this.customerRequest === null) {
            return;
        }
        this.topListShowing = false;
        this.$addSnackbarMessage({
            message: 'Customer list export in progress',
        });
        const response = await this.customerService.exportCustomers({...this.customerRequest, ...this.customerSortRequest});
        if(response.isSuccess()) {
            this.$addSnackbarMessage({
                message: 'Customer list exported successfully',
            })
        } else {
            this.$addSnackbarMessage({
                message: 'There was an issue exporting the list of customers. Try again later or contact customer support.',
            });
        }
    }
}
