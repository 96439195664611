



































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import {Validation} from 'vuelidate';
import {getErrorMessages} from "@/validations/Validator";
import { mixins } from 'vue-class-component';
import FormValidationMixin from "@/components/form/FormValidationMixin";
import Multiselect from 'vue-multiselect';

interface HTMLInputEvent extends Event{
    target: HTMLInputElement & EventTarget
}

/**
 * Renders one of several types of form inputs
 */
@Component
export default class FormInput extends mixins(FormValidationMixin) {
    /**
     * Type of the form input to render
     */
    @Prop({type: String, default: 'text'}) type!: string;

    /**
     * Minimum value for numerical inputs
     */
    @Prop({type: Number, required: false, default: undefined}) min?: number;

    /**
     * Maximum value for numerical inputs
     */
    @Prop({type: Number, required: false, default: undefined}) max?: number;

    /**
     * Step for numerical inputs
     */
    @Prop({type: Number, required: false, default: undefined}) step?: number;

    /**
     * Extra classes to apply to the input
     */
    @Prop({type: String, default: ''}) classes!: string;

    /**
     * The name of the input
     */
    @Prop({type: String, default: ''}) name!: string;

    /**
     * The current value of the input
     */
    @Prop() value! :any;

    /**
     * Icon to display on the left hand side of the input
     */
    @Prop({type: String, default: null}) leftIcon!: string;

    /**
     * Icon to display on the right hand side of the input
     */
    @Prop({type: String, default: null}) rightIcon!: string;

    /**
     * Whether the input is currently disabled
     */
    @Prop({type: Boolean, default: false}) disabled!: boolean;

    /**
     * Additional classes to apply to icons
     */
    @Prop({type: String, default: ''}) iconClasses!: string;

    /**
     * Displayed number of rows for multi-line inputs
     */
    @Prop({default: 5}) rows!: number;

    /**
     * Options to pass to calendar pickers
     */
    @Prop({default: null}) calendarOpts!: any;

    /**
     * Label for the input
     */
    @Prop({default: ''}) label!: string;

    /**
     * Placeholder for the input
     */
    @Prop({default: ''}) placeholder!: string;

    /**
     * Whether the input is currently focused
     */
    isFocused = false;

    /**
     * Determines if the input is a standard form input
     */
    isInput(){
        return this.type == "text" || this.type == "password"
    }

    /**
     * Emits an event when an input event is received from the form input
     * @param event
     */
    emitInput(event: HTMLInputEvent){
        this.$emit('input', event.target.value)
    }

    /**
     * Determines necessary outer container classes based on the state of the input
     */
    get outerClasses(){
        let extraClasses = {
            'is-filled': this.isFilled,
            'is-focused': this.isFocused
        };
        return extraClasses;
    }

    /**
     * Updates the focus state of the input
     */
    hasFocused(){
        this.isFocused = true;
    }

    /**
     * De-focuses the input
     */
    hasLostFocus(){
        this.isFocused = false;
        this.$emit('lostFocus');
    }

    /**
     * Whether the input currently has a value
     */
    get isFilled(){
        return this.value !== null && this.value !== ''
    }

    /**
     * Whether the input should have a floating label
     */
    get isFloatingLabel(){
        return this.type !== 'date';
    }

    resetInput() {
        this.$emit('input', '');
        this.$emit('enter');
    }
}
