



















































import {Component, Vue} from 'vue-property-decorator';
import {FeedHistoryReport} from '@/models/reports/FeedHistory';
import {ButtonType} from '@/components/form/FormTypes';
import FeedingHistoryChart from '../../../components/reports/feeding-history/FeedingHistoryChart.vue';
import Spinner from '../../../components/form/Spinner.vue';
import ReportDateSelector from '../../../components/reports/ReportDateSelector.vue';
import Multiselect from 'vue-multiselect';
import Table from '@/components/table/Table.vue';
import {TableCellType, TableHeaderData, TableRowData} from '@/components/table/cells/TableCellData';
import {convertToMomentTimezone, formatMoment, getReportDateRange} from '@/ts/TimezoneUtils';
import moment, {Moment} from 'moment';
import {sortBy} from '@/ts/utilities';
import {StatusRecord} from '@/models/Devices/MARRS/Records';
import axios from 'axios';
import {Attribute, AttributeMap} from '@/ts/permissions/Attribute';

enum Options {
    TABLE,
    CHART
}

/**
 * Shows a page for viewing the feeding history report for a device
 */
@Component({
    components: {
        FeedingHistoryChart,
        Spinner,
        ReportDateSelector,
        Multiselect,
        Table
    }
})
export default class PumpFeedingHistoryReport extends Vue {
    feedHistoryReport: FeedHistoryReport | null = null;
    loading: boolean = false;
    initialTab: number = 1;
    buttonType = ButtonType;

    timezone = '';
    gradient: any = null;

    Options = Options;

    currentSortKey: string = "timestamp";
    sortedAsc: boolean = false;

    startDate!: Moment;
    endDate!: Moment;
    deviceId!: string;

    reportOptions = [
        {
            label: 'Table',
            value: Options.TABLE
        },
        {
            label: 'Chart',
            value: Options.CHART
        }
    ];

    selectedOption = {
        label: 'Chart',
        value: Options.CHART
    };

    created(){
        this.timezone = this.$store.state.auth.loggedInUser.timezone;
        if(this.$route.query.hasOwnProperty('reportRange') && this.$route.query['reportRange'].length === 1){
            this.initialTab = Number.parseInt(this.$route.query['reportRange'][0] as string);
            const dateRange = getReportDateRange(this.initialTab, this.timezone);
            this.startDate = dateRange.startDate;
            this.endDate = dateRange.endDate;
        }else {
            this.startDate = moment.tz(this.timezone).subtract(27, 'day').set('hour', 0).set('minute', 0).set('second', 0);
            this.endDate = moment.tz(this.timezone).set('hour', 23).set('minute', 59).set('second', 59);
        }
        this.deviceId = this.$route.params.id;
    }

    async mounted(){
        await this.fetchData();
        //@ts-ignore
        this.gradient = this.$refs.canvas.getContext('2d').createLinearGradient(0,0, 0,this.$refs.container.clientHeight);
        this.gradient.addColorStop(0, 'rgb(184,172,225)');
        this.gradient.addColorStop(1, 'rgba(255,255,255, 0)');
    }

    async fetchData(){
        this.loading = true;
        const request = {
            startDate: this.startDate.toISOString(),
            endDate: this.endDate.toISOString()
        };
        const {data} = await axios.get(process.env.VUE_APP_BACKEND_URI + '/devices/' + this.deviceId + '/reports/feed-history-report', {
            params: request,
        });
        data.statusRecords.forEach((record: StatusRecord) => {
            record.timestamp = convertToMomentTimezone(this.timezone, record.timestamp as string);
        });
        this.feedHistoryReport = data;
        this.loading = false;
    }

    datesChanged(dates: any){
        this.startDate = dates.startDate;
        this.endDate = dates.endDate;
        this.fetchData();
    }

    get tableHeader(): TableHeaderData[]{
        return [
            {
                sortable: true,
                value: "Timestamp",
                widthClass: "w-48",
                sortKey: 'timestamp'
            },
            {
                sortable: false,
                value: "Wireless Address",
                widthClass: "w-64",
            },

            {
                sortable: false,
                value: "Software Version",
                widthClass: "w-48",
            },
            {
                sortable: false,
                value: "Graphics Version",
                widthClass: "w-48",
            },
            {
                sortable: true,
                value: "Amount Fed",
                widthClass: "w-48",
                sortKey: 'amountFedThisHour'
            },
            {
                sortable: true,
                value: "Feed Total",
                widthClass: "w-48",
                sortKey: 'feedTotalizer'
            },
            {
                sortable: true,
                value: "Dose Delivered",
                widthClass: "w-48",
                sortKey: 'feedDoseDelivered'
            },
            {
                sortable: true,
                value: "Boluses",
                widthClass: "w-48",
                sortKey: 'bolusesDelivered'
            },
            {
                sortable: true,
                value: "AC Status",
                widthClass: "w-48",
                sortKey: 'acStatus'
            },
            {
                sortable: true,
                value: "Battery Charging",
                widthClass: "w-48",
                sortKey: 'batteryCharging'
            },
            {
                sortable: true,
                value: "Low Battery Shutdown",
                widthClass: "w-48",
                sortKey: 'lowBatteryShutdown'
            },
            {
                sortable: true,
                value: "Powered Down",
                widthClass: "w-48",
                sortKey: 'poweredDown'
            },
        ]
    }

    get tableData(): TableRowData[]{
        if(this.feedHistoryReport === null || this.feedHistoryReport.statusRecords.length === 0){
            return [];
        }

        return [...this.feedHistoryReport.statusRecords].sort(sortBy(this.currentSortKey, this.sortedAsc)).map(record => {
            const cells = [
                {
                    type: TableCellType.NORMAL,
                    primaryValue: formatMoment(record.timestamp as Moment)
                },
                {
                    type: TableCellType.NORMAL,
                    primaryValue: record.wirelessAddress
                },
                {
                    type: TableCellType.NORMAL,
                    primaryValue: record.softwareVersion
                },
                {
                    type: TableCellType.NORMAL,
                    primaryValue: record.uiVersion
                },
                {
                    type: TableCellType.NORMAL,
                    primaryValue: ''+(record.amountFedThisHour/10).toFixed(1)
                },
                {
                    type: TableCellType.NORMAL,
                    primaryValue: ''+(record.feedTotalizer/10).toFixed(1)
                },
                {
                    type: TableCellType.NORMAL,
                    primaryValue: ''+record.feedDoseDelivered
                },
                {
                    type: TableCellType.NORMAL,
                    primaryValue: ''+record.bolusesDelivered
                },
                {
                    type: TableCellType.NORMAL,
                    primaryValue: record.acStatus ? 'Plugged In' : 'Not plugged in'
                },
                {
                    type: TableCellType.NORMAL,
                    primaryValue: record.batteryCharging ? 'Yes' : 'No'
                },
                {
                    type: TableCellType.NORMAL,
                    primaryValue: record.lowBatteryShutdown ? 'Yes' : 'No'
                },
                {
                    type: TableCellType.NORMAL,
                    primaryValue: record.poweredDown ? 'Yes' : 'No'
                },
            ];

            return {
                cells,
                indexKey: record.id
            }
        })
    }

    sortData(message: any){
        this.currentSortKey = message.sortKey;
        this.sortedAsc = message.sortedAsc;
    }

    get shouldShowReportSelector(){
        return !AttributeMap[this.$store.state.auth.loggedInUser.role].includes(Attribute.REQUIRES_CUSTOMER);
    }
}
