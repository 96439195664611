

















import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import CompactPumpFeedingHistoryGraph from '../../devices/marrs/reports/CompactPumpFeedingHistoryGraph.vue';
import {ReportDateContainer} from '@/models/reports/AdherenceReport';
import {PatientDeviceAssignment} from '@/models/patients/Patient';
import {namespace} from 'vuex-class';
import moment, {Moment} from 'moment';
import FeedingHistoryChart from '@/components/reports/feeding-history/FeedingHistoryChart.vue';
import Spinner from '@/components/form/Spinner.vue';
import PatientFeedingHistoryReportService from '@/services/patients/reports/PatientFeedingHistoryReportService';

const patients = namespace('patients');

/**
 * Renders a graph of feeding history for a patient in a card
 */
@Component({
    components: {
        FeedingHistoryChart,
        Spinner,
    }
})
export default class FeedHistoryCard extends Vue {
    /**
     * Feeding report data
     */
    feedHistoryReport: any = null;

    /**
     * Whether the request to fetch feeding data is loading
     */
    loading = true;
    @Prop() reportOptions!: ReportDateContainer;
    @Prop() reportDateOption!: number;
    @Prop() eventBus!: Vue;

    /**
     * ID of the patient
     */
    patientId!: string;

    /**
     * Timezone of the user
     */
    timezone: string = '';
    patientFeedingHistoryReportService = new PatientFeedingHistoryReportService(this.$store.state.auth.loggedInUser.timezone);
    @patients.State(state => state.homePage.patient.assignments) patientAssignments!: PatientDeviceAssignment[];
    today = moment.tz(this.$store.state.auth.loggedInUser.timezone).endOf('day');

    created(){
        this.timezone = this.$store.state.auth.loggedInUser.timezone;
        this.patientId = this.$route.params.id;
    }

    async mounted(){
        await this.fetchData()
        this.eventBus.$on('exportExcel', this.exportExcel);
        this.eventBus.$on('exportPdf', this.exportPdf);
    }

    /**
     * When the report date selector changes, fetch the updated data
     */
    @Watch('reportOptions')
    reportOptionsChanged(){
        this.fetchData();
    }

    /**
     * When the patient's assignments change, fetch the updated data
     */
    @Watch('patientAssignments')
    assignmentsChanged() {
        if(!this.loading) {
            this.fetchData();
        }
    }

    /**
     * Sends the request to fetch the feeding history data for the patient
     */
    async fetchData(){
        this.loading = true;
        const response = await this.patientFeedingHistoryReportService.fetchFeedingHistoryReport(Number.parseInt(this.patientId), this.reportOptions.startDate, this.today.toDate());
        if(response.isSuccess()) {
            response.map((report) => {
                this.feedHistoryReport = report;
            })
        }
        this.$emit('exportDisabled', this.feedHistoryReport === null || this.feedHistoryReport.statusRecords.length === 0)
        this.loading = false;
    }

    /**
     * Sends the request to download the feeding history data as an excel file
     */
    async exportExcel() {
        const response = await this.patientFeedingHistoryReportService.exportFeedingHistoryReportAsExcel(Number.parseInt(this.patientId), this.reportOptions.startDate, this.today.toDate());
        if(response.isSuccess()) {
            this.$addSnackbarMessage({
                message: 'Feeding history exported successfully'
            })
        } else {
            this.$addSnackbarMessage({
                message: 'There was an issue exporting the feeding history report. Try again later or contact customer support.',
            });
        }
    }

    /**
     * Sends the request to download the feeding history data as a PDF
     */
    async exportPdf() {
        const response = await this.patientFeedingHistoryReportService.exportFeedingHistoryReportAsPdf(Number.parseInt(this.patientId), this.reportOptions.startDate, this.today.toDate(), {
            graphData: (this.$refs.chartItem as any).$refs.canvas.toDataURL(),
        });
        if(response.isSuccess()) {
            this.$addSnackbarMessage({
                message: 'Feeding history exported successfully'
            })
        } else {
            this.$addSnackbarMessage({
                message: 'There was an issue exporting the feeding history report. Try again later or contact customer support.',
            });
        }
    }
}
