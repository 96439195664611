











import { Component, Vue } from 'vue-property-decorator';
import PumpSettingsTable from '../../../components/reports/settings-report/PumpSettingsTable.vue';

/**
 * Renders the pump settings report for a single patient
 */
@Component({
    components: {
        PumpSettingsTable,
    }
})
export default class PatientPumpSettingsReport extends Vue {

    get itemId() {
        return this.$route.params.id;
    }
}
