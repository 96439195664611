




































import { Component, Vue } from 'vue-property-decorator';
import {namespace} from "vuex-class";
import {FirmwareUpgradeJob, FirmwareUpgradeJobStatus, MARRSPump} from "@/models/Devices/MARRS/MARRSPump";
import FirmwareFile from "@/models/Devices/MARRS/FirmwareFile";
import Spinner from '@/components/form/Spinner.vue';
import TextDisplay from '@/components/utility/TextDisplay.vue';
import Multiselect from 'vue-multiselect';
import {ButtonType} from "@/components/form/FormTypes";
import ConnectFirmwareUpgradeService from '@/services/devices/connect/ConnectFirmwareUpgradeService';

const marrs = namespace('marrsPump');

/**
 * Shows the current software version running on the pump and info about any firmware jobs
 * @deprecated Deprecated during project monarch for replacement in project badger
 */
@Component({
    components: {
        Spinner,
        TextDisplay,
        Multiselect
    }
})
export default class CurrentSoftwareCard extends Vue {
    loading = false;
    connectFirmwareUpgradeService = new ConnectFirmwareUpgradeService(this.$store.state.auth.loggedInUser.timezone);
    currentFirmwareJobText: string[] = [];
    buttonType = ButtonType;

    @marrs.State(state => state.homePage.pump) pump!: MARRSPump;
    currentFirmwareJob: FirmwareUpgradeJob | null = null;
    availableFirmwareFiles: FirmwareFile[] = [];
    selectedFirmwareFile: FirmwareFile | null = null;
    FirmwareJobStatus = FirmwareUpgradeJobStatus;

    async mounted(){
        this.loading = true;
        await Promise.all([
            this.fetchAvailableFiles(),
            this.fetchCurrentJobInfo()
        ]);
        this.loading = false;
    }

    async fetchCurrentJobInfo(){
        this.currentFirmwareJob = await this.connectFirmwareUpgradeService.fetchPumpFirmwareDownloadStatus(this.pump.id);
        this.currentFirmwareJobText = this.connectFirmwareUpgradeService.getFirmwareJobStatusText(this.currentFirmwareJob);
    }

    async fetchAvailableFiles(){
        this.availableFirmwareFiles = await this.connectFirmwareUpgradeService.fetchAvailableFirmwareFiles();
    }

    async updatePump(){
        await this.connectFirmwareUpgradeService.updatePumpFirmware([this.pump.id], this.selectedFirmwareFile as FirmwareFile);
        await this.fetchCurrentJobInfo();
    }
}
