


















































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import {PageData, TableCellData, TableHeaderData, TableRowData} from "@/components/table/cells/TableCellData";
import TableRow from '@/components/table/TableRow.vue';
import TableHeader from "@/components/table/TableHeader.vue";
import paginate from "@/pagination";
import Filter, {SearchData} from "@/ts/Filter";
import { ButtonType } from '@/components/form/FormTypes';
import Spinner from '@/components/form/Spinner.vue';
import Multiselect from 'vue-multiselect';

/**
 * Renders a table of data
 */
@Component({
    components: {
        'table-row': TableRow,
        'table-header': TableHeader,
        Spinner,
        Multiselect
    }
})
export default class Table extends Vue {
    /**
     * Whether the table should be paginated
     */
    @Prop({default: false, type: Boolean}) paginated!: boolean;

    /**
     * Whether the table is searchable
     */
    @Prop({default: false, type: Boolean}) searchable!: boolean;

    /**
     * Whether te table has filters
     */
    @Prop({default: false, type: Boolean}) filterable!: boolean;

    /**
     * Rows of data to display
     */
    @Prop() rows!: TableRowData[] | PageData;

    /**
     * Header data to display
     */
    @Prop() headerCells!: TableHeaderData[];

    /**
     * Outside filters
     */
    @Prop() filterData!: Filter[];

    /**
     * Initial field the table should be sorted by
     */
    @Prop({type: String, default: ''}) initialSortKey!: string;

    /**
     * Whether the table is sorted ascending
     */
    @Prop({type: Boolean, default: true}) initialSortedAsc!: boolean;

    /**
     * Event bus for communicating with the parent
     */
    @Prop({type: Object, default: null}) eventBus!: Vue;

    /**
     * Initial page size
     */
    @Prop({type: Number, default: 25}) defaultPageSize!: number;

    /**
     * Whether the table is loading
     */
    @Prop({type: Boolean, default: false}) initialLoading!: boolean;

    /**
     * Classes to apply to the table container
     */
    @Prop({type: String, default: ''}) tableContainerClass!: string;

    /**
     * Whether the table is in a constrained container
     */
    @Prop({type: Boolean, default: false}) constrainedSticky!: boolean;

    /**
     * Whether the rows should have checkboxes
     */
    @Prop({type: Boolean, default: false}) checkableRows!: boolean;

    /**
     * Highlight css class to use for highlightable rows
     */
    @Prop({type: String, default: 'bg-purple-highlight'}) highlightClass!: string;

    /**
     * Message to display when the table is empty
     */
    @Prop({type: String, default: 'No items available'}) emptyTableMessage!: string;

    /**
     * Search fields to use for the combined search field
     */
    @Prop({default: () => []}) searchTypeData!: SearchData[];

    /**
     * VAlue of hte search field
     */
    @Prop({type: String, default: ''}) initialSearch!: string;

    /**
     * Wether to apply the relative class to the table container
     */
    @Prop({default: true}) tableContainerRelative!: boolean;

    /**
     * Whether an overlay should be shown over all rows
     */
    @Prop({default: false}) showOverlay!: boolean;

    /**
     * Extra classes to apply to the table element
     */
    @Prop({default: ''}) extraTableElementClasses!: string;
    buttonType = ButtonType;

    /**
     * Whether the table is loading
     */
    isLoading = false;


    /**
     * Current page the table is on
     */
    currentPage = 1;

    /**
     * Current page size
     */
    pageSize = 25;
    searchString = '';
    currentFilter = null;
    sticky = false;

    /**
     * Selected search tyep
     */
    selectedSearchItem: SearchData | null = null;
    observer: IntersectionObserver | undefined;

    /**
     * Rows of data to display
     */
    get filteredRowData(){
        let rows = this.rows;
        if(Table.isDataAsync(rows)){
            return rows.pageData;
        }else{
            return rows;
        }

    }

    mounted(){
        this.pageSize = this.defaultPageSize;
        if(this.eventBus != null){
            this.eventBus.$on('dataLoaded', this.asyncDataLoaded);
            this.eventBus.$on('dataReset', this.dataReset);
            this.eventBus.$on('asyncDataLoading', () => this.isLoading = true);
            this.eventBus.$on('resetSearch', () => {
                this.searchString = '';
                if (this.searchTypeData.length > 0) {
                    this.selectedSearchItem = this.searchTypeData[0]
                }
            })
        }
        let options: any = {
            threshold: [0]
        };
        if(this.constrainedSticky){
            options.root = this.$refs.stickyHeader as Element
        }
        this.observer = new IntersectionObserver((records, observer) => {
            for (const record of records) {
                const targetInfo = record.boundingClientRect;
                const rootBoundsInfo = record.rootBounds;
                if(rootBoundsInfo != null && targetInfo != null){
                    // Started sticking.
                    if (targetInfo.bottom < rootBoundsInfo.top) {
                        this.sticky = true;
                    }

                    // Stopped sticking.
                    if (targetInfo.bottom >= rootBoundsInfo.top &&
                        targetInfo.bottom < rootBoundsInfo.bottom) {
                        this.sticky = false;
                    }
                }
            }
        }, options);

        this.observer.observe(this.$refs.sentinel as Element);

        if(this.searchTypeData != null && this.searchTypeData.length > 0){
            this.selectedSearchItem = this.searchTypeData[0];
        }

        if(this.initialSearch != ''){
            this.searchString = this.initialSearch;
        }
    }

    beforeDestroy(){
        if(this.observer !== undefined){
            this.observer.disconnect();
        }
    }

    /**
     * Gets the width for each table cell based on the widths of the header
     */
    get tableCellWidthClasses() {
        return this.headerCells.map((header) => {
            return header.widthClass.replace("invisible", "").replace("invisible-table-children", "")
        })
    }

    /**
     * Current page of data to display
     */
    get paginatedRowData(){
        if(!this.paginated){
            return this.filteredRowData;
        }
        let rows = this.rows;
        if(Table.isDataAsync(rows)){
            return rows.pageData;
        }else{
            const currentOffset = (this.currentPage - 1) * this.pageSize;
            return this.filteredRowData.slice(currentOffset, currentOffset + this.pageSize);
        }

    }

    /**
     * Number of the last page of data
     */
    get lastPage(){
        let rows = this.rows;
        if(Table.isDataAsync(rows)){
            return rows.numPages;
        }else{
            return Math.ceil(this.filteredRowData.length / this.pageSize);
        }

    }

    /**
     * Creates the pagination information to show
     */
    get pageInfo(){
        return paginate(this.currentPage, this.lastPage);
    }

    /**
     * Class applied to space out elements above the table based on table settings
     */
    get infoClass() {
        if((this.filterable || this.searchable) && this.paginated){
            return ['justify-between'];
        }else if(this.paginated){
            return ['justify-end'];
        }else{
            return ['justify-start'];
        }
    }

    /**
     * Updates the sorting information when a column is sorted
     * @param message
     */
    sortData(message: any){
        const sortKey = message.sortKey;
        const sortedAsc = message.sortedAsc;
        if(Table.isDataAsync(this.rows)){
            this.isLoading = true;
        }
        this.$emit('sort', {sortKey, sortedAsc});
    }

    /**
     * Navigates the table to a specific page of data
     * @param page
     */
    navigateToPage(page: any){
        if(page === '...' || page === this.currentPage || page <= 0 || page > this.lastPage){
            return;
        }
        if(Table.isDataAsync(this.rows)){
            this.$emit('changePage', page);
            this.isLoading = true;
            this.currentPage = page;
        }else{
            this.currentPage = page;
        }

    }

    /**
     * Marks the table as having completed loading
     */
    asyncDataLoaded(){
        this.isLoading = false;
    }

    /**
     * Resets the table back to the first page
     */
    dataReset(){
        this.currentPage = 1;
        this.isLoading = false;
    }

    /**
     * Emits an event when the selected filters changes
     * @param val
     * @param oldVal
     */
    @Watch('currentFilter')
    filterChanged(val: string | null, oldVal: string | null){
        this.$emit('filterChanged', val);
    }

    /**
     * Resets the table back to page one if the rows data changes
     */
    @Watch('rows')
    rowsChanged(){
        if(!Table.isDataAsync(this.rows)){
            this.currentPage = 1;
        }
    }

    /**
     * Updates the page information when the page size changes
     * @param val
     */
    @Watch('pageSize')
    pageSizeChanged(val: number){
        this.currentPage = 1;
        if(Table.isDataAsync(this.rows)){
            this.isLoading = true;
            this.$emit('pageSizeChanged', val);
        }
    }

    /**
     * Whether the table data is loaded async
     * @param rows
     */
    static isDataAsync(rows: TableRowData[] | PageData): rows is PageData {
        return (rows as PageData).numPages !== undefined;
    }

    /**
     * Performs the sorting of table data
     * @param prop
     * @param index
     * @param asc
     */
    sortBy(prop: string, index: number, asc: boolean){
        return function(first:any, second:any){
            if(first[index]['primaryValue'] < second[index]['primaryValue']){
                return asc ? -1 : 1;
            }else if(first[index]['primaryValue'] > second[index]['primaryValue']){
                return asc ? 1 : -1;
            }

            return 0;
        }
    }

    /**
     * Emits an event when the table is searched
     */
    search(){
        if(Table.isDataAsync(this.rows)){
            this.isLoading = true;
        }
        if(this.searchTypeData.length == 0){
            this.$emit('search', this.searchString);
        }else if(this.selectedSearchItem != null){
            this.$emit('search', {
                search: this.searchString,
                key: this.selectedSearchItem.key
            })
        }

    }

    /**
     * Emits a search event when the search field is defocused
     */
    lostFocusClear() {
        if(this.searchString !== "") {
            return;
        }

        if(Table.isDataAsync(this.rows)){
            this.isLoading = true;
        }

        if(this.searchTypeData.length == 0){
            this.$emit('search', this.searchString);
        }else if(this.selectedSearchItem != null){
            this.$emit('search', {
                search: this.searchString,
                key: this.selectedSearchItem.key
            })
        }
    }

    /**
     * Whether the pagination controls should be shown.
     * They will not be shown if there is no data in the table
     */
    get shouldShowPaginationControls(){
        if(Table.isDataAsync(this.rows)){
            return this.rows != null && this.rows.pageData != null && this.rows.pageData.length > 0;
        }else{
            return this.rows.length > 0;
        }
    }

    /**
     * Emits an event when the data for a filter changes.
     * If the table is async, it will be marked as loading
     * @param key
     * @param data
     */
    handleFilterData({key, data}: {key: string, data: any}){
        if(Table.isDataAsync(this.rows)){
            this.isLoading = true;
        }
        this.$emit(key, data);
    }

    get totalItemsInTable() {
        if(Table.isDataAsync(this.rows)) {
            return this.rows.totalElements;
        } else if(this.showOverlay) {
            return this.rows.length -1;
        } else {
            return this.rows.length;
        }
    }


    /**
     * Creates the text showing how many elements are currently showing out of the total
     */
    get currentlyShowingText(){
        if(Table.isDataAsync(this.rows)) {
            const maxSize = this.rows.totalElements;
            const lowerPageSize = ((this.currentPage - 1) * this.pageSize) + 1;
            const upperPageSize = (this.currentPage) * this.pageSize < maxSize ? (this.currentPage) * this.pageSize : maxSize;
            return `${lowerPageSize}-${upperPageSize} of ${this.rows.totalElements}`
        } else if(this.showOverlay) {
            const maxSize = this.rows.length - 1;
            const lowerPageSize = ((this.currentPage - 1) * this.pageSize) + 1;
            const upperPageSize = (this.currentPage) * this.pageSize < maxSize ? (this.currentPage) * this.pageSize : maxSize;
            return `${lowerPageSize}-${upperPageSize} of ${this.rows.length - 1}`
        }else{
            const maxSize = this.rows.length;
            const lowerPageSize = ((this.currentPage - 1) * this.pageSize) + 1;
            const upperPageSize = (this.currentPage) * this.pageSize < maxSize ? (this.currentPage) * this.pageSize : maxSize;
            return `${lowerPageSize}-${upperPageSize} of ${this.rows.length}`
        }
    }

    /**
     * Whether any of the columns are filterable
     */
    get hasRowFilters(){
        for (const headerCell of this.headerCells){
            if(headerCell.filterable){
                return true;
            }
        }

        return false;
    }

    /**
     * Emits an event to reset all table filters
     */
    clearAllFilters(){
        this.searchString = "";
        this.$emit('clearAllFilters');
        this.search();
    }

    /**
     * Whether any column filters are active
     */
    get filtersActive(){
        for(const headerCell of this.headerCells){
            if(headerCell.filterable && headerCell.filters != undefined && headerCell.filters.find(value => value.active) != undefined){
                return true;
            }
        }

        return false;
    }
}
