import {Moment} from 'moment';
import {AsyncEither, Either, Failure, Success} from '@/errors/Either';
import {AdherenceRecord, DailyAdherenceRecord} from '@/models/reports/AdherenceReport';
import {Error} from '@/errors/Error';
import axios from 'axios';
import {convertToMomentTimezone} from '@/ts/TimezoneUtils';


export default class PatientAdherenceReportService {
    private readonly timezone: string;


    constructor(timezone: string) {
        this.timezone = timezone;
    }

    /**
     * Sends the request to fetch the adherence data for a patient
     * @param patientId ID of the patient
     * @param startDate Start date of the report
     * @param endDate End date of the report
     */
    // tslint:disable-next-line:max-line-length
    public async fetchAdherenceReport(patientId: number, startDate: Moment | Date, endDate: Moment | Date): AsyncEither<AdherenceRecord[], Error> {
        try {
            // tslint:disable-next-line:max-line-length
            const {data}: {data: AdherenceRecord[]} = await axios.get(process.env.VUE_APP_BACKEND_URI + '/patients/' + patientId + '/reports/adherence', {
                params: {
                    startDate: startDate.toISOString(),
                    endDate: endDate.toISOString(),
                },
            });
            data.forEach((record) => {
                if (record.startDate !== null) {
                    record.startDate = convertToMomentTimezone(this.timezone, record.startDate as string);
                }
            });
            return new Success(data);
        } catch (e) {
            return new Failure({
                message: 'Failed to generate adherence report',
            });
        }
    }

    /**
     * Fetches the daily adherence data
     * @param patientId ID of the patient
     * @param startDate Start date of the report
     * @param endDate End date of the report
     */
    // tslint:disable-next-line:max-line-length
    public async fetchAdherenceDaily(patientId: number, startDate: Moment | Date, endDate: Moment | Date): AsyncEither<DailyAdherenceRecord[], Error> {
        try {
            // tslint:disable-next-line:max-line-length
            const {data}: {data: DailyAdherenceRecord[]} = await axios.get(process.env.VUE_APP_BACKEND_URI + '/patients/' + patientId + '/reports/adherence/daily', {
                params: {
                    startDate: startDate.toISOString(),
                    endDate: endDate.toISOString(),
                },
            });
            data.forEach((record: DailyAdherenceRecord) => {
                record.timestamp = convertToMomentTimezone(this.timezone, record.timestamp as string);
                // tslint:disable-next-line:max-line-length
                record.prescriptionStartDate = convertToMomentTimezone(this.timezone, record.prescriptionStartDate as string);
            });
            return new Success(data);
        } catch (e) {
            return new Failure({
                message: 'Failed to generate weight history report',
            });
        }
    }
}
