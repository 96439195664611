/**
 * Represents an operation that can be successful or fail
 */
export type Either<T, E> = Success<T, E> | Failure<T, E>;

/**
 * Interface implemented by anything that is an either
 */
interface EitherInterface<T, E> {
    /**
     * Applies the given function to a successful operation
     * @param f Function to apply to a successful operation
     */
    map<A>(f: (t: T) => A): Either<A, E>;

    /**
     * Applies the given function to a failed operation
     * @param f
     */
    mapErr<U>(f: (e: E) => U): Either<T, U>;

    /**
     * Whether the operation was successful
     */
    isSuccess(): this is Success<T, E>;

    /**
     * Whether the operation failed
     */
    isFailure(): this is Failure<T, E>;
}

export class Success<T, E> implements EitherInterface<T, E> {
    private readonly value: T;


    constructor(value: T) {
        this.value = value;
    }

    public isFailure(): this is Failure<T, E> {
        return !this.isSuccess();
    }

    public isSuccess(): this is Success<T, E> {
        return true;
    }

    public map<A>(f: (t: T) => A): Either<A, E> {
        return new Success(f(this.value));
    }

    public mapErr<U>(f: (e: E) => U): Either<T, U> {
        return new Success(this.value);
    }
}

export class Failure<T, E> implements EitherInterface<T, E>{
    private readonly value: E;


    constructor(value: E) {
        this.value = value;
    }

    public isFailure(): this is Failure<T, E> {
        return !this.isSuccess();
    }

    public isSuccess(): this is Success<T, E> {
        return false;
    }

    public map<A>(f: (t: T) => A): Either<A, E> {
        return new Failure(this.value);
    }

    public mapErr<U>(f: (e: E) => U): Either<T, U> {
        return new Failure(f(this.value));
    }
}

/**
 * Operation with no successful data
 */
export type NullSuccess<E> = Either<null, E>;

export type AsyncNullSuccess<E> = Promise<NullSuccess<E>>;
export const AsyncNullSuccess = Promise;

export type AsyncEither<T, E> = Promise<Either<T, E>>;
export const AsyncEither = Promise;

export function nullSuccess<E>(): NullSuccess<E> {
    return new Success(null);
}
