






















import { Component, Vue } from 'vue-property-decorator';
import {ButtonType} from "@/components/form/FormTypes";
import {namespace} from "vuex-class";
import {MARRSPump} from "@/models/Devices/MARRS/MARRSPump";
import {MarrsPumpMutations} from "@/vuex/marrs";
import ConnectNotesService from '@/services/devices/connect/ConnectNotesService';

const marrs = namespace('marrsPump');

/**
 * Renders the device notes card
 */
@Component
export default class PumpNotesCard extends Vue {
    buttonType = ButtonType;
    editing = false;

    /**
     * Current value of the pump notes
     */
    pumpNotes: string = '';
    @marrs.State(state => state.homePage.pump) pump!: MARRSPump;
    @marrs.Mutation(MarrsPumpMutations.SET_PUMP_NOTES) setNotes!: (notes: string) => void;
    connectNotesService = new ConnectNotesService();

    mounted(){
        if(this.pump.notes !== null){
            this.pumpNotes = this.pump.notes.notes;
        }
    }

    /**
     * Sets the notes field to allow editing
     * Notifies the parent that editing has been enabled
     */
    editNotes() {
        this.editing = true;
        this.$emit('editing', true);
    }

    /**
     * Sets the notes field to disallow editing
     * Notifies the parent that editing has finished
     */
    finishEditing() {
        this.editing = false;
        this.$emit('editing', false);
    }

    /**
     * Cancels notes editing without saving the data
     */
    cancelNotesEdit() {
        if(this.pump?.notes !== null) {
            this.pumpNotes = this.pump.notes.notes;
        } else {
            this.pumpNotes = '';
        }
        this.finishEditing();
    }

    /**
     * Sends the request to update the device notes
     */
    async updateNotes(){
        const response = await this.connectNotesService.updatePumpNotes(this.pump.id, this.pumpNotes);
        response.map(() => {
            this.setNotes(this.pumpNotes);
            this.$addSnackbarMessage({
                message: "Device notes updated successfully"
            })
        })
        this.finishEditing()
    }

}
