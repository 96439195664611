
















import {Component, Prop, Vue} from 'vue-property-decorator';
import {ButtonType} from '../form/FormTypes';

/**
 * Renders a confirmation popup within a table
 */
@Component
export default class InnerTableDeleteConfirmation extends Vue {
    /**
     * Larder header
     */
    @Prop() mainTextValue!: string;

    /**
     * Small reminder text
     */
    @Prop({default: ''}) secondaryTextValue!: string;
    buttonTypes = ButtonType;
}
