




























































import {Component, Prop, Vue} from 'vue-property-decorator';
import moment, {Moment} from 'moment';
import {
    ErrorType,
    ErrorTypeNameMap, ErrorTypeScreenNumberMap, getErrorRecord,
    PumpErrorReportData,
    ScreenNumberToErrorTypeMap,
    getErrorDescription
} from '@/models/reports/PumpErrors';
import {AlertRecord} from '@/models/Devices/MARRS/Records';
import {formatMoment, getReportDateRange} from '@/ts/TimezoneUtils';
import {TableCellData, TableCellType, TableHeaderData, TableRowData} from '../../table/cells/TableCellData';
import {FilterType} from '@/ts/Filter';
import {sortBy} from '@/ts/utilities';
import ReportDateSelector from '@/components/reports/ReportDateSelector.vue';
import Table from '@/components/table/Table.vue';
import MARRSPumpScreen from '@/components/MARRS/monitor-mode/MARRSPumpScreen.vue';
import {ButtonType} from '@/components/form/FormTypes';
import Modal from '@/components/modal/Modal.vue';
import {namespace} from 'vuex-class';
import {ModalMutations} from '@/vuex/modal';
import ConnectErrorReportService from '@/services/devices/connect/reports/ConnectErrorReportService';
import PatientPumpErrorReportService from '@/services/patients/reports/PatientPumpErrorReportService';

const modal = namespace('modal');

/**
 * Renders a list of recent errors experienced by a pump
 */
@Component({
    components: {
        ReportDateSelector,
        Table,
        MARRSPumpScreen,
        Modal,
    }
})
export default class ErrorReport extends Vue {

    /**
     * ID of the pump or patient to generate the report for
     */
    @Prop() itemId!: number;

    /**
     * Whether the report should be displayed in patient mode
     */
    @Prop({default: false}) patientMode!: boolean;
    @modal.Mutation(ModalMutations.CHANGE_MODAL_STATE) changeModalState!: (state: boolean) => void;

    /**
     * Currently selected tab for the report date selector
     */
    initialTab: number = 1;

    /**
     * Error report data
     */
    errorReportData: PumpErrorReportData | null = null;

    /**
     * Start date of the report
     */
    startDate!: Moment;

    /**
     * End date of the report
     */
    endDate!: Moment;

    /**
     * Whether the report is loading
     */
    loading = false;

    /**
     * Error report type filters
     */
    activeErrorTypes: ErrorType[] = [];

    /**
     * Current user's timezone
     */
    timezone: string = "";

    /**
     * Currently selected error
     */
    selectedAlert: AlertRecord | null = null;

    /**
     * Map of error types to name
     */
    errorTypeNameMap = ErrorTypeNameMap;

    /**
     * Map of screen numbers to type
     */
    screenNumberToErrorTypeMap = ScreenNumberToErrorTypeMap;

    /**
     * Field the table is currently sorted by
     */
    currentSortKey: string = "timestamp";

    /**
     * Whether the table is sorted ascending
     */
    sortedAsc: boolean = false;

    connectErrorReportService = new ConnectErrorReportService(this.$store.state.auth.loggedInUser.timezone);
    patientPumpErrorReportService = new PatientPumpErrorReportService(this.$store.state.auth.loggedInUser.timezone);

    buttonTypes = ButtonType;

    /**
     * Whether the pump error modal is showing
     */
    pumpScreenModalShowing = false;

    /**
     * Index of the selected error
     */
    selectedIndex = -1;

    created(){
        this.timezone = this.$store.state.auth.loggedInUser.timezone;
        if(this.$route.query.hasOwnProperty('reportRange') && this.$route.query['reportRange'].length === 1){
            this.initialTab = Number.parseInt(this.$route.query['reportRange'][0] as string);
            const dateRange = getReportDateRange(this.initialTab, this.timezone);
            this.startDate = dateRange.startDate;
            this.endDate = dateRange.endDate;
        }else {
            this.startDate = moment.tz(this.timezone).subtract(27, 'day').set('hour', 0).set('minute', 0).set('second', 0).millisecond(0);
            this.endDate = moment.tz(this.timezone).set('hour', 23).set('minute', 59).set('second', 59).millisecond(0);
        }
    }

    async mounted() {
        await this.fetchData();
    }

    /**
     * Formats the given moment timestamp
     * @param timestamp Timestamp to format
     */
    formatTimestamp(timestamp: Moment | string){
        return formatMoment(timestamp as Moment);
    }

    /**
     * Clears the active error type filters
     */
    clearAllFilters() {
        this.activeErrorTypes = [];
    }

    /**
     * Updates the start/end date when the report date selector is updated
     * @param dates New report dates
     */
    async datesChanged(dates: any){
        this.startDate = dates.startDate;
        this.endDate = dates.endDate;
        await this.fetchData();
    }

    /**
     * Table header definition
     */
    get tableHeader(): TableHeaderData[]{
        const headerCells: TableHeaderData[] = [
            {
                sortable: true,
                value: "Timestamp",
                widthClass: `${this.patientMode ? 'w-3/10' : 'w-1/3'}`,
                sortKey: "timestamp"
            },
        ];

        if(this.patientMode) {
            headerCells.push(
                {
                    sortable: false,
                    value: 'Pump serial number',
                    widthClass: "w-1/4",
                }
            )
        }

        headerCells.push(
            {
                sortable: false,
                value: "Error type",
                widthClass: `${this.patientMode ? 'w-1/5' : 'w-1/3'}`,
                filterable: true,
                filterKey: "errorType",
                filters: [
                    {
                        type: FilterType.MULTISELECT,
                        key: 'errorType',
                        active: this.activeErrorTypes.length > 0,
                        displayText: 'Filter by error type',
                        enabled: true,
                        data: {
                            multiselect: true,
                            activeItems: {
                                errorType: [...this.activeErrorTypes]
                            },
                            items: [
                                {
                                    value: ErrorType.WARNING,
                                    label: ErrorTypeNameMap[ErrorType.WARNING],
                                    key: ErrorTypeNameMap[ErrorType.WARNING]
                                },
                                {
                                    value: ErrorType.ERROR,
                                    label: ErrorTypeNameMap[ErrorType.ERROR],
                                    key: ErrorTypeNameMap[ErrorType.ERROR]
                                },
                                {
                                    value: ErrorType.CRITICAL_ERROR,
                                    label: ErrorTypeNameMap[ErrorType.CRITICAL_ERROR],
                                    key: ErrorTypeNameMap[ErrorType.CRITICAL_ERROR]
                                },
                                {
                                    value: ErrorType.SYSTEM_ERROR,
                                    label: ErrorTypeNameMap[ErrorType.SYSTEM_ERROR],
                                    key: ErrorTypeNameMap[ErrorType.SYSTEM_ERROR]
                                },
                            ]
                        }
                    }
                ]
            },
            {
                sortable: false,
                value: 'Name',
                widthClass: `${this.patientMode ? 'w-1/4' : 'w-1/3'}`,
            },
        );

        return headerCells;
    }

    /**
     * Transforms an array of pump errors into an array of table rows
     */
    get tableData(): TableRowData[]{
        if(this.errorReportData == null){
            return []
        }

        return this.errorReportData.alertRecords
            .filter(this.filterRecords)
            .sort(sortBy(this.currentSortKey, this.sortedAsc))
            .map(record => {
                const cells: TableCellData[] = [
                    {
                        type: TableCellType.NORMAL,
                        primaryValue: formatMoment(record.timestamp as Moment),
                        clickable: true
                    },
                ];

                if(this.patientMode) {
                    cells.push({
                        type: TableCellType.NORMAL,
                        primaryValue: record.serialNumber
                    })
                }

                cells.push(
                    {
                        type: TableCellType.NORMAL,
                        primaryValue: ErrorTypeNameMap[ScreenNumberToErrorTypeMap[record.screen]]
                    },
                    {
                        type: TableCellType.NORMAL,
                        primaryValue: this.getErrorDescription(record)
                    },
                );
                return {
                    cells,
                    highlighted: false,
                    key: record.id,
                    indexKey: record.id
                }
            })
    }

    /**
     * Filters out alerts when the types filter is active
     * @param alertRecord Record to potentially filter out
     */
    filterRecords(alertRecord: AlertRecord){
        if(this.activeErrorTypes.length == 0){
            return true;
        }

        return this.activeErrorScreens.includes(alertRecord.screen);
    }

    /**
     * Array of screen numbers currently active
     */
    get activeErrorScreens(){
        return this.activeErrorTypes.flatMap(errorType => {
            return ErrorTypeScreenNumberMap[errorType];
        })
    }

    /**
     * Updates the sort information when the table is sorted
     * @param message
     */
    sortData(message: any){
        this.currentSortKey = message.sortKey;
        this.sortedAsc = message.sortedAsc;
    }

    /**
     * Updates the active error types when the filter is updated
     * @param data
     */
    handleErrorTypeChange(data: any){
        this.activeErrorTypes = data.errorType;
    }

    /**
     * Fetches information for the currently selected alert to disaply
     */
    get currentScreenData(){
        if(this.selectedAlert == null){
            return null;
        }
        return getErrorRecord(this.selectedAlert);
    }

    /**
     * Shows the pump error modal when a table row is clicked on
     * @param index
     */
    handleRowClick(index: number){
        if(this.errorReportData == null){
            return;
        }


        const recordIndex = this.errorReportData.alertRecords.findIndex(alert => alert.id === index);
        this.selectedIndex = recordIndex;
        this.selectedAlert = recordIndex >= 0 ? this.errorReportData.alertRecords[this.selectedIndex] : null;
        if(this.selectedAlert !== null) {
            this.showPumpScreenModal();
        }
    }

    /**
     * Shows the pump screen modal
     */
    showPumpScreenModal() {
      this.pumpScreenModalShowing = true;
      this.changeModalState(true)
    }

    /**
     * Hides the pump screen modal
     */
    hidePumpScreenModal() {
        this.pumpScreenModalShowing = false;
        this.changeModalState(false);
        this.selectedIndex = -1;
        this.selectedAlert = null;
    }

    /**
     * Whether there are any remaining pump errors to the left of the current alert
     */
    get hasPreviousAlert() {
        return this.selectedIndex > 0 && this.errorReportData !== null;
    }

    /**
     * Changes the selected alert to be one to the left of the current one
     */
    previousAlert() {
        if(this.hasPreviousAlert) {
            this.selectedIndex = this.selectedIndex - 1;
            this.selectedAlert = this.errorReportData!.alertRecords[this.selectedIndex]
        }
    }

    /**
     * Whether there are any remaining pump errors to the right of the current one
     */
    get hasNextAlert() {
        return this.errorReportData !== null && this.selectedIndex < this.errorReportData.alertRecords.length -1;
    }

    /**
     * Changes the selected alert to be one to the right of the current one
     */
    nextAlert() {
        if(this.hasNextAlert) {
            this.selectedIndex = this.selectedIndex + 1;
            this.selectedAlert = this.errorReportData!.alertRecords[this.selectedIndex]
        }
    }

    /**
     * Returns the description of the error passed in
     * @param alert
     */
    getErrorDescription(alert: AlertRecord){
        return getErrorDescription(alert);
    }

    /**
     * Sends the request to fetch the pump error data
     */
    async fetchData(){
        this.loading = true;
        if(this.patientMode) {
            const response = await this.patientPumpErrorReportService.fetchPatientPumpErrorReport(this.itemId, this.startDate, this.endDate);
            response.map((report) => {
                this.errorReportData = report;
            });
        } else {
            const response = await this.connectErrorReportService.fetchErrorReportForPump(this.startDate, this.endDate, this.itemId);
            response.map((report) => {
                this.errorReportData = report;
            });
        }
        this.loading = false;
    }

    /**
     * Sends the request to download the pump errors report as an excel document
     */
    async exportReportAsExcel() {
        if(this.patientMode) {
            const response = await this.patientPumpErrorReportService.exportPumpErrorReportAsExcel(this.itemId, this.startDate, this.endDate, {
                includedScreens: this.activeErrorScreens
            });
            if (response.isSuccess()) {
                this.$addSnackbarMessage({
                    message: 'Patient pump error report exported successfully'
                })
            } else {
                this.$addSnackbarMessage({
                    message: 'There was an issue exporting the report. Please check back later or contact customer support.'
                })
            }
        } else {
            const response = await this.connectErrorReportService.exportErrorReportAsExcel(this.startDate, this.endDate, this.itemId, {
                includedScreens: this.activeErrorScreens
            });
            if (response.isSuccess()) {
                this.$addSnackbarMessage({
                    message: 'Pump error report exported successfully'
                })
            } else {
                this.$addSnackbarMessage({
                    message: 'There was an issue exporting the report. Please check back later or contact customer support.'
                })
            }
        }
    }

    /**
     * Sends the request to download the pump errors report as a PDF
     */
    async exportReportAsPdf() {
        if(this.patientMode) {
            const response = await this.patientPumpErrorReportService.exportPumpErrorReportAsPdf(this.itemId, this.startDate, this.endDate, {
                includedScreens: this.activeErrorScreens
            });
            if (response.isSuccess()) {
                this.$addSnackbarMessage({
                    message: 'Patient pump error report exported successfully'
                })
            } else {
                this.$addSnackbarMessage({
                    message: 'There was an issue exporting the report. Please check back later or contact customer support.'
                })
            }
        } else {
            const response = await this.connectErrorReportService.exportErrorReportAsPdf(this.startDate, this.endDate, this.itemId, {
                includedScreens: this.activeErrorScreens
            });
            if (response.isSuccess()) {
                this.$addSnackbarMessage({
                    message: 'Pump error report exported successfully'
                })
            } else {
                this.$addSnackbarMessage({
                    message: 'There was an issue exporting the report. Please check back later or contact customer support.'
                })
            }
        }
    }
}
