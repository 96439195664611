














import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import Modal from '@/components/modal/Modal.vue';
import {ModalMutations} from '@/vuex/modal';
import {HubActions} from '@/vuex/hubs';
import {namespace} from 'vuex-class';
import ConfirmationModal from '@/components/modal/ConfirmationModal.vue';

const hubs = namespace('hubs');

/**
 * Renders the modal asking for confirmation about disabling a hub
 */
@Component({
    components: {
        ConfirmationModal
    }
})
export default class DisableWirelessModal extends Vue {

    /**
     * ID of the hub being disabled
     */
    @Prop() hubId!: number | null;

    /**
     *  Serial number of the hub
     */
    @Prop({default: ''}) serialNumber!: string;

    /**
     * Whether the modal is currently showing
     */
    @Prop({type: Boolean, default: false}) modalShowing!: boolean;
    @hubs.Action(HubActions.DISABLE_LIST_WIRELESS) disableWirelessAction!:(data: number) => any;

    modalLoading = false;


    /**
     * When the modal showing property changes, update the state of the modal backdrop
     * @param newVal
     * @param oldVal
     */
    @Watch('modalShowing')
    modalStateChanged(newVal: boolean, oldVal: boolean){
        if(newVal) {
            this.$store.commit('modal/' + ModalMutations.CHANGE_MODAL_STATE, true);
        }else{
            this.$store.commit('modal/' + ModalMutations.CHANGE_MODAL_STATE, false);
        }
        this.modalLoading = false;
    }

    /**
     * Closes the modal
     */
    dismissModal(){
        this.closeModal();
    }

    /**
     * Emits an event to close the modal
     */
    closeModal(){
        this.$emit('closeModal');
    }

    /**
     * Sends the request to disable the hub
     */
    async disableWireless(){
        this.modalLoading = true;
        await this.disableWirelessAction(this.hubId as number);
        this.$addSnackbarMessage({
            message: 'Wireless hub status successfully updated',
        });
        this.closeModal();
    }
}
