






















































































import {Component, Prop, Vue} from 'vue-property-decorator';
import Modal from '../modal/Modal.vue';
import {MARRSPump} from '@/models/Devices/MARRS/MARRSPump';
import {Patient, PatientDeviceAssignment, PatientDeviceAssignmentCreate} from '../../models/patients/Patient';
import {ButtonType} from '../form/FormTypes';
import Multiselect from 'vue-multiselect';
import {required} from 'vuelidate/lib/validators';
import moment, {Moment} from 'moment';
import {convertToMomentTimezone, formatMomentDateOnly} from '@/ts/TimezoneUtils';
import PatientDeviceAssignmentService from '@/services/patients/PatientDeviceAssignmentService';
import {PatientService} from '@/services/patients/PatientService';
import {CustomerDeviceAssociation} from '@/models/customers/CustomerDeviceAssociation';
import EditAssignmentTableFromDevice from '@/components/patients/assignments/EditAssignmentTableFromDevice.vue';

interface DateOpt {
    start: Date | null;
    end: Date | null;
}

enum Tabs {
    ASSIGN_PUMP,
    EDIT_ASSIGNMENTS
}

/**
 * Renders a modal to manage the assignments for a specific device
 */
@Component({
    components: {
        EditAssignmentTableFromDevice,
        Modal,
        Multiselect,
    },
    validations: {
        assignmentStartDate: {
            required
        }
    }
})
export default class PatientAssignmentModal extends Vue {
    /**
     * Current pump
     */
    @Prop() pump!: MARRSPump;

    /**
     * List of customer claims for the device
     */
    @Prop() claims!: CustomerDeviceAssociation[];

    /**
     * Currently selected patient
     */
    selectedPatient: Patient | null = null;
    buttonTypes = ButtonType;

    /**
     * Whether a past assignment is being created
     */
    forcePastAssignment = false;

    /**
     * List of patients that can be assigned
     */
    patients: Patient[] = [];

    /**
     * Currently selected assignment dates
     */
    assignmentStartDate: null | Date = null;

    /**
     * End date of the selected assignment
     */
    assignmentEndDate: null | Date = null;

    /**
     * If a start date is picked that does not have a set of days available between it
     * and today, this will be the max possible date for the end date
     */
    maxEndDate: null | Date = null;
    today = moment.tz(this.$store.state.auth.loggedInUser.timezone).startOf('day');

    patientDeviceAssignmentService = new PatientDeviceAssignmentService(this.$store.state.auth.loggedInUser.timezone);
    patientService = new PatientService(this.$store.state.auth.loggedInUser.timezone);

    tabOptions = Tabs;

    selectedTab = Tabs.ASSIGN_PUMP;

    async mounted() {
        const response = await this.patientService.fetchAllPatientsWithAssignments();
        if(response.isSuccess()) {
            response.map((patients) => {
                this.patients = patients;
            })
        }
    }

    /**
     * Updates the patient information when the selected patient changes
     * @param patient
     */
    handlePatientChange(patient: Patient) {
        this.selectedPatient = patient;
        this.assignmentStartDate = null;
        const currentAssignment = this.pump?.assignments?.find((assignment) => assignment.endDate === null);
        this.forcePastAssignment = (currentAssignment !== undefined && currentAssignment.patient !== undefined && this.selectedPatient.id === currentAssignment.patient.id);

        if(!this.forcePastAssignment) {
            this.assignmentStartDate = this.today.clone().add(1, 'days').startOf('day').toDate()
        }
    }

    handleStartDateUpdate(data: Date) {
        this.assignmentStartDate = data;
        this.assignmentEndDate = null;
        this.maxEndDate = null;

        const timezone = this.$store.state.auth.loggedInUser.timezone;
        const assignments: PatientDeviceAssignment[] = [];

        if(this.pump.assignments !== undefined && this.pump.assignments.length > 0) {
            assignments.push(... this.pump.assignments)
        }

        if(this.selectedPatient !== null && this.selectedPatient.assignments.length > 0) {
            assignments.push(... this.selectedPatient.assignments)
        }

        assignments.sort((a: PatientDeviceAssignment, b: PatientDeviceAssignment) => {
            return (a.startDate as Moment).diff((b.startDate as Moment))
        })

        const currentStartDate = convertToMomentTimezone(timezone, this.assignmentStartDate.toISOString()).startOf('day')
        for(let i = 0; i < assignments.length; i++) {
            if((assignments[i].startDate as Moment).isBetween(currentStartDate, this.today) || (assignments[i].startDate as Moment).isSameOrAfter(this.today)) {
                this.maxEndDate = (assignments[i].startDate as Moment).endOf('day').toDate();
                if(!this.forcePastAssignment) {
                    this.forcePastAssignment = true;
                }
                break;
            }
        }
    }

    /**
     * Gathers the array of date ranges that are currently disabled
     * @param assignmentId
     */
    getDisabledDates(assignmentId: number) {
        const dates: DateOpt[] = [];
        if(this.pump.assignments !== undefined) {
            this.pump.assignments.forEach((assignment) => {
                if(assignment.id !== assignmentId) {
                    dates.push(this.processAssignment(assignment))
                }
            });
        }


        if(this.selectedPatient !== null && this.selectedPatient.assignments !== undefined && this.selectedPatient.assignments.length > 0) {
            this.selectedPatient.assignments.forEach((assignment) => {
                if(assignment.id !== assignmentId) {
                    dates.push(this.processAssignment(assignment));
                }
            })
        }
        return dates;
    }

    /**
     * Extracts the start/end date of an assignment
     * @param assignment
     */
    processAssignment(assignment: PatientDeviceAssignment) {
        if(assignment.endDate !== null){
            return {
                start: (assignment.startDate as Moment).toDate(),
                end: (assignment.endDate as Moment).toDate()
            };
        } else if(this.forcePastAssignment) {
            return {
                start: (assignment.startDate as Moment).toDate(),
                end: moment.tz(this.$store.state.auth.loggedInUser.timezone).toDate()
            }
        } else {
            return {
                start: (assignment.startDate as Moment).toDate(),
                end: (assignment.startDate as Moment).toDate()
            }
        }
    }

    /**
     * Whether the selected patient has an active assignment
     */
    get patientHasCurrentAssignment() {
        return this.selectedPatient != null && this.selectedPatient.assignments !== undefined && this.selectedPatient.assignments.filter((assignment) => assignment.endDate === null).length > 0;
    }

    /**
     * Whether the current device has an active assignment
     */
    get deviceHasCurrentAssignment() {
        return this.pump.assignments !== undefined && this.pump.assignments.filter((assignment) => assignment.endDate === null).length > 0;
    }

    get shouldShowWarningIcon() {
        return (this.patientHasCurrentAssignment && this.selectedPatient !== null && this.assignmentStartDate !== null && !this.forcePastAssignment) ||
            (this.deviceHasCurrentAssignment && this.assignmentStartDate !== null && !this.forcePastAssignment)
    }

    /**
     * Formats the end date of the previous assignment
     */
    get oldAssignmentEnd() {
         if(this.assignmentStartDate !== null) {
            const date = convertToMomentTimezone(this.$store.state.auth.loggedInUser.timezone, this.assignmentStartDate.toISOString()).subtract(1, 'day').endOf('day');
            return formatMomentDateOnly(date)
        }
    }

    /**
     * Creates the options for the new assignment date picker
     */
    get startDatePickerOptions() {
        const currentClaim = this.claims.find((claim) => claim.endDate === null);
        if(this.selectedPatient === null || this.selectedPatient.assignments === undefined || currentClaim === undefined || this.pump.assignments === undefined) {
            return {
                popover: {
                    visibility: this.selectedPatient !== null ? 'hover-focus' : 'hidden', placement: 'bottom'
                },
            };
        }
        let minDate: Date | null = (currentClaim.id.startDate as Moment).toDate();
        // if(this.forcePastAssignment) {
        //     minDate = (currentClaim.id.startDate as Moment).toDate();
        // }else if (this.deviceHasCurrentAssignment && this.patientHasCurrentAssignment) {
        //     minDate = (this.pump.assignments[0].startDate as Moment).isAfter(this.selectedPatient.assignments[0].startDate as Moment) ?
        //         (this.pump.assignments[0].startDate as Moment).toDate() :
        //         (this.selectedPatient.assignments[0].startDate as Moment).toDate();
        // } else if(this.deviceHasCurrentAssignment) {
        //     minDate = (this.pump.assignments[0].startDate as Moment).toDate()
        // } else if(this.patientHasCurrentAssignment) {
        //     minDate = (this.selectedPatient.assignments[0].startDate as Moment).isAfter(currentClaim.id.startDate as Moment) ?
        //         (this.selectedPatient.assignments[0].startDate as Moment).toDate() :
        //         (currentClaim.id.startDate as Moment).toDate();
        // } else {
        //     minDate = (currentClaim.id.startDate as Moment).toDate();
        // }

        let maxDate: Date = this.today.clone().add(1, 'days').startOf('day').toDate();

        if (this.forcePastAssignment) {
            maxDate = this.today.clone().toDate();
        }

        return {
            disabledDates: [...this.getDisabledDates(0)],
            mode: 'single',
            popover: {
                placement: 'bottom'
            },
            minDate,
            maxDate
        }
    }

    get endDatePickerOptions() {
        if(this.assignmentStartDate === null || this.selectedPatient === null) {
            return {
                mode: 'single',
                popover: {
                    visibility: this.selectedPatient !== null ? 'hover-focus' : 'hidden', placement: 'bottom'
                },
            };
        }

        let maxDate: Date = this.today.clone().add(1, 'days').startOf('day').toDate();

        return {
            disabledDates: [...this.getDisabledDates(0)],
            mode: 'single',
            popover: {
                placement: 'bottom'
            },
            minDate: this.assignmentStartDate,
            maxDate: this.maxEndDate !== null ? this.maxEndDate : maxDate
        }
    }

    /**
     * Sends the request to create a new assignment
     */
    async createNewAssignment(){
        if(this.selectedPatient === null || this.assignmentStartDate === null) {
            return;
        }

        let assignmentInfo: PatientDeviceAssignmentCreate = {
            deviceId: this.pump.id,
            startDate: convertToMomentTimezone(this.$store.state.auth.loggedInUser.timezone, this.assignmentStartDate.toISOString()).startOf('day'),
            endDate: this.assignmentEndDate !== null ? moment.tz(this.assignmentEndDate, this.$store.state.auth.loggedInUser.timezone).endOf('day') : null
        };


        if(this.patientHasCurrentAssignment && !this.forcePastAssignment){
            const patientAssignmentEndResponse = await this.patientDeviceAssignmentService.updateAssignment(this.selectedPatient.id as number, {
                id: this.selectedPatient.assignments[0].id,
                startDate: this.selectedPatient.assignments[0].startDate,
                endDate: (assignmentInfo.startDate as Moment).clone().subtract(1, 'day').endOf('day')
            });
            if(patientAssignmentEndResponse.isFailure()) {
                return;
            }
        }


        if(this.deviceHasCurrentAssignment && !this.forcePastAssignment) {
            if(this.pump.assignments === undefined || this.pump.assignments[0].patient === null || this.pump.assignments[0].patient === undefined) {
                return;
            }
            const deviceAssignmentEndResponse = await this.patientDeviceAssignmentService.updateAssignment(this.pump.assignments[0].patient.id as number, {
                id: this.pump.assignments[0].id,
                startDate: this.pump.assignments[0].startDate as Moment,
                endDate: (assignmentInfo.startDate as Moment).clone().subtract(1, 'day').endOf('day')
            });
            if(deviceAssignmentEndResponse.isFailure()) {
                return;
            }
        }

        const response = await this.patientDeviceAssignmentService.createAssignment(this.selectedPatient.id as number, assignmentInfo);

        response.map((assignment) => {
            assignment.patient = this.selectedPatient as Patient;
            this.$emit('newAssignment', assignment);
        });
        this.selectedPatient = null;
        this.assignmentStartDate = null;
        this.assignmentEndDate = null;
        if(this.$v.assignmentStartDate !== undefined) {
            this.$v.assignmentStartDate.$reset();
        }
        const patientInfoResponse = await this.patientService.fetchAllPatientsWithAssignments();
        if(patientInfoResponse.isSuccess()) {
          patientInfoResponse.map((patients) => {
            this.patients = patients;
          })
        }
        this.selectedTab = Tabs.EDIT_ASSIGNMENTS
    }

    createMultiselectLabel(patient: Patient) {
        return `${patient.firstName} ${patient.lastName}`
    }
}
