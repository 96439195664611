import {Optional} from '@/ts/utilities';
import {Weight} from '@/models/patients/Patient';
import {AsyncEither, AsyncNullSuccess, Either, Failure, nullSuccess, Success} from '@/errors/Either';
import {Error} from '@/errors/Error';
import {Moment} from 'moment';
import axios from 'axios';


export default class WeightService {
    private readonly timezone: string;

    constructor(timezone: string) {
        this.timezone = timezone;
    }

    /**
     * Creates a new weight for a patient
     * @param patientId ID of the patient
     * @param weight Weight info
     */
    public async createWeight(patientId: number, weight: Optional<Weight>): AsyncEither<Weight, Error> {
        try {
            if (weight.date !== null) {
                weight.date = (weight.date as Moment).milliseconds(0).utc();
            }
            // tslint:disable-next-line:max-line-length
            const {data}: {data: Weight} = await axios.post(process.env.VUE_APP_BACKEND_URI + '/patients/' + patientId + '/weights', weight);
            return new Success(data);
        } catch (e) {
            return new Failure({
                message: 'Unable to create goal weight',
            });
        }
    }

    /**
     * Updates an existing weight for a patient
     * @param patientId ID of the patient
     * @param weight Weight info
     */
    public async updateWeight(patientId: number, weight: Weight): AsyncNullSuccess<Error> {
        try {
            if (weight.date !== null) {
                weight.date = (weight.date as Moment).milliseconds(0).utc();
            }
            // tslint:disable-next-line:max-line-length
            await axios.patch(process.env.VUE_APP_BACKEND_URI + '/patients/' + patientId + '/weights/' + weight.id, weight);
            return nullSuccess();
        } catch (e) {
            return new Failure({
                message: 'Unable to update weight',
            });
        }
    }

    /**
     * Deletes a weight for a patient
     * @param patientId ID of the patient
     * @param weightId
     */
    public async deleteWeight(patientId: number, weightId: number): AsyncNullSuccess<Error> {
        try {
            // tslint:disable-next-line:max-line-length
            await axios.delete(process.env.VUE_APP_BACKEND_URI + '/patients/' + patientId + '/weights/' + weightId);
            return nullSuccess();
        } catch (e) {
            return new Failure({
                message: 'Unable to delete weight',
            });
        }
    }
}
