













import { Component, Vue, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {RadioButtonData} from "@/components/form/FormTypes";
import RadioButton from '@/components/form/RadioButton.vue';
import FormValidationMixin from '@/components/form/FormValidationMixin';

/**
 * Renders a group of radio buttons
 */
@Component({
    components: {
        'RadioButton': RadioButton
    },
})
export default class RadioButtonGroup extends mixins(FormValidationMixin) {
    /**
     * Array of radio button options
     */
    @Prop() radioButtonData!: RadioButtonData[];

    /**
     * The current value of the radio button field
     */
    @Prop() value!: string;

    /**
     * Whether the radio buttons should be displayed inline
     */
    @Prop(Boolean) inline!: boolean;

    /**
     * Extra help messages to display in teh field
     */
    @Prop({default: () => {return []}}) extraMessages!: string[];

    /**
     * Whether the field is capable of having error messages
     */
    @Prop({default: true}) hasErrorMessages!: boolean;

    @Prop({default: true}) showLabel!: boolean;

    /**
     * Whether the radio button group is disabled
     */
    @Prop({default: false}) disabled!: boolean;
}
