






































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import Multiselect from 'vue-multiselect';
import {ButtonType, RadioButtonData} from '@/components/form/FormTypes';
import {Team} from '@/models/Teams';
import CreateTeamModal from '@/components/teams/CreateTeamModal.vue';
import {ModalMutations} from '@/vuex/modal';
import {namespace} from 'vuex-class';
import TeamService from '@/services/TeamService';
import {Attribute} from '@/ts/permissions/Attribute';
import HelpModal from '@/components/help/HelpModal.vue';

const modal = namespace('modal');

/**
 * Renders a card for which teams the user is assigned to
 */
@Component({
    components: {
        Multiselect,
        CreateTeamModal,
        HelpModal,
    }
})
export default class TeamSelector extends Vue {

    /**
     * Any teams the user is currently assigned to
     */
    @Prop() selectedTeams?: any;

    /**
     * Current radio button group selection
     */
    @Prop() teamGroup!: number;

    /**
     * Whether the team selector is disabled
     */
    @Prop({default: false}) disabled!: boolean;

    @Prop({default: () => []}) customerUserTeams!: Team[];

    @Prop({default: null}) customerSystemTeam!: Team | null;

    /**
     * List of teams the user can be assigned to
     */
    teams: Team[] = [];

    /**
     * Reference to the all patients team for a customer
     */
    systemTeam: Team | null = null;

    @modal.Mutation(ModalMutations.CHANGE_MODAL_STATE)showModalBackground!: (state: boolean) => void;

    buttonTypes = ButtonType;

    /**
     * Whether the create team modal is showing
     */
    createTeamModalShowing = false;

    teamService = new TeamService();

    async mounted(){
      if(this.$hasAttribute(Attribute.REQUIRES_CUSTOMER)) {
        await this.fetchAllTeams();
      } else {
        this.teams = this.customerUserTeams;
        this.systemTeam = this.customerSystemTeam;
      }
    }

    /**
     * Radio button options for controlling which options are shown
     */
    get teamRadioOptions(): RadioButtonData[] {
      return [
        {
          label: "No Patient Access (default)",
          value: 0
        },
        {
          label: "Access all available patients",
          value: 1
        },
        {
          label: "Access patients in a team",
          value: 2
        }
      ];
    }

    @Watch('customerUserTeams')
    customerTeamsChanged() {
      this.teams = this.customerUserTeams
    }

    @Watch('customerSystemTeam')
    customerSystemTeamChanged() {
      this.systemTeam = this.customerSystemTeam
    }

    /**
     * Emits an event when the data for a form field is updated
     * @param field
     * @param data
     */
    emitEvent(field: string, data: any){
        this.$emit(field, data);
    }

    /**
     * Fetches a list of all teams
     */
    async fetchAllTeams(){
        if(this.disabled) {
            return;
        }
        const response = await this.teamService.fetchAllTeams()
        response.map((data) => {
            this.teams = data.userTeams;
            this.systemTeam = data.systemTeam;
        })
    }

    /**
     * Fetches the list of teams and dismisses the create team modal
     */
    async reloadTeams(){
        await this.fetchAllTeams();
        this.dismissCreateTeamModal();
    }

    /**
     * Shows the create team modal
     */
    showCreateTeamModal(){
        this.createTeamModalShowing = true;
        this.showModalBackground(true);
    }

    /**
     * Hides the create team modal
     */
    dismissCreateTeamModal(){
        this.createTeamModalShowing = false;
        this.showModalBackground(false);
    }

    get assignedTeams() {
        if(this.selectedTeams !== undefined) {
            return (this.selectedTeams as Team[]).filter((team) => team.type === 1);
        }

        return [];
    }
}
