






























import {Component, Prop, Vue} from "vue-property-decorator";
import {Moment} from "moment";
import {formatMoment, formatMomentDateOnly} from "@/ts/TimezoneUtils";
import {ButtonType} from "@/components/form/FormTypes";

/**
 * Shows the status of a customer and provides a button to manage the status if the user
 * has the permission
 */
@Component
export default class CustomerStatus extends Vue {
    /**
     * Date the customer was created
     */
    @Prop() createdDate!: Moment;

    /**
     * Current status of the customer
     * 0 = Enabled, all other values = disabled
     */
    @Prop() status!: number;
    buttonTypes = ButtonType;

    /**
     * Formats the created date into a readable string
     */
    get formattedDate(){
        return formatMomentDateOnly(this.createdDate);
    }
}
