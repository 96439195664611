




























import {Component, Prop, Vue} from 'vue-property-decorator';
import MARRSPumpScreen from '../../MARRS/monitor-mode/MARRSPumpScreen.vue';
import Modal from '../../modal/Modal.vue';
import {MonitorModeRecord} from '@/models/MARRS/MonitorMode/MonitorModeData';
import {ButtonType} from '../../form/FormTypes';
import WirelessDiagnosticsModal from '@/components/devices/marrs/WirelessDiagnosticsModal.vue';
import {namespace} from 'vuex-class';
import {ModalMutations} from '@/vuex/modal';
import Spinner from '@/components/form/Spinner.vue';
import ConfirmationModal from '@/components/modal/ConfirmationModal.vue';
import ConnectMonitorModeService from '@/services/devices/connect/ConnectMonitorModeService';
import {InactivityActions} from '@/vuex/inactivity';

const modal = namespace('modal');
const inactivity = namespace('inactivity');

/**
 * Renders the monitor mode component for a specific pump
 */
@Component({
    components: {
        MARRSPumpScreen,
        ConfirmationModal,
        WirelessDiagnosticsModal,
        Spinner
    }
})
export default class MonitorPump extends Vue {

    /**
     * Whether the pump is currently connected
     */
    @Prop() connected!: boolean;

    /**
     * Whether the pump connection is currently pending
     */
    @Prop() connectionPending!: boolean;

    /**
     * ID of the device being monitored
     */
    @Prop() deviceId!: number;
    @modal.Mutation(ModalMutations.CHANGE_MODAL_STATE) changeModalState!: (modalState: boolean) => void;
    @inactivity.Action(InactivityActions.EXTEND_TIMER) extendTimer!: () => void;

    /**
     * Current monitor mode record
     */
    monitorModeRecord: MonitorModeRecord | null = null;
    buttonType = ButtonType;

    /**
     * Whether a monitor session is currently active
     */
    inSession = false;

    /**
     * Count of the number of consecutive monitor sessions
     */
    sessionCount = 0;

    /**
     * ID of the timer used to periodically restart the monitor pump session
     */
    timerId: number | null = null;

    /**
     * ID of the timer used to countdown the last 30 seconds of the 5 minute session
     */
    countdownTimerId: number | null = null;

    /**
     * ID of the timer used to periodically request the pump's screen
     */
    fetchRecordTimerId: number | null = null;

    /**
     * Whether the countdown modal is currently being shown
     */
    countdownModalShowing = false;
    connectMonitorModeService = new ConnectMonitorModeService();

    /**
     * Number of seconds remaining on the countdown
     */
    secondsRemaining = 30;

    /**
     * Number of consecutive failures to fetch the updated pump screen
     */
    failureCount = 0;

    /**
     * Whether the wireless diagnostic modal is showing
     */
    showingWirelessDiagnosticsModal = false;

    /**
     * Whether the request to fetch the pump screen is currently loading
     */
    loading = false;

    mounted() {
        if(this.$route.query.hasOwnProperty('autostart') && this.$route.query.autostart === "1") {
            this.startMonitorMode()
        }
    }

    /**
     * Sends the request to start a monitor mode session.
     * Starts a timer to refresh the session every 90 seconds for a total session length of 5 minutes
     * Starts a timer to refresh the pump screen every 5 seconds
     */
    async startMonitorMode(){
        this.failureCount = 0;
        this.loading = true;
        const result = await this.connectMonitorModeService.startMonitorMode(this.deviceId);
        if(result.isSuccess()){
            this.sessionCount++;
            this.inSession = true;
            if(this.sessionCount < 3){
                this.timerId = setTimeout(this.startMonitorMode, 90000);
            }else if(this.sessionCount === 3){
                this.timerId = setTimeout(this.showModal, 90000)
            }

            if(this.fetchRecordTimerId === null){
                this.fetchRecordTimerId = setInterval(this.fetchLatestRecord, 5000);
            }
        }
    }

    /**
     * Shows the countdown modal when there are 30 seconds remaining on the monitor session
     */
    showModal(){
        this.secondsRemaining = 30;
        this.countdownModalShowing = true;
        this.changeModalState(true);
        this.countdownTimerId = setInterval(() => {
            this.secondsRemaining -= 1;
            if(this.secondsRemaining <= 0 && this.countdownTimerId !== null){
                this.cancelCountdown(false);
            }
        }, 1000)
    }

    /**
     * Sends the request to fetch the latest pump monitor record
     * If three consecutive failures are observed, the session is terminated automatically
     */
    async fetchLatestRecord(){
        const result = await this.connectMonitorModeService.getLatestMonitorRecord(this.deviceId);
        const vm = this;
        if(result.isSuccess()){
            this.loading = false;
            result.map((record) => vm.monitorModeRecord = record.monitorStatusRecord);
        }else{
            this.failureCount++;
            if(this.failureCount >= 3){
                this.inSession = false;
                this.loading = false;
                if(this.timerId !== null){
                    clearTimeout(this.timerId);
                    this.timerId = null;
                }
                if(this.fetchRecordTimerId !== null){
                    clearInterval(this.fetchRecordTimerId);
                    this.fetchRecordTimerId = null;
                }
            }

        }
    }

    /**
     * Closes the countdown modal
     * @param shouldContinue Whether the 5 minute monitor session should start over at 0
     */
    cancelCountdown(shouldContinue: boolean){
        if(this.countdownTimerId !== null){
            clearInterval(this.countdownTimerId);
            this.countdownTimerId = null;
        }

        if(shouldContinue){
            this.sessionCount = 0;
            this.extendTimer()
            this.startMonitorMode();
        }else {
            this.stopMonitorMode();
        }

        if(this.countdownModalShowing) {
            this.countdownModalShowing = false;
            this.changeModalState(false);
        }
    }

    /**
     * Sends the request to stop monitor mode
     */
    async stopMonitorMode(){
        if(this.timerId !== null){
            clearTimeout(this.timerId);
            this.timerId = null;
        }
        if(this.fetchRecordTimerId != null){
            clearInterval(this.fetchRecordTimerId);
            this.fetchRecordTimerId = null;
        }
        await this.connectMonitorModeService.stopMonitorMode(this.deviceId);
        this.sessionCount = 0;
        this.inSession = false;
        this.monitorModeRecord = null;
    }

    /**
     * Shows the wireless diagnostic modal
     */
    showWirelessDiagnosticsModal() {
        this.showingWirelessDiagnosticsModal = true;
        this.changeModalState(true);
    }

    /**
     * Hides the wireless diagnostics modal
     */
    hideWirelessDiagnosticsModal() {
        this.showingWirelessDiagnosticsModal = false;
        this.changeModalState(false);
    }

    beforeDestroy() {
        if(this.inSession || this.timerId !== null || this.fetchRecordTimerId !== null) {
            this.stopMonitorMode()
        }
    }
}
