import {PatientDeviceAssignment} from '@/models/patients/Patient';
import {CustomerDeviceAssociation} from '@/models/customers/CustomerDeviceAssociation';
import {MARRSPump} from '@/models/Devices/MARRS/MARRSPump';
import {Moment} from 'moment';
import {Customer} from '@/models/customers/Customer';

/**
 * Represents a generic device
 */
export interface Device {
    id: number;
    serialNumber: string;
    connectionId: string;
    deviceType: number;
    notes: DeviceNotes | null;
    assignments?: PatientDeviceAssignment[];
    customerDeviceAssociations?: CustomerDeviceAssociation[];
}

/**
 * Notes that can associated with a device
 */
export interface DeviceNotes {
    id: number;
    notes: string;
}

/**
 * Represents a device that can be assigned to a patient
 */
export interface AssignableDeviceDto {
    device: MARRSPump;
    pendingRegistration: boolean;
    earliestAssignableDate: string | Moment;
    serialNumber?: string;
    id?: number;
}

/**
 * Represents a device that is pending customer registration
 */
export interface PumpRegistrationQueueEntry {
    device: MARRSPump;
    customer?: Customer;
    addedAt: string | Moment;
}

export interface CustomerPumpRegistrationViewResponse {
    unRegisteredDevices: PumpRegistrationQueueEntry[];
    registeredDevices: PumpRegistrationQueueEntry[];
}

/**
 * Status of attempts to register a pump
 */
export enum DeviceRegistrationStatus {
    REGISTERED = 0,
    ALREADY_REGISTERED = 1,
    ALREADY_REGISTERED_BY_OTHER = 2,
    ADDED_TO_QUEUE = 3,
    ALREADY_IN_QUEUE = 4,
    ALREADY_IN_OTHER_QUEUE = 5,
}

/**
 * Represents the result of trying to register a device
 */
export interface PumpRegistrationResponse {
    status: DeviceRegistrationStatus;
}
