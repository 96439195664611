






















































import {Component, Vue} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import HubTable from '@/components/devices/hubs/HubTable.vue';
import {ButtonType} from '@/components/form/FormTypes';
import {Permission} from '@/ts/permissions/Permission';
import Modal from '@/components/modal/Modal.vue';
import {Attribute} from '@/ts/permissions/Attribute';
import {Customer} from '@/models/customers/Customer';
import CustomerService from '@/services/CustomerService';
import Multiselect from 'vue-multiselect';
import WirelessHubService from '@/services/devices/hubs/WirelessHubService';
import {SnackbarActions} from '@/vuex/snackbar';
import {SnackbarRequest} from '@/models/Snackbar';
import {ModalMutations} from '@/vuex/modal';
import {HubListRequest} from '@/models/Devices/Hubs/Hub';
import HelpModal from '@/components/help/HelpModal.vue';
import Spinner from '@/components/form/Spinner.vue';

const hubs = namespace('hubs');
const snackbar = namespace('snackbar');
const modal = namespace('modal');

Component.registerHooks([
    'beforeRouteEnter',
    'beforeRouteLeave',
    'beforeRouteUpdate'
]);

/**
 * Renders a list of hubs the user has access to
 */
@Component({
    components: {
        HubTable: HubTable,
        Modal,
        Multiselect,
        HelpModal,
        Spinner,
    }
})
export default class HubList extends Vue {
    permissions = Permission;
    buttonTypes = ButtonType;
    eventBus = new Vue();

    /**
     * Whether the top list is showing
     */
    topListShowing = false;

    /**
     * Whether the claim hub modal is showing
     */
    showingClaimModal = false;

    /**
     * Serial number value for the claim hub modal
     */
    serialNumber = '';

    hubError = '';

    claimHubLoading = false;

    /**
     * List of customers to filter table by
     */
    customers: Customer[] = [];
    customerService = new CustomerService(this.$store.state.auth.loggedInUser.timezone);
    wirelessHubService = new WirelessHubService();
    selectedCustomer: Customer | null = null;
    @snackbar.Action(SnackbarActions.TRIGGER_SNACKBAR) addSnackbarMessage!: (item: SnackbarRequest) => void;
    @modal.Mutation(ModalMutations.CHANGE_MODAL_STATE) changeModalState!: (state: boolean) => void;
    hubListRequest: HubListRequest | null = null;

    async mounted() {
        if(!this.$hasAttribute(Attribute.REQUIRES_CUSTOMER) && this.$isAllowed(Permission.CUSTOMER_VIEW)) {
            this.fetchCustomers();
        }
    }

    /**
     * Whether the claim wireless hub button in the claim wireless device modal is disabled
     */
    get claimDeviceDisabled() {
       if(this.$isAllowed(this.$Permission.WIRELESS_HUB_CLAIM_BYPASS)) {
           return this.selectedCustomer === null || this.serialNumber === '';
       } else {
           return this.serialNumber === '';
       }
    }

    /**
     * Wrapper around the two hub claiming methods depending on the user's permissions
     */
    handleClaimDevice() {
        if(this.$isAllowed(this.$Permission.WIRELESS_HUB_CLAIM_BYPASS)) {
            this.claimDeviceWithBypass();
        } else {
            this.claimDevice();
        }
    }

    /**
     * Shows the claim wireless device modal
     */
    showClaimDeviceModal() {
        this.topListShowing = false;
        this.showingClaimModal = true;
        this.changeModalState(true);
        this.hubError = ''
    }

    /**
     * Hides the claim wireless device modal
     */
    hideClaimDeviceModal() {
        this.showingClaimModal = false;
        this.changeModalState(false);
        this.eventBus.$emit('reload');
    }

    /**
     * Sends the request to claim a wireless device
     * Note: This is used for the customer user roles
     */
    async claimDevice() {
        this.hubError = '';
        this.claimHubLoading = true;
        const response = await this.wirelessHubService.registerDevice(this.serialNumber);
        this.claimHubLoading = false;
        if(response.isSuccess()) {
            this.addSnackbarMessage({
                message: `Wireless Hub ${this.serialNumber} claimed successfully`
            });
            this.serialNumber = '';
            this.hideClaimDeviceModal()
        } else {
            response.mapErr(err => {
                if(err.statusCode && err.statusCode === 404) {
                    this.hubError = `Wireless Hub ${this.serialNumber} could not be found. Please check the serial number entered or contact customer support.`
                } else {
                    this.hubError = `Wireless Hub ${this.serialNumber} is claimed by another customer. Please contact customer support if you believe this is not correct.`
                    this.serialNumber = '';
                }

            })

        }
    }

    /**
     * Sends the request to claim a wireless device with the non-customer bypass
     * Note: this can only be used by non-customer users
     */
    async claimDeviceWithBypass() {
        if(this.selectedCustomer === null) {
            return;
        }

        const response = await this.wirelessHubService.registerDeviceWithBypass(this.selectedCustomer.id, this.serialNumber);
        if(response.isSuccess()) {
            this.addSnackbarMessage({
                message: `Wireless Hub ${this.serialNumber} claimed successfully`
            })
        } else {
            this.hubError = `Wireless Hub ${this.serialNumber} is claimed by another customer.`
        }
    }

    /**
     * Fetches the list of customers for filtering
     */
    async fetchCustomers() {
        const response = await this.customerService.fetchAllCustomers();
        response.map((customers) => {
            this.customers = customers;
        });
    }


    /**
     * Sends the request to export the list of wireless hubs
     */
    async exportList() {
        if(this.hubListRequest === null) {
            return;
        }
        this.topListShowing = false;
        this.$addSnackbarMessage({
            message: 'Wireless hub list export in progress',
        });
        const response = await this.wirelessHubService.exportList(this.hubListRequest);
        if(response.isSuccess()) {
            this.$addSnackbarMessage({
                message: 'Wireless hub list successfully exported'
            })
        } else {
            this.$addSnackbarMessage({
                message: 'There was an issue exporting the list of wireless hubs. Try again later or contact customer support.',
            });
        }
    }

}
