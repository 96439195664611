


























import { Component, Vue } from 'vue-property-decorator';
import HubDetails from '../../../components/devices/hubs/HubDetails.vue';
import {namespace} from 'vuex-class';
import {Hub} from "@/models/Devices/Hubs/Hub";
import {RawLocation, Route} from "vue-router";
import store from '@/store';
import {HubActions} from "@/vuex/hubs";
import ExtendedDeviceDetails from "@/components/devices/hubs/ExtendedDeviceDetails.vue";
import AssociatedPumps from '@/components/devices/hubs/AssociatedPumps.vue';
import MobileDetails from '@/components/devices/hubs/MobileDetails.vue';
import ConnectionHistory from '@/components/devices/hubs/ConnectionHistory.vue';

Component.registerHooks([
    'beforeRouteEnter',
    'beforeRouteLeave',
    'beforeRouteUpdate'
]);

const hubs = namespace('hubs');

/**
 * Shows details for a single wireless hub
 */
@Component({
    components: {
        HubDetails,
        ExtendedDeviceDetails,
        AssociatedPumps,
        MobileDetails,
        ConnectionHistory
    }
})
export default class HubView extends Vue {

    @hubs.State('currentHub') hub!: Hub;

    async beforeRouteEnter(to: Route, from: Route, next: ((to?: RawLocation | false | ((vm: HubView) => any) | void) => void)){
        await store.dispatch('hubs/'+HubActions.FETCH_HUB, to.params.id);
        next();
    }

    async beforeRouteUpdate(to: Route, from: Route, next: ((to?: RawLocation | false | ((vm: HubView) => any) | void) => void)){
        await store.dispatch('hubs/'+HubActions.FETCH_HUB, to.params.id);
        next();
    }
}
