import {
    AdjustBolus, AdjustBrightness,
    AdjustFeed, AdjustVolume,
    BaseScreenData, BiotechInfo, BiotechLanguage, BiotechOptions, BolusFed,
    BolusIncompleteData, CurrentValue, DoseFed,
    DoseIncompleteData, FeedingScreen, KTOBolus, KTOCont, ManualTestFailed,
    MonitorModeScreenData, MoreOptions, ReadyToFeedBolus, ReadyToFeedCont, ReadyToFeedDose, SystemError, ViewHistory,
} from '@/models/MARRS/MonitorMode/MonitorModeData';
import ScreenDefinitions from '@/models/MARRS/MonitorMode/ScreenDefinitions';

/**
 * Internal representation of the layout of a connect pump screen
 */
export interface ScreenData {
    type: ScreenType;
    regions: RegionContainer;
    title: string;
    buttons: ButtonMap;
}

/**
 * Map of the different pump buttons
 */
export interface ButtonMap {
    [key: number]: Button;
}

/**
 * Represents a single pump button and its state
 */
export interface Button {
    text: string;
    borderClass: string;
    backgroundClass: string;
    enabled?: (screenData: MonitorModeScreenData) => boolean;
    spanClass?: string;
    buttonIcon?: (screenData: MonitorModeScreenData) => string;
}

/**
 * Map of the differnet pump regions
 */
export interface RegionContainer {
    [key: number]: Region;
}

/**
 * Represetns a single region of the pump screen
 */
export interface Region {
    template: (screenData: MonitorModeScreenData, serialNumber?: string, language?: number) => string;
    regionClass?: (screenData: MonitorModeScreenData) => string;
    enabled?: (screenData: MonitorModeScreenData) => boolean;
}

/**
 * Different types of screens
 */
export enum ScreenType {
    STANDARD = 'standard-screen',
    BIOTECH = 'biotech-screen',
    HOLD = 'hold-screen',
    RUNNING = 'running-screen',
    WARNING = 'warning-screen',
    ERROR = 'error-screen',
    CRITICAL_ERROR = 'critical-error-screen',
}

export interface NumericalMap<TValue> {
    [key: number]: TValue;
}

const extraMessageMap: NumericalMap<string> = {
    24: `Note: The ‘Start’ button in the above display will not be shown on the pump unless a Feeding Cassette has been
    inserted and a Feeding Regimen has been completely specified by the user.  For Continuous mode, the regimen is
    completely specified if the Feed Rate is a non-zero value.  For Bolus mode, the regimen is completely specified
    if the Bolus Rate, Bolus Volume, and Bolus Interval are all non-zero values.`,
    25: `Note: The ‘Start’ button in the above display will not be shown on the pump unless a Feeding Cassette has been
    inserted and a Feeding Regimen has been completely specified by the user.  For Continuous mode, the regimen is
    completely specified if the Feed Rate is a non-zero value.  For Bolus mode, the regimen is completely specified
    if the Bolus Rate, Bolus Volume, and Bolus Interval are all non-zero values.`,
    32: `Note: The ‘Start’ button in the above display will not be shown on the pump unless a Feeding Cassette has been
    inserted and a Feeding Regimen has been completely specified by the user.  For Continuous mode, the regimen is
    completely specified if the Feed Rate is a non-zero value.  For Bolus mode, the regimen is completely specified
    if the Bolus Rate, Bolus Volume, and Bolus Interval are all non-zero values.`,
    53: `Note: The countdown which appears on the Lock Screen button while the user is pressing the button will not be
    shown in the above display due to the refresh rate but is shown on the pump.`,
    54: `Note: The countdown which appears on the Lock Screen button while the user is pressing the button will not be
    shown in the above display due to the refresh rate but is shown on the pump.`,
    61: `Note: The countdown which appears on the Lock Screen button while the user is pressing the button will not be
    shown in the above display due to the refresh rate but is shown on the pump.`,
    44: `Note: The ‘Prime Pump’ option in the above display will not be shown on the pump if a
    Feeding Cassette is not inserted.`,
    88: `Note: The ‘Back’ button in the above display will not be shown on the pump the first time it is turned on
    after shipment from the factory.  The user must select a language before continuing.`,
    89: `Note: The ‘Continue’ button in the above display will not be shown on the pump
    if a Feeding Cassette is not inserted.`,
    94: `Note: The ‘Continue’ button in the above display will not be shown on the pump
    if a Feeding Cassette is not inserted.`,
    91: `Note: The ‘Continue’ button in the above display will not be shown on the pump unless the test in process
    has completed.  ‘Wait until test ends or press Cancel’ text will not appear on pump if the process has completed.`,
};

/**
 * Map of the screen numbers to their layout definitions
 */
const screenMap: NumericalMap<ScreenData> = {
    5: {
        buttons: {
            1: {
                text: 'Adjust Rate',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
            3: {
                text: 'More Options',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
        },
        regions: {
            1: {
                template: (screenData: BaseScreenData) => ScreenDefinitions.normalSetLoaded(screenData),
            },
        },
        title: 'LOAD SET',
        type: ScreenType.STANDARD,
    },
    6: {
        buttons: {
            1: {
                text: 'Adjust Feed',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
            3: {
                text: 'More Options',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
        },
        regions: {
            1: {
                template: (screenData) => ScreenDefinitions.normalSetLoaded(screenData),
            },
        },
        title: 'LOAD SET',
        type: ScreenType.STANDARD,
    },
    7: {
        buttons: {
            1: {
                text: 'Adjust Bolus',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
            3: {
                text: 'More Options',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
        },
        regions: {
            1: {
                template: (screenData) => ScreenDefinitions.normalSetLoaded(screenData),
            },
        },
        title: 'LOAD SET',
        type: ScreenType.STANDARD,
    },
    8: {
        buttons: {
            1: {
                text: 'Adjust Rate',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
            3: {
                text: 'More Options',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
            5: {
                text: 'Prime',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
        },
        regions: {
            1: {
                template: (screenData: BaseScreenData) => ScreenDefinitions.cassetteLoaded(screenData),
            },
        },
        title: 'SET LOADED',
        type: ScreenType.STANDARD,
    },
    9: {
        buttons: {
            1: {
                text: 'Adjust Feed',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
            3: {
                text: 'More Options',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
            5: {
                text: 'Prime',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
        },
        regions: {
            1: {
                template: (screenData: BaseScreenData) => ScreenDefinitions.cassetteLoaded(screenData),
            },
        },
        title: 'SET LOADED',
        type: ScreenType.STANDARD,
    },
    10: {
        buttons: {
            1: {
                text: 'Adjust Bolus',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
            3: {
                text: 'More Options',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
            5: {
                text: 'Prime',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
        },
        regions: {
            1: {
                template: (screenData: BaseScreenData) => ScreenDefinitions.cassetteLoaded(screenData),
            },
        },
        title: 'SET LOADED',
        type: ScreenType.STANDARD,
    },
    81: {
        buttons: {
            1: {
                text: 'Adjust Feed',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
            3: {
                text: 'More Options',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
            4: {
                text: 'Skip Prime',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
            5: {
                text: 'Prime',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
        },
        regions: {
            1: {
                template: (screenData: BaseScreenData) => ScreenDefinitions.cassetteLoadedFluid(screenData),
            },
        },
        title: 'SET LOADED',
        type: ScreenType.STANDARD,
    },
    82: {
        buttons: {
            1: {
                text: 'Adjust Rate',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
            3: {
                text: 'More Options',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
            4: {
                text: 'Skip Prime',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
            5: {
                text: 'Prime',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
        },
        regions: {
            1: {
                template: (screenData: BaseScreenData) => ScreenDefinitions.cassetteLoadedFluid(screenData),
            },
        },
        title: 'SET LOADED',
        type: ScreenType.STANDARD,
    },
    83: {
        buttons: {
            1: {
                text: 'Adjust Bolus',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
            3: {
                text: 'More Options',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
            4: {
                text: 'Skip Prime',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
            5: {
                text: 'Prime',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
        },
        regions: {
            1: {
                template: (screenData: BaseScreenData) => ScreenDefinitions.cassetteLoadedFluid(screenData),
            },
        },
        title: 'SET LOADED',
        type: ScreenType.STANDARD,
    },
    13: {
        buttons: {
            5: {
                text: 'Stop',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
        },
        regions: {
            1: {
                template: (screenData: BaseScreenData) => ScreenDefinitions.autoPrime(screenData),
            },
        },
        title: 'PRIMING',
        type: ScreenType.STANDARD,
    },
    14: {
        buttons: {
            3: {
                text: 'Hold To Prime',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
        },
        regions: {
            1: {
                template: (screenData: BaseScreenData) => ScreenDefinitions.holdToPrimeNoFluid(screenData),
            },
        },
        title: 'HOLD TO PRIME',
        type: ScreenType.STANDARD,
    },
    15: {
        buttons: {
            3: {
                text: 'Hold To Prime',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
            5: {
                text: 'Done',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
        },
        regions: {
            1: {
                template: (screenData: BaseScreenData) => ScreenDefinitions.holdToPrimeFluid(screenData),
            },
        },
        title: 'HOLD TO PRIME',
        type: ScreenType.STANDARD,
    },
    18: {
        buttons: {
            3: {
                text: 'Hold To Prime',
                borderClass: '',
                backgroundClass: 'bg-marrs-dark-blue text-white',
            },
        },
        regions: {
            1: {
                template: (screenData: BaseScreenData) => ScreenDefinitions.priming(screenData),
            },
        },
        title: 'PRIMING',
        type: ScreenType.STANDARD,
    },
    21: {
        buttons: {
            2: {
                text: 'Keep Settings',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
            4: {
                text: 'Clear Settings',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
        },
        regions: {
            1: {
                template: (screenData: BaseScreenData) => ScreenDefinitions.priorSettings(screenData),
            },
        },
        title: 'PRIOR SETTINGS',
        type: ScreenType.STANDARD,
    },
    22: {
        buttons: {
            1: {
                text: 'Resume Dose',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
            3: {
                text: 'Restart Dose',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
            5: {
                text: 'Back',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
                enabled: (screenData) => !screenData.enableLockedSettings,
            },
        },
        regions: {
            1: {
                template: (screenData: BaseScreenData) => ScreenDefinitions.feedIncompleteMessage(screenData),
                regionClass: (screenData: BaseScreenData) => 'region-1-2',
            },
            3: {
                template: (screenData: MonitorModeScreenData) =>
                    ScreenDefinitions.doseLeft(screenData as DoseIncompleteData),
                regionClass: (screenData: BaseScreenData) => 'region-3',
            },
            4: {
                template: (screenData: MonitorModeScreenData) =>
                    ScreenDefinitions.doseFed(screenData as DoseIncompleteData),
                regionClass: (screenData: BaseScreenData) => 'region-4',
            },
        },
        title: 'FEED INCOMPLETE',
        type: ScreenType.STANDARD,
    },
    23: {
        buttons: {
            1: {
                text: 'Resume Boluses',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
            3: {
                text: 'Restart Boluses',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
            5: {
                text: 'Back',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
                enabled: (screenData) => !screenData.enableLockedSettings,
            },
        },
        regions: {
            1: {
                template: (screenData: BaseScreenData) => ScreenDefinitions.bolusIncomplete(screenData),
                regionClass: (screenData: BaseScreenData) => 'region-1-2-3',
            },
            4: {
                template: (screenData: MonitorModeScreenData) =>
                    ScreenDefinitions.bolusRate(screenData as BolusIncompleteData),
                regionClass: (screenData: BaseScreenData) => 'region-4',
            },
        },
        title: 'FEED INCOMPLETE',
        type: ScreenType.STANDARD,
    },
    24: {
        buttons: {
            1: {
                text: 'Adjust Rate',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
            3: {
                text: 'More Options',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
            5: {
                text: 'Start',
                borderClass: '',
                backgroundClass: 'bg-marrs-green text-white',
            },
        },
        regions: {
            1: {
                template: (screenData: MonitorModeScreenData) =>
                    ScreenDefinitions.rateDisplayCont(screenData as ReadyToFeedCont),
                regionClass: (screenData: BaseScreenData) => 'region-1-2',
            },
            3: {
                template: (screenData: MonitorModeScreenData) =>
                    ScreenDefinitions.feedTotalizer(screenData as ReadyToFeedCont),
                regionClass: (screenData: BaseScreenData) => 'region-3-4',
            },
        },
        title: 'READY TO FEED',
        type: ScreenType.STANDARD,
    },
    25: {
        buttons: {
            1: {
                text: 'Adjust Feed',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
            3: {
                text: 'More Options',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
            5: {
                text: 'Start',
                borderClass: '',
                backgroundClass: 'bg-marrs-green text-white',
            },
        },
        regions: {
            1: {
                template: (screenData: MonitorModeScreenData) =>
                    ScreenDefinitions.rateDisplayCont(screenData as ReadyToFeedCont),
                regionClass: (screenData: BaseScreenData) => 'region-1-2',
            },
            3: {
                template: (screenData: MonitorModeScreenData) => {
                    return (screenData as ReadyToFeedDose).continuousFeedDose > 0 ?
                        ScreenDefinitions.doseLeftReadyToFeed(screenData as ReadyToFeedDose) :
                        ScreenDefinitions.feedTotalizer(screenData as ReadyToFeedCont);
                },
                regionClass: (screenData: MonitorModeScreenData) =>
                    `${(screenData as ReadyToFeedDose).continuousFeedDose > 0 ? 'region-3' : 'region-3-4'}`,
            },
            4: {
                template: (screenData: MonitorModeScreenData) =>
                    ScreenDefinitions.feedTotalizerOneLine(screenData as ReadyToFeedCont),
                regionClass: (screenData: BaseScreenData) => 'region-4',
                enabled: (screenData: MonitorModeScreenData) =>
                    (screenData as ReadyToFeedDose).continuousFeedDose > 0,
            },
        },
        title: 'READY TO FEED',
        type: ScreenType.STANDARD,
    },
    32: {
        buttons: {
            1: {
                text: 'Adjust Bolus',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
            3: {
                text: 'More Options',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
            5: {
                text: 'Start',
                borderClass: '',
                backgroundClass: 'bg-marrs-green text-white',
            },
        },
        regions: {
            1: {
                template: (screenData: MonitorModeScreenData) =>
                    ScreenDefinitions.rateDisplayBolus(screenData as ReadyToFeedBolus),
                regionClass: (screenData: BaseScreenData) => 'region-1-2',
            },
            3: {
                template: (screenData: MonitorModeScreenData) => {
                    return (screenData as ReadyToFeedBolus).numberOfBoluses > 0 ?
                        ScreenDefinitions.bolusRateReadyToFeed(screenData as ReadyToFeedBolus) :
                        ScreenDefinitions.feedTotalizerBolus(screenData as ReadyToFeedBolus);
                },
                regionClass: (screenData: MonitorModeScreenData) =>
                    `${(screenData as ReadyToFeedBolus).numberOfBoluses > 0 ? 'region-3' : 'region-3-4'}`,
            },
            4: {
                template: (screenData: MonitorModeScreenData) =>
                    ScreenDefinitions.feedTotalizerSplitLineBolus(screenData as ReadyToFeedBolus),
                regionClass: (screenData: BaseScreenData) => 'region-4',
                enabled: (screenData: MonitorModeScreenData) =>
                    (screenData as ReadyToFeedBolus).numberOfBoluses > 0,
            },
        },
        title: 'READY TO FEED',
        type: ScreenType.STANDARD,
    },
    36: {
        buttons: {
            1: {
                text: 'Adjust Rate',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
            2: {
                text: 'Adjust Dose',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
            5: {
                text: 'Done',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
        },
        regions: {
            1: {
                template: (screenData: MonitorModeScreenData) =>
                    ScreenDefinitions.adjustFeedRateDisplay(screenData as AdjustFeed),
                regionClass: (screenData: BaseScreenData) => 'region-1-2',
            },
            3: {
                template: (screenData: MonitorModeScreenData) =>
                    ScreenDefinitions.adjustFeedDoseDisplay(screenData as AdjustFeed),
                regionClass: (screenData: MonitorModeScreenData) => 'region-3-4',
            },
        },
        title: 'ADJUST FEED',
        type: ScreenType.STANDARD,
    },
    37: {
        buttons: {
            1: {
                text: 'Adjust Rate',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
            2: {
                text: 'Adjust Boluses',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
            3: {
                text: 'Adjust Volume',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
            4: {
                text: 'Adjust Interval',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
            5: {
                text: 'Done',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
        },
        regions: {
            1: {
                template: (screenData: MonitorModeScreenData) =>
                    ScreenDefinitions.rateDisplayBolus(screenData as AdjustBolus),
                regionClass: (screenData: BaseScreenData) => 'region-1-2',
            },
            3: {
                template: (screenData: MonitorModeScreenData) =>
                    ScreenDefinitions.numberOfBoluses(screenData as AdjustBolus),
                regionClass: (screenData: MonitorModeScreenData) => 'region-3',
            },
            4: {
                template: (screenData: MonitorModeScreenData) =>
                    ScreenDefinitions.bolusIntervalDisplay(screenData as AdjustBolus),
                regionClass: (screenData: MonitorModeScreenData) => 'region-4',
            },
        },
        title: 'ADJUST BOLUS',
        type: ScreenType.STANDARD,
    },
    38: {
        buttons: {
            1: {
                text: '+',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
                spanClass: 'plus-button',
            },
            2: {
                text: '\u2013',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
                spanClass: 'minus-button',
            },
            4: {
                text: 'Cancel',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
            5: {
                text: 'Done',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
        },
        regions: {
            1: {
                template: (screenData: MonitorModeScreenData) =>
                    ScreenDefinitions.adjustContRate(screenData as CurrentValue),
                regionClass: (screenData: BaseScreenData) => 'region-1-2-3-4',
            },
        },
        title: 'ADJUST RATE',
        type: ScreenType.STANDARD,
    },
    39: {
        buttons: {
            1: {
                text: '+',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
                spanClass: 'plus-button',
            },
            2: {
                text: '\u2013',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
                spanClass: 'minus-button',
            },
            4: {
                text: 'Cancel',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
            5: {
                text: 'Done',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
        },
        regions: {
            1: {
                template: (screenData: MonitorModeScreenData) =>
                    ScreenDefinitions.adjustDoseRate(screenData as CurrentValue),
                regionClass: (screenData: BaseScreenData) => 'region-1-2-3-4',
            },
        },
        title: 'ADJUST DOSE',
        type: ScreenType.STANDARD,
    },
    40: {
        buttons: {
            1: {
                text: '+',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
                spanClass: 'plus-button',
            },
            2: {
                text: '\u2013',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
                spanClass: 'minus-button',
            },
            4: {
                text: 'Cancel',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
            5: {
                text: 'Done',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
        },
        regions: {
            1: {
                template: (screenData: MonitorModeScreenData) =>
                    ScreenDefinitions.adjustContRate(screenData as CurrentValue),
                regionClass: (screenData: BaseScreenData) => 'region-1-2-3-4',
            },
        },
        title: 'ADJUST RATE',
        type: ScreenType.STANDARD,
    },
    42: {
        buttons: {
            1: {
                text: '+',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
                spanClass: 'plus-button',
            },
            2: {
                text: '\u2013',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
                spanClass: 'minus-button',
            },
            4: {
                text: 'Cancel',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
            5: {
                text: 'Done',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
        },
        regions: {
            1: {
                template: (screenData: MonitorModeScreenData) =>
                    ScreenDefinitions.adjustBolusVolume(screenData as AdjustVolume),
                regionClass: (screenData: BaseScreenData) => 'region-1-2-3-4',
            },
        },
        title: 'ADJUST VOLUME',
        type: ScreenType.STANDARD,
    },
    41: {
        buttons: {
            1: {
                text: '+',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
                spanClass: 'plus-button',
            },
            2: {
                text: '\u2013',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
                spanClass: 'minus-button',
            },
            4: {
                text: 'Cancel',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
            5: {
                text: 'Done',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
        },
        regions: {
            1: {
                template: (screenData: MonitorModeScreenData) =>
                    ScreenDefinitions.adjustNumBoluses(screenData as CurrentValue),
                regionClass: (screenData: BaseScreenData) => 'region-1-2-3-4',
            },
        },
        title: 'ADJUST BOLUSES',
        type: ScreenType.STANDARD,
    },
    43: {
        buttons: {
            1: {
                text: '+',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
                spanClass: 'plus-button',
            },
            2: {
                text: '\u2013',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
                spanClass: 'minus-button',
            },
            4: {
                text: 'Cancel',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
            5: {
                text: 'Done',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
        },
        regions: {
            1: {
                template: (screenData: MonitorModeScreenData) =>
                    ScreenDefinitions.adjustBolusInterval(screenData as CurrentValue),
                regionClass: (screenData: BaseScreenData) => 'region-1-2-3-4',
            },
        },
        title: 'ADJUST INTERVAL',
        type: ScreenType.STANDARD,
    },
    44: {
        buttons: {
            1: {
                text: '',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
                buttonIcon: (screenData: BaseScreenData) => `marrs-up-caret-icon mx-auto h-6 w-6`,
            },
            2: {
                text: '',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
                buttonIcon: (screenData: BaseScreenData) => `marrs-down-caret-icon mx-auto h-6 w-6`,
            },
            4: {
                text: 'Select',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
            5: {
                text: 'Done',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
        },
        regions: {
            1: {
                template: (screenData: MonitorModeScreenData) =>
                    ScreenDefinitions.moreOptions(screenData as MoreOptions),
                regionClass: (screenData: BaseScreenData) => 'region-1-2-3-4',
            },
        },
        title: 'MORE OPTIONS',
        type: ScreenType.STANDARD,
    },
    45: {
        buttons: {
            1: {
                text: 'Yes',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
            5: {
                text: 'No',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
        },
        regions: {
            1: {
                template: (screenData: MonitorModeScreenData) =>
                    ScreenDefinitions.clearAmount(screenData as BaseScreenData),
                regionClass: (screenData: BaseScreenData) => 'region-1-2-3-4',
            },
        },
        title: 'CLEAR AMOUNT',
        type: ScreenType.STANDARD,
    },
    46: {
        buttons: {
            1: {
                text: '+',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
                spanClass: 'plus-button',
            },
            2: {
                text: '\u2013',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
                spanClass: 'minus-button',
            },
            4: {
                text: 'Cancel',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
            5: {
                text: 'Done',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
        },
        regions: {
            1: {
                template: (screenData: MonitorModeScreenData) =>
                    ScreenDefinitions.adjustBrightnessPicker(screenData as AdjustBrightness),
                regionClass: (screenData: BaseScreenData) => 'region-1-2-3-4',
            },
        },
        title: 'BRIGHTNESS',
        type: ScreenType.STANDARD,
    },
    47: {
        buttons: {
            1: {
                text: '+',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
                spanClass: 'plus-button',
            },
            2: {
                text: '\u2013',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
                spanClass: 'minus-button',
            },
            4: {
                text: 'Clear History',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
            5: {
                text: 'Done',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
        },
        regions: {
            1: {
                template: (screenData: MonitorModeScreenData) =>
                    ScreenDefinitions.withinLast(screenData as ViewHistory),
                regionClass: (screenData: BaseScreenData) => 'region-1-2 pt-1',
            },
            3: {
                template: (screenData: MonitorModeScreenData) =>
                    ScreenDefinitions.historyPumpFed(screenData as ViewHistory),
                regionClass: (screenData: BaseScreenData) => 'region-3-4',
            },
        },
        title: 'HISTORY',
        type: ScreenType.STANDARD,
    },
    48: {
        buttons: {
            1: {
                text: 'Yes',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
            5: {
                text: 'No',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
        },
        regions: {
            1: {
                template: (screenData: MonitorModeScreenData) =>
                    ScreenDefinitions.clearHistory(screenData as BaseScreenData),
                regionClass: (screenData: BaseScreenData) => 'region-1-2-3-4',
            },
        },
        title: 'CLEAR HISTORY',
        type: ScreenType.STANDARD,
    },
    49: {
        buttons: {
            5: {
                text: 'Back',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
        },
        regions: {
            1: {
                template: (screenData: MonitorModeScreenData) =>
                    ScreenDefinitions.pumpSettingsLocked(screenData as BaseScreenData),
                regionClass: (screenData: BaseScreenData) => 'region-1-2-3-4',
            },
        },
        title: 'SETTINGS LOCKED',
        type: ScreenType.STANDARD,
    },
    50: {
        buttons: {
            5: {
                text: 'Done',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
        },
        regions: {
            1: {
                template: (screenData: MonitorModeScreenData) =>
                    ScreenDefinitions.checkmark(screenData as BaseScreenData),
                regionClass: (screenData: BaseScreenData) => 'region-1-2',
            },
            3: {
                template: (screenData: MonitorModeScreenData) =>
                    ScreenDefinitions.feedCompleteDoseFed(screenData as DoseFed),
                regionClass: (screenData: BaseScreenData) => 'region-3-4',
            },
        },
        title: 'FEED COMPLETE',
        type: ScreenType.STANDARD,
    },
    51: {
        buttons: {
            5: {
                text: 'Done',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
        },
        regions: {
            1: {
                template: (screenData: MonitorModeScreenData) =>
                    ScreenDefinitions.checkmark(screenData as BaseScreenData),
                regionClass: (screenData: BaseScreenData) => 'region-1-2',
            },
            3: {
                template: (screenData: MonitorModeScreenData) =>
                    ScreenDefinitions.feedCompleteBolusFed(screenData as BolusFed),
                regionClass: (screenData: BaseScreenData) => 'region-3-4',
            },
        },
        title: 'FEED COMPLETE',
        type: ScreenType.STANDARD,
    },
    52: {
        buttons: {
        },
        regions: {
            1: {
                template: (screenData: MonitorModeScreenData) =>
                    ScreenDefinitions.poweringDown(screenData as BaseScreenData),
                regionClass: (screenData: BaseScreenData) => 'region-1-2-3-4',
            },
        },
        title: 'POWER DOWN',
        type: ScreenType.STANDARD,
    },
    85: {
        buttons: {
            1: {
                text: 'View Info',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
            2: {
                text: 'View Options',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
            3: {
                text: 'Test Pump',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
            5: {
                text: 'Restart Pump',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
        },
        regions: {
            1: {
                template: (screenData: MonitorModeScreenData) =>
                    ScreenDefinitions.biotechModeScreen(screenData as BaseScreenData),
                regionClass: (screenData: BaseScreenData) => 'region-1-2-3-4',
            },
        },
        title: 'BIOTECH MODE',
        type: ScreenType.BIOTECH,
    },
    86: {
        buttons: {
            5: {
                text: 'Back',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
        },
        regions: {
            1: {
                template: (screenData: MonitorModeScreenData, serialNumber?: string) =>
                    ScreenDefinitions.biotechInfo(screenData as BiotechInfo, serialNumber as string),
                regionClass: (screenData: BaseScreenData) => 'region-1-2-3-4',
            },
        },
        title: 'INFO',
        type: ScreenType.BIOTECH,
    },
    87: {
        buttons: {
            1: {
                text: '',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
                buttonIcon: (screenData: BaseScreenData) => `marrs-up-caret-icon mx-auto h-6 w-6`,
            },
            2: {
                text: '',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
                buttonIcon: (screenData: BaseScreenData) => `marrs-down-caret-icon mx-auto h-6 w-6`,
            },
            4: {
                text: 'Select',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
            5: {
                text: 'Back',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
        },
        regions: {
            1: {
                template: (screenData: MonitorModeScreenData, serialNumber?: string, language?: number) =>
                    ScreenDefinitions.biotechMoreOptions(screenData as BiotechOptions, language!),
                regionClass: (screenData: BaseScreenData) => 'region-1-2-3-4',
            },
        },
        title: 'OPTIONS',
        type: ScreenType.BIOTECH,
    },
    88: {
        buttons: {
            1: {
                text: '',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
                buttonIcon: (screenData: BaseScreenData) => `marrs-up-caret-icon mx-auto h-6 w-6`,
            },
            2: {
                text: '',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
                buttonIcon: (screenData: BaseScreenData) => `marrs-down-caret-icon mx-auto h-6 w-6`,
            },
            4: {
                text: 'Select',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
            5: {
                text: 'Back',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
        },
        regions: {
            1: {
                template: (screenData: MonitorModeScreenData) =>
                    ScreenDefinitions.biotechLanguages(screenData as BiotechLanguage),
                regionClass: (screenData: BaseScreenData) => 'region-1-2-3-4',
            },
        },
        title: 'LANGUAGE',
        type: ScreenType.BIOTECH,
    },
    89: {
        buttons: {
            1: {
                text: 'Continue',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
            5: {
                text: 'Back',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
        },
        regions: {
            1: {
                template: (screenData: MonitorModeScreenData) =>
                    ScreenDefinitions.biotechLoadFactoryTestCassette(screenData as BaseScreenData),
                regionClass: (screenData: BaseScreenData) => 'region-1-2-3-4',
            },
        },
        title: 'LOAD TEST SET',
        type: ScreenType.BIOTECH,
    },
    94: {
        buttons: {
            2: {
                text: 'Continue',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
            5: {
                text: 'Back',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
        },
        regions: {
            1: {
                template: (screenData: MonitorModeScreenData) =>
                    ScreenDefinitions.biotechLoadSet(screenData as BaseScreenData),
                regionClass: (screenData: BaseScreenData) => 'region-1-2-3-4',
            },
        },
        title: 'LOAD SET',
        type: ScreenType.BIOTECH,
    },
    90: {
        buttons: {
            4: {
                text: 'Start',
                borderClass: '',
                backgroundClass: 'bg-marrs-green text-white',
            },
            5: {
                text: 'Back',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
        },
        regions: {
            1: {
                template: (screenData: MonitorModeScreenData) =>
                    ScreenDefinitions.biotechStartTest(screenData as BaseScreenData),
                regionClass: (screenData: BaseScreenData) => 'region-1-2-3-4',
            },
        },
        title: 'TEST PUMP',
        type: ScreenType.BIOTECH,
    },
    91: {
        buttons: {
            4: {
                text: 'Cancel',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
            5: {
                text: 'Continue',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
        },
        regions: {
            1: {
                template: (screenData: MonitorModeScreenData) =>
                    ScreenDefinitions.biotechTesting(screenData as BaseScreenData),
                regionClass: (screenData: BaseScreenData) => 'region-1-2-3-4',
            },
        },
        title: 'TESTING PUMP',
        type: ScreenType.BIOTECH,
    },
    92: {
        buttons: {
            5: {
                text: 'Done',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
        },
        regions: {
            1: {
                template: (screenData: MonitorModeScreenData) =>
                    ScreenDefinitions.biotechTestComplete(screenData as BaseScreenData),
                regionClass: (screenData: BaseScreenData) => 'region-1-2-3-4',
            },
        },
        title: 'TEST PASSED',
        type: ScreenType.BIOTECH,
    },
    93: {
        buttons: {
            5: {
                text: 'Done',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
        },
        regions: {
            1: {
                template: (screenData: MonitorModeScreenData) =>
                    ScreenDefinitions.biotechTestFailed(screenData as ManualTestFailed),
                regionClass: (screenData: BaseScreenData) => 'region-1-2-3-4',
            },
        },
        title: 'TEST FAILED',
        type: ScreenType.BIOTECH,
    },
    11: {
        buttons: {
            1: {
                text: 'Auto Prime',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
            3: {
                text: 'Hold To Prime',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
        },
        regions: {
            1: {
                template: (screenData: MonitorModeScreenData) =>
                    ScreenDefinitions.disconnectPatientFromTubingNoFluid(screenData as BaseScreenData),
                regionClass: (screenData: BaseScreenData) => 'region-1-2-3-4',
            },
        },
        title: 'CAUTION',
        type: ScreenType.HOLD,
    },
    12: {
        buttons: {
            3: {
                text: 'Hold To Prime',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
            5: {
                text: 'Done',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
        },
        regions: {
            1: {
                template: (screenData: MonitorModeScreenData) =>
                    ScreenDefinitions.disconnectPatientFromTubingFluid(screenData as BaseScreenData),
                regionClass: (screenData: BaseScreenData) => 'region-1-2-3-4',
            },
        },
        title: 'CAUTION',
        type: ScreenType.HOLD,
    },
    67: {
        buttons: {
            1: {
                text: 'Adjust Rate',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
            3: {
                text: 'More Options',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
            5: {
                text: 'Continue',
                borderClass: '',
                backgroundClass: 'bg-marrs-green text-white',
            },
        },
        regions: {
            1: {
                template: (screenData: MonitorModeScreenData) =>
                    ScreenDefinitions.rateDisplayCont(screenData as ReadyToFeedCont),
                regionClass: (screenData: BaseScreenData) => 'region-1-2',
            },
            3: {
                template: (screenData: MonitorModeScreenData) =>
                    ScreenDefinitions.feedTotalizer(screenData as ReadyToFeedCont),
                regionClass: (screenData: BaseScreenData) => 'region-3-4',
            },
        },
        title: 'PAUSED',
        type: ScreenType.HOLD,
    },
    68: {
        buttons: {
            1: {
                text: 'Adjust Feed',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
            3: {
                text: 'More Options',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
            5: {
                text: 'Continue',
                borderClass: '',
                backgroundClass: 'bg-marrs-green text-white',
            },
        },
        regions: {
            1: {
                template: (screenData: MonitorModeScreenData) =>
                    ScreenDefinitions.rateDisplayCont(screenData as ReadyToFeedCont),
                regionClass: (screenData: BaseScreenData) => 'region-1-2',
            },
            3: {
                template: (screenData: MonitorModeScreenData) => {
                    return (screenData as ReadyToFeedDose).continuousFeedDose > 0 ?
                        ScreenDefinitions.doseLeftReadyToFeed(screenData as ReadyToFeedDose) :
                        ScreenDefinitions.feedTotalizer(screenData as ReadyToFeedCont);
                },
                regionClass: (screenData: MonitorModeScreenData) =>
                    `${(screenData as ReadyToFeedDose).continuousFeedDose > 0 ? 'region-3' : 'region-3-4'}`,
            },
            4: {
                template: (screenData: MonitorModeScreenData) =>
                    ScreenDefinitions.feedTotalizerOneLine(screenData as ReadyToFeedCont),
                regionClass: (screenData: BaseScreenData) => 'region-4',
                enabled: (screenData: MonitorModeScreenData) =>
                    (screenData as ReadyToFeedDose).continuousFeedDose > 0,
            },
        },
        title: 'PAUSED',
        type: ScreenType.HOLD,
    },
    75: {
        buttons: {
            1: {
                text: 'Adjust Bolus',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
            3: {
                text: 'More Options',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
            5: {
                text: 'Continue',
                borderClass: '',
                backgroundClass: 'bg-marrs-green text-white',
            },
        },
        regions: {
            1: {
                template: (screenData: MonitorModeScreenData) =>
                    ScreenDefinitions.rateDisplayBolus(screenData as ReadyToFeedBolus),
                regionClass: (screenData: BaseScreenData) => 'region-1-2',
            },
            3: {
                template: (screenData: MonitorModeScreenData) => {
                    return (screenData as ReadyToFeedBolus).numberOfBoluses > 0 ?
                        ScreenDefinitions.bolusRateReadyToFeed(screenData as ReadyToFeedBolus) :
                        ScreenDefinitions.feedTotalizer(screenData as ReadyToFeedCont);
                },
                regionClass: (screenData: MonitorModeScreenData) =>
                    `${(screenData as ReadyToFeedBolus).numberOfBoluses > 0 ? 'region-3' : 'region-3-4'}`,
            },
            4: {
                template: (screenData: MonitorModeScreenData) =>
                    ScreenDefinitions.feedTotalizerSplitLine(screenData as ReadyToFeedCont),
                regionClass: (screenData: BaseScreenData) => 'region-4',
                enabled: (screenData: MonitorModeScreenData) =>
                    (screenData as ReadyToFeedBolus).numberOfBoluses > 0,
            },
        },
        title: 'PAUSED',
        type: ScreenType.HOLD,
    },
    65: {
        buttons: {
            2: {
                text: 'Adjust KTO',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
            3: {
                text: 'Lock Screen',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
            5: {
                text: 'Continue',
                borderClass: '',
                backgroundClass: 'bg-marrs-green text-white',
            },
        },
        regions: {
            1: {
                template: (screenData: MonitorModeScreenData) =>
                    ScreenDefinitions.ktoMinutes(screenData as KTOCont),
                regionClass: (screenData: BaseScreenData) => 'region-1-2',
            },
            3: {
                template: (screenData: MonitorModeScreenData) => {
                    return((screenData as KTOCont).continuousFeedDoseRemaining > 0
                        && (screenData as KTOCont).doseEnabled) ?
                    ScreenDefinitions.ktoDoseLeft(screenData as KTOCont) :
                        ScreenDefinitions.ktoCont(screenData as KTOCont);
                },
                regionClass: (screenData: MonitorModeScreenData) =>
                    `${(screenData as KTOCont).doseEnabled && (screenData as KTOCont).continuousFeedDoseRemaining > 0
                        ? 'region-3' : 'region-3-4'}`,
            },
            4: {
                template: (screenData: MonitorModeScreenData) => ScreenDefinitions.ktoTotalFed(screenData as KTOCont),
                regionClass: (screenData: MonitorModeScreenData) => 'region-4',
                enabled: (screenData: MonitorModeScreenData) => (screenData as KTOCont).continuousFeedDoseRemaining > 0
                    && (screenData as KTOCont).doseEnabled,
            },
        },
        title: 'KTO',
        type: ScreenType.HOLD,
    },
    84: {
        buttons: {
            2: {
                text: 'Adjust KTO',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
            3: {
                text: 'Lock Screen',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
            5: {
                text: 'Continue',
                borderClass: '',
                backgroundClass: 'bg-marrs-green text-white',
            },
        },
        regions: {
            1: {
                template: (screenData: MonitorModeScreenData) =>
                    ScreenDefinitions.ktoMinutes(screenData as KTOBolus),
                regionClass: (screenData: BaseScreenData) => 'region-1-2',
            },
            3: {
                template: (screenData: MonitorModeScreenData) => {
                    return (screenData as ReadyToFeedBolus).numberOfBoluses > 0 ?
                        ScreenDefinitions.bolusRateReadyToFeed(screenData as ReadyToFeedBolus) :
                        ScreenDefinitions.feedTotalizer(screenData as ReadyToFeedCont);
                },
                regionClass: (screenData: MonitorModeScreenData) =>
                    `${(screenData as ReadyToFeedBolus).numberOfBoluses > 0 ? 'region-3' : 'region-3-4'}`,
            },
            4: {
                template: (screenData: MonitorModeScreenData) =>
                    ScreenDefinitions.feedTotalizerSplitLine(screenData as ReadyToFeedCont),
                regionClass: (screenData: BaseScreenData) => 'region-4',
                enabled: (screenData: MonitorModeScreenData) =>
                    (screenData as ReadyToFeedBolus).numberOfBoluses > 0,
            },
        },
        title: 'KTO',
        type: ScreenType.HOLD,
    },
    66: {
        buttons: {
            1: {
                text: '+',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
                spanClass: 'plus-button',
            },
            2: {
                text: '\u2013',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
                spanClass: 'minus-button',
            },
            4: {
                text: 'Cancel',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
            5: {
                text: 'Done',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
        },
        regions: {
            1: {
                template: (screenData: MonitorModeScreenData) =>
                    ScreenDefinitions.adjustKtoMinutes(screenData as CurrentValue),
                regionClass: (screenData: BaseScreenData) => 'region-1-2-3-4',
            },
        },
        title: 'ADJUST KTO',
        type: ScreenType.HOLD,
    },
    95: {
        buttons: {
        },
        regions: {
            1: {
                template: (screenData: MonitorModeScreenData) =>
                    ScreenDefinitions.removeCassette(screenData as CurrentValue),
                regionClass: (screenData: BaseScreenData) => 'region-1-2-3-4',
            },
        },
        title: 'SET LOADED',
        type: ScreenType.HOLD,
    },
    53: {
        buttons: {
            2: {
                text: 'KTO',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
                enabled: (screenData: MonitorModeScreenData) => (screenData as FeedingScreen).ktoEnabled,
            },
            3: {
                text: 'Lock Screen',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
            5: {
                text: 'Pause',
                borderClass: '',
                backgroundClass: 'bg-marrs-pause-orange text-white',
            },
        },
        regions: {
            1: {
                template: (screenData: MonitorModeScreenData) =>
                    ScreenDefinitions.rateDisplayCont(screenData as ReadyToFeedCont),
                regionClass: (screenData: BaseScreenData) => 'region-1-2',
            },
            3: {
                template: (screenData: MonitorModeScreenData) =>
                    ScreenDefinitions.feedTotalizer(screenData as ReadyToFeedCont),
                regionClass: (screenData: BaseScreenData) => 'region-3-4',
            },
        },
        title: 'FEEDING',
        type: ScreenType.RUNNING,
    },
    54: {
        buttons: {
            2: {
                text: 'KTO',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
                enabled: (screenData: MonitorModeScreenData) => (screenData as FeedingScreen).ktoEnabled,
            },
            3: {
                text: 'Lock Screen',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
            5: {
                text: 'Pause',
                borderClass: '',
                backgroundClass: 'bg-marrs-pause-orange text-white',
            },
        },
        regions: {
            1: {
                template: (screenData: MonitorModeScreenData) =>
                    ScreenDefinitions.rateDisplayCont(screenData as ReadyToFeedCont),
                regionClass: (screenData: BaseScreenData) => 'region-1-2',
            },
            3: {
                template: (screenData: MonitorModeScreenData) => {
                    return ScreenDefinitions.doseLeftFeeding(screenData as ReadyToFeedDose);
                },
                regionClass: (screenData: MonitorModeScreenData) => 'region-3',
            },
            4: {
                template: (screenData: MonitorModeScreenData) =>
                    ScreenDefinitions.feedTotalizerDoseFeeding(screenData as ReadyToFeedCont),
                regionClass: (screenData: BaseScreenData) => 'region-4',
            },
        },
        title: 'FEEDING',
        type: ScreenType.RUNNING,
    },
    61: {
        buttons: {
            2: {
                text: 'KTO',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
                enabled: (screenData: MonitorModeScreenData) => (screenData as FeedingScreen).ktoEnabled,
            },
            3: {
                text: 'Lock Screen',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
            5: {
                text: 'Pause',
                borderClass: '',
                backgroundClass: 'bg-marrs-pause-orange text-white',
            },
        },
        regions: {
            1: {
                template: (screenData: MonitorModeScreenData) =>
                    ScreenDefinitions.rateDisplayBolus(screenData as ReadyToFeedBolus),
                regionClass: (screenData: BaseScreenData) => 'region-1-2',
            },
            3: {
                template: (screenData: MonitorModeScreenData) => {
                    return (screenData as ReadyToFeedBolus).numberOfBoluses > 0 ?
                        ScreenDefinitions.bolusRateReadyToFeed(screenData as ReadyToFeedBolus) :
                        ScreenDefinitions.feedTotalizer(screenData as ReadyToFeedCont);
                },
                regionClass: (screenData: MonitorModeScreenData) =>
                    `${(screenData as ReadyToFeedBolus).numberOfBoluses > 0 ? 'region-3' : 'region-3-4'}`,
            },
            4: {
                template: (screenData: MonitorModeScreenData) =>
                    ScreenDefinitions.feedTotalizerSplitLine(screenData as ReadyToFeedCont),
                regionClass: (screenData: BaseScreenData) => 'region-4',
                enabled: (screenData: MonitorModeScreenData) =>
                    (screenData as ReadyToFeedBolus).numberOfBoluses > 0,
            },
        },
        title: 'FEEDING',
        type: ScreenType.RUNNING,
    },
    100: {
        buttons: {
            1: {
                text: '',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
                buttonIcon: (screenData: MonitorModeScreenData) => !screenData.alarmSilenced
                    ? 'marrs-silence-button-icon mx-auto h-12 w-14' : 'marrs-not-silenced-button-icon mx-auto h-8 w-8',
            },
            4: {
                text: 'Power Off',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
            5: {
                text: 'Continue',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
        },
        regions: {
            1: {
                template: (screenData: MonitorModeScreenData) =>
                    ScreenDefinitions.pumpInactive(screenData as BaseScreenData),
                regionClass: (screenData: BaseScreenData) => 'region-1-2-3-4',
            },
        },
        title: 'NOTICE',
        type: ScreenType.WARNING,
    },
    101: {
        buttons: {
            1: {
                text: '',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
                buttonIcon: (screenData: MonitorModeScreenData) => !screenData.alarmSilenced
                    ? 'marrs-silence-button-icon mx-auto h-12 w-14' : 'marrs-not-silenced-button-icon mx-auto h-8 w-8',
            },
            5: {
                text: 'Continue',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
        },
        regions: {
            1: {
                template: (screenData: MonitorModeScreenData) =>
                    ScreenDefinitions.lowBattery(screenData as BaseScreenData),
                regionClass: (screenData: BaseScreenData) => 'region-1-2-3-4',
            },
        },
        title: 'NOTICE',
        type: ScreenType.WARNING,
    },
    103: {
        buttons: {
            1: {
                text: '',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
                buttonIcon: (screenData: MonitorModeScreenData) => !screenData.alarmSilenced
                    ? 'marrs-silence-button-icon mx-auto h-12 w-14' : 'marrs-not-silenced-button-icon mx-auto h-8 w-8',
            },
            4: {
                text: 'Power Off',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
        },
        regions: {
            1: {
                template: (screenData: MonitorModeScreenData) =>
                    ScreenDefinitions.cassetteTubeError(screenData as BaseScreenData),
                regionClass: (screenData: BaseScreenData) => 'region-1-2-3-4',
            },
        },
        title: 'CAUTION',
        type: ScreenType.ERROR,
    },
    104: {
        buttons: {
            1: {
                text: '',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
                buttonIcon: (screenData: MonitorModeScreenData) => !screenData.alarmSilenced
                    ? 'marrs-silence-button-icon mx-auto h-12 w-14' : 'marrs-not-silenced-button-icon mx-auto h-8 w-8',
            },
            4: {
                text: 'Power Off',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
            5: {
                text: 'Continue',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
        },
        regions: {
            1: {
                template: (screenData: MonitorModeScreenData) =>
                    ScreenDefinitions.rotorStuckError(screenData as BaseScreenData),
                regionClass: (screenData: BaseScreenData) => 'region-1-2-3-4',
            },
        },
        title: 'CAUTION',
        type: ScreenType.ERROR,
    },
    105: {
        buttons: {
            1: {
                text: '',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
                buttonIcon: (screenData: MonitorModeScreenData) => !screenData.alarmSilenced
                    ? 'marrs-silence-button-icon mx-auto h-12 w-14' : 'marrs-not-silenced-button-icon mx-auto h-8 w-8',
            },
            5: {
                text: 'Continue',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
        },
        regions: {
            1: {
                template: (screenData: MonitorModeScreenData) =>
                    ScreenDefinitions.feedBagEmptyError(screenData as BaseScreenData),
                regionClass: (screenData: BaseScreenData) => 'region-1-2-3-4',
            },
        },
        title: 'CAUTION',
        type: ScreenType.ERROR,
    },
    106: {
        buttons: {
            1: {
                text: '',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
                buttonIcon: (screenData: MonitorModeScreenData) => !screenData.alarmSilenced
                    ? 'marrs-silence-button-icon mx-auto h-12 w-14' : 'marrs-not-silenced-button-icon mx-auto h-8 w-8',
            },
            5: {
                text: 'Continue',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
        },
        regions: {
            1: {
                template: (screenData: MonitorModeScreenData) =>
                    ScreenDefinitions.supplyTubeBlockedError(screenData as BaseScreenData),
                regionClass: (screenData: BaseScreenData) => 'region-1-2-3-4',
            },
        },
        title: 'CAUTION',
        type: ScreenType.ERROR,
    },
    109: {
        buttons: {
            1: {
                text: '',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
                buttonIcon: (screenData: MonitorModeScreenData) => !screenData.alarmSilenced
                    ? 'marrs-silence-button-icon mx-auto h-12 w-14' : 'marrs-not-silenced-button-icon mx-auto h-8 w-8',
            },
            5: {
                text: 'Continue',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
        },
        regions: {
            1: {
                template: (screenData: MonitorModeScreenData) =>
                    ScreenDefinitions.patientTubeBlockedError(screenData as BaseScreenData),
                regionClass: (screenData: BaseScreenData) => 'region-1-2-3-4',
            },
        },
        title: 'CAUTION',
        type: ScreenType.ERROR,
    },
    102: {
        buttons: {
            1: {
                text: '',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
                buttonIcon: (screenData: MonitorModeScreenData) => !screenData.alarmSilenced
                    ? 'marrs-silence-button-icon mx-auto h-12 w-14' : 'marrs-not-silenced-button-icon mx-auto h-8 w-8',
            },
            4: {
                text: 'Power Off',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
        },
        regions: {
            1: {
                template: (screenData: MonitorModeScreenData) =>
                    ScreenDefinitions.cassetteDislodgedError(screenData as BaseScreenData),
                regionClass: (screenData: BaseScreenData) => 'region-1-2-3-4',
            },
        },
        title: 'CAUTION',
        type: ScreenType.ERROR,
    },
    110: {
        buttons: {
            4: {
                text: 'Power Off',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
        },
        regions: {
            1: {
                template: (screenData: MonitorModeScreenData) =>
                    ScreenDefinitions.deadBatteryError(screenData as BaseScreenData),
                regionClass: (screenData: BaseScreenData) => 'region-1-2-3-4',
            },
        },
        title: 'WARNING',
        type: ScreenType.CRITICAL_ERROR,
    },
    111: {
        buttons: {
            4: {
                text: 'Power Off',
                borderClass: 'mm-button-border-blue',
                backgroundClass: 'bg-white',
            },
        },
        regions: {
            1: {
                template: (screenData: MonitorModeScreenData) =>
                    ScreenDefinitions.systemError(screenData as SystemError),
                regionClass: (screenData: BaseScreenData) => 'region-1-2-3-4',
            },
        },
        title: 'WARNING',
        type: ScreenType.CRITICAL_ERROR,
    },
};

export default screenMap;

export {extraMessageMap};
