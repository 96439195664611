



























































































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {Team} from '@/models/Teams';
import User from '@/models/User';
import {TableCellData, TableCellType, TableHeaderData, TableRowData} from '@/components/table/cells/TableCellData';
import {sortBy} from '@/ts/utilities';
import Table from '@/components/table/Table.vue';
import {ButtonType} from '@/components/form/FormTypes';
import {namespace} from 'vuex-class';
import {ModalMutations} from '@/vuex/modal';
import AddUsersToTeamModal from '@/components/teams/AddUsersToTeamModal.vue';
import {RoleNames} from '@/ts/permissions/Role';
import {Patient} from '@/models/patients/Patient';
import TeamService from '@/services/TeamService';
import AddPatientsToTeamModal from '@/components/teams/AddPatientsToTeamModal.vue';
import {Searchable} from '@/models/Searchable';
import {SearchData} from '@/ts/Filter';
import Multiselect from 'vue-multiselect';
import ConfirmationModal from '@/components/modal/ConfirmationModal.vue';

enum Tabs {
    USERS,
    PATIENTS
}

const auth = namespace('auth');
const modal = namespace('modal');

/**
 * Renders information about a team and which users/patients are assigned to the team
 */
@Component({
    components: {
        Table,
        AddUsersToTeamModal,
        AddPatientsToTeamModal,
        Multiselect,
        ConfirmationModal,
    }
})
export default class SelectedTeam extends Vue {
    /**
     * Currently selected team
     */
    @Prop() selectedTeam!: Team | null;

    /**
     * List of selectable teams
     */
    @Prop({default: () => []}) teams!: Team[];

    /**
     * Users assigned to the team
     */
    users: User[] = [];

    /**
     * Patients assigned to the team
     */
    patients: Patient[] = [];

    /**
     * Whether the request to fetch the team info is loading
     */
    loading = false;
    tabs = Tabs;
    ButtonType = ButtonType;

    /**
     * Field the users table is sorted by
     */
    userSortKey = 'username';

    /**
     * Whether the user table is sorted ascending
     */
    userSortedAsc = true;

    /**
     * Current search value
     */
    searchString = {
        key: Searchable.PATIENT_NAME,
        search: ""
    };

    /**
     * Fields the user table can be searched by
     */
    userSearchTypes: SearchData[] = [
        {
            key: Searchable.PATIENT_NAME,
            value: 'Name'
        },
        {
            key: Searchable.USERNAME,
            value: 'Username'
        },
    ];

    /**
     * Fields the patient table can be searched by
     */
    patientSearchTypes: SearchData[] = [
        {
            key: Searchable.PATIENT_NAME,
            value: 'Name'
        },
        {
            key: Searchable.PATIENT_ID,
            value: 'Patient ID'
        },
    ];

    /**
     * Field the patient table is sorted by
     */
    patientSortKey = 'lastName';

    /**
     * Whether the patient table is sorted ascending
     */
    patientSortedAsc = true;

    /**
     * Currently selected tab
     */
    currentTab = Tabs.USERS;

    /**
     * User selected for removal
     */
    userToRemove: User | null = null;

    /**
     * Whether the remove user modal is showing
     */
    removeUserModalShowing = false;

    /**
     * Whether the add users to teams modal is showing
     */
    addUsersToTeamModalShowing = false;

    /**
     * Patient selected for removal
     */
    patientToRemove: Patient | null = null;

    /**
     * Whether the remove patient from team modal is showing
     */
    removePatientModalShowing = false;

    /**
     * Whether the add patients to team modal is showing
     */
    addPatientsToTeamModalShowing = false;

    teamService = new TeamService();

    eventBus = new Vue()

    @auth.State('loggedInUser') loggedInUser!: User;
    @modal.Mutation(ModalMutations.CHANGE_MODAL_STATE) changeModalBackdropState!: (state: boolean) => void;

    async mounted(){
        await this.fetchTeamInfo();
    }

    /**
     * Fetches team info when the selected team changes
     * @param val
     */
    @Watch('selectedTeam')
    async selectedTeamUpdated(val: Team){
        await this.fetchTeamInfo()
    }

    /**
     * Resets teh search info when the selected tab changes
     */
    @Watch('currentTab')
    tabUpdated() {
        this.searchString.search = ""
        this.eventBus.$emit('resetSearch')
    }

    /**
     * Sends the request to fetch the information about the team
     */
    async fetchTeamInfo(){
      if(this.selectedTeam === null) {
        this.patients = [];
        this.users = [];
        return;
      }
        this.loading = true;
        const response = await this.teamService.fetchTeamInfo(this.selectedTeam.id);
        response.map((data) => {
            this.users = data.users;
            this.patients = data.patients;
        })
        this.loading = false;
    }

    /**
     * Updates the current search information
     * @param searchString
     */
    searchData(searchString: any){
        this.searchString = searchString;
    }

    /**
     * Updates the sort information for hte user table
     * @param message
     */
    sortUserData(message: any){
        this.userSortKey = message.sortKey;
        this.userSortedAsc = message.sortedAsc;
    }

    /**
     * User table header definition
     */
    get userTableHeader(): TableHeaderData[]{
        return [
            {
                sortable: true,
                value: 'Username',
                widthClass: "w-1/5",
                sortKey: 'username'
            },
            {
                sortable: true,
                value: "First name",
                widthClass: "w-1/5",
                sortKey: "firstName"
            },
            {
                sortable: true,
                value: "Last name",
                widthClass: "w-1/5",
                sortKey: "lastName"
            },
            {
                sortable: true,
                value: "Role name",
                widthClass: "w-1/5",
                sortKey: "role"
            },
            {
                sortable: false,
                value: 'Actions',
                widthClass: "w-1/5",
            },
        ]
    }

    /**
     * Filters the list of users based on current search
     */
    get searchedUserData(): User[] {
        return this.users.filter((user) => {
            let search = this.searchString.search.toLowerCase();
            if (this.searchString.key === Searchable.PATIENT_NAME) {
                return (user.firstName != undefined && user.firstName.toLowerCase().includes(search)) ||
                    (user.lastName != undefined && user.lastName.toLowerCase().includes(search)) ||
                    ((user.firstName !== undefined && user.lastName !== undefined) && (user.firstName.toLowerCase() + " " + user.lastName.toLowerCase()).includes(search));
            } else if(this.searchString.key === Searchable.USERNAME) {
                return user.username.toLowerCase().includes(search)
            }
            return false;
        })
    }

    /**
     * Transforms the list of assigned users to an array of table rows
     */
    get userTableData(): TableRowData[]{
        return [... this.searchedUserData].sort(sortBy(this.userSortKey, this.userSortedAsc)).map(user => {
            const cells = [
                {
                    type: TableCellType.NORMAL,
                    primaryValue: user.username as string
                },
                {
                    type: TableCellType.NORMAL,
                    primaryValue: user.firstName as string
                },
                {
                    type: TableCellType.NORMAL,
                    primaryValue: user.lastName as string
                },
                {
                    type: TableCellType.NORMAL,
                    primaryValue: RoleNames[user.role] as string
                },
                {
                    type: TableCellType.ICON,
                    primaryValue: ``,
                    icons: [
                        {
                            name: 'times',
                            tooltip: 'Remove user from team',
                            action: () => {
                                this.showUserRemoveModal(user)
                            }
                        }
                    ]
                },
            ];

            return {
                cells,
                indexKey: user.id
            }
        })
    }

    /**
     * Shows the modal to remove a user from the team
     * @param user
     */
    showUserRemoveModal(user: User){
        this.userToRemove = user;
        this.removeUserModalShowing = true;
        this.changeModalBackdropState(true);
    }

    /**
     * Hides the remove user from team modal
     */
    dismissUserRemoveModal(){
        this.userToRemove = null;
        this.removeUserModalShowing = false;
        this.changeModalBackdropState(false);
    }

    /**
     * Sends the request to remove the user from the team
     */
    async removeUserFromTeam(){
        if(this.selectedTeam === null) {
          return;
        }
        if(this.userToRemove == null){
            this.dismissUserRemoveModal();
            return;
        }
        const response = await this.teamService.removeUsersFromTeam([this.userToRemove.id as number], this.selectedTeam.id, this.loggedInUser.customers.length > 0 ? this.loggedInUser.customers[0].id : null);
        response.map(async () => {
            await this.fetchTeamInfo();
            this.dismissUserRemoveModal()
        })
    }

    /**
     * Shows the modal to add new users to the team
     */
    showAddUsersToTeamModal(){
        this.addUsersToTeamModalShowing = true;
        this.changeModalBackdropState(true);
    }

    /**
     * Hides the add users to team modal and fetches the team information
     */
    async dismissAddUsersToTeamModal(){
        this.addUsersToTeamModalShowing = false;
        this.changeModalBackdropState(false);
        await this.fetchTeamInfo();
    }

    /**
     * Updates the sort information for the patient table
     * @param message
     */
    sortPatientData(message: any){
        this.patientSortKey = message.sortKey;
        this.patientSortedAsc = message.sortedAsc;
    }

    /**
     * Table header definition for the patient table
     */
    get patientTableHeader(): TableHeaderData[]{
        return [

            {
                sortable: true,
                value: 'Patient ID',
                widthClass: "w-3/10",
                sortKey: 'patientId'
            },
            {
                sortable: true,
                value: "First name",
                widthClass: "w-3/10",
                sortKey: "firstName"
            },
            {
                sortable: true,
                value: "Last name",
                widthClass: "w-3/10",
                sortKey: "lastName"
            },
            {
                sortable: false,
                value: 'Actions',
                widthClass: "w-1/10",
            },
        ]
    }

    /**
     * Filters the patient table based on te current search information
     */
    get searchedPatientData(): Patient[] {
        return this.patients.filter((patient) => {
            let search = this.searchString.search.toLowerCase();
            if (this.searchString.key === Searchable.PATIENT_NAME) {
                return (patient.firstName != undefined && patient.firstName.toLowerCase().includes(search)) ||
                    (patient.lastName != undefined && patient.lastName.toLowerCase().includes(search)) ||
                    (patient.firstName.toLowerCase() + " " + patient.lastName.toLowerCase()).includes(search);
            } else if(this.searchString.key === Searchable.PATIENT_ID) {
                return patient.patientId.toLowerCase().includes(search)
            }
            return false;
        })
    }

    /**
     * Transforms the list of patients into an array of table rows
     */
    get patientTableData(): TableRowData[]{
        return [...this.searchedPatientData].sort(sortBy(this.patientSortKey, this.patientSortedAsc)).map((patient: Patient) => {
            const cells: TableCellData[] = [
                {
                    type: TableCellType.NORMAL,
                    primaryValue: patient.patientId
                },
                {
                    type: TableCellType.NORMAL,
                    primaryValue: patient.firstName
                },
                {
                    type: TableCellType.NORMAL,
                    primaryValue: patient.lastName as string
                },
                {
                    type: TableCellType.ICON,
                    primaryValue: ``,
                    icons: [
                        {
                            name: 'times',
                            tooltip: 'Remove patient from team',
                            action: () => {
                                this.showPatientRemoveModal(patient);
                            }
                        }
                    ]
                },
            ];
            return {
                cells: cells,
                indexKey: patient.id as number
            };
        });
    }

    /**
     * Shows the modal to remove a patient from a team
     * @param patient
     */
    showPatientRemoveModal(patient: Patient){
        this.patientToRemove = patient;
        this.removePatientModalShowing = true;
        this.changeModalBackdropState(true);
    }

    /**
     * Hides the remove patient from team modal
     */
    dismissPatientRemoveModal(){
        this.patientToRemove = null;
        this.removePatientModalShowing = false;
        this.changeModalBackdropState(false);
    }

    /**
     * Sends the request to remove a patient from the team
     */
    async removePatientFromTeam() {
        if(this.patientToRemove === null || this.selectedTeam === null){
            return;
        }
        const customerId = this.loggedInUser.customers.length > 0 ? this.loggedInUser.customers[0].id : null;
        const response = await this.teamService.removePatientFromTeam(this.patientToRemove.id as number, this.selectedTeam.id, customerId);

        if(response.isSuccess()){
            await this.fetchTeamInfo();
            this.dismissPatientRemoveModal()
        }
    }

    /**
     * Shows the modal to add patients to the selected team
     */
    showAddPatientsToTeamModal() {
        this.addPatientsToTeamModalShowing = true;
        this.changeModalBackdropState(true);
    }

    /**
     * Hides the modal to assign new patients to the team
     * Fetches the team information again on dismiss
     */
    async dismissAddPatientsToTeamModal(){
        this.addPatientsToTeamModalShowing = false;
        this.changeModalBackdropState(false);
        await this.fetchTeamInfo();
    }
}
