






















































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {MARRSPump} from '@/models/Devices/MARRS/MARRSPump';
import {DeviceRegistrationService} from '@/services/devices/DeviceRegistrationService';
import Modal from '../modal/Modal.vue';
import {Patient, PatientDeviceAssignment, PatientDeviceAssignmentCreate} from '@/models/patients/Patient';
import {required} from 'vuelidate/lib/validators';
import Multiselect from 'vue-multiselect';
import moment, {Moment} from 'moment';
import {ButtonType} from '@/components/form/FormTypes';
import EditAssignmentTable from '@/components/patients/assignments/EditAssignmentTable.vue';
import PatientDeviceAssignmentService from '@/services/patients/PatientDeviceAssignmentService';
import {convertToMomentTimezone, formatMomentDateOnly} from '@/ts/TimezoneUtils';
import {AssignableDeviceDto} from '@/models/Devices/Device';


interface DateOpt {
    start: Date | null;
    end: Date | null;
}

enum Tabs {
    ASSIGN_PUMP,
    EDIT_ASSIGNMENTS
}

/**
 * Renders a modal for managing the devices assigned to a patient
 */
@Component({
    components: {
        Modal,
        Multiselect,
        EditAssignmentTable
    },
    validations: {
        assignmentStartDate: {
            required
        }
    }
})
export default class DeviceAssignmentModal extends Vue {

    tabOptions = Tabs;

    selectedTab = Tabs.ASSIGN_PUMP;

    /**
     * List of devices that can be assigned
     */
    devices: AssignableDeviceDto[] = [];
    deviceService = new DeviceRegistrationService(this.$store.state.auth.loggedInUser.timezone);

    /**
     * Current patient
     */
    @Prop() patient!: Patient;

    /**
     * Currently selected device
     */
    selectedDevice: AssignableDeviceDto | null = null;

    /**
     * Currently selected date information
     */
    assignmentStartDate: null | Date = null;
    assignmentEndDate: null | Date = null;
    today = moment.tz(this.$store.state.auth.loggedInUser.timezone).startOf('day');
    buttonType = ButtonType;

    /**
     * Whether the assignment is a past assignment
     */
    forcePastAssignment = false;

    /**
     * If a start date is picked that does not have a set of days available between it
     * and today, this will be the max possible date for the end date
     */
    maxEndDate: Date | null = null;

    patientDeviceAssignmentService = new PatientDeviceAssignmentService(this.$store.state.auth.loggedInUser.timezone);

    async mounted() {
        await this.fetchAssignableDevices();
    }

    /**
     * Loads the list of assignable devices
     */
    private async fetchAssignableDevices() {
        const response = await this.deviceService.getAssignableDevices();
        response.map((devices) => {
            this.devices = devices;
        });
    }

    get patientCurrentAssignments(): PatientDeviceAssignment[] {
        if(this.patient.assignments !== undefined && this.patient.assignments.length > 0) {
            return[...this.patient.assignments]
        }
        return []
    }

    /**
     * Handles an assignment being removed
     * @param assignmentId
     */
    async handleAssignmentRemoved(assignmentId: number) {
        await this.fetchAssignableDevices();
        this.$emit('removeAssignment', assignmentId)
    }

    /**
     * Updates information when the selected device changes
     * @param device Device that was selected
     */
    handleDeviceChange(device: AssignableDeviceDto) {
        this.selectedDevice = device;
        this.assignmentStartDate = null;
        const currentAssignment = this.patientCurrentAssignments.find((assignment) => assignment.endDate === null);
        this.forcePastAssignment = (currentAssignment !== undefined && device.id === currentAssignment.device.id);

        if(!this.forcePastAssignment && !this.deviceHasUnmanageableAssignment) {
            this.assignmentStartDate = this.today.clone().add(1, 'days').startOf('day').toDate()
        }
    }

    handleStartDateUpdate(data: Date | null) {
        if(data == null) {
            return;
        }
        this.assignmentStartDate = data;
        this.maxEndDate = null;
        this.assignmentEndDate = null;

        const timezone = this.$store.state.auth.loggedInUser.timezone;
        const assignments: PatientDeviceAssignment[] = [];
        if(this.patient.assignments !== undefined && this.patient.assignments.length > 0) {
            assignments.push(...this.patient.assignments)
        }
        if(this.selectedDevice !== null && this.selectedDevice.device.assignments !== undefined && this.selectedDevice.device.assignments.length > 0) {
            assignments.push(...this.selectedDevice.device.assignments);
        }

        assignments.sort((a: PatientDeviceAssignment, b: PatientDeviceAssignment) => {
            return (a.startDate as Moment).diff((b.startDate as Moment))
        })

        const currentStartDate = convertToMomentTimezone(timezone, this.assignmentStartDate.toISOString()).startOf('day')
        for(let i = 0; i < assignments.length; i++) {
            if((assignments[i].startDate as Moment).isBetween(currentStartDate, this.today) || (assignments[i].startDate as Moment).isSameOrAfter(this.today)) {
                this.maxEndDate = (assignments[i].startDate as Moment).endOf('day').toDate();
                if(!this.forcePastAssignment) {
                    this.forcePastAssignment = true;
                }
                break;
            }
        }
    }

    /**
     * Fetches the disabled dates on the date selector
     * @param assignmentId
     */
    getDisabledDates(assignmentId: number) {
        const dates: DateOpt[] = [];
        this.patientCurrentAssignments.forEach((assignment) => {
            if(assignment.id !== assignmentId) {
                dates.push(this.processAssignment(assignment))
            }
        });

        if(this.selectedDevice !== null && this.selectedDevice.device.assignments !== undefined && this.selectedDevice.device.assignments.length > 0) {
            this.selectedDevice.device.assignments.forEach((assignment) => {
                if(assignment.id !== assignmentId) {
                    dates.push(this.processAssignment(assignment));
                }
            })
        }
        return dates;
    }

    /**
     * Converts the assignment information into a list of start/end dates
     * @param assignment
     */
    processAssignment(assignment: PatientDeviceAssignment) {
        if(assignment.endDate !== null){
            return {
                start: (assignment.startDate as Moment).toDate(),
                end: (assignment.endDate as Moment).toDate()
            };
        } else if(this.forcePastAssignment && (this.patient.assignments.length > 0 && assignment.id == this.patient.assignments[0].id)) {
            return {
                start: (assignment.startDate as Moment).toDate(),
                end: moment.tz(this.$store.state.auth.loggedInUser.timezone).toDate()
            }
        } else {
            return {
                start: (assignment.startDate as Moment).toDate(),
                end: (assignment.startDate as Moment).toDate()
            }
        }
    }

    /**
     * Whether the current patient has an active assignment
     */
    get patientHasCurrentAssignment() {
        return this.patientCurrentAssignments.filter((assignment) => assignment.endDate === null).length > 0;
    }

    /**
     * Whether the selected device has a current assignment
     */
    get deviceHasCurrentAssignment() {
        return this.selectedDevice !== null && this.selectedDevice.device.assignments !== undefined && this.selectedDevice.device.assignments.filter((assignment) => assignment.endDate === null).length > 0;
    }

    /**
     * Whether the selected device has an assignment that cannot be managed
     */
    get deviceHasUnmanageableAssignment(): boolean {
        if(this.selectedDevice === null || this.selectedDevice.device.assignments === undefined){
            return false;
        }
        return this.deviceHasCurrentAssignment && this.selectedDevice.device.assignments[0].patient === null;
    }

    /**
     * Formats the end date of the previous assignment that would be ended
     */
    get oldAssignmentEnd() {
         if(this.assignmentStartDate !== null ){
            const date = convertToMomentTimezone(this.$store.state.auth.loggedInUser.timezone, this.assignmentStartDate.toISOString()).subtract(1, 'day').endOf('day');
            return formatMomentDateOnly(date)
        }
    }

    /**
     * Determines if the warning icon should be shown
     */
    get shouldShowWarningIcon() {
        return (this.patientHasCurrentAssignment && this.selectedDevice !== null && this.assignmentStartDate !== null && !this.forcePastAssignment) ||
            (this.deviceHasCurrentAssignment && this.assignmentStartDate !== null && !this.forcePastAssignment) ||
            (this.deviceHasUnmanageableAssignment)
    }

    /**
     * Returns the options for the start date picker
     */
    get startDatePickerOptions() {
        if(this.selectedDevice === null || this.selectedDevice.device.assignments === undefined) {
            return;
        }
        let minDate: Date | null = (this.selectedDevice.earliestAssignableDate as Moment).toDate();

        let maxDate: Date = this.today.clone().add(1, 'days').startOf('day').toDate();

        if(this.deviceHasUnmanageableAssignment) {
            maxDate = (this.selectedDevice.device.assignments[0].startDate as Moment).toDate()
        } else if (this.forcePastAssignment) {
            maxDate = this.today.clone().toDate();
        }

        return {
            disabledDates: [...this.getDisabledDates(0)],
            mode: 'single',
            popover: {
                visibility: this.selectedDevice !== null ? 'hover-focus' : 'hidden', placement: 'bottom'
            },
            minDate,
            maxDate
        }
    }

    /**
     * Creates the options for the end date picker
     */
    get endDatePickerOptions() {
        if (this.assignmentStartDate === null || this.selectedDevice === null || this.selectedDevice.device.assignments === undefined) {
            return {
                mode: 'single',
                popover: {
                    visibility: this.selectedDevice !== null ? 'hover-focus' : 'hidden', placement: 'bottom'
                },
            };
        }

        let maxDate: Date = this.today.clone().add(1, 'days').startOf('day').toDate();

        if(this.deviceHasUnmanageableAssignment) {
            maxDate = (this.selectedDevice.device.assignments[0].startDate as Moment).toDate()
        } else if (this.forcePastAssignment) {
            maxDate = this.today.clone().toDate();
        }

        return {
            disabledDates: [...this.getDisabledDates(0)],
            mode: 'single',
            popover: {
                visibility: this.selectedDevice !== null ? 'hover-focus' : 'hidden', placement: 'bottom'
            },
            minDate: this.assignmentStartDate,
            maxDate: this.maxEndDate !== null ? this.maxEndDate : maxDate
        }
    }

    /**
     * Sends the request to create a new assignment
     */
    async createNewAssignment(){
        if(this.selectedDevice === null || this.assignmentStartDate === null) {
            return;
        }

        let assignmentInfo: PatientDeviceAssignmentCreate = {
            deviceId: this.selectedDevice.device.id,
            startDate: convertToMomentTimezone(this.$store.state.auth.loggedInUser.timezone, this.assignmentStartDate.toISOString()).startOf('day'),
            endDate: this.assignmentEndDate !== null ? moment.tz(this.assignmentEndDate, this.$store.state.auth.loggedInUser.timezone).endOf('day') : null
        };

        if(this.patientHasCurrentAssignment && !this.forcePastAssignment){
            const patientAssignmentEndResponse = await this.patientDeviceAssignmentService.updateAssignment(this.patient.id as number, {
                id: this.patientCurrentAssignments[0].id,
                startDate: this.patientCurrentAssignments[0].startDate,
                endDate: (assignmentInfo.startDate as Moment).clone().subtract(1, 'day').endOf('day')
            });
            if(patientAssignmentEndResponse.isFailure()) {
                return;
            }
        }

        if(this.deviceHasCurrentAssignment && !this.forcePastAssignment) {
            if(this.selectedDevice.device.assignments === undefined || this.selectedDevice.device.assignments[0].patient === null || this.selectedDevice.device.assignments[0].patient === undefined) {
                return;
            }
            const deviceAssignmentEndResponse = await this.patientDeviceAssignmentService.updateAssignment(this.selectedDevice.device.assignments[0].patient.id as number, {
                id: this.selectedDevice.device.assignments[0].id,
                startDate: this.selectedDevice.device.assignments[0].startDate as Moment,
                endDate: (assignmentInfo.startDate as Moment).clone().subtract(1, 'day').endOf('day')
            });
            if(deviceAssignmentEndResponse.isFailure()) {
                return;
            }
        }

        const response = await this.patientDeviceAssignmentService.createAssignment(this.patient.id as number, assignmentInfo);

        response.map((assignment) => {
            this.$emit('newAssignment', assignment);
        });
        this.selectedDevice = null;
        this.assignmentStartDate = null;
        this.assignmentEndDate = null;
        if(this.$v.assignmentStartDate !== undefined) {
            this.$v.assignmentStartDate.$reset();
        }
        await this.fetchAssignableDevices();
        this.selectedTab = Tabs.EDIT_ASSIGNMENTS
    }
}
