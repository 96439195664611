import {Moment} from 'moment';
import {AsyncEither, AsyncNullSuccess, Either, Failure, Success} from '@/errors/Either';
import {WeightHistoryExportData, WeightHistoryReport} from '@/models/reports/WeightHistory';
import {Error} from '@/errors/Error';
import axios from 'axios';
import {Weight} from '@/models/patients/Patient';
import {convertToMomentTimezone} from '@/ts/TimezoneUtils';
import {saveDownloadedFile} from '@/ts/utilities';


export default class PatientWeightHistoryReportService {
    private readonly timezone: string;


    constructor(timezone: string) {
        this.timezone = timezone;
    }

    /**
     * Fetches a weight history report for a patient
     * @param patientId ID of the patient
     * @param startDate Start date of the report
     * @param endDate End date of the report
     */
    // tslint:disable-next-line:max-line-length
    public async fetchWeightHistoryReport(patientId: number, startDate: Moment | Date, endDate: Moment | Date): AsyncEither<WeightHistoryReport, Error> {
        try {
            // tslint:disable-next-line:max-line-length
            const {data}: {data: WeightHistoryReport} = await axios.get(process.env.VUE_APP_BACKEND_URI + '/patients/' + patientId + '/reports/weight-history', {
                params: {
                    startDate: startDate.toISOString(),
                    endDate: endDate.toISOString(),
                },
            });
            data.weights.forEach((record: Weight) => {
                record.date = convertToMomentTimezone(this.timezone, record.date as string);
            });
            data.startDate = convertToMomentTimezone(this.timezone, data.startDate as string);
            data.endDate = convertToMomentTimezone(this.timezone, data.endDate as string);
            return new Success(data);
        } catch (e) {
            return new Failure({
                message: 'Failed to generate weight history report',
            });
        }
    }

    /**
     * Exports the weight history report as an excel document
     * @param patientId ID of the patient
     * @param startDate Start date of the report
     * @param endDate End date of the report
     */
    // tslint:disable-next-line:max-line-length
    public async exportWeightHistoryReport(patientId: number, startDate: Moment | Date, endDate: Moment | Date): AsyncNullSuccess<Error> {
        try {
            // tslint:disable-next-line:max-line-length
            const response =  await axios.get(process.env.VUE_APP_BACKEND_URI + '/patients/' + patientId + '/reports/weight-history/csv', {
                params: {
                    startDate: startDate.toISOString(),
                    endDate: endDate.toISOString(),
                },
                responseType: 'blob',
            });
            saveDownloadedFile(response, 'weight-history-report.xlsx');
            return new Success(null);
        } catch (e) {
            return new Failure({
                message: 'Unable to export weight history report',
            });
        }
    }

    /**
     * Exports a weight history report as a PDF
     * @param patientId
     * @param startDate
     * @param endDate
     * @param weightHistoryExportData
     */
    // tslint:disable-next-line:max-line-length
    public async exportWeightHistoryReportAsPDF(patientId: number, startDate: Moment | Date, endDate: Moment | Date, weightHistoryExportData: WeightHistoryExportData): AsyncNullSuccess<Error> {
        try {
            // tslint:disable-next-line:max-line-length
            const response =  await axios.post(process.env.VUE_APP_BACKEND_URI + '/patients/' + patientId + '/reports/weight-history/pdf', weightHistoryExportData, {
                params: {
                    startDate: startDate.toISOString(),
                    endDate: endDate.toISOString(),
                },
                responseType: 'blob',
            });
            saveDownloadedFile(response, 'weight-history-report.pdf');
            return new Success(null);
        } catch (e) {
            return new Failure({
                message: 'Unable to export weight history report',
            });
        }
    }
}
