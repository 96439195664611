import { render, staticRenderFns } from "./PatientPrescriptionQuickLink.vue?vue&type=template&id=58c573ba&scoped=true&"
import script from "./PatientPrescriptionQuickLink.vue?vue&type=script&lang=ts&"
export * from "./PatientPrescriptionQuickLink.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58c573ba",
  null
  
)

export default component.exports