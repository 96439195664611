











import { Component, Vue } from 'vue-property-decorator';
import PumpSettingsTable from '@/components/reports/settings-report/PumpSettingsTable.vue';

/**
 * Renders the page for showing the pump settings report for a specific device
 */
@Component({
    components: {
        PumpSettingsTable
    }
})
export default class PumpSettingsReport extends Vue {

    get id(){
        return this.$route.params.id;
    }
}
