


























import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import SideNavData from "@/components/navigation/SideNavData";
import IconDisplay from '@/components/utility/IconDisplay.vue';

/**
 * Renders a side bar nav item and any of its children
 */
@Component({
    components: {
        IconDisplay,
    }
})
export default class SideNavItem extends Vue {
    /**
     * How deeply the navigation is nested
     */
    @Prop(Number) nestingLevel!: number;

    /**
     * Current navigation item
     */
    @Prop() item!: SideNavData;

    /**
     * Whether the item is expanded
     */
    expanded = false;

    /**
     * Whether the item is currently active
     */
    active = false;

    /**
     *
     */
    hasActiveChild = false;

    mounted(){
        if(this.item.routeKey !== null && this.item.routeKey !== undefined && this.item.routeKey === this.$route.meta.activeSideNav){
            this.active = true;
        }
        if(this.item.childRouteKeys !== null && this.item.childRouteKeys !== undefined && this.item.childRouteKeys.includes(this.$route.meta.activeSideNav)){
            this.expanded = true;
            this.hasActiveChild = true;
        }
    }

    childNavItemIsActive(item: SideNavData) {
        return item.routeKey !== undefined && item.routeKey === this.$route.meta.activeSideNav;
    }

    /**
     * Updates the currently active navigation item whenever the current route changes
     */
    @Watch('$route')
    routeChanged(){
        if(this.item.routeKey !== null && this.item.routeKey !== undefined && this.item.routeKey === this.$route.meta.activeSideNav){
            this.active = true;
        }else{
            this.active = false;
        }
        if(this.item.childRouteKeys !== null && this.item.childRouteKeys !== undefined && this.item.childRouteKeys.includes(this.$route.meta.activeSideNav)){
            this.expanded = true;
            this.hasActiveChild = true;
        } else {
            this.expanded = false;
            this.hasActiveChild = false;
        }
    }

    /**
     * Returns extra classes to apply when the item is active
     */
    get activeClasses(){
        return [{
            'bg-purple-primary': this.active || this.expanded || this.hasActiveChild,
            'text-white': this.active || this.expanded || this.hasActiveChild,
            'border-b border-nav-border': !this.expanded,
            'hover:bg-gray-175': !this.active && !this.expanded && !this.hasActiveChild
        }]
    }

    /**
     * Creates the padding class based on the level of nesting
     */
    get nestClass(){
        const updatedNest = SideNavItem.generateNestingLevel(this.nestingLevel);
        return 'pl-'+updatedNest
    }

    /**
     * Generates the padding level based on the level of nesting
     * @param level
     */
    static generateNestingLevel(level: number){
        const defaultNest = 8;
        return defaultNest + (level * 4);
    }

    /**
     * Handles click events on the nav items
     * @param event
     */
    clickHandler(event: any){
        if (this.item.children.length > 0){
            this.expanded = !this.expanded;
            if(this.expanded && this.item.children[0]?.clickHandler !== undefined && !(this.active || this.hasActiveChild)) {
                this.item.children[0].clickHandler(event);
            }
        }else if (this.item.clickHandler !== undefined){
            this.item.clickHandler(event);
        }
    }
}
