import {Customer, CustomerListRequest} from '@/models/customers/Customer';
import {Module} from 'vuex';
import axios from 'axios';
import User from '@/models/User';
import {convertToMomentTimezone, convertToTimezone} from '@/ts/TimezoneUtils';

export interface CustomerModel {
    customerList: Customer[];
    maintenanceModeActive: boolean;
}

export enum CustomerGetters {

}

export enum CustomerMutations {
    SET_CUSTOMER_LIST = 'setCustomerList',
    SET_MAINTENANCE_MODE_ACTIVE = 'setMaintenanceModeActive',
}

export enum CustomerActions {
    FETCH_CUSTOMER_LIST = 'fetchCustomerList',
}

interface CustomerListData {
    customers: Customer[];
    user: User;
}

const customers: Module<CustomerModel, any> = {
    namespaced: true,
    state: {
        customerList: [],
        maintenanceModeActive: false
    },
    mutations: {
        [CustomerMutations.SET_CUSTOMER_LIST](state, data: CustomerListData){
            const timeZone = data.user.timezone as string;
            for (const customer of data.customers) {
                if (customer.lastUserLogin != null) {
                    customer.lastUserLogin = convertToMomentTimezone(timeZone, customer.lastUserLogin as string);
                }
            }
            state.customerList = data.customers;
        },
        [CustomerMutations.SET_MAINTENANCE_MODE_ACTIVE](state, data){
            state.maintenanceModeActive = data;
        },
    },
    actions: {
        async [CustomerActions.FETCH_CUSTOMER_LIST](context, request: CustomerListRequest) {
            const loggedInUser = context.rootState.auth.loggedInUser;
            const {data} = await axios.get(process.env.VUE_APP_BACKEND_URI + '/customers', {params: request});
            context.commit(CustomerMutations.SET_CUSTOMER_LIST,
                {customers: data, user: loggedInUser} as CustomerListData);
        },
    },
};

export default customers;
