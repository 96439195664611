
import {Component, Prop, Vue} from "vue-property-decorator";
import {Doughnut, mixins as chartMixins} from 'vue-chartjs'
import {mixins} from "vue-class-component";
import Chart from "chart.js";

/**
 * Renders a doughnut chart showing a patient's adherence percentage
 */
@Component({
    extends: Doughnut
})
export default class AdherenceDoughnutChart extends mixins(Doughnut, chartMixins.reactiveData) {
    /**
     * Adherence percentage to show
     */
    @Prop() adherence!: number;

    created(){
        Chart.pluginService.register({
            id: 'centerTextPlugin',
            beforeDraw: function(chart: Chart) {
                let width = chart.width as number,
                    height = chart.height as number,
                    ctx = chart.ctx as CanvasRenderingContext2D;

                ctx.restore();


                if((chart.config.options as any).centerText !== undefined){
                    let fontSize = (height / 60).toFixed(2);
                    ctx.font = fontSize + "em sans-serif";
                    ctx.textBaseline = "middle";
                    let text = (chart.config.options as any).centerText.text,
                        textX = Math.round((width - ctx.measureText(text).width) / 2),
                        textY = height / 2;

                    ctx.fillStyle = "#000000";
                    ctx.fillText(text, textX, textY);
                }


                ctx.save();
            }
        });
        let graySize = this.adherence >= 100 ? 0 : 100-this.adherence;
        //@ts-ignore
        this.chartData = {
            datasets: [
                {
                    label: 'Adherence',
                    data: [this.adherence, graySize],
                    backgroundColor: [
                        '#745CC6',
                        '#9e9e9e'
                    ],
                    borderColor: [
                        'transparent',
                        'transparent'
                    ],
                    borderWidth: 1
                }
            ]
        }
    }

    get options(){
        return {
            cutoutPercentage: 85,
            responsive: true,
            legend: {
                display: false
            },
            centerText: {
                text: `${this.adherence.toFixed(0)}%`
            },
            tooltips: {enabled: false},
            hover: {mode: null},
        }
    }

    mounted () {
        // Overwriting base render method with actual data.
        //@ts-ignore
        this.renderChart(this.chartData, this.options);
    }
}
