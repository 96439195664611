import {Module} from 'vuex';

export enum ErrorState {
    MAINTENANCE_MODE = 'maintenanceModeError',
}

export interface ErrorModel {
    [ErrorState.MAINTENANCE_MODE]: string;
}

export enum ErrorMutations {
    SET_MAINTENANCE_MODE = 'setMaintenanceMode',
}

const errors: Module<ErrorModel, any> = {
    namespaced: true,
    state: {
        maintenanceModeError: 'The application is currently down for maintenance mode'
    },
    mutations: {
        [ErrorMutations.SET_MAINTENANCE_MODE](state, message) {
            state.maintenanceModeError = message;
        },
    },
};

export default errors;

