import {Moment} from 'moment';
import {AsyncEither, AsyncNullSuccess, Either, Failure, nullSuccess, Success} from '@/errors/Either';
import {PumpErrorFilter, PumpErrorReportData} from '@/models/reports/PumpErrors';
import {Error} from '@/errors/Error';
import axios from 'axios';
import {AlertRecord} from '@/models/Devices/MARRS/Records';
import {convertToMomentTimezone} from '@/ts/TimezoneUtils';
import {saveDownloadedFile} from '@/ts/utilities';


export default class PatientPumpErrorReportService {
    private readonly timezone: string;


    constructor(timezone: string) {
        this.timezone = timezone;
    }

    /**
     * Fetches the pump errors report for a patient
     * @param patientId ID of the patient
     * @param startDate Start date of the report
     * @param endDate End date of the report
     */
    // tslint:disable-next-line:max-line-length
    public async fetchPatientPumpErrorReport(patientId: number, startDate: Moment | Date, endDate: Moment | Date): AsyncEither<PumpErrorReportData, Error> {
        try {
            const {data}: {data: PumpErrorReportData} =
                await axios.get(process.env.VUE_APP_BACKEND_URI + '/patients/' + patientId + '/reports/error-report', {
                    params: {
                        startDate: startDate.toISOString(),
                        endDate: endDate.toISOString(),
                    },
                });
            data.alertRecords.forEach((record: AlertRecord) => {
                record.timestamp = convertToMomentTimezone(this.timezone, record.timestamp as string);
            });
            return new Success(data);
        } catch (e) {
            return new Failure({
                message: 'Failed to get pump error report',
            });
        }
    }

    /**
     * Exports the patient pump errors report as an Excel document
     * @param patientId ID of the patient
     * @param startDate Start date of the report
     * @param endDate End date of the report
     * @param filter Filters to apply to the export
     */
    // tslint:disable-next-line:max-line-length
    public async exportPumpErrorReportAsExcel(patientId: number, startDate: Moment | Date, endDate: Moment | Date, filter: PumpErrorFilter): AsyncNullSuccess<Error> {
        try {
            const response =
                // tslint:disable-next-line:max-line-length
                await axios.post(process.env.VUE_APP_BACKEND_URI + '/patients/' + patientId + '/reports/error-report/csv', filter, {
                    params: {
                        startDate: startDate.toISOString(),
                        endDate: endDate.toISOString(),
                    },
                    responseType: 'blob',
                });
            saveDownloadedFile(response, 'patient-pump-error-report.xlsx');
            return nullSuccess();
        } catch (e) {
            return new Failure({
                message: 'Failed to get pump error report',
            });
        }
    }

    /**
     * Exports the patient pump errors report as a PDF
     * @param patientId ID of the patient
     * @param startDate Start date of the report
     * @param endDate End date of the report
     * @param filter Filters to apply to the export
     */
    // tslint:disable-next-line:max-line-length
    public async exportPumpErrorReportAsPdf(patientId: number, startDate: Moment | Date, endDate: Moment | Date, filter: PumpErrorFilter): AsyncNullSuccess<Error> {
        try {
            const response =
                // tslint:disable-next-line:max-line-length
                await axios.post(process.env.VUE_APP_BACKEND_URI + '/patients/' + patientId + '/reports/error-report/pdf', filter, {
                    params: {
                        startDate: startDate.toISOString(),
                        endDate: endDate.toISOString(),
                    },
                    responseType: 'blob',
                });
            saveDownloadedFile(response, 'patient-pump-error-report.pdf');
            return nullSuccess();
        } catch (e) {
            return new Failure({
                message: 'Failed to get pump error report',
            });
        }
    }
}
