




import { Component, Vue } from 'vue-property-decorator';

import oidcSettings from "@/config/oidc";
import {vuexOidcProcessSilentSignInCallback} from 'vuex-oidc';



@Component
export default class SilentRenew extends Vue {

    mounted(){
        vuexOidcProcessSilentSignInCallback(oidcSettings);
    }
}
