/**
 * Different types of buttons that can be displayed
 */
export enum ButtonType {
    PRIMARY,
    SECONDARY,
    TERTIARY,
    DEFAULT,
    TRANSPARENT,
    DARK,
    GREEN,
    NONE,
}

/**
 * Data type for data passed to radio buttons
 */
export interface RadioButtonData {
    label: string;
    value: string | number;
}

/**
 * Different states for checkboxes
 */
export enum CheckboxStateData {
    UNCHECKED,
    INDETERMINATE,
    CHECKED,
}
