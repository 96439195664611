




















































































import { Component, Vue } from 'vue-property-decorator';
import {Team} from '@/models/Teams';
import axios from 'axios';
import {ButtonType} from "@/components/form/FormTypes";
import Modal from '@/components/modal/Modal.vue';
import {maxLength, required} from "vuelidate/lib/validators";
import {Validations} from '@/ts/validation-decorator';
import {namespace} from "vuex-class";
import {ModalMutations} from "@/vuex/modal";
import User from '@/models/User';
import SelectedTeam from '@/components/teams/SelectedTeam.vue';
import HelpModal from '@/components/help/HelpModal.vue';
import Spinner from '@/components/form/Spinner.vue';
import ConfirmationModal from '@/components/modal/ConfirmationModal.vue';
import {sortBy} from '@/ts/utilities';

const modal = namespace('modal');
const auth = namespace('auth');

@Component({
    components:{
        Modal,
        SelectedTeam,
        HelpModal,
        Spinner,
        ConfirmationModal,
    }
})
export default class TeamList extends Vue {
    teamList: Team[] = [];
    ButtonType = ButtonType;
    name = '';
    createModalShowing = false;
    createLoading = false;
    listLoading = true;
    selectedTeam: Team | null = null;

    editingTeam: Team | null = null;
    editModalShowing = false;

    removeModalShowing = false;
    topListShowing = false;

    editName = '';

    @modal.Mutation(ModalMutations.CHANGE_MODAL_STATE) changeModalState!: (showing: boolean) => void;
    @auth.State('loggedInUser') loggedInUser!: User;

    @Validations()
    validations(){
        return {
            name: {
                required,
                maxLength: maxLength(30),
            },
            editName: {
                required,
                maxLength: maxLength(30),
            }
        }

    }

    async mounted() {
        let team = -1;
        if(this.$route.query.hasOwnProperty('team')) {
            team = Number.parseInt(this.$route.query.team as string);
        }
        await this.fetchTeams(team);
    }

    async fetchTeams(initialTeam: number){
        this.listLoading = true;
        this.selectedTeam = null;
        try{
            const {data} = await axios.get(process.env.VUE_APP_BACKEND_URI + "/teams");
            this.teamList = data;
            this.teamList = this.teamList.sort(sortBy('name', true))
             if(this.teamList.length > 0 && initialTeam > 0) {
                this.selectTeam(initialTeam);
            }
            this.listLoading = false;
        }catch(e){
            console.log(e);
        }
    }

    async touchField(field: string){
        this.$v[field].$touch();
    }

    showEditModal(){
        if(this.selectedTeam === null) {
          return;
        }
        this.editingTeam = this.selectedTeam as Team;
        this.editName = this.editingTeam.name;
        this.editModalShowing = true;
        this.changeModalState(true);
        this.$v.$reset();
    }

    dismissEditModal(){
        this.editModalShowing = false;
        this.changeModalState(false);
        this.editingTeam = null;
        this.editName = '';
    }

    async editTeam(){
        if(this.editingTeam === null){
            this.dismissEditModal();
            return;
        }
        const customerId = this.loggedInUser.customers.length > 0 ? this.loggedInUser.customers[0].id : null;
        this.createLoading = true;
        try{
            const {data} = await axios.patch(process.env.VUE_APP_BACKEND_URI + '/teams/'+this.editingTeam.id, {
                name: this.editName
            });
            const foundTeam = this.teamList.find(team => team.id === (this.editingTeam as Team).id);
            if(foundTeam != undefined){
                foundTeam.name = this.editName;
            }
        }catch(e){
            console.log(e);
        }
        this.createLoading = false;
        this.dismissEditModal();
    }

    showCreateModal(){
        this.topListShowing = false;
        this.changeModalState(true);
        this.createModalShowing = true;
        this.$v.$reset();
    }

    async createTeam(){
        const customerId = this.loggedInUser.customers.length > 0 ? this.loggedInUser.customers[0].id : null;
        this.createLoading = true;
        try{
            const {data} = await axios.post(process.env.VUE_APP_BACKEND_URI + '/teams', {
                name: this.name,
                customerId: customerId
            });
            this.$addSnackbarMessage({
                message: 'Team successfully created',
            });
            this.selectedTeam = data;
        }catch(e){
            this.$addSnackbarMessage({
                message: 'There was an issue creating a new team. Try again later or contact customer support.',
            });
        }
        this.createLoading = false;
        this.dismissCreateModal();
        if(this.selectedTeam != null) {
            await this.fetchTeams(this.selectedTeam.id);
        }
    }

    dismissCreateModal(){
        this.createModalShowing = false;
        this.changeModalState(false);
        this.name = '';
    }

    selectTeam(index: number){
        const team = this.teamList.find(team => team.id === index);
        this.selectedTeam = team != undefined ? team : null;
    }

    showRemoveModal(){
        if(this.selectedTeam === null) {
          return;
        }
        this.removeModalShowing = true;
        this.editingTeam = this.selectedTeam as Team;
        this.changeModalState(true);
    }

    async removeTeam(){
        if(this.editingTeam === null){
            this.dismissRemoveModal();
            return;
        }

        try{
            const {data} = await axios.delete(process.env.VUE_APP_BACKEND_URI + '/teams/' + this.editingTeam.id);
            this.dismissRemoveModal();
            this.$addSnackbarMessage({
                message: 'Team successfully removed',
            });
            await this.fetchTeams(-1);
        }catch(e){
            this.$addSnackbarMessage({
                message: 'There was an issue removing the team. Try again later or contact customer support.',
            });
        }
    }

    dismissRemoveModal(){
        this.removeModalShowing = false;
        this.changeModalState(false);
        this.editingTeam = null;
    }
}
