




























import {Component, Prop, Vue} from "vue-property-decorator";
import {Hub} from '@/models/Devices/Hubs/Hub';
import {MARRSPump} from "@/models/Devices/MARRS/MARRSPump";

/**
 * Renders the list of pumps associated with a specific wireless hub
 */
@Component
export default class AssociatedPumps extends Vue {

    /**
     * Hub to create list for
     */
    @Prop({type: Object}) hub!: Hub;

    /**
     * Determines the number of pumps per column
     * Defaults to 10 if the number of pumps is less than 40
     * Otherwise divides the number by 4
     */
    get columnSize(){
        if(this.hub.associatedPumpsData.length <= 40){
            return 10;
        }else{
            return Math.ceil(this.hub.associatedPumpsData.length / 4);
        }
    }

    /**
     * Creates the array of pumps for the first column
     */
    get columnOne(): MARRSPump[]{
        return this.hub.associatedPumpsData.slice(0, this.columnSize);
    }

    /**
     * Creates the array of pumps for the second column
     */
    get columnTwo(): MARRSPump[]{
        if(this.hub.associatedPumpsData.length > this.columnSize){
            return this.hub.associatedPumpsData.slice(this.columnSize, this.columnSize * 2);
        }else {
            return [];
        }
    }

    /**
     * Creates the array of pumps for the third column
     */
    get columnThree(): MARRSPump[]{
        if (this.hub.associatedPumpsData.length > this.columnSize * 2){
            return this.hub.associatedPumpsData.slice(this.columnSize * 2, this.columnSize * 3);
        }else {
            return [];
        }
    }

    /**
     * Creates the array of pumps for the fourth column
     */
    get columnFour(): MARRSPump[]{
        if (this.hub.associatedPumpsData.length > this.columnSize * 3){
            return this.hub.associatedPumpsData.slice(this.columnSize * 3);
        }else {
            return [];
        }
    }
}
