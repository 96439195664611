

















































































import { Component, Vue } from 'vue-property-decorator';
import User, {MeasurementUnit} from '@/models/User';
import {required, minLength, email, requiredIf, sameAs} from "vuelidate/lib/validators";
import {AssignableToTeams, getRolesForRole, RoleNames} from "@/ts/permissions/Role";
import {ButtonType, RadioButtonData} from "@/components/form/FormTypes";
import {namespace} from 'vuex-class';
import {TimezoneUtils} from '@/ts/TimezoneUtils';
import UpdatePasswordForm from '@/views/Users/UpdatePasswordForm.vue';
import {doesNotContain, minLengthCountingZero, passwordComplexity} from "@/ts/validators";
import axios from 'axios';
import Multiselect from 'vue-multiselect';
import Spinner from '@/components/form/Spinner.vue';
import TeamSelector from '@/components/users/TeamSelector.vue';
import UserPermissions from '@/components/users/UserPermissions.vue';
import UserPreferences from '@/components/users/UserPreferences.vue';
import GeneralUserInfo from '@/components/users/GeneralUserInfo.vue';
import {Customer} from '@/models/customers/Customer';
import {Team} from '@/models/Teams';

const authNamespace = namespace('auth');
enum Tabs {
    PROFILE,
    PASSWORD
}

@Component({
    components: {
        UpdatePasswordForm,
        Multiselect,
        GeneralUserInfo,
        UserPreferences,
        UserPermissions,
        TeamSelector,
        Spinner
    },
})
export default class Profile extends Vue {
    @authNamespace.State('loggedInUser') currentUser!: User;
    @authNamespace.Action('getLoggedInUser') updateLoggedInUser: any;

    user: User = {
        id: 0,
        name: '',
        firstName: 'John',
        lastName: 'Doe',
        email: '',
        role: 'admin',
        teams: [],
        username: '',
        measurementUnit: MeasurementUnit.METRIC,
        timezone: '',
        customers: [],
        teamGroup: 0,
        lastPasswordUpdate: null,
        lastTermsConditionsAcceptance: null
    };

    password: any = {
        newPassword: '',
        confirmPassword: '',
    };
    passwordValid = false;
    assignableToTeams = AssignableToTeams;
    tabs = Tabs;
    currentTab = Tabs.PROFILE;
    selectedTimezone: any = null;
    selectedRole: any = null;
    teams: Team[] = [];
    systemTeam: Team | null = null;
    loading = false;
    roleDefinitions: any[] = [];
    @authNamespace.State('loggedInUser') loggedInUser!: User;

    validity: any = {
        'general': true,
        'preferences': true,
        'permissions': false,
        'teams': true
    };

    customerList: Customer[] = [];
    selectedCustomer: Customer | null = null;

    disabledPermissionFields = {
        userRole: true,
        customer: true
    };

    timezones = TimezoneUtils.getTimezonesNames();

    buttonTypes = ButtonType;

    async mounted(){
        this.loading = true;
        this.user = { ... this.currentUser};
        this.user.teamGroup = 0;
        for(let timezone of this.timezones){
            if(this.user.timezone === timezone.tzName){
                this.selectedTimezone = timezone;
                break;
            }
        }

        this.roleDefinitions = getRolesForRole(this.loggedInUser.role);

        if(this.user.teams == null || this.user.teams.length == 0){
            this.user.teamGroup = 0;
        }else if(this.user.teams.find(team => team.type != undefined && team.type == 0) != undefined){
            this.user.teamGroup = 1;
        }else{
            this.user.teamGroup = 2;
        }

        if(this.loggedInUser.customers.length === 0){
            this.customerList = [];
        }else{
            this.selectedCustomer = this.loggedInUser.customers[0];
            this.customerList = [this.selectedCustomer];
        }

        for(let role of this.roleDefinitions){
            if(this.user.role === role.id){
                this.selectedRole = role;
                break;
            }
        }

        this.loading = false;
    }

    roleChanged(value: any){
        if(value == null){
            this.user.role = '';
            this.selectedRole = null;
        }else{
            this.user.role = value.id;
            this.selectedRole = value;
        }

    }

    timezoneChanged(value: any){
        if(value == null){
            this.user.timezone = '';
            this.selectedTimezone = null;
        }else{
            this.user.timezone = value.tzName;
            this.selectedTimezone = value;
        }

    }

    handleValidityChange(area: string, validity: boolean){
        this.validity[area] = !validity;
    }

    get isValid(){
        return this.validity.general
            && this.validity.preferences;
    }

    async updateUser(){
        const requestData = {
            user: {...this.user}
        };
        delete requestData.user.teamGroup;
        delete requestData.user.teams;
        try{
            const {status} = await axios.patch(process.env.VUE_APP_BACKEND_URI + '/users/'+this.user.id, requestData);
            if(status < 300){
                await this.updateLoggedInUser();
                this.$router.back();
            }
            this.$addSnackbarMessage({
                message: 'Profile updated successfully',
            });
        }catch(e){
            this.$addSnackbarMessage({
                message: 'There was an issue updating your profile. Try again later or contact customer support.',
            });
        }
    }

    cancelPassword() {
        this.password.newPassword = '';
        this.password.confirmPassword = '';
    }

    async updatePassword(){
        const requestData = {
            password: this.password.newPassword,
            passwordConfirm: this.password.confirmPassword
        };
        try{
            const {status} = await axios.patch(process.env.VUE_APP_BACKEND_URI + '/users/'+this.user.id+'/password', requestData);
            this.currentTab = Tabs.PROFILE
        }catch(e){
            this.$addSnackbarMessage({
                message: 'There was an issue updating your password. Try again later or contact customer support.',
            });
        }
    }
}
