






























































import { Component, Vue, Prop } from 'vue-property-decorator';
import {doesNotContain, minLengthCountingZero, passwordComplexity} from "@/ts/validators";
import {requiredIf, sameAs} from "vuelidate/lib/validators";
import IconDisplay from '@/components/utility/IconDisplay.vue';

@Component({
    components: {
      IconDisplay,
    },
    validations: {
        username: {},
        newPassword: {
            minLength: minLengthCountingZero(8),
            doesNotContain: doesNotContain('username'),
            complexity: passwordComplexity
        },
        confirmPassword:{
            requiredIf: requiredIf('newPassword'),
            sameAs: sameAs('newPassword')
        }
    }
})
export default class UpdatePasswordForm extends Vue {
    @Prop() username!: string;
    @Prop({default: ""}) newPassword!: string;
    @Prop({default: ""}) confirmPassword!: string;

    get newPasswordIsValid(){
        if(this.$v.newPassword){
            return !this.$v.newPassword.$invalid;
        }else{
            return false;
        }
    }

    get confirmPasswordIsValid(){
        if(this.$v.confirmPassword){
            return !this.$v.confirmPassword.$invalid;
        }else{
            return false;
        }
    }

    emitEvent(field: string, data: string){
        this.$emit(field, data);
        this.touchField(field);
    }

    touchField(field: string){
        if((this.$v as Object).hasOwnProperty(field)) {
          this.$nextTick(() => {
            this.$v[field].$touch();
            this.$emit('invalid', this.$v.$invalid);
          })
        }
    }
}
