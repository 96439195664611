






import {Component, Prop, Vue} from "vue-property-decorator";

/**
 * Renders a loading spinner
 */
@Component
export default class Spinner extends Vue {

    /**
     * Height of the loading spinner
     */
    @Prop({type: String, default: "64px"}) height!: string;

    /**
     * Width of the loading spinner
     */
    @Prop({type: String, default: "64px"}) width!: string;
}
