


























import {Component, Prop, Vue} from "vue-property-decorator";
import Modal from "@/components/modal/Modal.vue";
import Table from "@/components/table/Table.vue";
import {TableCellData, TableCellType, TableHeaderData, TableRowData} from "@/components/table/cells/TableCellData";
import User from "@/models/User";
import {Role, RoleNames} from "@/ts/permissions/Role";
import Checkbox from '@/components/form/Checkbox.vue';
import axios from 'axios';
import {namespace} from "vuex-class";
import AssignedTeamsChipDisplay from "@/components/teams/AssignedTeamsChipDisplay.vue";
import {sortBy} from '@/ts/utilities';

const auth = namespace('auth');

/**
 * Renders a modal to assign multiple users to a single team
 */
@Component({
    components: {
        Modal,
        Table,
        Checkbox
    }
})
export default class AddUsersToTeamModal extends Vue {
    /**
     * Users already assigned to the team
     */
    @Prop() existingUsers!: User[];

    /**
     * ID of the team to assign users to
     */
    @Prop() teamId!: number;

    /**
     * List of users that can be assigned to this team
     */
    assignableUsers: User[] = [];

    /**
     * Whether the request to fetch the list of users is pending
     */
    loading = false;

    /**
     * Currently selected users
     */
    checkedUsers: User[] = [];

    /**
     * Field the table is currently sorted by
     */
    sortKey = 'firstName';

    /**
     * Whether the table is currently sorted ascending
     */
    sortedAsc = true;

    searchString = '';

    @auth.State('loggedInUser') loggedInUser!: User;

    async mounted(){
        this.loading = true;
        try{
            const promiseData = await Promise.all([this.fetchUsers(), new Promise(function(resolve) {setTimeout(resolve, 1000)})]);
            this.assignableUsers = promiseData[0] as User[];
            this.loading = false;
        }catch(e){
            console.log(e);
        }
    }

    /**
     * Sends the request to fetch the list of assignable users
     */
    async fetchUsers(): Promise<any>{
        const {data} = await axios.get(process.env.VUE_APP_BACKEND_URI + '/users', {
            params: {
                roles: Role.CLINICAL_VIEWER.toString() + "," + Role.CLINICAL_SUPPORT_VIEWER.toString()
            }
        });

        return data;
    }

    sortData(message: any){
        this.sortKey = message.sortKey;
        this.sortedAsc = message.sortedAsc;
    }

    /**
     * Sends the request to assign the users to the team
     */
    async assignUsersToTeam(){
        try{
            const {data} = await axios.patch(process.env.VUE_APP_BACKEND_URI + '/teams/' + this.teamId + '/users', {
                objects: this.checkedUsers.map((user: User) => user.id),
                customer: this.loggedInUser.customers.length > 0 ? this.loggedInUser.customers[0].id : null
            });
            this.dismiss();
        }catch(e){
            console.log(e);
        }
    }

    /**
     * Emits an event to dismiss the modal
     */
    dismiss(){
        this.$emit('dismiss');
    }

    /**
     * Table header definition
     */
    get tableHeader(): TableHeaderData[]{
        return [
            {
                sortable: false,
                value: "Actions",
                widthClass: "w-1/10",
            },
            {
                sortable: true,
                value: "Name",
                widthClass: "w-1/5",
                sortKey: 'firstName'
            },
            {
                sortable: false,
                value: "Role",
                widthClass: "w-1/5",
            },

            {
                sortable: false,
                value: "Assigned team(s)",
                widthClass: "w-1/2",
            },
        ]
    }

    /**
     * Transforms the list of assignable users into an array of table rows
     * Includes a checkbox so the rows can be selected
     */
    get tableData(): TableRowData[]{
        const vm = this;
        const checkedUsers = this.checkedUsers;
        return this.assignableUsers
            .filter(user => this.existingUsers.find(existingUser => existingUser.id === user.id) == undefined)
            .filter(this.filterTable)
            .sort(sortBy('lastName', this.sortedAsc))
            .map(user => {
                const checked = this.checkedUsers.find(checkedUser => checkedUser.id === user.id) != undefined;
                const cells = [
                    {
                      type: TableCellType.SLOT_LIST,
                      primaryValue: '',
                      components: [
                          {
                              primaryValue: 'Checkbox',
                              componentOptions: {
                                  label: '',
                                  checked: checked
                              },
                              events: {
                                  change: function(checked: boolean){
                                      if(checked){
                                          vm.checkedUsers.push(user);
                                      }else{
                                          const index = vm.checkedUsers.findIndex(checkedUser => checkedUser.id === user.id);
                                          if(index != -1){
                                              vm.checkedUsers.splice(index, 1);
                                          }
                                      }
                                  }
                              }
                          }
                      ]
                    },
                    {
                        type: TableCellType.TWO_ROW,
                        primaryValue: user.firstName + " " + user.lastName,
                        secondaryValue: user.email
                    },
                    {
                        type: TableCellType.NORMAL,
                        primaryValue: RoleNames[user.role]
                    }
                    ,
                    {
                        type: TableCellType.SLOT_LIST,
                        primaryValue:"",
                        components: [
                            {
                                primaryValue: AssignedTeamsChipDisplay,
                                componentOptions: {
                                    teams: user.teams,
                                    role: user.role
                                }
                            }
                        ]
                    }
                ]

                return {
                    cells,
                    highlighted: checked,
                    indexKey: user.id
                }
            });
    }

    searchData(data: string) {
        this.searchString = data;
    }

    filterTable(user: User) {
        if(this.searchString === '') {
            return true;
        }

        return user.firstName?.toLowerCase()?.includes(this.searchString.toLowerCase()) || user.lastName?.toLowerCase()?.includes(this.searchString.toLowerCase()) ||
            ((user.firstName !== undefined && user.lastName !== undefined) && (user.firstName.toLowerCase() + " " + user.lastName.toLowerCase()).includes(this.searchString.toLowerCase()))
    }
}
