import { render, staticRenderFns } from "./DeviceAssignmentModal.vue?vue&type=template&id=40022ef1&scoped=true&"
import script from "./DeviceAssignmentModal.vue?vue&type=script&lang=ts&"
export * from "./DeviceAssignmentModal.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40022ef1",
  null
  
)

export default component.exports