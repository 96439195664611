






import {Component, Prop, Vue} from "vue-property-decorator";

/**
 * Renders a configurable progress bar
 */
@Component
export default class ProgressBar extends Vue {
    /**
     * Whether the progress bar has a defined state or undefined state
     */
    @Prop({type: Boolean, default: true}) determinate!: boolean;

    /**
     * Color of the progress bar
     */
    @Prop({type: String, default: ''}) color!: string;

    /**
     * Width of the progress piece of the progress bar
     */
    @Prop({type: String, default: ''}) width!: string;
}
