






import {Component, Prop, Vue} from "vue-property-decorator";

/**
 * Renders a button in a rounded style
 */
@Component
export default class BubbleLink extends Vue {

    /**
     * Whether the button is currently active
     */
    @Prop() active!: boolean;

    /**
     * Value to display
     */
    @Prop() value!: string;
}
