







import {Component, Prop, Vue} from "vue-property-decorator";

/**
 * Renders a checkbox component
 */
@Component
export default class Checkbox extends Vue {
    /**
     * Label attached to the checkbox
     */
    @Prop({default: ''}) label!: string;

    /**
     * Whether the checkbox is checked
     */
    @Prop() checked!: boolean;

    /**
     * Whether the checkbox is in an indeterminate state
     */
    @Prop({default: false}) indeterminate!: boolean;

    /**
     * Whether the checkbox is disabled
     */
    @Prop({default: false}) disabled!: boolean;

    /**
     * Emits an event on state change
     */
    clicked(){
        if(!this.disabled) {
            this.$emit('change', !this.checked);
        }

    }
}
