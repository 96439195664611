









































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import {LockedScreen, MonitorModeRecord, MonitorModeScreenData} from "@/models/MARRS/MonitorMode/MonitorModeData";
import ScreenMap, {extraMessageMap, NumericalMap, ScreenData, ScreenType} from "@/models/MARRS/MonitorMode/ScreenInfo";

/**
 * Renders the screen of a Connect Pump
 */
@Component
export default class MARRSPumpScreen extends Vue {
    /**
     * Monitor mode record to use for display
     */
    @Prop({type: Object}) monitorModeRecord!: MonitorModeRecord | null;

    /**
     * Current width of the pump screen
     */
    marrsPumpWidth: number | null = null;

    /**
     * Whether the pump screen should apply a minimum size
     */
    @Prop({default: false}) hasMinSize!: boolean;

    mounted() {
        if(this.$refs.hasOwnProperty("marrsPumpWrapper") && this.$refs.marrsPumpWrapper !== undefined) {
            this.$set(this, 'marrsPumpWidth', (this.$refs.marrsPumpWrapper as HTMLElement).clientWidth);
        }
    }

    /**
     * Returns the screen info for the current screen number
     */
    get screenInfo(): ScreenData | null{
        if(this.monitorModeRecord != null && this.monitorModeRecord.screenNumber in ScreenMap){
            return ScreenMap[this.monitorModeRecord.screenNumber];
        }
        return null;
    }

    /**
     * Returns the class for the current screen type
     */
    get screenClass(): string {
        if(this.screenInfo != null){
            return this.screenInfo.type;
        }
        return '';
    }

    /**
     * Gets the title of the current pump screen
     */
    get title(): string {
        if(this.screenInfo != null){
            return this.screenInfo.title;
        }
        return '';
    }

    /**
     * Returns the HTML for the first region of the screen
     */
    get regionOne(){
        if(this.screenInfo != null && this.monitorModeRecord != null && 1 in this.screenInfo.regions){
            return this.screenInfo.regions[1].template(this.monitorModeRecord.screenData, this.monitorModeRecord.serialNumber, this.monitorModeRecord.language);
        }
        return null;
    }

    /**
     * Returns the HTML for the second region of the screen
     */
    get regionTwo(){
        if(this.screenInfo != null && this.monitorModeRecord != null && 2 in this.screenInfo.regions){
            return this.screenInfo.regions[2].template(this.monitorModeRecord.screenData, this.monitorModeRecord.serialNumber, this.monitorModeRecord.language);
        }
        return null;
    }

    /**
     * Returns the HTML for the third region of the screen
     */
    get regionThree(){
        if(this.screenInfo != null && this.monitorModeRecord != null && 3 in this.screenInfo.regions){
            return this.screenInfo.regions[3].template(this.monitorModeRecord.screenData, this.monitorModeRecord.serialNumber, this.monitorModeRecord.language);
        }
        return null;
    }

    /**
     * Returns the HTML for the fourth region of the screen
     */
    get regionFour(){
        if(this.screenInfo != null && this.monitorModeRecord != null && 4 in this.screenInfo.regions){
            return this.screenInfo.regions[4].template(this.monitorModeRecord.screenData, this.monitorModeRecord.serialNumber, this.monitorModeRecord.language);
        }
        return null;
    }

    /**
     * Returns the classes for the first region of the screen
     */
    get regionOneClass(){
        if(this.screenInfo != null && this.monitorModeRecord != null && 1 in this.screenInfo.regions && this.screenInfo.regions[1].regionClass !== undefined){
            return this.screenInfo.regions[1].regionClass(this.monitorModeRecord.screenData);
        }
        return '';
    }

    /**
     * Returns the classes for the second region of the screen
     */
    get regionTwoClass(){
        if(this.screenInfo != null && this.monitorModeRecord != null && 2 in this.screenInfo.regions && this.screenInfo.regions[2].regionClass !== undefined){
            return this.screenInfo.regions[2].regionClass(this.monitorModeRecord.screenData);
        }
        return '';
    }

    /**
     * Returns the classes for the third region of the screen
     */
    get regionThreeClass(){
        if(this.screenInfo != null && this.monitorModeRecord != null && 3 in this.screenInfo.regions && this.screenInfo.regions[3].regionClass !== undefined){
            return this.screenInfo.regions[3].regionClass(this.monitorModeRecord.screenData);
        }
        return '';
    }

    /**
     * Returns the classes for the fourth region of the screen
     */
    get regionFourClass(){
        if(this.screenInfo != null && this.monitorModeRecord != null && 4 in this.screenInfo.regions && this.screenInfo.regions[4].regionClass !== undefined){
            return this.screenInfo.regions[4].regionClass(this.monitorModeRecord.screenData);
        }
        return '';
    }

    /**
     * Whether the screen settings are currently locked
     */
    get screenLocked(){
        if(this.monitorModeRecord != null && this.isScreenLocked(this.monitorModeRecord.screenData)){
            return this.monitorModeRecord.screenData.screenLocked;
        }

        return false;
    }

    /**
     * Type guard to cast the screen data when the screen is locked
     * @param monitorModeRecord
     */
    isScreenLocked(monitorModeRecord: MonitorModeScreenData): monitorModeRecord is LockedScreen{
        return (monitorModeRecord as LockedScreen).screenLocked !== undefined;
    }

    /**
     * Returns the classes to apply when the pump is running
     */
    get runningModeClass(){
        if(this.screenInfo != null && this.screenInfo.type == ScreenType.RUNNING){
            return 'running-mode';
        }
        return '';
    }

    /**
     * Whether the pump is currently feeding
     */
    get isFeeding(){
        if(this.screenInfo != null){
            return this.screenInfo.type == ScreenType.RUNNING;
        }
        return false;
    }

    /**
     * The image for the current battery state of the pump
     */
    get batteryIconName(){
        if(this.monitorModeRecord == null) {
            return '';
        }
        const batteryPercent = this.monitorModeRecord.screenData.batteryPercentage;
        if(batteryPercent == 100){
            return require('../../../assets/MARRS/MonitorMode/battery-full.png');
        }else if(batteryPercent <= 99 && batteryPercent >= 68){
            return require('../../../assets/MARRS/MonitorMode/battery-four-fifths.png');
        }else if(batteryPercent <= 67 && batteryPercent >= 58){
            return require('../../../assets/MARRS/MonitorMode/battery-three-fifths.png');
        }else if(batteryPercent <= 57 && batteryPercent >= 35){
            return require('../../../assets/MARRS/MonitorMode/battery-two-fifths.png');
        }else if(batteryPercent <= 34 && batteryPercent >= 6){
            return require('../../../assets/MARRS/MonitorMode/battery-one-fifth.png');
        }else if(batteryPercent <= 5 && batteryPercent >= 2){
            return require('../../../assets/MARRS/MonitorMode/battery-one-fifth-yellow.png');
        }else if(batteryPercent <= 1){
            return require('../../../assets/MARRS/MonitorMode/battery-one-tenth-red.png');
        }
    }

    /**
     * Returns information about the buttons for the current screen
     */
    get buttonIconMap(){
        let buttonMap: NumericalMap<string> = {};
        if(this.monitorModeRecord != null && this.screenInfo != null){
            buttonMap[1] = 1 in this.screenInfo.buttons && this.screenInfo.buttons[1].buttonIcon !== undefined ? this.screenInfo.buttons[1].buttonIcon(this.monitorModeRecord.screenData) : '';
            buttonMap[2] = 2 in this.screenInfo.buttons && this.screenInfo.buttons[2].buttonIcon !== undefined ? this.screenInfo.buttons[2].buttonIcon(this.monitorModeRecord.screenData) : '';
            buttonMap[3] = 3 in this.screenInfo.buttons && this.screenInfo.buttons[3].buttonIcon !== undefined ? this.screenInfo.buttons[3].buttonIcon(this.monitorModeRecord.screenData) : '';
            buttonMap[4] = 4 in this.screenInfo.buttons && this.screenInfo.buttons[4].buttonIcon !== undefined ? this.screenInfo.buttons[4].buttonIcon(this.monitorModeRecord.screenData) : '';
            buttonMap[5] = 5 in this.screenInfo.buttons && this.screenInfo.buttons[5].buttonIcon !== undefined ? this.screenInfo.buttons[5].buttonIcon(this.monitorModeRecord.screenData) : '';
        }

        return buttonMap;
    }

    /**
     * Returns any extra messages to display
     */
    get extraMonitorModeMessages(){
        if(this.monitorModeRecord != null && extraMessageMap.hasOwnProperty(this.monitorModeRecord.screenNumber)){
            return extraMessageMap[this.monitorModeRecord.screenNumber];
        }
        return null;
    }

    /**
     * Returns the base font size to use for the monitor mode text
     */
    get marrsPumpFontSize() {
        if(this.marrsPumpWidth !== null) {
            if(this.marrsPumpWidth <= 578 && this.marrsPumpWidth > 547) {
                return 'marrs-15px';
            } else if(this.marrsPumpWidth <= 547 && this.marrsPumpWidth > 524) {
                return 'marrs-14px';
            } else if(this.marrsPumpWidth <= 524 && this.marrsPumpWidth > 503) {
                return 'marrs-13px';
            } else if(this.marrsPumpWidth <= 503) {
                return 'marrs-12px';
            }
        }
        return 'marrs-16px'
    }

}
