



























import { Component, Vue } from 'vue-property-decorator';
import User, {TermsAndConditions} from '@/models/User';
import {RawLocation, Route} from 'vue-router';
import {TermsAndConditionsService} from '@/services/TermsAndConditionsService';
import {ButtonType} from '@/components/form/FormTypes';
import {namespace} from 'vuex-class';
import {AuthMutations} from '@/vuex/auth';

const oidc = namespace('oidcStore');
const auth = namespace('auth');

Component.registerHooks([
    'beforeRouteEnter',
    'beforeRouteLeave',
    'beforeRouteUpdate'
]);

@Component
export default class TermsAndConditionsAgreement extends Vue {
    termsAndConditions: TermsAndConditions | null = null;
    buttonTypes = ButtonType;
    termsAndConditionsService = new TermsAndConditionsService(this.$store.state.auth.loggedInUser);
    user: User = this.$store.state.auth.loggedInUser;
    @oidc.Action('signOutOidc') signOut!: any;
    @auth.Mutation(AuthMutations.RESET_TERMS_AND_CONDITIONS) resetTermsConditions!: () => void;
    @auth.Mutation(AuthMutations.LOG_USER_OUT) clearUser!: () => void;

    baaAccepted = false;
    licenseAgreementAccepted = false;
    termsAndConditionsAccepted = false;

    async beforeRouteEnter(to: Route, from: Route, next: ((to?: RawLocation | false | ((vm: TermsAndConditionsAgreement) => any) | void) => void)){
        const termsAndConditionsService = new TermsAndConditionsService('UTC');
        const response = await termsAndConditionsService.getAgreeableTerms();
        response.map(terms => {
            next(vm => {
                vm.termsAndConditions = terms;
            })
        })
    }

    get acceptDisabled() {
        return !this.termsAndConditionsAccepted || (this.user.baaAgreementNeeded && !this.baaAccepted) || (this.user.licenseAgreementNeeded && !this.licenseAgreementAccepted)
    }

    async acceptTermsAndConditions() {
        if(this.termsAndConditions === null) {
            return;
        }
        const response = await this.termsAndConditionsService.acceptTermsAndConditions(this.termsAndConditions.id);
        if(this.user.licenseAgreementNeeded || this.user.baaAgreementNeeded) {
            const baaLaResponse = await this.termsAndConditionsService.acceptBaaAndLicenseAgreement(this.user.customers[0].id, this.baaAccepted, this.licenseAgreementAccepted);
        }
        if(response.isSuccess()) {
            this.resetTermsConditions();
            this.$router.push('/');
        }
    }

    get baaUrl() {
        return process.env.VUE_APP_BAA_URL
    }

    get licenseAgreementUrl() {
        return process.env.VUE_APP_LICENSE_AGREEMENT_URL
    }

    decline() {
        this.clearUser();
        this.signOut();
    }
}
