













































































import { Component, Vue } from 'vue-property-decorator';
import TopNav from '@/components/navigation/TopNav.vue';
import {RawLocation, Route} from "vue-router";
import store from '@/store';
import {formatMoment, formatMomentDateOnly} from '@/ts/TimezoneUtils';
import {MarrsPumpActions, PumpHomePageModel} from "@/vuex/marrs";
import {namespace} from "vuex-class";
import {Moment} from "moment";
import DeviceConnectionStatus from '@/components/devices/DeviceConnectionStatus.vue';
import {ButtonType} from '@/components/form/FormTypes';

type PumpHomeTabs = "home" | "monitor-mode" | "reports" | "diagnostics";

const marrsPump = namespace('marrsPump');

Component.registerHooks([
    'beforeRouteEnter',
    'beforeRouteLeave',
    'beforeRouteUpdate'
]);

/**
 * Renders the home page for a single pump
 * Note: This page acts as a container for the nested pump routes
 */
@Component({
    components: {
        TopNav,
        DeviceConnectionStatus,
    }
})
export default class PumpHomePage extends Vue {
   @marrsPump.State('homePage') homePageData!: PumpHomePageModel;
   @marrsPump.Action(MarrsPumpActions.CHECK_PUMP_CONNECTION) checkPumpConnection!: (pumpId: number) => Promise<void>;
   timezone = '';
   reportListShowing = false;
   reportRoutes = ['marrs-feeding-history-report', 'marrs-pump-error-report', 'marrs-pump-settings-report'];
   buttonTypes = ButtonType;

    async beforeRouteEnter(to: Route, from: Route, next: ((to?: RawLocation | false | ((vm: PumpHomePage) => any) | void) => void)){
        await store.dispatch('marrsPump/' + MarrsPumpActions.FETCH_INITIAL_HOME_PAGE_DATA, to.params.id);
        next();
    }

    created(){
        this.timezone = this.$store.state.auth.loggedInUser.timezone;
        if(this.homePageData?.pump){
            this.checkPumpConnection(this.homePageData.pump.id)
        }
    }

    /**
     * Returns the most recent claim date formatted if it exists
     */
    get mostRecentClaimDate(){
        if(this.homePageData.customerClaims.length > 0 && this.homePageData.customerClaims[0].endDate === null){
            return formatMoment(this.homePageData.customerClaims[0].id.startDate as Moment);
        }
        return 'Not claimed';
    }

    /**
     * Whether a specific navigation tab is active
     * @param tabName
     */
    isTabActive(tabName: PumpHomeTabs){
        if(tabName === 'home' && this.$route.name === 'enteral-pump-home'){
            return true;
        }else if (tabName === 'reports' && this.reportRoutes.includes(this.$route.name ?? 'N/A')){
            return true;
        }else if(tabName === 'monitor-mode' && this.$route.name === 'marrs-monitor-pump'){
            return true;
        }else if(tabName === 'diagnostics' && this.$route.name === 'marrs-diagnostics-report'){
            return true;
        }
        return false;
    }

    /**
     * Navigates the browser to a specific pump report
     * @param name
     */
    navigateToReport(name: string){
        this.$router.push({name});
        this.reportListShowing = false;
    }
}
