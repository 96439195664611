

























































































import { Component, Vue } from 'vue-property-decorator';
import PatientDetailsQuickLink from '@/components/patients/quickLinks/PatientDetailsQuickLink.vue';
import PatientPrescriptionQuickLink from '@/components/patients/quickLinks/PatientPrescriptionQuickLink.vue';
import PatientAssignmentQuickLink from '@/components/patients/quickLinks/PatientAssignmentQuickLink.vue';
import PatientMonitorPumpQuickLink from '@/components/patients/quickLinks/PatientMonitorPumpQuickLink.vue';
import {namespace} from 'vuex-class';
import {PatientActions, PatientGetters, PatientHomePageModel, PatientMutations} from '@/vuex/patients';
import WirelessDiagnosticsQuickLink from '@/components/quickLinks/WirelessDiagnosticsQuickLink.vue';
import Spinner from '@/components/form/Spinner.vue';
import {ReportDateContainer} from '@/models/reports/AdherenceReport';
import moment from 'moment-timezone';
import PatientAdherenceCard from '@/components/patients/cards/PatientAdherenceCard.vue';
import PatientDetailsCard from '@/components/patients/cards/PatientDetailsCard.vue';
import WeightManagementCard from '@/components/patients/cards/WeightManagementCard.vue';
import DeviceStatusCard from '@/components/patients/cards/DeviceStatusCard.vue';
import PatientNotesCard from '@/components/patients/cards/PatientNotesCard.vue';
import EditPatientDetailsCard from '@/components/patients/cards/EditPatientDetailsCard.vue';
import {getReportDateRange} from '@/ts/TimezoneUtils';
import PatientFileCard from '@/components/patients/cards/PatientFileCard.vue';
import {FeedPrescription, Patient, PatientDeviceAssignment} from '@/models/patients/Patient';
import {ModalMutations} from '@/vuex/modal';
import PrescriptionManagementModal from '@/components/patients/prescriptions/PrescriptionManagementModal.vue';
import DeviceAssignmentModal from '@/components/patients/DeviceAssignmentModal.vue';
import {Route} from 'vue-router';
import {RawLocation} from 'vue-router/types/router';
import ConfirmationModal from '@/components/modal/ConfirmationModal.vue';

const patients = namespace('patients');
const modal = namespace('modal');

Component.registerHooks([
    'beforeRouteEnter',
    'beforeRouteLeave',
    'beforeRouteUpdate'
]);

/**
 * Renders general information about a specific patient
 */
@Component({
    components: {
        PatientDetailsQuickLink,
        PatientPrescriptionQuickLink,
        PatientAssignmentQuickLink,
        PatientMonitorPumpQuickLink,
        WirelessDiagnosticsQuickLink,
        Spinner,
        PatientAdherenceCard,
        PatientDetailsCard,
        WeightManagementCard,
        DeviceStatusCard,
        PatientNotesCard,
        EditPatientDetailsCard,
        PatientFileCard,
        PrescriptionManagementModal,
        DeviceAssignmentModal,
        ConfirmationModal,
    }
})
export default class PatientHomeTab extends Vue {
    @patients.State(state => state.homePage) homePageData!: PatientHomePageModel;
    @patients.Action(PatientActions.CHECK_PATIENT_HOME_DEVICE_CONNECTION) fetchDeviceStatus!: (deviceId: number) => any;
    @patients.State(state => state.homePage.patient) patient!: Patient;
    @patients.State(state => state.homePage.patient.assignments) patientAssignments!: PatientDeviceAssignment[];
    @patients.State(state => state.homePage.patient.feedPrescriptions) feedPrescriptions!: FeedPrescription[];
    @patients.Mutation(PatientMutations.ADD_PRESCRIPTION) addPrescription!: (prescription: FeedPrescription) => any;
    @patients.Mutation(PatientMutations.UPDATE_PRESCRIPTION) updatePrescription!: (prescription: FeedPrescription) => any;
    @patients.Mutation(PatientMutations.REMOVE_PRESCRIPTION) removePrescription!: (prescriptionId: number) => any;
    @modal.Mutation(ModalMutations.CHANGE_MODAL_STATE) changeModalState!: (state: boolean) => void;
    @patients.Mutation(PatientMutations.ADD_ASSIGNMENT) addAssignment!: (item: PatientDeviceAssignment) => void;
    @patients.Mutation(PatientMutations.REMOVE_ASSIGNMENT) removeAssignment!: (item: number) => void;
    @patients.Mutation(PatientMutations.UPDATE_ASSIGNMENT) updateAssignment!: (item: PatientDeviceAssignment) => void;
    @patients.Mutation(PatientMutations.SET_PATIENT_HOME_PAGE_DEVICE_CONNECTION) setDeviceConnection!: (data:  {connected: boolean, pending: boolean}) => void;
    @patients.Getter(PatientGetters.GET_LATEST_HOMEPAGE_ASSIGNMENT) latestAssignment!: PatientDeviceAssignment | null;
    reportOptions: ReportDateContainer = {
        startDate: moment.tz(this.$store.state.auth.loggedInUser.timezone).subtract(28, 'day').set('hour', 0).set('minute', 0).set('second', 0),
        endDate: moment.tz(this.$store.state.auth.loggedInUser.timezone).subtract(1, 'day').set('hour', 23).set('minute', 59).set('second', 59)
    };
    tabOption = 1;

    /**
     * Whether the patient details are being edited
     */
    editingPatientDetails = false;

    patientDetailsDirty = false;

    /**
     * Whether the patient notes are being edited
     */
    editingNotes = false;

    patientNotesDirty = false;

    /**
     * Saved route for the confirmation of when a user tries to navigate to another page
     * with pending edits
     */
    cancelledRoute: RawLocation | null = null;

    /**
     * Whether the prescription modal is showing
     */
    prescriptionModalShowing = false;

    /**
     * Whether the assignment modal is showing
     */
    assignmentModalShowing = false;

    /**
     * Whether the confirm navigation modal is showing
     */
    confirmModalShowing = false;

    created(){
        const timezone = this.$store.state.auth.loggedInUser.timezone;
        if(this.$route.query.hasOwnProperty('reportRange') && this.$route.query['reportRange'].length === 1){
            this.tabOption = Number.parseInt(this.$route.query['reportRange'][0] as string);
            const dateRange = getReportDateRange(this.tabOption, timezone);
            this.reportOptions.startDate = dateRange.startDate.subtract(1, 'day');
            this.reportOptions.endDate = dateRange.endDate.subtract(1, 'day');
        }else {
            this.reportOptions.startDate = moment.tz(timezone).subtract(28, 'day').set('hour', 0).set('minute', 0).set('second', 0);
            this.reportOptions.endDate = moment.tz(timezone).subtract(1, 'day').set('hour', 23).set('minute', 59).set('second', 59);
        }
    }

    beforeRouteLeave(to: Route, from: Route, next: (to?: RawLocation | false | ((vm: PatientHomeTab) => any) | void) => void) {
        if((this.patientNotesDirty && this.editingNotes) || (this.patientDetailsDirty && this.editingPatientDetails)) {
            this.cancelledRoute = to.fullPath;
            this.confirmModalShowing = true;
            this.changeModalState(true);
            next(false);
            return;
        }

        next()
    }

    /**
     * Cancels the confirm modal and stays on the page
     */
    cancelConfirm() {
        this.confirmModalShowing = false;
        this.changeModalState(false);
        this.cancelledRoute = null;
    }

    /**
     * Navigates away from the patient home page ignoring any
     * unsaved edits
     */
    continueConfirm() {
        this.patientDetailsDirty = false;
        this.patientNotesDirty = false;
        this.confirmModalShowing = false;
        this.changeModalState(false);
        if(this.cancelledRoute !== null) {
            this.$router.push(this.cancelledRoute)
        }
    }


    get routeOption() {
        if(this.$route.query.hasOwnProperty('initialOption')){
            return this.$route.query.initialOption;
        }

        return '';
    }

    /**
     * The latest currently assigned device
     */
    get latestDevice(){
        if(this.latestAssignment != null){
            return this.latestAssignment.device
        }

        return null;
    }

    datesUpdated(data: ReportDateContainer) {
        this.reportOptions = data;
        // this.reportOptions.startDate = data.startDate;
        // this.reportOptions.endDate = data.endDate;
    }

    /**
     * Fetches the device connection status when a new device is assigned
     * @param deviceId
     */
    handleDeviceAssigned(deviceId: number) {
        this.fetchDeviceStatus(deviceId);
    }

    /**
     * Updates the edit patient details value
     */
    handleEditPatientDetails() {
        this.editingPatientDetails = true;
    }

    /**
     * Handles the edit patient details click from the quick links
     * Scrolls to the edit patient details form
     */
    handleEditDetailsScroll() {
        this.editingPatientDetails = true;
        if(this.$refs.hasOwnProperty('patientDetailsRow')) {
            window.scrollTo((this.$refs.patientDetailsRow as Element).getBoundingClientRect().x, (this.$refs.patientDetailsRow as Element).getBoundingClientRect().y)
        }
    }

    /**
     * Shows the prescription modal
     */
    showPrescriptionModal(){
        this.prescriptionModalShowing = true;
        this.changeModalState(true);
    }

    /**
     * Handles new prescriptions from the prescription modal
     * @param prescription
     */
    handleNewPrescription(prescription: FeedPrescription) {
        this.addPrescription(prescription);
        this.$addSnackbarMessage({
            message: `Prescription ${prescription.name} created!`
        })
    }

    /**
     * Handles updated prescriptions from the prescription modal
     * @param prescription
     */
    handleUpdatedPrescription(prescription: FeedPrescription) {
        this.updatePrescription(prescription);
        this.$addSnackbarMessage({
            message: `Prescription ${prescription.name} updated!`
        })
    }

    /**
     * Handles deleted prescription from the prescription modal
     * @param prescriptionId
     */
    handleRemovedPrescription(prescriptionId: number) {
        this.removePrescription(prescriptionId);
        this.$addSnackbarMessage({
            message: `Prescription successfully removed`
        })
    }

    /**
     * Hides the prescription modal
     */
    hidePrescriptionModal() {
        this.prescriptionModalShowing = false;
        this.changeModalState(false);
    }

    /**
     * Shows the assignment modal
     */
    showAssignmentModal() {
        this.assignmentModalShowing = true;
        this.changeModalState(true);
    }

    /**
     * Hides the assignment modal
     */
    hideAssignmentModal() {
        this.assignmentModalShowing = false;
        this.changeModalState(false);
    }

    /**
     * Handles new assignments from the assignment modal
     * @param assignment
     */
    handleNewAssignment(assignment: PatientDeviceAssignment) {
        this.addAssignment(assignment);
        this.handleDeviceAssigned(assignment.device.id);
        this.$addSnackbarMessage({
            message: 'Assignment created successfully'
        })
    }

    /**
     * Handles assignments removed in the assignment modal
     * @param assignmentId
     */
    handleRemoveAssignment(assignmentId: number) {
        this.removeAssignment(assignmentId);
        if(this.latestAssignment === null) {
            this.setDeviceConnection({
                connected: false,
                pending: false
            });
        } else {
            this.fetchDeviceStatus(this.latestAssignment.device.id)
        }
        this.$addSnackbarMessage({
            message: 'Assignment successfully removed'
        })
    }

    /**
     * Handles assignments updated in the assignment modal
     * @param assignment
     */
    handleUpdateAssignment(assignment: PatientDeviceAssignment) {
        this.updateAssignment(assignment);
        this.$addSnackbarMessage({
            message: 'Assignment successfully updated'
        })
    }
}
