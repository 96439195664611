










































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {Moment} from 'moment';
import moment from 'moment-timezone';
import {convertToMomentTimezone, formatMomentDateOnly} from '@/ts/TimezoneUtils';
import Modal from '@/components/modal/Modal.vue';
import {namespace} from "vuex-class";
import {ModalMutations} from "@/vuex/modal";

const modal = namespace('modal');

/**
 * Renders a date selector for reports
 */
@Component({
    components: {
        Modal,
    }
})
export default class ReportDateSelector extends Vue {

    /**
     * Initially selected tab
     */
    @Prop() initialTab!: number;

    /**
     * Whether the custom option should be provided
     */
    @Prop({default: true}) includeCustom!: boolean;

    /**
     * An offset to apply to start dates
     */
    @Prop({default: 0}) startDateOffset!: number;

    /**
     * An offset to apply to end dates
     */
    @Prop({default: 0}) endDateOffset!: number;

    /**
     * Whether to display in menu mode
     */
    @Prop({default: false}) menuMode!: boolean;

    /**
     * Whether this date selector is to be synced with another
     */
    @Prop({default: false}) outsideSync!: boolean;

    /**
     * If outside sync is enabled, this is the start date of the selector
     */
    @Prop({default: null}) outsideStartDate!: Moment | null;

    /**
     * If outside sync is enabled, this is the end date of the selecotr
     */
    @Prop({default: null}) outsideEndDate!: Moment | null;

    /**
     * Whether the custom modal is showing
     */
    modalShowing = false;

    today: Moment | null = null;

    /**
     * Current tab index
     */
    tabIndex = 1;

    /**
     * User's timezone
     */
    timezone = '';

    /**
     * Currently selected dates
     */
    selectedDates = {
        start: null as null | Date,
        end: null as null | Date
    };

    /**
     * Currently selected dates
     */
    temporarySelectedDates = {
        start: null as null | Date,
        end: null as null | Date
    };

    @modal.Mutation(ModalMutations.CHANGE_MODAL_STATE) changeModalState!: (state: boolean) => void;

    created(){
        this.timezone = this.$store.state.auth.loggedInUser.timezone;
        this.today = moment.tz(this.timezone).subtract(this.endDateOffset, 'day').set('hour', 23).set('minute', 59).set('second', 59).millisecond(0);
        this.tabIndex = this.initialTab;
    }

    /**
     * Formats a date object
     * @param date
     */
    formatDateFromDate(date: Date){
        return this.formatDate(moment(date));
    }

    /**
     * Formats a moment object
     * @param date
     */
    formatDate(date: Moment){
        return formatMomentDateOnly(date);
    }

    /**
     * Updates the selected tab index
     */
    @Watch('initialTab')
    initialTabChanged(){
        if(this.outsideSync && this.initialTab !== this.tabIndex){
            this.tabIndex = this.initialTab;
        }
    }

    /**
     * Updates the selector information on tab change
     * @param index
     */
    tabChanged(index: number){
        if(index === 3){
            this.modalShowing = true;
            this.changeModalState(true);
            this.$emit('tabChange', null);
        }else{
            this.tabIndex = index;
            this.$emit('dates', {
                startDate: this.startDate,
                endDate: this.endDate
            });
            this.$emit('tabChange', index);
        }

    }

    /**
     * Sets the new dates from the custom modal
     */
    modalSelectDates(){
        this.tabIndex = 3;
        this.modalShowing = false;
        this.changeModalState(false);
        this.selectedDates.start = this.temporarySelectedDates.start;
        this.selectedDates.end = this.temporarySelectedDates.end;
        this.$emit('dates', {
            startDate: this.startDate,
            endDate: this.endDate
        })
    }

    /**
     * Hides the custom modal
     */
    dismissModal(){
        this.modalShowing = false;
        this.changeModalState(false);
        this.temporarySelectedDates.start = this.selectedDates.start;
        this.temporarySelectedDates.end = this.selectedDates.end;
    }

    /**
     * Returns the currently active start date
     */
    get activeStartDate() {
        if(this.outsideSync) {
            return this.outsideStartDate
        }

        return this.startDate;
    }

    /**
     * Returns the start date based on active tab
     */
    get startDate(){
        switch (this.tabIndex) {
            case 0:
                return moment.tz(this.timezone).subtract(this.startDateOffset, 'day').subtract(6, 'day').set('hour', 0).set('minute', 0).set('second', 0).millisecond(0);

            case 1:
                return moment.tz(this.timezone).subtract(this.startDateOffset, 'day').subtract(27, 'day').set('hour', 0).set('minute', 0).set('second', 0).millisecond(0);

            case 2:
                return moment.tz(this.timezone).subtract(this.startDateOffset, 'day').subtract(89, 'day').set('hour', 0).set('minute', 0).set('second', 0).millisecond(0);

            case 3:
                return convertToMomentTimezone(this.$store.state.auth.loggedInUser.timezone, (this.selectedDates.start as Date).toISOString()).startOf('day').millisecond(0)
        }
    }

    /**
     * Returns the active end date
     */
    get activeEndDate() {
        if(this.outsideSync) {
            return this.outsideEndDate;
        }

        return this.endDate;
    }

    /**
     * Returns the end date based on active tab
     */
    get endDate() {
        if(this.tabIndex === 3){
            return convertToMomentTimezone(this.$store.state.auth.loggedInUser.timezone, (this.selectedDates.end as Date).toISOString()).endOf('day').millisecond(0)
        }
        return this.today;
    }
}
