




















import {Component, Prop, Vue} from 'vue-property-decorator';
import {ButtonType} from '../form/FormTypes';
import {namespace} from 'vuex-class';
import WirelessDiagnosticsModal from '@/components/devices/marrs/WirelessDiagnosticsModal.vue';
import {MARRSPump} from "@/models/Devices/MARRS/MARRSPump";
import {ModalMutations} from "@/vuex/modal";
import DeviceConnectionStatus from '@/components/devices/DeviceConnectionStatus.vue';
import IconDisplay from '@/components/utility/IconDisplay.vue';

const modal = namespace('modal');

/**
 * Renders a quick link for performing wireless diagnostics on a pump
 */
@Component({
    components: {
        WirelessDiagnosticsModal,
        DeviceConnectionStatus,
        IconDisplay,
    }
})
export default class WirelessDiagnosticsQuickLink extends Vue {
    /**
     * Whether the pump's connection is currently pending
     */
    @Prop() connectionPending!: boolean;

    /**
     * Whether the pump is currently connected
     */
    @Prop() connected!: boolean;

    /**
     * Pump to perform wireless diagnostics on
     */
    @Prop() pump!: MARRSPump;
    @modal.Mutation(ModalMutations.CHANGE_MODAL_STATE) changeModalState!: (state: boolean) => void;

    /**
     * Whether the modal is showing
     */
    modalShowing = false;
    buttonType = ButtonType;

    /**
     * Show the modal
     */
    showModal(){
        this.changeModalState(true);
        this.modalShowing = true;
    }

    /**
     * Hide the modal
     */
    closeModal(){
        this.modalShowing = false;
        this.changeModalState(false);
    }
}
