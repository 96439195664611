import {Module} from 'vuex';
import User, {MeasurementUnit} from '@/models/User';
import axios from 'axios';
import {RootState} from '@/vuex/rootState';
import {Team, TeamType} from '@/models/Teams';

export interface AuthModel {
    loggedInUser: User | null;
    authLoading: boolean;
}

export enum AuthMutations {
    RESET_TERMS_AND_CONDITIONS = 'resetTermsAndConditions',
    UPDATE_AUTH_LOADING = 'updateAuthLoading',
    LOG_USER_OUT = 'logUserOut',
}

export enum AuthGetters {
    MEASUREMENT_UNIT = 'measurementUnit',
    USER_IS_ON_SYSTEM_TEAM  = 'userIsOnSystemTeam',
}
// {
//             name: 'Michael Smith Jr',
//             firstName: 'Michael',
//             lastName: 'Smith Jr',
//             email: 'michael.smithjr@cardinalhealth.com',
//             role: 'admin',
//             teams: [],
//             username: 'michael.smithjr',
//             measurementUnit: 'IMPERIAL',
//             timezone: 'America/Chicago',
//         },

const auth: Module<AuthModel, RootState> = {
    namespaced: true,
    state: {
        loggedInUser: null,
        authLoading: false,
    },
    mutations: {
        updateLoggedInUser(state, userData) {
            state.loggedInUser = userData;
        },
        [AuthMutations.RESET_TERMS_AND_CONDITIONS](state) {
            if (state.loggedInUser !== null) {
                state.loggedInUser.termsAndConditionsUpdateNeeded = false;
            }
        },
        [AuthMutations.UPDATE_AUTH_LOADING](state, newState) {
            state.authLoading = newState;
        },
        [AuthMutations.LOG_USER_OUT](state) {
            state.loggedInUser = null;
        },
    },
    getters: {
       [AuthGetters.MEASUREMENT_UNIT](state) {
            if (state.loggedInUser === null) {
                return '';
            }
            if (state.loggedInUser.measurementUnit === MeasurementUnit.IMPERIAL) {
                return 'lbs';
            } else if (state.loggedInUser.measurementUnit === MeasurementUnit.METRIC) {
                return 'kg';
            }
            return '';
        },
        [AuthGetters.USER_IS_ON_SYSTEM_TEAM](state) {
           if (state.loggedInUser === null) {
               return false;
           }

           return state.loggedInUser.teams.find((team: Team) => team.type === TeamType.SYSTEM) !== undefined;
        },
    },
    actions: {
        async getLoggedInUser(context) {
            const accessToken = context.rootGetters['oidcStore/oidcAccessToken'];
            // axios.defaults.headers['Authorization'] = 'Bearer ' + accessToken;
            if (accessToken != null) {
                try {
                    const { data } = await axios.get(process.env.VUE_APP_BACKEND_URI + '/users/profile');
                    context.commit('updateLoggedInUser', data);
                } catch (e) {
                    context.commit('updateLoggedInUser', null);
                }
            }
        },
    },
};

export default auth;
