








    import {Component, Prop, Vue} from 'vue-property-decorator';
    import {ButtonType} from '@/components/form/FormTypes';
    import Spinner from '@/components/form/Spinner.vue';

    /**
 * Renders a stylized button
 */
@Component({
    components: {
        Spinner
    }
})
export default class Button extends Vue {
    /**
     * Name of the font awesome icon to use
     */
    @Prop({type: String, default: ''}) iconName!: string;

    /**
     * Type of button to display
     */
    @Prop({default: ButtonType.DEFAULT}) type!: ButtonType;

    /**
     * Whether the button should be disabled
     */
    @Prop({default: false}) disabled!: boolean;

    @Prop({default: true}) shouldShowTransparentArrow!: boolean;

    buttonTypes = ButtonType;

    /**
     * Determines the appropriate button classes based on the button type
     */
    get buttonClasses(){
        switch(this.type){
            case ButtonType.PRIMARY:
                return ['btn', 'button-primary'];

            case ButtonType.SECONDARY:
                return ['btn', 'button-secondary'];

            case ButtonType.TERTIARY:
                return ['btn', 'button-tertiary'];

            case ButtonType.DEFAULT:
                return ['btn', 'button-default'];

            case ButtonType.TRANSPARENT:
                return ['btn', 'button-transparent'];

            case ButtonType.DARK:
                return ['btn', 'button-dark'];

            case ButtonType.GREEN:
                return ['btn', 'button-green']

            case ButtonType.NONE:
                return ['btn', 'button-none']
        }
    }

    /**
     * Emits an event on click
     * @param e Click event from the HTML button
     */
    clickHandler(e: any){
        this.$emit('click', e);
    }
}
