











import {Component, Prop, Vue} from "vue-property-decorator";

/**
 * Renders a chip component
 */
@Component
export default class Chip extends Vue {
    /**
     * Whether the chip is clickable
     */
    @Prop({default: false}) clickable!: boolean;

    /**
     * Whether the chip can be removed
     */
    @Prop({default: false}) removable!: boolean;

    /**
     * Whether the chip is in an active state
     */
    @Prop({default: false}) active!: boolean;

    /**
     * Determines extra classes based on the state of the chip
     */
    get extraClasses(){
        return {
            'chip-clickable': this.clickable,
            'bg-purple-dark': this.active,
            'text-white': this.active,
            'active': this.active
        }
    }

    /**
     * Emits an event on click if the chip is clickable
     */
    handleClick(){
        if(!this.clickable){
            return;
        }
        this.$emit('click');
    }
}
