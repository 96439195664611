import {AsyncEither, AsyncNullSuccess, Either, Failure, nullSuccess, Success} from '@/errors/Either';
import {TermsAndConditions} from '@/models/User';
import {Error} from '@/errors/Error';
import axios from 'axios';

export class TermsAndConditionsService {
    private timezone: string;

    constructor(timezone: string) {
        this.timezone = timezone;
    }

    /**
     * Fetches the terms and conditions to be agreed to
     */
    public async getAgreeableTerms(): AsyncEither<TermsAndConditions, Error> {
        try {
            // tslint:disable-next-line:max-line-length
            const {data}: {data: TermsAndConditions} = await axios.get(process.env.VUE_APP_BACKEND_URI + '/terms-conditions/agreement');
            return new Success(data);
        } catch (e) {
            return new Failure({
                message: 'Unable to get terms and conditions',
            });
        }
    }

    /**
     * Accepts a set of terms and conditions
     * @param id ID of terms and conditions to agree to
     */
    public async acceptTermsAndConditions(id: number): AsyncNullSuccess<Error> {
        try {
            await axios.put(process.env.VUE_APP_BACKEND_URI + '/terms-conditions/' + id);
            return nullSuccess();
        } catch (e) {
            return new Failure({
                message: 'Unable to accept terms and conditions',
            });
        }
    }

    // tslint:disable-next-line:max-line-length
    public async acceptBaaAndLicenseAgreement(id: number, baa: boolean, licenseAgreement: boolean): AsyncNullSuccess<Error> {
        try {
            await axios.put(process.env.VUE_APP_BACKEND_URI + `/customers/${id}/accept-agreements`, {
                baa,
                licenseAgreement,
            });
            return nullSuccess();
        } catch(e) {
            return new Failure({
                message: 'Unable to accept terms and conditions',
            });
        }
    }
}
