import { helpers } from 'vuelidate/lib/validators';

/**
 * Validates whether the property contains another property
 * @param prop
 */
export function doesNotContain(prop: any) {
    return helpers.withParams({type: 'doesNotContain', prop}, function(value: any, parentVm: any) {
        // @ts-ignore
        return !value.includes(this[prop].toLowerCase());
    });
}

/**
 * Validates whether the given string meets the password complexity requirements
 * @param value
 */
export function passwordComplexity(value: string) {
    const lowercase = /.*[a-z].*/.test(value) ? 1 : 0;
    const uppercase = /.*[A-Z].*/.test(value) ? 1 : 0;
    const numbers = /.*\d.*/.test(value) ? 1 : 0;
    const special = /\W/.test(value) ? 1 : 0;
    const complexity = lowercase + uppercase + numbers + special;
    return complexity >= 3;
}

/**
 * Whether a numerical field has the appropriate length
 * @param prop
 */
export function minLengthCountingZero(prop: number){
    return helpers.withParams({type: 'minLengthCountingZero', prop},
        (value: any , parentVm: any) => value && value.length >= prop);
}

