



















import { Component, Vue } from 'vue-property-decorator';
import {ButtonType} from '../../form/FormTypes';
import {namespace} from 'vuex-class';
import {Patient, PatientNotes} from '@/models/patients/Patient';
import {PatientMutations} from '@/vuex/patients';
import NotesService from '@/services/patients/NotesService';

const patients = namespace('patients');

/**
 * Renders a card allowing for the viewing/updating of the notes associated with a patient
 */
@Component
export default class PatientNotesCard extends Vue {
    @patients.State(state => state.homePage.patient) patient!: Patient;
    @patients.Mutation(PatientMutations.ADD_NOTES) addNotes!: (notes: PatientNotes) => void;
    @patients.Mutation(PatientMutations.UPDATE_NOTES) updateNotes!: (notes: PatientNotes) => void;
    /**
     * Current value of the patient notes field
     */
    patientNotes = '';

    /**
     * Whether editing is currently enabled
     */
    editing = false;

    buttonTypes = ButtonType;

    notesService = new NotesService(this.$store.state.auth.loggedInUser.timezone);

    mounted(): void {
        if(this.patient?.notes?.length !== undefined && this.patient.notes.length > 0) {
            this.patientNotes = this.patient.notes[0].notes;
        }
    }

    handleNotesChange(value: string) {
        if(this.patientNotes !== value) {
            this.$emit('dirty');
        }

        this.patientNotes = value;
    }

    /**
     * Sets the notes field to allow editing
     * Notifies the parent that editing has been enabled
     */
    editNotes() {
        this.editing = true;
        this.$emit('editing', true);
    }

    /**
     * Sets the notes field to disallow editing
     * Notifies the parent that editing has finished
     */
    finishEditing() {
        this.editing = false;
        this.$emit('editing', false);
    }

    /**
     * Cancels notes editing without saving the data
     */
    cancelNotesEdit() {
        if(this.patient?.notes?.length !== undefined && this.patient.notes.length > 0) {
            this.patientNotes = this.patient.notes[0].notes;
        } else {
            this.patientNotes = '';
        }
        this.finishEditing();
    }

    /**
     * Sends the request to update the patient's notes
     */
    async handleNotesUpdate(){
        if(this.patient?.notes?.length !== undefined && this.patient.notes.length === 0) {
            const response = await this.notesService.createNotes(this.patient.id as number, {
                notes: this.patientNotes
            });
            if(response.isSuccess()){
                response.map((notes) => {
                    this.addNotes(notes);
                });
                this.$addSnackbarMessage({
                    message: "Patient notes updated successfully"
                })
            }
        } else if (this.patient?.notes?.length !== undefined) {
            const updatedNotes = {
                id: this.patient.notes[0].id,
                notes: this.patientNotes
            };
            const response = await this.notesService.updateNotes(this.patient.id as number, updatedNotes);
            if(response.isSuccess()){
                this.updateNotes(updatedNotes);
                this.$addSnackbarMessage({
                    message: "Patient notes updated successfully"
                })
            }
        }
        this.finishEditing();
    }
}
