















import {Component, Prop, Vue} from "vue-property-decorator";
import {
    BooleanFilterData,
    CalendarFilterData,
    FilterDataType,
    MultiselectFilterData, RangeFilterData,
    TableFilterData
} from "@/ts/Filter";
import TableFilter from '@/components/table/TableFilter.vue';
import {ButtonType} from "@/components/form/FormTypes";

/**
 * Renders a container of table filters for a column
 */
@Component({
    components: {
        TableFilter
    }
})
export default class TableFilterContainer extends Vue {
    /**
     * An array of table filters
     */
    @Prop({default: () => []}) filters!: TableFilterData[];

    ButtonType = ButtonType;

    dataModel: {[index: string]: any[] | any} = {};

    created(){
        for(const filter of this.filters){
            const data = filter.data;
            if(this.isMultiselect(data) && data.activeItems != undefined){
                for(const prop of Object.keys(data.activeItems)){
                    if(data.activeItems.hasOwnProperty(prop)){
                        this.$set(this.dataModel, prop, [...data.activeItems[prop]]);
                    }
                }
            }else if(this.isCalendar(data)){
                this.$set(this.dataModel, filter.key, data.initialRange);
            }else if(this.isBoolean(data)) {
                this.$set(this.dataModel, filter.key, data.selected)
            }else if(this.isRange(data)){
                if(data.activeRangeData instanceof Array && data.activeRangeData.length == 0){
                    this.$set(this.dataModel, filter.key, [data.minRange, data.maxRange])
                }else{
                    this.$set(this.dataModel, filter.key, data.activeRangeData);
                }
            }
        }
    }

    /**
     * Returns the active items for a specific filter
     * @param key
     */
    getValue(key: string){
        if(this.dataModel.hasOwnProperty(key)){
            return this.dataModel[key];
        }

        return [];
    }

    /**
     * Updates the active data for a specific filter when it changes
     * @param key
     * @param data
     */
    updateData(key: string, data: any){
        const filter = this.filters.find(filter => filter.key === key);
        if(filter == undefined) {
            return;
        }
        if(this.dataModel.hasOwnProperty(key) && this.isMultiselect(filter.data)) {
            const index = this.dataModel[key].findIndex((item: any) => item == data);
            if (index == -1) {
                this.dataModel[key].push(data);
            } else {
                this.dataModel[key].splice(index, 1);
            }
        } else if(this.isCalendar(filter.data) || this.isBoolean(filter.data) || this.isRange(filter.data)){
            this.$set(this.dataModel, key, data);
        } else{
            this.$set(this.dataModel, key, [data]);
        }
    }

    /**
     * Emits a filter update when the filter data changes
     */
    emitFilter(){
        this.$emit('filter', this.dataModel);
    }

    /**
     * Type-guard for determining if the filter is a multi-select filter
     * @param data
     */
    isMultiselect(data: FilterDataType): data is MultiselectFilterData{
        return (data as MultiselectFilterData).multiselect !== undefined;
    }

    /**
     * Type-guard for determining if the filter is a calendar filter
     * @param data
     */
    isCalendar(data: FilterDataType): data is CalendarFilterData {
        return (data as CalendarFilterData).initialRange !== undefined;
    }

    /**
     * Type-guard for determining if the filter is a boolean filter
     * @param data
     */
    isBoolean(data: FilterDataType): data is BooleanFilterData{
        return (data as BooleanFilterData).selected !== undefined;
    }

    /**
     * Type-guard for determining if the filter is a range filter
     * @param data
     */
    isRange(data: FilterDataType): data is RangeFilterData {
        return (data as RangeFilterData).maxRange !== undefined;
    }

    /**
     * Dismisses the filter if clicked outside the filter box
     * @param event
     * @param el
     */
    onClickOutside(event: MouseEvent, el: HTMLElement){
        this.$emit('close');
    }
}
