






























































import { Component, Vue } from 'vue-property-decorator';
import PatientAssignmentQuickLink from '@/components/devices/marrs/quickLinks/PatientAssignmentQuickLink.vue';
import Spinner from '@/components/form/Spinner.vue';
import {namespace} from "vuex-class";
import MonitorPumpQuickLink from '@/components/devices/marrs/quickLinks/MonitorPumpQuickLink.vue';
import WirelessDiagnosticsQuickLink from '@/components/quickLinks/WirelessDiagnosticsQuickLink.vue';
import PumpAlarmsQuickLink from '@/components/devices/marrs/quickLinks/PumpAlarmsQuickLink.vue';
import PumpSettingsQuickLink from '@/components/devices/marrs/quickLinks/PumpSettingsQuickLink.vue';
import PumpCertificationCard from '@/components/devices/marrs/cards/PumpCertificationCard.vue';
import WirelessHubDetailsCard from '@/components/devices/marrs/cards/WirelessHubDetailsCard.vue';
import PatientAssignmentHistoryCard from '@/components/devices/marrs/cards/PatientAssignmentHistoryCard.vue';
import ServiceToolsCard from '@/components/serviceTools/ServiceToolsCard.vue';
import FeedHistoryCard from '@/components/devices/marrs/cards/FeedHistoryCard.vue';
import CurrentSoftwareCard from '@/components/devices/marrs/cards/CurrentSoftwareCard.vue';
import PumpNotesCard from '@/components/devices/marrs/cards/PumpNotesCard.vue';
import AdvancedDetailsCard from '@/components/devices/marrs/cards/AdvancedDetailsCard.vue';
import {MARRSPump} from '@/models/Devices/MARRS/MARRSPump';
import ConfirmationModal from '@/components/modal/ConfirmationModal.vue';
import {RawLocation} from 'vue-router/types/router';
import {Route} from 'vue-router';
import {ModalMutations} from '@/vuex/modal';

const marrs = namespace('marrsPump');
const modal = namespace('modal');

Component.registerHooks([
    'beforeRouteEnter',
    'beforeRouteLeave',
    'beforeRouteUpdate'
]);

/**
 * Renders general information about a specific pump
 */
@Component({
    components: {
        PatientAssignmentQuickLink,
        Spinner,
        MonitorPumpQuickLink,
        WirelessDiagnosticsQuickLink,
        PumpAlarmsQuickLink,
        PumpSettingsQuickLink,
        PumpCertificationCard,
        WirelessHubDetailsCard,
        PatientAssignmentHistoryCard,
        ServiceToolsCard,
        FeedHistoryCard,
        CurrentSoftwareCard,
        PumpNotesCard,
        AdvancedDetailsCard,
        ConfirmationModal,
    }
})
export default class PumpHomeTab extends Vue {
    @marrs.State(state => state.homePage.connectionPending) connectionPending!: boolean;
    @marrs.State(state => state.homePage.connected) connected!: boolean;
    @marrs.State(state => state.homePage.pump) pump!: MARRSPump;
    @modal.Mutation(ModalMutations.CHANGE_MODAL_STATE) changeModalState!: (state: boolean) => void;

    editingNotes = false;
    cancelledRoute: RawLocation | null = null;
    confirmModalShowing = false;

    beforeRouteLeave(to: Route, from: Route, next: (to?: RawLocation | false | ((vm: PumpHomeTab) => any) | void) => void) {
        if(this.editingNotes) {
            this.cancelledRoute = to.fullPath;
            this.confirmModalShowing = true;
            this.changeModalState(true);
            next(false);
            return;
        }

        next()
    }

    /**
     * Cancels the unsaved changes modal and stays on the page
     */
    cancelConfirm() {
        this.confirmModalShowing = false;
        this.changeModalState(false);
        this.cancelledRoute = null;
    }

    /**
     * Continues from the unsaved changes modal and leaves the page without saving changes
     */
    continueConfirm() {
        this.editingNotes = false;
        this.confirmModalShowing = false;
        this.changeModalState(false);
        if(this.cancelledRoute !== null) {
            this.$router.push(this.cancelledRoute)
        }
    }
}
