







import {Component, Prop, Vue} from "vue-property-decorator";

/**
 * Renders a toggle switch
 */
@Component
export default class ToggleSwitch extends Vue {

    /**
     * Extra classes to apply
     */
   @Prop({type: String, default: ''}) classes!: string;

    /**
     * Name of the toggle switch
     */
   @Prop({type: String, default: ''}) name!: string;

    /**
     * Value of the toggle switch
     */
   @Prop({type: Boolean}) value! :boolean;

    /**
     * Emits an event on switch change
     * @param event
     */
    emitInput(event: any){
        this.$emit('change', !this.value)
    }

}
