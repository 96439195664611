






import { Component, Vue, Prop } from 'vue-property-decorator';
import {TableCellData, TableRowData} from "@/components/table/cells/TableCellData";
import TableCell from '@/components/table/cells/TableCell.vue';

/**
 * Renders a row of data for a table
 */
@Component({
    components: {
        'table-cell': TableCell
    }
})
export default class TableRow extends Vue {
    /**
     * Data to display in the row
     */
    @Prop() rowData!: TableRowData;

    /**
     * Class to apply if the table is highlighted
     */
    @Prop() highlightClass!: string;

    /**
     * Width classes to be applied to each cell
     */
    @Prop({default: () => []}) widthClasses!: string[];

    /**
     * Whether the table row is highlighted
     */
    highlighted = false;

    /**
     * Returns the width class for a specific cell
     * @param index
     */
    getCellWidth(index: number) {
        if(this.rowData.cells[index].class !== undefined) {
            return this.rowData.cells[index].class
        }
        if(index < this.widthClasses.length) {
            return this.widthClasses[index]
        }

        return '';
    }
}
