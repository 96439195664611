



































import { Component, Vue } from 'vue-property-decorator';
import {namespace} from "vuex-class";
import {PatientDeviceAssignment} from "@/models/patients/Patient";
import {ButtonType} from "@/components/form/FormTypes";
import {formatMomentDateOnly} from "@/ts/TimezoneUtils";
import moment, {Moment} from 'moment';
import UnassignDeviceFromPatientModal from '@/components/devices/marrs/UnassignDeviceFromPatientModal.vue';
import {MARRSPump} from '@/models/Devices/MARRS/MARRSPump';
import marrs, {MarrsPumpMutations} from '@/vuex/marrs';
import {ModalMutations} from '@/vuex/modal';
import {CustomerDeviceAssociation} from '@/models/customers/CustomerDeviceAssociation';
import PatientAssignmentModal from '@/components/patients/PatientAssignmentModal.vue';

const marrsPump = namespace('marrsPump');
const modal = namespace('modal');

/**
 * Renders a quick link on the pump home page for managing the current assignment of the pump
 */
@Component({
    components: {
        UnassignDeviceFromPatientModal,
        PatientAssignmentModal,
    }
})
export default class PatientAssignmentQuickLink extends Vue {
    @marrsPump.State(state => state.homePage.deviceAssignments) deviceAssignments!: PatientDeviceAssignment[];
    @marrsPump.State(state => state.homePage.customerClaims) customerClaims!: CustomerDeviceAssociation[];
    @marrsPump.State(state => state.homePage.pump) pump!: MARRSPump;
    @marrsPump.Mutation(MarrsPumpMutations.EDIT_ASSIGNMENT) editAssignment!: (assignment: PatientDeviceAssignment) => void;
    @marrsPump.Mutation(MarrsPumpMutations.ADD_NEW_ASSIGNMENT) addNewAssignment!: (assignment: PatientDeviceAssignment) => void;
    @marrsPump.Mutation(MarrsPumpMutations.REMOVE_ASSIGNMENT) removeAssignment!: (assignmentId: number) => void;
    @modal.Mutation(ModalMutations.CHANGE_MODAL_STATE) showModalBackdrop!: (state: boolean) => void;
    buttonType = ButtonType;
    endModalShowing = false;
    assignModalShowing = false;

    /**
     * Formats the start date of the most recent assignment
     */
    get formattedRecentStartDate(){
        return this.deviceAssignments.length > 0 ? formatMomentDateOnly(this.deviceAssignments[0].startDate as Moment) : "";
    }

    /**
     * Shows the end assignment modal
     */
    showEndAssignmentModal() {
        this.showModalBackdrop(true);
        this.endModalShowing = true;
    }

    /**
     * Hides the end assignment modal
     */
    hideEndAssignmentModal() {
        this.endModalShowing = false;
        this.showModalBackdrop(false);
    }

    /**
     * Handles an assignment being ended by the end assignment modal
     * @param endDate End date of the assignment
     */
    handleEndedAssignment(endDate: Date) {
        if(this.deviceAssignments[0].endDate === null) {
            const assignment = {...this.deviceAssignments[0]};
            assignment.endDate = moment.tz(endDate, this.$store.state.auth.loggedInUser.timezone);
            this.editAssignment(assignment);
            this.hideEndAssignmentModal();
        }
    }

    /**
     * Updates an assignment after update
     */
    handleUpdatedAssignment(assignment: PatientDeviceAssignment) {
        this.editAssignment(assignment)
        this.$addSnackbarMessage({
            message: 'Assignment successfully updated'
        })
    }

    handlesRemovedAssignment(id: number){
        this.removeAssignment(id)
        this.$addSnackbarMessage({
            message: 'Assignment successfully removed'
        })
    }

    /**
     * Shows the create assignment modal
     */
    showAssignModal() {
        this.assignModalShowing = true;
        this.showModalBackdrop(true);
    }

    /**
     * Hides the new assignment modal
     */
    hideAssignModal() {
        this.assignModalShowing = false;
        this.showModalBackdrop(false);
    }

    /**
     * Handles an assignment being created by the assignment modal
     * @param assignment
     */
    handleNewAssignment(assignment: PatientDeviceAssignment) {
        this.addNewAssignment(assignment);
        this.$addSnackbarMessage({
            message: 'Assignment created successfully'
        })
    }
}
