import {Module} from 'vuex';


export interface ModalModal {
    modalShowing: boolean;
};


export enum ModalMutations {
    CHANGE_MODAL_STATE = 'changeModalState',
}

const modal: Module<ModalModal, any> = {
    namespaced: true,
    state: {
        modalShowing: false
    },
    getters: {
        modalState(state, getters)  {
          return state.modalShowing;
        },
    },
    mutations: {
        [ModalMutations.CHANGE_MODAL_STATE](state, modalState: boolean) {
            state.modalShowing = modalState;
        },
    },
};

export default modal;
