


























































import { Component, Vue } from 'vue-property-decorator';
import {ButtonType} from "@/components/form/FormTypes";
import {namespace} from "vuex-class";
import {Certification, CertificationReply} from '@/models/Devices/MARRS/Certification';
import {formatMoment} from "@/ts/TimezoneUtils";
import {Moment} from "moment";
import {ModalMutations} from '@/vuex/modal';
import {MARRSPump} from '@/models/Devices/MARRS/MARRSPump';
import WirelessDiagnosticsModal from '@/components/devices/marrs/WirelessDiagnosticsModal.vue';
import {MarrsPumpMutations} from '@/vuex/marrs';
import Spinner from '@/components/form/Spinner.vue';
import IconDisplay from '@/components/utility/IconDisplay.vue';
import ConnectCertificationService from '@/services/devices/connect/ConnectCertificationService';
import ConfirmationModal from '@/components/modal/ConfirmationModal.vue';
import DeviceConnectionStatus from '@/components/devices/DeviceConnectionStatus.vue';

const marrs = namespace('marrsPump');
const modal = namespace('modal');

/**
 * Renders information about the most recent certification for the device
 */
@Component({
    components: {
        WirelessDiagnosticsModal,
        Spinner,
        IconDisplay,
        ConfirmationModal,
        DeviceConnectionStatus,
    }
})
export default class PumpCertificationCard extends Vue {
    @marrs.State(state => state.homePage.latestCertification) latestCertification!: Certification;
    @marrs.State(state => state.homePage.pump.id) deviceId!: number;
    @marrs.State(state => state.homePage.connected) deviceConnected!: boolean;
    @marrs.State(state => state.homePage.connectionPending) connectionPending!: boolean;
    @marrs.State(state => state.homePage.pump) pump!: MARRSPump;
    @marrs.Mutation(MarrsPumpMutations.UPDATE_CERTIFICATION) updateCertificationInfo!: (data: CertificationReply) => void;
    @modal.Mutation(ModalMutations.CHANGE_MODAL_STATE) changeModalState!: (state: boolean) => void;

    /**
     * Whether the wireless diagnostics modal is showing
     */
    modalShowing = false;
    errorModalShowing = false;
    errorMessage = '';
    connectCertificationService: ConnectCertificationService | null = null;
    buttonType = ButtonType;

    /**
     * Whether the certification request is pending
     */
    certifyPending = false;

    mounted(){
        const timezone = this.$store.state.auth.loggedInUser.timezone;
        this.connectCertificationService = new ConnectCertificationService(timezone);
    }

    /**
     * Determines the type of the latest certification
     */
    get certificationType(){
        if(this.latestCertification?.testType !== undefined){
            return this.latestCertification.testType;
        }
        return ""
    }

    /**
     * Formats the date of the latest certification
     */
    get certificationDate(){
        if(this.latestCertification?.timestamp != undefined){
            return formatMoment(this.latestCertification.timestamp as Moment);
        }
        return "N/A";
    }

    /**
     * Sends the request to download the certification PDF
     */
    async downloadCertification(){
        if(this.connectCertificationService === null){
            return;
        }
        const response = await this.connectCertificationService.fetchCertificationPdf(this.deviceId, this.latestCertification.id);
        response.mapErr((e) => {
            this.$addSnackbarMessage({
                message: 'There was an issue downloading the certification PDF. Please try again later or contact customer support'
            })
        })
    }

    /**
     * Shows the wireless diagnostics modal
     */
    showModal(){
        this.changeModalState(true);
        this.modalShowing = true;
    }

    /**
     * Hides the wireless diagnostics modal
     */
    closeModal(){
        this.modalShowing = false;
        this.changeModalState(false);
    }

    /**
     * Sends the request to perform a certification on the device
     */
    async certifyPump() {
        this.certifyPending = true;
        const response = await this.connectCertificationService?.autoCertifyPump(this.deviceId);
        if(response !== undefined && response.isSuccess()) {
            response.map(data => {
                this.updateCertificationInfo(data);
            });
            this.$addSnackbarMessage({
                message: 'Certification completed'
            })
        } else if(response !== undefined && response.isFailure()) {
            this.showErrorModal('A certification could not be performed. Please ensure the pump has fed at least 1 mL of fluid and all 5 buttons have been pressed within the past 10 days. After doing this, please wait 24 hours for the updated data to reach the portal.')
        }
        this.certifyPending = false;
    }

    showErrorModal(message: string) {
        this.changeModalState(true)
        this.errorModalShowing = true;
        this.errorMessage = message;
    }

    hideErrorModal() {
        this.changeModalState(false);
        this.errorModalShowing = false;
        this.errorMessage = '';
    }
}
