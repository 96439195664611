import {Moment} from 'moment';

/**
 * Represents hourly feeding data sent by a connect pump
 */
export interface StatusRecord {
    id: number;
    timestamp: Moment | string;
    serialNumber: string;
    wirelessAddress: string;
    softwareVersion: string;
    uiVersion: string;
    batteryVoltage: number;
    factoryCertified: boolean;
    acStatus: boolean;
    batteryCharging: boolean;
    poweredDown: boolean;
    lowBatteryShutdown: boolean;
    feedTotalizer: number;
    flushTotalizer: number;
    feedDoseDelivered: number;
    bolusesDelivered: number;
    amountFedThisHour: number;
    consolidatedRecordCount: number;
    runOverflow: number;
}

/**
 * Represents daily diagnostic data sent by a connect pump
 */
export interface DiagnosticRecord {
    id: number;
    softwareVersion: number;
    uiVersion: number;
    timestamp: Moment | string;
    certificationTime: Moment | string;
    serialNumber: string;
    testType: string;
    testResult: boolean;
    hardwareVersion: number;
    soundVolumeDifference: number;
    stopLightGreenOn: number;
    stopLightGreenOff: number;
    stopLightYellowOn: number;
    stopLightYellowOff: number;
    stopLightRedOn: number;
    stopLightRedOff: number;
    acPowerOn: number;
    backlightCathode: number;
    backlightAnode: number;
    setDetectionNoMagnet1: number;
    setDetectionNoMagnet2: number;
    setDetectionNoMagnet3: number;
    setDetectionNoMagnet4: number;
    peakUsVoltageUpNoFluid: number;
    usFrequencyUpNoFluid: number;
    peakUsVoltageDownNoFluid: number;
    usFrequencyDownNoFluid: number;
    peakUsVoltageUpVcoInhibit: number;
    peakUsVoltageDownVcoInhibit: number;
    voltageRegulatorBitFlags: number;
    railAdc5Volt: number;
    railAdc8Volt: number;
    referenceAdc: number;
    setDetectionMagnet2: number;
    setDetectionMagnet3: number;
    setDetectionMagnet4: number;
    rotorCurrentPeakToPeakNoTube: number;
    rotorCurrentAverageNoTube: number;
    setDetectionMagnet1: number;
    rotorCurrentPeakToPeakTube: number;
    rotorCurrentAverageTube: number;
    rotorRevolutionDuration: number;
    rotorOnAccuracy: number;
    cycleCompletionTime: number;
    peakUsVoltageUpFluid: number;
    usFrequencyUpFluid: number;
    peakUsVoltageDownFluid: number;
    usFrequencyDownFluid: number;
    powerButton: number;
    button1: number;
    button2: number;
    button3: number;
    button4: number;
    button5: number;
    xbeeVersion: string;
    wirelessAddress: string;
}

/**
 * Represents alarm data sent by a connect pump
 */
export interface AlertRecord {
    id: number;
    timestamp: Moment | string;
    softwareVersion: string;
    uiVersion: string;
    serialNumber: string;
    screen: number;
    wirelessAddress: string;
    language: number;
    systemErrorCode: number;
}

/**
 * Represents the different feed modes for a connect pump
 */
export enum PumpSettingsType {
    CONTINUOUS_FEED = 0,
    CONTINUOUS_DOSE = 1,
    BOLUS = 2,
}

/**
 * Represents a change in the pump's settings
 */
export interface PumpSettingsRecord {
    serialNumber: string;
    timestamp: Moment | string;
    feedMode: PumpSettingsType;
    ktoEnabled: boolean;
    feedRate: number;
    volume?: number;
    numberOfBoluses?: number;
    bolusInterval?: number;
}
