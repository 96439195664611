import {Moment} from 'moment';
import {Device} from '@/models/Devices/Device';
import {Team} from '@/models/Teams';
import {Searchable} from '@/models/Searchable';
import {PatientAssignmentStatus} from '@/models/patients/PatientAssignmentStatus';

/**
 * Represents a single patient
 */
export interface Patient {
    id: number | null;
    patientId: string;
    firstName: string;
    lastName: string;
    dateOfBirth: string | Moment | null;
    sex: string | null;
    feedPrescriptions: FeedPrescription[];
    weights: Weight[];
    goalWeight: GoalWeight[];
    assignments: PatientDeviceAssignment[];
    teams: Team[];
    notes?: PatientNotes[];
    createdDate?: string | Moment;
}

/**
 * Represents the assignment of a device to a patient
 */
export interface PatientDeviceAssignment {
    id: number;
    device: Device;
    startDate: string | Moment;
    endDate: string | Moment | null;
    patient?: Patient;
}

/**
 * Request data to create a new patient device assignment
 */
export interface PatientDeviceAssignmentCreate {
    deviceId: number;
    startDate: Moment | string;
    endDate: Moment | string | null;
}

/**
 * Represents notes that can be added to a patient
 */
export interface PatientNotes {
    id: number | null;
    notes: string;
}

/**
 * Represents a nutritional prescription for a patient
 */
export interface FeedPrescription {
    id: number;
    name: string;
    startDate: string | Moment;
    dailyVolume: number;
    notes: string;
    kcal: number;
}

/**
 * Represents a weight entry for a patient
 */
export interface Weight {
    id: number;
    date: string | Moment;
    weight: number;
}

/**
 * Represents the patient's current goal weight
 */
export interface GoalWeight {
    id: number;
    goalWeight: number;
    startDate: string | Moment;
    modifiedDate: string | Moment | null;
}

export enum PrescriptionFilter {
    PROVIDED,
    NOT_PROVIDED,
    PROVIDED_AND_NOT_PROVIDED,

}


export interface PatientListFilter {
    prescriptionFilter: PrescriptionFilter | null;
    pumpAssignmentFilter: PatientAssignmentStatus | null;
    teams: number[] | null;
    search: string;
    searchable: Searchable;
    pageSize: number;
    page: number;
    sortKey: string;
    sortedAsc: boolean;
}

export interface CreatePatientRequest {
    patient: Patient;
    teams: number[] | null;
    customer: number;
}
