import {AsyncEither, AsyncNullSuccess, Either, Failure, nullSuccess, Success} from '@/errors/Either';
import {Error} from '@/errors/Error';
import axios from 'axios';
import {ServiceableDevice} from '@/models/service/ServiceTools';
import {saveDownloadedFile} from '@/ts/utilities';

export default class ServiceToolsService {
    private timezone: string;


    constructor(timezone: string) {
        this.timezone = timezone;
    }

    /**
     * Removes any active assignments from a single pump
     * @param deviceId ID of the pump
     */
    public async returnSinglePumpToCustomer(deviceId: number): AsyncNullSuccess<Error> {
        try {
            await axios.delete(process.env.VUE_APP_BACKEND_URI + '/devices/service/' + deviceId + '/assignments');
            return nullSuccess();
        } catch (e) {
            return new Failure({
                message: 'Unable to return device to customer',
            });
        }
    }

    /**
     * Removes any active assignments from multiple pumps
     * @param deviceIds IDs of the pumps
     */
    public async bulkReturnPumpsToCustomer(deviceIds: number[]): AsyncNullSuccess<Error> {
        try {
            await axios.delete(process.env.VUE_APP_BACKEND_URI + '/devices/service/assignments', {
                data: {
                    deviceIds,
                },
            });
            return nullSuccess();
        } catch (e) {
            return new Failure({
                message: 'Unable to return devices to customer',
            });
        }
    }

    /**
     * Removes any active assignments and terminates any customer claims from a single pump
     * @param deviceId ID of the pump
     */
    public async returnSinglePumpToStock(deviceId: number): AsyncNullSuccess<Error> {
        try {
            await axios.delete(process.env.VUE_APP_BACKEND_URI + '/devices/service/' + deviceId + '/stock');
            return nullSuccess();
        } catch (e) {
            return new Failure({
                message: 'Unable to return device to stock',
            });
        }
    }

    /**
     * Removes any active assignments and terminates any customer claims from multiple pumps
     * @param deviceIds IDs of the pumps
     */
    public async bulkReturnPumpToStock(deviceIds: number[]): AsyncNullSuccess<Error> {
        try {
            await axios.delete(process.env.VUE_APP_BACKEND_URI + '/devices/service/stock', {
                data: {
                    deviceIds,
                },
            });
            return nullSuccess();
        } catch (e) {
            return new Failure({
                message: 'Unable to return devices to stock',
            });
        }
    }

    /**
     * Fetches the list of devices that can be serviced
     */
    public async fetchServiceableDevices(): AsyncEither<ServiceableDevice[], Error> {
        try {
            // tslint:disable-next-line:max-line-length
            const {data}: {data: ServiceableDevice[]} = await axios.get(process.env.VUE_APP_BACKEND_URI + '/devices/service');
            return new Success(data);
        } catch (e) {
            return new Failure({
                message: 'Unable to fetch serviceable devices',
            });
        }
    }

    /**
     * Exports all diagnostic data between two date ranges
     * @param startDate Start date of the export
     * @param endDate End date of the export
     */
    public async exportMassDiagnosticRecords(startDate: Date, endDate: Date): AsyncNullSuccess<Error> {
        try {
            const response = await axios.get(process.env.VUE_APP_BACKEND_URI + '/devices/service/diagnostics-export', {
                responseType: 'blob',
                params: {
                    startDate: startDate.toISOString(),
                    endDate: endDate.toISOString(),
                },
            });
            saveDownloadedFile(response, 'mass-diagnostic-export.xlsx');
            return nullSuccess();
        } catch (e) {
            return new Failure({
                message: 'Unable to export records',
            });
        }
    }
}
