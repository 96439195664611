

































import {Component, Prop, Vue} from "vue-property-decorator";
import {ButtonType} from "@/components/form/FormTypes";
import Spinner from '@/components/form/Spinner.vue';

/**
 * Renders a popup on the screen
 */
@Component({
    components: {
        Spinner
    }
})
export default class Modal extends Vue {
    /**
     * Title of the modal
     */
    @Prop() title!: string;

    /**
     * Text for the primary button
     */
    @Prop({default: ""}) primaryButtonText!: string;

    /**
     * Text for the secondary button
     */
    @Prop({default: ""}) secondaryButtonText!: string;

    /**
     * Text to display in the body
     */
    @Prop({default: ""}) bodyText!: string;

    /**
     * Whether the modal is currently loading
     */
    @Prop({default: false}) loading!: boolean;

    /**
     * Whether hte primary button is currently disabled
     */
    @Prop({default: false}) primaryButtonDisabled!: boolean;

    /**
     * Whether the button container for primary/secondary buttons should be hidden
     */
    @Prop({default: false}) hideButtonContainer!: boolean;

    buttonTypes = ButtonType;
}
