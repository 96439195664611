import {TableFilterData} from '@/ts/Filter';
import {CheckboxStateData} from '@/components/form/FormTypes';

/**
 * Different types of table cells
 */
export enum TableCellType {
    NORMAL,
    TWO_ROW,
    LABEL,
    ICON,
    SLOT,
    LINK,
    SLOT_LIST,
    RAW,
}

/**
 * Paginated data where not all of the data is
 * immediately available
 */
export interface PaginatedData<T> {
    totalElements: number;
    totalPages: number;
    data: T[];
}

/**
 * A page of data to be displayed in the table
 */
export interface PageData {
    numPages: number;
    pageData: TableRowData[];
    totalElements: number;
}

/**
 * Represents an icon to display in a table
 */
export interface Icon {
    name: string;
    action: (event: Event) => void;
    title?: string;
    tooltip?: string;
}

/**
 * Represents a row of data in the table
 */
export interface TableRowData {
    cells: TableCellData[];
    highlighted?: boolean;
    key?: any;
    class?: string;
    indexKey?: string | number | boolean | symbol;
}

/**
 * Represents the data for a specific table cell
 */
export interface TableCellData {
    type: TableCellType;
    primaryValue: string;
    secondaryValue?: string | any;
    icons?: Icon[];
    componentOptions?: any;
    components?: any;
    class?: string;
    innerClass?: string;
    clickable?: boolean;
}

export interface DynamicComponentOptions {
    events?: any;
    componentOptions?: any;
    primaryValue?: any;
    secondaryValue?: any;
}

/**
 * Data for the select all checkbox in a table
 */
export interface SelectAllData {
    hasSelectAll: boolean;
    state: CheckboxStateData;
    handler: () => void;
}

/**
 * Header data for a table
 */
export interface TableHeaderData {
    sortable: boolean;
    sortKey?: string;
    value: string;
    widthClass: string;
    filterable?: boolean;
    filters?: TableFilterData[];
    filterKey?: string;
    selectAll?: SelectAllData;
    filterLoading?: boolean;
}
