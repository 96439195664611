






import { Component, Vue, Prop } from 'vue-property-decorator';

/**
 * Displays a button in tab form
 */
@Component
export default class TabLink extends Vue {
    /**
     * Whether this item is currently active
     */
    @Prop(Boolean) isActive!: boolean;

    /**
     * Whether to apply the purple text when active
     */
    @Prop({type: Boolean, default: true}) coloredText!: boolean;

    /**
     * Default set of classes to apply
     */
    @Prop({default: () => ['border-b-2 cursor-pointer py-2 mr-6 -mb-px border-transparent']}) classes!: string[];
}
