import {AsyncNullSuccess, Failure, nullSuccess} from '@/errors/Either';
import {Error} from '@/errors/Error';
import axios from 'axios';


export default class ConnectWirelessDiagnosticsService {

    /**
     * Performs step one of wireless diagnostics
     * @param deviceId
     */
    public async stepOne(deviceId: string): AsyncNullSuccess<Error> {
        try {
            await axios.get(process.env.VUE_APP_BACKEND_URI + '/devices/marrs/' + deviceId + '/wireless-diagnostics/step-one');
            return nullSuccess();
        } catch (e) {
            const response = e.response;
            if (response.status === 422 && response.data.fieldErrors.length > 0){
                return new Failure({
                    statusCode: response.status,
                    message: response.data.fieldErrors[0].field
                });
            }

            return new Failure({
                message: 'Pump not connected',
            });
        }

    }

    /**
     * Performs step two of wireless diagnostics
     * @param deviceId
     */
    public async stepTwo(deviceId: string): AsyncNullSuccess<Error> {
        try {
            await axios.get(process.env.VUE_APP_BACKEND_URI + '/devices/marrs/' + deviceId + '/wireless-diagnostics/step-two');;
            return nullSuccess();
        } catch (e) {
            return new Failure({
                message: 'Pump not connected',
            });
        }
    }

    /**
     * Performs step three of wireless diagnostics
     * @param deviceId
     */
    public async stepThree(deviceId: string): AsyncNullSuccess<Error> {
        try {
            await axios.get(process.env.VUE_APP_BACKEND_URI + '/devices/marrs/' + deviceId + '/wireless-diagnostics/step-three');
            return nullSuccess();
        } catch (e) {
            return new Failure({
                message: 'Hub not connected',
            });
        }
    }

    /**
     * Performs step four of wireless diagnostics
     * @param deviceId
     */
    public async stepFour(deviceId: string): AsyncNullSuccess<Error> {
        try {
            await axios.get(process.env.VUE_APP_BACKEND_URI + '/devices/marrs/' + deviceId + '/wireless-diagnostics/step-four');
            return nullSuccess();
        } catch (e) {
            return new Failure({
                message: 'Device cloud not connected',
            });
        }
    }
}
