import {Error} from '@/errors/Error';
import {AsyncEither, AsyncNullSuccess, Either, Failure, nullSuccess, Success} from '@/errors/Either';
import axios from 'axios';
import {convertToMomentTimezone} from '@/ts/TimezoneUtils';
import {PatientDeviceAssignment, PatientDeviceAssignmentCreate} from '@/models/patients/Patient';
import {Optional} from '@/ts/utilities';
import {Moment} from 'moment';

export default class PatientDeviceAssignmentService {

    private readonly timezone: string;

    constructor(timezone: string) {
        this.timezone = timezone;
    }

    /**
     * Sends the request to create a new patient device assignment
     * @param patientId ID of the patient
     * @param assignmentInfo Assignment information
     */
    // tslint:disable-next-line:max-line-length
    public async createAssignment(patientId: number, assignmentInfo: PatientDeviceAssignmentCreate): AsyncEither<PatientDeviceAssignment, Error> {
        try {
            assignmentInfo.startDate = (assignmentInfo.startDate as Moment).milliseconds(0).utc();
            if (assignmentInfo.endDate !== null) {
                assignmentInfo.endDate = (assignmentInfo.endDate as Moment).milliseconds(0).utc();
            }
            // tslint:disable-next-line:max-line-length
            const {data}: {data: PatientDeviceAssignment} = await axios.post(process.env.VUE_APP_BACKEND_URI + '/patients/' + patientId + '/device-assignments', assignmentInfo);
            data.startDate = convertToMomentTimezone(this.timezone, data.startDate as string);
            if (data.endDate !== null) {
                data.endDate = convertToMomentTimezone(this.timezone, data.endDate as string);
            }
            return new Success(data);
        } catch (e) {
            return new Failure({
                message: 'Unable to create patient device assignment',
            });
        }
    }

    /**
     * Sends the request to update an existing assignment
     * @param patientId ID of the patient to update for
     * @param assignment Updated assignment info
     */
    // tslint:disable-next-line:max-line-length
    public async updateAssignment(patientId: number, assignment: Optional<PatientDeviceAssignment>): AsyncNullSuccess<Error> {
        const updatedAssignment = {...assignment};
        try {
            if (updatedAssignment.startDate !== null) {
                updatedAssignment.startDate = (updatedAssignment.startDate as Moment).clone().milliseconds(0).utc();
            }
            if (updatedAssignment.endDate !== null) {
                updatedAssignment.endDate = (updatedAssignment.endDate as Moment).clone().milliseconds(0).utc();
            }
            // tslint:disable-next-line:max-line-length
            await axios.patch(process.env.VUE_APP_BACKEND_URI + '/patients/' + patientId + '/device-assignments/' + updatedAssignment.id + '/update', updatedAssignment);
            return nullSuccess();
        } catch (e) {
            return new Failure({
                message: 'Unable to update patient device assignment',
            });
        }
    }

    /**
     * Sends the request to delete an existing assignment
     * @param patientId ID of the patient
     * @param assignmentId ID of the assignmt to delete
     */
    public async deleteAssignment(patientId: number, assignmentId: number): AsyncNullSuccess<Error> {
        try {
            // tslint:disable-next-line:max-line-length
            await axios.delete(process.env.VUE_APP_BACKEND_URI + '/patients/' + patientId + '/device-assignments/' + assignmentId);
            return nullSuccess();
        } catch (e) {
            return new Failure({
                message: 'Unable to remove patient device assignment',
            });
        }
    }

    /**
     * Sends the request to end a current patient device assignment
     * @param deviceId ID of the device
     * @param assignmentId ID of the assignment
     * @param endDate End date of the assignment
     */
    // tslint:disable-next-line:max-line-length
    public async unassignDeviceFromPatient(deviceId: number, assignmentId: number, endDate: Date): AsyncNullSuccess<Error> {
        try {
            // tslint:disable-next-line:max-line-length
            await axios.patch(process.env.VUE_APP_BACKEND_URI + '/devices/marrs/' + deviceId + '/device-assignments/' + assignmentId, {
                endDate,
            });
            return nullSuccess();
        } catch (e) {
            return new Failure({
                message: 'Unable to remove patient device assignment',
            });
        }
    }

}
