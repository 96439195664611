




























































import { Component, Vue } from 'vue-property-decorator';
import {Patient} from '@/models/patients/Patient';
import {namespace} from 'vuex-class';
import {ButtonType, RadioButtonData} from '../../form/FormTypes';
import {hasKey, NullableOptional} from '@/ts/utilities';
import {Validations} from '@/ts/validation-decorator';
import {maxLength, maxValue, minLength, minValue, required} from 'vuelidate/lib/validators';
import moment, {Moment} from 'moment';
import {PatientService} from '@/services/patients/PatientService';
import {PatientMutations} from '@/vuex/patients';

const patients = namespace('patients');

/**
 * Renders a form to update the patient's details
 */
@Component
export default class EditPatientDetailsCard extends Vue {
    @patients.State(state => state.homePage.patient) patient!: Patient;
    @patients.Mutation(PatientMutations.UPDATE_PATIENT) updatePatientData!: (patient: NullableOptional<Patient>) => void;

    /**
     * Container for patient info
     */
    updatePatient: NullableOptional<Patient> = {
        assignments: null,
        dateOfBirth: null,
        feedPrescriptions: null,
        firstName: '',
        goalWeight: null,
        id: null,
        lastName: '',
        patientId: '',
        sex: null,
        teams: null,
        weights: null
    };

    /**
     * Patients updated date of birth
     */
    dateOfBirth: Date | null = null;

    buttonTypes = ButtonType;

    /**
     * Radio button options for patient's sex
     */
    sexOptions: RadioButtonData[] = [
        {
            label: "Male",
            value: "MALE"
        },
        {
            label: "Female",
            value: "FEMALE"
        },
    ];

    patientService = new PatientService(this.$store.state.auth.loggedInUser.timezone);

    mounted() {
        this.updatePatient.firstName = this.patient.firstName;
        this.updatePatient.lastName = this.patient.lastName;
        this.updatePatient.patientId = this.patient.patientId;
        this.dateOfBirth = new Date((this.patient.dateOfBirth as Moment).year(), (this.patient.dateOfBirth as Moment).month(), (this.patient.dateOfBirth as Moment).date())
        this.updatePatient.sex = this.patient.sex !== null ? this.patient.sex.toUpperCase() : '';
    }

    /**
     * Set's up the form field validations
     */
    @Validations()
    validations(){
        return {
            updatePatient: {
                firstName: {
                    required,
                    maxLength: maxLength(30)
                },
                lastName: {
                    required,
                    maxLength: maxLength(30)
                },
                patientId: {
                    required,
                    maxLength: maxLength(30)
                },
            },
            dateOfBirth: {
                required,
                minValue: minValue(moment.tz().year(1900).month(0).date(1).subtract(1, 'day').toDate()),
                maxValue: maxValue(moment.tz().endOf('day').toDate())
            }
        }
    }

    /**
     * Updates the value of a field when it changes
     * Also refreshes the form validations
     * @param field Field that was updated
     * @param value New value for the field
     */
    updateField(field: string, value: any) {
        if(field === 'dateOfBirth') {
            if(this.dateOfBirth !== value) {
                this.$emit('dirty')
            }
            this.dateOfBirth = value;
            this.touchField('dateOfBirth')
        }else if(hasKey(this.updatePatient, field)) {
            if(this.updatePatient[field] as any !== value) {
                this.$emit('dirty')
            }
            (this.updatePatient[field] as any) = value;
            this.touchField(field);
        }
    }

    /**
     * Updates the form field validations for a specific field
     * @param field
     */
    touchField(field: string) {
        this.$nextTick(() => {
            if(field === 'dateOfBirth' && this.$v.dateOfBirth !== undefined) {
                this.$v.dateOfBirth.$touch();
            } else if(this.$v.updatePatient !== undefined && hasKey(this.$v.updatePatient, field) && this.$v.updatePatient[field] !== undefined) {
                (this.$v.updatePatient[field] as any).$touch();
            }
        });
    }

    /**
     * Sends the request to update the patient's details
     */
    async handleUpdatePatient() {
        this.updatePatient.id = this.patient.id;
        this.updatePatient.dateOfBirth = moment.tz(this.dateOfBirth, this.$store.state.auth.loggedInUser.timezone);
        if(this.updatePatient.sex !== null) {
            this.updatePatient.sex = this.updatePatient.sex.charAt(0) + this.updatePatient.sex.slice(1).toLowerCase();
        }
        const response = await this.patientService.updatePatient(this.updatePatient);
        response.map((updatedPatient) => {
            this.updatePatientData(updatedPatient);
            this.finishEditing();
        });
    }

    /**
     * Emits an event to le the parent know that editing is over
     */
    finishEditing() {
        this.$emit('doneEditing');
    }
}
