






















import { Component, Vue } from 'vue-property-decorator';
import {maxLength, required} from "vuelidate/lib/validators";
import Modal from '@/components/modal/Modal.vue';
import {namespace} from "vuex-class";
import User from '@/models/User';
import TeamService from '@/services/TeamService';

const auth = namespace('auth');

/**
 * Renders a modal to create a new team
 */
@Component({
    validations: {
        name: {
            required,
            maxLength: maxLength(30)
        }
    },
    components: {
        Modal
    }
})
export default class CreateTeamModal extends Vue {
    /**
     * Current name of the team
     */
    name = '';

    /**
     * Whether the create request is loading
     */
    createLoading = false;

    @auth.State('loggedInUser') loggedInUser!: User;

    teamService = new TeamService();

    /**
     * Emits an event to tell the parent to close the modal
     */
    dismissModal(){
        this.$emit('dismiss');
    }

    /**
     * Updates the form field validations
     * @param field
     */
    async touchField(field: string){
        this.$v[field].$touch();
    }

    /**
     * Sends the request to create a new team
     */
    async createTeam(){
        const customerId = this.loggedInUser.customers.length > 0 ? this.loggedInUser.customers[0].id : null;
        this.createLoading = true;
        await this.teamService.createTeam(this.name, customerId)
        this.$emit('teamCreated');
    }
}
