import {Validation} from 'vuelidate';
import axios from 'axios';
import {helpers, ValidationRule} from 'vuelidate/lib/validators';
import {formatMoment, formatMomentDateOnly} from '@/ts/TimezoneUtils';
import moment from 'moment';

/**
 * Returns which validation rules are enabled
 * @param $v
 */
function determineEnabledValidators($v: Validation) {
    return Object.getOwnPropertyNames($v.$params);
}

/**
 * Returns an array of error messages representing invalid fields
 * @param $v
 * @param fieldName
 */
function getErrorMessages($v: Validation, fieldName: string): string[] {
    const errors: string[] = [];
    const enabledValidators = determineEnabledValidators($v);
    for (const validator of enabledValidators) {
        // @ts-ignore
        if ($v[validator]) {
            continue;
        }
        switch (validator) {
            case 'required':
                errors.push(fieldName + ' cannot be blank');
                break;
            case 'requiredIf':
                errors.push(fieldName + ' cannot be blank');
                break;
            case 'minLength':
                errors.push(fieldName + ' must be ' + $v.$params[validator].min + ' characters or more');
                break;
            case 'maxLength':
                errors.push(fieldName + ' must be ' + $v.$params[validator].max + ' characters or less');
                break;
            case 'minValue':
                // tslint:disable-next-line:max-line-length
                errors.push(fieldName + ' must be greater than or equal to ' + (isDate($v.$params[validator].min) ? formatMomentDateOnly(moment($v.$params[validator].min)) : $v.$params[validator].min));
                break;
            case 'maxValue':
                // tslint:disable-next-line:max-line-length
                errors.push(fieldName + ' must be less than or equal to ' + (isDate($v.$params[validator].max) ? formatMomentDateOnly(moment($v.$params[validator].max)) : $v.$params[validator].max));
                break;
            case 'between':
                errors.push(fieldName + ' must be between ' +
                    $v.$params[validator].min + ' and ' + $v.$params[validator].max);
                break;
            case 'alpha':
                errors.push(fieldName + ' can only include letters');
                break;
            case 'numeric':
                errors.push(fieldName + ' can only include numbers');
                break;
            case 'integer':
                errors.push(fieldName + ' must be an integer');
                break;
            case 'decimal':
                errors.push(fieldName + ' must be a decimal');
                break;
            case 'email':
                errors.push(fieldName + ' must be a valid email');
                break;
            case 'alphaNum':
                errors.push(fieldName + ' can only include letters and numbers');
                break;
            case 'alphaWithSeparators':
                errors.push(fieldName + ' can only include letters, numbers, dashes, periods, underscore characters');
                break;
        }
    }

    return errors;
}

function isUnique(url: string, id?: number) {
    return async (value: any) => {
        if (value == null || value === '') {
            return false;
        }
        try {
            const request = {
                data: value,
            } as any;
            if (id !== undefined) {
                request.id = id;
            }
            await axios.post(url, request);
            return true;
        } catch (e) {
            return false;
        }
    };
}

function isDate(value: any): value is Date {
    return (value as Date).toISOString() !== undefined;
}

const alphaWithSeparators = helpers.regex('alphaWithSeparators', /^[a-zA-Z0-9\-_\.]*$/);

export {isUnique, determineEnabledValidators, getErrorMessages, alphaWithSeparators};
