






























import {Component, Prop, Vue} from 'vue-property-decorator';
import Modal from '@/components/modal/Modal.vue';
import Multiselect from 'vue-multiselect';
import {Team, TeamType} from '@/models/Teams';
import User from '@/models/User';
import {Role} from '@/ts/permissions/Role';
import {Patient} from '@/models/patients/Patient';
import TeamService from '@/services/TeamService';
import Spinner from '@/components/form/Spinner.vue';
import {requiredIf} from 'vuelidate/lib/validators';
import {Validations} from '@/ts/validation-decorator';
import {getErrorMessages} from '@/validations/Validator';

/**
 * Renders a modal to add a patient to multiple teams
 */
@Component({
    components: {
        Modal,
        Multiselect,
        Spinner
    }
})
export default class AddPatientToTeamsModal extends Vue {

    /**
     * Patient to add to teams
     */
    @Prop() patient!: Patient;

    /**
     * List of selected teams
     */
    selectedTeams: Team[] = [];

    /**
     * List of teams that can be assigned to
     */
    teams: Team[] = [];

    /**
     * List of team assignments taht cannot be managed by the current user
     */
    unmanageableTeams: Team[] = [];

    /**
     * Whether the request to load the list of teams is loading
     */
    loading = false;
    teamService = new TeamService();

    async mounted(): Promise<void> {
        const user = this.$store.state.auth.loggedInUser;
        await this.fetchTeams();
        this.patient.teams.forEach((team) => {
            if(team.type != TeamType.SYSTEM && user.role !== Role.ADMIN && user.teams.find((userTeam: Team) => userTeam.id === team.id) === undefined){
                this.unmanageableTeams.push(team);
            }else if(team.type != TeamType.SYSTEM) {
                this.selectedTeams.push(team);
            }
        })
    }

    /**
     * Form field validation
     */
    @Validations()
    validations() {
        return {
            selectedTeams: {
                requiredIf: requiredIf(() => !this.userIsOnSystemTeam)
            }
        }
    }

    /**
     * Whether the user can see all patients
     */
    get userIsOnSystemTeam() {
        return this.$store.state.auth.loggedInUser.teams.find((team: Team) => team.type === TeamType.SYSTEM) !== undefined;
    }

    /**
     * Form field error messages
     */
    get errorMessages() {
        return getErrorMessages(this.$v.selectedTeams, 'The list of teams')
    }

    /**
     * Sends the request to fetch the list of assignable teams
     */
    async fetchTeams(){
        const user = this.$store.state.auth.loggedInUser as User;
        if(user.role !== Role.ADMIN){
            this.teams = user.teams;
            return;
        }
        this.loading = true;
        const response = await this.teamService.listTeams()
        response.map((teams) => {
            this.teams = teams;
        })

        this.loading = false;
    }

    /**
     * Sends the request to assign the patient to the list of teams
     */
    async assignPatientToTeams() {
        const teamIds = this.selectedTeams.map((team: Team) => team.id);
        const systemTeam = this.patient.teams.find((team: Team) => team.type === TeamType.SYSTEM);
        await this.teamService.assignPatientToTeams(this.patient.id as number, teamIds);

        const newTeams = [... this.selectedTeams];
        if(systemTeam !== undefined){
            newTeams.push(systemTeam);
        }

        this.$emit('teamUpdate', [...newTeams, ...this.unmanageableTeams]);
    }
}
