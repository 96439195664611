








































































import { Component, Vue } from 'vue-property-decorator';
import {ButtonType} from "@/components/form/FormTypes";
import CustomerInfo from '@/components/customers/CustomerInfo.vue';
import {RawLocation, Route} from "vue-router";
import {Customer} from "@/models/customers/Customer";
import axios from 'axios'
import {getRegions} from "@/ts/Region";
import User from "@/models/User";
import {convertToMomentTimezone} from "@/ts/TimezoneUtils";
import CustomerStatus from '@/components/customers/CustomerStatus.vue';
import CustomerUsers from '@/components/customers/CustomerUsers.vue';
import Spinner from '@/components/form/Spinner.vue';
import ManageCustomerStatus from '@/components/customers/ManageCustomerStatus.vue';
import CustomerStatusHistory from '@/components/customers/CustomerStatusHistory.vue';
import {Permission} from "@/ts/permissions/Permission";

enum Tabs {
    GENERAL,
    STATUS
}
Component.registerHooks([
    'beforeRouteEnter',
    'beforeRouteLeave',
    'beforeRouteUpdate'
]);

/**
 * Renders a form to edit a customer
 */
@Component({
    components: {
        CustomerInfo,
        CustomerStatus,
        CustomerUsers,
        Spinner,
        ManageCustomerStatus,
        CustomerStatusHistory
    }
})
export default class CustomerEdit extends Vue {
    buttonTypes = ButtonType;
    tabs = Tabs;

    /**
     * Current customer data
     */
    currentCustomer: Customer | null = null;

    /**
     * Currently selected customer region
     */
    selectedRegion: any = null;

    /**
     * Whether the data is valid
     */
    dataValid = true;
    currentTab = Tabs.GENERAL;

    /**
     * Whether the data is loading
     */
    isLoading = true;
    Permission = Permission;


    async beforeRouteEnter(to: Route, from: Route, next: ((to?: RawLocation | false | ((vm: CustomerEdit) => any) | void) => void)){
        const {data} = await axios.get(process.env.VUE_APP_BACKEND_URI + '/customers/' + to.params.id);

        next((vm => {
            const user = vm.$store.state.auth.loggedInUser as User;
            if((data as Customer).createdDate as string != null && (data as Customer).createdDate as string != undefined){
                (data as Customer).createdDate = convertToMomentTimezone(user.timezone as string, (data as Customer).createdDate as string);
            }

            vm.currentCustomer = data;
            //@ts-ignore
            vm.selectedRegion = getRegions().find(region => region.value == vm.currentCustomer.region);
            vm.isLoading = false;
        }));
    }

    mounted(){
    }

    /**
     * Updates the region information when the field changes
     * @param region
     */
    regionChanged(region: any){
        this.selectedRegion = region;
        if(this.currentCustomer != null){
            this.currentCustomer.region = region != null ? region.value: null;
        }

    }

    /**
     * Sends the request to update the customer
     * todo: refactor to service
     */
    async saveChanges(){
        if(this.currentCustomer == null) {
            return;
        }
        try{
            await axios.patch(process.env.VUE_APP_BACKEND_URI + '/customers/' + this.currentCustomer.id, this.currentCustomer)
        }catch(e){
            console.log(e)
        }
    }

    /**
     * Updates the customer's status
     * @param status
     */
    handleStatusChange(status: number){
        if(this.currentCustomer != null){
            this.currentCustomer.customerStatus = status;
            this.currentTab = this.tabs.GENERAL;
        }
    }


}
