












































import { Component, Vue } from 'vue-property-decorator';
import {Patient} from '@/models/patients/Patient';
import {namespace} from 'vuex-class';
import {formatMomentDateOnly} from '@/ts/TimezoneUtils';
import {Moment} from 'moment';
import {ButtonType} from '@/components/form/FormTypes';

const patients = namespace('patients');

/**
 * Renders a card displaying the patient's details
 */
@Component
export default class PatientDetailsCard extends Vue {
    @patients.State(state => state.homePage.patient) patient!: Patient;
    buttonTypes = ButtonType;

    /**
     * Formats the patient's date of birth
     */
    get dateOfBirth(){
        return formatMomentDateOnly(this.patient.dateOfBirth as Moment);
    }

    /**
     * Formats the patient's create on date
     */
    get createdOn(){
        return formatMomentDateOnly(this.patient.createdDate as Moment);
    }
}
