





































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {
    BooleanFilterData,
    CalendarFilterData, FilterDataType,
    FilterType,
    MultiselectFilterData, RangeFilterData,
    TableFilterData
} from "@/ts/Filter";
import Chip from '@/components/form/Chip.vue';
import VueSlider from 'vue-slider-component';

/**
 * Represents a filter for a specific column of table data
 */
@Component({
    components: {
        Chip,
        VueSlider
    }
})
export default class TableFilter extends Vue {
    /**
     * Table filter data
     */
    @Prop() filterData!: TableFilterData;

    /**
     * Any active items for the filter
     */
    @Prop({default: () => {}}) activeItems!: any;
    FilterType = FilterType;

    /**
     * Whether an item for the filter is active
     * @param value
     */
    isActive(value: any){
        return this.activeItems.includes(value);
    }

    /**
     * Emits an event when the filter data changes
     * @param value
     */
    emitClick(value: any){
        const data = this.filterData.data;
        if(this.isMultiselect(data) && !data.multiselect && this.activeItems.length > 0 && this.activeItems[0] != value){
            this.$emit('filter', this.activeItems[0]);
        }
        this.$emit('filter', value);
    }

    /**
     * Whether the filter is multiselectable data
     * @param data
     */
    isMultiselect(data: FilterDataType): data is MultiselectFilterData{
        return (data as MultiselectFilterData).multiselect !== undefined;
    }
}
