














import {Component, Prop, Vue} from 'vue-property-decorator';
import Table from '../../table/Table.vue';
import {TableCellData, TableCellType, TableRowData} from '../../table/cells/TableCellData';
import moment, {Moment} from 'moment';
import FormInput from '../../form/FormInput.vue';
import {Patient, PatientDeviceAssignment} from '@/models/patients/Patient';
import {formatMomentDateOnly} from '@/ts/TimezoneUtils';
import {AssignableDeviceDto, Device} from '@/models/Devices/Device';
import Checkbox from '@/components/form/Checkbox.vue';
import PatientDeviceAssignmentService from '@/services/patients/PatientDeviceAssignmentService';
import {Optional} from '@/ts/utilities';
import InnerTableDeleteConfirmation from '@/components/table/InnerTableDeleteConfirmation.vue';


interface DateOpt {
    start: Date | null;
    end: Date | null;
}

/**
 * Renders an editable table of patient device assignments
 */
@Component({
    components: {
        Table,
        InnerTableDeleteConfirmation
    }
})
export default class EditAssignmentTable extends Vue {
    /**
     * ID of the assignment currently being editing
     */
    assignmentBeingEditedId = 0;

    /**
     * Start/End date of the assignment being edited
     */
    editingAssignmentOpt: DateOpt | null = null;

    /**
     * Start date of the assignment being edited
     */
    editingAssignmentDate: Moment | null = null;

    /**
     * Device for the assignment currently being edited
     */
    editingDevice: Device | null = null;

    /**
     * Assignment currently being removed
     */
    assignmentBeingRemoved: PatientDeviceAssignment | null = null;

    /**
     * Whether an end date is being set for the assignment being edited
     */
    endingAssignment = false;

    /**
     * List of assignments
     */
    patientCurrentAssignments: PatientDeviceAssignment[] = [];

    /**
     * List of assignable devices
     */
    @Prop() devices!: AssignableDeviceDto[];

    /**
     * Patient assignments are being edited for
     */
    @Prop() patient!: Patient;
    today = moment.tz(this.$store.state.auth.loggedInUser.timezone).startOf('day');

    assignmentService = new PatientDeviceAssignmentService(this.$store.state.auth.loggedInUser.timezone);

    mounted() {
        if(this.patient.assignments !== undefined && this.patient.assignments.length > 0) {
            this.patientCurrentAssignments.push(...this.patient.assignments)
        }
    }

    /**
     * Determines which dates should be disabled in the date picker based on
     * the start/end date of all prior assignments
     */
    getDisabledDates(assignmentId: number) {
        const dates: DateOpt[] = [];
        this.patientCurrentAssignments.forEach((assignment) => {
            if(assignment.id !== assignmentId) {
                dates.push(this.processAssignment(assignment))
            }
        });

        if(this.editingDevice !== null && this.editingDevice.assignments !== undefined && this.editingDevice.assignments.length > 0) {
            this.editingDevice.assignments.forEach((assignment) => {
                if(assignment.id !== assignmentId) {
                    dates.push(this.processAssignment(assignment));
                }
            })
        }
        return dates;
    }

    /**
     * Transforms the start/end date of the assignments from
     * Moment objects to date objects
     */
    processAssignment(assignment: PatientDeviceAssignment) {
        if(assignment.endDate !== null){
            return {
                start: (assignment.startDate as Moment).toDate(),
                end: (assignment.endDate as Moment).toDate()
            };
        } else {
            return {
                start: (assignment.startDate as Moment).toDate(),
                end: this.today.clone().toDate()
            }
        }
    }

    /**
     * Table header definition
     */
    get tableHeader() {
        return [
            {
                sortable: false,
                value: "Pump",
                widthClass: "w-2/5",
            },
            {
                sortable: false,
                value: "Assignment Range",
                widthClass: "w-2/5",
            },
            {
                sortable: false,
                value: "Actions",
                widthClass: "w-1/5",
            },
        ]
    }

    /**
     * Transforms the list of assignments into an array of table rows
     * Creates special rows based on whether the assignment is being
     * edited or removed
     */
    get tableData(): TableRowData[] {
        return this.patient.assignments.flatMap((assignment) => {
            if(assignment.id === this.assignmentBeingEditedId) {
                return this.createEditingTableRow(assignment);
            } else if (this.assignmentBeingRemoved !== null && assignment.id === this.assignmentBeingRemoved.id) {
                return this.createRemovingTableRow(assignment);
            } else {
                return this.createNormalTableRow(assignment, true);
            }
        })
    }

    /**
     * Generates the table row data for a normal row (not editing)
     * @param assignment
     */
    createNormalTableRow(assignment: PatientDeviceAssignment, showIcons: boolean): TableRowData[] {
        const cells = [
            {
                type: TableCellType.NORMAL,
                primaryValue: assignment.device.serialNumber,
                class: 'w-2/5',
            },
            {
                type: TableCellType.NORMAL,
                primaryValue: this.createDateRangeString(assignment),
                class: 'w-2/5',
            },
            {
                type: TableCellType.ICON,
                primaryValue: '',
                class: 'w-1/5',
                icons: showIcons ? [
                    {
                        name: 'pen',
                        tooltip: 'Edit assignment',
                        action: () => {
                            this.assignmentBeingEditedId = assignment.id;
                            this.editingDevice = assignment.device;
                            this.endingAssignment = false;
                            if (assignment.endDate !== null) {
                                this.editingAssignmentOpt = {
                                    start: (assignment.startDate as Moment).toDate(),
                                    end: (assignment.endDate as Moment).toDate()
                                };
                                this.endingAssignment = true;
                            } else {
                                this.editingAssignmentDate = assignment.startDate as Moment;
                            }
                        }
                    },
                    {
                        name: 'trash',
                        tooltip: 'Delete assignment',
                        action: () => {
                            this.assignmentBeingRemoved = assignment;
                        }
                    }
                ] : []
            },
        ]

        return [{
            highlighted: false,
            cells,
            indexKey: assignment.id
        }]
    }

    /**
     * Generates the table row for an assignment being edited
     * @param assignment
     */
    createEditingTableRow(assignment: PatientDeviceAssignment): TableRowData[] {
        const cells: TableCellData[] = [];

        let minDate = new Date();
        if (this.editingDevice !== null) {
            const deviceWithData = this.findDeviceFromAssignment(this.editingDevice.id);
            if(deviceWithData !== undefined) {
                minDate = (deviceWithData.earliestAssignableDate as Moment).toDate()
            }
        }

        const dateSelectorComponents: any[] = [
            {
                primaryValue: FormInput,
                componentOptions: {
                    class: 'w-full z-150',
                    label: (assignment.endDate !== null || this.endingAssignment) ? 'Assignment Date Range' : 'Assignment Start Date',
                    type: 'date',
                    calendarOpts: {
                        inline: false,
                        popover: {
                            placement: 'left',
                            visibility: 'hover-focus'
                        },
                        mode: (assignment.endDate !== null || this.endingAssignment) ? 'range' : 'single',
                        disabledDates: [...this.getDisabledDates(assignment.id)],
                        minDate
                    },
                    value: (assignment.endDate !== null || this.endingAssignment) ? this.editingAssignmentOpt : this.editingAssignmentDate?.toDate()
                },
                events: {
                    input: (data: DateOpt | Date) => {
                        if((assignment.endDate !== null || this.endingAssignment) && !this.isDate(data)) {
                            this.editingAssignmentOpt = data;
                        } else {
                            this.editingAssignmentDate = moment.tz(data as Date, this.$store.state.auth.loggedInUser.timezone);
                        }
                    }
                }
            }
        ];

        if(assignment.endDate === null) {
            dateSelectorComponents.push({
                primaryValue: Checkbox,
                componentOptions: {
                    label: 'End Assignment',
                    checked: this.endingAssignment
                },
                events: {
                    change: (data: boolean) => {
                        this.endingAssignment = data;
                        if (data) {
                            this.editingAssignmentOpt = {
                                start: (assignment.startDate as Moment).toDate(),
                                end: this.today.clone().toDate()
                            };
                        } else {
                            this.editingAssignmentDate = (assignment.startDate as Moment);
                        }
                    }
                }
            })
        }

        cells.push(
            {
                type: TableCellType.NORMAL,
                primaryValue: assignment.device.serialNumber,
                class: 'w-2/5',
            },
            {
                type: TableCellType.SLOT_LIST,
                primaryValue: '',
                class: 'w-2/5',
                components: dateSelectorComponents
            },
            {
                type: TableCellType.ICON,
                primaryValue: '',
                class: 'w-1/5',
                icons: [
                    {
                        name: 'check',
                        tooltip: 'Save changes',
                        action: () => {
                            this.updateAssignment();
                        }
                    },
                    {
                        name: 'times',
                        tooltip: 'Cancel changes',
                        action: () => {
                            this.editingAssignmentOpt = null;
                            this.editingAssignmentDate = null;
                            this.assignmentBeingEditedId = 0;
                            this.editingDevice = null;
                            this.endingAssignment = false;
                        }
                    }
                ]
            },
        );

        return [{
            highlighted: false,
            cells
        }]
    }

    /**
     * Creates the table row for the assignment currently being removed
     * @param assignment Assignment to create table row for
     */
    createRemovingTableRow(assignment: PatientDeviceAssignment): TableRowData[] {
        const rows: TableRowData[] = []
        const normalRow = this.createNormalTableRow(assignment, false)[0]
        normalRow.class = 'z-20 bg-white'

        rows.push(normalRow)
        if(this.assignmentBeingRemoved === null) {
            return rows;
        }

        rows.push({
            highlighted: false,
            class: 'z-20 shadow-md',
            cells: [{
                class: 'w-full px-0 py-0',
                primaryValue: '',
                type: TableCellType.SLOT_LIST,
                components: [
                    {
                        primaryValue: InnerTableDeleteConfirmation,
                        componentOptions: {
                            mainTextValue: `Delete ${this.assignmentBeingRemoved.device.serialNumber} Assignment?`,
                            secondaryTextValue: 'Are you sure you wish to permanently delete this assignment entry?'
                        },
                        events: {
                            delete: () => {
                                if(this.assignmentBeingRemoved === null) {
                                    return;
                                }
                                this.deleteAssignment()
                            },
                            cancel: () => {
                                this.assignmentBeingRemoved = null;
                            }
                        }
                    }
                ]
            }]
        })

        return rows
    }

    /**
     * Converts the start/end date of the assignments into a date range string
     * @param assignment Assignment to create date range string for
     */
    createDateRangeString(assignment: PatientDeviceAssignment) {
        if(assignment.endDate === null) {
            return formatMomentDateOnly(assignment.startDate as Moment) + " - Now";
        } else {
            return formatMomentDateOnly(assignment.startDate as Moment) + " - " + formatMomentDateOnly(assignment.endDate as Moment);
        }
    }

    /**
     * Finds the assignable device Dto object based on the device ID of the device being edited
     * @param deviceId ID of the device to find
     */
    findDeviceFromAssignment(deviceId: number): AssignableDeviceDto | undefined {
        return this.devices.find((device) => device.id === deviceId);
    }

    /**
     * Sends the request to delete an assignment
     */
    async deleteAssignment() {
        if(this.assignmentBeingRemoved === null) {
            return;
        }

        const response = await this.assignmentService.deleteAssignment(this.patient.id as number, this.assignmentBeingRemoved.id);
        if(response.isSuccess()){
            this.$emit('removeAssignment', this.assignmentBeingRemoved.id);
        } else {
            this.$addSnackbarMessage({
                message: 'There was an issue removing the assignment. Try again later or contact customer support.',
            });
        }
        this.assignmentBeingRemoved = null;
    }

    /**
     * Sends the request to update an assignment
     */
    async updateAssignment() {
        if((this.endingAssignment && this.editingAssignmentOpt === null) || (!this.endingAssignment && this.editingAssignmentDate === null)){
            return;
        }
        const timezone = this.$store.state.auth.loggedInUser.timezone;
        const assignment: Optional<PatientDeviceAssignment> = {
            id: this.assignmentBeingEditedId,
            startDate: moment.tz(this.endingAssignment ? (this.editingAssignmentOpt as DateOpt).start : this.editingAssignmentDate?.toDate(), timezone).startOf('day'),
            endDate: this.endingAssignment ? moment.tz((this.editingAssignmentOpt as DateOpt).end, timezone).endOf('day') : null
        };

        const response = await this.assignmentService.updateAssignment(this.patient.id as number, assignment);

        if(response.isSuccess()){
            this.$emit('updateAssignment', assignment);
            this.assignmentBeingEditedId = -1;
        } else {
            this.$addSnackbarMessage({
                message: 'There was an issue updating the assignment. Try again later or contact customer support.',
            });
        }
    }

    /**
     * Type guard to determine if the object passed in is a Date
     */
    isDate(data: DateOpt | Date): data is Date {
        return (data as Date).getDate !== undefined;
    }
}
