




















































import {Component, Prop, Vue} from 'vue-property-decorator';
import Modal from '../../modal/Modal.vue';
import {FeedPrescription} from '@/models/patients/Patient';
import PrescriptionForm from '@/components/patients/prescriptions/PrescriptionForm.vue';
import {Moment} from 'moment';
import {NumericalMap} from '@/models/MARRS/MonitorMode/ScreenInfo';
import {formatMomentDateOnly} from '@/ts/TimezoneUtils';

enum Tabs {
    EDIT,
    CREATE
}

/**
 * Renders a modal for managing a patient's prescriptions
 */
@Component({
    components: {
        Modal,
        PrescriptionForm
    }
})
export default class PrescriptionManagementModal extends Vue {
    tabs = Tabs;

    /**
     * Currently selected tab
     */
    currentTab = Tabs.EDIT;

    /**
     * ID of the current prescription
     */
    @Prop() patientId!: number;

    /**
     * Array of patient's prescriptions
     */
    @Prop() prescriptions!: FeedPrescription[];

    /**
     * ID of the prescription that is currently expanded
     */
    expandedPrescriptionId: number = 0;

    mounted(){
        if(this.prescriptions.length === 0) {
            this.currentTab = Tabs.CREATE;
        }
    }

    /**
     * Creates an array of disabled prescription dates
     * Excludes the start date of the prescription matching the ID passed in
     * @param id ID of prescription to ignore
     */
    getDisabledDates(id: number) {
        const dates: Date[] = [];
        this.prescriptions.forEach((prescription) => {
            if(prescription.id !== id){
                dates.push((prescription.startDate as Moment).toDate())
            }
        });
        return dates;
    }

    /**
     * Creates a map, mapping prescription IDs to the date range string
     */
    get prescriptionDateRangeMap() {
        const prescriptionRangeMap: NumericalMap<string> = {};
        for(let i = 0; i < this.prescriptions.length; i++) {
            const currentPrescription = this.prescriptions[i];
            if(i - 1 >= 0) {
                this.$set(prescriptionRangeMap, currentPrescription.id,`${formatMomentDateOnly(currentPrescription.startDate as Moment)} - ${formatMomentDateOnly((this.prescriptions[i - 1].startDate as Moment).subtract(1, 'day'))}`);
            } else {
                this.$set(prescriptionRangeMap, currentPrescription.id,`${formatMomentDateOnly(currentPrescription.startDate as Moment)} - Now`);
            }
        }

        return prescriptionRangeMap;
    }

    /**
     * Fetches the date range string for a prescription from the map
     * @param prescriptionId
     */
    getPrescriptionDateRange(prescriptionId: number) {
        if(this.prescriptionDateRangeMap.hasOwnProperty(prescriptionId)){
            return this.prescriptionDateRangeMap[prescriptionId];
        } else {
            return ''
        }
    }

    /**
     * Updates which prescription is currently expanded
     * @param id
     */
    updateSelectedPrescription(id: number){
        if(this.expandedPrescriptionId === id) {
            this.expandedPrescriptionId = 0;
        }else{
            this.expandedPrescriptionId = id;
        }
    }

    /**
     * Emits an event when a prescription is created
     * @param eventData Prescription data
     */
    handlePrescriptionCreated(eventData: any) {
        this.$emit('newPrescription', eventData);
        this.currentTab = Tabs.EDIT;
    }
}
