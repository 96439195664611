import {
    FeedPrescription,
    GoalWeight,
    Patient,
    PatientDeviceAssignment,
    PatientNotes,
    Weight
} from '@/models/patients/Patient';
import {ActionContext, Module} from 'vuex';
import {RootState} from '@/vuex/rootState';
import {PatientService} from '@/services/patients/PatientService';
import axios from 'axios';
import moment, {Moment} from 'moment';
import {Team} from '@/models/Teams';
import {or} from 'vuelidate/lib/validators';
import {convertToMomentTimezone, formatMomentDateOnly} from '@/ts/TimezoneUtils';
import User from '@/models/User';


export interface PatientState {
    homePage: PatientHomePageModel;
}

export interface PatientHomePageModel {
    patient: Patient | null;
    deviceConnected: boolean;
    deviceConnectionPending: boolean;
}

export enum PatientMutations {
    SET_PATIENT_HOME_PAGE_PATIENT = 'setPatientHomePagePatient',
    UPDATE_PATIENT = 'updatePatient',
    SET_PATIENT_HOME_PAGE_DEVICE_CONNECTION = 'setPatientHomePageDeviceConnection',
    ADD_GOAL_WEIGHT = 'addGoalWeight',
    UPDATE_GOAL_WEIGHT = 'updateGoalWeight',
    ADD_WEIGHT = 'addWeight',
    UPDATE_WEIGHT = 'updateWeight',
    REMOVE_WEIGHT = 'removeWeight',
    ADD_NOTES = 'addNotes',
    UPDATE_NOTES = 'updateNotes',
    UPDATE_TEAM_LIST = 'updateTeamList',
    ADD_PRESCRIPTION = 'addPrescription',
    UPDATE_PRESCRIPTION = 'updatePrescription',
    REMOVE_PRESCRIPTION = 'removePrescription',
    ADD_ASSIGNMENT = 'addAssignment',
    UPDATE_ASSIGNMENT = 'updateAssignment',
    REMOVE_ASSIGNMENT = 'removeAssignment',
}

export enum PatientActions {
    FETCH_PATIENT_HOME_PAGE_PATIENT = 'fetchPatientHomePagePatient',
    CHECK_PATIENT_HOME_DEVICE_CONNECTION = 'checkPatientHomeDeviceConnection',
}

export enum PatientGetters {
    GET_LATEST_HOMEPAGE_ASSIGNMENT = 'getLatestHomePageAssignment',
    GET_FUTURE_ASSIGNMENT_START_DATE = 'getFutureAssignmentStartDate',
}

type PatientActionContext = ActionContext<PatientState, RootState>;

const patients: Module<PatientState, RootState> = {
    namespaced: true,
    state: {
        homePage: {
            patient: null,
            deviceConnected: false,
            deviceConnectionPending: true,
        },
    },
    getters: {
        [PatientGetters.GET_LATEST_HOMEPAGE_ASSIGNMENT](state, getters, rootState) {
            if (state?.homePage?.patient?.assignments?.length !== undefined
                && state.homePage.patient.assignments.length > 0) {
                const hasPastAssignments = state.homePage.patient.assignments.length > 1;
                const startOfToday = moment.tz((rootState.auth.loggedInUser as User).timezone as string).startOf('day');
                const latestAssignmentEnd = state.homePage.patient.assignments[0].endDate;

                if (hasPastAssignments) {
                    const priorAssignmentEnd = state.homePage.patient.assignments[1].endDate as Moment;

                    if(priorAssignmentEnd.isAfter(startOfToday)) {
                        return state.homePage.patient.assignments[1];
                    }
                }
                const latestAssignmentStartDate = state.homePage.patient.assignments[0].startDate as Moment;
                if ((latestAssignmentEnd === null && latestAssignmentStartDate.isSameOrBefore(startOfToday)) ||
                    (latestAssignmentEnd !== null && (latestAssignmentEnd as Moment).isAfter(startOfToday))) {
                    return state.homePage.patient.assignments[0];
                }
            }

            return null;
        },
        [PatientGetters.GET_FUTURE_ASSIGNMENT_START_DATE](state, getters, rootState) {
            if (state?.homePage?.patient?.assignments?.length !== undefined
                && state.homePage.patient.assignments.length > 0) {
                const today = moment.tz((rootState.auth.loggedInUser as User).timezone as string).startOf('day');
                // tslint:disable-next-line:max-line-length
                if ((state.homePage.patient.assignments[0].startDate as Moment).isAfter(today)) {
                    return formatMomentDateOnly(state.homePage.patient.assignments[0].startDate as Moment);
                }
            }

            return null;
        }
    },
    mutations: {
        [PatientMutations.SET_PATIENT_HOME_PAGE_PATIENT](state, patient: Patient) {
            state.homePage.patient = patient;
        },
        [PatientMutations.UPDATE_PATIENT](state, patient: Patient) {
            if (state.homePage.patient !== null) {
                state.homePage.patient.firstName = patient.firstName;
                state.homePage.patient.lastName = patient.lastName;
                state.homePage.patient.sex = patient.sex;
                state.homePage.patient.dateOfBirth = patient.dateOfBirth;
                state.homePage.patient.patientId = patient.patientId;
            }

        },
        [PatientMutations.SET_PATIENT_HOME_PAGE_DEVICE_CONNECTION](state, data: {connected: boolean, pending: boolean}){
            state.homePage.deviceConnected = data.connected;
            state.homePage.deviceConnectionPending = data.pending;
        },
        [PatientMutations.ADD_GOAL_WEIGHT](state, data: GoalWeight) {
            if (state?.homePage?.patient?.goalWeight?.length !== undefined) {
                state.homePage.patient.goalWeight.push(data);
            }
        },
        [PatientMutations.UPDATE_GOAL_WEIGHT](state, data: {goalWeight: GoalWeight, timezone: string}) {
            if (state?.homePage?.patient?.goalWeight?.length !== undefined) {
                const goalWeight = state.homePage.patient.goalWeight.find((gw) => gw.id === data.goalWeight.id);
                if (goalWeight !== undefined) {
                    goalWeight.goalWeight = data.goalWeight.goalWeight;
                    goalWeight.modifiedDate = moment.tz(data.timezone);
                }
            }
        },
        [PatientMutations.ADD_WEIGHT](state, data: Weight) {
            if (state?.homePage?.patient?.weights?.length !== undefined) {
                state.homePage.patient.weights.push(data);
                state.homePage.patient.weights.sort((a, b) => {
                    return (b.date as Moment).diff(a.date as Moment);
                });
            }
        },
        [PatientMutations.UPDATE_WEIGHT](state, data: Weight) {
            if (state?.homePage?.patient?.weights?.length !== undefined) {
                const  originalWeight = state.homePage.patient.weights.find((w) => w.id === data.id);
                if (originalWeight !== undefined) {
                    originalWeight.date = data.date;
                    originalWeight.weight = data.weight;
                    state.homePage.patient.weights.sort((a, b) => {
                        return (b.date as Moment).diff(a.date as Moment);
                    });
                }
            }
        },
        [PatientMutations.REMOVE_WEIGHT](state, weightId: number) {
            if (state?.homePage?.patient?.weights?.length !== undefined) {
                const weightIndex = state.homePage.patient.weights.findIndex((w) => w.id === weightId);
                if (weightIndex >= 0) {
                    state.homePage.patient.weights.splice(weightIndex, 1);
                }
            }
        },
        [PatientMutations.ADD_NOTES](state, notes: PatientNotes) {
            if (state?.homePage?.patient?.notes?.length !== undefined) {
                state.homePage.patient.notes.push(notes);
            }
        },
        [PatientMutations.UPDATE_NOTES](state, notes: PatientNotes) {
            if (state?.homePage?.patient?.notes?.length !== undefined) {
                const originalNotes = state.homePage.patient.notes.find((n) => n.id === notes.id);
                if (originalNotes !== undefined) {
                    originalNotes.notes = notes.notes;
                }
            }
        },
        [PatientMutations.UPDATE_TEAM_LIST](state, newTeams: Team[]) {
            if (state?.homePage?.patient?.teams?.length !== undefined) {
                state.homePage.patient.teams = newTeams;
            }
        },
        [PatientMutations.ADD_PRESCRIPTION](state, prescription: FeedPrescription) {
            if (state?.homePage?.patient?.feedPrescriptions?.length !== undefined) {
                state.homePage.patient.feedPrescriptions.push(prescription);
                state.homePage.patient.feedPrescriptions.sort((a, b) => {
                    return (b.startDate as Moment).diff(a.startDate as Moment);
                });
            }
        },
        [PatientMutations.UPDATE_PRESCRIPTION](state, prescription: FeedPrescription) {
            if (state?.homePage?.patient?.feedPrescriptions?.length !== undefined) {
                // tslint:disable-next-line:max-line-length
                const originalPrescription = state.homePage.patient.feedPrescriptions.find((feedPrescription) => feedPrescription.id === prescription.id);
                if(originalPrescription !== undefined) {
                    originalPrescription.startDate = prescription.startDate;
                    originalPrescription.notes = prescription.notes;
                    originalPrescription.kcal = prescription.kcal;
                    originalPrescription.dailyVolume = prescription.dailyVolume;
                    originalPrescription.name = prescription.name;
                    state.homePage.patient.feedPrescriptions.sort((a, b) => {
                        return (b.startDate as Moment).diff(a.startDate as Moment);
                    });
                }
            }
        },
        [PatientMutations.REMOVE_PRESCRIPTION](state, prescriptionId: number) {
            if (state?.homePage?.patient?.feedPrescriptions?.length !== undefined) {
                // tslint:disable-next-line:max-line-length
                const prescriptionIndex = state.homePage.patient.feedPrescriptions.findIndex((prescription) => prescription.id === prescriptionId);
                if (prescriptionIndex >= 0) {
                    state.homePage.patient.feedPrescriptions.splice(prescriptionIndex, 1);
                }
            }
        },
        [PatientMutations.ADD_ASSIGNMENT](state, assignment: PatientDeviceAssignment) {
            if (state?.homePage?.patient?.assignments?.length !== undefined) {
                const existingAssignment = state.homePage.patient.assignments
                    .find((findAssignment) => findAssignment.endDate === null);
                if (existingAssignment !== undefined && assignment.endDate === null) {
                    existingAssignment.endDate = (assignment.startDate as Moment).clone()
                        .subtract(1, 'day').endOf('day');
                }
                state.homePage.patient.assignments.push(assignment);
                state.homePage.patient.assignments.sort((a, b) => {
                    return (b.startDate as Moment).diff(a.startDate as Moment);
                });
            }
        },
        [PatientMutations.UPDATE_ASSIGNMENT](state, assignment: PatientDeviceAssignment) {
            if (state?.homePage?.patient?.assignments?.length !== undefined) {
                const originalAssignment = state.homePage.patient.assignments
                    .find((deviceAssignment) => deviceAssignment.id === assignment.id);
                if (originalAssignment !== undefined) {
                    originalAssignment.startDate = assignment.startDate;
                    originalAssignment.endDate = assignment.endDate;
                    state.homePage.patient.assignments.sort((a, b) => {
                        return (b.startDate as Moment).diff(a.startDate as Moment);
                    });
                }
            }
        },
        [PatientMutations.REMOVE_ASSIGNMENT](state, assignmentId: number) {
            if (state?.homePage?.patient?.assignments?.length !== undefined) {
                const assignmentIndex = state.homePage.patient.assignments
                    .findIndex((assignment) => assignment.id === assignmentId);
                if (assignmentIndex >= 0) {
                    state.homePage.patient.assignments.splice(assignmentIndex, 1);
                }
            }
        },
    },
    actions: {
        async [PatientActions.FETCH_PATIENT_HOME_PAGE_PATIENT](context: PatientActionContext, patientId: number) {
            const timezone = context.rootState.auth.loggedInUser?.timezone;
            if (timezone === undefined) {
                return;
            }

            const patientService = new PatientService(timezone);
            const result = await patientService.fetchPatient(patientId);
            if (result.isSuccess()) {
                result.map((patient) => {
                    context.commit(PatientMutations.SET_PATIENT_HOME_PAGE_PATIENT, patient);
                });
            }
        },
        async [PatientActions.CHECK_PATIENT_HOME_DEVICE_CONNECTION](context: PatientActionContext, deviceId: number) {
            context.commit(PatientMutations.SET_PATIENT_HOME_PAGE_DEVICE_CONNECTION, {pending: true, connected: false});
            try {
                const {status} = await axios.get(process.env.VUE_APP_BACKEND_URI +
                    '/devices/marrs/' + deviceId + '/wireless-diagnostics/step-two');
                // tslint:disable-next-line:max-line-length
                context.commit(PatientMutations.SET_PATIENT_HOME_PAGE_DEVICE_CONNECTION, {pending: false, connected: status < 400});
            } catch (e) {
                // tslint:disable-next-line:max-line-length
                context.commit(PatientMutations.SET_PATIENT_HOME_PAGE_DEVICE_CONNECTION, {pending: false, connected: false});
            }
        },
    },
};

export default patients;
