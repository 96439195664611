import {Role} from '@/ts/permissions/Role';

export enum Attribute {
    REQUIRES_CUSTOMER,
}

const AttributeMap = {
    [Role.SUPER]: [

    ],
    [Role.MARKETING_SALES]: [

    ],
    [Role.CUSTOMER_SERVICE]: [

    ],
    [Role.SERVICE]: [

    ],
    [Role.ADMIN]: [
        Attribute.REQUIRES_CUSTOMER,
    ],
    [Role.CLINICAL_VIEWER]: [
        Attribute.REQUIRES_CUSTOMER,
    ],
    [Role.CLINICAL_SUPPORT_VIEWER]: [
        Attribute.REQUIRES_CUSTOMER,
    ],
    [Role.BIOMED_VIEWER]: [
        Attribute.REQUIRES_CUSTOMER,
    ],
}as {[index: string]: Attribute[]};

export function roleHasAttribute(role: string, attribute: Attribute) {
    return AttributeMap[role].includes(attribute);
}

export {AttributeMap};
