import { render, staticRenderFns } from "./TeamSelector.vue?vue&type=template&id=51df7842&scoped=true&"
import script from "./TeamSelector.vue?vue&type=script&lang=ts&"
export * from "./TeamSelector.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51df7842",
  null
  
)

export default component.exports