import Vue, {AsyncComponent, VueConstructor} from 'vue';
import Router, {Route} from 'vue-router';
import Home from './views/Home.vue';
import store from './store';
import {vuexOidcCreateRouterMiddleware} from 'vuex-oidc';
import OidcCallback from './views/OAuth/OidcCallback.vue';
import UserList from '@/views/Users/UserList.vue';
import UserCreate from '@/views/Users/UserCreate.vue';
import Profile from '@/views/Users/Profile.vue';
import UserEdit from '@/views/Users/UserEdit.vue';
import HubList from '@/views/devices/Hubs/HubList.vue';
import SilentRenew from '@/views/OAuth/SilentRenew.vue';
import {Permission, PermissionRoleMap} from '@/ts/permissions/Permission';
import HubView from '@/views/devices/Hubs/HubView.vue';
import HubClaim from '@/views/devices/Hubs/HubClaim.vue';
import CustomerList from '@/views/customers/CustomerList.vue';
import CustomerCreate from '@/views/customers/CustomerCreate.vue';
import CustomerEdit from '@/views/customers/CustomerEdit.vue';
import PumpFeedingHistoryReport from '@/views/devices/marrs/PumpFeedingHistoryReport.vue';
import TeamList from '@/views/teams/TeamList.vue';
import PumpErrorReportPage from '@/views/devices/marrs/PumpErrorReportPage.vue';
import PumpListPage from '@/views/devices/marrs/PumpListPage.vue';
import MaintenanceMode from '@/views/MaintenanceMode.vue';
import PumpSettingsReport from '@/views/devices/marrs/PumpSettingsReport.vue';
import Dashboard from '@/views/patients/Dashboard.vue';
import PumpHomePage from '@/views/devices/marrs/home/PumpHomePage.vue';
import PumpHomeTab from '@/views/devices/marrs/home/PumpHomeTab.vue';
import MonitorPumpPage from '@/views/devices/marrs/MonitorPumpPage.vue';
const DiagnosticsTable = () =>
    import(/* webpackChunkName: "service" */'@/components/devices/marrs/DiagnosticsTable.vue');
import PatientListPage from '@/views/patients/PatientListPage.vue';
import PatientCreate from '@/views/patients/PatientCreate.vue';
import PatientHomePage from '@/views/patients/PatientHomePage.vue';
import PatientHomeTab from './views/patients/PatientHomeTab.vue';
import PatientMonitorMode from '@/views/patients/PatientMonitorMode.vue';
import ClaimDevicesPage from '@/views/devices/marrs/ClaimDevicesPage.vue';
import PatientWeightHistoryReport from '@/views/patients/reports/PatientWeightHistoryReport.vue';
import PatientPumpSettingsReport from '@/views/patients/reports/PatientPumpSettingsReport.vue';
import PatientPumpErrorReport from '@/views/patients/reports/PatientPumpErrorReport.vue';
const ServicePanel = () =>  import(/* webpackChunkName: "service" */ '@/views/service/ServicePanel.vue');
import TermsAndConditionsAgreement from '@/views/Users/TermsAndConditionsAgreement.vue';
import {AuthMutations} from '@/vuex/auth';
import {ModalMutations} from '@/vuex/modal';
import CustomerAccountDisabled from '@/views/CustomerAccountDisabled.vue';
import {InactivityActions} from '@/vuex/inactivity';

Vue.use(Router);

const router =  new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    {
      path: '/oauth/callback',
      name: 'oidcCallback',
      component: OidcCallback,
      meta: {
        isOidcCallback: true,
        isPublic: true,
      },
    },
    {
      path: '/oauth/silent',
      name: 'oidcSilent',
      component: SilentRenew,
      meta: {
        isPublic: true
      },
    },
    {
      path: '/users',
      name: 'user-list',
      component: UserList,
      meta: {
        activeSideNav: 'users',
        permissions: [
          Permission.USER_LIST,
        ],
      },
    },
    {
      path: '/users/create',
      name: 'user-create',
      component: UserCreate,
      meta: {
        activeSideNav: 'users',
        permissions: [
          Permission.USER_CREATE,
        ],
      },
    },
    {
      path: '/users/profile',
      name: 'user-profile',
      component: Profile,
      meta: {
        activeSideNav: 'profile',
      },
    },
    {
      path: '/users/:id',
      name: 'user-edit',
      component: UserEdit,
      meta: {
        activeSideNav: 'users',
        permissions: [
            Permission.USER_VIEW,
        ],
      },
    },
    {
      path: '/devices/hubs',
      name: 'hub-list',
      component: HubList,
      meta: {
        activeSideNav: 'hubs',
        permissions: [
            Permission.WIRELESS_HUB_LIST,
        ],
      },
    },
    {
      path: '/devices/hubs/claim',
      name: 'hub-claim',
      component: HubClaim,
      meta: {
        activeSideNav: 'hubs',
        permissions: [
            Permission.WIRELESS_HUB_CLAIM,
        ],
      },
    },
    {
        path: '/devices/hubs/:id',
        name: 'hub-view',
        component: HubView,
        meta: {
            activeSideNav: 'hubs',
            permissions: [
                Permission.WIRELESS_HUB_VIEW,
            ],
        },
    },
    {
      path: '/customers',
      name: 'customer-list',
      component: CustomerList,
      meta: {
        activeSideNav: 'customers',
        permissions: [
            Permission.CUSTOMER_VIEW,
        ],
      },
    },
    {
      path: '/customers/create',
      name: 'customer-create',
      component: CustomerCreate,
      meta: {
        activeSideNav: 'customers',
        permissions: [
            Permission.CUSTOMER_CREATE,
        ],
      },
    },
    {
      path: '/customers/:id',
      name: 'customer-view',
      component: CustomerEdit,
      meta: {
        activeSideNav: 'customers',
        permissions: [
            Permission.CUSTOMER_VIEW,
        ],
      },
    },
    {
      path: '/devices/connect/',
      name: 'connect-pump-list',
      component: PumpListPage,
      meta: {
        activeSideNav: 'enteralPumps',
        permissions: [
            Permission.DEVICE_VIEW,
        ],
      },
    },
    {
      path: '/devices/connect/claim',
      name: 'claim-pumps',
      component: ClaimDevicesPage,
      meta: {
        activeSideNav: 'enteralPumps',
        permissions: [
          Permission.PUMP_REGISTRATION_QUEUE_VIEW,
        ],
      },
    },
    {
      path: '/devices/connect/:id',
      component: PumpHomePage,
      children: [
        {
          path: '',
          component: PumpHomeTab,
          name: 'enteral-pump-home',
          meta: {
            activeSideNav: 'enteralPumps',
            permissions: [
              Permission.DEVICE_VIEW,
            ],
          },
        },
        {
          path: 'feeding-history-report',
          name: 'marrs-feeding-history-report',
          component: PumpFeedingHistoryReport,
          meta: {
            activeSideNav: 'enteralPumps',
            permissions: [
              Permission.FEED_HISTORY_REPORT,
            ],
          },
        },
        {
          path: 'pump-error-report',
          name: 'marrs-pump-error-report',
          component: PumpErrorReportPage,
          meta: {
            activeSideNav: 'enteralPumps',
            permissions: [
              Permission.PUMP_ERROR_REPORT,
            ],
          },
        },
        {
          path: 'monitor-pump',
          name: 'marrs-monitor-pump',
          component: MonitorPumpPage,
          meta: {
            activeSideNav: 'enteralPumps',
            permissions: [
              Permission.MONITOR_MODE,
            ],
          },
        },
        {
          path: 'pump-settings-report',
          name: 'marrs-pump-settings-report',
          component: PumpSettingsReport,
          meta: {
            activeSideNav: 'enteralPumps',
            permissions: [
              Permission.PUMP_SETTINGS_REPORT,
            ],
          },
        },
        {
          path: 'diagnostics',
          name: 'marrs-diagnostics-report',
          component: DiagnosticsTable,
          meta: {
            activeSideNav: 'enteralPumps',
            permissions: [
              Permission.PUMP_DIAGNOSTICS,
            ],
          },
        },
      ],
      meta: {
        activeSideNav: 'enteralPumps',
        permissions: [
        ],
      },
    },
    // {
    //   path: '/devices/marrs/:id/test-certification-list',
    //   name: 'test-certification-list',
    //   component: TestCertificationList,
    //   meta: {
    //     activeSideNav: 'enteralPumps',
    //     permissions: [
    //     ],
    //   },
    // },
    {
      path: '/teams',
      name: 'team-list',
      component: TeamList,
      meta: {
        activeSideNav: 'teams',
        permissions: [
            Permission.TEAM_LIST,
        ],
      },
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: Dashboard,
      meta: {
        activeSideNav: 'dashboard',
        permissions: [
            Permission.DASHBOARD,
        ],
      },
    },
    {
      path: '/patients',
      name: 'patient-list',
      component: PatientListPage,
      meta: {
        activeSideNav: 'patients',
        permissions: [
            Permission.PATIENT_LIST,
        ],
      },
    },
    {
      path: '/patients/new',
      name: 'patient-create',
      component: PatientCreate,
      meta: {
        activeSideNav: 'patients',
        permissions: [
          Permission.PATIENT_CREATE,
        ],
      },
    },
    {
      path: '/patients/:id',
      component: PatientHomePage,
      children: [
        {
          path: '',
          component: PatientHomeTab,
          name: 'patient-home',
          meta: {
            activeSideNav: 'patients',
            permissions: [
              Permission.PATIENT_VIEW,
            ],
          },
        },
        {
          path: 'monitor',
          component: PatientMonitorMode,
          name: 'patient-monitor-pump',
          meta: {
            activeSideNav: 'patients',
            permissions: [
              Permission.PATIENT_VIEW,
              Permission.MONITOR_MODE,
            ],
          },
        },
        {
          path: 'weight-history-report',
          component: PatientWeightHistoryReport,
          name: 'patient-weight-history-report',
          meta: {
            activeSideNav: 'patients',
            permissions: [
              Permission.PATIENT_VIEW,
              Permission.WEIGHT_HISTORY_REPORT,
            ],
          },
        },
        {
          path: 'pump-settings-report',
          name: 'patient-pump-settings-report',
          component: PatientPumpSettingsReport,
          meta: {
            activeSideNav: 'patients',
            permissions: [
              Permission.PUMP_SETTINGS_REPORT,
              Permission.PATIENT_VIEW,
            ],
          },
        },
        {
          path: 'pump-error-report',
          name: 'patient-pump-error-report',
          component: PatientPumpErrorReport,
          meta: {
            activeSideNav: 'patients',
            permissions: [
              Permission.PUMP_ERROR_REPORT,
              Permission.PATIENT_VIEW,
            ],
          },
        },
      ],
      meta: {
        activeSideNav: 'patients',
        permissions: [
            Permission.PATIENT_VIEW,
        ],
      },
    },
    {
      path: '/service-panel',
      name: 'service-panel',
      component: ServicePanel,
      meta: {
        activeSideNav: 'service',
        permissions: [
          Permission.SERVICE_TOOLS,
        ],
      },
    },
    {
      path: '/down-for-maintenance',
      name: 'maintenance-mode',
      component: MaintenanceMode,
      meta: {
        isPublic: true,
        isErrorHandler: true,
      },
    },
    {
      path: '/terms-conditions-agreement',
      name: 'terms-conditions-agreement',
      component: TermsAndConditionsAgreement,
      meta: {
      },
    },
    {
      path: '/account-disabled',
      name: 'account-disabled',
      component: CustomerAccountDisabled,
      meta: {
        isPublic: true,
        isErrorHandler: true,
      },
    },
  ],
});

router.beforeEach((to: Route, from: Route, next: any) => {
  const loggedInUser = store.state.auth.loggedInUser;
  if (loggedInUser === null && to.meta['isPublic'] !== undefined && !to.meta['isPublic']) {
    store.commit('auth/' + AuthMutations.UPDATE_AUTH_LOADING, true);
  }

  store.commit('modal/' + ModalMutations.CHANGE_MODAL_STATE, false);

  next();
});

router.beforeEach(vuexOidcCreateRouterMiddleware(store, 'oidcStore'));

router.beforeEach(async (to: Route, from: Route, next: any) => {
  let loggedInUser = store.state.auth.loggedInUser;

  if (to.name === 'maintenance-mode' || to.name === 'account-disabled') {
    store.commit('auth/' + AuthMutations.UPDATE_AUTH_LOADING, false);
    next();
    return;
  }

  if (to.meta['isPublic'] !== undefined && to.meta['isPublic']
      && to.name !== 'oidcSilent' && to.name !== 'oidcCallback'
      && !store.getters['oidcStore/oidcIsAuthenticated']) {
    store.commit('auth/' + AuthMutations.UPDATE_AUTH_LOADING, true);
    await store.dispatch('oidcStore/authenticateOidcSilent', {ignoreErrors: true});
    await store.dispatch('auth/getLoggedInUser');
    store.commit('auth/' + AuthMutations.UPDATE_AUTH_LOADING, false);
    next();
    return;
  } else if(to.meta['isPublic'] !== undefined && to.meta['isPublic']
      && to.name !== 'oidcSilent' && to.name !== 'oidcCallback'
      && loggedInUser === null && store.getters['oidcStore/oidcIsAuthenticated']){
    await store.dispatch('auth/getLoggedInUser');
  } else if (to.meta['isPublic'] !== undefined && to.meta['isPublic']) {
    next();
    return;
  }


  if (((to.meta['isErrorHandler'] !== undefined && !to.meta['isErrorHandler'])
      || to.meta['isErrorHandler'] === undefined) && loggedInUser === null
      && store.getters['oidcStore/oidcIsAuthenticated'] ) {
    await store.dispatch('auth/getLoggedInUser');
    loggedInUser = store.state.auth.loggedInUser;
  }
  if (!store.getters['oidcStore/oidcIsAuthenticated']) {
    await store.dispatch('oidcStore/authenticateOidcSilent', {ignoreErrors: true});
    if (store.getters['oidcStore/oidcIsAuthenticated']) {
      await store.dispatch('auth/getLoggedInUser');
      loggedInUser = store.state.auth.loggedInUser;
    } else {
      await store.dispatch('oidcStore/authenticateOidc')
      next(false);
      return;
    }
  }

  if (to.name !== 'terms-conditions-agreement' && loggedInUser != null && loggedInUser.termsAndConditionsUpdateNeeded) {
    next({name: 'terms-conditions-agreement'});
    return;
  }

  if ( (to.meta['isPublic'] !== undefined && !to.meta['isPublic']
      || to.meta['isPublic'] === undefined && loggedInUser != null) ) {
    store.dispatch('inactivity/' + InactivityActions.EXTEND_TIMER);
    if (to.meta['permissions'] !== undefined) {
      const permissions: Permission[] =  to.meta['permissions'];
      for (const permission of permissions) {
        if (loggedInUser !== null && ! PermissionRoleMap[loggedInUser.role].includes(permission)) {
          // if (store.state.auth.authLoading) {
          //   store.commit('auth/' + AuthMutations.UPDATE_AUTH_LOADING, false);
          // }
          next(false);
          return;
        }
      }
    }
  }
  if (store.state.auth.authLoading && store.state.auth.loggedInUser != null) {
    store.commit('auth/' + AuthMutations.UPDATE_AUTH_LOADING, false);
  }
  next();
});

export default router;
