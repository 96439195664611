import {SnackbarData, SnackbarRequest} from '@/models/Snackbar';
import {ActionContext, Module} from 'vuex';
import {RootState} from '@/vuex/rootState';
import moment from 'moment';

export interface SnackbarState {
    items: SnackbarData[];
}

export enum SnackbarMutations {
    ADD_ITEM = 'addItem',
    REMOVE_ITEM = 'removeItem',
}

export enum SnackbarActions {
    TRIGGER_SNACKBAR = 'triggerSnackbar',
}

type SnackbarActionContext = ActionContext<SnackbarState, RootState>;

const snackbar: Module<SnackbarState, RootState> = {
    namespaced: true,
    state: {
        items: [],
    },
    mutations: {
        [SnackbarMutations.ADD_ITEM](state, item: SnackbarData) {
            if (state.items.length < 5) {
                state.items.push(item);
            }
        },
        [SnackbarMutations.REMOVE_ITEM](state, item: SnackbarData) {
            // tslint:disable-next-line:max-line-length
            const originalItem = state.items.findIndex((snackbarItem) => snackbarItem.timestamp === item.timestamp);
            if (originalItem >= 0) {
                state.items.splice(originalItem, 1);
            }
        },
    },
    actions: {
        // tslint:disable-next-line:max-line-length
        [SnackbarActions.TRIGGER_SNACKBAR](context: SnackbarActionContext, item: SnackbarRequest) {
            if (item.timeout === undefined) {
                item.timeout = 10000;
            }
            const snackbarItem: SnackbarData = {
                message: item.message,
                actionText: item.actionText,
                timestamp: moment().valueOf(),
                showDismiss: true,
            };

            snackbarItem.timerId = setTimeout(() => {
                context.commit(SnackbarMutations.REMOVE_ITEM, snackbarItem);
            }, item.timeout);
            context.commit(SnackbarMutations.ADD_ITEM, snackbarItem);
        },
    },
};

export default snackbar;
