import {ActionContext, Module} from 'vuex';
import axios from 'axios';
import {Hub} from '@/models/Devices/Hubs/Hub';
import {convertToMomentTimezone} from '@/ts/TimezoneUtils';
import {FirmwareInfo, MARRSPump} from '@/models/Devices/MARRS/MARRSPump';
import {Certification, CertificationReply} from '@/models/Devices/MARRS/Certification';
import {CustomerDeviceAssociation} from '@/models/customers/CustomerDeviceAssociation';
import {RootState} from '@/vuex/rootState';
import {PatientDeviceAssignment} from '@/models/patients/Patient';
import moment, {Moment} from 'moment';

export interface MarrsPumpModel {
    homePage: PumpHomePageModel;
}

export interface PumpHomePageModel {
    pump: MARRSPump | null;
    hub: Hub | null;
    latestCertification: Certification | null;
    customerClaims: CustomerDeviceAssociation[];
    deviceAssignments: PatientDeviceAssignment[];
    firmwareInfo: FirmwareInfo | null;
    connectionPending: boolean;
    connected: boolean;
}

interface InitialPumpHomeModel {
    pump: MARRSPump | null;
    associatedHub: Hub | null;
    latestCertification: Certification | null;
    customerClaims: CustomerDeviceAssociation[];
    deviceAssignments: PatientDeviceAssignment[];
    firmwareInfo: FirmwareInfo | null;
    timezone?: string;
}

export enum MarrsPumpGetters {

}

export enum MarrsPumpMutations {
    SET_INITIAL_HOME_PAGE_DATA = 'setInitialHomePageData',
    SET_PUMP_CONNECTION = 'setPumpConnection',
    SET_PUMP_NOTES = 'setPumpNotes',
    END_ASSIGNMENT = 'endAssignment',
    RETURN_TO_STOCK = 'returnToStock',
    ADD_NEW_ASSIGNMENT = 'addNewAssignment',
    EDIT_ASSIGNMENT = 'editAssignment',
    REMOVE_ASSIGNMENT = 'removeAssignment',
    UPDATE_CERTIFICATION = 'updateCertification',
}

export enum MarrsPumpActions {
    FETCH_INITIAL_HOME_PAGE_DATA = 'fetchInitialHomePageData',
    CHECK_PUMP_CONNECTION = 'checkPumpConnection',
}

type MarrsActionContext = ActionContext<MarrsPumpModel, RootState>;

const marrs: Module<MarrsPumpModel, RootState> = {
    namespaced: true,
    state: {
       homePage: {
           pump: null,
           hub: null,
           latestCertification: null,
           customerClaims: [],
           deviceAssignments: [],
           connectionPending: true,
           connected: false,
           firmwareInfo: null,
       },
    },
    mutations: {
        [MarrsPumpMutations.SET_INITIAL_HOME_PAGE_DATA](state, data: InitialPumpHomeModel) {
            data.deviceAssignments.forEach((assignment) => {
                if (assignment.endDate != null) {
                    assignment.endDate = convertToMomentTimezone(data.timezone as string, assignment.endDate as string);
                }
                assignment.startDate = convertToMomentTimezone(data.timezone as string, assignment.startDate as string);
            });
            state.homePage.deviceAssignments = data.deviceAssignments;
            (data.pump as MARRSPump).assignments = state.homePage.deviceAssignments;
            state.homePage.pump = data.pump;
            if (data.associatedHub?.lastMessageTimestamp !== undefined) {
                data.associatedHub.lastMessageTimestamp =
                    convertToMomentTimezone(data.timezone as string, data.associatedHub.lastMessageTimestamp as string);
            }
            state.homePage.hub = data.associatedHub;
            if (data.latestCertification != null) {
                data.latestCertification.timestamp =
                    convertToMomentTimezone(data.timezone as string, data.latestCertification.timestamp as string);
            }
            state.homePage.latestCertification = data.latestCertification;
            data.customerClaims.forEach((claim) => {
                if (claim.endDate != null) {
                    claim.endDate = convertToMomentTimezone(data.timezone as string, claim.endDate as string);
                }
                claim.id.startDate = convertToMomentTimezone(data.timezone as string, claim.id.startDate as string);
            });
            state.homePage.customerClaims = data.customerClaims;

            state.homePage.firmwareInfo = data.firmwareInfo;
        },
        [MarrsPumpMutations.SET_PUMP_CONNECTION](state, data: {pending: boolean, connected: boolean}) {
            state.homePage.connectionPending = data.pending;
            state.homePage.connected = data.connected;
        },
        [MarrsPumpMutations.SET_PUMP_NOTES](state, data: string){
            if (state.homePage?.pump?.notes?.id !== undefined) {
                state.homePage.pump.notes.notes = data;
            }
        },
        [MarrsPumpMutations.END_ASSIGNMENT](state, data: {assignmentId: number, timezone: string}) {
            if (state.homePage?.deviceAssignments?.length !== undefined) {
                const originalAssignment = state.homePage.deviceAssignments
                    .find((assignment) => assignment.id === data.assignmentId);
                if (originalAssignment !== undefined) {
                    originalAssignment.endDate = moment.tz(data.timezone);
                }
            }
        },
        [MarrsPumpMutations.RETURN_TO_STOCK](state, timezone: string) {
            if (state.homePage?.customerClaims?.length !== undefined) {
                const originalClaim = state.homePage.customerClaims.find((claim) => claim.endDate === null);
                if (originalClaim !== undefined) {
                    originalClaim.endDate = moment.tz(timezone);
                }
            }

            if (state.homePage?.deviceAssignments?.length !== undefined) {
                const originalAssignment = state.homePage.deviceAssignments
                    .find((assignment) => assignment.endDate === null);
                if (originalAssignment !== undefined) {
                    originalAssignment.endDate = moment.tz(timezone);
                }
            }
        },
        [MarrsPumpMutations.ADD_NEW_ASSIGNMENT](state, assignment: PatientDeviceAssignment) {
            // tslint:disable-next-line:max-line-length
            if (state.homePage?.deviceAssignments?.length !== undefined && state.homePage?.pump?.assignments !== undefined) {
                const existingAssignment = state.homePage.deviceAssignments
                    .find((findAssignment) => findAssignment.endDate === null);
                if (existingAssignment !== undefined && assignment.endDate === null) {
                    existingAssignment.endDate = (assignment.startDate as Moment).clone()
                        .subtract(1, 'day').endOf('day');
                }
                state.homePage.deviceAssignments.push(assignment);
                state.homePage.deviceAssignments.sort((a, b) => {
                    return (b.startDate as Moment).diff(a.startDate as Moment);
                });
                state.homePage.pump.assignments = state.homePage.deviceAssignments;
            }
        },
        [MarrsPumpMutations.EDIT_ASSIGNMENT](state, assignment: PatientDeviceAssignment) {
            // tslint:disable-next-line:max-line-length
            if (state.homePage?.deviceAssignments?.length !== undefined && state.homePage?.pump?.assignments !== undefined) {
                const originalAssignment = state.homePage.deviceAssignments
                    .find((deviceAssignment) => deviceAssignment.id === assignment.id);
                if (originalAssignment !== undefined) {
                    originalAssignment.startDate = assignment.startDate;
                    originalAssignment.endDate = assignment.endDate;
                    state.homePage.deviceAssignments.sort((a, b) => {
                        return (b.startDate as Moment).diff(a.startDate as Moment);
                    });
                    state.homePage.pump.assignments = state.homePage.deviceAssignments;
                }
            }
        },
        [MarrsPumpMutations.REMOVE_ASSIGNMENT](state, assignmentId: number) {
            // tslint:disable-next-line:max-line-length
            if (state.homePage?.deviceAssignments?.length !== undefined && state.homePage?.pump?.assignments !== undefined) {
                const assignmentIndex = state.homePage.deviceAssignments
                    .findIndex((assignment) => assignment.id === assignmentId);
                if (assignmentIndex >= 0) {
                    state.homePage.deviceAssignments.splice(assignmentIndex, 1);
                    state.homePage.pump.assignments = state.homePage.deviceAssignments;
                }
            }
        },
        [MarrsPumpMutations.UPDATE_CERTIFICATION](state, certificationInfo: CertificationReply) {
            if (state.homePage.pump?.serialNumber !== undefined) {
                if (state.homePage.latestCertification?.id !== undefined) {
                    state.homePage.latestCertification.id = certificationInfo.id;
                    state.homePage.latestCertification.certificationResult = certificationInfo.passed;
                    state.homePage.latestCertification.testType = certificationInfo.type;
                    state.homePage.latestCertification.timestamp = certificationInfo.timestamp;
                } else {
                    state.homePage.latestCertification = {
                        serialNumber: state.homePage.pump.serialNumber,
                        id: certificationInfo.id,
                        certificationResult: certificationInfo.passed,
                        testType: certificationInfo.type,
                        timestamp: certificationInfo.timestamp
                    };
                }
            }
        },
    },
    actions: {
        async [MarrsPumpActions.FETCH_INITIAL_HOME_PAGE_DATA](context: MarrsActionContext, pumpId: number) {
            const {data}: {data: InitialPumpHomeModel} =
                await axios.get(process.env.VUE_APP_BACKEND_URI + '/devices/marrs/' + pumpId);
            data.timezone = context.rootState.auth.loggedInUser?.timezone;
            context.commit(MarrsPumpMutations.SET_INITIAL_HOME_PAGE_DATA, data);
        },
        async [MarrsPumpActions.CHECK_PUMP_CONNECTION](context: MarrsActionContext, pumpId: number) {
            context.commit(MarrsPumpMutations.SET_PUMP_CONNECTION, {pending: true, connected: false});
            try {
                const {status} = await axios.get(process.env.VUE_APP_BACKEND_URI +
                    '/devices/marrs/' + pumpId + '/wireless-diagnostics/step-two');
                context.commit(MarrsPumpMutations.SET_PUMP_CONNECTION, {pending: false, connected: status < 400});
            } catch (e) {
                context.commit(MarrsPumpMutations.SET_PUMP_CONNECTION, {pending: false, connected: false});
            }
        },
    },
};

export default marrs;
