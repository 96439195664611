import {Moment} from 'moment';
import {Either, Failure, Success} from '@/errors/Either';
import {DiagnosticRecord} from '@/models/Devices/MARRS/Records';
import {Error} from '@/errors/Error';
import axios from 'axios';
import {convertToMomentTimezone} from '@/ts/TimezoneUtils';
import {saveDownloadedFile} from '@/ts/utilities';


export default class ConnectDiagnosticsService {
    private readonly timezone: string;


    constructor(timezone: string) {
        this.timezone = timezone;
    }

    /**
     * Fetches a list of diagnostic data for a specific pump
     * @param startDate Start date of the report
     * @param endDate End Date of the report
     * @param pumpId ID of the pump to fetch diagnostics for
     */
    // tslint:disable-next-line:max-line-length
    public async fetchDiagnosticRecords(startDate: Moment | Date, endDate: Moment | Date, pumpId: number): Promise<Either<DiagnosticRecord[], Error>> {
        const request = {
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString(),
        };
        try {
            // tslint:disable-next-line:max-line-length
            const {data}: {data: DiagnosticRecord[]} = await axios.get(process.env.VUE_APP_BACKEND_URI + '/devices/marrs/' + pumpId + '/diagnostics', {
                params: request,
            });
            data.forEach((record) => {
                if (record.timestamp !== null) {
                    record.timestamp = convertToMomentTimezone(this.timezone, record.timestamp as string);
                }
            });
            return new Success(data);
        } catch (e) {
            return new Failure({message: 'Failure getting job counts'});
        }
    }

    /**
     * Exports the diagnostic data as an Excel file
     * @param startDate Start date of the report
     * @param endDate End date of the report
     * @param pumpId ID of the pump
     */
    // tslint:disable-next-line:max-line-length
    public async exportDiags(startDate: Moment | Date, endDate: Moment | Date, pumpId: number): Promise<Either<null, Error>> {
        const request = {
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString(),
        };
        try {
            // tslint:disable-next-line:max-line-length
            const response = await axios.get(process.env.VUE_APP_BACKEND_URI + '/devices/marrs/' + pumpId + '/diagnostics/export', {
                params: request,
                responseType: 'blob',
            });
            saveDownloadedFile(response, 'pump-diagnostic-export.xlsx');
            return new Success(null);
        } catch (e) {
            return new Failure({
                message: 'Unable to export diagnostics',
            });
        }
    }
}
