











import { Component, Vue } from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import {ButtonType} from '@/components/form/FormTypes';
import {AuthMutations} from '@/vuex/auth';

const oidcModule = namespace('oidcStore');
const auth = namespace('auth');

@Component
export default class CustomerAccountDisabled extends Vue {

    @oidcModule.Action('removeUser') removeOidcUser: any;
    @oidcModule.Action('signOutOidc') signOut!: any;
    @auth.Mutation(AuthMutations.LOG_USER_OUT) logUserOut!: () => void;

    buttonTypes = ButtonType;

    mounted(){
        this.removeOidcUser();
        this.logUserOut();
    }
}
