
import {Component, Prop, Vue} from 'vue-property-decorator';
import {Line, mixins as chartMixins} from 'vue-chartjs';
import {mixins} from 'vue-class-component';
import {WeightHistoryReport} from '@/models/reports/WeightHistory';
import {Moment} from 'moment';
import {DailyAdherenceRecord} from '@/models/reports/AdherenceReport';
import {namespace} from 'vuex-class';
import {AuthGetters} from '@/vuex/auth';
import {ChartTooltipItem} from 'chart.js';

const auth = namespace('auth');

/**
 * Renders a graph of the patient's weight history over time
 * Has the ability to overlay adherence data
 */
@Component({
    extends: Line
})
export default class WeightReportGraph extends mixins(Line, chartMixins.reactiveData) {

    /**
     * Weight history data
     */
    @Prop() initialData!: WeightHistoryReport;

    /**
     * Adherence data
     */
    @Prop({default: () => []}) adherenceData!: DailyAdherenceRecord[];

    /**
     * Whether the adherence data should be shown
     */
    @Prop({default: false}) showAdherenceData!: boolean;
    @Prop() gradient!: any;
    @auth.Getter(AuthGetters.MEASUREMENT_UNIT) measurementUnit!: string;

    created() {
        let modifiedRecords: any[] = [];

        for(const value of this.initialData.weights.reverse()){
            modifiedRecords.push({
                x: (value.date as Moment),
                y: value.weight
            });
        }

        const datasets: any[] = [
            {
                label: 'Weight History',
                data: modifiedRecords,
                borderColor: '#745cc6',
                pointBorderColor: '#745cc6',
                pointBackgroundColor: 'white',
                pointHoverBackgroundColor: '#745cc6',
                pointRadius: 4,
                fill: 'origin',
                backgroundColor: 'transparent',
                lineTension: 0,
                yAxisID: 'weight-history-y-axis',
            }
        ];

        if(this.showAdherenceData && this.adherenceData.length > 0) {
            let adherenceRecords: any[] = [];
            for(const value of this.adherenceData) {
                adherenceRecords.push({
                    x: (value.timestamp as Moment),
                    y: value.adherence
                })
            }

            datasets.push({
                label: 'Adherence',
                data: adherenceRecords,
                type: 'bar',
                backgroundColor: '#EFEFEF',
                yAxisID: 'adherence-y-axis',
            })
        }

        //@ts-ignore
        this.chartData = {
            datasets
        }
    }

    get options() {
        const vm = this;
        const yAxes: any[] = [
            {
                id: 'weight-history-y-axis',
                position: 'left',
                ticks: {
                    callback: function(value: any, index: number, values: any){
                        return value + ` ${vm.measurementUnit}`;
                    },
                    min: 0
                },

            }
        ];

        if(this.showAdherenceData && this.adherenceData.length > 0) {
            yAxes.push({
                id: 'adherence-y-axis',
                position: 'right',
                ticks: {
                    callback: function(value: any, index: number, values: any){
                        return value + ' %';
                    },
                    min: 0
                },
                gridLines: {
                    display: false,
                }
            })
        }


        return {
            hover: {
                mode: 'point',
            },
            scales: {
                xAxes: [
                    {
                        type: 'time',
                        distribution: 'linear',
                        time: {
                            unit: (this.initialData.endDate as Moment).diff(this.initialData.startDate as Moment, 'day') <= 8 ? 'day': 'week',
                            displayFormats: {
                                day: 'DD MMM YYYY',
                                week: 'DD MMM YYYY',
                                month: 'MMM YYYY',
                                quarter: 'MMM YYYY'
                            },
                            tooltipFormat: 'DD MMM YYYY',
                        },
                        ticks: {
                            min: this.initialData.startDate,
                            max: this.initialData.endDate
                        }
                    }
                ],
                yAxes
            },
            tooltips: {
                callbacks: {
                  label: function(tooltipItem: ChartTooltipItem, data: any) {
                      var label = data.datasets[tooltipItem.datasetIndex as number].label || '';

                      if (label) {
                          label += ': ';
                      }
                      if(tooltipItem.datasetIndex === 0) {
                          return label + `${tooltipItem.yLabel} ${vm.measurementUnit}`
                      } else {
                          return label + `${tooltipItem.yLabel}%`
                      }
                  }
                }
            },
            legend: {
                onClick: false,
                display: true
            },
            maintainAspectRatio: false,
            responsive: true
        };
    }

    mounted () {
        // Overwriting base render method with actual data.
        //@ts-ignore
        this.renderChart(this.chartData, this.options);
    }
}
