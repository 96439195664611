


























import {Component, Vue} from "vue-property-decorator";
    import {namespace} from "vuex-class";
    import {FirmwareInfo, MARRSPump} from "@/models/Devices/MARRS/MARRSPump";
    import {ButtonType} from "@/components/form/FormTypes";
    import {Attribute, AttributeMap} from "@/ts/permissions/Attribute";
    import User from "@/models/User";
    import CustomerDeviceClaimHistoryModal from '@/components/customers/CustomerDeviceClaimHistoryModal.vue';
    import {ModalMutations} from "@/vuex/modal";
    import {CustomerDeviceAssociation} from "@/models/customers/CustomerDeviceAssociation";

const marrs = namespace('marrsPump');
const auth = namespace('auth');
const modal = namespace('modal');

/**
 * Renders the advanced details for a specific pump
 */
@Component({
    components: {
        CustomerDeviceClaimHistoryModal
    }
})
export default class AdvancedDetailsCard extends Vue {
    @marrs.State(state => state.homePage.pump) pump!: MARRSPump;
    @marrs.State(state => state.homePage.firmwareInfo) firmwareInfo!: FirmwareInfo;
    @marrs.State(state => state.homePage.customerClaims) customerClaims!: CustomerDeviceAssociation[];
    @auth.State('loggedInUser') loggedInUser!: User;
    @modal.Mutation(ModalMutations.CHANGE_MODAL_STATE) changeModalState!: (state: boolean) => void;

    /**
     * Whether the customer claim history modal is currently showing
     */
    modalShowing = false;

    buttonType = ButtonType;

    /**
     * Returns the pump's wireless version or N/A if it is not known
     */
    get wirelessVersion(): string{
        return this.firmwareInfo?.wirelessVersion ?? "N/A";
    }

    /**
     * Checks to see if the user is capable of seeing the claim history of devices
     */
    get shouldShowClaimHistory(){
        return !AttributeMap[this.loggedInUser.role].includes(Attribute.REQUIRES_CUSTOMER)
    }

    /**
     * Shows the customer claim history modal
     */
    showModal(){
        this.changeModalState(true);
        this.modalShowing = true;
    }

    /**
     * Hides the customer claim history modal
     */
    hideModal(){
        this.modalShowing = false;
        this.changeModalState(false);
    }
}
