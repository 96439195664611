import axios from 'axios';
import {AsyncNullSuccess, Failure, nullSuccess} from '@/errors/Either';
import {Error} from '@/errors/Error';


export default class ConnectNotesService {
    /**
     * Updates the notes for a given pump
     * @param pumpId ID of the pump to update notes for
     * @param notes New notes value
     */
    public async updatePumpNotes(pumpId: number, notes: string): AsyncNullSuccess<Error> {
        try {
            await axios.post(process.env.VUE_APP_BACKEND_URI + '/devices/marrs/' + pumpId + '/notes', {
                notes,
            });
            return nullSuccess();
        } catch (e) {
           return new Failure({
               message: 'Unable to update pump notes',
           });
        }
    }
}
