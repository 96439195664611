









import {Component, Prop, Vue} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import {ModalMutations} from '@/vuex/modal';
import Modal from '../modal/Modal.vue';

const modal = namespace('modal');

/**
 * Renders a modal for displaying help messages
 */
@Component({
    components: {
        Modal,
    }
})
export default class HelpModal extends Vue {
    /**
     * Whether the modal is showing
     */
    modalShowing = false;
    @modal.Mutation(ModalMutations.CHANGE_MODAL_STATE) changeModalState!: (state: boolean) => void;

    /**
     * Title of the modal
     */
    @Prop() title!: string;

    /**
     * Shows the modal
     */
    showModal() {
        this.modalShowing = true;
        this.changeModalState(true);
    }

    /**
     * Hides the modal
     */
    hideModal() {
        this.modalShowing = false;
        this.changeModalState(false);
    }
}
