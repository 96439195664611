




































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {email, maxLength, minLength, required, alphaNum} from 'vuelidate/lib/validators';
import {alphaWithSeparators, isUnique} from '@/validations/Validator';
import {Validations} from "@/ts/validation-decorator";

/**
 * Renders a form for capturing general user info
 */
@Component
export default class GeneralUserInfo extends Vue {

    /**
     * Current value for the first name field
     */
    @Prop({type: String, default: ''}) firstName!: string;

    /**
     * Current value of the last name field
     */
    @Prop({type: String, default: ''}) lastName!: string;

    /**
     * Current value of the username field
     */
    @Prop({type: String, default: ''}) username!: string;

    /**
     * Current value of the email field
     */
    @Prop({type: String, default: ''}) email!: string;

    /**
     * Title of the card
     */
    @Prop({type: String, default: 'General'}) title!: string;

    /**
     * ID of the user if used on user edit
     */
    @Prop({required: false}) userId!: number | undefined;

    @Prop({default: false}) disabled!: boolean;

    /**
     * Maps fields to debounce timers for form field validation updates
     */
    touchMap = new Map<string, any>();

    emitEvent(field: string, data: string){
        this.$emit(field, data);
        if(this.touchMap.has(field)) {
            clearTimeout(this.touchMap.get(field))
        }
        this.touchMap.set(field, setTimeout(() => this.touchField(field), 1000))
    }

    /**
     * Form field validations
     */
    @Validations()
    validations(){
        return {
            firstName: {
                required,
                maxLength: maxLength(30)
            },
            lastName: {
                required,
                maxLength: maxLength(30)
            },
            username: {
                required,
                minLength: minLength(4),
                maxLength: maxLength(64),
                alphaWithSeparators,
                isUnique: isUnique(process.env.VUE_APP_BACKEND_URI + '/users/username-check', this.userId),
            },
            email: {
                required,
                email,
                maxLength: maxLength(100),
                isUnique: isUnique(process.env.VUE_APP_BACKEND_URI + '/users/email-check', this.userId),
            },
        }
    }

    /**
     * Waits for async validations before emitting whehter the form is invalid
     */
    waitForValidations(){
        return new Promise(resolve => {

            if(!this.$v.$pending){
                resolve(this.$v.$invalid);
                return;
            }

            const unwatch = this.$watch(() => !this.$v.$pending, (isNotPending) => {
                if(isNotPending && unwatch){
                    unwatch();
                    resolve(this.$v.$invalid);
                }
            })
        })
    }

    /**
     * Updates the form field validations for a field and emits whether the form is invalid
     * @param field
     */
    async touchField(field: string){
        this.$nextTick(async () => {
            if((this.$v as Object).hasOwnProperty(field)) {
                this.$v[field].$touch();
                const invalid = await this.waitForValidations();
                this.$emit('invalid', invalid);
            }
        })

    }

}
