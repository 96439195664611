import {AsyncEither, Either, Failure, Success} from '@/errors/Either';
import {CertificationReply} from '@/models/Devices/MARRS/Certification';
import {Error} from '@/errors/Error';
import axios from 'axios';
import {convertToMomentTimezone} from '@/ts/TimezoneUtils';
import {saveDownloadedFile} from '@/ts/utilities';


export default class ConnectCertificationService {
    private readonly timezone: string;


    constructor(timezone: string) {
        this.timezone = timezone;
    }

    /**
     * Sends the request to perform a certification on a specific pump
     * @param deviceId ID of the pump to certify
     */
    public async autoCertifyPump(deviceId: number): Promise<Either<CertificationReply, Error>> {
        try {
            // tslint:disable-next-line:max-line-length
            const {data}: {data: CertificationReply} = await axios.post(process.env.VUE_APP_BACKEND_URI + '/devices/marrs/' + deviceId + '/certifications/');
            data.timestamp = convertToMomentTimezone(this.timezone, data.timestamp as string);
            return new Success(data);
        } catch (e) {
            return new Failure({
                message: 'Unable to certify pump',
            });
        }
    }

    /**
     * Downloads a certification PDF
     * @param deviceId ID of the device to download the certification for
     * @param certificationId ID of the certification to download
     */
    public async fetchCertificationPdf(deviceId: number, certificationId: number): Promise<Either<null, Error>> {
        // tslint:disable-next-line:max-line-length
        try {
            const response = await axios.get(process.env.VUE_APP_BACKEND_URI + '/devices/marrs/' + deviceId + '/certifications/' + certificationId, {
                responseType: 'blob',
            });
            saveDownloadedFile(response, 'pump-certification.pdf');
            return new Success(null);
        } catch (e) {
            return new Failure({
                message: 'Unable to download certification PDF',
            });
        }
    }

    /**
     * Certifies multiple pumps
     * @param devices
     */
    public async bulkCertifyPumps(devices: Set<number>): AsyncEither<any, Error> {
        try {
            const {data} = await axios.post(process.env.VUE_APP_BACKEND_URI + '/devices/marrs/certify', [...devices]);
            return new Success(data);
        } catch(e) {
            return new Failure({
                message: 'Unable to bulk certify pumps',
            });
        }
    }
}
