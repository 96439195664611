import {Moment} from 'moment';

/**
 * Represents a single certification for a connect pump
 */
export interface Certification {
    id: number;
    serialNumber: string;
    timestamp: Moment | string;
    testType: string;
    certificationResult: boolean;
}

/**
 * Filter used to filter the list of certifications based on pass/fail
 */
export enum CertificationFilter {
    PASS = 0,
    FAIL = 1,
    PASS_AND_FAIL = 2,
}

/**
 * Represents the result of attempting to perform a certification on a connect pump
 */
export interface CertificationReply {
    id: number;
    timestamp: Moment | string;
    passed: boolean;
    type: string;
}
