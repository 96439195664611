import { render, staticRenderFns } from "./ConnectionHistory.vue?vue&type=template&id=0225b42f&scoped=true&"
import script from "./ConnectionHistory.vue?vue&type=script&lang=ts&"
export * from "./ConnectionHistory.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0225b42f",
  null
  
)

export default component.exports