























import {Component, Prop, Vue} from "vue-property-decorator";
import {Team} from '@/models/Teams';
import {AssignableToTeams} from "@/ts/permissions/Role";
import {ButtonType} from '@/components/form/FormTypes';

/**
 * Renders the list of teams a patient is assigned to
 */
@Component
export default class AssignedTeamsChipDisplayPatient extends Vue {

    /**
     * List of teams
     */
    @Prop({default: null}) teams!: Team[] | null;

    /**
     * Max size of the container
     */
    maxSize = 0;

    /**
     * Current size of elements in the container
     */
    currentSize = 0;

    /**
     * Whether more elements can be shown
     */
    canShowMoreElements = true;

    /**
     * Map between element index and current classes
     */
    elementState: {[index: number]: string} = {};

    /**
     * Backup map between element index and current classes
     */
    elementStateBackup: {[index: number]: string} = {};

    /**
     * How many chips are hidden
     */
    hiddenCount = 0;

    /**
     * Whether the container is expanded
     */
    expanded = false;

    buttonTypes = ButtonType;

    mounted(){
        if(this.isMemberOfUserTeam){
            this.maxSize = (this.$refs.chipContainer as HTMLElement).offsetWidth
        }
    }

    updated(){
        if(this.isMemberOfUserTeam){
            this.maxSize = (this.$refs.chipContainer as HTMLElement).offsetWidth
        }
    }

    /**
     * Navigates to the teams page with the selected team selected
     * @param id
     */
    navigateToTeam(id: number) {
        if(this.$isAllowed(this.$Permission.TEAM_VIEW)) {
            this.$router.push({name: 'team-list', query: {team: id.toString()}})
        }
    }

    /**
     * Teams the user is assigned to
     */
    get userTeams(){
        if(this.teams === null){
            return [];
        }
        return this.teams.filter(team => team.type === 1);
    }

    /**
     * Whether the user is assigned to a user generated team
     */
    get isMemberOfUserTeam(){
        return this.teams != null && this.teams.find(team => team.type === 1) != undefined;
    }

    /**
     * Determines whether a specific team chip can be shown
     * During initial render, this team will gradually show more elements until it runs out of space
     * At that point, all remaining chips will be hidden
     * @param index
     */
    canShowElement(index: number){
        if(this.maxSize == 0){
            this.elementState[index] = "invisible";
            return [this.elementState[index]];
        }


        if(!this.canShowMoreElements){
            if(!this.elementState.hasOwnProperty(index)){
                this.elementState[index] = "hidden";
                this.hiddenCount++;
            }else if(this.elementState[index] === "invisible"){
                this.elementState[index] = "hidden";
                this.hiddenCount++;
            }
            // console.log(index + ": returning: " + this.elementState[index]);
            return [this.elementState[index]];
        }else if(this.elementState.hasOwnProperty(index) && this.elementState[index] != "invisible"){
            // console.log(index + ": returning2: " + this.elementState[index]);
            return [this.elementState[index]];
        }
        const element: HTMLElement = (this.$refs.chip as any)[index];
        const combinedSize = element.offsetWidth + this.currentSize;
        // console.log(index + ": combinedSize=" + combinedSize + " elementWidth=" + element.offsetWidth + " maxSize="+this.maxSize);
        if(element.offsetWidth + this.currentSize <= this.maxSize){
            // console.log("returning showChip");
            this.currentSize += element.offsetWidth + 10;
            this.elementState[index] = "showChip";
            return ["showChip"];
        }

        this.canShowMoreElements = false;
        // console.log("returning hidden");
        this.elementState[index] = "hidden";
        this.hiddenCount ++;
        return ["hidden"];
    }

    /**
     * Shows all of the hidden chips and marks the container as expanded
     */
    toggleCell(){
        if(!this.expanded){
            this.elementStateBackup = Object.assign({}, this.elementState);
            for(const key in Object.keys(this.elementState)){
                if(this.elementState.hasOwnProperty(key)){
                    this.elementState[key] = 'showChip'
                }
            }
        }else{
            for(const key in Object.keys(this.elementStateBackup)){
                if(this.elementState.hasOwnProperty(key) && this.elementStateBackup.hasOwnProperty(key)){
                    this.elementState[key] = this.elementStateBackup[key];
                }
            }
        }


        this.expanded = !this.expanded;
    }

    /**
     * The text of the show more/less button
     * If not expanded, this will include the number of hidden chips
     */
    get getShowText(){
        if(!this.expanded){
            return "Show More (" + this.hiddenCount + ")";
        }else{
            return "Show Less"
        }
    }

}
