import _Vue from 'vue';
import {Permission, PermissionRoleMap} from '@/ts/permissions/Permission';
import {Attribute, AttributeMap} from '@/ts/permissions/Attribute';
import store from '@/store';

export function AuthenticationPlugin(Vue: typeof _Vue, options?: any): void {
    /**
     * Checks whether the currently logged in user has a specific permission
     * @param permission Permission to check for
     */
    Vue.prototype.$isAllowed = (permission: Permission) => {
        const user = store.state.auth.loggedInUser;
        if (user === null || user === undefined) {
            return false;
        }
        return PermissionRoleMap[user.role].includes(permission);
    };

    /**
     * Checks whether the logged in user has a specific attribute
     * @param attribute Attribute to check for
     */
    Vue.prototype.$hasAttribute = (attribute: Attribute) => {
        const user = store.state.auth.loggedInUser;
        if (user === null || user === undefined) {
            return false;
        }
        return AttributeMap[user.role].includes(attribute);
    };

    Vue.prototype.$Permission = Permission;
}
