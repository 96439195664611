















































    import {Component, Vue} from 'vue-property-decorator';
    import Table from '../../components/table/Table.vue';
    import {FilterData, FilterType, SearchData} from '@/ts/Filter';
    import {Searchable} from '@/models/Searchable';
    import {
        PageData,
        PaginatedData,
        TableCellData,
        TableCellType,
        TableHeaderData,
        TableRowData
    } from '@/components/table/cells/TableCellData';
    import {Patient, PatientListFilter, PrescriptionFilter} from '@/models/patients/Patient';
    import TextDisplay from '@/components/utility/TextDisplay.vue';
    import {formatMomentDateOnly} from '@/ts/TimezoneUtils';
    import {Moment} from 'moment';
    import AssignedTeamsChipDisplayPatient from '@/components/teams/AssignedTeamsChipDisplayPatient.vue';
    import {PatientService} from '@/services/patients/PatientService';
    import {PatientAssignmentStatus} from '@/models/patients/PatientAssignmentStatus';
    import User from '@/models/User';
    import {Role} from '@/ts/permissions/Role';
    import {Team} from '@/models/Teams';
    import axios from 'axios';
    import {PatientHomeRouteOptions} from '@/models/patients/PatientHome';
    import HelpModal from '@/components/help/HelpModal.vue';
    import {ButtonType} from '@/components/form/FormTypes';

@Component({
    components: {
        Table,
        HelpModal,
    }
})
export default class PatientListPage extends Vue {
    topListShowing = false;
    loading = false;
    currentSortKey: string = "lastName";
    sortedAsc: boolean = true;
    patientData: PaginatedData<Patient> = {totalElements: 0, totalPages: 0, data: []};
    patientService = new PatientService(this.$store.state.auth.loggedInUser.timezone);

    searchString = {
        key: Searchable.PATIENT_ID,
        search: ""
    };

    searchTypes: SearchData[] = [
      {
        key: Searchable.PATIENT_ID,
        value: 'Patient ID'
      },
      {
          key: Searchable.PATIENT_NAME,
          value: 'Patient name'
      },
      {
        key: Searchable.SERIAL_NUMBER,
        value: 'Serial number'
      },
    ];

    eventBus = new Vue();

    pageSize = 30;

    teamList: Team[] = [];
    buttonTypes = ButtonType;


    filterData = {
        prescription: PrescriptionFilter.PROVIDED_AND_NOT_PROVIDED,
        assignments: PatientAssignmentStatus.ASSIGNED_AND_UNASSIGNED,
        teams: [] as number[]
    };

    async mounted(): Promise<void> {
        await Promise.all([this.fetchData(1), this.fetchTeams()]);
    }

    searchData(searchString: any){
        this.searchString = searchString;
        this.fetchData(1)
    }

    async fetchTeams(){
        const user = this.$store.state.auth.loggedInUser as User;
        if(user.role !== Role.ADMIN){
            this.teamList = user.teams;
            return;
        }
        try{
            const {data}: {data: Team[]} = await axios.get(process.env.VUE_APP_BACKEND_URI + "/teams");
            this.teamList = data;
        }catch(e){
            console.log(e);
        }
    }

    async exportList() {
        const request: PatientListFilter = {
            pageSize: this.pageSize,
            page: 0,
            sortedAsc: this.sortedAsc,
            sortKey: this.currentSortKey,
            search: this.searchString.search,
            searchable: this.searchString.key,
            prescriptionFilter: this.filterData.prescription,
            pumpAssignmentFilter: this.filterData.assignments,
            teams: this.filterData.teams
        };
        this.topListShowing = false;
        this.$addSnackbarMessage({
            message: 'Patient list export in progress',
        });
        const response = await this.patientService.exportPatients(request);
        if(response.isSuccess()) {
            this.$addSnackbarMessage({
                message: 'Patient list successfully exported'
            });
        } else {
            this.$addSnackbarMessage({
                message: 'There was an issue exporting the list of patients. Try again later or contact customer support.',
            });
        }
    }

    get teamFilterOptions(): FilterData[] {
        return this.teamList.map((team) => {
            return {
                value: team.id,
                label: team.name,
                key: team.id
            }
        })
    }

    get tableHeader(): TableHeaderData[]{
        if(this.filterData === undefined) {
            return [];
        }
        const vm = this;
        return [
            {
                sortable: true,
                value: "Patient name",
                widthClass: "w-1/4",
                sortKey: "lastName",
            },
            {
                sortable: false,
                value: "Patient ID",
                widthClass: "w-1/10",
            },
            {
                sortable: true,
                value: "Prescription",
                widthClass: "w-1/5",
                sortKey: "prescription",
                filterable: true,
                filterKey: "prescriptionFilter",
                filters: [
                    {
                        type: FilterType.MULTISELECT,
                        key: 'status',
                        active: vm.filterData.prescription !== PrescriptionFilter.PROVIDED_AND_NOT_PROVIDED,
                        enabled: true,
                        displayText: "Filter by status",
                        data: {
                            multiselect: true,
                            activeItems: {
                                status: vm.filterData.prescription !== PrescriptionFilter.PROVIDED_AND_NOT_PROVIDED ? [vm.filterData.prescription] : []
                            },
                            items: [
                                {
                                    value: PrescriptionFilter.PROVIDED,
                                    label: 'Provided',
                                    key: 'provided'
                                },
                                {
                                    value: PrescriptionFilter.NOT_PROVIDED,
                                    label: 'Not Provided',
                                    key: 'not-provided'
                                }
                            ]
                        }
                    }
                ]
            },
            {
                sortable: false,
                value: "Pump assignment",
                widthClass: "w-1/5",
                filterable: true,
                filterKey: 'assignmentFilter',
                filters: [
                    {
                        type: FilterType.MULTISELECT,
                        key: 'status',
                        active: vm.filterData.assignments !== PatientAssignmentStatus.ASSIGNED_AND_UNASSIGNED,
                        enabled: true,
                        displayText: "Filter by status",
                        data: {
                            multiselect: true,
                            activeItems: {
                                status: vm.filterData.assignments !== PatientAssignmentStatus.ASSIGNED_AND_UNASSIGNED ? [vm.filterData.assignments] : []
                            },
                            items: [
                                {
                                    value: PatientAssignmentStatus.ASSIGNED,
                                    label: 'Assigned',
                                    key: 'assigned'
                                },
                                {
                                    value: PatientAssignmentStatus.UNASSIGNED,
                                    label: 'Not assigned',
                                    key: 'not-assigned'
                                }
                            ]
                        }
                    }
                ]
            },
            {
                sortable: false,
                value: "Assigned team(s)",
                widthClass: "w-1/4",
                filterable: true,
                filterKey: 'teamFilter',
                filters: [
                    {
                      type: FilterType.MULTISELECT,
                        key: 'status',
                        active: vm.filterData.teams.length !== 0,
                        enabled: true,
                        displayText: "Filter by team",
                        data: {
                            multiselect: true,
                            activeItems: {
                                status: vm.filterData.teams.length !== 0 ? [...vm.filterData.teams] : []
                            },
                            items: vm.teamFilterOptions
                        }
                    }
                ]
            },
        ]
    }

    get tableData(): PageData{
        if(this.patientData === undefined){
            return {
                numPages: 0,
                totalElements: 0,
                pageData: []
            }
        }
        const tableRows: TableRowData[] = this.patientData.data.map(patient => {
            const cells: TableCellData[] = [
                {
                    type: TableCellType.SLOT_LIST,
                    primaryValue: '',
                    components: [
                        {
                            primaryValue: TextDisplay,
                            componentOptions: {
                                title: patient.lastName + ", " + patient.firstName,
                                value: 'DOB: ' + formatMomentDateOnly(patient.dateOfBirth as Moment),
                                titleClickable: true
                            },
                            events: {
                                titleClick: () => {
                                    if(patient.id !== null) {
                                        this.$router.push({name: 'patient-home', params: {id: patient.id.toString()}})
                                    }
                                }
                            }
                        }
                    ]
                },
                {
                    type: TableCellType.NORMAL,
                    primaryValue: patient.patientId
                },
                {
                    type: TableCellType.SLOT_LIST,
                    primaryValue: '',
                    components: [
                        {
                            primaryValue: TextDisplay,
                            componentOptions: {
                                value: patient.feedPrescriptions.length > 0 ? patient.feedPrescriptions[0].dailyVolume + " mL" : 'Not Provided',
                                valueClickable: true
                            },
                            events: {
                                valueClick: () => {
                                    if(patient.id !== null) {
                                        this.$router.push({name: 'patient-home', params: {id: patient.id.toString()}, query: {initialOption: PatientHomeRouteOptions.PRESCRIPTIONS}})
                                    }
                                }
                            }
                        }
                    ]
                },
                {
                    type: TableCellType.SLOT_LIST,
                    primaryValue: '',
                    components: [
                        {
                            primaryValue: TextDisplay,
                            componentOptions: {
                                value: this.getAssignmentText(patient),
                                valueClickable: true
                            },
                            events: {
                                valueClick: () => {
                                    if(this.getAssignmentText(patient) !== 'Not assigned') {
                                        this.$router.push({name: 'enteral-pump-home', params: {id: patient.assignments[0].device.id.toString()}})
                                    }
                                }
                            }
                        }
                    ]
                },
                {
                    type: TableCellType.SLOT_LIST,
                    primaryValue:"",
                    components: [
                        {
                            primaryValue: AssignedTeamsChipDisplayPatient,
                            componentOptions: {
                                key: patient.id,
                                teams: patient.teams
                            }
                        }
                    ]
                }
            ];
            return {
                cells,
                highlighted: false,
                indexKey: patient.id as number
            }
        });


        return {
            numPages: this.patientData.totalPages,
            totalElements: this.patientData.totalElements,
            pageData: tableRows
        }
    }

    getAssignmentText(patient: Patient){
        if(patient.assignments.length > 0 && patient.assignments[0].endDate === null && patient.assignments[0].device !== null){
            return patient.assignments[0].device.serialNumber;
        }

        return "Not assigned";
    }

    async fetchData(page: number) {
        if(this.searchString === undefined || this.filterData === undefined) {
            return;
        }
        const request: PatientListFilter = {
            pageSize: this.pageSize,
            page: page - 1,
            sortedAsc: this.sortedAsc,
            sortKey: this.currentSortKey,
            search: this.searchString.search,
            searchable: this.searchString.key,
            prescriptionFilter: this.filterData.prescription,
            pumpAssignmentFilter: this.filterData.assignments,
            teams: this.filterData.teams
        };
        const response = await this.patientService.fetchPatients(request);
        if(response.isSuccess()){
            response.map(fetchedData => {
                this.patientData = fetchedData;
                page == 1 ? this.eventBus.$emit('dataReset') : this.eventBus.$emit('dataLoaded');
            });
        }
    }

    sortData(message: any){
        this.currentSortKey = message.sortKey;
        this.sortedAsc = message.sortedAsc;
        this.fetchData(1);
    }

    pageSizeChanged(newSize: number){
        this.pageSize = newSize;
        this.fetchData(1);
    }

    handlePrescriptionFilter(data: any){
        if(data.status !== undefined && data.status.length == 1){
            this.filterData.prescription = data.status[0];
        }else {
            this.filterData.prescription = PrescriptionFilter.PROVIDED_AND_NOT_PROVIDED;
        }
        this.fetchData(1);
    }

    handleAssignmentFilter(data: any){
        if(data.status !== undefined && data.status.length == 1){
            this.filterData.assignments = data.status[0];
        }else{
            this.filterData.assignments = PatientAssignmentStatus.ASSIGNED_AND_UNASSIGNED;
        }
        this.fetchData(1);
    }

    handleTeamFilter(data: any){
        if(data.status !== undefined){
            this.filterData.teams = data.status;
        }else {
            this.filterData.teams = [];
        }
        this.fetchData(1);
    }

    handleClearAllFilters(){
        this.filterData.prescription = PrescriptionFilter.PROVIDED_AND_NOT_PROVIDED;
        this.filterData.assignments = PatientAssignmentStatus.ASSIGNED_AND_UNASSIGNED;
        this.filterData.teams = [];
    }

}
