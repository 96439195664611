export enum Region {
    UNITED_STATES = 0,
    CANADA = 1,
    AUSTRALIA_NEW_ZEALAND = 2,
    SINGAPORE = 3,
    UNITED_KINGDOM = 4,
    FRANCE = 5,
    NETHERLANDS = 6,
    ITALY = 7,
    GERMANY = 8,
    SPAIN = 9,
    NORWAY = 10,
    SWEDEN = 11,
    FINLAND = 12,
    BELGIUM = 13,
    MEXICO = 14,
    BRAZIL = 15,
    PANAMA = 16,
    COLOMBIA = 17,
    CHILE = 18,
    PERU = 19,
    JAPAN = 20,
    CHINA = 21,
    IRELAND = 22,
    SOUTH_AFRICA = 23,
    UNITED_ARAB_EMIRATES = 24,
    QATAR = 25,
    KUWAIT = 26,
    SAUDI_ARABIA = 27,
}

const RegionNameMap: { [index: number]: string } = {
    [Region.UNITED_STATES]: 'United States',
    [Region.CANADA]: 'Canada',
    [Region.AUSTRALIA_NEW_ZEALAND]: 'Australia/New Zealand',
    [Region.SINGAPORE]: 'Singapore',
    [Region.UNITED_KINGDOM]: 'United Kingdom',
    [Region.FRANCE]: 'France',
    [Region.NETHERLANDS]: 'Netherlands',
    [Region.ITALY]: 'Italy',
    [Region.GERMANY]: 'Germany',
    [Region.SPAIN]: 'Spain',
    [Region.NORWAY]: 'Norway',
    [Region.SWEDEN]: 'Sweden',
    [Region.FINLAND]: 'Finland',
    [Region.BELGIUM]: 'Belgium',
    [Region.MEXICO]: 'Mexico',
    [Region.BRAZIL]: 'Brazil',
    [Region.PANAMA]: 'Panama',
    [Region.COLOMBIA]: 'Colombia',
    [Region.CHILE]: 'Chile',
    [Region.PERU]: 'Peru',
    [Region.JAPAN]: 'Japan',
    [Region.CHINA]: 'China',
    [Region.IRELAND]: 'Ireland',
    [Region.SOUTH_AFRICA]: 'South Africa',
    [Region.UNITED_ARAB_EMIRATES]: 'United Arab Emirates',
    [Region.QATAR]: 'Qatar',
    [Region.KUWAIT]: 'Kuwait',
    [Region.SAUDI_ARABIA]: 'Saudi Arabia',
};

export function getRegions() {
    const regions: any[] = [];
    for (const region in RegionNameMap) {
        if (RegionNameMap.hasOwnProperty(region)) {
            regions.push({
                label: RegionNameMap[region],
                value: Number(region),
            });
        }
    }
    return regions;
}

export {RegionNameMap};
