



































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {Team, TeamType} from '@/models/Teams';
import {ButtonType, RadioButtonData} from '../form/FormTypes';
import Multiselect from 'vue-multiselect';
import {Validations} from '@/ts/validation-decorator';
import {requiredIf} from 'vuelidate/lib/validators';
import TeamService from '@/services/TeamService';
import HelpModal from '@/components/help/HelpModal.vue';

/**
 * Renders a form for managing which teams a patient is assigned to
 */
@Component({
    components: {
        Multiselect,
        HelpModal,
    }
})
export default class PatientTeamSelector extends Vue {
    /**
     * Currently selected teams
     */
    @Prop({default: () => []}) selectedTeams?: any[];

    /**
     * Currently selected team group (Users on teams vs all users
     */
    teamGroup: number = 0;
    teams: Team[] = [];
    systemTeam: Team | null = null;

    buttonTypes = ButtonType;

    teamService = new TeamService()

    /**
     * Creates the radio button options for the team group picker
     */
    get teamRadioOptions(): RadioButtonData[] {
        const data = [];
        data.push({
            label: "Visible to users on teams",
            value: 1
        });
        if(this.userIsOnSystemTeam) {
            data.push( {
                label: "Visible only to users with access to all patients",
                value: 0
            },)
        }


        return data
    }

    /**
     * Whether the user is assigned to the system team for their customer
     */
    get userIsOnSystemTeam() {
        return this.$store.state.auth.loggedInUser.teams.find((team: Team) => team.type === TeamType.SYSTEM) !== undefined;
    }

    async mounted(){
        await this.fetchAllTeams();
        if(!this.userIsOnSystemTeam) {
            this.teamGroup = 1;
        }
    }

    /**
     * Updates the list of teams when the team group selector changes
     */
    @Watch('teamGroup')
    teamGroupChanged(){
        if(this.teamGroup === 0){
            this.$emit('selectedTeams', []);
        }
    }

    /**
     * Updates field validations on changes to the selected teams
     */
    @Watch('selectedTeams')
    selectedTeamsChanged() {
        if(this.$v.selectedTeams) {
            this.$v.selectedTeams.$touch();
            this.$emit('invalid', this.$v.$invalid);
        }

    }

    /**
     * Emits an event when a form field changes
     * @param field Field that was updated
     * @param data New field data
     */
    emitEvent(field: string, data: any){
        this.$emit(field, data);
    }

    /**
     * Form field validations
     */
    @Validations()
    validations() {
        return {
            selectedTeams: {
                requiredIf: requiredIf(() => !this.userIsOnSystemTeam)
            }
        }
    }

    /**
     * Fetches the list of teams that can be assigned
     */
    async fetchAllTeams(){
        if(this.userIsOnSystemTeam){
            const response = await this.teamService.fetchAllTeams()
            response.map((data) => {
                this.teams = data.userTeams;
                this.systemTeam = data.systemTeam;
            })
        }else {
            this.teams = this.$store.state.auth.loggedInUser.teams;
        }

    }

}
