























































































import { Component, Vue } from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import {PatientActions, PatientGetters, PatientHomePageModel, PatientMutations} from '@/vuex/patients';
import AssignedTeamsChipDisplayPatient from '@/components/teams/AssignedTeamsChipDisplayPatient.vue';
import {RawLocation, Route} from 'vue-router';
import store from '@/store';
import {Team, TeamType} from '@/models/Teams';
import AddPatientToTeamsModal from '@/components/teams/AddPatientToTeamsModal.vue';
import {ModalMutations} from '@/vuex/modal';
import {PatientService} from '@/services/patients/PatientService';
import {PatientDeviceAssignment} from '@/models/patients/Patient';
import {ButtonType} from '@/components/form/FormTypes';
import ConfirmationModal from '@/components/modal/ConfirmationModal.vue';

const patient = namespace('patients');
const modal = namespace('modal');

Component.registerHooks([
    'beforeRouteEnter',
    'beforeRouteLeave',
    'beforeRouteUpdate'
]);

/**
 * Renders a hoe page for a single patient
 * Note: This page acts as a container for the nested patient routes
 */
@Component({
    components: {
        AssignedTeamsChipDisplayPatient,
        AddPatientToTeamsModal,
        ConfirmationModal,
    }
})
export default class PatientHomePage extends Vue {
    @patient.State(state => state.homePage) homePageData!: PatientHomePageModel;
    @patient.Action(PatientActions.CHECK_PATIENT_HOME_DEVICE_CONNECTION) fetchDeviceStatus!: (deviceId: number) => any;
    @patient.Mutation(PatientMutations.SET_PATIENT_HOME_PAGE_DEVICE_CONNECTION) setPatientHomePageConnectionStatus!: (data: {connected: boolean, pending: boolean}) => void;
    @modal.Mutation(ModalMutations.CHANGE_MODAL_STATE) changeModalState!: (state: boolean) => void;
    @patient.Mutation(PatientMutations.UPDATE_TEAM_LIST) updatePatientTeamList!: (newTeams: Team[]) => void;
    @patient.Getter(PatientGetters.GET_LATEST_HOMEPAGE_ASSIGNMENT) latestAssignment!: PatientDeviceAssignment | null;
    @patient.Getter(PatientGetters.GET_FUTURE_ASSIGNMENT_START_DATE) futureAssignmentStartDate!: string | null;

    reportRoutes = ['patient-pump-error-report', 'patient-pump-settings-report', 'patient-weight-history-report'];

    /**
     * Whether the report dropdown menu is showing
     */
    reportListShowing = false;

    /**
     * Whether the teams modal is showing
     */
    showingTeamsModal = false;

    /**
     * Whether the top list is showing
     */
    topListShowing = false;

    /**
     * Whether the remove patient modal is showing
     */
    showingRemoveModal = false;
    patientService = new PatientService(this.$store.state.auth.loggedInUser.timezone);
    buttonTypes = ButtonType;


    async beforeRouteEnter(to: Route, from: Route, next: (to?: (RawLocation | false | ((vm: PatientHomePage) => any) | void)) => void): Promise<any> {
        await store.dispatch('patients/' + PatientActions.FETCH_PATIENT_HOME_PAGE_PATIENT, to.params.id);
        next();
    }

    mounted(): void {
        if(this.homePageData.patient?.assignments?.length !== undefined && this.homePageData.patient?.assignments.length > 0 && this.homePageData.patient.assignments[0].endDate === null){
            this.fetchDeviceStatus(this.homePageData.patient.assignments[0].device.id);
        } else {
          this.setPatientHomePageConnectionStatus({connected: false, pending: false})
        }
    }

    // get latestAssignment(){
    //     if(this.homePageData?.patient?.assignments === undefined || this.homePageData.patient.assignments.length === 0){
    //         return null;
    //     }
    //     const hasPastAssignments = this.homePageData.patient.assignments.length > 1;
    //     const endOfToday = moment.tz(this.$store.state.auth.loggedInUser.timezone).endOf('day');
    //     const latestAssignmentEnd = this.homePageData.patient.assignments[0].endDate;
    //
    //     if (hasPastAssignments) {
    //         const priorAssignmentEnd = this.homePageData.patient.assignments[1].endDate as Moment;
    //
    //         if(priorAssignmentEnd.isSameOrBefore(endOfToday)) {
    //             return this.homePageData.patient.assignments[1]
    //         }
    //     }
    //
    //     if (latestAssignmentEnd == null || (latestAssignmentEnd as Moment).isSameOrBefore(endOfToday)) {
    //         return this.homePageData.patient.assignments[0]
    //     }
    //
    //     return null;
    // }

    /**
     * Determines where a specific tab is active
     * @param tabName
     */
    isTabActive(tabName: string){
        if(tabName === 'home' && this.$route.name === 'patient-home'){
            return true;
        }else if (tabName === 'reports' && this.reportRoutes.includes(this.$route.name ?? 'N/A')){
            return true;
        }else if(tabName === 'monitor-mode' && this.$route.name === 'patient-monitor-pump'){
            return true;
        }
        return false;
    }

    /**
     * Navigates the browser to a specific patient report page
     * @param name
     */
    navigateToReport(name: string){
        this.$router.push({name});
        this.reportListShowing = false;
    }

    /**
     * Returns the non-system teams the patient is assigned to
     */
    get patientUserTeams(){
        if(this.homePageData?.patient?.teams?.length !== undefined) {
            return this.homePageData.patient.teams.filter((team) => team.type === TeamType.USER);
        }

        return []
    }

    /**
     * Shows the edit teams modal
     */
    showTeamsModal(){
        this.showingTeamsModal = true;
        this.changeModalState(true);
    }

    /**
     * Updates the patients team information
     * @param newTeams
     */
    handleTeamUpdate(newTeams: Team[]){
        this.updatePatientTeamList(newTeams);
        this.hideTeamsModal();
    }

    /**
     * Hides the teams modal
     */
    hideTeamsModal(){
        this.showingTeamsModal = false;
        this.changeModalState(false);
    }

    /**
     * Shows the modal to remove a patient
     */
    showRemovePatientModal() {
        this.topListShowing = false;
        this.showingRemoveModal = true;
        this.changeModalState(true);
    }

    /**
     * Sends the request to remove a patient
     */
    async removePatient() {
        if(this.homePageData.patient === null) {
            return;
        }
        const response = await this.patientService.removePatient(this.homePageData.patient.id as number);
        if(response.isSuccess()){
            this.$addSnackbarMessage({
                message: 'Patient successfully removed',
            });
            this.hideRemovePatientModal();
            this.$router.push({name: 'patient-list'})
        } else {
            this.$addSnackbarMessage({
                message: 'There was an issue exporting removing the patient. Try again later or contact customer support.',
            });
            this.hideRemovePatientModal();
        }

    }

    /**
     * Hides the patient remove modal
     */
    hideRemovePatientModal() {
        this.showingRemoveModal = false;
        this.changeModalState(false);
    }
}
