import {Moment} from 'moment';
import {AsyncEither, AsyncNullSuccess, Either, Failure, nullSuccess, Success} from '@/errors/Either';
import {FeedHistoryExportData, FeedHistoryReport} from '@/models/reports/FeedHistory';
import {Error} from '@/errors/Error';
import axios from 'axios';
import {StatusRecord} from '@/models/Devices/MARRS/Records';
import {convertToMomentTimezone} from '@/ts/TimezoneUtils';
import {saveDownloadedFile} from '@/ts/utilities';


export default class PatientFeedingHistoryReportService {
    private readonly timezone: string;


    constructor(timezone: string) {
        this.timezone = timezone;
    }

    /**
     * Sends the request to fetch feeding history data for a patient
     * @param patientId ID of the patient
     * @param startDate Start date of the report
     * @param endDate End date of the report
     */
    // tslint:disable-next-line:max-line-length
    public async fetchFeedingHistoryReport(patientId: number, startDate: Moment | Date, endDate: Moment | Date): AsyncEither<FeedHistoryReport, Error> {
        try {
            // tslint:disable-next-line:max-line-length
            const {data}: {data: FeedHistoryReport} = await axios.get(process.env.VUE_APP_BACKEND_URI + '/patients/' + patientId + '/reports/feed-history', {
                params: {
                    startDate: startDate.toISOString(),
                    endDate: endDate.toISOString(),
                },
            });
            data.statusRecords.forEach((record: StatusRecord) => {
                record.timestamp = convertToMomentTimezone(this.timezone, record.timestamp as string);
            });
            data.startDate = convertToMomentTimezone(this.timezone, data.startDate as string);
            data.endDate = convertToMomentTimezone(this.timezone, data.endDate as string);
            return new Success(data);
        } catch (e) {
            return new Failure({
                message: 'Failed to generate feed history report',
            });
        }
    }

    /**
     * Exports the feeding history data as an Excel document
     * @param patientId ID of the patient
     * @param startDate Start date of the report
     * @param endDate End date of the report
     */
    // tslint:disable-next-line:max-line-length
    public async exportFeedingHistoryReportAsExcel(patientId: number, startDate: Moment | Date, endDate: Moment | Date): AsyncNullSuccess<Error> {
        try {
            // tslint:disable-next-line:max-line-length
            const response = await axios.get(process.env.VUE_APP_BACKEND_URI + '/patients/' + patientId + '/reports/feed-history/csv', {
                params: {
                    startDate: startDate.toISOString(),
                    endDate: endDate.toISOString(),
                },
                responseType: 'blob',
            });
            saveDownloadedFile(response, 'patient-feed-history.xlsx');

            return nullSuccess();
        } catch (e) {
            return new Failure({
                message: 'Failed to generate feed history report',
            });
        }
    }

    /**
     * Exports the feeding history data as a PDF
     * @param patientId ID of the patient
     * @param startDate Start date of the report
     * @param endDate End date of the report
     * @param feedHistoryExportData Feeding history graph data to show in the PDF
     */
    // tslint:disable-next-line:max-line-length
    public async exportFeedingHistoryReportAsPdf(patientId: number, startDate: Moment | Date, endDate: Moment | Date, feedHistoryExportData: FeedHistoryExportData): AsyncNullSuccess<Error> {
        try {
            // tslint:disable-next-line:max-line-length
            const response = await axios.post(process.env.VUE_APP_BACKEND_URI + '/patients/' + patientId + '/reports/feed-history/pdf', feedHistoryExportData, {
                params: {
                    startDate: startDate.toISOString(),
                    endDate: endDate.toISOString(),
                },
                responseType: 'blob',
            });
            saveDownloadedFile(response, 'patient-feed-history.pdf');

            return nullSuccess();
        } catch (e) {
            return new Failure({
                message: 'Failed to generate feed history report',
            });
        }
    }
}
