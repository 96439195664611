



















import { Component, Vue } from 'vue-property-decorator';
import {namespace} from "vuex-class";
import {Hub} from '@/models/Devices/Hubs/Hub';
import {formatMoment} from "@/ts/TimezoneUtils";
import {Moment} from "moment";

const marrs = namespace('marrsPump');

/**
 * Renders information about the deivce's most recent hub
 */
@Component
export default class WirelessHubDetailsCard extends Vue {
    @marrs.State(state => state.homePage.hub) hub!: Hub | undefined | null;

    /**
     * Formats the last message received timestamp of the hub
     */
    get lastMessageTime(){
        if(this.hub?.lastMessageTimestamp !== undefined){
            return formatMoment(this.hub.lastMessageTimestamp as Moment);
        }
        return "N/A";
    }

    /**
     * Gets the serial number of the hub
     */
    get serialNumber(){
        return this.hub?.serialNumber !== undefined ? this.hub.serialNumber : "N/A"
    }

    /**
     * Gets the type of the hub
     */
    get type(){
        return this.hub?.productName !== undefined ? this.hub.productName : "N/A"
    }

    /**
     * Navigates to the page for the hub when the serial number is clicked
     */
    navigateToHub() {
        if(this.hub === undefined || this.hub === null) {
            return;
        }

        this.$router.push({
            name: 'hub-view',
            params: {
                id: this.hub.id.toString()
            }
        })
    }
}
