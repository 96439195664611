


















































































    import {Component, Vue} from 'vue-property-decorator';
    import Table from '../../components/table/Table.vue';
    import {SearchData} from '@/ts/Filter';
    import {Searchable} from '@/models/Searchable';
    import {ButtonType} from '@/components/form/FormTypes';
    import {
        TableCellData,
        TableCellType,
        TableHeaderData,
        TableRowData
    } from '@/components/table/cells/TableCellData';
    import {ServiceableDevice} from '@/models/service/ServiceTools';
    import Checkbox from '@/components/form/Checkbox.vue';
    import TextDisplay from '@/components/utility/TextDisplay.vue';
    import ServiceToolsService from '@/services/ServiceToolsService';
    import Multiselect from 'vue-multiselect';
    import Modal from '@/components/modal/Modal.vue';
    import {namespace} from 'vuex-class';
    import {ModalMutations} from '@/vuex/modal';
    import moment from 'moment';
    import ConfirmationModal from '@/components/modal/ConfirmationModal.vue';

    enum ActionOptions {
        RETURN_TO_STOCK,
        RETURN_TO_CUSTOMER,
    }
    const modal = namespace('modal');

    @Component({
    components: {
        Table,
        Multiselect,
        Modal,
        ConfirmationModal,
    }
})
export default class ServicePanel extends Vue {

    buttonTypes = ButtonType;
    selectedPumps: Set<number> = new Set([]);
    searchString = "";
    searchKey = Searchable.SERIAL_NUMBER;
    devices: ServiceableDevice[] = [];
    serviceToolsService = new ServiceToolsService(this.$store.state.auth.loggedInUser.timezone);
    @modal.Mutation(ModalMutations.CHANGE_MODAL_STATE) changeModalState!: (state: boolean) => void;
    loading = false;
    selectedAction: SearchData | null = null;
    serviceToolsModalShowing = false;
    massDiagnosticsModalShowing = false;
    topListShowing = false;

    selectedDates = {
        start: null as null | Date,
        end: null as null | Date
    };

    maxDate: null | Date = null;

    handleSearch({search, key}: {search: string, key: number}){
        this.searchString = search;
        this.searchKey = key;
    }

    mounted() {
        this.fetchData();
    }

    testEvent(data: any) {
        if(this.maxDate === null ) {
            this.maxDate = new Date(data.id);
        }
    }

    updateSelectedDates(data: any){
        console.log(data);
        if(data !== null ) {
            this.selectedDates.start = data.start;
            this.selectedDates.end = data.end;
            this.maxDate = null;
        }

    }

    get massDiagsEndDate() {
        if(this.maxDate === null) {
            return new Date();
        }

        return moment(this.maxDate).add(30, 'day').toDate();
    }

    async fetchData() {
        this.loading = true;
        const response = await this.serviceToolsService.fetchServiceableDevices();
        response.map((devices) => {
            this.devices = devices;
        });
        this.loading = false;
    }

    async processAction() {
        if(this.selectedAction === null) {
            return;
        }

        if(this.selectedAction.key === ActionOptions.RETURN_TO_STOCK) {
            const response = await this.serviceToolsService.bulkReturnPumpToStock([...this.selectedPumps]);
            if(response.isSuccess()) {
                this.selectedPumps = new Set<number>([]);
                this.fetchData();
                this.$addSnackbarMessage({
                    message: 'Pumps successfully returned to stock',
                });
                this.hideServiceToolsModal();
            } else {
                this.$addSnackbarMessage({
                    message: 'There was an issue returning the pump to stock. Try again later or contact customer support.',
                });
            }
        } else {
            const response = await this.serviceToolsService.bulkReturnPumpsToCustomer([...this.selectedPumps]);
            if(response.isSuccess()) {
                this.devices
                    .filter((device) => this.selectedPumps.has(device.deviceId))
                    .forEach((device) => device.hasAssignment = false);
                this.$addSnackbarMessage({
                    message: 'Pumps successfully returned to customer',
                });
                this.hideServiceToolsModal();
            } else {
                this.$addSnackbarMessage({
                    message: 'There was an issue returning the pump to the customer. Try again later or contact customer support.',
                });
            }
        }
    }

    async exportDiagnosticRecords() {
        if(this.selectedDates.start === null || this.selectedDates.end === null) {
            return;
        }

        this.$addSnackbarMessage({
            message: 'Diagnostic export in progress',
        });
        const response = await this.serviceToolsService.exportMassDiagnosticRecords(this.selectedDates.start, this.selectedDates.end);
        if(response.isSuccess()) {
            this.$addSnackbarMessage({
                message: 'Mass Diagnostic Export finished successfully.',
            });
        } else {
            this.$addSnackbarMessage({
                message: 'There was an issue exporting the list of diagnostic records. Try again later or contact customer support.',
            });
        }
        this.hideMassDiagnosticsModal();
    }

    showMassDiagnosticsModal() {
        this.topListShowing = false;
        this.massDiagnosticsModalShowing = true;
        this.changeModalState(true);
    }

    hideMassDiagnosticsModal() {
        this.massDiagnosticsModalShowing = false;
        this.changeModalState(false);
        this.selectedDates.start = null;
        this.selectedDates.end = null;
    }

    showServiceToolsModal() {
        this.serviceToolsModalShowing = true;
        this.changeModalState(true);
    }

    hideServiceToolsModal() {
        this.serviceToolsModalShowing = false;
        this.changeModalState(false);
    }

    get tableHeader(): TableHeaderData[]{
        const vm = this;
        return [
            {
                sortable: false,
                value: 'Serial number',
                widthClass: 'w-1/3',
            },
            {
                sortable: false,
                value: 'Customer',
                widthClass: 'w-1/3',
            },
            {
                sortable: false,
                value: 'Patient assignment status',
                widthClass: 'w-1/3',
            },
        ]
    }

    get tableData(): TableRowData[] {
        return this.devices
            .filter(this.filterDevicesBySerialNumber)
            .map((device) => {
                const vm = this;
                const cells: TableCellData[] = [
                    {
                        type: TableCellType.SLOT_LIST,
                        primaryValue:"",
                        components: [
                            {
                                primaryValue: Checkbox,
                                componentOptions: {
                                    label: '',
                                    checked: vm.selectedPumps.has(device.deviceId)
                                },
                                events: {
                                    change: () => {
                                        if(vm.selectedPumps.has(device.deviceId)){
                                            vm.selectedPumps.delete(device.deviceId);
                                            vm.selectedPumps = new Set<number>(vm.selectedPumps);
                                        }else {
                                            vm.selectedPumps = new Set<number>(vm.selectedPumps.add(device.deviceId));
                                        }
                                    }
                                }
                            },
                            {
                                primaryValue: TextDisplay,
                                componentOptions: {
                                    value: device.serialNumber,
                                    class: 'inline-block',
                                    valueClickable: false,
                                },
                            }
                        ]
                    },
                    {
                        type: TableCellType.NORMAL,
                        primaryValue: device.customer.name
                    },
                    {
                        type: TableCellType.NORMAL,
                        primaryValue: `${device.hasAssignment ? 'Assigned' : 'Unassigned'}`
                    },
                ];

                return {
                    cells,
                    indexKey: device.deviceId
                }
            })
    }

    filterDevicesBySerialNumber(device: ServiceableDevice) {
        if(this.searchString === ""){
            return true;
        }
        return device.serialNumber.includes(this.searchString);
    }

    get searchTypes(): SearchData[] {
        return [
            {
                key: Searchable.SERIAL_NUMBER,
                value: 'Serial number'
            }
        ]
    }

    get actionOptions(): SearchData[] {
        return [
            {
                key: ActionOptions.RETURN_TO_STOCK,
                value: 'Return to stock'
            },
            {
                key: ActionOptions.RETURN_TO_CUSTOMER,
                value: 'Return to customer'
            }
        ]
    }

    get devicesToActOn() {
        if(this.selectedPumps.size === 0) {
            return ''
        }

        return this.devices.filter((device) => this.selectedPumps.has(device.deviceId))
            .map((device) => device.serialNumber)
            .join(', ');
    }
}
