import {Moment} from 'moment';
import {Team} from '@/models/Teams';

/**
 * Represents a single customer
 */
export interface Customer {
    id: number;
    name: string;
    customerNumber: string;
    region: number;
    notes: string;
    customerStatus: number;
    isActive: boolean;
    lastUserLogin: Moment | string;
    createdDate: Moment | string;
    teams?: Team[];
}

/**
 * Request data sent to fetch a list of customers
 */
export interface CustomerListRequest {
    search: string;
    disabled: boolean;
}

/**
 * Request data sent to sort the list of customers
 */
export interface CustomerSortRequest {
    sortKey: string;
    sortedAsc: boolean;
}

export type CustomerExportRequest = CustomerListRequest & CustomerSortRequest;

/**
 * Status of a customer account (enabled or disabled)
 */
export enum CustomerStatus {
    ENABLED = 0,
    DISABLED = 1,
}

/**
 * Map of customer statuses to their name and icons
 */
const CustomerStatusMap = [
    {
        label: 'Enabled',
        value: CustomerStatus.ENABLED,
        icon: 'unlock',
    },
    {
        label: 'Disabled',
        value: CustomerStatus.DISABLED,
        icon: 'lock',
    },
];

export {CustomerStatusMap};
