












import { Component, Vue } from 'vue-property-decorator';
import {namespace} from "vuex-class";
import {Patient} from '@/models/patients/Patient';
import {ButtonType} from '../../form/FormTypes';
import {formatMomentDateOnly} from '@/ts/TimezoneUtils';
import {Moment} from 'moment';

const patient = namespace('patients');

/**
 * Describes the details of the patient
 */
@Component
export default class PatientDetailsQuickLink extends Vue {
    @patient.State(state => state.homePage.patient) patientData!: Patient;

    buttonType = ButtonType;

    /**
     * Formats the creation date of the patient
     */
    get patientCreation(){
        if(this.patientData.createdDate !== undefined){
            return formatMomentDateOnly(this.patientData.createdDate as Moment);
        }
        return 'N/A';
    }
}
