var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"perfect-square"},[_c('div',{class:['square-content', {'marrs-min-screen': _vm.hasMinSize}]},[_c('div',{ref:"marrsPumpWrapper",class:['pump-wrapper', _vm.marrsPumpFontSize]},[(_vm.monitorModeRecord != null && _vm.screenInfo != null)?_c('div',{staticClass:"pump-region",class:[this.screenClass]},[(!this.screenLocked)?_c('div',{staticClass:"button-container"},[_c('div',{staticClass:"button-slot"},[(this.screenInfo && 1 in this.screenInfo.buttons &&
                         (this.screenInfo.buttons[1].enabled === undefined ||
                         (this.screenInfo.buttons[1].enabled !== undefined && this.screenInfo.buttons[1].enabled(this.monitorModeRecord.screenData))))?_c('div',{staticClass:"marrs-button",class:[this.screenInfo.buttons[1].backgroundClass, this.screenInfo.buttons[1].borderClass]},[_c('span',{class:[this.screenInfo.buttons[1].spanClass, this.buttonIconMap[1]],domProps:{"textContent":_vm._s(this.screenInfo.buttons[1].text)}})]):_vm._e()]),_c('div',{staticClass:"button-slot"},[(this.screenInfo && 2 in this.screenInfo.buttons &&
                         (this.screenInfo.buttons[2].enabled === undefined ||
                         (this.screenInfo.buttons[2].enabled !== undefined && this.screenInfo.buttons[2].enabled(this.monitorModeRecord.screenData))))?_c('div',{staticClass:"marrs-button",class:[this.screenInfo.buttons[2].backgroundClass, this.screenInfo.buttons[2].borderClass]},[_c('span',{class:[this.screenInfo.buttons[2].spanClass, this.buttonIconMap[2]],domProps:{"textContent":_vm._s(this.screenInfo.buttons[2].text)}})]):_vm._e()]),_c('div',{staticClass:"button-slot"},[(this.screenInfo && 3 in this.screenInfo.buttons &&
                         (this.screenInfo.buttons[3].enabled === undefined ||
                         (this.screenInfo.buttons[3].enabled !== undefined && this.screenInfo.buttons[3].enabled(this.monitorModeRecord.screenData))))?_c('div',{staticClass:"marrs-button",class:[this.screenInfo.buttons[3].backgroundClass, this.screenInfo.buttons[3].borderClass]},[_c('span',{class:[this.screenInfo.buttons[3].spanClass, this.buttonIconMap[3]],domProps:{"textContent":_vm._s(this.screenInfo.buttons[3].text)}})]):_vm._e()]),_c('div',{staticClass:"button-slot"},[(this.screenInfo && 4 in this.screenInfo.buttons &&
                         (this.screenInfo.buttons[4].enabled === undefined ||
                         (this.screenInfo.buttons[4].enabled !== undefined && this.screenInfo.buttons[4].enabled(this.monitorModeRecord.screenData))))?_c('div',{staticClass:"marrs-button",class:[this.screenInfo.buttons[4].backgroundClass, this.screenInfo.buttons[4].borderClass]},[_c('span',{class:[this.screenInfo.buttons[4].spanClass, this.buttonIconMap[4]],domProps:{"textContent":_vm._s(this.screenInfo.buttons[4].text)}})]):_vm._e()]),_c('div',{staticClass:"button-slot"},[(this.screenInfo && 5 in this.screenInfo.buttons &&
                         (this.screenInfo.buttons[5].enabled === undefined ||
                         (this.screenInfo.buttons[5].enabled !== undefined && this.screenInfo.buttons[5].enabled(this.monitorModeRecord.screenData))))?_c('div',{staticClass:"marrs-button",class:[this.screenInfo.buttons[5].backgroundClass, this.screenInfo.buttons[5].borderClass]},[_c('span',{class:[this.screenInfo.buttons[5].spanClass, this.buttonIconMap[5]],domProps:{"textContent":_vm._s(this.screenInfo.buttons[5].text)}})]):_vm._e()])]):_c('div',{staticClass:"button-container"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"h-2/5"})]),_c('div',{staticClass:"title-detail-container"},[_c('div',{staticClass:"title-region flex justify-center items-center"},[_c('span',{staticClass:"inline-block",domProps:{"textContent":_vm._s(_vm.title)}})]),_c('div',{staticClass:"detail-container"},[(this.isFeeding)?_c('div',{staticClass:"w-3/20 marrs-feed-drip bg-marrs-light-green"}):_vm._e(),_c('div',{staticClass:"detail-region",class:[this.runningModeClass]},[(this.regionOne != null && (this.screenInfo.regions[1].enabled === undefined ||
                    (this.screenInfo.regions[1].enabled !== undefined && this.screenInfo.regions[1].enabled(this.monitorModeRecord.screenData))))?_c('div',{class:[this.regionOneClass],domProps:{"innerHTML":_vm._s(this.regionOne)}}):_vm._e(),(this.regionTwo != null && (this.screenInfo.regions[2].enabled === undefined ||
                    (this.screenInfo.regions[2].enabled !== undefined && this.screenInfo.regions[2].enabled(this.monitorModeRecord.screenData))))?_c('div',{class:[this.regionTwoClass],domProps:{"innerHTML":_vm._s(this.regionTwo)}}):_vm._e(),(this.regionThree != null && (this.screenInfo.regions[3].enabled === undefined ||
                    (this.screenInfo.regions[3].enabled !== undefined && this.screenInfo.regions[3].enabled(this.monitorModeRecord.screenData))))?_c('div',{class:[this.regionThreeClass],domProps:{"innerHTML":_vm._s(this.regionThree)}}):_vm._e(),(this.regionFour != null && (this.screenInfo.regions[4].enabled === undefined ||
                    (this.screenInfo.regions[4].enabled !== undefined && this.screenInfo.regions[4].enabled(this.monitorModeRecord.screenData))))?_c('div',{class:[this.regionFourClass],domProps:{"innerHTML":_vm._s(this.regionFour)}}):_vm._e()])]),_c('div',{staticClass:"icon-region"},[_c('div',{staticClass:"flex flex-row justify-end w-full h-full items-center"},[_c('div',{staticClass:"flex flex-row justify-between w-full h-full"},[(this.monitorModeRecord.screenData.alarmSilenced)?_c('div',{staticClass:"flex flex-row items-center h-full pl-4"},[_c('img',{staticClass:"h-9/10",attrs:{"src":require("../../../assets/MARRS/MonitorMode/icon_mute_donut.png"),"alt":""}})]):_c('div',{staticClass:"flex flex-row flex-1 h-full pl-1 pr-1 items-center"},[_c('div',{staticClass:"w-1/3 h-full flex flex-row justify-center items-center"},[(this.monitorModeRecord.screenData.setUseGT24Hours)?_c('img',{attrs:{"src":require("../../../assets/MARRS/MonitorMode/set-use-24-hours.png"),"alt":""}}):_vm._e()]),_c('div',{staticClass:"w-1/3 h-full flex flex-row justify-center items-center"},[(this.monitorModeRecord.screenData.enableLockedSettings)?_c('img',{attrs:{"src":require("../../../assets/MARRS/MonitorMode/settings-locked.png"),"alt":""}}):_vm._e()]),_vm._m(2)]),_c('div',{staticClass:"flex flex-row items-center"},[_c('span',{domProps:{"textContent":_vm._s(this.monitorModeRecord.screenData.batteryPercentage)}}),_vm._v("% "),_c('img',{attrs:{"src":this.batteryIconName,"alt":""}})])])])])])]):_vm._e()])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-2/5 w-full"},[_c('span',{staticClass:"marrs-lock-icon h-16 w-7/10 mx-auto mt-2 block"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"button-slot"},[_c('div',{staticClass:"marrs-button mm-button-border-blue bg-white"},[_c('span',[_vm._v(" Unlock Screen ")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-1/3 h-full flex flex-row justify-center items-center"},[_c('img',{attrs:{"src":require("../../../assets/MARRS/MonitorMode/wifi-4.png"),"alt":""}})])}]

export { render, staticRenderFns }