import {Moment} from 'moment';
import {AsyncEither, AsyncNullSuccess, Either, Failure, nullSuccess, Success} from '@/errors/Either';
import {PumpSettingsFilter, PumpSettingsReportData} from '@/models/reports/PumpSettings';
import {Error} from '@/errors/Error';
import axios from 'axios';
import {PumpSettingsRecord} from '@/models/Devices/MARRS/Records';
import {convertToMomentTimezone} from '@/ts/TimezoneUtils';
import {saveDownloadedFile} from '@/ts/utilities';


export default class PatientPumpSettingsReportService {
    private readonly timezone: string;


    constructor(timezone: string) {
        this.timezone = timezone;
    }

    /**
     * Fetches recent settings changes for the patient's assigned pumps
     * @param patientId ID of the patient
     * @param startDate Start date of the report
     * @param endDate End date of the report
     */
    // tslint:disable-next-line:max-line-length
    public async fetchPumpSettingsReport(patientId: number, startDate: Moment | Date, endDate: Moment | Date): AsyncEither<PumpSettingsReportData, Error> {
        try {
            // tslint:disable-next-line:max-line-length
            const {data}: {data: PumpSettingsReportData} = await axios.get(process.env.VUE_APP_BACKEND_URI + '/patients/' + patientId + '/reports/pump-settings', {
                params: {
                    startDate: startDate.toISOString(),
                    endDate: endDate.toISOString(),
                },
            });
            data.pumpSettingsRecords.forEach((record: PumpSettingsRecord) => {
                record.timestamp = convertToMomentTimezone(this.timezone, record.timestamp as string);
            });
            return new Success(data);
        } catch (e) {
            return new Failure({
                message: 'Unable to fetch pump settings report',
            });
        }
    }

    /**
     * Exports the pump settings report as an Excel document
     * @param patientId ID of the patient
     * @param startDate Start date of the report
     * @param endDate End date of the report
     * @param pumpSettingsFilter Filters to apply to the export
     */
    // tslint:disable-next-line:max-line-length
    public async exportPumpSettingsReportCsv(patientId: number, startDate: Moment | Date, endDate: Moment | Date, pumpSettingsFilter: PumpSettingsFilter): AsyncNullSuccess<Error> {
        try {
            // tslint:disable-next-line:max-line-length
            const response = await axios.post(process.env.VUE_APP_BACKEND_URI + '/patients/' + patientId + '/reports/pump-settings/csv', pumpSettingsFilter,  {
                params: {
                    startDate: startDate.toISOString(),
                    endDate: endDate.toISOString(),
                },
                responseType: 'blob',
            });
            saveDownloadedFile(response, 'patient-pump-settings-report.xlsx');
            return nullSuccess();
        } catch (e) {
            return new Failure({
                message: 'Unable to fetch pump settings report',
            });
        }
    }

    /**
     * Exports the pump settings report as a PDF
     * @param patientId ID of the patient
     * @param startDate Start date of the report
     * @param endDate End date of the report
     * @param pumpSettingsFilter Filters to apply to the export
     */
    // tslint:disable-next-line:max-line-length
    public async exportPumpSettingsReportPdf(patientId: number, startDate: Moment | Date, endDate: Moment | Date, pumpSettingsFilter: PumpSettingsFilter): AsyncNullSuccess<Error> {
        try {
            // tslint:disable-next-line:max-line-length
            const response = await axios.post(process.env.VUE_APP_BACKEND_URI + '/patients/' + patientId + '/reports/pump-settings/pdf', pumpSettingsFilter,  {
                params: {
                    startDate: startDate.toISOString(),
                    endDate: endDate.toISOString(),
                },
                responseType: 'blob',
            });
            saveDownloadedFile(response, 'patient-pump-settings-report.pdf');
            return nullSuccess();
        } catch (e) {
            return new Failure({
                message: 'Unable to fetch pump settings report',
            });
        }
    }
}
