








import {Component, Prop, Vue} from "vue-property-decorator";

@Component
/**
 * This is a component for displaying text, it is primarily used for SLOT_LIST cells in a table
 */
export default class TextDisplay extends Vue {
    /**
     * Text to display
     */
    @Prop({type: String, default: ''}) value!: string;

    /**
     * Title to display if there's a title for the item
     */
    @Prop({type: String, default: ''}) title!: string;

    /**
     * Whether to display the items inline
     */
    @Prop({default: false}) inline!: boolean;

    /**
     * Whether the title is clickable
     */
    @Prop({default: false}) titleClickable!: boolean;

    /**
     * Whether the value text is clickable
     */
    @Prop({default: false}) valueClickable!: boolean;

}
