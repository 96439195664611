




















import {Component, Prop, Vue} from 'vue-property-decorator';
import {namespace} from "vuex-class";

const oidc = namespace('oidcStore');

/**
 * Creates the top nav bar for the application
 */
@Component
export default class TopNav extends Vue {

    @oidc.Action('signOutOidc') signOut!: any;

    /**
     * Wether to have the default slot apply flex grow
     */
    @Prop({default: false}) defaultSlotFlexGrow!: boolean;
}
