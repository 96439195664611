


























import {Component, Prop, Vue} from "vue-property-decorator";
import {
    PageData,
    PaginatedData,
    TableCellType,
    TableHeaderData,
    TableRowData
} from "../../table/cells/TableCellData";
import {FirmwareUpgradeJobStatus, MARRSPumpWithDetails} from "@/models/Devices/MARRS/MARRSPump";
import TextDisplay from "@/components/utility/TextDisplay.vue";
import {formatMoment} from "@/ts/TimezoneUtils";
import {Moment} from "moment";
import IconDisplay from "@/components/utility/IconDisplay.vue";
import {FilterData, FilterType, SearchData} from "@/ts/Filter";
import Table from "@/components/table/Table.vue";
import {Searchable} from "@/models/Searchable";
import {PatientAssignmentStatus} from "@/models/patients/PatientAssignmentStatus";
import {Certification, CertificationFilter} from "@/models/Devices/MARRS/Certification";
import ConnectCertificationService from '@/services/devices/connect/ConnectCertificationService';

/**
 * Renders a list of pumps
 */
@Component({
    components: {
        Table
    }
})
export default class PumpListTable extends Vue {
    /**
     * Current page of pump data
     */
    @Prop({default: () => {
            return {totalElements: 0, totalPages: 0, data: []}
        }}) pumpData!: PaginatedData<MARRSPumpWithDetails>;

    /**
     * Event bus for sending events to child table
     */
    @Prop() eventBus!: Vue;

    /**
     * Whether the table is sorted ascending
     */
    @Prop() sortedAsc!: boolean;

    /**
     * Field the table is currently sorted by
     */
    @Prop() currentSortKey!: string;

    /**
     * Current page size
     */
    pageSize = 30;

    /**
     * Current filter data
     */
    @Prop() filterData!: any;

    /**
     * Current value of the search field
     */
    @Prop() initialSearch!: string;

    /**
     * Whether the pump version request is pending
     */
    @Prop() loadingVersions!: boolean;

    /**
     * Array of current pump software versions
     */
    @Prop() versions!: FilterData[];

    connectCertificationService = new ConnectCertificationService(this.$store.state.auth.loggedInUser.timezone);

    /**
     * Table header definition
     */
    get tableHeader(): TableHeaderData[]{
        const vm = this;
        return [
            {
                sortable: false,
                sortKey: 'serialNumber',
                value: 'Serial number',
                widthClass: 'w-16/100',
            },
            {
                sortable: true,
                sortKey: 'patient',
                value: 'Assigned patient',
                widthClass: 'w-16/100',
                filterable: true,
                filterKey: 'patientFilter',
                filters: [
                    {
                        type: FilterType.MULTISELECT,
                        key: 'status',
                        active: vm.filterData.patient !== PatientAssignmentStatus.ASSIGNED_AND_UNASSIGNED,
                        enabled: true,
                        displayText: 'Filter by result',
                        data: {
                            multiselect: true,
                            activeItems: {
                                status: vm.filterData.patient !== PatientAssignmentStatus.ASSIGNED_AND_UNASSIGNED ? [vm.filterData.patient] : []
                            },
                            items: [
                                {
                                    value: PatientAssignmentStatus.ASSIGNED,
                                    label: 'Assigned',
                                    key: 'assigned'
                                },
                                {
                                    value: PatientAssignmentStatus.UNASSIGNED,
                                    label: 'No assignment',
                                    key: 'unassigned'
                                },
                            ]
                        }
                    }
                ]
            },
            {
                sortable: true,
                sortKey: 'lastConnection',
                value: 'Last connection',
                widthClass: 'w-23/100',
                filterable: true,
                filterKey: 'connectionFilter',
                filters: [
                    {
                        type: FilterType.CALENDAR,
                        key: 'dateRange',
                        active: vm.filterData.connectionStart != null,
                        enabled: true,
                        displayText: 'Filter by date',
                        data: {
                            initialRange: {
                                start: vm.filterData.connectionStart,
                                end: vm.filterData.connectionEnd,
                            }
                        }
                    }
                ]
            },
            {
                sortable: true,
                sortKey: 'certification',
                value: 'Last certification attempt',
                widthClass: 'w-23/100',
                filterable: true,
                filterKey: 'certificationFilter',
                filters: [
                    {
                        type: FilterType.MULTISELECT,
                        key: 'result',
                        active: vm.filterData.certificationStatus !== CertificationFilter.PASS_AND_FAIL,
                        enabled: true,
                        displayText: 'Filter by result',
                        data: {
                            multiselect: true,
                            activeItems: {
                                result: vm.filterData.certificationStatus !== CertificationFilter.PASS_AND_FAIL ? [vm.filterData.certificationStatus] : []
                            },
                            items: [
                                {
                                    value: CertificationFilter.PASS,
                                    label: 'Pass',
                                    key: 'pass'
                                },
                                {
                                    value: CertificationFilter.FAIL,
                                    label: 'Fail',
                                    key: 'fail'
                                },
                            ]
                        }
                    },
                    {
                        type: FilterType.CALENDAR,
                        key: 'dateRange',
                        active: vm.filterData.certificationStart != null,
                        enabled: true,
                        displayText: 'Filter by date',
                        data: {
                            initialRange: {
                                start: vm.filterData.certificationStart,
                                end: vm.filterData.certificationEnd,
                            }
                        }
                    }
                ]
            },
            {
                sortable: false,
                sortKey: 'swVersion',
                value: 'SW / UI version',
                widthClass: 'w-22/100',
                filterable: true,
                filterKey: 'versionFilter',
                filterLoading: this.loadingVersions,
                filters: [
                    {
                        type: FilterType.MULTISELECT,
                        key: 'swVersion',
                        active: vm.filterData.swVersions.length != 0,
                        enabled: true,
                        displayText: 'Filter by SW version',
                        data: {
                            multiselect: true,
                            activeItems: {
                                swVersion: vm.filterData.swVersions.length != 0 ? [...vm.filterData.swVersions] : []
                            },
                            items: this.versions
                        }
                    },
                    {
                        type: FilterType.MULTISELECT,
                        key: 'uiVersion',
                        active: vm.filterData.uiVersions.length != 0,
                        enabled: true,
                        displayText: 'Filter by UI version',
                        data: {
                            multiselect: true,
                            activeItems: {
                                uiVersion: vm.filterData.uiVersions.length != 0 ? [...vm.filterData.uiVersions] : []
                            },
                            items:this.versions
                        }
                    },
                ]
            },
        ]
    }

    /**
     * Transforms the list of pumps into an array of table rows
     */
    get tableData(): PageData{
        const vm = this;
        const rows: TableRowData[] = this.pumpData.data.map(pumpData => {
            let swVersion = "N/A";
            if(pumpData.latestFirmwareJob.status == FirmwareUpgradeJobStatus.PENDING){
                swVersion = "Downloading";
            } else if(pumpData.firmwareInfo != null){
                swVersion = pumpData.firmwareInfo.firmwareVersion + " / " + pumpData.firmwareInfo.graphicsVersion
            }
            const cells = [
                {
                    type: TableCellType.SLOT_LIST,
                    primaryValue:"",
                    components: [
                        {
                            primaryValue: TextDisplay,
                            componentOptions: {
                                value: pumpData.pump.serialNumber,
                                valueClickable: true
                            },
                            events: {
                                valueClick: () => {
                                    vm.$router.push({name: 'enteral-pump-home', params: {id: pumpData.pump.id.toString()}})
                                }
                            }
                        }
                    ]
                },
                {
                    type: TableCellType.NORMAL,
                    primaryValue: this.getAssignedPatientText(pumpData)
                },
                {
                    type: TableCellType.NORMAL,
                    primaryValue: formatMoment(pumpData.pump.lastConnectionTime as Moment)
                },
                {
                    type: TableCellType.SLOT_LIST,
                    primaryValue:"",
                    innerClass: 'flex flex-row justify-between items-center',
                    components: [
                        {
                            primaryValue: IconDisplay,
                            componentOptions: {
                                icon: `${pumpData.latestCertification != null && pumpData.latestCertification.certificationResult ? 'check-circle' : 'times-circle'}`,
                                class: `${(pumpData.latestCertification as Certification).id == null ? 'hidden' : ''}`
                            }
                        },
                        {
                            primaryValue: TextDisplay,
                            componentOptions: {
                                value: this.getCertificationTimestamp(pumpData),
                                class: 'px-8 w-4/5 text-center'
                            }
                        },
                        {
                            primaryValue: IconDisplay,
                            componentOptions: {
                                icon: 'download',
                                class: `${pumpData.latestCertification.id == null ? 'hidden' : 'cursor-pointer'} text-black text-opacity-60 hover:text-opacity-100 hover:bg-warm-gray-1 rounded-full w-7 h-7 cursor-pointer flex flex-row justify-center items-center`,
                                tooltip: 'Download certification report',
                            },
                            events: {
                                click: () => vm.downloadCertification(pumpData.pump.id, pumpData.latestCertification.id)
                            }
                        }
                    ]
                },
                {
                    type: TableCellType.NORMAL,
                    primaryValue: pumpData.pump.lastVersionUpdate != null ? (pumpData.pump.softwareVersion as number).toFixed(2) + "/" + (pumpData.pump.uiVersion as number).toFixed(2) : "N/A",
                },
            ];

            return {
                cells: cells,
                highlighted: false,
                indexKey: pumpData.pump.id
            }
        });

        return {
            numPages: this.pumpData.totalPages,
            totalElements: this.pumpData.totalElements,
            pageData: rows
        }
    }

    /**
     * Emits an event to tell the parent to fetch a new page
     * @param page
     */
    fetchNewPage(page: number){
        this.$emit('changePage', page);
    }

    /**
     * Emits an event to tell the parent the sort information has changed
     * @param message
     */
    sortData(message: any){
        this.$emit('sort', message);
    }

    /**
     * Emits an event to tell the parent the page size has changed
     * @param newPageSize
     */
    pageSizeChanged(newPageSize: number){
        this.$emit('pageSizeChanged', newPageSize);
        this.pageSize = newPageSize;
    }

    /**
     * Creates the text for the assignment patient column
     * If the user can see patients and the pump is assigned, it will return the patients name
     * If the user cannot see patients, the column will only say whether the pump is assigned
     * @param marrsPump
     */
    getAssignedPatientText(marrsPump: MARRSPumpWithDetails) {
        if(marrsPump.assignedPatient !== null) {
            return marrsPump.assignedPatient.firstName + " " + marrsPump.assignedPatient.lastName
        }

        if(marrsPump.assigned) {
            return "Assigned"
        }

        return "No patient assigned"
    }

    /**
     * Emits an event to tell the parent the search data has changed
     * @param searchString
     */
    async searchData(searchString: any){
        this.$emit('search', searchString);
    }

    /**
     * Array of fields that can be searched by
     */
    get searchTypes(): SearchData[] {
        let options = [
            {
                key: Searchable.SERIAL_NUMBER,
                value: 'Serial number'
            }
        ];
        if(this.$isAllowed(this.$Permission.PATIENT_VIEW)) {
            options.push({
                key: Searchable.PATIENT_NAME,
                value: 'Patient name'
            })
        }
        return options
    }

    /**
     * Formats the timestamp of the latest pump certification
     * @param pump
     */
    getCertificationTimestamp(pump: MARRSPumpWithDetails){
        if(pump.latestCertification == null || pump.latestCertification.id === null){
            return "N/A";
        }

        return formatMoment(pump.latestCertification.timestamp as Moment)
    }

    /**
     * Sends the request to download a pump's certification report
     * @param deviceId ID of the device to download the certification for
     * @param certId ID of the certification to download
     */
    async downloadCertification(deviceId: number, certId: number){
        const response = await this.connectCertificationService.fetchCertificationPdf(deviceId, certId);
        response.mapErr(() => {
            this.$addSnackbarMessage({
                message: 'There was an issue downloading the certification PDF. Please try again later or contact customer support.'
            })
        })
    }
}
