































import {Component, Prop, Vue} from 'vue-property-decorator';
    import {namespace} from "vuex-class";
    import {Hub, HubListRequest} from "@/models/Devices/Hubs/Hub";
import {
    PageData,
    PaginatedData,
    TableCellData,
    TableCellType,
    TableHeaderData, TableRowData
} from '../../table/cells/TableCellData';
    import Table from "@/components/table/Table.vue";
    import {HubActions} from "@/vuex/hubs";
    import { FilterType} from "@/ts/Filter";
    import Multiselect from "vue-multiselect";
    import {ButtonType} from "@/components/form/FormTypes";
import {Attribute} from "@/ts/permissions/Attribute";
    import DisableWirelessModal from "@/components/devices/hubs/DisableWirelessModal.vue";
    import EnableWirelessModal from "@/components/devices/hubs/EnableWirelessModal.vue";
    import {saveDownloadedFile} from "@/ts/utilities";
    import {formatMoment} from "@/ts/TimezoneUtils";
    import {Moment} from 'moment';
import TextDisplay from '@/components/utility/TextDisplay.vue';
import {Customer} from '@/models/customers/Customer';

const hubs = namespace('hubs');

/**
 * Renders a table of hubs
 */
@Component({
    components:{
        Table,
        Multiselect,
        DisableWirelessModal,
        EnableWirelessModal
    }
})
export default class HubTable extends Vue {

    @Prop() claimDeviceEventBus!: Vue;

    /**
     * Current list of hubs
     */
    @hubs.State('hubList') hubList!: PaginatedData<Hub> | null;
    @hubs.Action(HubActions.LIST_HUBS) listHubs!: (data: any) => any;
    @hubs.Action(HubActions.ENABLE_LIST_WIRELESS) enableWirelessAction!:(data: number) => any;
    @hubs.Action(HubActions.DISABLE_LIST_WIRELESS) disableWirelessAction!:(data: number) => any;

    buttonTypes = ButtonType;
    attributes = Attribute;

    /**
     * Whether the table is currently sorted ascending
     */
    sortedAsc = true;

    /**
     * Field the table is currently sorted by
     */
    currentSortKey = "connectionId";

    /**
     * Current value of the search field
     */
    searchString = "";

    /**
     * Current page size
     */
    pageSize = 30;

    /**
     * Current page
     */
    currentPage = 1;

    /**
     * Event bus for sending events to the child table
     */
    eventBus: Vue = new Vue();
    currentTypeFilter: number | null = null;
    @Prop({default: () => []}) customers!: Customer[];

    /**
     * Whether the disable wireless modal is showing
     */
    disableWirelessModalShowing = false;

    /**
     * Whether the enable wireless modal is showing
     */
    enableWirelessModalShowing = false;

    /**
     * Whether the list of hubs is currently loading
     */
    loading = false;

    hubId : number | null = null;
    hubSerialNumber = '';

    /**
     * Generates the table header
     * The enable/disable wireless header is determined by the user's permissions
     */
    get tableHeader(){
        const tableHeader: TableHeaderData[] = [
            {
                sortable: false,
                value: "Serial number",
                widthClass: `${this.$isAllowed(this.$Permission.WIRELESS_HUB_MANAGE_WIRELESS) ? 'w-1/5' : 'w-1/4'}`,
            },
            {
                sortable: false,
                value: "Type",
                widthClass: `${this.$isAllowed(this.$Permission.WIRELESS_HUB_MANAGE_WIRELESS) ? 'w-1/10' : 'w-3/20'}`,
                filterable: true,
                filterKey: "type",
                filters: [
                    {
                        type: FilterType.MULTISELECT,
                        key: 'type',
                        active: this.currentTypeFilter != null,
                        enabled: true,
                        displayText: 'Filter by type',
                        data: {
                            multiselect: false,
                            activeItems: {
                                type: this.currentTypeFilter == null ? [] : [this.currentTypeFilter],
                            },
                            items: [
                                {
                                    value: 0,
                                    label: 'Wifi',
                                    key: 'wifi'
                                },
                                {
                                    value: 1,
                                    label: '3G',
                                    key: '3g'
                                },
                                {
                                    value: 2,
                                    label: 'LTE',
                                    key: 'lte'
                                },
                            ]
                        }
                    }
                ]
            },
            {
                sortable: true,
                value: "Wireless ID",
                widthClass: `${this.$isAllowed(this.$Permission.WIRELESS_HUB_MANAGE_WIRELESS) ? 'w-1/5' : 'w-1/4'}`,
                sortKey: 'connectionId'
            },
        ];

        if(this.$isAllowed(this.$Permission.WIRELESS_HUB_MANAGE_WIRELESS)){
            tableHeader.push({
                sortable: true,
                value: "Wireless enabled",
                widthClass: "w-3/20",
                sortKey: 'enabled'
            },)
        }

        tableHeader.push(...[
            {
                sortable: true,
                value: "Connection",
                widthClass: 'w-3/20',
                sortKey: 'connectionStatus'
            },
            {
                sortable: true,
                value: "Last message",
                widthClass: "w-1/5",
                sortKey: 'lastMessageTimestamp'
            }
        ]);

        return tableHeader;
    }

    /**
     * Transforms the list hubs into an array of table columns
     */
    get tableData(): PageData {
        if(this.hubList == null){
            return {
                numPages: 1,
                pageData: [],
                totalElements: 0
            };
        }

        let hubTableData: TableRowData[] = this.hubList.data.map((hub) => {
            let connectionIdParts = hub.connectionId.split("-");
            const row = [
                {
                    type: TableCellType.SLOT_LIST,
                    primaryValue: '',
                    components: [
                        {
                            primaryValue: TextDisplay,
                            componentOptions: {
                                value: hub.serialNumber,
                                valueClickable: true
                            },
                            events: {
                                valueClick: () => {
                                    this.$router.push({name: 'hub-view', params: {id: hub.id.toString()}})
                                }
                            }
                        }
                    ]
                },
                {
                    type: TableCellType.NORMAL,
                    primaryValue: hub.productName
                },
                {
                    type: TableCellType.NORMAL,
                    primaryValue: connectionIdParts[2] + "-" + connectionIdParts[3]
                },
            ] as TableCellData[];

            if(this.$isAllowed(this.$Permission.WIRELESS_HUB_MANAGE_WIRELESS)){
                row.push({
                    type: TableCellType.SLOT,
                    primaryValue: `ToggleSwitch`,
                    componentOptions: {
                        value: hub.enabled,
                        name: "",
                        events: {
                            change: () => {hub.enabled ? this.prepareForDisableWireless(hub.id, hub.serialNumber) : this.prepareForEnableWireless(hub.id, hub.serialNumber)},
                        }
                    }
                })
            }

            row.push(...[
                {
                    type: TableCellType.NORMAL,
                    primaryValue: hub.connectionStatus ? "Connected" : "Disconnected"
                },
                {
                    type: TableCellType.NORMAL,
                    primaryValue: hub.lastMessageTimestamp != null ? formatMoment(hub.lastMessageTimestamp as Moment) : 'N/A'
                },
            ]);

            return {
                cells: row,
                indexKey: hub.id
            };
        });

        return {
            numPages: this.hubList.totalPages,
            pageData: hubTableData,
            totalElements: this.hubList.totalElements
        };
    }

    /**
     * Fetches the first page of hubs on page load
     * Sets up event handler to fetch the first page on reload
     */
    async mounted() {
        this.currentSortKey = "connectionId";
        this.sortedAsc = true;
        await this.fetchNewPage(1);
        this.claimDeviceEventBus.$on('reload', () => {
            this.fetchNewPage(1)
        })
    }

    /**
     * Updates the sort information on table sort and refetches the first page
     * @param message
     */
    async sortData(message: any){
        this.currentSortKey = message.sortKey;
        this.sortedAsc = message.sortedAsc;
        await this.fetchNewPage(1);
    }

    /**
     * Updates the page size on page size change and refetches the first page
     * @param newPageSize
     */
    async pageSizeChanged(newPageSize: number){
        this.pageSize = newPageSize;
        await this.fetchNewPage(1);
    }

    /**
     * Updates the search string on search and refetches the first page
     * @param searchString
     */
    async searchData(searchString: string){
        this.searchString = searchString;
        await this.fetchNewPage(1);
    }

    /**
     * Sends the request to fetch a specific page of hubs
     * @param pageNum Number of the page to fetch
     */
    async fetchNewPage(pageNum: number){
        let request: HubListRequest = {
            search: this.searchString,
            sort: this.currentSortKey,
            asc: this.sortedAsc,
            page: pageNum,
            size: this.pageSize
        };
        this.$emit('requestUpdated', request);

        if(this.currentTypeFilter != null){
            request.type = this.currentTypeFilter as number
        }
        await Promise.all([
            this.listHubs(request),
            new Promise(function(resolve) {setTimeout(resolve, 1000)})
        ]);
        pageNum == 1 ? this.eventBus.$emit('dataReset') : this.eventBus.$emit('dataLoaded');
    }

    /**
     * Shows the enable wireless modal
     * @param id
     * @param serialNumber
     */
    prepareForEnableWireless(id: number, serialNumber: string) {
        this.enableWirelessModalShowing = true;
        this.hubId = id;
        this.hubSerialNumber = serialNumber;
    }

    /**
     * Shows the disable wireless modal
     * @param id
     * @param serialNumber
     */
    prepareForDisableWireless(id: number, serialNumber: string) {
        this.disableWirelessModalShowing = true;
        this.hubId = id;
        this.hubSerialNumber = serialNumber;
    }

    /**
     * Updates the selected hub types on a filter change
     * @param data
     */
    async handleTypeFilter(data: any){
        if((data.type as any[]).length > 0){
            this.currentTypeFilter = data.type[0]
        }else{
            this.currentTypeFilter = null;
        }
        await this.fetchNewPage(1);
    }

    /**
     * Resets the hub filters
     */
    handleClearAllFilters(){
        this.currentTypeFilter = null;
    }
}
