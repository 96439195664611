import {Device} from '@/models/Devices/Device';
import {Certification} from '@/models/Devices/MARRS/Certification';
import {Moment} from 'moment';
import {Patient} from '@/models/patients/Patient';

/**
 * Represents a single connect pump
 */
export interface MARRSPump extends Device{
    id: number;
    serialNumber: string;
    connectionId: string;
    serialNumberReversed: string;
    deviceType: number;
    registrationTime: string | Moment;
    lastConnectionTime: string | Moment;
    firstUse: string | Moment;
    softwareVersion: number | null;
    uiVersion: number | null;
    lastVersionUpdate: string | Moment | null;
}

/**
 * Represents a connect pump with all of its details
 */
export interface MARRSPumpWithDetails {
    pump: MARRSPump;
    firmwareInfo: FirmwareInfo;
    latestCertification: Certification;
    assignedPatient: Patient | null;
    latestFirmwareJob: FirmwareUpgradeJob;
    assigned: boolean;
}

export interface FirmwareInfo {
    firmwareVersion: string;
    graphicsVersion: string;
    wirelessVersion: string | null;
    lastUpgrade: string | Moment;
}

/**
 * Status of a request to upgrade a pump's software
 */
export enum FirmwareUpgradeJobStatus {
    PENDING = 0,
    COMPLETED = 1,
    ERROR = 2,
    CANCELLED = 3,
}

/**
 * Represents an attempt to upgrade the software on a connect pump
 */
export interface FirmwareUpgradeJob {
    id: number;
    step: number;
    status: FirmwareUpgradeJobStatus;
    swVersion: number;
    uiVersion: number;
}

/**
 * Represents the number of pumps waiting to go to each state
 */
export interface FirmwareJobCount {
    stepTwoCount: number;
    retryCount: number;
}
