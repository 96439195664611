













    import {Component, Prop, Vue} from 'vue-property-decorator';
import {ButtonType} from '@/components/form/FormTypes';
import {namespace} from 'vuex-class';
import {FeedPrescription} from '@/models/patients/Patient';
import PrescriptionManagementModal from '@/components/patients/prescriptions/PrescriptionManagementModal.vue';
import {ModalMutations} from '@/vuex/modal';
import {PatientMutations} from '@/vuex/patients';
import {SnackbarActions} from '@/vuex/snackbar';
import {SnackbarRequest} from '@/models/Snackbar';
    import {PatientHomeRouteOptions} from '@/models/patients/PatientHome';

const patients = namespace('patients');

/**
 * Renders a quick link providing quick access to a patient's prescriptions
 */
@Component({
    components: {
        PrescriptionManagementModal,
    }
})
export default class PatientPrescriptionQuickLink extends Vue {
    buttonType = ButtonType;

    @patients.State(state => state.homePage.patient.id) patientId!: number;
    @patients.State(state => state.homePage.patient.feedPrescriptions) feedPrescriptions!: FeedPrescription[];

    /**
     * Used to trigger the prescription modal appearing on mount
     */
    @Prop({default: ''}) routeOption!: string;

    mounted() {
        if(this.routeOption === PatientHomeRouteOptions.PRESCRIPTIONS){
            this.showPrescriptionModal();
        }
    }

    /**
     * Returns the daily volume of the current prescription if it exists
     */
    get currentPrescriptionAmount(){
        if(this.feedPrescriptions.length > 0){
            return this.feedPrescriptions[0].dailyVolume;
        }

        return null;
    }

    /**
     * Emits an event to trigger the parent to show the prescription modal
     */
    showPrescriptionModal(){
        this.$emit('addPrescription')
    }
}
