

















































import {Component, Vue, Watch} from 'vue-property-decorator';
import MARRSPumpScreen from '@/components/MARRS/monitor-mode/MARRSPumpScreen.vue';
import {namespace} from 'vuex-class';
import {Patient, PatientDeviceAssignment} from '@/models/patients/Patient';
import WirelessDiagnosticsModal from '@/components/devices/marrs/WirelessDiagnosticsModal.vue';
import {ModalMutations} from '@/vuex/modal';
import {ButtonType} from '@/components/form/FormTypes';
import Spinner from '@/components/form/Spinner.vue';
import {PumpSettingsRecord, PumpSettingsType} from '@/models/Devices/MARRS/Records';
import moment, {Moment} from 'moment';
import {formatMomentDateOnly} from '@/ts/TimezoneUtils';
import {PatientGetters} from '@/vuex/patients';
import DeviceConnectionStatus from '@/components/devices/DeviceConnectionStatus.vue';
import ConnectSettingsReportService from '@/services/devices/connect/reports/ConnectSettingsReportService';

const patients = namespace('patients');
const modal = namespace('modal');

/**
 * Renders a card describing the status of the device assigned to a patient
 * and it's most recent settings change
 */
@Component({
    components: {
        MARRSPumpScreen,
        WirelessDiagnosticsModal,
        Spinner,
        DeviceConnectionStatus,
    }
})
export default class DeviceStatusCard extends Vue {
    @patients.State(state => state.homePage.patient) patient!: Patient;
    @patients.State(state => state.homePage.deviceConnectionPending) connectionPending!: boolean;
    @patients.State(state => state.homePage.deviceConnected) connected!: boolean;
    @modal.Mutation(ModalMutations.CHANGE_MODAL_STATE) changeModalState!: (state: boolean) => void;
    @patients.State(state => state.homePage.patient.assignments) patientAssignments!: PatientDeviceAssignment[];
    @patients.Getter(PatientGetters.GET_LATEST_HOMEPAGE_ASSIGNMENT) latestAssignment!: PatientDeviceAssignment | null;
    @patients.Getter(PatientGetters.GET_FUTURE_ASSIGNMENT_START_DATE) futureAssignmentStartDate!: string | null;

    /**
     * Whether the wireless diagnostics modal is showing
     */
    modalShowing = false;

    /**
     * Whether the request to fetch pump settings is loading
     */
    loadingPumpSettings = false;

    buttonTypes = ButtonType;

    connectSettingsReportService = new ConnectSettingsReportService(this.$store.state.auth.loggedInUser.timezone);

    /**
     * Latest pump settings change if one exists
     */
    latestSettingsRecord: PumpSettingsRecord | null = null;


    mounted(): void {
        this.fetchLatestSettingsRecord();
    }

    /**
     * If a patient's assignments change, refresh the latest pump settings record
     */
    @Watch('patientAssignments')
    assignmentsChanged() {
        if(!this.loadingPumpSettings) {
            this.fetchLatestSettingsRecord();
        }
    }

    /**
     * Show the wireless diagnostics modal
     */
    showModal(){
        this.changeModalState(true);
        this.modalShowing = true;
    }

    /**
     * Hide the wireless diagnostic modal
     */
    closeModal(){
        this.modalShowing = false;
        this.changeModalState(false);
    }

    /**
     * Sends the request to fetch the latest pump settings record
     */
    async fetchLatestSettingsRecord() {
        if(this.latestAssignment === null){
            return;
        }
        const response = await this.connectSettingsReportService.fetchSettingsReportForPump(this.latestAssignment.startDate as Moment, moment.tz(this.$store.state.auth.loggedInUser.timezone), this.latestAssignment.device.id);
        response.map((pumpSettingsData) => {
            if(pumpSettingsData.pumpSettingsRecords.length > 0){
                this.latestSettingsRecord = pumpSettingsData.pumpSettingsRecords[0];
            }
        })
    }

    /**
     * Determines the feed mode of the latest pump settings record
     * if the record exists
     */
    get latestSettingsRecordFeedMode() {
        if(this.latestSettingsRecord === null) {
            return ''
        }

        switch(this.latestSettingsRecord.feedMode) {
            case PumpSettingsType.CONTINUOUS_DOSE:
                return 'Dose'
            case PumpSettingsType.BOLUS:
                return 'Bolus'
            case PumpSettingsType.CONTINUOUS_FEED:
                return 'Continuous'
        }
    }

    /**
     * Formats the date of the latest pump settings change
     */
    get latestSettingsRecordDate() {
        if(this.latestSettingsRecord === null) {
            return ''
        }

        return formatMomentDateOnly(this.latestSettingsRecord.timestamp as Moment);
    }

}
