
import {Component, Prop, Vue} from "vue-property-decorator";
import {Line, mixins as chartMixins} from 'vue-chartjs'
import {mixins} from "vue-class-component";
import {StatusRecord} from "@/models/Devices/MARRS/Records";
import {Moment} from "moment";
import {groupBy, sumBy} from "@/ts/utilities";
import {formatMomentDateOnly} from "@/ts/TimezoneUtils";
import {FeedHistoryReport} from "@/models/reports/FeedHistory";
import moment from 'moment';

/**
 * Renders a line chart of feeding history data
 */
@Component({
    extends: Line
})
export default class FeedingHistoryChart extends mixins(Line, chartMixins.reactiveData) {

    /**
     * Feeding history data
     */
    @Prop() initialData!: FeedHistoryReport;

    /**
     * Gradient to use for the lines
     */
    @Prop() gradient!: any;

    created(){
        let groupedData = groupBy(this.initialData.statusRecords, (record: StatusRecord) => formatMomentDateOnly(record.timestamp as Moment));
        // console.log(groupedData.entries())

        let modifiedRecords: any[] = [];

        for(const [key, value] of groupedData.entries()){
            modifiedRecords.push({
                x: moment().year(value[0].timestamp.year()).month(value[0].timestamp.month()).date(value[0].timestamp.date()).startOf('day'),
                y: (sumBy(value, (record: StatusRecord) => record.amountFedThisHour)/10)
            });
        }
        modifiedRecords.sort((a, b) => (a.x as Moment).diff(b.x as Moment));

        //@ts-ignore
        this.chartData = {
            datasets: [
                {
                    label: 'Feed History',
                    data: modifiedRecords,
                    borderColor: '#745cc6',
                    pointBorderColor: '#745cc6',
                    pointBackgroundColor: 'white',
                    pointHoverBackgroundColor: '#745cc6',
                    pointRadius: 4,
                    spanGaps: false,
                    fill: 'origin',
                    backgroundColor: 'rgba(116, 92, 198, .15)',
                    lineTension: 0
                }
            ]
        }
    }

    get options() {
        return {
            scales: {
                xAxes: [
                    {
                        type: 'time',
                        distribution: 'linear',
                        time: {
                            unit: (this.initialData.endDate as Moment).diff(this.initialData.startDate as Moment, 'day') <= 8 ? 'day': 'week',
                            displayFormats: {
                                day: 'DD MMM YYYY',
                                week: 'DD MMM YYYY',
                                month: 'MMM YYYY',
                                quarter: 'MMM YYYY'
                            },
                            tooltipFormat: 'DD MMM YYYY',
                        },
                        ticks: {
                            min: this.initialData.startDate,
                            max: this.initialData.endDate,
                            fontSize: 10,
                            fontColor: 'rgba(0, 0, 0, .6)'
                        },
                        gridLines: {
                            display: false,
                        }
                    },
                ],
                yAxes: [
                    {
                        ticks: {
                            callback: function(value: any, index: number, values: any){
                                return value + ' mL';
                            },
                            min: 0
                        },
                        gridLines: {
                            color: 'rgba(0, 0, 0, .1)'
                        }
                    }
                ]
            },
            legend: {
              onClick: false,
              display: false
            },
            maintainAspectRatio: false,
            responsive: true,
            tooltips: {
                callbacks: {
                    label: function(tooltipItem: any, data: any) {
                        return "Volume delivered: " + tooltipItem.yLabel + " mL";
                    }
                }
            }
        };
    }

    mounted () {
        // Overwriting base render method with actual data.
        //@ts-ignore
        this.renderChart(this.chartData, this.options);
    }
}
