





































    import {Component, Prop, Vue} from 'vue-property-decorator';
import {ButtonType} from '@/components/form/FormTypes';
import {namespace} from 'vuex-class';
import {Patient, PatientDeviceAssignment} from '@/models/patients/Patient';
import {formatMomentDateOnly} from '@/ts/TimezoneUtils';
import moment, {Moment} from 'moment';
import {ModalMutations} from '@/vuex/modal';
import DeviceAssignmentModal from '@/components/patients/DeviceAssignmentModal.vue';
import {SnackbarActions} from '@/vuex/snackbar';
import {SnackbarRequest} from '@/models/Snackbar';
    import {PatientGetters, PatientMutations} from '@/vuex/patients';
    import {PatientHomeRouteOptions} from '@/models/patients/PatientHome';
    import IconDisplay from '@/components/utility/IconDisplay.vue';

const patients = namespace('patients');
const modal = namespace('modal');
const snackbar = namespace('snackbar');

/**
 * Renders a quick link describing the patient's current assignment status
 */
@Component({
    components: {
        DeviceAssignmentModal,
        IconDisplay,
    }
})
export default class PatientAssignmentQuickLink extends Vue {
    buttonType = ButtonType;
    @patients.State(state => state.homePage.patient.id) patientId!: number;
    @patients.State(state => state.homePage.patient) patient!: Patient;
    @patients.State(state => state.homePage.patient.assignments) assignments!: PatientDeviceAssignment[];
    @patients.Getter(PatientGetters.GET_LATEST_HOMEPAGE_ASSIGNMENT) latestAssignment!: PatientDeviceAssignment | null;
    @patients.Getter(PatientGetters.GET_FUTURE_ASSIGNMENT_START_DATE) futureAssignmentStartDate!: string | null;

    /**
     * Used to trigger the assignment modal opening on mount
     */
    @Prop({default: ''}) routeOption!: string;

    modalShowing = false;

    mounted() {
        if(this.routeOption === PatientHomeRouteOptions.ASSIGNMENTS){
            this.showAssignmentModal();
        }
    }

    /**
     * Formats the start date of the latest assignment
     */
    get assignedSince(){
        if (this.latestAssignment !== null) {
            return formatMomentDateOnly(this.latestAssignment.startDate as Moment)
        }

        return null;
    }

    /**
     * Emits an event to have the parent show the assignment modal
     */
    showAssignmentModal() {
        this.$emit('addAssignment')
    }
}
