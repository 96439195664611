import {Moment} from 'moment';
import {PumpSettingsFilter, PumpSettingsReportData} from '@/models/reports/PumpSettings';
import axios from 'axios';
import {PumpSettingsRecord} from '@/models/Devices/MARRS/Records';
import {convertToMomentTimezone} from '@/ts/TimezoneUtils';
import {saveDownloadedFile} from '@/ts/utilities';
import {AsyncEither, AsyncNullSuccess, Failure, nullSuccess, Success} from '@/errors/Either';
import {Error} from '@/errors/Error';


export default class ConnectSettingsReportService {
    private readonly timezone: string;


    constructor(timezone: string) {
        this.timezone = timezone;
    }

    /**
     * Fetches a report of recent pump settings changes
     * @param startDate Start date of the report
     * @param endDate End date of the report
     * @param pumpId ID of the pump
     */
    // tslint:disable-next-line:max-line-length
    public async fetchSettingsReportForPump(startDate: Moment | Date, endDate: Moment | Date, pumpId: number): AsyncEither<PumpSettingsReportData, Error> {
        const request = {
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString(),
        };
        try {
            const {data}: {data: PumpSettingsReportData} =
                // tslint:disable-next-line:max-line-length
                await axios.get(process.env.VUE_APP_BACKEND_URI + '/devices/' + pumpId + '/reports/pump-settings-report', {
                    params: request,
                });

            data.pumpSettingsRecords.forEach((record: PumpSettingsRecord) => {
                record.timestamp = convertToMomentTimezone(this.timezone, record.timestamp as string);
            });

            return new Success(data);
        } catch (e) {
            return new Failure({
                message: 'Unable to fetch pump settings data',
            });
        }
    }

    /**
     * Exports the list of recent pump settings changes as an Excel document
     * @param startDate Start date of the report
     * @param endDate End date of the report
     * @param pumpId ID pf the pump
     * @param pumpSettingsFilter Filters to apply to the export
     */
    // tslint:disable-next-line:max-line-length
    public async exportSettingsReportForPumpAsExcel(startDate: Moment | Date, endDate: Moment | Date, pumpId: number, pumpSettingsFilter: PumpSettingsFilter): AsyncNullSuccess<Error> {
        const request = {
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString(),
        };
        try {
            const response =
                // tslint:disable-next-line:max-line-length
                await axios.post(process.env.VUE_APP_BACKEND_URI + '/devices/' + pumpId + '/reports/pump-settings-report/csv', pumpSettingsFilter,  {
                    params: request,
                    responseType: 'blob',
                });
            saveDownloadedFile(response, 'pump-settings-report.xlsx');
            return nullSuccess();
        } catch (e) {
            return new Failure({
                message: 'Unable to export pump settings data',
            });
        }
    }

    /**
     * Exports the list of recent pump settings changes as a PDF
     * @param startDate Start date of the report
     * @param endDate End date of the report
     * @param pumpId ID pf the pump
     * @param pumpSettingsFilter Filters to apply to the export
     */
    // tslint:disable-next-line:max-line-length
    public async exportSettingsReportForPumpAsPdf(startDate: Moment | Date, endDate: Moment | Date, pumpId: number, pumpSettingsFilter: PumpSettingsFilter): AsyncNullSuccess<Error> {
        const request = {
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString(),
        };
        try {
            const response =
                // tslint:disable-next-line:max-line-length
                await axios.post(process.env.VUE_APP_BACKEND_URI + '/devices/' + pumpId + '/reports/pump-settings-report/pdf', pumpSettingsFilter,  {
                    params: request,
                    responseType: 'blob',
                });
            saveDownloadedFile(response, 'pump-settings-report.pdf');
            return nullSuccess();
        } catch (e) {
            return new Failure({
                message: 'Unable to export pump settings data',
            });
        }
    }
}
