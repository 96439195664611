import {OidcSettings} from 'vuex-oidc';

const oidcSettings: OidcSettings = {
    authority: process.env.VUE_APP_OIDC_AUTHORITY,
    clientId: 'CFP Frontend',
    redirectUri: process.env.VUE_APP_OIDC_REDIRECT,
    responseType: 'id_token token',
    scope: 'openid profile email',
    silentRedirectUri: process.env.VUE_APP_SILENT_RENEW_URI,
    automaticSilentRenew: true,
    automaticSilentSignin: false,
    postLogoutRedirectUri: process.env.VUE_APP_LOGOUT_REDIRECT,
};

export default oidcSettings;
