export enum QueueState {
    WAITING_FOR_CONNECTION,
    READY_FOR_REGISTRATION,
    REGISTERED,
    ERROR
}

export default interface HubQueueStatus {
    serialNumber: string;
    connectionId: string;
    isConnected: boolean;
    state: QueueState;
    error?: string;
}
