








import {Component, Vue} from "vue-property-decorator";
    import {Permission} from "@/ts/permissions/Permission";
    import {namespace} from "vuex-class";
    import {CustomerMutations} from "@/vuex/customers";
import MaintenanceModeService from '@/services/MaintenanceModeService';

const customers = namespace('customers');

/**
 * Renders a notice indicating if maintenance mode is currently active for the application
 */
@Component
export default class MaintenanceModeStatus extends Vue {

    @customers.State('maintenanceModeActive') maintenanceModeActive!: boolean;
    @customers.Mutation(CustomerMutations.SET_MAINTENANCE_MODE_ACTIVE) setMaintenanceMode!: any;

    timerId: number | null = null;

    maintenanceModeService = new MaintenanceModeService(this.$store.state.auth.loggedInUser.timezone);

    /**
     * Fetches the updated maintenance mode status on component render
     * Sets a timer to perform the check every 10 minutes
     */
    async mounted(){
        if(this.$isAllowed(Permission.MANAGE_MAINTENANCE_MODE)){
            await this.updateMaintenanceModeStatus();
            //Run every 10 minutes
            this.timerId = setInterval(() => this.updateMaintenanceModeStatus, 600000)
        }
    }

    /**
     * Fetches the updated maintenance mode status
     */
    async updateMaintenanceModeStatus(){
        const response = await this.maintenanceModeService.fetchMaintenanceModeStatus()
        response.map((data) => {
            if(data.id != null){
                this.setMaintenanceMode(true);
            }else{
                this.setMaintenanceMode(false);
            }
        }).mapErr(() => {
            this.$addSnackbarMessage({
                message: 'There was an issue updating the status of maintenance mode. Try again later or contact customer support.',
            });
        })
    }

    /**
     * On component destroy, gets rid of the timer for checking
     * maintenance mode status if it exists
     */
    beforeDestroy(){
        if(this.timerId != null){
            clearInterval(this.timerId);
        }
    }
}
