import {Moment} from 'moment';
import axios from 'axios';
import {StatusRecord} from '@/models/Devices/MARRS/Records';
import {convertToMomentTimezone} from '@/ts/TimezoneUtils';
import {saveDownloadedFile} from '@/ts/utilities';
import {FeedHistoryExportData, FeedHistoryReport} from '@/models/reports/FeedHistory';
import {AsyncEither, AsyncNullSuccess, Either, Failure, nullSuccess, NullSuccess, Success} from '@/errors/Either';
import {Error} from '@/errors/Error';


export default class ConnectFeedingHistoryReportService {
    private readonly timezone: string;


    constructor(timezone: string) {
        this.timezone = timezone;
    }

    /**
     * Fetches the feeding history data for a single pump
     * @param startDate Start date of the report
     * @param endDate End date of the report
     * @param pumpId ID of the pump to fetch the report for
     */
    // tslint:disable-next-line:max-line-length
    public async fetchFeedingHistoryReportForPump(startDate: Moment | Date, endDate: Moment | Date, pumpId: number): AsyncEither<FeedHistoryReport, Error> {
        const request = {
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString(),
        };
        try {
            // tslint:disable-next-line:max-line-length
            const {data}: {data: FeedHistoryReport} = await axios.get(process.env.VUE_APP_BACKEND_URI + '/devices/' + pumpId + '/reports/feed-history-report', {
                params: request,
            });
            data.statusRecords.forEach((record: StatusRecord) => {
                record.timestamp = convertToMomentTimezone(this.timezone, record.timestamp as string);
            });
            data.startDate = convertToMomentTimezone(this.timezone, data.startDate as string);
            data.endDate = convertToMomentTimezone(this.timezone, data.endDate as string);
            return new Success(data);
        } catch (e) {
            return new Failure({
                message: 'Failed to fetch feeding history',
            });
        }
    }

    /**
     * Exports the pump feeding history as an Excel document
     * @param startDate Start date of the report
     * @param endDate End date of the report
     * @param pumpId ID of the pump
     */
    // tslint:disable-next-line:max-line-length
    public async exportFeedingHistoryReportAsExcel(startDate: Moment | Date, endDate: Moment | Date, pumpId: number): AsyncNullSuccess<Error> {
        const request = {
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString(),
        };
        try {
            // tslint:disable-next-line:max-line-length
            const response = await axios.get(process.env.VUE_APP_BACKEND_URI + '/devices/' + pumpId + '/reports/feed-history-report/csv', {
                params: request,
                responseType: 'blob',
            });
            saveDownloadedFile(response, 'pump-feeding-history.xlsx');
            return nullSuccess();
        } catch (e) {
            return new Failure({
                message: 'Unable to export feeding history report',
            });
        }
    }

    /**
     * Exports the pump feeding history as a PDF
     * @param startDate Start date of the report
     * @param endDate End date of the report
     * @param pumpId ID of the pump
     * @param feedHistoryExportData Graph image data to include in the PDF
     */
    // tslint:disable-next-line:max-line-length
    public async exportFeedingHistoryReportAsPdf(startDate: Moment | Date, endDate: Moment | Date, pumpId: number, feedHistoryExportData: FeedHistoryExportData): AsyncNullSuccess<Error> {
        const request = {
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString(),
        };
        try {
            // tslint:disable-next-line:max-line-length
            const response = await axios.post(process.env.VUE_APP_BACKEND_URI + '/devices/' + pumpId + '/reports/feed-history-report/pdf', feedHistoryExportData, {
                params: request,
                responseType: 'blob',
            });
            saveDownloadedFile(response, 'pump-feeding-history.pdf');
            return nullSuccess();
        } catch (e) {
            return new Failure({
                message: 'Unable to export feeding history report',
            });
        }
    }
}
