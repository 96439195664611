import {AsyncEither, AsyncNullSuccess, Either, Failure, nullSuccess, Success} from '@/errors/Either';
import {Error} from '@/errors/Error';
import axios from 'axios';
import {saveDownloadedFile} from '@/ts/utilities';


export default class PatientFilesService {
    private readonly timezone: string;

    constructor(timezone: string) {
        this.timezone = timezone;
    }

    /**
     * Fetches the patient files for a given patient
     * @param patientID ID of the patient
     */
    public async fetchPatientFiles(patientID: number): AsyncEither<string[], Error> {
        try {
            // tslint:disable-next-line:max-line-length
            const {data}: {data: string[]} = await axios.get(process.env.VUE_APP_BACKEND_URI + '/patients/' + patientID + '/files');
            return new Success(data);
        } catch (e) {
            return new Failure({
                message: 'Unable to fetch patient files',
            });
        }
    }

    /**
     * Uploads a new patient file for a patient
     * @param patientId ID of the patient
     * @param fileData File to upload
     */
    public async createPatientFile(patientId: number, fileData: any): AsyncNullSuccess<Error>  {
        try {
            const formData = new FormData();
            formData.append('file', fileData);
            await axios.post(process.env.VUE_APP_BACKEND_URI + '/patients/' + patientId + '/files', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            return nullSuccess();
        } catch (e) {
            let message = 'Unable to create patient file';
            if (e.response?.data?.errors?.length !== undefined && e.response.data.errors.length > 0) {
                message = e.response.data.errors[0];
            }
            return new Failure({
                message,
            });
        }
    }

    /**
     * Downloads a specific patient file
     * @param patientId ID of the patient
     * @param filename Name of the file
     */
    public async downloadPatientFile(patientId: number, filename: string): AsyncNullSuccess<Error> {
        try {
            // tslint:disable-next-line:max-line-length
            const response = await axios.get(process.env.VUE_APP_BACKEND_URI + '/patients/' + patientId + '/files/' + filename, {
                responseType: 'blob',
            });
            saveDownloadedFile(response, 'patient-file.pdf');
            return nullSuccess();
        } catch (e) {
            return new Failure({
                message: 'Unable to download requested file',
            });
        }
    }

    /**
     * Deletes a patient file
     * @param patientId ID of the patient
     * @param filename Name of file
     */
    public async deletePatientFile(patientId: number, filename: string): AsyncNullSuccess<Error> {
        try {
            await axios.delete(process.env.VUE_APP_BACKEND_URI + '/patients/' + patientId + '/files/' + filename);
            return nullSuccess();
        } catch (e) {
            return new Failure({
                message: 'Unable to remove patient file',
            });
        }
    }
}
