













































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {Validations} from '@/ts/validation-decorator';
import {maxLength, maxValue, minLength, minValue, required} from 'vuelidate/lib/validators';
import {RadioButtonData} from '@/components/form/FormTypes';
import moment from 'moment';

/**
 * Renders a form for editing patient details
 */
@Component
export default class PatientDetails extends Vue {
    /**
     * Current value of the first name field
     */
    @Prop() firstName!: string;

    /**
     * Current value of the patient's last name
     */
    @Prop() lastName!: string;

    /**
     * Current value of the patient id
     */
    @Prop() patientId!: string;

    /**
     * Current value of the date of birth field
     */
    @Prop() dateOfBirth!: Date;

    /**
     * Current value of the patient's notes field
     */
    @Prop() notes!: string;

    /**
     * Current value of the sex field
     */
    @Prop() sex!: string;

    /**
     * Emits an event when a field changes
     * Updates the fields form validations
     * @param field Field that was updated
     * @param data New field value
     */
    emitEvent(field: string, data: string) {
        this.$emit(field, data);
        this.touchField(field)
    }

    /**
     * Form field validation restrictions
     */
    @Validations()
    validations() {
        return {
            firstName: {
                required,
                maxLength: maxLength(30)
            },
            lastName: {
                required,
                maxLength: maxLength(30)
            },
            patientId: {
                required,
                maxLength: maxLength(30)
            },
            dateOfBirth: {
                required,
                minValue: minValue(moment.tz().year(1900).month(0).date(1).subtract(1, 'day').toDate()),
                maxValue: maxValue(moment.tz().endOf('day').toDate())
            }
        };
    }

    /**
     * Radio options for the sex radio buttons
     */
    sexOptions: RadioButtonData[] = [
        {
            label: 'Male',
            value: 'Male'
        },
        {
            label: 'Female',
            value: 'Female'
        },
    ];

    /**
     * Updates the form field validations for a specific field
     * @param field Field that was updated
     */
    async touchField(field: string) {
        this.$nextTick(() => {
            if((this.$v as Object).hasOwnProperty(field)) {
                this.$v[field].$touch();
                this.$emit('invalid', this.$v.$invalid);
            }
        })

    }

    /**
     * Processes an update to the date of birth field
     * Updates form field validations for the date of birth field
     * @param eventData
     */
    handleDateOfBirthUpdate(eventData: any) {
        this.emitEvent('dateOfBirth', eventData);
        this.touchField('dateOfBirth');
    }
}
