






import { Component, Vue } from 'vue-property-decorator';
import ConfirmationModal from './modal/ConfirmationModal.vue';
import {namespace} from 'vuex-class';
import {AuthMutations} from '../vuex/auth';
import {InactivityActions, InactivityMutations} from '../vuex/inactivity';
import {ModalMutations} from '@/vuex/modal';

const auth = namespace('auth');
const oidc = namespace('oidcStore');
const inactivity = namespace('inactivity');
const modal = namespace('modal');

@Component({
    components: {
        ConfirmationModal,
    }
})
export default class InactivityModal extends Vue {
    secondsRemaining = 120;

    secondsRemainingTimerId: number | null = null;

    @oidc.Action('signOutOidc') signOut!: any;
    @auth.Mutation(AuthMutations.LOG_USER_OUT) clearUser!: () => void;
    @inactivity.Action(InactivityActions.EXTEND_TIMER) extendTimer!: () => void;
    @inactivity.Mutation(InactivityMutations.UPDATE_MODAL_STATUS) updateInactivityModalState!: (state: boolean) => void;
    @modal.Mutation(ModalMutations.CHANGE_MODAL_STATE) changeModalState!: (state: boolean) => void;

    mounted() {
        this.changeModalState(true)
        this.secondsRemaining = 120;
        this.secondsRemainingTimerId = setInterval(() => {
            this.secondsRemaining = this.secondsRemaining - 1
            if(this.secondsRemaining === 0) {
                this.signUserOut()
            }
        }, 1000)

    }

    signUserOut() {
        if(this.secondsRemainingTimerId !== null) {
            clearInterval(this.secondsRemainingTimerId)
        }
        this.signOut();
        this.clearUser();
        this.updateInactivityModalState(false);
    }

    continueSession() {
        if(this.secondsRemainingTimerId !== null) {
            clearInterval(this.secondsRemainingTimerId)
        }
        this.extendTimer()
        this.updateInactivityModalState(false);
    }

    beforeDestroy() {
        this.changeModalState(false);
    }
}
