


































































































































import {Component, Vue} from 'vue-property-decorator';
import {convertToMomentTimezone, formatMoment, TimezoneUtils} from '@/ts/TimezoneUtils';
import {ButtonType} from '@/components/form/FormTypes';
import {AssignableToTeams, getRolesForRole, Role, RoleNames} from '@/ts/permissions/Role';
import {namespace} from 'vuex-class';
import User, {MeasurementUnit} from '@/models/User';
import UpdatePasswordForm from '@/views/Users/UpdatePasswordForm.vue';
import {RawLocation, Route} from 'vue-router';
import store from '@/store';
import axios from 'axios';
import Multiselect from 'vue-multiselect';
import TeamSelector from '@/components/users/TeamSelector.vue';
import UserPermissions from '@/components/users/UserPermissions.vue';
import UserPreferences from '@/components/users/UserPreferences.vue';
import GeneralUserInfo from '@/components/users/GeneralUserInfo.vue';
import {Customer} from '@/models/customers/Customer';
import {Team} from '@/models/Teams';
import Spinner from '@/components/form/Spinner.vue';
import {ModalMutations} from '@/vuex/modal';
import UserService from '@/services/UserService';
import moment, {Moment} from 'moment';
import OtherUserDetails from '@/components/users/OtherUserDetails.vue';
import ConfirmationModal from '@/components/modal/ConfirmationModal.vue';
import {Attribute} from '@/ts/permissions/Attribute';
import {Permission} from '@/ts/permissions/Permission';

const usersNamespace = namespace('users');
const authNamespace = namespace('auth');
const modal = namespace('modal');

enum Tabs {
    PROFILE,
    PASSWORD
}
Component.registerHooks([
    'beforeRouteEnter',
    'beforeRouteLeave',
    'beforeRouteUpdate'
]);
@Component({
    components: {
        UpdatePasswordForm,
        Multiselect,
        GeneralUserInfo,
        UserPreferences,
        UserPermissions,
        TeamSelector,
        Spinner,
        ConfirmationModal,
        OtherUserDetails,
    },
})
export default class UserEdit extends Vue {
    assignableToTeams = AssignableToTeams;
    async beforeRouteEnter(to: Route, from: Route, next: ((to?: RawLocation | false | ((vm: UserEdit) => any) | void) => void)){
        if(store.state.auth.loggedInUser?.id !== undefined && to.params.id == store.state.auth.loggedInUser.id.toString()){
            next({name: 'user-profile'});
        }else{
            next();
        }

    }

    user: User = {
        name: '',
        firstName: 'John',
        lastName: 'Doe',
        email: '',
        role: 'admin',
        teams: [],
        username: '',
        measurementUnit: MeasurementUnit.METRIC,
        timezone: '',
        customers: [],
        teamGroup: 0,
        lastPasswordUpdate: '',
        lastTermsConditionsAcceptance: null
    };

    password: any = {
        newPassword: '',
        confirmPassword: '',
    };
    tabs = Tabs;
    currentTab = Tabs.PROFILE;
    passwordValid = false;
    topListShowing = false;

    @authNamespace.State('loggedInUser') loggedInUser!: User;
    @modal.Mutation(ModalMutations.CHANGE_MODAL_STATE) changeModalState!: (state: boolean) => void;

    roleNames = RoleNames;
    roleDefinitions: any[] = [];

    selectedTimezone: any = null;
    selectedRole: any = null;
    teams: Team[] = [];
    systemTeam: Team | null = null;
    validity: any = {
        'general': true,
        'preferences': true,
        'permissions': true,
        'teams': true
    };

    customerList: Customer[] = [];
    selectedCustomer: Customer | null = null;

    disabledPermissionFields = {
        userRole: false,
        customer: true
    };

    loading = false;

    timezones = TimezoneUtils.getTimezonesNames();

    buttonTypes = ButtonType;
    removeUserModalShowing = false;
    userService = new UserService(this.$store.state.auth.loggedInUser.timezone);

    async mounted() {
        this.loading = true;
        try{
            const {data}: {data: User} = await axios.get(process.env.VUE_APP_BACKEND_URI + '/users/' + this.$route.params.id);
            if(data.lastPasswordUpdate !== null) {
                data.lastPasswordUpdate = convertToMomentTimezone(this.$store.state.auth.loggedInUser.timezone, data.lastPasswordUpdate as string);
            }
            if(data.lastLogin !== null) {
                data.lastLogin = convertToMomentTimezone(this.$store.state.auth.loggedInUser.timezone, data.lastLogin as string);
            }

            if(data.lastTermsConditionsAcceptance !== null) {
                data.lastTermsConditionsAcceptance = convertToMomentTimezone(this.$store.state.auth.loggedInUser.timezone, data.lastTermsConditionsAcceptance as string);
            }
            this.$set(this, 'user', data);
            if(!this.isUserEditable) {
              this.disabledPermissionFields.userRole = true;
              this.selectedRole = {
                id: this.user.role,
                display: RoleNames[this.user.role],
              }
            }
            //this.user = data;
        }catch(e){
            console.log(e);
            return;
        }

        this.roleDefinitions = getRolesForRole(this.loggedInUser.role);

        if(this.user.teams == null || this.user.teams.length == 0){
            this.$set(this.user, 'teamGroup', 0);
        }else if(this.user.teams.find(team => team.type != undefined && team.type == 0) != undefined){
            this.$set(this.user, 'teamGroup', 1);
        }else{
            this.$set(this.user, 'teamGroup', 2);
        }

        if(this.loggedInUser.customers.length === 0 && this.$isAllowed(Permission.CUSTOMER_VIEW)){
            const {data} = await axios.get(process.env.VUE_APP_BACKEND_URI + '/customers');
            this.customerList = data;
            if(this.user.customers.length !== 0) {
              this.selectedCustomer = this.user.customers[0]
              this.teams = this.selectedCustomer.teams?.filter((team) => team.type === 1) || [];
            }
        }else if(this.loggedInUser.customers.length > 0){
            this.selectedCustomer = this.loggedInUser.customers[0];
            this.customerList = [this.selectedCustomer];
        }

        for(let timezone of this.timezones){
            if(this.user.timezone === timezone.tzName){
                this.selectedTimezone = timezone;
                break;
            }
        }

        for(let role of this.roleDefinitions){
            if(this.user.role === role.id){
                this.selectedRole = role;
                break;
            }
        }

        if(this.$hasAttribute(Attribute.REQUIRES_CUSTOMER)) {
            const systemTeam = this.loggedInUser.teams.find((team) => team.type === 0);
            if(systemTeam !== undefined) {
              this.systemTeam = systemTeam;
            }
        } else {
            const systemTeam = this.selectedCustomer?.teams?.find((team) => team.type === 0);
            if(systemTeam !== undefined) {
              this.systemTeam = systemTeam;
            }
        }


        this.loading = false;

    }

    showRemoveUserModal() {
        this.removeUserModalShowing = true;
        this.changeModalState(true);
        this.topListShowing = false;
    }

    hideRemoveUserModal() {
        this.removeUserModalShowing = false;
        this.changeModalState(false);
    }

    roleChanged(value: any){
        if(value == null){
            this.user.role = '';
            this.selectedRole = null;
        }else{
            this.user.role = value.id;
            this.selectedRole = value;
        }

    }

    timezoneChanged(value: any){
        if(value == null){
            this.user.timezone = '';
            this.selectedTimezone = null;
        }else{
            this.user.timezone = value.tzName;
            this.selectedTimezone = value;
        }

    }

    handleValidityChange(area: string, validity: boolean){
        this.validity[area] = !validity;
    }

    get isValid(){
        let teamsValid = true;
        if(this.user.teamGroup === 2 && this.user.teams.filter((team) => team.type === 1).length === 0) {
            teamsValid = false;
        }

        return this.validity.general
            && this.validity.permissions
            && this.validity.permissions
            && teamsValid;
    }

    async sendResetPassword() {
        try {
            await axios.patch(process.env.VUE_APP_BACKEND_URI + '/users/' + this.user.id + '/reset-password');
            this.user.lastPasswordUpdate = moment.tz(this.$store.state.auth.loggedInUser.timezone);
        } catch (e) {
            console.warn(e);
        }
    }

    async updateUser(){
        const requestData = {
            user: {...this.user}
        } as any;
        if(this.user.role === Role.ADMIN && this.systemTeam != null){
            requestData.teams = [this.systemTeam.id]
        }else if(!this.assignableToTeams.includes(this.user.role)){
            requestData.teams = null
        } else if(requestData.user.teamGroup === 0){
            requestData.teams = [];
        }else if(requestData.user.teamGroup === 1 && this.systemTeam != null){
            requestData.teams = [this.systemTeam.id]
        }else if(requestData.user.teamGroup === 2){
            requestData.teams = this.user.teams.map(team => team.id);
        }
        if(this.selectedCustomer != null){
            requestData.customer = this.selectedCustomer.id
        }
        delete requestData.user.teamGroup;
        delete requestData.user.teams;
        delete requestData.user.lastPasswordUpdate;
        try{
            const {status} = await axios.patch(process.env.VUE_APP_BACKEND_URI + '/users/'+this.user.id, requestData);
            if(status < 300){
                this.$router.push({name: 'user-list'});
            }
            this.$addSnackbarMessage({
                message: 'User successfully updated',
            });
        }catch(e){
            this.$addSnackbarMessage({
                message: 'There was an issue updating the user. Try again later or contact customer support.',
            });
        }
    }

    async updatePassword(){
        const requestData = {
            password: this.password.newPassword,
            passwordConfirm: this.password.confirmPassword
        };
        try{
            const {status} = await axios.patch(process.env.VUE_APP_BACKEND_URI + '/users/'+this.user.id+'/password', requestData);
            this.currentTab = Tabs.PROFILE
        }catch(e){
            this.$addSnackbarMessage({
                message: 'There was an issue updating the user\'s password. Try again later or contact customer support.',
            });
        }
    }

    async removeUser() {
        const response = await this.userService.removeUser(this.user.id as number);
        if(response.isSuccess()) {
            this.hideRemoveUserModal();
            this.$addSnackbarMessage({
                message: 'User successfully removed',
            });
            this.$router.push({name: 'user-list'});
        } else {
            this.$addSnackbarMessage({
                message: 'There was an issue removing. Try again later or contact customer support.',
            });
        }
    }

    formatMoment(dateTime: Moment) {
        return formatMoment(dateTime);
    }

    cancelTemporaryPassword() {
        this.password.newPassword = '';
        this.password.confirmPassword = '';
    }

    get canSendResetPasswordEmail() {
        return (this.user.lastPasswordUpdate === null) ||
            (this.user.lastLogin === null) ||
            ((this.user.lastPasswordUpdate as Moment).isBefore(this.user.lastLogin as Moment) && (this.user.lastPasswordUpdate as Moment).add(2, 'days').isSameOrBefore(moment.tz(this.$store.state.auth.loggedInUser.timezone)));
    }

    get isUserEditable() {
      return getRolesForRole(this.loggedInUser.role).find((r) => r.id === this.user.role) !== undefined;
    }
}
