import axios from 'axios';
import {AsyncEither, Failure, Success} from '@/errors/Either';
import {Error} from '@/errors/Error';


export default class MaintenanceModeService {

    private readonly timezone: string;


    constructor(timezone: string) {
        this.timezone = timezone;
    }

    /**
     * Fetches the status of maintenance mode
     */
    public async fetchMaintenanceModeStatus(): AsyncEither<any, Error> {
        try {
            const {data} = await axios.get(process.env.VUE_APP_BACKEND_URI + '/maintenance-mode');
            return new Success(data);
        } catch (e) {
            return new Failure({
                message: 'Unable to fetch maintenance mode status',
            });
        }
    }
}
