

















import {Component, Prop, Vue} from 'vue-property-decorator';
import {SnackbarData} from '@/models/Snackbar';
import {ButtonType} from '@/components/form/FormTypes';

/**
 * Renders a snackbar item for displaying a message to the user
 */
@Component
export default class Snackbar extends Vue {
    /**
     * Data to display
     */
    @Prop() snackbarData!: SnackbarData;
    buttonType = ButtonType;
}
