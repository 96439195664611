
















import {Component, Prop, Vue} from 'vue-property-decorator';
import Modal from '../../modal/Modal.vue';
import PatientDeviceAssignmentService from '@/services/patients/PatientDeviceAssignmentService';
import {Moment} from 'moment';

/**
 * Renders a modal to end the current pump assignment
 */
@Component({
    components: {
        Modal,
    }
})
export default class UnassignDeviceFromPatientModal extends Vue {
    /**
     * Current value of the selected end date
     */
    endDate: Date | null = null;

    /**
     * Start date of the current assignment
     */
    @Prop() startDate!: Moment;

    /**
     * ID of the pump for the current assignment
     */
    @Prop() deviceId!: number;

    /**
     * ID of the current assignment
     */
    @Prop() assignmentId!: number;
    patientDeviceAssignmentService = new PatientDeviceAssignmentService(this.$store.state.auth.loggedInUser.timezone);


    /**
     * Creates the configuration options for the date picker
     */
    get datePickerOpts() {
        return {
            minDate: this.startDate.toDate(),
            maxDate: new Date()
        }
    }

    /**
     * Sends the request to end the current assignment
     */
    async endAssignment() {
        if(this.endDate === null){
            return;
        }
        const response = await this.patientDeviceAssignmentService.unassignDeviceFromPatient(this.deviceId, this.assignmentId, this.endDate);
        if(response.isSuccess()) {
            this.$emit('endAssignment', this.endDate);
        }
    }
}
