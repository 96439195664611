






















































import {Component, Prop, Vue} from "vue-property-decorator";
import ProgressBar from '../../progress/ProgressBar.vue';
import {ButtonType} from "@/components/form/FormTypes";
import Modal from '@/components/modal/Modal.vue';
import Spinner from '@/components/form/Spinner.vue';
import ConnectWirelessDiagnosticsService from '@/services/devices/connect/ConnectWirelessDiagnosticsService';

/**
 * Renders a modal to perform wireless diagnostics on a specific pump
 */
@Component({
    components: {
        ProgressBar,
        Modal,
        Spinner
    }
})
export default class WirelessDiagnosticsModal extends Vue {

    /**
     * ID of the pump to test
     */
    @Prop() deviceId!: string;

    /**
     * Serial number of the pump to test
     */
    @Prop({default: ''}) serialNumber!: string;

    /**
     * Current progress bar information
     */
    progressInfo = {
        pump: {
            color: '',
            width: 'w-0',
            error: false
        },
        hub: {
            color: '',
            width: 'w-0',
            error: false,
            errorCase: ''
        },
        digi: {
            color: '',
            width: 'w-0',
            error: false
        },
    };

    ButtonType = ButtonType;

    /**
     * Whether the wireless diagnostics process is in progress
     */
    inProgress = false;

    connectWirelessDiagnosticsService = new ConnectWirelessDiagnosticsService();

    mounted() {
        this.testPumpConnection()
    }

    /**
     * Sends the request to perform the two steps of wireless diagnostics
     * Updates the status for the first progress bar appropriately
     */
    async testPumpConnection(){
        this.resetState();
        this.progressInfo.pump.color = 'bg-blue-400';
        this.progressInfo.pump.width = 'w-2/5';
        this.inProgress = true;

        const response = await this.connectWirelessDiagnosticsService.stepOne(this.deviceId);
        const stepOneResult = response.map(() => {
            this.allPass();
        }).mapErr((error) => {
            if(error.statusCode === 422) {
                this.progressInfo.hub.errorCase = error.message
                this.setPumpState(false);
                this.setHubState(false);
                this.testDigiConnection();
                return;
            }
            return this.connectWirelessDiagnosticsService.stepTwo(this.deviceId)
        })
        stepOneResult.mapErr(async (stepTwoResult) => {
            if(stepTwoResult !== undefined) {
                const response = await stepTwoResult;
                response.map(() => {
                    this.allPass()
                }).mapErr(() => {
                    this.setPumpState(false);
                    this.testHubConnection()
                })
            }
        })
        // try {
        //     const {data} = await axios.get(process.env.VUE_APP_BACKEND_URI + '/devices/marrs/' + this.deviceId + '/wireless-diagnostics/step-one');
        //     this.allPass();
        //     return;
        // }catch (stepOneError){
        //     const response = stepOneError.response;
        //     if(response.status === 422 && response.data.fieldErrors.length > 0){
        //         this.progressInfo.hub.errorCase = response.data.fieldErrors[0].field;
        //         this.setPumpState(false);
        //         this.setHubState(false);
        //         await this.testDigiConnection();
        //         return;
        //     }
        //     try {
        //         const {data} = await axios.get(process.env.VUE_APP_BACKEND_URI + '/devices/marrs/' + this.deviceId + '/wireless-diagnostics/step-two');
        //         this.allPass();
        //         return;
        //     }catch (stepTwoError){
        //         this.setPumpState(false);
        //     }
        // }
        //
        // await this.testHubConnection();
    }

    /**
     * Sends the request to perform the third step of wireless diagnostics
     * Updates the status of the second progress bar appropriately
     */
    async testHubConnection(){
        this.progressInfo.hub.color = 'bg-blue-400';
        this.progressInfo.hub.width = 'w-2/5';
        const response = await this.connectWirelessDiagnosticsService.stepThree(this.deviceId);
        response.map(() => {
            this.hubDigiPass()
        }).mapErr(() => {
            this.setHubState(false);
            this.testDigiConnection()
        })
        // try {
        //     const {data} = await axios.get(process.env.VUE_APP_BACKEND_URI + '/devices/marrs/' + this.deviceId + '/wireless-diagnostics/step-three');
        //     this.hubDigiPass();
        //     return;
        // }catch (e){
        //     this.setHubState(false);
        // }
        //
        // await this.testDigiConnection();
    }

    /**
     * Sends the request to perform the fourth step of wireless diagnostics
     * Updates the status of the third progress bar
     */
    async testDigiConnection(){
        this.progressInfo.digi.color = 'bg-blue-400';
        this.progressInfo.digi.width = 'w-2/5';
        const response = await this.connectWirelessDiagnosticsService.stepFour(this.deviceId);
        response.map(() => {
            this.setDigiState(true);
        }).mapErr(() => {
            this.setDigiState(false);
        })
        this.inProgress = false;
    }

    /**
     * Resets the state of all of the progress bars
     */
    resetState(){
        this.progressInfo.pump.color = '';
        this.progressInfo.pump.width = 'w-0';
        this.progressInfo.pump.error = false;

        this.progressInfo.hub.color = '';
        this.progressInfo.hub.width = 'w-0';
        this.progressInfo.hub.error = false;
        this.progressInfo.hub.errorCase = '';

        this.progressInfo.digi.color = '';
        this.progressInfo.digi.width = 'w-0';
        this.progressInfo.digi.error = false;
    }

    /**
     * Marks all tests as passing
     */
    allPass(){
        this.setPumpState(true);

        this.hubDigiPass();
    }

    /**
     * Marks the hub and digi tests as in progress
     */
    hubDigiPass(){
        this.setHubState(true);

        this.setDigiState(true);

        this.inProgress = false;
    }

    /**
     * Updates the state of the first progress bar
     * @param pass Whether the tests passed
     */
    setPumpState(pass: boolean){
        if(pass){
            this.progressInfo.pump.color = 'bg-green-400';
            this.progressInfo.pump.width = 'w-full';
            this.progressInfo.pump.error = false;
        }else{
            this.progressInfo.pump.color = 'bg-red-400';
            this.progressInfo.pump.width = 'w-3/5';
            this.progressInfo.pump.error = true;
        }
    }

    /**
     * Updates teh state of the second progress bar
     * @param pass Whether the test passed
     */
    setHubState(pass: boolean){
        if(pass){
            this.progressInfo.hub.color = 'bg-green-400';
            this.progressInfo.hub.width = 'w-full';
            this.progressInfo.hub.error = false;
        }else{
            this.progressInfo.hub.color = 'bg-red-400';
            this.progressInfo.hub.width = 'w-3/5';
            this.progressInfo.hub.error = true;
        }
    }

    /**
     * Updates the state of the third progress bar
     * @param pass Whether the test passed
     */
    setDigiState(pass: boolean){
        if(pass){
            this.progressInfo.digi.color = 'bg-green-400';
            this.progressInfo.digi.width = 'w-full';
            this.progressInfo.digi.error = false;
        }else{
            this.progressInfo.digi.color = 'bg-red-400';
            this.progressInfo.digi.width = 'w-3/5';
            this.progressInfo.digi.error = true;
        }
    }
}
