













































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {required} from 'vuelidate/lib/validators';
import Multiselect from 'vue-multiselect';
import {RadioButtonData} from '@/components/form/FormTypes';
import {TimezoneUtils} from '@/ts/TimezoneUtils';
import {getErrorMessages} from '@/validations/Validator';

/**
 * Renders a form for capturing the user's preferences
 */
@Component({
    validations: {
        timezone: {
          required
        },
        measurementUnit: {
            required
        }
    },
    components: {
        Multiselect
    }
})
export default class UserPreferences extends Vue {

    /**
     * Currently selected timezone
     */
    @Prop({type: Object}) timezone?: any;

    /**
     * Currently selected measurement unit
     */
    @Prop({type: String, default: ''}) measurementUnit!: string;

    @Prop({default: false}) disabled!: boolean;

    /**
     * Available measurement units
     */
    measurementSystems: RadioButtonData[] = [
        {
            label: "Metric",
            value: "METRIC"
        },
        {
            label: "Imperial",
            value: "IMPERIAL"
        },
    ];

    /**
     * Messages to show beneath the measurement system radio button
     */
    extraRadioMessages = [
        "Measurement system applies to patient height and weight fields only.",
        "Device-related units are not converted",
    ];

    timezones = TimezoneUtils.getTimezonesNames();

    /**
     * Updates the form field validation when the timezone value changes
     */
    @Watch('timezone')
    timezoneChanged(){
        if(this.$v.timezone != undefined){
            this.$v.timezone.$touch();
            this.$emit('invalid', this.$v.$invalid);
        }
    }

    /**
     * Emits an event when the value of a form field changes
     * @param field
     * @param data
     */
    emitEvent(field: string, data: any){
        this.$emit(field, data);
        if(this.$v[field] != undefined){
            this.$v[field].$touch();
            this.$emit('invalid', this.$v.$invalid);
        }
    }

    /**
     * Returns any error messages for the timezone field
     */
    get timezoneErrorMessages(){
        if (this.$v && this.$v.timezone && this.$v.timezone.$error) {
            return getErrorMessages(this.$v.timezone, 'Timezone');
        } else {
            return [];
        }
    }
}
