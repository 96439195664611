






























































import {Component, Prop, Vue} from "vue-property-decorator";
import {Hub} from '@/models/Devices/Hubs/Hub';
import {HubActions} from "@/vuex/hubs";
import Spinner from '@/components/form/Spinner.vue';

/**
 * Renders the mobile details for a hub
 */
@Component({
    components: {
        Spinner
    }
})
export default class MobileDetails extends Vue {
    /**
     * Current hub
     */
    @Prop({type: Object}) hub!: Hub;

    /**
     * Whether the request to fetch details is currently loading
     */
    isLoading = true;

    /**
     * Fetches the hub's mobile details on component load
     */
    async mounted(){
        await this.$store.dispatch('hubs/' + HubActions.FETCH_MOBILE_DETAILS, this.hub.id);
        this.isLoading = false;
    }

    /**
     * Fetches the mobile details from the hub
     */
    get getMobileDetails(){
        if(this.hub.hubMobileDetails != null){
            return this.hub.hubMobileDetails;
        }else{
            return null;
        }
    }
}
