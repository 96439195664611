import {Component, Vue, Prop} from 'vue-property-decorator';
import {Validation} from 'vuelidate';
import {getErrorMessages} from '@/validations/Validator';

/**
 * Mixin used to provide validation to form inputs
 */
@Component
export default class FormValidationMixin extends Vue {

    /**
     * Object containing validation data
     */
    @Prop() public validationObject?: Validation;

    /**
     * Name of the field
     */
    @Prop(String) public label!: string;

    /**
     * Whether the field currently has an error
     */
    get hasError() {
        return this.validationObject && this.validationObject.$error;
    }

    /**
     * Array of error messages to display
     */
    get errorMessages() {
        if (this.validationObject !== undefined && this.hasError) {
            return getErrorMessages(this.validationObject, this.label);
        } else {
            return [];
        }
    }
}
