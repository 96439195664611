import 'core-js/stable';
import 'regenerator-runtime/runtime';
if (!!window.MSInputMethodContext && !!(document as any).documentMode) {
  (Symbol as any).useSimple();
}

import './assets/scss/app.scss';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import 'intersection-observer';

import Vue, {PluginFunction} from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Vuelidate from 'vuelidate';
import FormInput from './components/form/FormInput.vue';
import Button from './components/form/Button.vue';
import TabLink from './components/navigation/TabLink.vue';
import axios, {AxiosResponse} from 'axios';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import {
  faArrowDown,
  faArrowUp,
  faChevronLeft,
  faChevronRight,
  faSearch,
  faPen,
  faTrash,
  faChevronUp,
  faChevronDown,
  faTimes,
  faCheck,
  faLock,
  faUnlock,
  faFilter,
  faDownload,
  faTimesCircle,
  faCheckCircle,
  faCircle,
  faSave,
  faUserCircle,
  faDesktop,
  faExclamationCircle,
  faCog,
  faFilePrescription,
  faTabletAlt,
  faFileUpload, faPlus, faMinus, faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import SideBarNav from '@/components/navigation/SideBarNav.vue';
import SideNavItem from '@/components/navigation/SideNavItem.vue';
import RadioButtonGroup from '@/components/form/RadioButtonGroup.vue';
import ToggleSwitch from '@/components/form/ToggleSwitch.vue';
import {AuthenticationPlugin} from '@/ts/plugins/AuthenticationPlugin';
import Spinner from '@/components/form/Spinner.vue';
import VCalendar from 'v-calendar';
import Checkbox from '@/components/form/Checkbox.vue';
import TopNav from './components/navigation/TopNav.vue';
import {ErrorMutations} from '@/vuex/errors';
import MaintenanceModeStatus from '@/components/customers/MaintenanceModeStatus.vue';
import vClickOutside from 'v-click-outside';
import SnackbarContainer from '@/components/utility/SnackbarContainer.vue';
import {SnackbarPlugin} from '@/ts/plugins/SnackbarPlugin';
import InactivityModal from '@/components/InactivityModal.vue';
import VTooltip from 'v-tooltip';

library.add(faArrowUp, faArrowDown, faChevronLeft,
    faChevronRight, faSearch, faPen, faTrash, faChevronUp, faChevronDown, faTimes,
    faCheck, faLock, faUnlock, faFilter, faDownload, faCheckCircle, faTimesCircle, faCircle, faFileUpload,
    faSave, faUserCircle, faDesktop, faExclamationCircle, faCog, faFilePrescription, faTabletAlt, faQuestionCircle,
    faPlus, faMinus, faExclamationTriangle);

Vue.config.productionTip = false;
Vue.use(Vuelidate);
Vue.use(VCalendar, {
  componentPrefix: 'vc',
  locale: 'en-US',
  locales: {
    'en-US': {
      firstDAyOfWeek: 1,
      masks: {
        title: 'MMMM YYYY',
        weekdays: 'W',
        navMonths: 'MMM',
        dayPopover: 'WWW, MMM D, YYYY',
        input: ['DD MMM YYYY', 'YYYY-MM-DD', 'YYYY/MM/DD'],
        data: ['DD MMM YYYY', 'YYYY-MM-DD', 'YYYY/MM/DD'],
      },
    },
  },
});
Vue.use(vClickOutside as PluginFunction<{}>);
Vue.component('form-input', FormInput);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('Button', Button);
Vue.component('ToggleSwitch', ToggleSwitch);
Vue.component('tab-link', TabLink);
Vue.component('side-bar', SideBarNav);
Vue.component('side-nav-item', SideNavItem);
Vue.component('RadioButtonGroup', RadioButtonGroup);
Vue.component('spinner', Spinner);
Vue.component('Checkbox', Checkbox);
Vue.component('TopNav', TopNav);
Vue.component('MaintenanceModeStatus', MaintenanceModeStatus);
Vue.component('SnackbarContainer', SnackbarContainer);
Vue.component('InactivityModal', InactivityModal);


Vue.use(AuthenticationPlugin);
Vue.use(SnackbarPlugin);
Vue.use(VTooltip, {
  defaultDelay: {
    show: 500,
    hide: 100,
  },
  defaultPlacement: 'bottom-end',
  defaultOffset: 6,
});

axios.interceptors.request.use(async (config) => {
  config.headers['Authorization'] = 'Bearer ' + store.getters['oidcStore/oidcAccessToken'];
  return config;
});

axios.interceptors.response.use((response: AxiosResponse): AxiosResponse => {
  return response;
}, (error: any): any => {
  if (error.response.status === 503) {
    store.commit('errors/' + ErrorMutations.SET_MAINTENANCE_MODE, error.response.data.errors[0]);
    router.push({name: 'maintenance-mode'});
  } else if (error.response.status === 402) {
    router.push({name: 'account-disabled'});
  }
  return Promise.reject(error);
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
