


































































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import Spinner from '@/components/form/Spinner.vue';
import AdherenceDoughnutChart from '@/components/reports/adherence/AdherenceDoughnutChart.vue';
import {AdherenceRecord, ReportDateContainer} from '@/models/reports/AdherenceReport';
import {namespace} from 'vuex-class';
import {FeedPrescription, Patient, PatientDeviceAssignment} from '@/models/patients/Patient';
import {ButtonType} from '@/components/form/FormTypes';
import {Moment} from 'moment';
    import ReportDateSelector from '@/components/reports/ReportDateSelector.vue';
import DeviceAssignmentModal from '@/components/patients/DeviceAssignmentModal.vue';
import PrescriptionManagementModal from '@/components/patients/prescriptions/PrescriptionManagementModal.vue';
import {PatientMutations} from '@/vuex/patients';
import {ModalMutations} from '@/vuex/modal';
import FeedHistoryCard from '@/components/patients/cards/FeedHistoryCard.vue';
import PatientAdherenceReportService from '@/services/patients/reports/PatientAdherenceReportService';

const patients = namespace('patients');
const modal = namespace('modal');

/**
 * Renders a pie chart of the patient's adherence in a card
 */
@Component({
    components: {
        Spinner,
        AdherenceDoughnutChart,
        ReportDateSelector,
        FeedHistoryCard,
    }
})
export default class PatientAdherenceCard extends Vue {

    @patients.State(state => state.homePage.patient) patient!: Patient;
    @patients.State(state => state.homePage.patient.assignments) patientAssignments!: PatientDeviceAssignment[];
    @patients.State(state => state.homePage.patient.feedPrescriptions) feedPrescriptions!: FeedPrescription[];
    /**
     * Whether the adherence request is loading
     */
    loading = false;

    /**
     * List of patient adherence records
     */
    adherenceRecords: AdherenceRecord[] = [];
    buttonTypes = ButtonType;
    patientAdherenceReportService = new PatientAdherenceReportService(this.$store.state.auth.loggedInUser.timezone);
    @Prop() reportOptions!: ReportDateContainer;
    @Prop() reportDateOption!: number;
    exportDisabled = true;

    eventBus = new Vue();

    /**
     * Returns the patient's overall adherence if it exists
     */
    get overallAdherencePercent() {
        if(this.adherenceRecords.length > 0){
            return this.adherenceRecords[0].totalAdherence;
        }

        return null;
    }

    /**
     * Whether the patient does not have any prescriptions
     */
    get hasNoPrescription(){
        return this.patient.feedPrescriptions.length === 0;
    }

    /**
     * Whether the patient does not have any assignments
     */
    get hasNoAssignments(){
        return this.patient.assignments.length === 0;
    }

    mounted(): void {
        this.fetchData();
    }

    /**
     * When the report date selector changes, fetch the updated data
     */
    @Watch('reportOptions')
    reportOptionsChanged(){
        this.fetchData();
    }

    /**
     * When a patient's assignments change, fetch the updated data
     */
    @Watch('patientAssignments')
    assignmentsChanged() {
        if(!this.loading) {
            this.fetchData();
        }
    }

    /**
     * When a patient's prescriptions change, fetch the updated data
     */
    @Watch('feedPrescriptions')
    prescriptionChange() {
        if(!this.loading) {
            this.fetchData();
        }
    }

    /**
     * Emits an event to have the parent show the add prescription modal
     */
    showPrescriptionModal(){
       this.$emit('addPrescription')
    }

    /**
     * Emits an event to have the parent show the add assignment modal
     */
    showAssignmentModal() {
       this.$emit('addAssignment')
    }

    get latestPrescriptionAmount() {
        if(this.feedPrescriptions.length > 0) {
            return this.feedPrescriptions[0].dailyVolume + ' mL / day'
        }
        return "N/A"
    }

    /**
     * Sends the request to fetch the adherence data
     */
    async fetchData() {
        this.loading = true;
        const response = await this.patientAdherenceReportService.fetchAdherenceReport(this.patient.id as number, this.reportOptions.startDate, this.reportOptions.endDate);
        if(response.isSuccess()){
            response.map((data) => {
                this.adherenceRecords = data;
            })
        }
        this.loading = false;
    }

    /**
     * Returns the number of reportable days based on the report date selector option
     */
    get totalDays() {
        switch(this.reportDateOption) {
            case 0:
                return 7;

            case 1:
                return 28;

            case 2:
                return 90;
        }
    }
}
