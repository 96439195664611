









import {Component, Prop, Vue} from "vue-property-decorator";

/**
 * Displays a clickable font awesome icon
 */
@Component
export default class IconDisplay extends Vue {
    /**
     * Icon to display
     */
    @Prop() icon!: string;

    @Prop({default: true}) fontAwesome!: boolean;

    @Prop({default: ''}) svgClasses!: string;

    @Prop({default: null}) tooltip!: string | null;

    customIcons: Map<string, string> = new Map<string, string>([
        ["filter-inactive", '<g><path fill="none" stroke-miterlimit="10" stroke="currentColor" d="M13.6,11.2l5.6-7.3H4.8l5.6,7.3v6.6l3.3,2.4l-0.1-3.5V11.2z"/></g>'],
        ['filter-active', '<rect fill="#705FA9" width="24" height="24"/><g><path fill="#FFFFFF" d="M13.6,11.2l5.6-7.3H4.8l5.6,7.3v6.6l3.3,2.4l-0.1-3.5V11.2z"/></g>'],
        ['sort', '<g><g><line fill="none" stroke="currentColor" stroke-miterlimit="10" x1="7.8" y1="3" x2="7.8" y2="20.3"/><polyline fill="none" stroke="currentColor" stroke-miterlimit="10" points="12.5,15.6 7.8,20.3 3.1,15.6"/></g><g><line fill="none" stroke="currentColor" stroke-miterlimit="10" x1="16.2" y1="21" x2="16.2" y2="3.7"/><polyline fill="none" stroke="currentColor" stroke-miterlimit="10" points="11.5,8.4 16.2,3.7 20.9,8.4"/></g></g>'],
        ['check', '<path fill="none" d="M0,0h24v24H0V0z"/><path fill="#098422" d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M10,17l-5-5l1.4-1.4l3.6,3.6l7.6-7.6L19,8 L10,17z"/>'],
        ['times', '<path fill="#FFFFFF" d="M0,0h24v24H0V0z"/><path fill="#B00020" d="M4.9,4.9C1,8.8,1,15.2,4.9,19.1s10.2,3.9,14.1,0s3.9-10.2,0-14.1S8.8,1,4.9,4.9z M16.2,9.2L13.4,12l2.8,2.8 l-1.4,1.4L12,13.4l-2.8,2.8l-1.4-1.4l2.8-2.8L7.8,9.2l1.4-1.4l2.8,2.8l2.8-2.8L16.2,9.2z"/>'],
        ['connect-pump', '<g><g><circle cx="53.22" cy="184.95" r="10.76"/><circle cx="53.22" cy="157.18" r="10.76"/><circle cx="53.22" cy="268.27" r="10.76"/><circle cx="53.22" cy="240.5" r="10.76"/><circle cx="53.22" cy="212.73" r="10.76"/><path d="M394.33,83.5H170.05c0,0-76.85,1.11-126.81,14.27c-49.96,13.16-55.07,219.25-2.39,234.95 c56.95,16.97,129.21,15.77,129.21,15.77h224.27c18.87,0,34.17-15.37,34.17-34.33V117.83C428.5,98.87,413.2,83.5,394.33,83.5z M276.89,297.97c0,16.37-13.52,29.64-30.19,29.64l-94.05,0.88c0,0-101.03-5.2-109.37-22.1c-26.02-52.72-20.3-162.73-0.76-178.38 c8.96-7.18,39.02-20.4,113.78-22.69c78.44,2.29,120.6,8.33,120.6,39.08V297.97z M400.7,305.65c0,10.68-27.75,23.28-49.71,22.83 c-20.37-0.42-47.14-13.99-47.14-22.83V119.69c0-4.88,3.96-8.84,8.84-8.84h11.95l4,11.82c0.45,1.33,1.7,2.23,3.11,2.23h42.24 c1.47,0,2.76-0.98,3.16-2.39l3.29-11.66h11.41c4.88,0,8.84,3.96,8.84,8.84V305.65z"/><circle cx="163.96" cy="303.92" r="11.32"/><path d="M248.9,148.19H79.02c-1.69,0-3.06,1.37-3.06,3.06v122.97c0,1.69,1.37,3.06,3.06,3.06H248.9c1.69,0,3.06-1.37,3.06-3.06 V151.24C251.96,149.55,250.59,148.19,248.9,148.19z"/>t</g></g>'],
        ['plus', '<g><line fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-miterlimit="10" x1="12" y1="1.3" x2="12" y2="22.7"/></g><g><line fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-miterlimit="10" x1="22.7" y1="12" x2="1.3" y2="12"/></g>'],
        ['wireless-diagnostics', '<path fill="#231f20" d="M167.63,140.77,149.7,122.84l27-26.95a38.14,38.14,0,0,0-53.9,0l-45,45,17.92,17.92H59.82l-9,8.9,45,45,9-9v-36l17.92,17.92,13.48-13.47,87.23,87.11,74.59,87.11a12.65,12.65,0,0,0,17.92,0l12.53-12.52a12.66,12.66,0,0,0,0-17.93l-87.12-74.59-87.1-87.11Z"/><path fill="#231f20" d="M287.84,142.19l-99,99.16-87.12,74.59a12.66,12.66,0,0,0,0,17.93l12.53,12.52a12.65,12.65,0,0,0,17.92,0l74.59-87.11,99-99Z"/><path fill="#231f20" d="M326.06,139.76,308.33,122l32.59-32.59a45.68,45.68,0,1,0,17.73,17.73Z"/><path fill="#231f20" d="M215.37,287.49a53.17,53.17,0,1,1,53.17-53.17A53.23,53.23,0,0,1,215.37,287.49Z"/><path fill="#fff" d="M215.37,184.43a49.89,49.89,0,1,1-49.89,49.89,49.88,49.88,0,0,1,49.89-49.89m0-6.56a56.45,56.45,0,1,0,56.45,56.45,56.51,56.51,0,0,0-56.45-56.45Z"/><path fill="#fff" d="M178.11,226.5l6.78,6.78a43.09,43.09,0,0,1,61,0l6.78-6.78A52.73,52.73,0,0,0,178.11,226.5Zm27.1,27.1,10.16,10.16,10.16-10.16A14.35,14.35,0,0,0,205.21,253.6Zm-13.55-13.55,6.78,6.77a24,24,0,0,1,33.86,0l6.78-6.77A33.58,33.58,0,0,0,191.66,240.05Z"/>']
    ])

    get customIcon(): string | undefined {
        if(!this.fontAwesome) {
            return this.customIcons.get(this.icon)
        }
    }

    get iconClassName(): string {
        return `icon-${this.icon}`
    }
}
