

























import {Component, Prop, Vue} from "vue-property-decorator";
import Modal from "@/components/modal/Modal.vue";
import Table from "@/components/table/Table.vue";
import {TableCellType, TableHeaderData, TableRowData} from "@/components/table/cells/TableCellData";
import User from "@/models/User";
import Checkbox from '@/components/form/Checkbox.vue';
import {namespace} from "vuex-class";
import {Patient} from '@/models/patients/Patient';
import AssignedTeamsChipDisplayPatient from './AssignedTeamsChipDisplayPatient.vue';
import {PatientService} from '@/services/patients/PatientService';
import {AsyncEither, Either} from '@/errors/Either';
import {Error} from '@/errors/Error';
import TeamService from '@/services/TeamService';
import {sortByProp} from '@/ts/utilities';

const auth = namespace('auth');

/**
 * Renders a modal to add multiple patients to a single team
 */
@Component({
    components: {
        Modal,
        Table,
        Checkbox
    }
})
export default class AddPatientsToTeamModal extends Vue {
    /**
     * Patients that are already on the team
     */
    @Prop() existingPatients!: Patient[];

    /**
     * ID of the team
     */
    @Prop() teamId!: number;

    /**
     * Patients that can be assigned to the team
     */
    assignablePatients: Patient[] = [];

    /**
     * Whether the table is loading
     */
    loading = false;

    /**
     * Patients that are currently selected
     */
    checkedPatients: Patient[] = [];

    /**
     * Field the table is currently sorted by
     */
    sortKey = 'firstName';

    /**
     * Whether the table is sorted ascending
     */
    sortedAsc = true;

    searchString = ''

    patientService = new PatientService(this.$store.state.auth.loggedInUser.timezone);
    teamService = new TeamService()

    @auth.State('loggedInUser') loggedInUser!: User;

    async mounted(){
        this.loading = true;
        const promiseData = await Promise.all([this.fetchPatients(), new Promise(function(resolve) {setTimeout(resolve, 1000)})]);
        promiseData[0].map((patients) => {
            this.assignablePatients = patients;
        })
        this.loading = false;
    }

    /**
     * Sends the request to fetch the list of assignable patients
     */
    async fetchPatients(): AsyncEither<Patient[], Error>{
        return this.patientService.fetchPatientsWithoutFilter()
    }

    /**
     * Updates the sort information when the table is sorted
     * @param message
     */
    sortData(message: any){
        this.sortKey = message.sortKey;
        this.sortedAsc = message.sortedAsc;
    }

    /**
     * Sends the request to assign the patients to the team
     */
    async assignPatientsToTeam(){
        await this.teamService.assignPatientsToTeam(this.checkedPatients.map((patient: Patient) => patient.id), this.teamId, this.loggedInUser.customers.length > 0 ? this.loggedInUser.customers[0].id : null);
        this.dismiss();
    }

    /**
     * Emits an event to tell the parent to hide the modal
     */
    dismiss(){
        this.$emit('dismiss');
    }

    /**
     * Table header definition
     */
    get tableHeader(): TableHeaderData[]{
        return [
            {
                sortable: false,
                value: "Actions",
                widthClass: "w-1/10",
            },
            {
                sortable: true,
                value: "Name",
                widthClass: "w-1/5",
                sortKey: 'firstName'
            },
            {
                sortable: false,
                value: "Assigned team(s)",
                widthClass: "w-7/10",
            },
        ]
    }

    /**
     * Transforms the list of assignable patients to an arrya of table rows
     * Include a checkbox so that each row is selectable
     */
    get tableData(): TableRowData[]{
        const vm = this;
        const checkedUsers = this.checkedPatients;
        return this.assignablePatients
            .filter(patient => this.existingPatients.find(existingUser => existingUser.id === patient.id) == undefined)
            .filter(this.filterTable)
            .sort(sortByProp((patient: Patient) => patient.lastName, this.sortedAsc))
            .map(patient => {
                const checked = this.checkedPatients.find(checkedPatient => checkedPatient.id === patient.id) != undefined;
                const cells = [
                    {
                        type: TableCellType.SLOT_LIST,
                        primaryValue: '',
                        components: [
                            {
                                primaryValue: 'Checkbox',
                                componentOptions: {
                                    label: '',
                                    checked: checked
                                },
                                events: {
                                    change: function(checked: boolean){
                                        if(checked){
                                            vm.checkedPatients.push(patient);
                                        }else{
                                            const index = vm.checkedPatients.findIndex(checkedPatient => checkedPatient.id === patient.id);
                                            if(index != -1){
                                                vm.checkedPatients.splice(index, 1);
                                            }
                                        }
                                    }
                                }
                            }
                        ]
                    },
                    {
                        type: TableCellType.TWO_ROW,
                        primaryValue: patient.firstName + " " + patient.lastName,
                    },
                    {
                        type: TableCellType.SLOT_LIST,
                        primaryValue:"",
                        components: [
                            {
                                primaryValue: AssignedTeamsChipDisplayPatient,
                                componentOptions: {
                                    teams: patient.teams,
                                }
                            }
                        ]
                    }
                ];

                return {
                    cells,
                    highlighted: checked,
                    indexKey: patient.id as number
                }
            });
    }

    searchData(data: string) {
        this.searchString = data;
    }

    filterTable(patient: Patient) {
        if(this.searchString === '') {
            return true;
        }

        return patient.firstName.toLowerCase().includes(this.searchString.toLowerCase()) || patient.lastName.toLowerCase().includes(this.searchString.toLowerCase()) ||
            (patient.firstName.toLowerCase() + " " + patient.lastName.toLowerCase()).includes(this.searchString.toLowerCase());
    }
}
