import {Moment} from 'moment';
import {Either, Failure, Success} from '@/errors/Either';
import {PumpErrorFilter, PumpErrorReportData} from '@/models/reports/PumpErrors';
import {Error} from '@/errors/Error';
import axios from 'axios';
import {AlertRecord} from '@/models/Devices/MARRS/Records';
import {convertToMomentTimezone} from '@/ts/TimezoneUtils';
import {saveDownloadedFile} from '@/ts/utilities';


export default class ConnectErrorReportService {
    private readonly timezone: string;


    constructor(timezone: string) {
        this.timezone = timezone;
    }

    /**
     * Fetches the pump error report for a single pump
     * @param startDate Start date of the report
     * @param endDate End date of the report
     * @param pumpId ID of the pump to fetch the report for
     */
    // tslint:disable-next-line:max-line-length
    public async fetchErrorReportForPump(startDate: Moment | Date, endDate: Moment | Date, pumpId: number): Promise<Either<PumpErrorReportData, Error>> {
        try {
            const request = {
                startDate: startDate.toISOString(),
                endDate: endDate.toISOString(),
            };
            const {data}: {data: PumpErrorReportData} =
                await axios.get(process.env.VUE_APP_BACKEND_URI + '/devices/' + pumpId + '/reports/error-report', {
                    params: request,
                });
            data.alertRecords.forEach((record: AlertRecord) => {
                record.timestamp = convertToMomentTimezone(this.timezone, record.timestamp as string);
            });
            return new Success(data);
        } catch (e) {
            return new Failure({
                message: 'Failed to get pump error report',
            });
        }
    }

    /**
     * Exports a pump error report as an excel document
     * @param startDate Start date of the report
     * @param endDate End date of the report
     * @param pumpId ID of the pump to export for
     * @param filter Filters to apply to the export
     */
    // tslint:disable-next-line:max-line-length
    public async exportErrorReportAsExcel(startDate: Moment | Date, endDate: Moment | Date, pumpId: number, filter: PumpErrorFilter): Promise<Either<null, Error>> {
        try {
            const request = {
                startDate: startDate.toISOString(),
                endDate: endDate.toISOString(),
            };
            const response = // tslint:disable-next-line:max-line-length
                await axios.post(process.env.VUE_APP_BACKEND_URI + '/devices/' + pumpId + '/reports/error-report/csv', filter, {
                    params: request,
                    responseType: 'blob',
                });
            saveDownloadedFile(response, 'pump-error-report.xlsx');
            return new Success(null);
        } catch (e) {
            return new Failure({
                message: 'Failed to export pump error report',
            });
        }
    }

    /**
     * Exports a pump error report as a PDF
     * @param startDate Start date of the report
     * @param endDate End date of the report
     * @param pumpId ID of the pump to export for
     * @param filter Filters to apply to the export
     */
    // tslint:disable-next-line:max-line-length
    public async exportErrorReportAsPdf(startDate: Moment | Date, endDate: Moment | Date, pumpId: number, filter: PumpErrorFilter): Promise<Either<null, Error>> {
        try {
            const request = {
                startDate: startDate.toISOString(),
                endDate: endDate.toISOString(),
            };
            const response = // tslint:disable-next-line:max-line-length
                await axios.post(process.env.VUE_APP_BACKEND_URI + '/devices/' + pumpId + '/reports/error-report/pdf', filter, {
                    params: request,
                    responseType: 'blob',
                });
            saveDownloadedFile(response, 'pump-error-report.pdf');
            return new Success(null);
        } catch (e) {
            return new Failure({
                message: 'Failed to export pump error report',
            });
        }
    }
}
