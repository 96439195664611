



































import {Component, Prop, Vue} from "vue-property-decorator";
import {
    PageData,
    PaginatedData,
    TableCellType,
    TableHeaderData,
    TableRowData
} from "../../table/cells/TableCellData";
import {MARRSPumpWithDetails} from "@/models/Devices/MARRS/MARRSPump";
import Checkbox from "@/components/form/Checkbox.vue";
import TextDisplay from "@/components/utility/TextDisplay.vue";
import {formatMoment} from "@/ts/TimezoneUtils";
import {Moment} from "moment";
import IconDisplay from "@/components/utility/IconDisplay.vue";
import {FilterType, SearchData} from "@/ts/Filter";
import Table from "@/components/table/Table.vue";
import {ButtonType, CheckboxStateData} from "@/components/form/FormTypes";
import {Searchable} from "@/models/Searchable";
import {Certification, CertificationFilter} from "@/models/Devices/MARRS/Certification";
import Spinner from '@/components/form/Spinner.vue';
import ConnectCertificationService from '@/services/devices/connect/ConnectCertificationService';

/**
 * Renders a table to show pumps and their latest certifications
 */
@Component({
    components: {
        Table,
        Spinner
    }
})
export default class PumpCertificationTable extends Vue {
    /**
     * Current page of pump data
     */
    @Prop({default: () => {
            return {totalElements: 0, totalPages: 0, data: []}
        }}) pumpData!: PaginatedData<MARRSPumpWithDetails>;

    /**
     * Event bus for sending events to the table
     */
    @Prop() eventBus!: Vue;

    /**
     * Whether the table is sorted ascending
     */
    @Prop() sortedAsc!: boolean;

    /**
     * Field the table is currently sorted by
     */
    @Prop() currentSortKey!: string;

    /**
     * Currently applied filters
     */
    @Prop() filterData!: any;

    /**
     * Array of pumps currently selected
     */
    selectedPumps: Set<number> = new Set([]);

    /**
     * Current state of the select all checkbox
     */
    selectAllState = CheckboxStateData.UNCHECKED;

    /**
     * Current page size of the table
     */
    pageSize = 30;
    buttonType = ButtonType;

    /**
     * Whether the certify pumps button has been pressed at least once
     */
    haveCertifiedPumps = false;

    /**
     * Whether the request to certify pumps is currently pending
     */
    certifyPending = false;

    /**
     * Results of certifying a group of pumps
     */
    certData = {
        results: null as any | null,
    };

    /**
     * Initial value of the search field
     */
    @Prop() initialSearch!: string;

    connectCertificationService = new ConnectCertificationService(this.$store.state.auth.loggedInUser.timezone);

    /**
     * Table header definition
     */
    get tableHeader(): TableHeaderData[]{
        const vm = this;
        return [
            {
                sortable: false,
                sortKey: 'serialNumber',
                value: 'Serial number',
                widthClass: 'w-1/4',
                selectAll: {
                    hasSelectAll: true,
                    state: vm.selectAllState,
                    handler: () => {
                        if(vm.selectAllState == CheckboxStateData.CHECKED){
                            vm.selectedPumps = new Set<number>([]);
                            vm.selectAllState = CheckboxStateData.UNCHECKED;
                        }else{
                            vm.selectedPumps = new Set<number>(vm.pumpData.data.map(pump => pump.pump.id));
                            vm.selectAllState = CheckboxStateData.CHECKED
                        }
                    }
                }
            },
            {
                sortable: true,
                sortKey: 'lastConnection',
                value: 'Last connection',
                widthClass: 'w-1/4',
                filterable: !vm.filterData.showOnlyCertErrors,
                filterKey: 'connectionFilter',
                filters: [
                    {
                        type: FilterType.CALENDAR,
                        key: 'dateRange',
                        active: vm.filterData.connectionStart != null,
                        enabled: true,
                        displayText: 'Filter by date',
                        data: {
                            initialRange: {
                                start: vm.filterData.connectionStart,
                                end: vm.filterData.connectionEnd,
                            }
                        }
                    }
                ]
            },
            {
                sortable: true,
                sortKey: 'certification',
                value: 'Last certification attempt',
                widthClass: 'w-1/4',
                filterable: !vm.filterData.showOnlyCertErrors,
                filterKey: 'certificationFilter',
                filters: [
                    {
                        type: FilterType.MULTISELECT,
                        key: 'result',
                        active: vm.filterData.certificationStatus !== CertificationFilter.PASS_AND_FAIL,
                        enabled: true,
                        displayText: 'Filter by result',
                        data: {
                            multiselect: true,
                            activeItems: {
                                result: vm.filterData.certificationStatus !== CertificationFilter.PASS_AND_FAIL ? [vm.filterData.certificationStatus] : []
                            },
                            items: [
                                {
                                    value: CertificationFilter.PASS,
                                    label: 'Pass',
                                    key: 'pass'
                                },
                                {
                                    value: CertificationFilter.FAIL,
                                    label: 'Fail',
                                    key: 'fail'
                                },
                            ]
                        }
                    },
                    {
                        type: FilterType.CALENDAR,
                        key: 'dateRange',
                        active: vm.filterData.certificationStart != null,
                        enabled: true,
                        displayText: 'Filter by date',
                        data: {
                            initialRange: {
                                start: vm.filterData.certificationStart,
                                end: vm.filterData.certificationEnd
                            }
                        }
                    }
                ]
            },
            {
                sortable: false,
                sortKey: 'certResult',
                value: 'Certification result',
                widthClass: 'w-1/4',
            },
        ]
    }

    /**
     * Transforms the list of pumps into an array of table rows
     */
    get tableData(): PageData{
        const vm = this;
        const rows: TableRowData[] = this.pumpData.data.map(pumpData => {
            const cells = [
                {
                    type: TableCellType.SLOT_LIST,
                    primaryValue:"",
                    components: [
                        {
                            primaryValue: Checkbox,
                            componentOptions: {
                                label: '',
                                checked: vm.selectedPumps.has(pumpData.pump.id)
                            },
                            events: {
                                change: () => {
                                    if(vm.selectedPumps.has(pumpData.pump.id)){
                                        vm.selectedPumps.delete(pumpData.pump.id);
                                        vm.selectedPumps = new Set<number>(vm.selectedPumps);
                                    }else {
                                        vm.selectedPumps = new Set<number>(vm.selectedPumps.add(pumpData.pump.id));
                                    }
                                    vm.updateSelectAllCheckbox()
                                }
                            }
                        },
                        {
                            primaryValue: TextDisplay,
                            componentOptions: {
                                value: pumpData.pump.serialNumber,
                                class: 'inline-block',
                                valueClickable: true,
                            },
                            events: {
                                valueClick: () => {
                                    vm.$router.push({name: 'enteral-pump-home', params: {id: pumpData.pump.id.toString()}})
                                }
                            }
                        }
                    ]
                },
                {
                    type: TableCellType.NORMAL,
                    primaryValue: formatMoment(pumpData.pump.lastConnectionTime as Moment) as string
                },
                {
                    type: TableCellType.SLOT_LIST,
                    primaryValue:"",
                    innerClass: 'flex flex-row justify-between items-center',
                    components: [
                        {
                            primaryValue: IconDisplay,
                            componentOptions: {
                                icon: `${pumpData.latestCertification != null && pumpData.latestCertification.certificationResult ? 'check-circle' : 'times-circle'}`,
                                class: `${(pumpData.latestCertification as Certification).id == null ? 'hidden' : ''}`
                            }
                        },
                        {
                            primaryValue: TextDisplay,
                            componentOptions: {
                                value: this.getCertificationTimestamp(pumpData),
                                class: 'px-8 w-4/5 text-center'
                            }
                        },
                        {
                            primaryValue: IconDisplay,
                            componentOptions: {
                                icon: 'download',
                                class: `${pumpData.latestCertification.id == null ? 'hidden' : 'cursor-pointer'} text-black text-opacity-60 hover:text-opacity-100 hover:bg-warm-gray-1 rounded-full w-7 h-7 cursor-pointer flex flex-row justify-center items-center`,
                                tooltip: 'Download certification report'
                            },
                            events: {
                                click: () => vm.downloadCertification(pumpData.pump.id, pumpData.latestCertification.id)
                            }
                        }
                    ]
                },
                {
                    type: TableCellType.NORMAL,
                    primaryValue: this.getCertResult(pumpData) as string
                },
            ];

            return {
                cells: cells,
                highlighted: vm.selectedPumps.has(pumpData.pump.id),
                indexKey: pumpData.pump.id
            }
        });

        return {
            numPages: this.pumpData.totalPages,
            totalElements: this.pumpData.totalElements,
            pageData: rows
        }
    }

    /**
     * Emits the event to change pages of the table so the parent can pass in the updated data
     * @param page
     */
    fetchNewPage(page: number){
        this.$emit('changePage', page);
    }

    /**
     * Emits the event when the sorting of the table changes so the parent can react
     * @param message
     */
    sortData(message: any){
        this.$emit('sort', message);
    }

    /**
     * Emits an event when the page size is changed so the parent can react
     * @param newPageSize
     */
    pageSizeChanged(newPageSize: number){
        this.$emit('pageSizeChanged', newPageSize);
        this.pageSize = newPageSize;
    }

    /**
     * Emits an event with the table is searched so the parent can react
     * @param searchString
     */
    async searchData(searchString: any){
        this.$emit('search', searchString);
    }

    /**
     * Array of different fields that can be searched by
     */
    get searchTypes(): SearchData[] {
        return [
            {
                key: Searchable.SERIAL_NUMBER,
                value: 'Serial number'
            }
        ]
    }

    /**
     * Updates the state of the select all checkbox
     */
    updateSelectAllCheckbox(){
        if(this.selectedPumps.size == 0){
            this.selectAllState = CheckboxStateData.UNCHECKED;
        }else if(this.selectedPumps.size < this.pageSize && this.selectedPumps.size < this.pumpData.data.length){
            this.selectAllState = CheckboxStateData.INDETERMINATE;
        }else{
            this.selectAllState = CheckboxStateData.CHECKED;
        }
    }

    /**
     * Fetches the certification result for a specific pump.
     * If a certify pumps request is pending for that pump, it will return pending
     * Otherwise it will return the latest certification result
     * @param pump
     */
    getCertResult(pump: MARRSPumpWithDetails){
        if(this.haveCertifiedPumps && this.certifyPending){
            return this.selectedPumps.has(pump.pump.id) ? "Pending" : this.extractCertificationResult(pump);
        }else if(this.haveCertifiedPumps){
           if(this.certData.results.hasOwnProperty(pump.pump.id)){
               if(this.certData.results[pump.pump.id].passed == null){
                   return "Unable to communicate with pump"
               }

               return this.certData.results[pump.pump.id].passed ? "Pass" : "Fail";
           }else {
               return this.extractCertificationResult(pump);
           }
        }else{
            return this.extractCertificationResult(pump);
        }

    }

    /**
     * Determines the certification result of the pump passed in
     * @param pump
     */
    private extractCertificationResult(pump: MARRSPumpWithDetails) {
        if (pump.latestCertification == null || pump.latestCertification.id === null) {
            return "N/A";
        }

        return pump.latestCertification.certificationResult ? "Pass" : "Fail";
    }

    /**
     * Formats the timestamp of the latest certification for the pump passed in
     * @param pump
     */
    getCertificationTimestamp(pump: MARRSPumpWithDetails){
        if(pump.latestCertification == null || pump.latestCertification.id === null){
            return "N/A";
        }

        return formatMoment(pump.latestCertification.timestamp as Moment)
    }

    /**
     * Sends the request to download a specific certification PDF
     * @param deviceId ID of the device to download certification for
     * @param certId ID of the certification to download
     */
    async downloadCertification(deviceId: number, certId: number){
        const response = await this.connectCertificationService.fetchCertificationPdf(deviceId, certId);
        response.mapErr(() => {
            this.$addSnackbarMessage({
                message: 'There was an issue downloading the certification PDF. Please try again later or contact customer support'
            })
        })
    }

    /**
     * Sends the request to certify a group of pumps
     */
    async certifyDevices(){
        this.haveCertifiedPumps = true;
        this.certifyPending = true;
        const response = await this.connectCertificationService.bulkCertifyPumps(this.selectedPumps);

        response.map((data) => {
            this.$emit('certResult', data.result);
            this.$set(this.certData, 'results', data.result);
        })

        this.certifyPending = false;
    }
}
