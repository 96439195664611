










import { Component, Vue, Prop } from 'vue-property-decorator';

/**
 * Renders a stylized radio button
 */
@Component
export default class RadioButton extends Vue {
    /**
     * Name of the radio button
     */
    @Prop({type: String, default: ''}) name!: string;

    /**
     * Value of the radio button
     */
    @Prop() rbValue!: string | number;

    /**
     * Current value of the radio button field
     */
    @Prop() value!: string | number;

    /**
     * Whether the radio button should be displayed inline
     */
    @Prop({type: Boolean, default: false}) inline!: boolean;

    /**
     * Whether the radio button is disabled
     */
    @Prop({default: false}) disabled!: boolean;

    /**
     * Whether this radio button si currently selected
     */
    get isChecked(){
        return this.rbValue === this.value;
    }

    emitValue() {
        if(this.disabled) {
            return;
        }
        this.$emit('change', this.rbValue);
    }
}
