







































import { Component, Vue } from 'vue-property-decorator';
import {ButtonType} from '@/components/form/FormTypes';
import {CreatePatientRequest, Patient, PatientNotes} from '@/models/patients/Patient';
import PatientDetails from '@/components/patients/PatientDetails.vue';
import PatientTeamSelector from '@/components/patients/PatientTeamSelector.vue';
import {Team, TeamType} from '@/models/Teams';
import {Optional} from '@/ts/utilities';
import {PatientService} from '@/services/patients/PatientService';

/**
 * Renders a page for creating new patients
 */
@Component({
    components: {
        PatientDetails,
        PatientTeamSelector
    }
})
export default class PatientCreate extends Vue {
    buttonTypes = ButtonType;

    patientService = new PatientService(this.$store.state.auth.loggedInUser.timezone);
    /**
     * Patient info
     */
    patient: Patient = {
        id: 0,
        firstName: '',
        lastName: '',
        dateOfBirth: null,
        patientId: '',
        sex: null,
        feedPrescriptions: [],
        teams: [],
        weights: [],
        goalWeight: [],
        assignments: [],
        notes: [
            {
              id: null,
              notes: ''
            }
        ]
    };

    /**
     * Whether the form is valid
     */
    isValid = false;

    /**
     * Whether the teams section is valid
     */
    teamsValid = false;

    mounted() {
        this.teamsValid = this.userIsOnSystemTeam
    }

    /**
     * Whether the currently logged in user is a member of the system team
     */
    get userIsOnSystemTeam() {
        return this.$store.state.auth.loggedInUser.teams.find((team: Team) => team.type === TeamType.SYSTEM) !== undefined;
    }

    /**
     * Sends the request to create a new patient
     */
    async createPatient(){
        const patientCopy: Optional<Patient> = {
            ... this.patient
        };
        patientCopy.notes = [
            ... this.patient.notes as PatientNotes[]
        ];
        const teams = [
            ... this.patient.teams
        ].map((team: Team) => team.id);

        patientCopy.id = null;
        patientCopy.feedPrescriptions = undefined;
        patientCopy.assignments = undefined;
        patientCopy.weights = undefined;
        patientCopy.goalWeight = undefined;
        patientCopy.teams = undefined;
        if(patientCopy.notes[0].notes === '') {
            patientCopy.notes = undefined
        }

        const request: CreatePatientRequest = {
            patient: patientCopy as Patient,
            teams: teams.length > 0 ? teams : null,
            customer: this.$store.state.auth.loggedInUser.customers[0].id
        };

        const result = await this.patientService.createPatient(request);
        if(result.isSuccess()){
            this.$addSnackbarMessage({
                message: 'Patient created successfully',
            });
            this.$router.push({name: 'patient-list'})
        }else{
            result.mapErr(err => {
                //console.log(err.message)
                this.$addSnackbarMessage({
                    message: 'There was an issue exporting creating a new patient. Try again later or contact customer support.',
                });
            });
        }
    }
}
