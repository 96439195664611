import Vue from 'vue';
import Vuex from 'vuex';
import {OidcSettings, vuexOidcCreateStoreModule} from 'vuex-oidc';
import oidcSettings from './config/oidc';
import users from './vuex/users';
import auth from './vuex/auth';
import hubs from './vuex/hubs';
import modal from './vuex/modal';
import customers from './vuex/customers';
import errors from './vuex/errors';
import marrsPump from './vuex/marrs';
import patients from './vuex/patients';
import snackbar from '@/vuex/snackbar';
import inactivity from '@/vuex/inactivity';

Vue.use(Vuex);



export default new Vuex.Store({
  modules: {
    oidcStore: vuexOidcCreateStoreModule(oidcSettings, {namespaced: true}),
    users,
    auth,
    hubs,
    modal,
    customers,
    errors,
    marrsPump,
    patients,
    snackbar,
    inactivity,
  },
});
