import {Optional} from '@/ts/utilities';
import {PatientNotes} from '@/models/patients/Patient';
import {AsyncEither, AsyncNullSuccess, Either, Failure, nullSuccess, Success} from '@/errors/Either';
import {Error} from '@/errors/Error';
import axios from 'axios';


export default class NotesService {
    private readonly timezone: string;

    constructor(timezone: string) {
        this.timezone = timezone;
    }

    /**
     * Creates new notes for a patient.
     * NOTE: This is only used if a patient has no notes. If they have notes @see {@link updateNotes}
     * @param patientId ID of the patient
     * @param notes Notes information
     */
    public async createNotes(patientId: number, notes: Optional<PatientNotes>): AsyncEither<PatientNotes, Error> {
        try {
            // tslint:disable-next-line:max-line-length
            const {data}: {data: PatientNotes} = await axios.post(process.env.VUE_APP_BACKEND_URI + '/patients/' + patientId + '/notes', notes);
            return new Success(data);
        } catch (e) {
            return new Failure({
                message: 'Unable to create patient notes',
            });
        }
    }

    /**
     * Updates existing notes for a patient
     * @param patientId ID of the patient to update
     * @param notes Updates notes information
     */
    public async updateNotes(patientId: number, notes: PatientNotes): AsyncNullSuccess<Error> {
        try {
            // tslint:disable-next-line:max-line-length
            await axios.patch(process.env.VUE_APP_BACKEND_URI + '/patients/' + patientId + '/notes/' + notes.id, notes);
            return nullSuccess();
        } catch (e) {
            return new Failure({
                message: 'Unable to update patient notes',
            });
        }
    }
}
