// Borrowed from https://github.com/mesemus/vuelidate-property-decorators/blob/master/src/vuelidate-decorators.js

import {createDecorator, mixins, VueDecorator} from 'vue-class-component';

function validationCreator() {
    // @ts-ignore
    const validations = this.$vnode.componentOptions.Ctor.options.__validations || {};
    const ret = { };
    for (const k in validations) {
        if (!validations.hasOwnProperty(k)) { continue; }
        let val = validations[k];
        if (k === '__validations__') {
            // @ts-ignore
            val = this[val];
        }
        if (typeof val === 'function') {
            // @ts-ignore
            val = val.call(this);
        }
        if (k === '__validations__') {
            Object.assign(ret, val);
        } else {
            // @ts-ignore
            ret[k] = val;
        }
    }
    return ret;
}

export function Validations(): VueDecorator {
    return createDecorator((componentOptions, k) => {
        // @ts-ignore
        if (!componentOptions.__validations) {
            // @ts-ignore
            componentOptions.__validations = {};
        }
        // @ts-ignore
        componentOptions.__validations['__validations__'] = k;
        componentOptions.validations = validationCreator;
    });
}

export function Validate(rules: any): VueDecorator {
    return createDecorator((componentOptions, k) => {
        // @ts-ignore
        if (!componentOptions.__validations) {
            // @ts-ignore
            componentOptions.__validations = {};
        }
        // @ts-ignore
        componentOptions.__validations[k] = rules;
        componentOptions.validations = validationCreator;
    });
}
