import {Customer} from '@/models/customers/Customer';
import User from '@/models/User';

/**
 * Represents a team in the application
 */
export interface Team {
    id: number;
    name: string;
    customer: Customer;
    users: User[];
    patients: any[];
    type?: number;
}

export enum TeamType {
    SYSTEM = 0,
    USER = 1,
}
