import * as moment from 'moment-timezone';
import {Moment} from 'moment';

export class TimezoneData {
    tzName: string = '';
    tzPresentationName: string = '';
}

/**
 * Reformats a date time string into the standard format
 * @param timezone Timezone to use
 * @param datetime Date time string to convert to
 */
export function convertToTimezone(timezone: string, datetime: string) {
    return formatMoment(convertToMomentTimezone(timezone, datetime));
}

/**
 * Converts a date time string into a moment object at the given timezone
 * @param timezone Timezone to use
 * @param dateTime Date time string to convert to
 */
export function convertToMomentTimezone(timezone: string, dateTime: string) {
    return moment
        .tz(dateTime, 'YYYY-MM-DDTHH:mm:ss', 'UTC')
        .tz(timezone);
}

/**
 * Formats a moment object into the standard date/time format
 * @param momentInstance Moment object to format
 */
export function formatMoment(momentInstance: Moment) {
    return momentInstance.format('DD MMM YYYY hh:mm A z');
}

/**
 * Formats a moment object into the standard date format
 * @param momentInstance Moment object to format
 */
export function formatMomentDateOnly(momentInstance: Moment) {
    return momentInstance.format('DD MMM YYYY');
}

export function getReportDateRange(tab: number, timezone: string) {
    if (tab === 0) {
        return {
            startDate: moment.tz(timezone).subtract(6, 'day').set('hour', 0).set('minute', 0).set('second', 0),
            endDate: moment.tz(timezone).set('hour', 23).set('minute', 59).set('second', 59),
        };
    } else if (tab === 1) {
        return {
            startDate: moment.tz(timezone).subtract(27, 'day').set('hour', 0).set('minute', 0).set('second', 0),
            endDate: moment.tz(timezone).set('hour', 23).set('minute', 59).set('second', 59),
        };
    } else {
        return {
            startDate: moment.tz(timezone).subtract(89, 'day').set('hour', 0).set('minute', 0).set('second', 0),
            endDate: moment.tz(timezone).set('hour', 23).set('minute', 59).set('second', 59),
        };
    }
}

export class TimezoneUtils {

    public static getTimezonesNames(): TimezoneData[] {
        const arr: TimezoneData[] = [];
        const names: string[] = [
            ...moment.tz.zonesForCountry('US'),
            ...moment.tz.zonesForCountry('CA'),
            ...moment.tz.zonesForCountry('AU'),
            ...moment.tz.zonesForCountry('NZ'),
            'UTC',
        ];
        for (const name of names) {
            if ((name.indexOf('/') < 0 && name !== 'UTC') || name.startsWith('Etc/')) {
                continue;
            }
            const data = new TimezoneData();
            data.tzName = name;
            data.tzPresentationName = moment.tz(name).format('Z');
            arr.push(data);
        }
        arr.sort((a, b) => {
            return a.tzName.toLowerCase() > b.tzName.toLowerCase() ? 1 : -1;
        });
        arr.forEach(a => a.tzPresentationName = `${a.tzName} (GMT ${a.tzPresentationName})`);
        return arr;
    }
}
