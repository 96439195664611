






























import {Component, Prop, Vue} from "vue-property-decorator";
import {TableHeaderData} from "@/components/table/cells/TableCellData";
import TableFilterContainer from "@/components/table/TableFilterContainer.vue";
import Checkbox from "@/components/form/Checkbox.vue";
import {CheckboxStateData} from '@/components/form/FormTypes';
import IconDisplay from '@/components/utility/IconDisplay.vue';

/**
 * Renders the header for a table
 */
@Component({
    components: {
        TableFilterContainer,
        Checkbox,
        IconDisplay
    }
})
export default class TableHeader extends Vue {
    /**
     * Table header data to display
     */
    @Prop() cells!: TableHeaderData[];
    @Prop({type: String, default: ''}) initialSortKey!: string;
    @Prop({type: Boolean, default: true}) initialSortedAsc!: boolean;

    @Prop({type: Boolean, default: false}) sticky!: boolean;

    currentlySortedBy = '';
    sortedAsc = true;
    filterVisible = false;
    filtersVisible: any = {

    };

    mounted(){
        this.currentlySortedBy = this.initialSortKey;
        this.sortedAsc = this.initialSortedAsc;
        for (const cell of this.cells){
            if(cell.filterable && cell.filterKey != undefined){
                this.$set(this.filtersVisible, cell.filterKey, false);
            }
        }
    }

    /**
     * Returns the sticky header class if the header is sticky
     */
    get shadowClass(){
        if(this.sticky){
            return 'sticky-header-shadow';
        }else{
            return '';
        }
    }

    /**
     * Emits an event when a sortable column is sorted
     * @param sortKey
     * @param index
     */
    sort(sortKey: string, index: number){
        if(sortKey === null || sortKey === undefined){
            return;
        }

        if(this.currentlySortedBy === sortKey){
            this.sortedAsc = !this.sortedAsc;
        }else{
            this.currentlySortedBy = sortKey;
            this.sortedAsc = true;
        }
        this.$emit('sorted', {
            sortKey,
            sortedAsc: this.sortedAsc,
            index
        });
    }

    /**
     * Emits an event when a filter changes data
     * @param key
     * @param data
     */
    filter(key: string, data: any){
       this.$emit('filter', {
           key,
           data
       });
       this.filtersVisible[key] = false;
    }

    /**
     * Whether the select all checkbox is checked
     * @param cell
     */
    checkboxChecked(cell: TableHeaderData) {
        return cell.selectAll && cell.selectAll.state === CheckboxStateData.CHECKED
    }

    /**
     * Whether the select all checkbox is in an indeterminate state
     * @param cell
     */
    checkboxIndeterminate(cell: TableHeaderData) {
        return cell.selectAll && cell.selectAll.state === CheckboxStateData.INDETERMINATE
    }

    /**
     * Background classes for the filter when it is active
     * @param cell
     */
    filterActive(cell: TableHeaderData){
        return cell.filters != undefined && cell.filters.find(filter => filter.active) != undefined;
    }
}
