





































import {Component, Vue} from "vue-property-decorator";
import {namespace} from "vuex-class";
import {PatientDeviceAssignment} from "@/models/patients/Patient";
import {formatMomentDateOnly} from "@/ts/TimezoneUtils";
import moment, {Moment} from "moment";
import {ButtonType} from "@/components/form/FormTypes";
import User from "@/models/User";
import {Attribute, AttributeMap} from "@/ts/permissions/Attribute";
import {ModalMutations} from "@/vuex/modal";
import PatientAssignmentModal from '@/components/patients/PatientAssignmentModal.vue';
import {MARRSPump} from '@/models/Devices/MARRS/MARRSPump';
import {CustomerDeviceAssociation} from '@/models/customers/CustomerDeviceAssociation';
import {MarrsPumpMutations} from '@/vuex/marrs';
import UnassignDeviceFromPatientModal from '@/components/devices/marrs/UnassignDeviceFromPatientModal.vue';
import {Team, TeamType} from '@/models/Teams';

const marrs = namespace('marrsPump');

const auth = namespace('auth');
const modal = namespace('modal');

/**
 * Renders the information about the current or most recent assignment
 */
@Component({
    components: {
        PatientAssignmentModal,
        UnassignDeviceFromPatientModal
    }
})
export default class PatientAssignmentHistoryCard extends Vue {
    @marrs.State(state => state.homePage.deviceAssignments) deviceAssignments!: PatientDeviceAssignment[];
    @marrs.State(state => state.homePage.customerClaims) customerClaims!: CustomerDeviceAssociation[];
    @marrs.State(state => state.homePage.pump) pump!: MARRSPump;
    @marrs.Mutation(MarrsPumpMutations.ADD_NEW_ASSIGNMENT) addNewAssignment!: (assignment: PatientDeviceAssignment) => void;
    @marrs.Mutation(MarrsPumpMutations.EDIT_ASSIGNMENT) editAssignment!: (assignment: PatientDeviceAssignment) => void;
    @marrs.Mutation(MarrsPumpMutations.REMOVE_ASSIGNMENT) removeAssignment!: (assignmentId: number) => void;
    @auth.State('loggedInUser') loggedInUser!: User;
    @modal.Mutation(ModalMutations.CHANGE_MODAL_STATE) showModalBackdrop!: (state: boolean) => void;
    buttonType = ButtonType;

    /**
     * Whether the add/edit assignments modal is showing
     */
    modalShowing = false;

    /**
     * Whether the end assignment modal is showing
     */
    endModalShowing = false;

    /**
     * Formats the start date of the most recent assignment or -- if no current assignment
     */
    get latestAssignmentStartDate(){
        if(this.deviceAssignments.length > 0){
            return formatMomentDateOnly(this.deviceAssignments[0].startDate as Moment)
        }
        return "--";
    }

    /**
     * Formats the end date of the most recent assignment or -- if the assignment has not ended
     */
    get latestAssignmentEndDate(){
        if(this.deviceAssignments.length > 0 && this.deviceAssignments[0].endDate !== null){
            return formatMomentDateOnly(this.deviceAssignments[0].endDate as Moment)
        }
        return "--";
    }

    /**
     * Returns the name of the patient or N/A if no current assignment
     */
    get patientName(){
        if(this.deviceAssignments.length === 0 || this.deviceAssignments[0].patient === undefined || this.deviceAssignments[0].patient === null){
            return "N/A";
        }

        return this.deviceAssignments[0].patient.firstName + " " + this.deviceAssignments[0].patient.lastName;
    }

    /**
     * Whether the user can see all patients
     */
    get userIsOnSystemTeam() {
        return this.$store.state.auth.loggedInUser.teams.find((team: Team) => team.type === TeamType.SYSTEM) !== undefined;
    }

    /**
     * Shows the assignment history modal
     */
    showHistory(){
        this.showModalBackdrop(true);
        this.modalShowing = true;
    }

    /**
     * Hides the assignment history modal
     */
    hideHistory(){
        this.modalShowing = false;
        this.showModalBackdrop(false);
    }

    /**
     * Shows the end assignment modal
     */
    showEndAssignmentModal() {
        this.showModalBackdrop(true);
        this.endModalShowing = true;
    }

    /**
     * Hides the end assignment modal
     */
    hideEndAssignmentModal() {
        this.endModalShowing = false;
        this.showModalBackdrop(false);
    }

    /**
     * Handles when an assignment is ended
     * @param endDate
     */
    handleEndedAssignment(endDate: Date) {
        if(this.deviceAssignments[0].endDate === null) {
            const assignment = {...this.deviceAssignments[0]};
            assignment.endDate = moment.tz(endDate, this.$store.state.auth.loggedInUser.timezone);
            this.editAssignment(assignment);
            this.hideEndAssignmentModal();
        }
    }

    /**
     * Handles when a new assignment is created
     * @param assignment
     */
    handleNewAssignment(assignment: PatientDeviceAssignment) {
        this.addNewAssignment(assignment);
    }

    /**
     * Handles when an existing assignment is updated
     * @param assignment
     */
    handleUpdatedAssignment(assignment: PatientDeviceAssignment) {
        this.editAssignment(assignment);
    }

    /**
     * Handles when an assignment is removed
     * @param assignmentId
     */
    handleRemovedAssignment(assignmentId: number) {
        this.removeAssignment(assignmentId);
    }
}
