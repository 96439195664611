export default interface Filter {
    value: string | number | null;
    label: string;
}

export enum FilterType {
    MULTISELECT,
    CALENDAR,
    BOOLEAN,
    RANGE,
}

export interface MultiselectFilterData {
    items: FilterData[];
    multiselect: boolean;
    activeItems?: any;
}

export interface CalendarFilterData {
    initialRange: any;
}

export interface BooleanFilterData {
    selected: boolean;
    label: string;
}

export interface RangeFilterData {
    minRange: number;
    maxRange: number;
    activeRangeData: number | number[];
}

export interface FilterData {
    value: any;
    key: string | number;
    label: string;
    active?: boolean;
}

export type FilterDataType = MultiselectFilterData | CalendarFilterData | BooleanFilterData | RangeFilterData;

export interface TableFilterData {
    type: FilterType;
    data: FilterDataType;
    key: string;
    active: boolean;
    enabled: boolean;
    displayText: string;
}

export interface SearchData {
    key: string | number;
    value: string;
}
