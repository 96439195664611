






import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Spinner from '@/components/form/Spinner.vue';
import UserService from '@/services/UserService';
import User from '@/models/User';
import {Role} from '@/ts/permissions/Role';

const oidcModule = namespace('oidcStore');
const authModule = namespace('auth');

/**
 * Processes the OIDC information received from the SSO on login
 */
@Component({
    components: {
        Spinner
    }
})
export default class OidcCallback extends Vue {
    @oidcModule.Action('oidcSignInCallback') oidcSignInCallback: any;
    @authModule.Action('getLoggedInUser') getLoggedInUser: any;
    @authModule.State('loggedInUser') loggedInuser!: User;
    userService = new UserService('UTC');

    mounted(){
        this.oidcSignInCallback()
            .then(async (redirectPath: string) => {
                console.log("Successfully logged in and fetched user info, path: " + redirectPath);
                await this.getLoggedInUser();
                if(this.loggedInuser === null) {
                    return;
                }
                if(this.loggedInuser.termsAndConditionsUpdateNeeded) {
                    this.$router.push({name: 'terms-conditions-agreement'});
                    return;
                }
                this.userService.updateLastLogin();
                if(redirectPath !== '/' && this.$router.getMatchedComponents({path: redirectPath}).length === 1) {
                    this.$router.push(redirectPath);
                } else {
                    this.$router.push({name: this.getDefaultPageForRole()});
                }
            }).catch((err: any) => {
                console.error(err);
            })
    }


    /**
     * Returns the default landing page based on the users role
     */
    getDefaultPageForRole() {
        const dashboardRoles = [Role.ADMIN, Role.CLINICAL_VIEWER, Role.CLINICAL_SUPPORT_VIEWER];
        if(dashboardRoles.includes(<Role> this.loggedInuser.role)){
            return 'dashboard';
        } else if (this.loggedInuser.role === Role.CUSTOMER_SERVICE || this.loggedInuser.role === Role.MARKETING_SALES) {
            return 'customer-list';
        }

        return 'connect-pump-list';
    }
}
