








import {Component, Vue} from "vue-property-decorator";
import ErrorReport from '@/components/reports/errorReport/ErrorReport.vue';

/**
 * Shows a page for viewing the error report for a device
 */
@Component({
    components: {
        ErrorReport
    }
})
export default class PumpErrorReportPage extends Vue {

    get itemId() {
        return Number.parseInt(this.$route.params.id);
    }
}
