import {Error} from '@/errors/Error';
import {AsyncEither, AsyncNullSuccess, Either, Failure, nullSuccess, Success} from '@/errors/Either';
import axios from 'axios';
import {Team} from '@/models/Teams';
import {Patient} from '@/models/patients/Patient';

export default class TeamService {

    /**
     * Lists teams separated by user teams and system teams
     */
    public async fetchAllTeams(): AsyncEither<any, Error> {
        try {
            const {data} = await axios.get(process.env.VUE_APP_BACKEND_URI + '/teams/all');
            return new Success(data);
        } catch (e) {
            return new Failure({
                message: 'Unable to fetch list of teams',
            });
        }
    }

    /**
     * Fetches all teams
     */
    public async listTeams(): AsyncEither<Team[], Error> {
        try {
            const {data}: {data: Team[]} = await axios.get(process.env.VUE_APP_BACKEND_URI + '/teams');
            return new Success(data);
        } catch (e) {
            return new Failure({
                message: 'Unable to fetch list of teams',
            });
        }
    }

    /**
     * Creates a new team
     * @param name Name of the new team
     * @param customerId
     */
    public async createTeam(name: string, customerId: number | null): AsyncNullSuccess<Error> {
        try {
            await axios.post(process.env.VUE_APP_BACKEND_URI + '/teams', {
                name,
                customerId,
            });
            return nullSuccess();
        } catch (e) {
            return new Failure({
                message: 'Unable to create new team',
            });
        }
    }

    /**
     * Fetches the list of users and patients assigned to a team
     * @param teamId
     */
    public async fetchTeamInfo(teamId: number): AsyncEither<any, Error> {
        try {
            const {data} = await axios.get(process.env.VUE_APP_BACKEND_URI + '/teams/' + teamId + '/info');
            return new Success(data);
        } catch (e) {
            return new Failure({
                message: 'Unable to get team info',
            });
        }
    }

    /**
     * Assigns a patient to a list of teams
     * @param patientId ID of the patient to assign
     * @param teamIds IDs of the teams to assign to
     */
    public async assignPatientToTeams(patientId: number, teamIds: number[]): AsyncNullSuccess<Error> {
        try {
            await axios.patch(process.env.VUE_APP_BACKEND_URI + '/patients/' + patientId + '/teams', teamIds);
            return nullSuccess();
        } catch (e) {
            return new Failure({
                message: 'Unable to change patient team assignments',
            });
        }
    }

    /**
     * Removes a patient from a team
     * @param patientId ID of the patient
     * @param teamId ID of the team
     * @param customerId Optional ID of the customer
     */
    // tslint:disable-next-line:max-line-length
    public async removePatientFromTeam(patientId: number, teamId: number, customerId: number | null): AsyncNullSuccess<Error> {
        try {
            await axios.delete(process.env.VUE_APP_BACKEND_URI + '/teams/' + teamId + '/patients', {
                data: {
                    objects: [patientId],
                    customer: customerId,
                },
            });
            return nullSuccess();
        } catch (e) {
            return new Failure({
                message: 'Failed to remove patient from team',
            });
        }
    }

    /**
     * Assigns multiple patients to a single team
     * @param patientIds IDs of patients to assign
     * @param teamId ID of the team to assign
     * @param customerId
     */
    // tslint:disable-next-line:max-line-length
    public async assignPatientsToTeam(patientIds: Array<number | null>, teamId: number, customerId: number | null): AsyncNullSuccess<Error> {
        try {
            await axios.patch(process.env.VUE_APP_BACKEND_URI + '/teams/' + teamId + '/patients', {
                objects: patientIds,
                customer: customerId,
            });
            return nullSuccess();
        } catch (e) {
            return new Failure({
                message: 'Unable to assign patients to teams',
            });
        }
    }

    /**
     * Removes multiple users from a single team
     * @param userIds IDs of the users to remove
     * @param teamId ID of the team to remove them from
     * @param customerId ID of the customer
     */
    // tslint:disable-next-line:max-line-length
    public async removeUsersFromTeam(userIds: number[], teamId: number, customerId: number | null): AsyncNullSuccess<Error> {
        try {
            const {data} = await axios.delete(process.env.VUE_APP_BACKEND_URI + '/teams/' + teamId + '/users', {
                data: {
                    objects: userIds,
                    customer: customerId,
                },
            });
            return nullSuccess();
        } catch (e) {
            return new Failure({
                message: 'Unable to remove user from team',
            });
        }
    }
}
