import {Attribute, roleHasAttribute} from '@/ts/permissions/Attribute';

export enum Role {
    SUPER = 'super',
    MARKETING_SALES = 'marketingSales',
    SERVICE = 'serviceTechnician',
    CUSTOMER_SERVICE = 'customerService',
    ADMIN = 'admin',
    CLINICAL_VIEWER = 'clinicalViewer',
    CLINICAL_SUPPORT_VIEWER = 'clinicalSupportViewer',
    BIOMED_VIEWER = 'biomedViewer',
}

const RoleNames: { [index: string]: string } = {
    [Role.SUPER]: 'Super',
    [Role.MARKETING_SALES]: 'Marketing/Sales',
    [Role.SERVICE]: 'Service',
    [Role.CUSTOMER_SERVICE]: 'Customer Service',
    [Role.ADMIN]: 'Admin',
    [Role.CLINICAL_VIEWER]: 'Clinical Viewer',
    [Role.CLINICAL_SUPPORT_VIEWER]: 'Clinical Support Viewer',
    [Role.BIOMED_VIEWER]: 'Biomed Viewer',
};

export interface RoleDefinition {
    id: Role;
    display: string;
}

const RoleDefinitions = [
    {
        id: Role.SUPER,
        display: RoleNames[Role.SUPER],
    },
    {
        id: Role.MARKETING_SALES,
        display: RoleNames[Role.MARKETING_SALES],
    },
    {
        id: Role.SERVICE,
        display: RoleNames[Role.SERVICE],
    },
    {
        id: Role.CUSTOMER_SERVICE,
        display: RoleNames[Role.CUSTOMER_SERVICE],
    },
    {
        id: Role.ADMIN,
        display: RoleNames[Role.ADMIN],
    },
    {
        id: Role.CLINICAL_VIEWER,
        display: RoleNames[Role.CLINICAL_VIEWER],
    },
    {
        id: Role.CLINICAL_SUPPORT_VIEWER,
        display: RoleNames[Role.CLINICAL_SUPPORT_VIEWER],
    },
    {
        id: Role.BIOMED_VIEWER,
        display: RoleNames[Role.BIOMED_VIEWER],
    },
];

const RoleDefinitionByRole = {
    [Role.SUPER]: [
        {
            id: Role.SUPER,
            display: RoleNames[Role.SUPER],
        },
        {
            id: Role.MARKETING_SALES,
            display: RoleNames[Role.MARKETING_SALES],
        },
        {
            id: Role.SERVICE,
            display: RoleNames[Role.SERVICE],
        },
        {
            id: Role.CUSTOMER_SERVICE,
            display: RoleNames[Role.CUSTOMER_SERVICE],
        },
        {
            id: Role.ADMIN,
            display: RoleNames[Role.ADMIN],
        },
        {
            id: Role.CLINICAL_VIEWER,
            display: RoleNames[Role.CLINICAL_VIEWER],
        },
        {
            id: Role.CLINICAL_SUPPORT_VIEWER,
            display: RoleNames[Role.CLINICAL_SUPPORT_VIEWER],
        },
        {
            id: Role.BIOMED_VIEWER,
            display: RoleNames[Role.BIOMED_VIEWER],
        },
    ],
    [Role.MARKETING_SALES]: [
        {
            id: Role.MARKETING_SALES,
            display: RoleNames[Role.MARKETING_SALES],
        },
    ],
    [Role.SERVICE]: [
        {
            id: Role.SERVICE,
            display: RoleNames[Role.SERVICE],
        },
    ],
    [Role.CUSTOMER_SERVICE]: [
        {
            id: Role.CUSTOMER_SERVICE,
            display: RoleNames[Role.CUSTOMER_SERVICE],
        },
    ],
    [Role.ADMIN]: [
        {
            id: Role.ADMIN,
            display: RoleNames[Role.ADMIN],
        },
        {
            id: Role.CLINICAL_VIEWER,
            display: RoleNames[Role.CLINICAL_VIEWER],
        },
        {
            id: Role.CLINICAL_SUPPORT_VIEWER,
            display: RoleNames[Role.CLINICAL_SUPPORT_VIEWER],
        },
        {
            id: Role.BIOMED_VIEWER,
            display: RoleNames[Role.BIOMED_VIEWER],
        },
    ],
} as {[index: string]: RoleDefinition[]};



const AssignableToTeams = [
    Role.CLINICAL_VIEWER,
    Role.CLINICAL_SUPPORT_VIEWER,
] as string[];

export function getRolesForRole(roleId: string) {
    if (RoleDefinitionByRole.hasOwnProperty(roleId)) {
        return RoleDefinitionByRole[roleId];
    } else {
        return [];
    }
}


export {RoleNames, RoleDefinitions, AssignableToTeams};
