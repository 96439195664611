













import {Component, Prop, Vue} from 'vue-property-decorator';
import User from '../../models/User';
import {formatMoment} from '@/ts/TimezoneUtils';
import {Moment} from 'moment';

/**
 * Renders extra details about a user
 */
@Component
export default class OtherUserDetails extends Vue {
    @Prop() user!: User;

    /**
     * Formats the last login time for a user
     */
    get lastUserLogin() {
        if(this.user.lastLogin !== undefined && this.user.lastLogin !== null) {
            return formatMoment(this.user.lastLogin as Moment);
        }

        return "N/A"
    }

    /**
     * Formats the terms and conditions acceptance time for a user
     */
    get lastTermsConditionsAcceptance() {
        if(this.user.lastLogin !== undefined && this.user.lastTermsConditionsAcceptance !== null) {
            return formatMoment(this.user.lastTermsConditionsAcceptance as Moment)
        }

        return "N/A"
    }
}
