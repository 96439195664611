








import { Component, Vue } from 'vue-property-decorator';
import ErrorReport from '../../../components/reports/errorReport/ErrorReport.vue';

/**
 * Renders the pump errors report for a single patient
 */
@Component({
    components: {
        ErrorReport
    }
})
export default class PatientPumpErrorReport extends Vue {
    get itemId() {
        return Number.parseInt(this.$route.params.id);
    }
}
