






























import { Component, Vue, Prop } from 'vue-property-decorator';
import { TableCellData, TableCellType } from "@/components/table/cells/TableCellData";

/**
 * Renders a specific cell in a table row
 */
@Component
export default class TableCell extends Vue {
    TableCellType = TableCellType;

    /**
     * Table cell data
     */
    @Prop() data!: TableCellData;

    /**
     * Width of the table cell
     */
    @Prop({default: ''}) widthClass!: string;

    /**
     * Events to bind to for slot cells
     */
    get events(){
        if(this.data.componentOptions.events != undefined){
            return this.data.componentOptions.events;
        }else{
            return {};
        }
    }

    /**
     * Emits an event when a cell is clicked if it is clickable
     */
    emitClick(){
        if(this.data.clickable){
            this.$emit('cellClicked')
        }
    }
}
