



























    import {Component, Prop, Vue} from "vue-property-decorator";
import Spinner from '@/components/form/Spinner.vue';
    import {CustomerHistory} from '@/models/customers/CustomerStatusHistory';
    import {convertToTimezone} from "@/ts/TimezoneUtils";
    import User from "@/models/User";
    import CustomerService from '@/services/CustomerService';

/**
 * This component renders the status history of a given customer
 */
@Component({
    components: {
        Spinner
    }
})
export default class CustomerStatusHistory extends Vue {

    /**
     * ID of the current customer
     */
    @Prop() customerId!: number;

    /**
     * List of status changes for this customer
     */
    statusChanges: CustomerHistory[] = [];

    /**
     * Whether the request to fetch the customer data is currently loading
     */
    loading = true;

    customerService = new CustomerService(this.$store.state.auth.loggedInUser.timezone)

    /**
     * Fetches the customer status history on component load
     */
    async mounted(){
        await this.fetchData();
    }

    /**
     * Sends the request to fetch the customer status history. Once the history is received,
     * the included timestamps are converted to the user's timezone
     */
    async fetchData(){
        this.loading = true;
        const response = await this.customerService.fetchCustomerStatuses(this.customerId);
        response.map((statusChanges) => this.statusChanges = statusChanges);
        this.loading = false;
    }
}
