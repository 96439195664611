



















import { Component, Vue } from 'vue-property-decorator';
import Spinner from '@/components/form/Spinner.vue';
import moment, {Moment} from 'moment';
import {ButtonType} from "@/components/form/FormTypes";
import ConnectErrorReportService from '@/services/devices/connect/reports/ConnectErrorReportService';

/**
 * Renders a quick link on the pump home page showing recent alarms
 */
@Component({
    components: {
        Spinner
    }
})
export default class PumpAlarmsQuickLink extends Vue {
    /**
     * Whether the request is loading
     */
    loading = false;

    /**
     * Start date of the request
     */
    startDate: Moment | null = null;

    /**
     * End date of the request
     */
    endDate: Moment | null = null;

    /**
     * User's timezone
     */
    timezone: string = "";
    connectErrorReportService: ConnectErrorReportService | null = null;

    /**
     * Number of alarms within the time period
     */
    numberOfAlarms: number = 0;
    buttonType = ButtonType;

    /**
     * Sets the start/end date on component create and sends the request to fetch the data
     */
    async created(){
        const timezone = this.$store.state.auth.loggedInUser.timezone;
        this.startDate = moment.tz(timezone).subtract(6, 'day').set('hour', 0).set('minute', 0).set('second', 0);
        this.endDate = moment.tz(timezone).set('hour', 23).set('minute', 59).set('second', 59);
        this.connectErrorReportService = new ConnectErrorReportService(timezone);
        await this.fetchData();
    }

    /**
     * Sends the request to fetch the recent pump alarms
     */
    async fetchData(){
        if(this.connectErrorReportService === null){
            return;
        }
        this.loading = true;
        const response = await this.connectErrorReportService.fetchErrorReportForPump(this.startDate as Moment, this.endDate as Moment, Number.parseInt(this.$route.params.id));
        response.map((data) => {
            this.numberOfAlarms = data.alertRecords.length;
            this.loading = false;
        });
    }

    /**
     * Navigates the user to the pump errors report page, sets the report to a 7 day report
     */
    navigateToPumpAlarms(){
        this.$router.push({name: 'marrs-pump-error-report', query: {reportRange: ['0']}});
    }
}
