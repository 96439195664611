import {AsyncEither, AsyncNullSuccess, Either, Failure, nullSuccess, Success} from '@/errors/Either';
import {Customer, CustomerExportRequest} from '@/models/customers/Customer';
import {Error} from '@/errors/Error';
import axios from 'axios';
import {saveDownloadedFile} from '@/ts/utilities';
import {CustomerHistory} from '@/models/customers/CustomerStatusHistory';
import User from '@/models/User';
import {convertToTimezone} from '@/ts/TimezoneUtils';

export default class CustomerService {

    private readonly timezone: string;


    constructor(timezone: string) {
        this.timezone = timezone;
    }

    /**
     * Sends a request to fetch all customers
     */
    public async fetchAllCustomers(): AsyncEither<Customer[], Error> {
        try {
            const {data}: {data: Customer[]} = await axios.get(process.env.VUE_APP_BACKEND_URI + '/customers');
            return new Success(data);
        } catch (e) {
            return new Failure({
                message: 'Unable to fetch customer list',
            });
        }
    }

    /**
     * Sends a request to export the list of customers as an excel document
     * @param request
     */
    public async exportCustomers(request: CustomerExportRequest): AsyncNullSuccess<Error> {
        try {
            const response = await axios.get(process.env.VUE_APP_BACKEND_URI + '/customers/export', {
                params: request,
                responseType: 'blob',
            });
            saveDownloadedFile(response, 'customer-export.xlsx');
            return nullSuccess();
        } catch (e) {
            return new Failure({
                message: 'Unable to export customer list',
            });
        }
    }

    /**
     * Fetches a list of status changes for each customer
     * @param customerId
     */
    public async fetchCustomerStatuses(customerId: number): AsyncEither<CustomerHistory[], Error> {
        try {
            // tslint:disable-next-line:max-line-length
            const {data}: {data: CustomerHistory[]} = await axios.get(process.env.VUE_APP_BACKEND_URI + '/customers/' + customerId + '/status');
            data.forEach((status) => {
                if (status.changeDate != null && status.changeDate !== '') {
                    status.changeDate = convertToTimezone(this.timezone, status.changeDate as string);
                }
            });
            return new Success(data);
        } catch (e) {
            return new Failure({
                message: 'Unable to get customer status history',
            });
        }
    }

    /**
     * Fetches a list of users assigned to a specific customer
     * @param customerId
     */
    public async fetchUsersAssignedToCustomer(customerId: number): AsyncEither<User[], Error> {
        try {
            const {data} = await axios.get(process.env.VUE_APP_BACKEND_URI + '/customers/' + customerId + '/users');
            (data as User[]).forEach((user) => {
                if (user.lastLogin != null) {
                    user.lastLogin = convertToTimezone(this.timezone, user.lastLogin as string)
                } else {
                    user.lastLogin = 'N/A';
                }
            });
            return new Success(data);
        } catch (e) {
            return new Failure({
                message: 'Unable to get users assigned to customer',
            });
        }
    }

    /**
     * Updates the status of a customer
     * @param customerId ID of the customer
     * @param customerStatus New status
     */
    public async updateCustomerStatus(customerId: number, customerStatus: any): AsyncNullSuccess<Error> {
        try {
            await axios.post(process.env.VUE_APP_BACKEND_URI + '/customers/' + customerId + '/status', customerStatus);
            return nullSuccess();
        } catch (e) {
            return new Failure({
                message: 'Unable to update customer status',
            });
        }
    }
}
