import { CreatePatientRequest, Patient,
    PatientListFilter} from '@/models/patients/Patient';
import {AsyncEither, AsyncNullSuccess, Either, Failure, nullSuccess, Success} from '@/errors/Either';
import {PaginatedData} from '@/components/table/cells/TableCellData';
import {Error} from '@/errors/Error';
import axios from 'axios';
import {convertToMomentTimezone} from '@/ts/TimezoneUtils';
import {Moment} from 'moment';
import {NullableOptional, saveDownloadedFile} from '@/ts/utilities';

export class PatientService {
    private readonly timezone: string;


    constructor(timezone: string) {
        this.timezone = timezone;
    }

    /**
     * Fetches a list of patients without the ability to request filters.
     * NOTE: This will not paginate the list and will instead fetch the full list of visible patients
     */
    public async fetchPatientsWithoutFilter(): AsyncEither<Patient[], Error> {
        try {
            const {data}: {data: Patient[]} = await axios.get(process.env.VUE_APP_BACKEND_URI + '/patients/list');
            return new Success(data);
        } catch (e) {
            return new Failure({
                message: 'Unable to list patients',
            });
        }
    }

    /**
     * Fetches a list of patients
     * @param filter Filters to apply to the list
     */
    public async fetchPatients(filter: PatientListFilter): AsyncEither<PaginatedData<Patient>, Error> {
        try {
            // tslint:disable-next-line:max-line-length
            const {data}: {data: PaginatedData<Patient>} = await axios.post(process.env.VUE_APP_BACKEND_URI + '/patients/list', filter);
            data.data.forEach((patient) => {
                this.processPatient(patient);
            });
            return new Success(data);
        } catch (e) {
            return new Failure({
                message: 'Unable to fetch patient list',
            });
        }
    }

    /**
     * Fetches a list of patients that have assignments
     */
    public async fetchAllPatientsWithAssignments(): AsyncEither<Patient[], Error> {
        try {
            // tslint:disable-next-line:max-line-length
            const {data}: {data: Patient[]} = await axios.get(process.env.VUE_APP_BACKEND_URI + '/patients/list-with-assignments');
            data.forEach((patient) => {
                this.processPatient(patient);
            });
            return new Success(data);
        } catch (e) {
            return new Failure({
                message: 'Unable to fetch patient list',
            });
        }
    }

    /**
     * Exports the list of patients as an excel document
     * @param filter Filters to apply to the export
     */
    public async exportPatients(filter: PatientListFilter): AsyncNullSuccess<Error> {
        try {
            const response = await axios.post(process.env.VUE_APP_BACKEND_URI + '/patients/export', filter, {
                responseType: 'blob',
            });
            saveDownloadedFile(response, 'patient-list-export.xlsx');
            return nullSuccess();
        } catch (e) {
            return new Failure({
                message: 'Unable to export patient list',
            });
        }
    }

    /**
     * Creates a new patient
     * @param request New patient information
     */
    public async createPatient(request: CreatePatientRequest): AsyncNullSuccess<Error> {
        try {
            await axios.post(process.env.VUE_APP_BACKEND_URI + '/patients', request);
            return nullSuccess();
        } catch (e) {
            return new Failure({
                message: 'Unable to create patient',
            });
        }
    }

    /**
     * Updates existing information about a patient
     * @param patient Updated patient information
     */
    public async updatePatient(patient: NullableOptional<Patient>): AsyncEither<NullableOptional<Patient>, Error> {
        try {
            await axios.patch(process.env.VUE_APP_BACKEND_URI + '/patients/' + patient.id, patient);
            return new Success(patient);
        } catch (e) {
            return new Failure({
                message: 'Unable to update patient',
            });
        }
    }

    /**
     * Fetches a patient with all of its relationships
     * @param patientId ID of the patient to fetch
     */
    public async fetchPatient(patientId: number): AsyncEither<Patient, Error> {
        try {
            // tslint:disable-next-line:max-line-length
            const {data}: {data: Patient} = await axios.get(process.env.VUE_APP_BACKEND_URI + '/patients/' + patientId);
            return new Success(this.processPatient(data));
        } catch (e) {
            return new Failure({
                message: 'Unable to fetch patient',
            });
        }
    }

    /**
     * Deletes a patient
     * @param patientId ID of the patient to remove
     */
    public async removePatient(patientId: number): AsyncNullSuccess<Error> {
        try {
            await axios.delete(process.env.VUE_APP_BACKEND_URI + '/patients/' + patientId);
            return nullSuccess();
        } catch (e) {
            return new Failure({
                message: 'Failed to remove patient',
            });
        }
    }

    /**
     * Processes the JSON of a patient received from the API
     * Converts date/time fields to moments and ensures all relationships are
     * properly sorted
     * @param patient Patient to process
     */
    private processPatient(patient: Patient): Patient {
        if (patient.dateOfBirth !== null) {
            patient.dateOfBirth = convertToMomentTimezone('UTC', patient.dateOfBirth as string);
        }

        if (patient.assignments !== null && patient.assignments.length > 0) {
            patient.assignments.forEach((assignment) => {
                assignment.startDate = convertToMomentTimezone(this.timezone, assignment.startDate as string);

                if (assignment.endDate !== null) {
                    assignment.endDate = convertToMomentTimezone(this.timezone, assignment.endDate as string);
                }
            });

            patient.assignments.sort((a, b) => {
                return (b.startDate as Moment).diff(a.startDate as Moment);
            });
        } else {
            patient.assignments = [];
        }


        if (patient.feedPrescriptions !== null && patient.feedPrescriptions.length > 0) {
            patient.feedPrescriptions.forEach((prescription) => {
                // tslint:disable-next-line:max-line-length
                prescription.startDate = convertToMomentTimezone(this.timezone, prescription.startDate as string);
            });

            patient.feedPrescriptions.sort((a, b) => {
                return (b.startDate as Moment).diff(a.startDate as Moment);
            });
        } else {
            patient.feedPrescriptions = [];
        }

        if (patient?.goalWeight?.length !== undefined) {
            patient.goalWeight.forEach((goalWeight) => {
                goalWeight.startDate = convertToMomentTimezone(this.timezone, goalWeight.startDate as string);

                if (goalWeight.modifiedDate !== null) {
                    goalWeight.modifiedDate = convertToMomentTimezone(this.timezone, goalWeight.modifiedDate as string);
                }
            });
            patient.goalWeight.sort((a, b) => {
                return (b.startDate as Moment).diff(a.startDate as Moment);
            });
        } else {
            patient.goalWeight = [];
        }

        if (patient?.weights?.length !== undefined) {
            patient.weights.forEach((weight) => {
                weight.date = convertToMomentTimezone(this.timezone, weight.date as string);
            });
            patient.weights.sort((a, b) => {
                return (b.date as Moment).diff(a.date as Moment);
            });
        } else {
            patient.weights = [];
        }

        if (patient.createdDate !== undefined) {
            patient.createdDate = convertToMomentTimezone(this.timezone, patient.createdDate as string);
        }

        return patient;
    }
}
