





























































import {Component, Vue} from 'vue-property-decorator';
import CustomerInfo from '@/components/customers/CustomerInfo.vue';
import UserPreferences from '@/components/users/UserPreferences.vue';
import UserPermissions from '@/components/users/UserPermissions.vue';
import GeneralUserInfo from '@/components/users/GeneralUserInfo.vue';
import User, {MeasurementUnit} from '@/models/User';
import {ButtonType} from '@/components/form/FormTypes';
import {Role, RoleDefinitions} from '@/ts/permissions/Role';
import axios from 'axios';

/**
 * Renders the page to create a new customer
 */
@Component({
    components: {
        CustomerInfo,
        GeneralUserInfo,
        UserPreferences,
        UserPermissions
    }
})
export default class CustomerCreate extends Vue {

    /**
     * Customer information
     */
     customer = {
         name: '',
         customerNumber: '',
         region: null as null | number,
         notes: ''
    };

    /**
     * User information
     */
    user: User = {
        name: '',
        firstName: '',
        lastName: '',
        email: '',
        role: '',
        teams: [],
        username: '',
        measurementUnit: MeasurementUnit.METRIC,
        timezone: '',
        customers: [],
        teamGroup: 0,
        lastPasswordUpdate: null,
        lastTermsConditionsAcceptance: null
    };

    selectedTimezone: any = null;
    selectedRole: any = {
      display: 'Admin',
      id: Role.ADMIN
    };
    selectedRegion: any = null;
    validity: any = {
        'general': false,
        'preferences': false,
        'permissions': false,
        'customer': false,
    };

    disabledPermissionFields = {
        customer: true,
        userRole: true,
    };

    buttonTypes = ButtonType;

    mounted(){
        this.selectedRole = RoleDefinitions.find(value => value.id === Role.ADMIN);
        this.user.role = Role.ADMIN
    }

    /**
     * Updates the selected region information when it changes
     * @param region
     */
    regionChanged(region: any){
         this.selectedRegion = region;
         this.customer.region = region.value;
    }

    /**
     * Updates the selected role information when the field changes
     * @param value
     */
    roleChanged(value: any){
        if(value == null){
            this.user.role = '';
            this.selectedRole = null;
        }else{
            this.user.role = value.id;
            this.selectedRole = value;
        }

    }

    /**
     * Updates the selected timezone when the field changes
     * @param value
     */
    timezoneChanged(value: any){
        if(value == null){
            this.user.timezone = '';
            this.selectedTimezone = null;
        }else{
            this.user.timezone = value.tzName;
            this.selectedTimezone = value;
        }

    }

    handleValidityChange(area: string, validity: boolean){
        this.validity[area] = !validity;
    }

    /**
     * Used to fill in the customer field with the name of the customer being created
     */
    get inProgressCustomer(){
        return {
            id: 0,
            name: this.customer.name
        }
    }

    /**
     * Whether all the form fields are valid
     */
    get isValid(){
        return this.validity.general
            && this.validity.permissions
            && this.validity.preferences
            && this.validity.customer;
    }

    /**
     * Sends the request to create a new customer
     */
    async createCustomer(){
        const requestData = {
            customer: {...this.customer},
            user: {...this.user}
        };
        delete requestData.user.teamGroup;
        delete requestData.user.teams;
        try{
            const {status} = await axios.post(process.env.VUE_APP_BACKEND_URI + '/customers', requestData);
            if(status < 300){
                this.$router.push({name: 'customer-list'});
            }
        }catch(e){
            console.warn(e);
        }
    }
}
