import _Vue from 'vue';
import store from '@/store';
import {SnackbarData, SnackbarRequest} from '@/models/Snackbar';
import {SnackbarActions, SnackbarMutations} from '@/vuex/snackbar';

export function SnackbarPlugin(Vue: typeof _Vue, options?: any): void {
    Vue.prototype.$addSnackbarMessage = (item: SnackbarRequest) => {
        store.dispatch('snackbar/' + SnackbarActions.TRIGGER_SNACKBAR, item);
    };
}
