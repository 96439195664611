


































































import { Component, Vue } from 'vue-property-decorator';
import Table from '@/components/table/Table.vue';
import WeightReportGraph from '@/components/patients/reports/weightHistory/WeightReportGraph.vue';
import {WeightHistoryReport} from '@/models/reports/WeightHistory';
import {ButtonType} from '@/components/form/FormTypes';
import ReportDateSelector from '@/components/reports/ReportDateSelector.vue';
import Multiselect from 'vue-multiselect';
import moment, {Moment} from 'moment';
import {TableCellType, TableHeaderData, TableRowData} from '@/components/table/cells/TableCellData';
import {saveDownloadedFile, sortBy} from '@/ts/utilities';
import {formatMoment, formatMomentDateOnly, getReportDateRange} from '@/ts/TimezoneUtils';
import {DailyAdherenceRecord} from '@/models/reports/AdherenceReport';
import Spinner from '@/components/form/Spinner.vue';
import {SnackbarActions} from '@/vuex/snackbar';
import {SnackbarRequest} from '@/models/Snackbar';
import {namespace} from 'vuex-class';
import {PatientHomeRouteOptions} from '@/models/patients/PatientHome';
import {AuthGetters} from '@/vuex/auth';
import axios from 'axios';
import {Failure, Success} from '@/errors/Either';
import PatientWeightHistoryReportService from '@/services/patients/reports/PatientWeightHistoryReportService';
import PatientAdherenceReportService from '@/services/patients/reports/PatientAdherenceReportService';

enum Options {
    TABLE,
    CHART
}

const snackbar = namespace('snackbar');
const auth = namespace('auth');

/**
 * Renders the weight history report for a single patient
 */
@Component({
    components: {
        Table,
        WeightReportGraph,
        Multiselect,
        ReportDateSelector,
        Spinner,
    }
})
export default class PatientWeightHistoryReport extends Vue {

    @snackbar.Action(SnackbarActions.TRIGGER_SNACKBAR) addSnackbarMessage!: (item: SnackbarRequest) => void;
    @auth.Getter(AuthGetters.MEASUREMENT_UNIT) measurementUnit!: string;

    /**
     * Weight history report data
     */
    weightHistoryReport: WeightHistoryReport | null = null;

    /**
     * Daily adherence data
     */
    adherenceRecords: DailyAdherenceRecord[] = [];

    /**
     * Whether the requests to load data are loading
     */
    loading = true;

    initialTab: number = 1;
    buttonType = ButtonType;

    timezone = '';
    gradient: any = null;

    options = Options;

    /**
     * Field the table of data is currently sorted by
     */
    currentSortKey: string = "date";

    /**
     * Whether the table is sorted ascending
     */
    sortedAsc: boolean = false;

    /**
     * Start date of the report
     */
    startDate!: Moment;

    /**
     * End date of the report
     */
    endDate!: Moment;

    /**
     * Whether the adherence data should be shown on the graph
     */
    showAdherenceData = false;

    /**
     * Whether the adherence data is loading
     */
    adherenceLoading = false;

    patientWeightHistoryReportService = new PatientWeightHistoryReportService(this.$store.state.auth.loggedInUser.timezone);
    patientAdherenceReportService = new PatientAdherenceReportService(this.$store.state.auth.loggedInUser.timezone);

    reportOptions = [
        {
            label: 'Table',
            value: Options.TABLE
        },
        {
            label: 'Chart',
            value: Options.CHART
        }
    ];

    selectedOption = {
        label: 'Chart',
        value: Options.CHART
    };

    created() {
        if(this.$route.query.hasOwnProperty('reportRange') && this.$route.query['reportRange'].length === 1){
            this.initialTab = Number.parseInt(this.$route.query['reportRange'][0] as string);
            const dateRange = getReportDateRange(this.initialTab, this.timezone);
            this.startDate = dateRange.startDate;
            this.endDate = dateRange.endDate;
        }else {
            this.startDate = moment.tz(this.timezone).subtract(27, 'day').set('hour', 0).set('minute', 0).set('second', 0).millisecond(0);
            this.endDate = moment.tz(this.timezone).set('hour', 23).set('minute', 59).set('second', 59).millisecond(0);
        }
    }

    async mounted() {
        //@ts-ignore
        // this.gradient = this.$refs.canvas.getContext('2d').createLinearGradient(0,0, 0,this.$refs.container.clientHeight);
        // this.gradient.addColorStop(0, 'rgb(184,172,225)');
        // this.gradient.addColorStop(1, 'rgba(255,255,255, 0)');
        await this.fetchData();
    }

    /**
     * Fetches the report data when the date range changes
     * @param dates
     */
    datesChanged(dates: any){
        this.startDate = dates.startDate;
        this.endDate = dates.endDate;
        this.fetchData();
    }

    /**
     * Updates the sort information for the table
     * @param message
     */
    sortData(message: any){
        this.currentSortKey = message.sortKey;
        this.sortedAsc = message.sortedAsc;
    }

    /**
     * Updates the state of the show adherence checkbox
     * @param checked
     */
    updateShowAdherence(checked: boolean) {
        this.showAdherenceData = checked;
        this.fetchData();
    }

    navigateToEditWeight() {
        this.$router.push({name: 'patient-home', params: {id: this.$route.params.id}, query: {initialOption: PatientHomeRouteOptions.WEIGHT}});
    }

    /**
     * Table header definition
     */
    get tableHeader(): TableHeaderData[] {
        return [
            {
                sortable: true,
                value: "Timestamp",
                widthClass: "w-1/2",
                sortKey: 'date'
            },
            {
                sortable: false,
                value: "Weight",
                widthClass: "w-1/2",
            },
        ]
    }

    /**
     * Transforms the array of weights into an array of table rows
     */
    get tableData(): TableRowData[] {
        if(this.weightHistoryReport === null || this.weightHistoryReport.weights.length === 0){
            return [];
        }

        return [...this.weightHistoryReport.weights]
            .sort(sortBy(this.currentSortKey, this.sortedAsc))
            .map((weight) => {
                const cells = [
                    {
                        type: TableCellType.NORMAL,
                        primaryValue: formatMomentDateOnly(weight.date as Moment)
                    },
                    {
                        type: TableCellType.NORMAL,
                        primaryValue: weight.weight.toFixed(2) + ` ${this.measurementUnit}`
                    },
                ];
                return {
                    cells,
                    indexKey: weight.id
                }
            })
    }

    /**
     * Fetches the weight history report data
     */
    async fetchData() {
        this.loading = true;
        await Promise.all([
            this.fetchAdherenceData(),
            this.fetchWeightData()
        ]);
        this.loading = false;
    }

    /**
     * Sends the request to fetch the weight history report data
     */
    async fetchWeightData() {
        const response = await this.patientWeightHistoryReportService.fetchWeightHistoryReport(Number.parseInt(this.$route.params.id), this.startDate, this.endDate);
        response.map((report) => {
            this.weightHistoryReport = report;
        })
    }

    /**
     * Sends the request to fetch the daily adherence data
     */
    async fetchAdherenceData() {
        if(!this.showAdherenceData) {
            return;
        }
        this.adherenceLoading = true;
        const response = await this.patientAdherenceReportService.fetchAdherenceDaily(Number.parseInt(this.$route.params.id), this.startDate, this.endDate);
        response.map((records) => {
            this.adherenceRecords = records;
        })
        this.adherenceLoading = false;
    }

    /**
     * Sends the request to download the weight history report as an excel file
     */
    async exportWeightHistoryCsv() {
        const response = await this.patientWeightHistoryReportService.exportWeightHistoryReport(Number.parseInt(this.$route.params.id), this.startDate, this.endDate);
        if(response.isSuccess()){
            this.addSnackbarMessage({
                message: 'Weight history report successfully exported'
            })
        } else {
            this.$addSnackbarMessage({
                message: 'There was an issue exporting the weight history report. Try again later or contact customer support.',
            });
        }
    }

    /**
     * Sends the request to download the weight history report as a PDF
     */
    async exportWeightHistoryPDF() {

        const response = await this.patientWeightHistoryReportService.exportWeightHistoryReportAsPDF(Number.parseInt(this.$route.params.id), this.startDate, this.endDate, {
            graphData: (this.$refs.chartItem as any).$refs.canvas.toDataURL(),
        });
        if(response.isSuccess()){
            this.addSnackbarMessage({
                message: 'Weight history report successfully exported'
            })
        } else {
            this.$addSnackbarMessage({
                message: 'There was an issue exporting the weight history report. Try again later or contact customer support.',
            });
        }
    }
}
