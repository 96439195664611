





















import { Component, Vue } from 'vue-property-decorator';
import MonitorPump from '@/components/devices/marrs/MonitorPump.vue';
import {namespace} from 'vuex-class';
import {PatientDeviceAssignment} from '@/models/patients/Patient';
import moment from 'moment-timezone';
import {Moment} from 'moment';
import {PatientHomeRouteOptions} from '@/models/patients/PatientHome';
import {PatientGetters} from '@/vuex/patients';
import {formatMomentDateOnly} from '@/ts/TimezoneUtils';

const patients = namespace('patients');

@Component({
    components: {
        MonitorPump,
    }
})
export default class PatientMonitorMode extends Vue {
    @patients.State(state => state.homePage.deviceConnectionPending) connectionPending!: boolean;
    @patients.State(state => state.homePage.deviceConnected) connected!: boolean;
    @patients.State(state => state.homePage.patient.assignments) patientAssignments!: PatientDeviceAssignment[];
    @patients.Getter(PatientGetters.GET_LATEST_HOMEPAGE_ASSIGNMENT) latestAssignment!: PatientDeviceAssignment | null;
    @patients.Getter(PatientGetters.GET_FUTURE_ASSIGNMENT_START_DATE) futureAssignmentStartDate!: string | null;

    get deviceId() {
        const today = moment.tz(this.$store.state.auth.loggedInUser.timezone);
        if(this.latestAssignment !== null) {
            return this.latestAssignment.device.id;
        }

        return null;
    }

    sendToCreateAssignment() {
        this.$router.push({
            name: 'patient-home', query: {
                initialOption: PatientHomeRouteOptions.ASSIGNMENTS
            }
        })
    }
}
