










import {Component, Prop, Vue} from 'vue-property-decorator';
import IconDisplay from '@/components/utility/IconDisplay.vue';

/**
 * Renders a component showing whether a device is currently connected
 */
@Component({
    components: {
        IconDisplay,
    }
})
export default class DeviceConnectionStatus extends Vue {
    /**
     * Whether the connection is pending
     */
    @Prop() connectionPending!: boolean;

    /**
     * Whether the device is connected
     */
    @Prop() connected!: boolean;
}
