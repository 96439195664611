






import { Component, Vue } from 'vue-property-decorator';
import {SnackbarData} from '@/models/Snackbar';
import Snackbar from '@/components/utility/Snackbar.vue';
import {namespace} from 'vuex-class';
import {SnackbarMutations} from '@/vuex/snackbar';

const snackbar = namespace('snackbar');

/**
 * Renders a group of snackbar items
 */
@Component({
    components: {
        Snackbar
    }
})
export default class SnackbarContainer extends Vue {

    @snackbar.State(state => state.items) items!: SnackbarData[];
    @snackbar.Mutation(SnackbarMutations.REMOVE_ITEM) removeItem!: (item: SnackbarData) => void;

    /**
     * Removes a snackbar item on dismiss
     * @param item
     */
    handleDismiss(item: SnackbarData){
        this.removeItem(item);
    }

}
