






























import {Component, Prop, Vue} from 'vue-property-decorator';
import ReportDateSelector from '@/components/reports/ReportDateSelector.vue';
import Table from '@/components/table/Table.vue';
import moment, {Moment} from 'moment';
import {PumpSettingsRecord} from '@/models/Devices/MARRS/Records';
import {formatMoment, getReportDateRange} from '@/ts/TimezoneUtils';
import {getFeedMode, PumpSettingsReportData} from '@/models/reports/PumpSettings';
import {TableCellData, TableCellType, TableHeaderData, TableRowData} from '@/components/table/cells/TableCellData';
import {FilterType} from '@/ts/Filter';
import {sortBy} from '@/ts/utilities';
import TextDisplay from '@/components/utility/TextDisplay.vue';
import {SnackbarActions} from '@/vuex/snackbar';
import {SnackbarRequest} from '@/models/Snackbar';
import {namespace} from 'vuex-class';
import {ButtonType} from '@/components/form/FormTypes';
import ConnectSettingsReportService from '@/services/devices/connect/reports/ConnectSettingsReportService';
import PatientPumpSettingsReportService from '@/services/patients/reports/PatientPumpSettingsReportService';

const snackbar = namespace('snackbar');

/**
 * Renders a table describing recent pump settings changes
 */
@Component({
    components:{
        Table,
        ReportDateSelector
    }
})
export default class PumpSettingsTable extends Vue {
    /**
     * Whether to display in patient mode
     */
    @Prop({default: false}) patient!: boolean;

    /**
     * ID of the patient or pump to display report for
     */
    @Prop() itemId!: number;
    @snackbar.Action(SnackbarActions.TRIGGER_SNACKBAR) addSnackbarMessage!: (item: SnackbarRequest) => void;

    /**
     * Currently selected tab for the report date selector
     */
    initialTab: number = 1;

    /**
     * Current start date of the report
     */
    startDate!: Moment;

    /**
     * Current end date of the report
     */
    endDate!: Moment;

    /**
     * Timezone of the user
     */
    timezone: string = "";

    /**
     * Whether the report is loading
     */
    loading = false;

    /**
     * Report data
     */
    reportData: PumpSettingsReportData | null = null;

    /**
     * Field the table is currently sorted by
     */
    currentSortKey: string = "timestamp";

    /**
     * Whether the table is sorted ascending
     */
    sortedAsc: boolean = false;

    /**
     * Feed mode filters
     */
    activeFeedModeFilters: number[] = [];
    buttonTypes = ButtonType

    patientPumpSettingsReportService = new PatientPumpSettingsReportService(this.$store.state.auth.loggedInUser.timezone);
    connectSettingsReportService = new ConnectSettingsReportService(this.$store.state.auth.loggedInUser.timezone);

    created(){
        this.timezone = this.$store.state.auth.loggedInUser.timezone;
        if(this.$route.query.hasOwnProperty('reportRange') && this.$route.query['reportRange'].length === 1){
            this.initialTab = Number.parseInt(this.$route.query['reportRange'][0] as string);
            const dateRange = getReportDateRange(this.initialTab, this.timezone);
            this.startDate = dateRange.startDate;
            this.endDate = dateRange.endDate;
        }else {
            this.startDate = moment.tz(this.timezone).subtract(27, 'day').set('hour', 0).set('minute', 0).set('second', 0).millisecond(0);
            this.endDate = moment.tz(this.timezone).set('hour', 23).set('minute', 59).set('second', 59).millisecond(0);
        }
    }

    async mounted(){
        await this.fetchData();
    }

    /**
     * Table header definition
     */
    get tableHeader(): TableHeaderData[]{
        const headerCells: TableHeaderData[] = [
            {
                sortable: true,
                value: "Timestamp",
                widthClass: "w-1/4",
                sortKey: "timestamp"
            },
        ];

        if (this.patient) {
            headerCells.push({
                sortable: false,
                value: 'Pump serial number',
                widthClass: 'w-1/5'
            })
        }

        headerCells.push({
                sortable: false,
                value: "Feed mode",
                widthClass: `${this.patient ? 'w-3/20' : 'w-1/4'}`,
                filterable: true,
                filterKey: "feedMode",
                filters: [
                    {
                        type: FilterType.MULTISELECT,
                        key: 'feedMode',
                        active: this.activeFeedModeFilters.length > 0,
                        displayText: 'Filter by feed mode',
                        enabled: true,
                        data: {
                            multiselect: true,
                            activeItems: {
                                feedMode: [...this.activeFeedModeFilters]
                            },
                            items: [
                                {
                                    value: 0,
                                    label: "Continuous",
                                    key: "Continuous"
                                },
                                {
                                    value: 1,
                                    label: "Dose",
                                    key: "Dose"
                                },
                                {
                                    value: 2,
                                    label: "Bolus",
                                    key: "Bolus"
                                },
                            ]
                        }
                    }
                ]
            },
            {
                sortable: false,
                value: "Pump settings",
                widthClass: `${this.patient ? 'w-2/5' : 'w-1/2'}`,
            });
        return headerCells;
    }

    /**
     * Transforms an array of pump settings records into an array of table rows
     */
    get tableData(): TableRowData[]{
        if(this.reportData == null){
            return []
        }

        return this.reportData.pumpSettingsRecords
            .filter(this.filterRecords)
            .sort(sortBy(this.currentSortKey, this.sortedAsc))
            .map(record => {
                let components: any[] = [];
                components.push({
                    primaryValue: TextDisplay,
                    componentOptions: {
                        title: 'Feed Rate',
                        class: 'mr-4',
                        value: record.feedRate.toString() + " mL"
                    }
                });

                if(record.feedMode == 1 && record.volume != undefined){
                    components.push({
                        primaryValue: TextDisplay,
                        componentOptions: {
                            title: 'Dose',
                            value: record.volume.toString() + " mL"
                        }
                    })
                }else if(record.feedMode == 2 && record.volume != undefined && record.bolusInterval != undefined && record.numberOfBoluses != undefined){
                    components.push({
                        primaryValue: TextDisplay,
                        componentOptions: {
                            title: 'Volume',
                            class: 'mr-4',
                            value: record.volume.toString() + " mL"
                        }
                    },{
                        primaryValue: TextDisplay,
                        componentOptions: {
                            title: 'Bolus Interval',
                            class: 'mr-4',
                            value: record.bolusInterval.toString() + " hrs"
                        }
                    },{
                        primaryValue: TextDisplay,
                        componentOptions: {
                            title: '# Boluses',
                            value: record.numberOfBoluses.toString()
                        }
                    })
                }

                const cells: TableCellData[] = [
                    {
                        type: TableCellType.NORMAL,
                        primaryValue: formatMoment(record.timestamp as Moment)
                    }
                ];

                if (this.patient) {
                    cells.push({
                        type: TableCellType.NORMAL,
                        primaryValue: record.serialNumber
                    })
                }

                cells.push(
                    {
                        type: TableCellType.NORMAL,
                        primaryValue: getFeedMode(record.feedMode)
                    },
                    {
                        type: TableCellType.SLOT_LIST,
                        primaryValue: "",
                        innerClass: "flex flex-row",
                        components: components
                    }
                );

                return {
                    cells,
                    highlighted: false,
                    indexKey: (record.timestamp as Moment).unix()
                }
            })
    }

    /**
     * Filters out pump settings records based on active feed mode filters
     * @param pumpSettingsRecord
     */
    filterRecords(pumpSettingsRecord: PumpSettingsRecord){
        if(this.activeFeedModeFilters.length == 0){
            return true;
        }

        return this.activeFeedModeFilters.includes(pumpSettingsRecord.feedMode);
    }

    /**
     * Updates the sorting information when the table is sorted
     * @param message
     */
    sortData(message: any){
        this.currentSortKey = message.sortKey;
        this.sortedAsc = message.sortedAsc;
    }

    /**
     * Updates the start and end date of the report when the date selector is updated
     * @param dates
     */
    async datesChanged(dates: any){
        this.startDate = dates.startDate;
        this.endDate = dates.endDate;
        await this.fetchData();
    }

    /**
     * Sends the request to fetch the pump settings data
     */
    async fetchData(){
        this.loading = true;

        if(this.patient){
            const response = await this.patientPumpSettingsReportService.fetchPumpSettingsReport(this.itemId, this.startDate, this.endDate);
            response.map((report) => {
                this.reportData = report;
            })
        }else{
            const response = await this.connectSettingsReportService.fetchSettingsReportForPump(this.startDate, this.endDate, this.itemId);
            response.map((report) => {
                this.reportData = report;
            })
        }

        this.loading = false;
    }

    /**
     * Sends the request to download the report as an excel document
     */
    async exportReportAsExcel() {
        if(this.patient) {
            const response = await this.patientPumpSettingsReportService.exportPumpSettingsReportCsv(this.itemId, this.startDate, this.endDate, {
                includedTypes: this.activeFeedModeFilters
            });
            if(response.isSuccess()) {
                this.addSnackbarMessage({
                    message: 'Patient pump settings report successfully exported'
                })
            } else {
                this.addSnackbarMessage({
                    message: 'There was an issue exporting the patient pump settings report. Check back later or contact customer service.'
                })
            }
        } else {
            const response = await this.connectSettingsReportService.exportSettingsReportForPumpAsExcel(this.startDate, this.endDate, this.itemId, {
                includedTypes: this.activeFeedModeFilters
            });
            response
                .map(() => this.addSnackbarMessage({
                    message: 'Pump settings report successfully exported'
                }))
                .mapErr(() => this.addSnackbarMessage({
                    message: 'There was an issue exporting the pump settings report. Check back later or contact customer service.'
                }))
        }
    }

    /**
     * Sends the request to download the report as a PDF
     */
    async exportReportAsPdf() {
        if(this.patient) {
            const response = await this.patientPumpSettingsReportService.exportPumpSettingsReportPdf(this.itemId, this.startDate, this.endDate, {
                includedTypes: this.activeFeedModeFilters
            });
            if(response.isSuccess()) {
                this.addSnackbarMessage({
                    message: 'Patient pump settings report successfully exported'
                })
            } else {
                this.addSnackbarMessage({
                    message: 'There was an issue exporting the patient pump settings report. Check back later or contact customer service.'
                })
            }
        } else {
            const response = await this.connectSettingsReportService.exportSettingsReportForPumpAsPdf(this.startDate, this.endDate, this.itemId, {
                includedTypes: this.activeFeedModeFilters
            });
            response
                .map(() => this.addSnackbarMessage({
                    message: 'Pump settings report successfully exported'
                }))
                .mapErr(() => this.addSnackbarMessage({
                    message: 'There was an issue exporting the pump settings report. Check back later or contact customer service.'
                }))

        }
    }

    handleFeedModeChange(data: any){
       this.activeFeedModeFilters = data.feedMode;
    }

    clearAllFilters() {
        this.activeFeedModeFilters = []
    }
}
