




























import { Component, Vue } from 'vue-property-decorator';
import ReportDateSelector from '../../reports/ReportDateSelector.vue';
import Table from '../../table/Table.vue';
import moment, {Moment} from 'moment';
import {TableCellData, TableCellType, TableHeaderData, TableRowData} from "../../table/cells/TableCellData";
import {DiagnosticRecord} from "@/models/Devices/MARRS/Records";
import {formatMoment, getReportDateRange} from "@/ts/TimezoneUtils";
import {sortBy} from "@/ts/utilities";
import {ButtonType} from '@/components/form/FormTypes';
import ConnectDiagnosticsService from '@/services/devices/connect/ConnectDiagnosticsService';

/**
 * Renders diagnostic information for a specific pump
 */
@Component({
    components: {
        Table,
        ReportDateSelector
    }
})
export default class DiagnosticsTable extends Vue {
    buttonTypes = ButtonType;

    /**
     * Whether the request is loading
     */
    loading = false;

    /**
     * The field the table is currently sorted by
     */
    currentSortKey = 'timestamp';

    /**
     * Whether the table is currently sorting ascending
     */
    sortedAsc = false;

    /**
     * Array of diagnostic records
     */
    diagnosticsRecords: DiagnosticRecord[] = [];

    /**
     * Initial date range fo the report
     */
    initialTab = 1;

    /**
     * User's timezone
     */
    timezone = '';

    /**
     * Start date of the report
     */
    startDate: Moment | Date | null = null;

    /**
     * End date of the report
     */
    endDate: Moment | Date | null = null;
    deviceId: string = '';
    connectDiagnosticsService = new ConnectDiagnosticsService(this.$store.state.auth.loggedInUser.timezone);

    created(){
        this.timezone = this.$store.state.auth.loggedInUser.timezone;
        if(this.$route.query.hasOwnProperty('reportRange') && this.$route.query['reportRange'].length === 1){
            this.initialTab = Number.parseInt(this.$route.query['reportRange'][0] as string);
            const dateRange = getReportDateRange(this.initialTab, this.timezone);
            this.startDate = dateRange.startDate;
            this.endDate = dateRange.endDate;
        }else {
            this.startDate = moment.tz(this.timezone).subtract(27, 'day').set('hour', 0).set('minute', 0).set('second', 0);
            this.endDate = moment.tz(this.timezone).set('hour', 23).set('minute', 59).set('second', 59);
        }
        this.deviceId = this.$route.params.id;
    }

    /**
     * Fetches report data on component render
     */
    mounted(){
        this.fetchData();
    }

    /**
     * Reacts to a date change from the report date selector
     * @param dates
     */
    datesChanged(dates: any){
        this.startDate = dates.startDate;
        this.endDate = dates.endDate;
        this.fetchData();
    }

    /**
     * Reacts to a change in sorting of the table data
     * @param message
     */
    sortData(message: any){
        this.currentSortKey = message.sortKey;
        this.sortedAsc = message.sortedAsc;
    }

    /**
     * Sends the request to fetch the diagnostic data for the pump
     */
    async fetchData(){
        if(this.startDate === null || this.endDate === null){
            return;
        }
        this.loading = true;
        const result = await this.connectDiagnosticsService.fetchDiagnosticRecords(this.startDate, this.endDate, Number.parseInt(this.deviceId));
        result.map(record => {
            this.diagnosticsRecords = record;
        });
        this.loading = false;
    }

    /**
     * Sends the request to download the excel export of the diagnostic records
     */
    async exportDiags() {
        if(this.startDate === null || this.endDate === null){
            return;
        }
        const response = await this.connectDiagnosticsService.exportDiags(this.startDate, this.endDate, Number.parseInt(this.deviceId));
        if(response.isSuccess()) {
            this.$addSnackbarMessage({
                message: 'Diagnostics successfully exported',
            });
        } else {
            this.$addSnackbarMessage({
                message: 'There was an issue exporting the diagnostic report. Try again later or contact customer support.',
            });
        }

    }

    /**
     * Performs a null safe get on the diagnostic fields
     */
    nullSafeDiagsGet(value: number | null){
        if(value === null){
            return ""
        }

        return value.toString();
    }

    /**
     * Table header definition
     */
    get tableHeader(): TableHeaderData[]{
        return [
            {
                sortable: true,
                value: "Timestamp",
                widthClass: "w-32",
                sortKey: 'timestamp'
            },
            {
                sortable: false,
                value: "Wireless Address",
                widthClass: "w-64",
            },
            {
                sortable: false,
                value: "Software Version",
                widthClass: "w-32",
            },
            {
                sortable: false,
                value: "UI Version",
                widthClass: "w-32",
            },
            {
                sortable: false,
                value: "Xbee Version",
                widthClass: "w-32",
            },
            {
                sortable: false,
                value: "Record Type",
                widthClass: "w-32",
            },
            {
                sortable: false,
                value: "Result (if certification)",
                widthClass: "w-32",
            },
            {
                sortable: false,
                value: "Hardware Version",
                widthClass: "w-32",
            },
            {
                sortable: false,
                value: "Sound Volume Difference",
                widthClass: "w-32",
            },
            {
                sortable: false,
                value: "Stop Light Green On",
                widthClass: "w-32",
            },
            {
                sortable: false,
                value: "Stop Light Green Off",
                widthClass: "w-32",
            },
            {
                sortable: false,
                value: "Stop Light Yellow On",
                widthClass: "w-32",
            },
            {
                sortable: false,
                value: "Stop Light Yellow Off",
                widthClass: "w-32",
            },
            {
                sortable: false,
                value: "Stop Light Red On",
                widthClass: "w-32",
            },
            {
                sortable: false,
                value: "Stop Light Red Off",
                widthClass: "w-32",
            },
            {
                sortable: false,
                value: "AC Power On",
                widthClass: "w-32",
            },
            {
                sortable: false,
                value: "Backlight Cathode",
                widthClass: "w-32",
            },
            {
                sortable: false,
                value: "Backlight Anode",
                widthClass: "w-32",
            },
            {
                sortable: false,
                value: "Set Detection No Magnet 1",
                widthClass: "w-32",
            },
            {
                sortable: false,
                value: "Set Detection No Magnet 2",
                widthClass: "w-32",
            },
            {
                sortable: false,
                value: "Set Detection No Magnet 3",
                widthClass: "w-32",
            },
            {
                sortable: false,
                value: "Set Detection No Magnet 4",
                widthClass: "w-32",
            },
            {
                sortable: false,
                value: "Peak US Voltage Up No Fluid",
                widthClass: "w-32",
            },
            {
                sortable: false,
                value: "US Frequency Up No Fluid",
                widthClass: "w-32",
            },
            {
                sortable: false,
                value: "Peak US Voltage Down No Fluid",
                widthClass: "w-32",
            },
            {
                sortable: false,
                value: "US Frequency Down No Fluid",
                widthClass: "w-32",
            },
            {
                sortable: false,
                value: "Peak US Voltage Up VCO Inhibit",
                widthClass: "w-32",
            },
            {
                sortable: false,
                value: "Peak US Voltage Down VCO Inhibit",
                widthClass: "w-32",
            },
            {
                sortable: false,
                value: "Voltage Regulator Bit Flags",
                widthClass: "w-32",
            },
            {
                sortable: false,
                value: "Rail ADC 5 Volt",
                widthClass: "w-32",
            },
            {
                sortable: false,
                value: "Rail ADC 8 Volt",
                widthClass: "w-32",
            },
            {
                sortable: false,
                value: "Reference ADC",
                widthClass: "w-32",
            },
            {
                sortable: false,
                value: "Set Detection Magnet 2",
                widthClass: "w-32",
            },
            {
                sortable: false,
                value: "Set Detection Magnet 3",
                widthClass: "w-32",
            },
            {
                sortable: false,
                value: "Set Detection Magnet 4",
                widthClass: "w-32",
            },
            {
                sortable: false,
                value: "Rotor Current Peak to Peak No Tube",
                widthClass: "w-32",
            },
            {
                sortable: false,
                value: "Rotor Current Average No Tube",
                widthClass: "w-32",
            },
            {
                sortable: false,
                value: "Set Detection Magnet 1",
                widthClass: "w-32",
            },
            {
                sortable: false,
                value: "Rotor Current Peak to Peak Tube",
                widthClass: "w-32",
            },
            {
                sortable: false,
                value: "Rotor Current Average Tube",
                widthClass: "w-32",
            },
            {
                sortable: false,
                value: "Rotor Revolution Duration",
                widthClass: "w-32",
            },
            {
                sortable: false,
                value: "Rotor On Accuracy",
                widthClass: "w-32",
            },
            {
                sortable: false,
                value: "Cycle Completion Time",
                widthClass: "w-32",
            },
            {
                sortable: false,
                value: "Peak US Voltage Up Fluid",
                widthClass: "w-32",
            },
            {
                sortable: false,
                value: "US Frequency Up Fluid",
                widthClass: "w-32",
            },
            {
                sortable: false,
                value: "Peak US Voltage Down Fluid",
                widthClass: "w-32",
            },
            {
                sortable: false,
                value: "US Frequency Down Fluid",
                widthClass: "w-32",
            },
            {
                sortable: false,
                value: "Power Button",
                widthClass: "w-32",
            },
            {
                sortable: false,
                value: "Button 1",
                widthClass: "w-32",
            },
            {
                sortable: false,
                value: "Button 2",
                widthClass: "w-32",
            },
            {
                sortable: false,
                value: "Button 3",
                widthClass: "w-32",
            },
            {
                sortable: false,
                value: "Button 4",
                widthClass: "w-32",
            },
            {
                sortable: false,
                value: "Button 5",
                widthClass: "w-32",
            },
        ]
    }

    /**
     * Transforms the array of diagnostic records into an array of table rows
     */
    get tableData(): TableRowData[] {
        return this.diagnosticsRecords
            .sort(sortBy(this.currentSortKey, this.sortedAsc))
            .map(record => {
            const cells: TableCellData[] = [
                {
                    type: TableCellType.NORMAL,
                    primaryValue: formatMoment(record.timestamp as Moment)
                },
                {
                    type: TableCellType.NORMAL,
                    primaryValue: record.wirelessAddress
                },
                {
                    type: TableCellType.NORMAL,
                    primaryValue: record.softwareVersion.toFixed(2)
                },
                {
                    type: TableCellType.NORMAL,
                    primaryValue: record.uiVersion.toFixed(2)
                },
                {
                    type: TableCellType.NORMAL,
                    primaryValue: record.xbeeVersion
                },
                {
                    type: TableCellType.NORMAL,
                    primaryValue: record.testType
                },
                {
                    type: TableCellType.NORMAL,
                    primaryValue: `${record.testResult !== null ? (record.testResult ? 'Pass' : 'Fail') : 'N/A'}`
                },
                {
                    type: TableCellType.NORMAL,
                    primaryValue: this.nullSafeDiagsGet(record.hardwareVersion)
                },
                {
                    type: TableCellType.NORMAL,
                    primaryValue: this.nullSafeDiagsGet(record.soundVolumeDifference)
                },
                {
                    type: TableCellType.NORMAL,
                    primaryValue: this.nullSafeDiagsGet(record.stopLightGreenOn)
                },
                {
                    type: TableCellType.NORMAL,
                    primaryValue: this.nullSafeDiagsGet(record.stopLightGreenOff)
                },
                {
                    type: TableCellType.NORMAL,
                    primaryValue: this.nullSafeDiagsGet(record.stopLightYellowOn)
                },
                {
                    type: TableCellType.NORMAL,
                    primaryValue: this.nullSafeDiagsGet(record.stopLightYellowOff)
                },
                {
                    type: TableCellType.NORMAL,
                    primaryValue: this.nullSafeDiagsGet(record.stopLightRedOn)
                },
                {
                    type: TableCellType.NORMAL,
                    primaryValue: this.nullSafeDiagsGet(record.stopLightRedOff)
                },
                {
                    type: TableCellType.NORMAL,
                    primaryValue: this.nullSafeDiagsGet(record.acPowerOn)
                },
                {
                    type: TableCellType.NORMAL,
                    primaryValue: this.nullSafeDiagsGet(record.backlightCathode)
                },
                {
                    type: TableCellType.NORMAL,
                    primaryValue: this.nullSafeDiagsGet(record.backlightAnode)
                },
                {
                    type: TableCellType.NORMAL,
                    primaryValue: this.nullSafeDiagsGet(record.setDetectionNoMagnet1)
                },
                {
                    type: TableCellType.NORMAL,
                    primaryValue: this.nullSafeDiagsGet(record.setDetectionNoMagnet2)
                },
                {
                    type: TableCellType.NORMAL,
                    primaryValue: this.nullSafeDiagsGet(record.setDetectionNoMagnet3)
                },
                {
                    type: TableCellType.NORMAL,
                    primaryValue: this.nullSafeDiagsGet(record.setDetectionNoMagnet4)
                },
                {
                    type: TableCellType.NORMAL,
                    primaryValue: this.nullSafeDiagsGet(record.peakUsVoltageUpNoFluid)
                },
                {
                    type: TableCellType.NORMAL,
                    primaryValue: this.nullSafeDiagsGet(record.usFrequencyUpNoFluid)
                },
                {
                    type: TableCellType.NORMAL,
                    primaryValue: this.nullSafeDiagsGet(record.peakUsVoltageDownNoFluid)
                },
                {
                    type: TableCellType.NORMAL,
                    primaryValue: this.nullSafeDiagsGet(record.usFrequencyDownNoFluid)
                },
                {
                    type: TableCellType.NORMAL,
                    primaryValue: this.nullSafeDiagsGet(record.peakUsVoltageUpVcoInhibit)
                },
                {
                    type: TableCellType.NORMAL,
                    primaryValue: this.nullSafeDiagsGet(record.peakUsVoltageDownVcoInhibit)
                },
                {
                    type: TableCellType.NORMAL,
                    primaryValue: this.nullSafeDiagsGet(record.voltageRegulatorBitFlags)
                },
                {
                    type: TableCellType.NORMAL,
                    primaryValue: this.nullSafeDiagsGet(record.railAdc5Volt)
                },
                {
                    type: TableCellType.NORMAL,
                    primaryValue: this.nullSafeDiagsGet(record.railAdc8Volt)
                },
                {
                    type: TableCellType.NORMAL,
                    primaryValue: this.nullSafeDiagsGet(record.referenceAdc)
                },
                {
                    type: TableCellType.NORMAL,
                    primaryValue: this.nullSafeDiagsGet(record.setDetectionMagnet2)
                },
                {
                    type: TableCellType.NORMAL,
                    primaryValue: this.nullSafeDiagsGet(record.setDetectionMagnet3)
                },
                {
                    type: TableCellType.NORMAL,
                    primaryValue: this.nullSafeDiagsGet(record.setDetectionMagnet4)
                },
                {
                    type: TableCellType.NORMAL,
                    primaryValue: this.nullSafeDiagsGet(record.rotorCurrentPeakToPeakNoTube)
                },
                {
                    type: TableCellType.NORMAL,
                    primaryValue: this.nullSafeDiagsGet(record.rotorCurrentAverageNoTube)
                },
                {
                    type: TableCellType.NORMAL,
                    primaryValue: this.nullSafeDiagsGet(record.setDetectionMagnet1)
                },
                {
                    type: TableCellType.NORMAL,
                    primaryValue: this.nullSafeDiagsGet(record.rotorCurrentPeakToPeakTube)
                },
                {
                    type: TableCellType.NORMAL,
                    primaryValue: this.nullSafeDiagsGet(record.rotorCurrentAverageTube)
                },
                {
                    type: TableCellType.NORMAL,
                    primaryValue: this.nullSafeDiagsGet(record.rotorRevolutionDuration)
                },
                {
                    type: TableCellType.NORMAL,
                    primaryValue: this.nullSafeDiagsGet(record.rotorOnAccuracy)
                },
                {
                    type: TableCellType.NORMAL,
                    primaryValue: this.nullSafeDiagsGet(record.cycleCompletionTime)
                },
                {
                    type: TableCellType.NORMAL,
                    primaryValue: this.nullSafeDiagsGet(record.peakUsVoltageUpFluid)
                },
                {
                    type: TableCellType.NORMAL,
                    primaryValue: this.nullSafeDiagsGet(record.usFrequencyUpFluid)
                },
                {
                    type: TableCellType.NORMAL,
                    primaryValue: this.nullSafeDiagsGet(record.peakUsVoltageDownFluid)
                },
                {
                    type: TableCellType.NORMAL,
                    primaryValue: this.nullSafeDiagsGet(record.usFrequencyDownFluid)
                },
                {
                    type: TableCellType.NORMAL,
                    primaryValue: this.nullSafeDiagsGet(record.powerButton)
                },
                {
                    type: TableCellType.NORMAL,
                    primaryValue: this.nullSafeDiagsGet(record.button1)
                },
                {
                    type: TableCellType.NORMAL,
                    primaryValue: this.nullSafeDiagsGet(record.button2)
                },
                {
                    type: TableCellType.NORMAL,
                    primaryValue: this.nullSafeDiagsGet(record.button3)
                },
                {
                    type: TableCellType.NORMAL,
                    primaryValue: this.nullSafeDiagsGet(record.button4)
                },
                {
                    type: TableCellType.NORMAL,
                    primaryValue: this.nullSafeDiagsGet(record.button5)
                },
            ];

            return {
                cells,
                indexKey: record.id
            }
        })
    }
}
