
























import {Component, Prop, Vue} from "vue-property-decorator";
import Table from '@/components/table/Table.vue';
import {TableCellData, TableCellType, TableHeaderData, TableRowData} from "@/components/table/cells/TableCellData";
import User from '@/models/User';
import {RoleNames} from '@/ts/permissions/Role';
import CustomerService from '@/services/CustomerService';

/**
 * Creates a table representing the users that exist for this custoemr
 */
@Component({
    components: {
        Table
    }
})
export default class CustomerUsers extends Vue {

    /**
     * Current customer ID
     */
    @Prop() customerId!: number;

    /**
     * Table header definition
     */
    tableHeader: TableHeaderData[] = [
        {
            sortable: false,
            value: "Name",
            widthClass: "w-1/5",
            sortKey: 'name'
        },
        {
            sortable: false,
            value: "Username",
            widthClass: "w-1/5",
            sortKey: 'username'
        },
        {
            sortable: false,
            value: "Role",
            widthClass: "w-1/5",
            sortKey: 'role'
        },
        {
            sortable: false,
            value: "Last login",
            widthClass: "w-2/5",
            sortKey: 'team'
        }
    ];

    /**
     * Field the table is currently sorted by
     */
    currentSortKey = 'name';

    /**
     * Whether the list is currently sorted in ascending order
     */
    sortedAsc = true;

    /**
     * Whether the list of users is currently loading
     */
    loading = true;

    /**
     * List of users for this customer
     */
    users: User[] = [];

    customerService = new CustomerService(this.$store.state.auth.loggedInUser.timezone);

    /**
     * Fetches the list of users for this customer on component load
     */
    async mounted(){
        this.loading = true;
        const response = await this.customerService.fetchUsersAssignedToCustomer(this.customerId);
        response.map((users) => this.users = users);
        this.loading = false;
    }

    /**
     * Transforms the list of fetched users into an array of table rows
     */
    get tableCellData(): TableRowData[]{
        const vm = this;
        return this.users.map(function(user: User): TableRowData{
            const cells = [
                {
                    type: TableCellType.TWO_ROW,
                    primaryValue: user.firstName + " " + user.lastName,
                    secondaryValue: user.email
                },
                {
                    type: TableCellType.NORMAL,
                    primaryValue: user.username
                },
                {
                    type: TableCellType.NORMAL,
                    primaryValue: RoleNames[user.role]
                }
                ,
                {
                    type: TableCellType.NORMAL,
                    primaryValue: user.lastLogin
                }
            ] as TableCellData[];

            return {
                cells,
                indexKey: user.id
            }
        })
    }

    /**
     * Called when the sort direction or field is changed
     * Updates the values so the tableCellData property can update
     * @param message
     */
    sortData(message: any){
        this.currentSortKey = message.sortKey;
        this.sortedAsc = message.sortedAsc;
    }
}
