











































import {Component, Prop, Vue} from "vue-property-decorator";
import FeedingHistoryChart from '@/components/reports/feeding-history/FeedingHistoryChart.vue';
import {FeedHistoryReport} from "@/models/reports/FeedHistory";
import Spinner from '@/components/form/Spinner.vue';
import ReportDateSelector from '@/components/reports/ReportDateSelector.vue';
import {ButtonType} from "@/components/form/FormTypes";
import {Moment} from 'moment';

/**
 * Renders a chart of feeding history data
 */
@Component({
    components: {
        FeedingHistoryChart,
        Spinner,
        ReportDateSelector
    }
})
export default class CompactPumpFeedingHistoryGraph extends Vue {

    /**
     * Feeding history data
     */
    @Prop() feedHistoryReport!: FeedHistoryReport;

    /**
     * Whether the request to fetch chart data is loading
     */
    @Prop() loading!: boolean;

    /**
     * Whether the chart should react to date changes by teh parent
     */
    @Prop({default: false}) outsideSync!: boolean;

    /**
     * Start date of the parent. Only used when outsideSync is true
     */
    @Prop({default: null}) outsideStartDate!: Moment | null;

    /**
     * End date of the parent. Only used when outsideSync is true
     */
    @Prop({default: null}) outsideEndDate!: Moment | null;

    /**
     * Initial report date option that should be selected
     */
    @Prop({default: 1}) initialTab!: number;

    /**
     * Whether the show report button should be shown
     */
    @Prop({default: true}) showFullReportButton!: boolean;

    /**
     * How many days of offset should be applied to the start/end date
     */
    @Prop({default :0} ) dateOffset!: number;
    buttonTypes = ButtonType;

    timezone = '';
    gradient: any = null;

    created(){
        this.timezone = this.$store.state.auth.loggedInUser.timezone;
    }

    mounted(){
        //@ts-ignore
        this.gradient = this.$refs.canvas.getContext('2d').createLinearGradient(0,0, 0,this.$refs.container.clientHeight);
        this.gradient.addColorStop(0, 'rgb(184,172,225)');
        this.gradient.addColorStop(1, 'rgba(255,255,255, 0)');
    }

    /**
     * Reacts to date changes from the report date selector
     * @param dates
     */
    datesChanged(dates: any){
        this.$emit('dates', dates);
    }

    navigateToFeedingHistory(){
        this.$router.push({name: 'marrs-feeding-history-report', query: {reportRange: [this.initialTab.toString()]}})
    }

}
