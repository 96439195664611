








import { Component, Vue } from 'vue-property-decorator';
import CompactPumpFeedingHistoryGraph from '../reports/CompactPumpFeedingHistoryGraph.vue';
import moment, {Moment} from "moment";
import {StatusRecord} from '@/models/Devices/MARRS/Records';
import {convertToMomentTimezone} from "@/ts/TimezoneUtils";
import ConnectFeedingHistoryReportService from '@/services/devices/connect/reports/ConnectFeedingHistoryReportService';

/**
 * Renders the feeding history graph in card form
 */
@Component({
    components: {
        CompactPumpFeedingHistoryGraph
    }
})
export default class FeedHistoryCard extends Vue {

    /**
     * Feeding history report information
     */
    feedHistoryReport: any = null;

    /**
     * Whether the data is currently loading
     */
    loading = true;

    /**
     * Start date for the report
     */
    startDate!: Moment;

    /**
     * End date for the report
     */
    endDate!: Moment;

    /**
     * ID of the device to fetch report for
     */
    deviceId!: string;

    /**
     * Timezone of the user
     */
    timezone: string = '';
    connectFeedingHistoryReportService = new ConnectFeedingHistoryReportService(this.$store.state.auth.loggedInUser.timezone);

    /**
     * Sets up initial start/end dates on component creation
     */
    created(){
        this.timezone = this.$store.state.auth.loggedInUser.timezone;
        this.startDate = moment.tz(this.timezone).subtract(27, 'day').set('hour', 0).set('minute', 0).set('second', 0);
        this.endDate = moment.tz(this.timezone).set('hour', 23).set('minute', 59).set('second', 59);
        this.deviceId = this.$route.params.id;
    }

    /**
     * On component first load, fetch the initial data
     */
    async mounted(){
        await this.fetchData()
    }

    /**
     * Update the start/end date when they change and refresh the report
     * @param dates
     */
    handleDateChange(dates: any) {
        this.startDate = dates.startDate;
        this.endDate = dates.endDate;
        this.fetchData();
    }

    /**
     * Sends the request to fetch the report information
     */
    async fetchData(){
        this.loading = true;
        const response = await this.connectFeedingHistoryReportService.fetchFeedingHistoryReportForPump(this.startDate, this.endDate, Number.parseInt(this.deviceId));
        response
            .map((feedHistoryData) => this.feedHistoryReport = feedHistoryData)
            .mapErr(() => this.$addSnackbarMessage({
                message: 'There was an issue fetching the feeding history data. Try again later or contact customer support'
            }))
        this.loading = false;
    }

    /**
     * Sends the request to download the excel version of the report
     */
    async exportExcel() {
        const response = await this.connectFeedingHistoryReportService.exportFeedingHistoryReportAsExcel(this.startDate, this.endDate, Number.parseInt(this.deviceId));
        response
            .map(() => this.$addSnackbarMessage({
                message: 'Feeding history exported successfully'
            }))
            .mapErr(() => this.$addSnackbarMessage({
                message: 'There was an error export the data. Try again later or contact customer support'
            }))

    }

    /**
     * Sends the request to download the PDF version of the report
     */
    async exportPdf() {

        const response = await this.connectFeedingHistoryReportService.exportFeedingHistoryReportAsPdf(this.startDate, this.endDate, Number.parseInt(this.deviceId), {
            graphData: (this.$refs.feedHistoryGraph as any).$refs.chartItem.$refs.canvas.toDataURL(),
        });
        response
            .map(() => this.$addSnackbarMessage({
                message: 'Feeding history exported successfully'
            }))
            .mapErr(() => this.$addSnackbarMessage({
                message: 'There was an error export the data. Try again later or contact customer support'
            }))
    }
}
