import {Module} from 'vuex';
import User from '@/models/User';
import axios from 'axios';
import Vue from 'vue';
import {sortBy} from '@/ts/utilities';

export interface UsersModel {
    list: User[];
    userBeingEdited: User | null;
}

export enum UserGetters {
    SORTED_LIST = 'sortedList',
    FILTER_LIST = 'filterList',
    FTILERED_SORTED_LIST = 'filterSortedList',
}

export enum UserMutations {
    SET_USER_LIST = 'setUserList',
    SET_EDIT_USER = 'setEditUser'
}

export enum UserActions {
    GET_USER_LIST = 'getListOfUsers',
}

const users: Module<UsersModel, any> = {
    namespaced: true,
    state: {
        list: [],
        userBeingEdited: null,
    },
    mutations: {
        [UserMutations.SET_USER_LIST]: (state, userList) => {
            Vue.set(state, 'list', userList);
            // state.list = userList;
        },
        [UserMutations.SET_EDIT_USER]: (state, user) => {
            state.userBeingEdited = user;
        },
    },
    actions: {
        async [UserActions.GET_USER_LIST](context) {
            const accessToken = context.rootGetters['oidcStore/oidcAccessToken'];
            // axios.defaults.headers['Authorization'] = 'Bearer ' + accessToken;
            const { data } = await axios.get(process.env.VUE_APP_BACKEND_URI + '/users');
            context.commit(UserMutations.SET_USER_LIST, data);
        },
    },
    getters: {
        [UserGetters.SORTED_LIST]: (state) => (sortKey: string | null, sortedAsc: boolean) => {
            if (sortKey === '' || sortKey === null) {
                return state.list;
            }
            return state.list.sort(sortBy(sortKey, sortedAsc));
        },
        [UserGetters.FILTER_LIST]: (state) => (team: string) => {
            return state.list.filter((user) => user.teams.map((team2) => team2.name).includes(team) );
        },
        [UserGetters.FTILERED_SORTED_LIST]:
            (state, getters) => (sortKey: string | null, sortedAsc: boolean, sortFunc: (user: User) => boolean) => {
            if (sortFunc === null) {
                return getters[UserGetters.SORTED_LIST](sortKey, sortedAsc);
            } else {
                return getters[UserGetters.SORTED_LIST](sortKey, sortedAsc)
                    .filter( sortFunc );
            }
        },
    },
};

export default users;
