





































































import { Component, Vue } from 'vue-property-decorator';
import {Patient} from '@/models/patients/Patient';
import {namespace} from 'vuex-class';
import Spinner from '@/components/form/Spinner.vue';
import {ButtonType} from '@/components/form/FormTypes';
import ConfirmationModal from '@/components/modal/ConfirmationModal.vue';
import {ModalMutations} from '@/vuex/modal';
import PatientFilesService from '@/services/patients/PatientFilesService';

const patients = namespace('patients');
const modal = namespace('modal');

/**
 * Renders a card displaying PDF files associated with the patient
 */
@Component({
    components: {
        Spinner,
        ConfirmationModal,
    }
})
export default class PatientFileCard extends Vue {
    patientFilesService = new PatientFilesService(this.$store.state.auth.loggedInUser.timezone);
    @patients.State(state => state.homePage.patient) patient!: Patient;
    @modal.Mutation(ModalMutations.CHANGE_MODAL_STATE) changeModalState!: (state: boolean) => void;

    /**
     * List of files currently associated with the patient
     */
    files: string[] = [];

    /**
     * Whether the request to fetch the list of files is pending
     */
    loading = false;

    /**
     *  Whether the delete file confirmation modal is showing
     */
    deleteModalShowing = false;

    /**
     * Whether the modal for a file being too big is showing
     */
    tooBigModalShowing = false;

    /**
     *  Name of file being deleted
     */
    deleteFileName = ''

    /**
     * Currently selected file for upload
     */
    file: File | null = null;
    buttonTypes = ButtonType;

    async mounted() {
        this.fetchFiles()
    }

    /**
     * On picking a file from teh file chooser, this grabs a reference to that file for upload
     */
    handleFileUpload() {
        this.file = (this.$refs.file as any).files[0];
        if(!this.isUploadDisabled) {
            this.uploadFile();
        } else if(this.isFileTooBig) {
            this.showTooBigModal()
        }
    }

    /**
     * Fetches the list of files currently associated with the patient
     */
    async fetchFiles() {
        this.loading = true;
        const response = await this.patientFilesService.fetchPatientFiles(this.patient.id as number)
        response.map(data => {
            this.files = data;
        });
        this.loading = false;
    }

    /**
     * Sends the request to upload a new file
     */
    async uploadFile() {
        if(this.file == null) {
            return
        }

        const response = await this.patientFilesService.createPatientFile(this.patient.id as number, this.file);
        if(response.isSuccess()) {
            this.$addSnackbarMessage({
                message: "File uploaded successfully"
            });
            this.files.push(this.file.name);
            this.file = null;
            (this.$refs.file as any).value = null
        } else {
            response.mapErr(err => {
                this.$addSnackbarMessage({
                    message: err.message
                })
            })
        }
    }

    /**
     * Determines if a file exceeds the max file size
     */
    get isFileTooBig() {
        return (this.file !== null && this.file.size > 2500000)
    }

    /**
     * Determines if the upload button is disabled because the file is too big
     * or has not been chosen
     */
    get isUploadDisabled() {
        return this.isFileTooBig || (this.file === null);
    }

    showDeleteModal(name: string) {
        this.deleteModalShowing = true;
        this.changeModalState(true);
        this.deleteFileName = name;
    }

    hideDeleteModal() {
        this.deleteModalShowing = false;
        this.changeModalState(false);
        this.deleteFileName = ''
    }

    showTooBigModal() {
        this.tooBigModalShowing = true;
        this.changeModalState(true);
    }

    hideTooBigModal() {
        this.tooBigModalShowing = false;
        this.changeModalState(false);
        this.file = null;
        (this.$refs.file as any).value = null
    }

    /**
     * Sends the request to remove a specific file
     * @param name Name of the file to remove
     */
    async deleteFile(name: string) {
        const response = await this.patientFilesService.deletePatientFile(this.patient.id as number, name);
        if(response.isSuccess()) {
            this.$addSnackbarMessage({
                message: "File successfully removed"
            });
            const index = this.files.findIndex(fName => fName === name);
            if(index >= 0) {
                this.files.splice(index, 1);
            }
            this.hideDeleteModal()
        }  else {
            response.mapErr(err => {
                this.$addSnackbarMessage({
                    message: err.message
                })
            })
        }
    }

    /**
     * Sends the request to download a specific file
     * @param name Name of the file to download
     */
    async downloadFile(name: string) {
        const response = await this.patientFilesService.downloadPatientFile(this.patient.id as number, name)
        if(response.isSuccess()) {
            this.$addSnackbarMessage({
                message: "File downloaded successfully"
            })
        } else {
            response.mapErr(err => {
                this.$addSnackbarMessage({
                    message: err.message
                })
            })
        }
    }
}
