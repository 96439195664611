import {Customer} from '@/models/customers/Customer';
import {Team} from '@/models/Teams';
import {Searchable} from '@/models/Searchable';
import {Role} from '@/ts/permissions/Role';
import {Moment} from 'moment';
import {Region} from '@/ts/Region';

/**
 * Represents a single user
 */
export default interface User {
    id?: number;
    name: string;
    firstName?: string;
    lastName?: string;
    email: string;
    role: string;
    teams: Team[];
    username: string;
    measurementUnit?: MeasurementUnit;
    timezone?: string;
    teamGroup?: number;
    lastLogin?: string | Moment | null;
    customers: Customer[];
    lastPasswordUpdate: string | Moment | null;
    termsAndConditionsUpdateNeeded?: boolean;
    lastTermsConditionsAcceptance: string | Moment | null;
    baaAgreementNeeded?: boolean;
    licenseAgreementNeeded?: boolean;
}

export interface UserFilter {
    search: string;
    searchable: Searchable;
    sortKey: string;
    sortedAsc: boolean;
    roles: Role[];
    teams: number[];
    teamGroupFilters: any[];
}

export enum MeasurementUnit {
    IMPERIAL = 'IMPERIAL',
    METRIC = 'METRIC',
}

/**
 * Represents terms and conditions for a user
 */
export interface TermsAndConditions {
    id: number;
    version: number;
    startDate: string | Moment;
    isActive: boolean;
    agreementText: string;
    region: Region;
}
