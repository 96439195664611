













import { Component, Vue } from 'vue-property-decorator';
import {namespace} from "vuex-class";
import {ErrorState} from "@/vuex/errors";
import {ButtonType} from '@/components/form/FormTypes';
import {AuthMutations} from '@/vuex/auth';

const oidcModule = namespace('oidcStore');
const errors = namespace('errors');
const auth = namespace('auth');

@Component
export default class MaintenanceMode extends Vue {

    @oidcModule.Action('removeUser') removeOidcUser: any;
    @oidcModule.Action('signOutOidc') signOut!: any;
    @errors.State(ErrorState.MAINTENANCE_MODE) maintenanceMode!: string;
    @auth.Mutation(AuthMutations.LOG_USER_OUT) logUserOut!: () => void;
    buttonTypes = ButtonType;

    mounted(){
        this.removeOidcUser();
        this.logUserOut();
    }
}
