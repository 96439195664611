























































































import {Component, Vue} from 'vue-property-decorator';
import {ButtonType} from '@/components/form/FormTypes';
import Table from '@/components/table/Table.vue';
import {Searchable} from '@/models/Searchable';
import {FilterType, SearchData} from '@/ts/Filter';
import {TableCellData, TableCellType, TableHeaderData, TableRowData} from '@/components/table/cells/TableCellData';
import {Attribute, AttributeMap} from '@/ts/permissions/Attribute';
import {DeviceRegistrationService} from '@/services/devices/DeviceRegistrationService';
import {DeviceRegistrationStatus, PumpRegistrationQueueEntry} from '@/models/Devices/Device';
import {formatMoment} from '@/ts/TimezoneUtils';
import {Moment} from 'moment';
import {SnackbarActions} from '@/vuex/snackbar';
import {SnackbarRequest} from '@/models/Snackbar';
import {namespace} from 'vuex-class';
import {ModalMutations} from '@/vuex/modal';
import {Customer} from '@/models/customers/Customer';
import CustomerService from '@/services/CustomerService';
import Multiselect from 'vue-multiselect';
import ConfirmationModal from '@/components/modal/ConfirmationModal.vue';
import Spinner from '@/components/form/Spinner.vue';

const snackbar = namespace('snackbar');
const modal = namespace('modal');

/**
 * Renders a page for a customer to claim pumps
 */
@Component({
    components: {
        Table,
        Multiselect,
        ConfirmationModal,
        Spinner,
    }
})
export default class ClaimDevicesPage extends Vue {

    /**
     * Current serial number value
     */
    serialNumber = '';
    buttonTypes = ButtonType;

    /**
     * Whether the request is pending
     */
    loading = false;

    /**
     * Field the table is currently sorted by
     */
    currentSortKey: string = "queueDate";

    /**
     * Whether the table is sorted ascending
     */
    sortedAsc: boolean = true;
    deviceService = new DeviceRegistrationService(this.$store.state.auth.loggedInUser.timezone);
    customerService = new CustomerService(this.$store.state.auth.loggedInUser.timezone);

    /**
     * Currently queued pumps
     */
    queueEntries: PumpRegistrationQueueEntry[] = [];
    customers: Customer[] = [];
    @snackbar.Action(SnackbarActions.TRIGGER_SNACKBAR) addSnackbarMessage!: (item: SnackbarRequest) => void;
    @modal.Mutation(ModalMutations.CHANGE_MODAL_STATE) changeModalState!: (state: boolean) => void;

    /**
     * Whether the remove from queue modal is showing
     */
    removeModalShowing = false;

    /**
     * Device selected for removal
     */
    deviceToRemove: PumpRegistrationQueueEntry | null = null;
    selectedCustomer: Customer | null = null;

    searchString = {
        key: Searchable.SERIAL_NUMBER,
        search: ""
    };

    searchTypes: SearchData[] = [
        {
            key: Searchable.SERIAL_NUMBER,
            value: 'Serial number'
        },
    ];

    filterData = {
        queueDateStart: null as null | Date,
        queueDateEnd: null as null | Date,
        lastConnectionStart: null as null | Date,
        lastConnectionEnd: null as null | Date,
    };

    claimLoading = false;

    errorModalShowing = false;

    claimError = '';

    async mounted() {
        await Promise.all([
            this.fetchQueue(),
            this.fetchCustomers()
        ])
    }

    /**
     * Updates the table search values
     * @param searchString
     */
    searchData(searchString: any){
        this.searchString = searchString;
    }

    /**
     * Table header definition
     */
    get tableHeader(): TableHeaderData[] {
        return [
            {
                sortable: true,
                value: "Serial Number",
                widthClass: "w-1/5",
                sortKey: "lastName",
            },
            {
                sortable: false,
                value: "Added to Queue",
                widthClass: "w-1/4",
                filterKey: 'queueDateFilter',
                filterable: true,
                filters: [
                    {
                        type: FilterType.CALENDAR,
                        key: 'dateRange',
                        active: this.filterData.queueDateStart != null,
                        enabled: true,
                        displayText: 'Filter by date',
                        data: {
                            initialRange: {
                                start: this.filterData.queueDateStart,
                                end: this.filterData.queueDateStart,
                            }
                        }
                    }
                ]
            },
            {
                sortable: false,
                value: "Last Connection",
                widthClass: "w-1/4",
                filterKey: 'lastConnectionFilter',
                filterable: true,
                filters: [
                    {
                        type: FilterType.CALENDAR,
                        key: 'dateRange',
                        active: this.filterData.lastConnectionStart != null,
                        enabled: true,
                        displayText: 'Filter by date',
                        data: {
                            initialRange: {
                                start: this.filterData.lastConnectionStart,
                                end: this.filterData.lastConnectionEnd,
                            }
                        }
                    }
                ]
            },
            {
                sortable: false,
                value: "Status",
                widthClass: "w-1/4",
            },
            {
                sortable: false,
                value: "Actions",
                widthClass: "w-5/100",
                sortKey: "",
            },
        ];
    }

    /**
     * Transforms the list fo queued pumps into an array of table rows
     */
    get tableData(): TableRowData[] {
        return this.queueEntries
            .filter(this.filterByQueueDate)
            .filter(this.filterByLastConnectionDate)
            .map((entry) => {
            const cells: TableCellData[] = [

            ];

            if(this.$hasAttribute(Attribute.REQUIRES_CUSTOMER)) {
                cells.push(
                    {
                        type: TableCellType.NORMAL,
                        primaryValue: entry.device.serialNumber,
                    }
                );
            } else {
                cells.push(
                    {
                        type: TableCellType.TWO_ROW,
                        primaryValue: entry.device.serialNumber,
                        secondaryValue: `Customer: ${entry.customer !== undefined ? entry.customer.name : 'N/A'}`
                    }
                )
            }

            cells.push(
                {
                    type: TableCellType.NORMAL,
                    primaryValue: formatMoment(entry.addedAt as Moment),
                },
                {
                    type: TableCellType.NORMAL,
                    primaryValue: formatMoment(entry.device.lastConnectionTime as Moment),
                },
                {
                    type: TableCellType.NORMAL,
                    primaryValue: this.getStatus(entry)
                },
                {
                    type: TableCellType.ICON,
                    primaryValue: '',
                    icons: [
                        {
                            name: 'trash',
                            action: () => {
                                this.deviceToRemove = entry;
                                this.showRemoveModal();
                            }
                        }
                    ]
                }
            );

            return {
                cells,
                indexKey: entry.device.id
            }
        });
    }

    /**
     * Returns the status for a specific table row entry
     * @param entry
     */
    getStatus(entry: PumpRegistrationQueueEntry) {
        return (entry.device.lastConnectionTime as Moment).isAfter(entry.addedAt as Moment) ? 'Connected to unclaimed hub' : 'Waiting for connection';
    }

    /**
     * Shows the remove pump from queue modal
     */
    showRemoveModal() {
        this.changeModalState(true);
        this.removeModalShowing = true;
    }

    /**
     * Hides the remove pump from queue modal
     */
    hideRemoveModal() {
        this.changeModalState(false);
        this.removeModalShowing = false;
        this.deviceToRemove = null;
    }

    /**
     * Updates the filters for filtering by queue date
     * @param data
     */
    handleQueueDateFilter(data: any){
        if(data.dateRange){
            this.filterData.queueDateStart = data.dateRange.start;
            const endDate = (data.dateRange.end as Date);
            endDate.setHours(23, 59, 59);
            this.filterData.queueDateEnd = endDate;
        }
    }

    /**
     * Updates the filters for filtering by last connection date
     * @param data
     */
    handleLastConnectionFilter(data: any){
        if(data.dateRange){
            this.filterData.lastConnectionStart = data.dateRange.start;
            const endDate = (data.dateRange.end as Date);
            endDate.setHours(23, 59, 59);
            this.filterData.lastConnectionEnd = endDate;
        }
    }

    /**
     * Filters the array of queued pumps by queue date
     * @param entry
     */
    filterByQueueDate(entry: PumpRegistrationQueueEntry) {
        if(this.filterData.queueDateStart !== null && this.filterData.queueDateEnd) {
            return (entry.addedAt as Moment).isSameOrAfter(this.filterData.queueDateStart) &&
                (entry.addedAt as Moment).isSameOrBefore(this.filterData.queueDateEnd);
        }

        return true;
    }

    /**
     * Filters the array of queued pumps by last connection date
     * @param entry
     */
    filterByLastConnectionDate(entry: PumpRegistrationQueueEntry) {
        if(this.filterData.lastConnectionStart !== null && this.filterData.lastConnectionEnd) {
            return (entry.device.lastConnectionTime as Moment).isSameOrAfter(this.filterData.lastConnectionStart) &&
                (entry.device.lastConnectionTime as Moment).isSameOrBefore(this.filterData.lastConnectionEnd);
        }

        return true;
    }

    /**
     * Resets the status of all filters
     */
    clearAllFilters() {
        this.filterData.queueDateStart = null;
        this.filterData.queueDateEnd = null;
        this.filterData.lastConnectionStart = null;
        this.filterData.lastConnectionEnd = null;
    }

    /**
     * Updates the table sorting info
     * @param message
     */
    sortData(message: any){
        this.currentSortKey = message.sortKey;
        this.sortedAsc = message.sortedAsc;
    }

    /**
     * Whether the add to queue button is disabled
     */
    get addToQueueDisabled() {
        if(this.$hasAttribute(Attribute.REQUIRES_CUSTOMER)) {
            return this.serialNumber === '';
        } else {
            return this.serialNumber === '' || this.selectedCustomer === null;
        }
    }

    /**
     * Fetches the list of queud pumps
     */
    async fetchQueue() {
        let response;
        this.loading = true;
        if(AttributeMap[this.$store.state.auth.loggedInUser.role].includes(Attribute.REQUIRES_CUSTOMER)) {
            response = await this.deviceService.getQueuedDevicesForCustomer();
        } else {
            response = await this.deviceService.getQueuedDevicesForAllCustomers();
        }

        response.map((entries) => {
            this.queueEntries = entries;
        });
        this.loading = false;
    }

    /**
     * Sends the request to remove a device from the queue
     */
    async removeDeviceFromQueue() {
        if(this.deviceToRemove === null) {
            return;
        }
        const response = await this.deviceService.removeDeviceFromQueue(this.deviceToRemove.device.id);
        response.map(() => {
            this.addSnackbarMessage({
                message: `${this.deviceToRemove?.device.serialNumber} successfully removed from queue`
            });
            this.hideRemoveModal();
            this.fetchQueue();
        })
    }

    /**
     * Wrapper around adding to queue based on permission
     */
    handleAddToQueue() {
        if(this.$isAllowed(this.$Permission.PUMP_REGISTRATION_QUEUE_BYPASS)) {
            this.addDeviceToCustomerWithBypass();
        } else {
            this.addDeviceToQueue();
        }
    }

    /**
     * Adds a device to a customer bypassing the queue
     * Note: This can only be used by non-customer users
     */
    async addDeviceToCustomerWithBypass() {
        if(this.serialNumber === '' || this.selectedCustomer === null) {
            return;
        }
        this.claimLoading = true;
        const response = await this.deviceService.addDeviceToCustomerWithBypass(this.selectedCustomer.id, this.serialNumber);
        this.claimLoading = false;
        if(response.isSuccess()) {
            this.addSnackbarMessage({
                message: `${this.serialNumber} added to customer ${this.selectedCustomer.name}`
            });
            this.serialNumber = '';
        } else {
            this.showErrorModal(`There was an issue claiming ${this.serialNumber}. Try again later or contact customer support.`);
        }
    }

    /**
     * Sends the request to add the device to the queue
     */
    async addDeviceToQueue() {
        this.claimLoading = true;
        const response = await this.deviceService.queuePump(this.serialNumber);
        this.claimLoading = false;
        response.map((response) => {
            switch(response.status) {
                case DeviceRegistrationStatus.REGISTERED:
                    this.addSnackbarMessage({
                        message: `Pump ${this.serialNumber} successfully claimed.`
                    });
                    this.serialNumber = '';
                    break;

                case DeviceRegistrationStatus.ALREADY_REGISTERED:
                    this.showErrorModal(`Pump ${this.serialNumber} is already claimed.`)
                    break;

                case DeviceRegistrationStatus.ALREADY_REGISTERED_BY_OTHER:
                    this.showErrorModal(`Pump ${this.serialNumber} has been claimed by another customer. Please contact customer support if you believe this is incorrect.`)
                    break;

                case DeviceRegistrationStatus.ADDED_TO_QUEUE:
                    this.addSnackbarMessage({
                        message: `Pump ${this.serialNumber} successfully added to registration queue.`
                    });
                    this.serialNumber = '';
                    this.fetchQueue();
                    break;

                case DeviceRegistrationStatus.ALREADY_IN_QUEUE:
                    this.showErrorModal(`Pump ${this.serialNumber} is already in the registration queue.`)
                    break;

                case DeviceRegistrationStatus.ALREADY_IN_OTHER_QUEUE:
                    this.showErrorModal(`Pump ${this.serialNumber} has been claimed by another customer. Please contact customer support if you believe this is incorrect.`)
                    break;
            }
        }).mapErr((err) => {
            if(err.statusCode === 404) {
                this.showErrorModal(`A pump with serial number ${this.serialNumber} could not be found. Please contact customer support if you believe this is incorrect.`)
            }
        })
    }

    /**
     * Fetches the list of customers
     */
    async fetchCustomers() {
        if(this.$hasAttribute(Attribute.REQUIRES_CUSTOMER)) {
            return;
        }

        const response = await this.customerService.fetchAllCustomers();
        response.map((customers) => {
            this.customers = customers;
        });
    }

    showErrorModal(error: string) {
        this.claimError = error;
        this.errorModalShowing = true;
        this.changeModalState(true);
    }

    hideErrorModal() {
        this.claimError = ''
        this.errorModalShowing = false;
        this.changeModalState(false);
    }
}
