



















import { Component, Vue } from 'vue-property-decorator';
import Table from '@/components/table/Table.vue';
import {namespace} from 'vuex-class';
import {CustomerActions} from "@/vuex/customers";
import {Customer, CustomerListRequest, CustomerSortRequest} from '@/models/customers/Customer';
import {sortBy} from "@/ts/utilities";
import {TableCellData, TableCellType, TableHeaderData, TableRowData} from "@/components/table/cells/TableCellData";
import {formatMoment} from "@/ts/TimezoneUtils";
import {Moment} from 'moment';
import TableHeader from "@/components/table/TableHeader.vue";
import Checkbox from '@/components/form/Checkbox.vue';
import {RegionNameMap} from "@/ts/Region";

const customers = namespace('customers');

/**
 * This component renders the list of customers currently in the system
 */
@Component({
    components:{
        Table,
        Checkbox
    }
})
export default class CustomerTable extends Vue {

    /**
     * Property the list of customers is currently sorted by
     */
    currentSortKey: string = "name";

    /**
     * Whether the data is currently sorted in ascending order
     */
    sortedAsc: boolean = true;

    /**
     * Value of the input in the search box
     */
    currentSearchString: string = "";

    /**
     * Whether to include disabled customers when fetching the list of customers
     */
    includeDisabled: boolean = false;
    @customers.Action(CustomerActions.FETCH_CUSTOMER_LIST) getCustomers!: (request: CustomerListRequest) => any;
    @customers.State('customerList') customerList!: Customer[];

    /**
     * Whether the list of customers is currently loading
     */
    loading = false;

    /**
     * Table header definition for customer list table
     */
    tableHeader: TableHeaderData[] = [
        {
            sortable: true,
            value: "Customer name",
            widthClass: "w-1/5",
            sortKey: 'name'
        },
        {
            sortable: true,
            value: "Customer ID",
            widthClass: "w-1/5",
            sortKey: 'customerNumber'
        },
        {
            sortable: false,
            value: "Region",
            widthClass: "w-1/5",
            sortKey: 'region'
        },
        {
            sortable: true,
            value: "Last user activity",
            widthClass: "w-1/5",
            sortKey: 'lastUserLogin'
        },
        {
            sortable: false,
            value: "Status",
            widthClass: "w-1/5",
            sortKey: 'customerStatus'
        },
    ];

    /**
     * Fetches the list of customers on component load
     */
    async mounted(){
       await this.requestCustomers();
    }

    /**
     * Fetches the list of customers
     */
    async requestCustomers(){
        this.loading = true;
        this.$emit('requestUpdated', {search: this.currentSearchString, disabled: this.includeDisabled});
        await this.getCustomers({search: this.currentSearchString, disabled: this.includeDisabled});
        this.loading = false;
    }

    /**
     * Transforms the fetched list of customers into an array of table rows
     */
    get tableData(){
        return [...this.customerList].sort(sortBy(this.currentSortKey, this.sortedAsc)).map((customer) => {
            const cells: TableCellData[] = [
                {
                    type: TableCellType.LINK,
                    primaryValue: customer.name,
                    secondaryValue: {
                        name: 'customer-view',
                        params: {
                            id: customer.id
                        },
                    }
                },
                {
                    type: TableCellType.NORMAL,
                    primaryValue: customer.customerNumber
                },
                {
                    type: TableCellType.NORMAL,
                    primaryValue: RegionNameMap[customer.region],
                },
                {
                    type: TableCellType.NORMAL,
                    primaryValue: customer.lastUserLogin != null ? formatMoment(customer.lastUserLogin as Moment) : "N/A",
                },
                {
                    type: TableCellType.NORMAL,
                    primaryValue: customer.customerStatus === 1 ? 'Disabled' : 'Enabled'
                }
            ];

            return {
                cells,
                indexKey: customer.id
            } as TableRowData
        })
    }

    /**
     * Called when the sort field or direction is changed. Notifies the parent component of this change
     * @param message
     */
    sortData(message: any){
        this.currentSortKey = message.sortKey;
        this.sortedAsc = message.sortedAsc;
        this.$emit('sortUpdated', {sortKey: this.currentSortKey, sortedAsc: this.sortedAsc} as CustomerSortRequest)
    }

    /**
     * Called when the search action in the table is triggered. It updates the current search value
     * and requests the list of customers again
     * @param searchString
     */
    search(searchString: string){
        this.currentSearchString = searchString;
        this.requestCustomers();
    }

    /**
     * Called when including disabled customers changes
     * Requests the list of customers after updating value
     * @param val New value for including disabled customers
     */
    includeDisabledChanged(val: boolean){
        this.includeDisabled = val;
        this.requestCustomers();
    }
}
