import {
    AdjustBolus, AdjustBrightness,
    AdjustFeed, AdjustVolume,
    BaseScreenData, BiotechInfo, BiotechLanguage, BiotechOptions, BolusFed,
    BolusIncompleteData, CurrentValue, DoseFed,
    DoseIncompleteData, KTOBolus, KTOCont, ManualTestFailed, MonitorModeScreenData, MoreOptions, ReadyToFeedBolus,
    ReadyToFeedCont, ReadyToFeedDose, SystemError, ViewHistory
} from '@/models/MARRS/MonitorMode/MonitorModeData';
import {NumericalMap} from '@/models/MARRS/MonitorMode/ScreenInfo';

/**
 * Map between the pump languages and their IDs
 */
const languages: NumericalMap<string> = {
    1: 'English',
    2: 'Español',
    3: 'Français',
    4: 'Deutsch',
    5: 'Italiano',
    6: 'Português',
    7: 'Svenska',
    8: 'Nederlands',
    9: 'Suomi',
    10: 'Dansk',
    11: 'Ελληνικά',
    12: 'Русский',
    13: 'Polski',
    14: 'Türkçe',
    15: 'Norsk',
    16: '日本語',
    17: '简体中文'
}

/**
 * Functions that construct the different pieces of each screen
 */
export default {
    cassetteLoaded: (screenData: BaseScreenData) =>
        `<div class="marrs-text-only">
            <span class="marrs-checkmark-icon mt-1 mb-1 h-6 w-6 block mx-auto"></span>
            <span class="font-bold">Feeding Cassette</span><br/>
            <span class="font-bold">detected.</span> <br/> <br/>
            <span>To continue,</span> <br/>
            <span>press </span><span class="font-bold">Prime.</span><br/>
        </div>`,
    normalSetLoaded: (screenData: BaseScreenData) =>
        `<div class="h-full w-full">
            <span class="marrs-normal-load-set-animation h-40 block"></span>
         </div>`,
    cassetteLoadedFluid: (screenData: BaseScreenData) =>
        `<div class="marrs-text-only">
            <span class="marrs-checkmark-icon mt-1 mb-1 h-6 w-6 block mx-auto"></span>
            <span class="font-bold">Feeding Cassette</span><br/>
            <span class="font-bold">detected.</span> <br/> <br/>
            <span>To continue,</span> <br/>
            <span>press </span><span class="font-bold">Prime</span>
            <span>or</span><br/>
            <span class="font-bold">Skip Prime</span>.
        </div>`,
    autoPrime: (screenData: BaseScreenData) =>
        `<div class="marrs-text-only">
            <span>Wait until priming ends</span><br/>
            <span>or press </span><span class="font-bold">Stop</span> <span>if fluid</span> <br/>
            <span>reached end of line.</span>
        </div>`,
    holdToPrimeNoFluid: (screenData: BaseScreenData) =>
        `<div class="marrs-text-only">
            <span>Press</span> <span class="font-bold">Hold To Prime</span><br/>
            <span>until fluid has reached</span><br/>
            <span>end of tubing.</span>
        </div>`,
    holdToPrimeFluid: (screenData: BaseScreenData) =>
        `<div class="marrs-text-only">
            <span>Press</span> <span class="font-bold">Hold To Prime</span><br/>
            <span>until fluid has reached</span><br/>
            <span>end of tubing.</span><br/><br/>
            <span>When priming is</span><br/>
            <span>complete, press</span> <span class="font-bold">Done.</span>
        </div>`,
    priming: (screenData: BaseScreenData) => `
        <div class="flex flex-row items-center justify-center">
            <div class="marrs-priming-animation h-20 w-20 inline-block"></div>
        </div>
    `,
    priorSettings: (screenData: BaseScreenData) =>
        `<div class="marrs-text-only pt-2">
            <span>Press </span><span class="font-bold">Keep Settings</span><br/>
            <span>to load last delivery</span><br/>
            <span>settings.</span><br/><br/>
            <span>Press </span><span class="font-bold">Clear Settings</span><br/>
            <span>to enter new delivery</span><br/>
            <span>settings.</span>
        </div>`,
    feedIncompleteMessage: (screenData: BaseScreenData) =>
        `<div class="marrs-text-only pt-2">
            <span>The pump did not finish</span><br/>
            <span>the specified dose</span>
        </div>`,
    doseLeft: (screenData: DoseIncompleteData) =>
        `<div class="marrs-text-only flex flex-row justify-between items-center h-full">
            <span class="inline-block font-bold">Dose Left:</span>
            <span class="inline-block font-bold">${screenData.doseLeft} mL</span>
        </div>`,
    doseFed: (screenData: DoseIncompleteData) =>
        `<div class="marrs-text-only flex flex-row justify-between items-center h-full">
            <span class="inline-block font-bold">Dose Fed:</span>
            <span class="inline-block font-bold">${screenData.doseFed} mL</span>
        </div>`,
    bolusIncomplete: (screenData: BaseScreenData) =>
        `<div class="marrs-text-only pt-2">
            <span>Pump did not finish the</span><br/>
            <span>number of specified</span><br/>
            <span>Boluses.</span>
        </div>`,
    bolusRate: (screenData: BolusIncompleteData) =>
        `<div class="marrs-text-only flex flex-col">
            <div class="flex flex-row justify-between">
                <span class="font-bold">Bolus:</span>
                <span class="font-bold">${screenData.bolusesFed} / ${screenData.numberOfBoluses}</span>
            </div>
            <div>
                <span class="font-bold text-right inline-block w-full marrs-text-0-9em">
                    ${screenData.bolusVolume} mL every ${screenData.bolusInterval} hrs
                </span>
            </div>
        </div>`,
    rateDisplayCont: (screenData: ReadyToFeedCont) =>
        `<div class="font-bold">
            <span class="block w-full marrs-text-0-9em">Rate:</span>
            <span class="block w-full text-right marrs-text-2-1em leading-none">${screenData.continuousFeedRate}</span>
            <span class="block w-full text-right marrs-text-0-9em leading-none">mL / hr</span>
        </div>`,
    feedTotalizer: (screenData: ReadyToFeedCont) =>
        `<div class="font-bold">
            <span class="block w-full marrs-text-0-9em">Total Fed:</span>
            <span class="block w-full text-right marrs-text-2-1em leading-none">
                ${Math.floor(screenData.patientFeedTotalizer / 10)}
            </span>
            <span class="block w-full text-right marrs-text-0-9em leading-none">mL</span>
        </div>`,
    feedTotalizerBolus: (screenData: ReadyToFeedBolus) =>
        `<div class="font-bold">
            <span class="block w-full marrs-text-0-9em">Total Fed:</span>
            <span class="block w-full text-right marrs-text-2-1em leading-none">
                ${Math.floor(screenData.patientBolusTotalizer / 10)}
            </span>
            <span class="block w-full text-right marrs-text-0-9em leading-none">mL</span>
        </div>`,
    doseLeftReadyToFeed: (screenData: ReadyToFeedDose) =>
        `<div class="marrs-text-0-9em flex flex-row justify-between items-center h-full">
            <span class="inline-block font-bold">Dose Left:</span>
            <span class="inline-block font-bold">${screenData.continuousFeedDose} mL</span>
        </div>`,
    feedTotalizerOneLine: (screenData: ReadyToFeedCont) =>
        `<div class="marrs-text-0-9em flex flex-row justify-between items-center h-full font-bold">
            <span class="inline-block">Total Fed:</span>
            <div class="inline-block">
                 <span >
                    ${Math.floor(screenData.patientFeedTotalizer / 10)}
                </span>
                <span>mL</span>
            </div>
        </div>`,
    feedTotalizerSplitLine: (screenData: ReadyToFeedCont) =>
        `<div class="marrs-text-0-9em flex flex-col h-full font-bold">
            <span class="block text-left">Total Fed:</span>
            <div class="block text-right">
                 <span >
                    ${Math.floor(screenData.patientFeedTotalizer / 10)}
                </span>
                <span>mL</span>
            </div>
        </div>`,
    feedTotalizerSplitLineBolus: (screenData: ReadyToFeedBolus) =>
        `<div class="marrs-text-0-9em flex flex-col h-full font-bold">
            <span class="block text-left">Total Fed:</span>
            <div class="block text-right">
                 <span >
                    ${Math.floor(screenData.patientBolusTotalizer / 10)}
                </span>
                <span>mL</span>
            </div>
        </div>`,
    rateDisplayBolus: (screenData: AdjustBolus) =>
        `<div class="font-bold">
            <span class="block w-full marrs-text-0-9em">Rate:</span>
            <span class="block w-full text-right marrs-text-2-1em leading-none">${screenData.bolusFeedRate}</span>
            <span class="block w-full text-right marrs-text-0-9em leading-none">mL / hr</span>
        </div>`,
    bolusRateReadyToFeed: (screenData: ReadyToFeedBolus) =>
        `<div class="marrs-text-0-9em flex flex-col">
            <div class="flex flex-row justify-between leading-none">
                <span class="font-bold">Bolus:</span>
                <span class="font-bold">${screenData.currentbolus} / ${screenData.numberOfBoluses}</span>
            </div>
            <div>
                <span class="font-bold text-right inline-block w-full marrs-text-0-9em">
                    ${screenData.bolusVolume} mL every ${screenData.bolusInterval} hrs
                </span>
            </div>
        </div>`,
    adjustFeedRateDisplay: (screenData: AdjustFeed) =>
        `<div class="font-bold">
            <span class="block w-full marrs-text-0-9em">Rate:</span>
            <span class="block w-full text-right marrs-text-2-1em leading-none">${screenData.continuousFeedRate}</span>
            <span class="block w-full text-right marrs-text-0-9em leading-none">mL / hr</span>
        </div>`,
    adjustFeedDoseDisplay: (screenData: AdjustFeed) =>
        `<div class="font-bold">
            <span class="block w-full marrs-text-0-9em">Dose:</span>
            <span class="block w-full text-right marrs-text-2-1em leading-none">${screenData.continuousFeedDose}</span>
            <span class="block w-full text-right marrs-text-0-9em leading-none">mL</span>
        </div>`,
    numberOfBoluses: (screenData: AdjustBolus) =>
        `<div class="marrs-text-only font-bold flex flex-row justify-between h-full items-center">
            <span class="inline-block"># Boluses:</span>
            <span class="inline-block">${screenData.numberOfBoluses}</span>
        </div>`,
    bolusIntervalDisplay: (screenData: AdjustBolus) =>
        `<div class="marrs-text-only font-bold flex flex-col h-full">
            <span class="block marrs-text-0-9em">Bolus:</span>
            <span class="block text-right marrs-text-0-9em">
                ${screenData.bolusVolume} mL every ${screenData.bolusInterval} hours
            </span>
        </div>`,
    adjustContRate: (screenData: CurrentValue) =>
        `<div class="mt-3">
            <div class="bg-marrs-dark-blue text-white flex flex-col font-bold p-1">
                <span class="block marrs-text-0-9em">Rate:</span>
                <span class="block text-right marrs-text-3-15em">${screenData.currentValue}</span>
                <span class="block text-right marrs-text-0-9em">mL / hr</span>
            </div>
            <div class="flex flex-row justify-between items-center marrs-text-0-9em">
                <span>Maximum:</span>
                <span class="font-bold">600</span>
            </div>
        </div>`,
    adjustDoseRate: (screenData: CurrentValue) =>
        `<div class="mt-3">
            <div class="bg-marrs-dark-blue text-white flex flex-col font-bold p-1">
                <span class="block marrs-text-0-9em">Dose:</span>
                <span class="block text-right marrs-text-3-15em">${screenData.currentValue}</span>
                <span class="block text-right marrs-text-0-9em">mL / hr</span>
            </div>
            <div class="flex flex-row justify-between items-center marrs-text-0-9em">
                <span>Maximum:</span>
                <span class="font-bold">3000</span>
            </div>
        </div>`,
    adjustBolusVolume: (screenData: AdjustVolume) =>
        `<div class="mt-3">
            <div class="bg-marrs-dark-blue text-white flex flex-col font-bold p-1">
                <span class="block marrs-text-0-9em">Bolus Volume:</span>
                <span class="block text-right marrs-text-3-15em">${screenData.currentValue}</span>
                <span class="block text-right marrs-text-0-9em">mL / hr</span>
            </div>
            <div class="flex flex-row justify-between items-center marrs-text-0-9em">
                <span>Maximum:</span>
                <span class="font-bold">${screenData.maximumBolusVolume}</span>
            </div>
        </div>`,
    adjustNumBoluses: (screenData: CurrentValue) =>
        `<div class="mt-3">
            <div class="bg-marrs-dark-blue text-white flex flex-col font-bold p-1">
                <span class="block marrs-text-0-9em">Number of Boluses:</span>
                <span class="block text-right marrs-text-3-15em">${screenData.currentValue}</span>
            </div>
            <div class="flex flex-row justify-between items-center marrs-text-0-9em">
                <span>Maximum:</span>
                <span class="font-bold">99</span>
            </div>
        </div>`,
    adjustBolusInterval: (screenData: CurrentValue) =>
        `<div class="mt-3">
            <div class="bg-marrs-dark-blue text-white flex flex-col font-bold p-1">
                <span class="block marrs-text-0-9em">Bolus Interval:</span>
                <span class="block text-right marrs-text-3-15em">${screenData.currentValue}</span>
                <span class="block text-right marrs-text-0-9em">hours</span>
            </div>
            <div class="flex flex-row justify-between items-center marrs-text-0-9em">
                <span>Maximum:</span>
                <span class="font-bold">24</span>
            </div>
        </div>`,
    moreOptions: function(screenData: MoreOptions) {
        const completionAudioString = screenData.showCompletionAudio ? this.completionAudioItem(screenData, 3) : '';
        const adjustBrightnessIndex = screenData.showCompletionAudio ? 4 : 3;
        const adjustBrightnessItem = this.adjustBrightnessItem(screenData, adjustBrightnessIndex);
        const primePump = screenData.showPrime ? this.primePumpItem(screenData, adjustBrightnessIndex + 1) : '';
        return `<div class="h-full pt-1">
            <div class="h-1/6 marrs-text-0-9em w-full flex flex-row items-center pl-1
                        ${screenData.indexOfHighlightedOption === 0 ? 'text-white bg-marrs-dark-blue' : ''}">
                <span>Clear Amount Fed</span>
            </div>
            <div class="h-1/6 marrs-text-0-9em w-full flex flex-row items-center pl-1
                        ${screenData.indexOfHighlightedOption === 1 ? 'text-white bg-marrs-dark-blue' : ''}">
                <span>View History</span>
            </div>
            <div class="h-1/6 marrs-text-0-9em w-full flex flex-row items-center pl-1 justify-between
                        ${screenData.indexOfHighlightedOption === 2 ? 'text-white bg-marrs-dark-blue' : ''}">
                <span>Airplane Mode</span>
                <div>
                    <span class="h-3 w-3 inline-block
                        ${screenData.airplaneMode ? 'marrs-item-checked' : 'marrs-item-unchecked'}"></span>
                    <span>${screenData.airplaneMode ? 'On' : 'Off'}</span>
                </div>
            </div>` + `
            ${completionAudioString}
            ${adjustBrightnessItem}
            ${primePump}
        </div>`;
    },
    completionAudioItem: (screenData: MoreOptions, index: number) =>
        `<div class="h-1/6 marrs-text-0-9em w-full flex flex-row items-center pl-1 justify-between
                    ${screenData.indexOfHighlightedOption === index ? 'text-white bg-marrs-dark-blue' : ''}">
            <span>Completion Audio</span>
            <div>
                <span class="h-3 w-3 inline-block
                    ${screenData.completionAudio ? 'marrs-item-checked' : 'marrs-item-unchecked'}"></span>
                <span>${screenData.completionAudio ? 'On' : 'Off'}</span>
            </div>
        </div>`,
    adjustBrightnessItem: (screenData: MoreOptions, index: number) =>
        `<div class="h-1/6 marrs-text-0-9em w-full flex flex-row items-center pl-1
             ${screenData.indexOfHighlightedOption === index ? 'text-white bg-marrs-dark-blue' : ''}">
            <span>Adjust Brightness</span>
         </div>`,
    primePumpItem: (screenData: MoreOptions, index: number) =>
        `<div class="h-1/6 marrs-text-0-9em w-full flex flex-row items-center pl-1
            ${screenData.indexOfHighlightedOption === index ? 'text-white bg-marrs-dark-blue' : ''}">
            <span>Prime Pump</span>
        </div>`,
    clearAmount: (screenData: BaseScreenData) =>
        `<div class="marrs-text-only">
            <span>Are you sure you want</span> <br/>
            <span>to clear the Amount </span>
            <span>Fed?</span><br/>
        </div>`,
    adjustBrightnessPicker: (screenData: AdjustBrightness) =>
        `<div class="h-full flex flex-col justify-center">
            <div class="h-9/10 bg-marrs-dark-blue flex flex-row justify-center items-center">
                <span class="h-8/10 w-1/2 inline-block marrs-brightness-icon-${screenData.indexOfSelectedLevel}"></span>
            </div>
        </div>`,
    withinLast: (screenData: ViewHistory) =>
        `<div class="h-full flex flex-col justify-center font-bold text-white bg-marrs-dark-blue">
            <span class="block marrs-text-0-9em leading-none">Within Last:</span>
            <span class="block marrs-text-2-1em text-right leading-none">${screenData.hoursSelected}</span>
            <span class="block marrs-text-0-9em text-right leading-none">hours</span>
        </div>`,
    historyPumpFed: (screenData: ViewHistory) =>
        `<div class="h-full flex flex-col justify-center font-bold leading-none">
            <span class="block marrs-text-0-9em leading-none">Pump Fed:</span>
            <span class="block marrs-text-2-5em text-right leading-none">${screenData.totalFedOverTimePeriod}</span>
            <span class="block marrs-text-0-9em text-right leading-none">mL</span>
        </div>`,
    clearHistory: (screenData: BaseScreenData) =>
        `<div class="marrs-text-only">
            <span>Are you sure you want</span> <br/>
            <span>to clear the pump </span>
            <span>history?</span><br/>
        </div>`,
    pumpSettingsLocked: (screenData: BaseScreenData) =>
        `<div class="marrs-text-only">
            <span>Pump Settings have</span><br/>
            <span>been locked. Refer to</span><br/>
            <span>user manual to unlock.</span><br/><br/>
            <span>Press </span><span class="font-bold">Back </span><span>to return</span><br/>
            <span>to previous screen.</span>
        </div>`,
    checkmark: (screenData: BaseScreenData) =>
        `<div class="h-full">
            <div class="h-full flex flex-row justify-center items-center">
                <span class="h-9/10 w-12 inline-block marrs-checkmark-large-icon"></span>
            </div>
        </div>`,
    feedCompleteDoseFed: (screenData: DoseFed) =>
        `<div class="marrs-text-0-9em flex flex-col h-full font-bold">
            <span class="block text-left leading-none">Dose Fed:</span>
            <div class="block text-right">
                 <span class="block marrs-text-2-5em leading-none">
                    ${screenData.doseFed}
                </span>
                <span class="block leading-none">mL</span>
            </div>
        </div>`,
    feedCompleteBolusFed: (screenData: BolusFed) =>
        `<div class="marrs-text-0-9em flex flex-col h-full font-bold">
            <span class="block text-left">Boluses Fed:</span>
            <div class="block text-right">
                 <span class="marrs-text-3-15em">
                    ${screenData.bolusesFed}
                </span>
            </div>
        </div>`,
    poweringDown: (screenData: BaseScreenData) =>
        `<div class="marrs-text-0-9em flex flex-col h-full font-bold pt-2">
            <span class="marrs-text-0-9em block text-left">Hold Button to Power</span>
            <span class="marrs-text-0-9em block text-left">Down in</span>
            <span class="marrs-text-3-5em block text-center">5</span>
            <span class="marrs-text-0-9em block text-right">seconds.</span>
        </div>`,
    biotechModeScreen: (screenData: BaseScreenData) =>
        `<div class="marrs-text-only h-full">
            <span class="marrs-text-only">If you are not an</span><br/>
            <span class="marrs-text-only">authorized biotech,</span><br/>
            <span class="marrs-text-only">press </span><span class="marrs-text-0-9em font-bold">Restart Pump</span><br/>
            <span class="marrs-text-only">to exit Biotech Mode.</span>
        </div>`,
    biotechInfo: (screenData: BiotechInfo, serialNumber: string) =>
        `<div class="h-full flex flex-col marrs-text-0-85em">
           <div class="h-1/5 flex flex-row justify-between items-center">
                <span>Serial #:</span>
                <span class="font-bold">${serialNumber}</span>
            </div>
            <div class="h-1/5 flex flex-row justify-between bg-marrs-light-purple items-center">
                <div>
                    <span>Days since</span><br/>
                    <span>last certification:</span>
                </div>
                <span class="font-bold">${screenData.daysSinceCert}</span>
            </div>
            <div class="h-1/5 flex flex-row justify-between items-center">
                <span>HW Version:</span>
                <span class="font-bold">${screenData.hwVersion}</span>
            </div>
            <div class="h-1/5 flex flex-row justify-between bg-marrs-light-purple items-center">
                <span>SW Version:</span>
                <span class="font-bold">${screenData.swVersion}</span>
            </div>
           <div class="h-1/5 flex flex-row justify-between items-center">
                <span>UI Version:</span>
                <span class="font-bold">${screenData.uiVersion}</span>
            </div>
        </div>`,
    biotechMoreOptions: (screenData: BiotechOptions, language: number) =>
        `<div class="h-full flex flex-col marrs-text-0-85em pt-1">
           <div class="h-1/5 marrs-text-0-9em w-full flex flex-row items-center px-1 justify-between
                        ${screenData.indexOfHighlightedOption === 0 ? 'text-white bg-marrs-dark-purple' : ''}
                        ${screenData.indexOfOptionInTopPosition === 0 ? '' : 'hidden'}">
                <span>Lock Settings</span>
                <div>
                    <span class="h-3 w-3 inline-block
                        ${screenData.lockSettings ? 'marrs-item-checked' : 'marrs-item-unchecked'}"></span>
                    <span>${screenData.lockSettings ? 'On' : 'Off'}</span>
                </div>
            </div>
            <div class="h-1/5 marrs-text-0-9em w-full flex flex-row items-center px-1 justify-between
                        ${screenData.indexOfHighlightedOption === 1 ? 'text-white bg-marrs-dark-purple' : ''}">
                <span>Power Save</span>
                <div>
                    <span class="h-3 w-3 inline-block
                        ${screenData.powerSave ? 'marrs-item-checked' : 'marrs-item-unchecked'}"></span>
                    <span>${screenData.powerSave ? 'On' : 'Off'}</span>
                </div>
            </div>
            <div class="h-1/5 marrs-text-0-9em w-full flex flex-row items-center px-1 justify-between
                        ${screenData.indexOfHighlightedOption === 2 ? 'text-white bg-marrs-dark-purple' : ''}">
                <span>Dose</span>
                <div>
                    <span class="h-3 w-3 inline-block
                        ${screenData.dose ? 'marrs-item-checked' : 'marrs-item-unchecked'}"></span>
                    <span>${screenData.dose ? 'On' : 'Off'}</span>
                </div>
            </div>
            <div class="h-1/5 marrs-text-0-9em w-full flex flex-row items-center px-1 justify-between
                        ${screenData.indexOfHighlightedOption === 3 ? 'text-white bg-marrs-dark-purple' : ''}">
                <span>Bolus</span>
                <div>
                    <span class="h-3 w-3 inline-block
                        ${screenData.bolus ? 'marrs-item-checked' : 'marrs-item-unchecked'}"></span>
                    <span>${screenData.bolus ? 'On' : 'Off'}</span>
                </div>
            </div>
            <div class="h-1/5 marrs-text-0-9em w-full flex flex-row items-center px-1 justify-between
                        ${screenData.indexOfHighlightedOption === 4 ? 'text-white bg-marrs-dark-purple' : ''}">
                <span>KTO</span>
                <div>
                    <span class="h-3 w-3 inline-block
                        ${screenData.kto ? 'marrs-item-checked' : 'marrs-item-unchecked'}"></span>
                    <span>${screenData.kto ? 'On' : 'Off'}</span>
                </div>
            </div>
           <div class="h-1/5 marrs-text-0-9em w-full flex flex-row items-center px-1 justify-between
                        ${screenData.indexOfHighlightedOption === 5 ? 'text-white bg-marrs-dark-purple' : ''}
                        ${screenData.indexOfOptionInTopPosition === 0 ? 'hidden' : ''}">
                <span>Language</span>
                <span class="font-bold">${languages[language]}</span>
            </div>
        </div>`,
    biotechLanguages: (screenData: BiotechLanguage) =>
        `<div class="h-full flex flex-col marrs-text-0-85em pt-1 font-bold">
            <div class="h-1/5 marrs-text-0-9em w-full flex flex-row items-center px-1
                        ${screenData.indexOfHighlightedOption === 1 ? 'text-white bg-marrs-dark-purple' : ''}
                        ${screenData.indexOfOptionInTopPosition > 1 ? 'hidden' : ''}">
                <div>
                    <span class="h-3 w-3 inline-block
                      ${screenData.indexOfSelectedOption === 1 ? 'marrs-item-checked' : 'marrs-item-unchecked'}"></span>
                    <span>${languages[1]}</span>
                </div>
            </div>
            <div class="h-1/5 marrs-text-0-9em w-full flex flex-row items-center px-1
                        ${screenData.indexOfHighlightedOption === 2 ? 'text-white bg-marrs-dark-purple' : ''}
                        ${screenData.indexOfOptionInTopPosition > 2 ? 'hidden' : ''}">
                <div>
                    <span class="h-3 w-3 inline-block
                      ${screenData.indexOfSelectedOption === 2 ? 'marrs-item-checked' : 'marrs-item-unchecked'}"></span>
                    <span>${languages[2]}</span>
                </div>
            </div>
            <div class="h-1/5 marrs-text-0-9em w-full flex flex-row items-center px-1
                        ${screenData.indexOfHighlightedOption === 3 ? 'text-white bg-marrs-dark-purple' : ''}
                        ${screenData.indexOfOptionInTopPosition > 3 ? 'hidden' : ''}">
                <div>
                    <span class="h-3 w-3 inline-block
                      ${screenData.indexOfSelectedOption === 3 ? 'marrs-item-checked' : 'marrs-item-unchecked'}"></span>
                    <span>${languages[3]}</span>
                </div>
            </div>
            <div class="h-1/5 marrs-text-0-9em w-full flex flex-row items-center px-1
                        ${screenData.indexOfHighlightedOption === 4 ? 'text-white bg-marrs-dark-purple' : ''}
                        ${screenData.indexOfOptionInTopPosition > 4 ? 'hidden' : ''}">
                <div>
                    <span class="h-3 w-3 inline-block
                      ${screenData.indexOfSelectedOption === 4 ? 'marrs-item-checked' : 'marrs-item-unchecked'}"></span>
                    <span>${languages[4]}</span>
                </div>
            </div>
            <div class="h-1/5 marrs-text-0-9em w-full flex flex-row items-center px-1
                        ${screenData.indexOfHighlightedOption === 5 ? 'text-white bg-marrs-dark-purple' : ''}
                        ${screenData.indexOfOptionInTopPosition > 5 ? 'hidden' : ''}">
                <div>
                    <span class="h-3 w-3 inline-block
                      ${screenData.indexOfSelectedOption === 5 ? 'marrs-item-checked' : 'marrs-item-unchecked'}"></span>
                    <span>${languages[5]}</span>
                </div>
            </div>
            <div class="h-1/5 marrs-text-0-9em w-full flex flex-row items-center px-1
                        ${screenData.indexOfHighlightedOption === 6 ? 'text-white bg-marrs-dark-purple' : ''}
                        ${(screenData.indexOfOptionInTopPosition < 2
                            || screenData.indexOfOptionInTopPosition > 6) ? 'hidden' : ''}">
                <div>
                    <span class="h-3 w-3 inline-block
                      ${screenData.indexOfSelectedOption === 6 ? 'marrs-item-checked' : 'marrs-item-unchecked'}"></span>
                    <span>${languages[6]}</span>
                </div>
            </div>
            <div class="h-1/5 marrs-text-0-9em w-full flex flex-row items-center px-1
                        ${screenData.indexOfHighlightedOption === 7 ? 'text-white bg-marrs-dark-purple' : ''}
                        ${(screenData.indexOfOptionInTopPosition < 3
                            || screenData.indexOfOptionInTopPosition > 7) ? 'hidden' : ''}">
                <div>
                    <span class="h-3 w-3 inline-block
                      ${screenData.indexOfSelectedOption === 7 ? 'marrs-item-checked' : 'marrs-item-unchecked'}"></span>
                    <span>${languages[7]}</span>
                </div>
            </div>
            <div class="h-1/5 marrs-text-0-9em w-full flex flex-row items-center px-1
                        ${screenData.indexOfHighlightedOption === 8 ? 'text-white bg-marrs-dark-purple' : ''}
                        ${(screenData.indexOfOptionInTopPosition < 4
                            || screenData.indexOfOptionInTopPosition > 8) ? 'hidden' : ''}">
                <div>
                    <span class="h-3 w-3 inline-block
                      ${screenData.indexOfSelectedOption === 8 ? 'marrs-item-checked' : 'marrs-item-unchecked'}"></span>
                    <span>${languages[8]}</span>
                </div>
            </div>
            <div class="h-1/5 marrs-text-0-9em w-full flex flex-row items-center px-1
                        ${screenData.indexOfHighlightedOption === 9 ? 'text-white bg-marrs-dark-purple' : ''}
                        ${(screenData.indexOfOptionInTopPosition < 5
                            || screenData.indexOfOptionInTopPosition > 9) ? 'hidden' : ''}">
                <div>
                    <span class="h-3 w-3 inline-block
                      ${screenData.indexOfSelectedOption === 9 ? 'marrs-item-checked' : 'marrs-item-unchecked'}"></span>
                    <span>${languages[9]}</span>
                </div>
            </div>
            <div class="h-1/5 marrs-text-0-9em w-full flex flex-row items-center px-1
                        ${screenData.indexOfHighlightedOption === 10 ? 'text-white bg-marrs-dark-purple' : ''}
                        ${(screenData.indexOfOptionInTopPosition < 6
                            || screenData.indexOfOptionInTopPosition > 10) ? 'hidden' : ''}">
                <div>
                    <span class="h-3 w-3 inline-block
                    ${screenData.indexOfSelectedOption === 10 ? 'marrs-item-checked' : 'marrs-item-unchecked'}"></span>
                    <span>${languages[10]}</span>
                </div>
            </div>
            <div class="h-1/5 marrs-text-0-9em w-full flex flex-row items-center px-1
                        ${screenData.indexOfHighlightedOption === 11 ? 'text-white bg-marrs-dark-purple' : ''}
                        ${(screenData.indexOfOptionInTopPosition < 7
                            || screenData.indexOfOptionInTopPosition > 11) ? 'hidden' : ''}">
                <div>
                    <span class="h-3 w-3 inline-block
                    ${screenData.indexOfSelectedOption === 11 ? 'marrs-item-checked' : 'marrs-item-unchecked'}"></span>
                    <span>${languages[11]}</span>
                </div>
            </div>
            <div class="h-1/5 marrs-text-0-9em w-full flex flex-row items-center px-1
                        ${screenData.indexOfHighlightedOption === 12 ? 'text-white bg-marrs-dark-purple' : ''}
                        ${(screenData.indexOfOptionInTopPosition < 8
                            || screenData.indexOfOptionInTopPosition > 12) ? 'hidden' : ''}">
                <div>
                    <span class="h-3 w-3 inline-block
                    ${screenData.indexOfSelectedOption === 12 ? 'marrs-item-checked' : 'marrs-item-unchecked'}"></span>
                    <span>${languages[12]}</span>
                </div>
            </div>
            <div class="h-1/5 marrs-text-0-9em w-full flex flex-row items-center px-1
                        ${screenData.indexOfHighlightedOption === 13 ? 'text-white bg-marrs-dark-purple' : ''}
                        ${(screenData.indexOfOptionInTopPosition < 9
                            || screenData.indexOfOptionInTopPosition > 13) ? 'hidden' : ''}">
                <div>
                    <span class="h-3 w-3 inline-block
                    ${screenData.indexOfSelectedOption === 13 ? 'marrs-item-checked' : 'marrs-item-unchecked'}"></span>
                    <span>${languages[13]}</span>
                </div>
            </div>
            <div class="h-1/5 marrs-text-0-9em w-full flex flex-row items-center px-1
                        ${screenData.indexOfHighlightedOption === 14 ? 'text-white bg-marrs-dark-purple' : ''}
                        ${(screenData.indexOfOptionInTopPosition < 10
                            || screenData.indexOfOptionInTopPosition > 14) ? 'hidden' : ''}">
                <div>
                    <span class="h-3 w-3 inline-block
                    ${screenData.indexOfSelectedOption === 14 ? 'marrs-item-checked' : 'marrs-item-unchecked'}"></span>
                    <span>${languages[14]}</span>
                </div>
            </div>
            <div class="h-1/5 marrs-text-0-9em w-full flex flex-row items-center px-1
                        ${screenData.indexOfHighlightedOption === 15 ? 'text-white bg-marrs-dark-purple' : ''}
                        ${(screenData.indexOfOptionInTopPosition < 11
                            || screenData.indexOfOptionInTopPosition > 15) ? 'hidden' : ''}">
                <div>
                    <span class="h-3 w-3 inline-block
                    ${screenData.indexOfSelectedOption === 15 ? 'marrs-item-checked' : 'marrs-item-unchecked'}"></span>
                    <span>${languages[15]}</span>
                </div>
            </div>
            <div class="h-1/5 marrs-text-0-9em w-full flex flex-row items-center px-1
                        ${screenData.indexOfHighlightedOption === 16 ? 'text-white bg-marrs-dark-purple' : ''}
                        ${(screenData.indexOfOptionInTopPosition < 12
                            || screenData.indexOfOptionInTopPosition > 16) ? 'hidden' : ''}">
                <div>
                    <span class="h-3 w-3 inline-block
                    ${screenData.indexOfSelectedOption === 16 ? 'marrs-item-checked' : 'marrs-item-unchecked'}"></span>
                    <span>${languages[16]}</span>
                </div>
            </div>
            <div class="h-1/5 marrs-text-0-9em w-full flex flex-row items-center px-1
                        ${screenData.indexOfHighlightedOption === 17 ? 'text-white bg-marrs-dark-purple' : ''}
                        ${(screenData.indexOfOptionInTopPosition < 13
                            || screenData.indexOfOptionInTopPosition > 17) ? 'hidden' : ''}">
                <div>
                    <span class="h-3 w-3 inline-block
                    ${screenData.indexOfSelectedOption === 17 ? 'marrs-item-checked' : 'marrs-item-unchecked'}"></span>
                    <span>${languages[17]}</span>
                </div>
            </div>
        </div>`,
    biotechLoadFactoryTestCassette: (screenData: BaseScreenData) =>
        `<div class="h-full marrs-text-only font-bold">
            <div class="flex flex-row items-center"><span class="marrs-biotech-load-manual-cassette mx-auto h-20 w-20 inline-block"></span></div>
            <span>Load Manual Test</span><br/>
            <span>cassette.</span>
        </div>`,
    biotechLoadSet: (screenData: BaseScreenData) =>
        ` <div class="h-full w-full">
            <span class="marrs-biotech-load-set-animation h-40 block"></span>
         </div>`,
    biotechStartTest: (screenData: BaseScreenData) =>
        `<div class="h-full marrs-text-only flex flex-col justify-end pb-2">
            <div>
                <span>Press </span><span class="font-bold">Start </span><span>to begin</span>
            </div>
            <span>manual test.</span>
        </div>`,
    biotechTesting: (screenData: BaseScreenData) =>
        `<div class="h-full marrs-text-only flex flex-col justify-end pb-4">
            <div>
                <span>Image</span>
            </div>
            <div>
                <span>Wait until test ends or</span><br/>
                <span>press </span><span class="font-bold">Cancel.</span>
            </div>
        </div>`,
    biotechTestComplete: (screenData: BaseScreenData) =>
        `<div class="h-full marrs-text-only">
                <span class="block marrs-checkmark-icon mx-auto mt-1 h-8 w-8"></span><br/>
                <span class="font-bold">Test passed.</span><br/><br/>
                <span>To continue, press</span><br/>
                <span class="font-bold">Done.</span>
        </div>`,
    biotechTestFailed: (screenData: ManualTestFailed) =>
        `<div class="h-full marrs-text-only">
                <span class="block marrs-x-icon mx-auto mt-1 h-6 w-6"></span><br/>
                <span class="font-bold">Test failed with code</span><br/>
                <span class="font-bold">"${screenData.failureCode}".</span><br/><br/>
                <span>To continue, press</span><br/>
                <span class="font-bold">Done.</span>
        </div>`,
    disconnectPatientFromTubingNoFluid: (screenData: BaseScreenData) =>
        `<div class="h-full marrs-text-only pt-2">
               <span class="font-bold">Disconnect patient</span><br/>
               <span class="font-bold">from tubing before</span><br/>
               <span class="font-bold">priming.</span><br/><br/>
               <span>To prime, press</span><br/>
               <span class="font-bold">Auto Prime</span> <span>or</span><br/>
               <span class="font-bold">Hold To Prime</span><span>.</span>
        </div>`,
    disconnectPatientFromTubingFluid: (screenData: BaseScreenData) =>
        `<div class="h-full marrs-text-only pt-2">
               <span class="font-bold">Disconnect patient</span><br/>
               <span class="font-bold">from tubing before</span><br/>
               <span class="font-bold">priming.</span><br/><br/>
               <span>To prime, press</span> <span class="font-bold">Hold</span><br/>
               <span class="font-bold">To Prime</span><span>.</span>
        </div>`,
    ktoMinutes: (screenData: KTOCont | KTOBolus) =>
        `<div class="h-full marrs-text-0-85em pt-2 flex flex-row items-start pb-1">
            <div class="w-1/2 min-h-full">
                <span class="font-bold">Time</span>
                <span class="font-bold">Remaining</span>
            </div>
            <div class="w-1/2 min-h-full flex flex-col items-end justify-end">
                <span class="marrs-text-2-5em leading-none font-bold">${screenData.ktoMinutes}</span>
                <span class="font-bold">minutes</span>
            </div>
        </div>`,
    ktoCont: (screenData: KTOCont) =>
        `<div class="font-bold">
            <span class="block w-full marrs-text-0-9em">Total Fed:</span>
            <span class="block w-full text-right marrs-text-2-1em leading-none">
                ${Math.floor(screenData.patientFeedTotalizer / 10)}
            </span>
            <span class="block w-full text-right marrs-text-0-9em leading-none">mL</span>
        </div>`,
    ktoDoseLeft: (screenData: KTOCont) =>
            `<div class="marrs-text-only flex flex-row justify-between items-center h-full">
                <span class="inline-block font-bold">Dose Left:</span>
                <span class="inline-block font-bold">${screenData.continuousFeedDoseRemaining} mL</span>
            </div>`,
    ktoTotalFed: (screenData: KTOCont) =>
        `<div class="marrs-text-0-9em flex flex-row justify-between items-center font-bold h-full">
                <span class="inline-block">Total Fed:</span>
                <div class="inline-block">
                     <span>${Math.floor(screenData.patientFeedTotalizer / 10)} mL</span>
                </div>
            </div>`,
    adjustKtoMinutes: (screenData: CurrentValue) =>
        `<div class="mt-3">
            <div class="bg-marrs-orange text-white flex flex-col font-bold p-1">
                <span class="block marrs-text-0-9em">Duration:</span>
                <span class="block text-right marrs-text-3-15em">${screenData.currentValue}</span>
                <span class="block text-right marrs-text-0-9em">minutes</span>
            </div>
            <div class="flex flex-row justify-between items-center marrs-text-0-9em">
                <span>Maximum:</span>
                <span class="font-bold">240</span>
            </div>
        </div>`,
    removeCassette: (screenData: BaseScreenData) =>
        `<div class="h-full marrs-text-only pt-2">
               <br/><span>To continue, remove</span><br/>
               <span>and reload the</span><br/>
               <span>cassette.</span>
        </div>`,
    doseLeftFeeding: (screenData: ReadyToFeedDose) =>
        `<div class="marrs-text-0-9em flex flex-row justify-between items-center h-full">
            <span class="inline-block font-bold">Dose<br/>Left:</span>
            <span class="inline-block font-bold">${screenData.continuousFeedDose} mL</span>
        </div>`,
    feedTotalizerDoseFeeding: (screenData: ReadyToFeedCont) =>
        `<div class="marrs-text-0-9em flex flex-row justify-between items-center h-full font-bold">
            <span class="inline-block font-bold">Total<br/>Fed:</span>
            <div class="inline-block">
                 <span>${Math.floor(screenData.patientFeedTotalizer / 10)} mL</span>
            </div>
        </div>`,
    pumpInactive: (screenData: BaseScreenData) =>
        `<div class="h-full marrs-text-only pt-2">
               <span class="font-bold block text-center">PUMP INACTIVE</span><br/>
               <span>Pump has been idle for</span><br/>
               <span>10 minutes. Press</span><br/>
               <span class="font-bold">Continue</span> or <span class="font-bold">Power</span><br/>
               <span class="font-bold">Off</span><span>.</span>
        </div>`,
    lowBattery: (screenData: BaseScreenData) =>
        `<div class="h-full marrs-text-only pt-2">
               <span class="font-bold block text-center">LOW BATTERY</span><br/>
               <span>Plug pump into AC</span><br/>
               <span>Power now or pump</span><br/>
               <span>will stop feeding in</span><br/>
               <span>< 30 minutes.</span>
        </div>`,
    cassetteTubeError: (screenData: BaseScreenData) =>
        `<div class="h-full marrs-text-only pt-2">
               <span class="font-bold block text-center">CASSETTE ERROR</span><br/>
               <span>Tube slip detected.</span><br/>
               <span>Reload cassette or</span><br/>
               <span class="font-bold">Power Off</span>
        </div>`,
    rotorStuckError: (screenData: BaseScreenData) =>
        `<div class="h-full marrs-text-only pt-2">
               <span class="font-bold block text-center">ROTOR STUCK</span><br/>
               <span>Remove cassette. Check</span><br/>
               <span>rotor for blockage then</span><br/>
               <span>press</span> <span class="font-bold">Continue</span><br/>
               <span>or</span> <span class="font-bold">Power Off</span>
        </div>`,
    feedBagEmptyError: (screenData: BaseScreenData) =>
        `<div class="h-full marrs-text-only">
               <span class="font-bold block text-center">FEED BAG</span>
               <span class="font-bold block text-center">EMPTY</span>
               <div class="marrs-feed-bag-empty h-10 mx-auto w-17/20"></div>
               <span>Refill bag or load new</span><br/>
               <span>set and press</span><br/>
               <span class="font-bold">Continue.</span>
        </div>`,
    supplyTubeBlockedError: (screenData: BaseScreenData) =>
        `<div class="h-full marrs-text-only">
               <span class="font-bold block text-center">SUPPLY TUBE</span>
               <span class="font-bold block text-center">BLOCKED</span>
               <div class="marrs-supply-tube-blocked h-12 mx-auto w-17/20"></div>
               <span>Clear tube blockage and</span><br/>
               <span>press</span> <span class="font-bold">Continue.</span>
        </div>`,
    patientTubeBlockedError: (screenData: BaseScreenData) =>
        `<div class="h-full marrs-text-only">
               <span class="font-bold block text-center">PATIENT TUBE</span>
               <span class="font-bold block text-center">BLOCKED</span>
               <div class="marrs-patient-tube-blocked h-12 mx-auto w-17/20"></div>
               <span>Clear tube blockage and</span><br/>
               <span>press</span> <span class="font-bold">Continue.</span>
        </div>`,
    cassetteDislodgedError: (screenData: BaseScreenData) =>
        `<div class="h-full marrs-text-only pt-2">
               <span class="font-bold block text-center">CASSETTE</span>
               <span class="font-bold block text-center">DISLODGED</span><br/>
               <span>Cassette is dislodged</span><br/>
               <span>Reload cassette or</span><br/>
               <span class="font-bold">Power Off</span>
        </div>`,
    deadBatteryError: (screenData: BaseScreenData) =>
        `<div class="h-full marrs-text-only pt-2">
               <span class="font-bold block text-center">DEAD BATTERY</span>
               <span class="font-bold block text-center">ERROR</span><br/>
               <span>Plug pump into AC</span><br/>
               <span>Power now or press</span><br/>
               <span class="font-bold">Power Off</span>
        </div>`,
    systemError: (screenData: SystemError) =>
        `<div class="h-full marrs-text-only pt-2">
               <span class="font-bold block text-center">SYSTEM ERROR ${screenData.systemErrorCode}</span><br/>
               <span>Contact authorized</span><br/>
               <span>distributor and provide</span><br/>
               <span>error number (${screenData.systemErrorCode})</span>
        </div>`,
};
