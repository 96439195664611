
























































































import {Component, Vue} from 'vue-property-decorator';
import Table from '../../components/table/Table.vue';
import ReportDateSelector from '../../components/reports/ReportDateSelector.vue';
import moment, {Moment} from 'moment';
import {TableCellType, TableHeaderData, TableRowData} from '@/components/table/cells/TableCellData';
import {saveDownloadedFile, sortByProp} from '@/ts/utilities';
import {convertToMomentTimezone, formatMoment, formatMomentDateOnly} from '@/ts/TimezoneUtils';
import axios from 'axios';
import TextDisplay from '@/components/utility/TextDisplay.vue';
import {FilterType, SearchData} from '@/ts/Filter';
import {Patient} from '@/models/patients/Patient';
import {VarianceAvailabilityStatus} from '@/models/patients/Weight';
import {Team} from '@/models/Teams';
import User from '@/models/User';
import {Role} from '@/ts/permissions/Role';
import {Searchable} from '@/models/Searchable';
import Multiselect from 'vue-multiselect';
import {ModalMutations} from '@/vuex/modal';
import {namespace} from 'vuex-class';
import WirelessDiagnosticsModal from '@/components/devices/marrs/WirelessDiagnosticsModal.vue';
import {PatientHomeRouteOptions} from '@/models/patients/PatientHome';
import Spinner from '@/components/form/Spinner.vue';
import {AuthGetters} from '@/vuex/auth';
import HelpModal from '@/components/help/HelpModal.vue';


interface DashboardRecord {
    patient: Patient,
    lastConnectionTimestamp: string | Moment;
    serialNumber: string;
    goalWeight: number;
    weight: number;
    latestWeightDate: string | Moment;
    adherence: number;
    reportableDays: number;
    variance: number;
    varianceOver: boolean;
    teams: number[];
    deviceId: number;
}

enum DataAvailabilityStatus {
    AVAILABLE,
    INSUFFICIENT_DATA,
    ALL
}

const modal = namespace('modal');
const auth = namespace('auth');

@Component({
    components: {
        Table,
        ReportDateSelector,
        Multiselect,
        WirelessDiagnosticsModal,
        Spinner,
        HelpModal,
    }
})
export default class DashboardTest extends Vue {
    startDate: Moment | null = null;
    endDate: Moment | null = null;
    initialTab = 1;

    loading = false;
    timezone: string = "";

    currentSortKey: string = "adherence";
    sortedAsc: boolean = true;
    dashboardData: DashboardRecord[] = [];
    topListShowing = false;
    selectedTab = 1;
    adherenceRangeData = {
        min: 0,
        max: 100
    };
    varianceRangeData = {
        min: 0,
        max: 100,
    };
    maxAdherenceRange = 100;
    searchString = {
        key: Searchable.PATIENT_NAME,
        search: ""
    };

    filterData = {
        adherenceData: DataAvailabilityStatus.ALL,
        adherence: [] as number[],
        weightStatus: DataAvailabilityStatus.ALL,
        weightDateStart: null as Date | null,
        weightDateEnd: null as Date | null,
        varianceRange: [] as number[],
        varianceType: [] as VarianceAvailabilityStatus[],
        connectionStart: null as Date | null,
        connectionEnd: null as Date | null,
    };

    teamList: Team[] = [];

    selectedTeams: Team[] = [];

    @modal.Mutation(ModalMutations.CHANGE_MODAL_STATE) changeModalState!: (state: boolean) => void;
    @auth.Getter(AuthGetters.MEASUREMENT_UNIT) measurementUnit!: string;
    modalShowing = false;
    wirelessDiagnosticsPumpId: number | null = null;
    wirelessDiagnosticsSerialNumber: string = '';

    async created(){
        this.timezone = this.$store.state.auth.loggedInUser.timezone;
        this.startDate = moment.tz(this.timezone).subtract(28, 'day').set('hour', 0).set('minute', 0).set('second', 0);
        this.endDate = moment.tz(this.timezone).subtract(1, 'day').set('hour', 23).set('minute', 59).set('second', 59);
        await Promise.all([
            this.fetchData(),
            // this.fetchTeams()
        ]);
    }

    async datesChanged(dates: any){
        this.startDate = dates.startDate;
        this.endDate = dates.endDate;
        await this.fetchData();
    }

    get tableHeader(): TableHeaderData[]{
        const vm = this;
        return [
            {
                sortable: true,
                value: "Patient name",
                widthClass: "w-1/4",
                sortKey: "name",
            },
            {
                sortable: true,
                value: "Adherence %",
                widthClass: "w-1/4",
                sortKey: "adherence",
                filterable: true,
                filterKey: "adherenceFilter",
                filters: [
                    {
                        type: FilterType.MULTISELECT,
                        key: 'status',
                        active: vm.filterData.adherenceData != DataAvailabilityStatus.ALL,
                        enabled: true,
                        displayText: "Filter by result",
                        data: {
                            multiselect: true,
                            activeItems: {
                                status: vm.filterData.adherenceData != DataAvailabilityStatus.ALL ? [vm.filterData.adherenceData] : []
                            },
                            items: [
                                {
                                    value: DataAvailabilityStatus.AVAILABLE,
                                    label: 'Adherence available',
                                    key: 'available'
                                },
                                {
                                    value: DataAvailabilityStatus.INSUFFICIENT_DATA,
                                    label: 'Insufficient data',
                                    key: 'insufficient'
                                },
                            ]
                        }
                    },
                    {
                        type: FilterType.RANGE,
                        key: 'adherence',
                        active: vm.filterData.adherence.length > 0,
                        enabled: true,
                        displayText: "Filter by adherence range",
                        data: {
                            minRange: this.adherenceRangeData.min,
                            maxRange: this.adherenceRangeData.max,
                            activeRangeData: vm.filterData.adherence.length > 0 ? [...vm.filterData.adherence] : []
                        }
                    }
                ]
            },
            {
                sortable: true,
                value: "Weight",
                widthClass: "w-1/4",
                sortKey: "weight",
                filterable: true,
                filterKey: "weightFilter",
                filters: [
                    {
                        type: FilterType.MULTISELECT,
                        key: 'status',
                        active: vm.filterData.weightStatus !== DataAvailabilityStatus.ALL,
                        enabled: true,
                        displayText: "Filter by result",
                        data: {
                            multiselect: true,
                            activeItems: {
                                status: vm.filterData.weightStatus != DataAvailabilityStatus.ALL ? [vm.filterData.weightStatus] : []
                            },
                            items: [
                                {
                                    value: DataAvailabilityStatus.AVAILABLE,
                                    label: 'Weight available',
                                    key: 'available'
                                },
                                {
                                    value: DataAvailabilityStatus.INSUFFICIENT_DATA,
                                    label: 'Weight not available',
                                    key: 'insufficient'
                                },
                            ]
                        }
                    },
                    {
                        type: FilterType.CALENDAR,
                        key: 'dateRange',
                        active: vm.filterData.weightDateStart != null,
                        enabled: true,
                        displayText: 'Filter by weight date range',
                        data: {
                            initialRange: {
                                start: vm.filterData.weightDateStart,
                                end: vm.filterData.weightDateEnd,
                            }
                        }
                    },
                    {
                        type: FilterType.RANGE,
                        key: 'variance',
                        active: vm.filterData.varianceRange.length > 0,
                        enabled: true,
                        displayText: "Filter by variance range",
                        data: {
                            minRange: this.varianceRangeData.min,
                            maxRange: this.varianceRangeData.max,
                            activeRangeData: vm.filterData.varianceRange.length > 0 ? [...vm.filterData.varianceRange] : []
                        }
                    },
                    {
                        type: FilterType.MULTISELECT,
                        key: 'varianceStatus',
                        active: vm.filterData.varianceType.length > 0,
                        enabled: true,
                        displayText: "Filter by variance",
                        data: {
                            multiselect: true,
                            activeItems: {
                                varianceStatus: vm.filterData.varianceType.length > 0 ? [...vm.filterData.varianceType] : []
                            },
                            items: [
                                {
                                    value: VarianceAvailabilityStatus.OVER,
                                    label: 'Over',
                                    key: 'over'
                                },
                                {
                                    value: VarianceAvailabilityStatus.UNDER,
                                    label: 'Under',
                                    key: 'under'
                                },
                                {
                                    value: VarianceAvailabilityStatus.NOT_AVAILABLE,
                                    label: 'Not available',
                                    key: 'na'
                                },
                            ]
                        }
                    },
                ]
            },
            {
                sortable: true,
                value: "Last connection",
                widthClass: "w-1/4",
                sortKey: "lastConnection",
                filterable: true,
                filterKey: 'connectionFilter',
                filters: [
                    {
                        type: FilterType.CALENDAR,
                        key: 'dateRange',
                        active: vm.filterData.connectionStart != null,
                        enabled: true,
                        displayText: 'Filter by date range',
                        data: {
                            initialRange: {
                                start: vm.filterData.connectionStart,
                                end: vm.filterData.connectionEnd,
                            }
                        }
                    }
                ]
            },
        ];
    }

    get sortedDashboardData() {
        if(this.currentSortKey === "name") {
            return this.dashboardData.sort(sortByProp((a: DashboardRecord) => a.patient.lastName, this.sortedAsc))
        }else if(this.currentSortKey === "adherence"){
            return this.dashboardData.sort(sortByProp((a: DashboardRecord) => a.adherence, this.sortedAsc));
        }else if(this.currentSortKey === "weight"){
            return this.dashboardData.sort(sortByProp((a: DashboardRecord) => a.weight, this.sortedAsc))
        }else {
            return this.dashboardData.sort(sortByProp((a: DashboardRecord) => a.lastConnectionTimestamp as Moment, this.sortedAsc));
        }
    }

    get tableData(): TableRowData[]{
        const vm = this;
        return this.sortedDashboardData
            .filter(this.filterDashboardByTeams)
            .filter(this.filterDashboardBySearch)
            .filter(this.filterDashboardDataByAdherence)
            .filter(this.filterDashboardDataByWeight)
            .filter(this.filterDashboardByConnectionDate)
            .map(record => {
                return {
                    cells: [
                        {
                            type: TableCellType.SLOT_LIST,
                            primaryValue: '',
                            components: [
                                {
                                    primaryValue: TextDisplay,
                                    componentOptions: {
                                        title: record.patient.lastName + ", " + record.patient.firstName,
                                        value: 'DOB: ' + formatMomentDateOnly(record.patient.dateOfBirth as Moment),
                                        titleClickable: true
                                    },
                                    events: {
                                        titleClick: () => {
                                            if(record.patient.id !== null) {
                                                this.$router.push({name: 'patient-home', params: {id: record.patient.id.toString()}, query: {reportRange: this.selectedTab.toString()}})
                                            }
                                        }
                                    }
                                }
                            ]
                        },
                        {
                            type: TableCellType.SLOT_LIST,
                            primaryValue: '',
                            components: [
                                {
                                    primaryValue: TextDisplay,
                                    componentOptions: {
                                        title: record.reportableDays > 0 ? Math.round(record.adherence) + "%" : 'Insufficient data',
                                        value: record.reportableDays > 0 ? `${record.reportableDays} of ${this.numTotalDays} days reported` : 'N/A',
                                    }
                                }
                            ]
                        },
                        {
                            type: TableCellType.SLOT_LIST,
                            primaryValue: '',
                            components: [
                                {
                                    primaryValue: TextDisplay,
                                    componentOptions: {
                                        title: record.weight !== null ? `${record.weight} ${this.measurementUnit}\u00A0` : '',
                                        value: record.weight !== null ? `(Updated: ${formatMomentDateOnly(record.latestWeightDate as Moment)})` : "Click here to enter a weight",
                                        inline: true,
                                        titleClickable: record.weight !== null,
                                        valueClickable: record.weight === null
                                    },
                                    events: {
                                        titleClick: () => {
                                            if(record.patient.id !== null && record.weight !== null) {
                                                vm.$router.push({name: 'patient-home', params: {id: record.patient.id.toString()}, query: {initialOption: PatientHomeRouteOptions.WEIGHT}})
                                            }

                                        },
                                        valueClick: () => {
                                            if(record.patient.id !== null && record.weight === null) {
                                                vm.$router.push({name: 'patient-home', params: {id: record.patient.id.toString()}, query: {initialOption: PatientHomeRouteOptions.WEIGHT}})
                                            }
                                        }
                                    }
                                },
                                {
                                    primaryValue: TextDisplay,
                                    componentOptions: {
                                        title: record.weight !== null && record.goalWeight !== null ? `Variance:\u00A0` : '',
                                        value: record.weight !== null && record.goalWeight !== null ? `${Math.round(record.variance)}% ${record.varianceOver ? "over": "under"} goal weight` : `${record.goalWeight === null ? 'Click here to enter a goal weight' : 'Weight is required to calculate variance'}`,
                                        inline: true,
                                        valueClickable: (record.weight !== null && record.goalWeight !== null) || record.goalWeight === null
                                    },
                                    events: {
                                        valueClick: () => {
                                            if(record.patient.id !== null && record.goalWeight === null) {
                                                vm.$router.push({name: 'patient-home', params: {id: record.patient.id.toString()}, query: {initialOption: PatientHomeRouteOptions.GOAL_WEIGHT}})
                                            }

                                        }
                                    }
                                }
                            ]
                        },
                        {
                            type: TableCellType.SLOT_LIST,
                            primaryValue: '',
                            components: [
                                {
                                    primaryValue: TextDisplay,
                                    componentOptions: {
                                        title: record.lastConnectionTimestamp != null ? formatMoment(record.lastConnectionTimestamp as Moment) : 'N/A',
                                        value: `Pump SN: ${record.serialNumber}`,
                                        titleClickable: true,
                                        valueClickable: true
                                    },
                                    events: {
                                        titleClick: () => {
                                            vm.showModal(record.deviceId, record.serialNumber);
                                        },
                                        valueClick: () => {
                                            vm.$router.push({name: 'enteral-pump-home', params: {id: record.deviceId.toString()}})
                                        }
                                    }
                                }
                            ]
                        },
                    ],
                    key: record.patient.id,
                    highlighted: false,
                    indexKey: record.patient.id as number
                }
            })
    }

    async fetchData(){
        if(this.startDate == null || this.endDate == null){
            return;
        }
        this.loading = true;
        try{
            const {data}: {data: DashboardRecord[]} = await axios.get(process.env.VUE_APP_BACKEND_URI + '/devices/marrs/dashboard', {
                params: {
                    startDate: this.startDate.toISOString(),
                    endDate: this.endDate.toISOString()
                }
            });
            data.forEach(record => {
                record.patient.dateOfBirth = convertToMomentTimezone('UTC', record.patient.dateOfBirth as string);
                record.lastConnectionTimestamp = convertToMomentTimezone(this.timezone, record.lastConnectionTimestamp as string);
                record.latestWeightDate = convertToMomentTimezone(this.timezone, record.latestWeightDate as string);
            });
            const filteredAdherenceList = data.filter((record => record.adherence !== null));
            if(filteredAdherenceList.length == 0){
                this.adherenceRangeData.min = 0;
                this.adherenceRangeData.max = 0;
            }else{
                this.adherenceRangeData.min = Math.round(filteredAdherenceList.reduce((a, b) => a.adherence < b.adherence ? a : b).adherence);
                this.adherenceRangeData.max = Math.round(filteredAdherenceList.reduce((a, b) => a.adherence > b.adherence ? a : b).adherence);
            }

            const filteredVarianceList = data.filter(record => record.variance != null);
            if(filteredVarianceList.length === 0){
                this.varianceRangeData.min = 0;
                this.varianceRangeData.max = 0;
            }else{
                this.varianceRangeData.min = Math.round(filteredVarianceList.reduce((a, b) => a.variance < b.variance ? a : b).variance);
                this.varianceRangeData.max = Math.round(filteredVarianceList.reduce((a, b) => a.variance > b.variance ? a : b).variance);
            }

            this.dashboardData = data;
        }catch(e){
            console.log(e);
        }
        this.loading = false;
    }

    sortData(message: any){
        console.log(message);
        this.currentSortKey = message.sortKey;
        this.sortedAsc = message.sortedAsc;
    }

    handleAdherenceFilter(data: any){
        if(data.status.length == 1){
            this.filterData.adherenceData = data.status[0];
        }else{
            this.filterData.adherenceData = DataAvailabilityStatus.ALL;
        }

        if(data.adherence.length == 2 && (data.adherence[0] != this.adherenceRangeData.min || data.adherence[1] != this.adherenceRangeData.max) && (data.adherence[0] !== data.adherence[1])){
            this.filterData.adherence = data.adherence;
        } else {
            this.filterData.adherence = []
        }
    }

    handleWeightFilter(data: any){
        if(data.status.length == 1){
            this.filterData.weightStatus = data.status[0];
        }else{
            this.filterData.weightStatus = DataAvailabilityStatus.ALL;
        }

        if(data.dateRange){
            this.filterData.weightDateStart = data.dateRange.start;
            this.filterData.weightDateEnd = data.dateRange.end;
        }

        if(data.variance.length == 2 && (data.variance[0] != this.varianceRangeData.min || data.variance[1] != this.varianceRangeData.max) && data.variance[0] !== data.variance[1]){
            this.filterData.varianceRange = data.variance;
        } else {
            this.filterData.varianceRange = [];
        }

        this.filterData.varianceType = data.varianceStatus;
    }

    handleConnectionFilter(data: any){
        if(data.dateRange){
            this.filterData.connectionStart = data.dateRange.start;
            this.filterData.connectionEnd = data.dateRange.end;
        }
    }

    clearAllFilters() {
        this.filterData.adherenceData = DataAvailabilityStatus.ALL;
        this.filterData.adherence = [];
        this.filterData.weightStatus = DataAvailabilityStatus.ALL;
        this.filterData.weightDateStart = null;
        this.filterData.weightDateEnd = null;
        this.filterData.varianceRange = [];
        this.filterData.varianceType = [];
        this.filterData.connectionStart = null;
        this.filterData.connectionEnd = null;
    }

    filterDashboardDataByAdherence(record: DashboardRecord): boolean{
        if(this.filterData.adherenceData === DataAvailabilityStatus.ALL){
            if(this.filterData.adherence.length > 0 && record.adherence != null){
                const adherence = Math.round(record.adherence);
                return adherence >= this.filterData.adherence[0] && adherence <= this.filterData.adherence[1];
            }
            return true;
        }else if(this.filterData.adherenceData === DataAvailabilityStatus.AVAILABLE && record.adherence !== null){
            if(this.filterData.adherence.length > 0){
                const adherence = Math.round(record.adherence);
                return adherence >= this.filterData.adherence[0] && adherence <= this.filterData.adherence[1];
            }
            return true;
        }else if(this.filterData.adherenceData === DataAvailabilityStatus.INSUFFICIENT_DATA && record.adherence === null){
            return true;
        }

        return false;
    }

    filterDashboardDataByWeight(record: DashboardRecord): boolean {
        if(this.filterData.weightStatus === DataAvailabilityStatus.AVAILABLE && record.weight === null){
            return false;
        }else if(this.filterData.weightStatus === DataAvailabilityStatus.INSUFFICIENT_DATA && record.weight !== null){
            return false;
        }

        if(this.filterData.weightDateStart != null && this.filterData.weightDateEnd !== null && !(record.latestWeightDate == null || (record.latestWeightDate as Moment).isBetween(this.filterData.weightDateStart, this.filterData.weightDateEnd.setHours(23, 59, 59)))){
            return false;
        }

        let keep;
        const recordVariance = record.variance != null ? Math.round(record.variance) : null

        if(this.filterData.varianceRange.length > 0 && this.filterData.varianceType.length !== 3){
            const varianceTypes = [...this.filterData.varianceType]
            if(varianceTypes.length === 0) {
              varianceTypes.push(VarianceAvailabilityStatus.UNDER, VarianceAvailabilityStatus.OVER);
            }

            if(varianceTypes.includes(VarianceAvailabilityStatus.OVER) && recordVariance !== null){
                keep = record.varianceOver && recordVariance >= this.filterData.varianceRange[0] && recordVariance <= this.filterData.varianceRange[1];
                console.log(keep);
            }

            if(varianceTypes.includes(VarianceAvailabilityStatus.UNDER) && !keep && recordVariance !== null){
                keep = !record.varianceOver && recordVariance >= this.filterData.varianceRange[0] && recordVariance <= this.filterData.varianceRange[1];
            }

            if(varianceTypes.includes(VarianceAvailabilityStatus.NOT_AVAILABLE) && !keep){
                keep = record.variance === null;
            } else if(!varianceTypes.includes(VarianceAvailabilityStatus.NOT_AVAILABLE) && recordVariance === null) {
                keep = false;
            }
        }else if(this.filterData.varianceRange.length === 0 && this.filterData.varianceType.length !== 0 && this.filterData.varianceType.length !== 3){
            if(this.filterData.varianceType.includes(VarianceAvailabilityStatus.OVER)){
                keep = record.varianceOver;
                console.log(keep);
            }

            if(this.filterData.varianceType.includes(VarianceAvailabilityStatus.UNDER) && !keep){
                keep = record.variance !== null && !record.varianceOver;
            }

            if(this.filterData.varianceType.includes(VarianceAvailabilityStatus.NOT_AVAILABLE) && !keep){
                keep = record.variance === null;
            }
        }

        return keep !== undefined ? keep : true;
    }

    filterDashboardByConnectionDate(record: DashboardRecord){
        return !(this.filterData.connectionStart != null && this.filterData.connectionEnd !== null && !(record.lastConnectionTimestamp as Moment).isBetween(this.filterData.connectionStart, this.filterData.connectionEnd.setHours(23, 59, 59)));
    }

    filterDashboardByTeams(record: DashboardRecord){
        if(this.selectedTeams.length > 0){
            return this.selectedTeams.filter(team => record.teams.includes(team.id)).length > 0;
        }

        return true;
    }

    filterDashboardBySearch(record: DashboardRecord){
        if(this.searchString.search === ""){
            return true;
        }

        if(this.searchString.key === Searchable.PATIENT_NAME){
            return record.patient.firstName.toLowerCase().includes(this.searchString.search.toLowerCase()) || record.patient.lastName.toLowerCase().includes(this.searchString.search.toLowerCase()) ||
                (record.patient.firstName.toLowerCase() + " " + record.patient.lastName.toLowerCase()).includes(this.searchString.search.toLowerCase());
        }

        if(this.searchString.key === Searchable.SERIAL_NUMBER){
            return record.serialNumber.startsWith(this.searchString.search) || record.serialNumber.endsWith(this.searchString.search);
        }

        return false;
    }

    async exportListToExcel(){
        if(this.startDate === null || this.endDate === null){
            return;
        }
        let request: any = {...this.filterData};
        if(this.searchString.search !== ""){
            request.searchable = this.searchString.key;
            request.search = this.searchString.search;
        }
        request.teams = this.selectedTeams.map(team => team.id);
        request.sortKey = this.currentSortKey;
        request.sortedAsc = this.sortedAsc;
        this.$addSnackbarMessage({
            message: 'Patient dashboard export in progress',
        });
        try{
            const response = await axios.post(process.env.VUE_APP_BACKEND_URI + '/devices/marrs/dashboard/csv', request,{
                params: {
                    startDate: this.startDate.toISOString(),
                    endDate: this.endDate.toISOString()
                },
                responseType: 'blob'
            });
            saveDownloadedFile(response, 'patient-dashboard-export.xlsx');
            this.$addSnackbarMessage({
                message: 'Patient dashboard exported successfully',
            });
        }catch(e){
            this.$addSnackbarMessage({
                message: 'There was an issue exporting the patient dashboard. Try again later or contact customer support.',
            });
        }
        this.topListShowing = false;
    }

    async exportListToPdf(){
        if(this.startDate === null || this.endDate === null){
            return;
        }
        let request: any = {...this.filterData};
        if(this.searchString.search !== ""){
            request.searchable = this.searchString.key;
            request.search = this.searchString.search;
        }
        request.teams = this.selectedTeams.map(team => team.id);
        request.sortKey = this.currentSortKey;
        request.sortedAsc = this.sortedAsc;
        this.$addSnackbarMessage({
            message: 'Patient dashboard export in progress',
        });
        try{
            const response = await axios.post(process.env.VUE_APP_BACKEND_URI + '/devices/marrs/dashboard/pdf', request,{
                params: {
                    startDate: this.startDate.toISOString(),
                    endDate: this.endDate.toISOString()
                },
                responseType: 'blob'
            });
            saveDownloadedFile(response, 'patient-dashboard-export.pdf');
            this.$addSnackbarMessage({
                message: 'Patient dashboard exported successfully',
            });
        }catch(e){
            this.$addSnackbarMessage({
                message: 'There was an issue exporting the list of customers. Try again later or contact customer support.',
            });
        }
        this.topListShowing = false;
    }

    async fetchTeams(){
        const user = this.$store.state.auth.loggedInUser as User;
        if(user.role !== Role.ADMIN){
            this.teamList = user.teams;
            return;
        }
        try{
            const {data}: {data: Team[]} = await axios.get(process.env.VUE_APP_BACKEND_URI + "/teams");
            this.teamList = data;
        }catch(e){
            console.log(e);
        }
    }

    get searchTypes(): SearchData[] {
        return [
            {
                key: Searchable.PATIENT_NAME,
                value: 'Patient name'
            },
            {
                key: Searchable.SERIAL_NUMBER,
                value: 'Serial number'
            }
        ]
    }

    get numTotalDays(){
        if(this.endDate === null || this.startDate === null){
            return 0;
        }
        return this.endDate.diff(this.startDate, 'days') + 1;
    }

    searchData(searchString: any){
        this.searchString = searchString;
    }

    showModal(pumpId: number, serialNumber: string){
        this.wirelessDiagnosticsPumpId = pumpId;
        this.wirelessDiagnosticsSerialNumber = serialNumber;
        this.changeModalState(true);
        this.modalShowing = true;
    }

    closeModal(){
        this.modalShowing = false;
        this.changeModalState(false);
        this.wirelessDiagnosticsPumpId = null;
        this.wirelessDiagnosticsSerialNumber = '';
    }
}
