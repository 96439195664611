import {Moment} from 'moment';
import {PumpSettingsRecord} from '@/models/Devices/MARRS/Records';

/**
 * Represents a pump settings report
 */
export interface PumpSettingsReportData {
    startDate: string | Moment;
    endDate: string | Moment;
    pumpSettingsRecords: PumpSettingsRecord[];
}

export interface PumpSettingsFilter {
    includedTypes: number[];
}

/**
 * Returns the description for each feed mode
 * @param mode
 */
export function getFeedMode(mode: number) {
    switch (mode) {
        case 0:
            return 'Continuous';
        case 1:
            return 'Dose';
        case 2:
            return 'Bolus';
        default:
            return '';
    }
}
