import {FirmwareJobCount, FirmwareUpgradeJob, FirmwareUpgradeJobStatus} from '@/models/Devices/MARRS/MARRSPump';
import axios from 'axios';
import FirmwareFile from '@/models/Devices/MARRS/FirmwareFile';
import {AsyncNullSuccess, Either, Failure, nullSuccess, Success} from '@/errors/Either';
import {Error} from '@/errors/Error';


export default class ConnectFirmwareUpgradeService {
    private readonly timezone: string;


    constructor(timezone: string) {
        this.timezone = timezone;
    }

    /**
     * Fetches the status of a pump software update
     * @param pumpId ID of the pump to fetch the update for
     */
    public async fetchPumpFirmwareDownloadStatus(pumpId: number) {
        try {
            // tslint:disable-next-line:max-line-length
            const {data}: {data: FirmwareUpgradeJob | ''} = await axios.get(process.env.VUE_APP_BACKEND_URI + '/devices/marrs/' + pumpId + '/update-status');
            if (data === '') {
                return null;
            }
            return data;
        } catch (e) {
            return null;
        }
    }

    /**
     * Fetches a list of available firmware files for updating
     */
    public async fetchAvailableFirmwareFiles() {
        try {
            // tslint:disable-next-line:max-line-length
            const {data}: {data: FirmwareFile[]} = await axios.get(process.env.VUE_APP_BACKEND_URI + '/devices/marrs/firmware-update/files');
            data.forEach((file) => {
                file.name = file.version.toFixed(2) + ' Software';
            });
            return data;
        } catch (e) {
            return [];
        }
    }

    /**
     * Sends the request to start a firmware update for a list of pumps
     * @param selectedPumps Pumps to update
     * @param firmwareFile Firmware to apply
     */
    public async updatePumpFirmware(selectedPumps: number[], firmwareFile: FirmwareFile): AsyncNullSuccess<Error> {
        try {
            await axios.post(process.env.VUE_APP_BACKEND_URI + '/devices/marrs/firmware-update', {
                targetDeviceIdList: selectedPumps,
                file: firmwareFile.fileName,
            });
            return nullSuccess();
        } catch (e) {
            return new Failure({
                message: 'Unable to update pump firmware',
            });
        }
    }

    /**
     * Sends a request to move all eligible pumps to step 2
     */
    public async sendFirmwareJobsToStep2(): Promise<Either<null, Error> > {
        try {
            await axios.post(process.env.VUE_APP_BACKEND_URI + '/devices/marrs/firmware-update/step-two');
            return new Success(null);
        } catch (e) {
            return new Failure({message: 'Error going to step two'});
        }
    }

    /**
     * Sends a request to have all failed pumps retry their firmware updates
     */
    public async retryFailedFirmwareJobs() {
        try {
            await axios.post(process.env.VUE_APP_BACKEND_URI + '/devices/marrs/firmware-update/retry');
            return new Success(null);
        } catch (e) {
            return new Failure({message: 'Error going to step two'});
        }
    }

    /**
     * Fetches a list of pumps at each step
     */
    public async getFirmwareJobCount(): Promise<Either<FirmwareJobCount, Error>> {
        try {
            // tslint:disable-next-line:max-line-length
            const {data}: {data: FirmwareJobCount} = await axios.get(process.env.VUE_APP_BACKEND_URI + '/devices/marrs/firmware-update/available-jobs');
            return new Success(data);
        } catch (e) {
            return new Failure({message: 'Failure getting job counts'});
        }
    }

    /**
     * Returns formatted status text for a firmware upgrade job about its status
     * @param job
     */
    public getFirmwareJobStatusText(job: FirmwareUpgradeJob | null) {
        if (job === null || job === undefined) {
            return ['N/A'];
        }

        if (job.status === FirmwareUpgradeJobStatus.ERROR) {
            return [`Download Failed <span class="font-semibold underline">Retry</span>`];
        }

        if (job.status === FirmwareUpgradeJobStatus.CANCELLED) {
            return ['Download Cancelled'];
        }

        if (job.step === 1) {
            // tslint:disable-next-line:max-line-length
            return job.status === FirmwareUpgradeJobStatus.PENDING ?
                [`Step 1:`, `Downloading ${job.swVersion.toFixed(2)} SW`] :
                [`Step 1:`, `Complete`];
        } else if (job.step === 2) {
            // tslint:disable-next-line:max-line-length
            return job.status === FirmwareUpgradeJobStatus.PENDING ?
                [`Step 2:`, `Downloading ${job.uiVersion.toFixed(2)} UI`] :
                [`Step 2:`, `Complete`];
        } else if (job.step === 3) {
            // tslint:disable-next-line:max-line-length
            return job.status === FirmwareUpgradeJobStatus.PENDING ?
                [`Step 3:`, `Software downloaded. Pending reboot.`] :
                [`Software update ${job.swVersion.toFixed(2)} complete.`];
        }

        return ['N/A'];
    }
}
