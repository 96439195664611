



































































import {Component, Prop, Vue} from "vue-property-decorator";
import {Hub} from '@/models/Devices/Hubs/Hub';
import {ButtonType} from "../../form/FormTypes";
import DisableWirelessModal from '@/components/devices/hubs/DisableWirelessModal.vue';
import EnableWirelessModal from '@/components/devices/hubs/EnableWirelessModal.vue';

/**
 * Renders the basic details about a hub
 */
@Component({
    components:{
        EnableWirelessModal,
        DisableWirelessModal
    }
})
export default class HubDetails extends Vue {
    /**
     * Current hub
     */
    @Prop({type: Object}) hub!: Hub;
    ButtonType = ButtonType;

    /**
     * Whether the disable wireless modal is showing
     */
    disableWirelessModalShowing = false;

    /**
     * Whether the enable wireless modal is showing
     */
    enableWirelessModalShowing = false;

    /**
     * Creates a shortened version of the hub's wireless ID
     */
    get hubWirelessId(){
        const wirelessIdParts = this.hub.connectionId.split("-");
        return wirelessIdParts[2] + "-" + wirelessIdParts[3];
    }

    /**
     * Returns the appropriate image based on the type of hub
     */
    get imageName(){
        if(this.hub.productName === "3G"){
            return require('../../../assets/hubs/3g.png');
        }else if(this.hub.productName === "Wifi"){
            return require('../../../assets/hubs/wifi.jpg');
        }else {
            return require('../../../assets/hubs/lte.png');
        }
    }

    /**
     * Sets the appropriate modal flag when the enable/disable wireless button is clicked
     */
    toggleWireless(){
        if(this.hub.enabled){
            this.disableWirelessModalShowing = true;
        }else{
            this.enableWirelessModalShowing = true;
        }
    }
}
