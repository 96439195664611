

















































































import {Component, Prop, Vue} from "vue-property-decorator";
import {Hub} from '@/models/Devices/Hubs/Hub';
import {ButtonType} from "../../form/FormTypes";
import {namespace} from 'vuex-class';
import {HubActions} from "@/vuex/hubs";
import Spinner from '@/components/form/Spinner.vue';

const hubs = namespace('hubs');

/**
 * Renders the extended details about a specific hub
 */
@Component({
    components: {
        Spinner
    }
})
export default class ExtendedDeviceDetails extends Vue {

    /**
     * Current Hub
     */
    @Prop({type: Object}) hub!: Hub;
    ButtonType = ButtonType;

    /**
     * Whether the update device details request is pending
     */
    deviceDetailsLoading = false;

    @hubs.Action(HubActions.UPDATE_HUB_DETAILS) updateHubDetails!: (id: number) => any;

    /**
     * Sends the request to update the hub's details
     */
    async updateDeviceDetails(){
        this.deviceDetailsLoading = true;
        await this.updateHubDetails(this.hub.id);
        this.deviceDetailsLoading = false;
    }
}
