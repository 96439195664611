











































import { Component, Vue } from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import {ButtonType} from '../../form/FormTypes';
import DeviceConnectionStatus from '@/components/devices/DeviceConnectionStatus.vue';
import IconDisplay from '@/components/utility/IconDisplay.vue';

const patients = namespace('patients');

/**
 * Provides a quick way to get to monitor mode
 */
@Component({
    components: {
        DeviceConnectionStatus,
        IconDisplay,
    }
})
export default class PatientMonitorPumpQuickLink extends Vue {
    @patients.State(state => state.homePage.deviceConnectionPending) connectionPending!: boolean;
    @patients.State(state => state.homePage.deviceConnected) connected!: boolean;

    buttonType = ButtonType;
}
