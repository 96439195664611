import { render, staticRenderFns } from "./PatientListPage.vue?vue&type=template&id=0ea7b9a9&scoped=true&"
import script from "./PatientListPage.vue?vue&type=script&lang=ts&"
export * from "./PatientListPage.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ea7b9a9",
  null
  
)

export default component.exports