















































import {Component, Prop, Vue} from "vue-property-decorator";
import Multiselect from 'vue-multiselect';
import {CustomerStatus, CustomerStatusMap} from "@/models/customers/Customer";
import {ButtonType} from "@/components/form/FormTypes";
import CustomerService from '@/services/CustomerService';

/**
 * Renders a form for changing the current status of a customer account
 */
@Component({
    components: {
        Multiselect
    }
})
export default class ManageCustomerStatus extends Vue {

    /**
     * Current customer status
     */
    @Prop() customerStatus!: number;

    /**
     * Current customer id
     */
    @Prop() customerId!: number;

    /**
     * Field for storing the reason for customer status change
     */
    notes = '';
    buttonTypes = ButtonType;

    statuses: any[] = [];
    selectedStatus: any = null;

    customerService = new CustomerService(this.$store.state.auth.loggedInUser.timezone);

    mounted(){
        this.statuses = CustomerStatusMap;
        this.selectedStatus= this.statuses.find(status => status.value !== this.customerStatus);
    }

    /**
     * Sends the request to change the current customer status
     */
    async updateStatus(){
        if(this.selectedStatus != null){
            const request = {
                active: this.selectedStatus.value === CustomerStatus.ENABLED,
                notes: this.notes
            };

            const response = await this.customerService.updateCustomerStatus(this.customerId, request);
            response.map(() => {
                this.$addSnackbarMessage({
                    message: 'Customer status updated successfully',
                });
                this.$emit('status', this.selectedStatus.value);
            }).mapErr(() => {
                this.$addSnackbarMessage({
                    message: 'There was an issue updating the status of the customer. Try again later or contact customer support.',
                });
            })
        }

    }
}
