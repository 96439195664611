import {Module} from 'vuex';
import axios from 'axios';
import {Hub, HubListRequest, SetHubData, SetHubListData, ToggleWirelessData} from '@/models/Devices/Hubs/Hub';
import {PaginatedData} from '@/components/table/cells/TableCellData';
import * as moment from 'moment';
import {convertToMomentTimezone, convertToTimezone} from '@/ts/TimezoneUtils';
import Vue from 'vue';

export interface HubsModel {
    currentHub: Hub | null;
    hubList: PaginatedData<Hub> | null;
}

export enum HubGetters {

}

export enum HubMutations {
    SET_HUB_LIST_DATA = 'setHubListData',
    SET_WIRELESS_STATE = 'setWirelessState',
    SET_HUB = 'setHub',
    SET_HUB_MOBILE_DETAILS = 'setHubMobileDetails',
    SET_HUB_CONNECTION_HISTORY = 'setHubConnectionHistory',
    SET_HUB_DETAILS = 'setHubDetails',
}

export enum HubActions {
    LIST_HUBS = 'getListOfHubs',
    ENABLE_LIST_WIRELESS = 'enableWirelessList',
    DISABLE_LIST_WIRELESS = 'disableWirelessList',
    FETCH_HUB = 'fetchHub',
    FETCH_MOBILE_DETAILS = 'fetchMobileDetails',
    FETCH_CONNECTION_HISTORY = 'fetchConnectionHistory',
    UPDATE_HUB_DETAILS = 'updateHubDetails'
}

const hubs: Module<HubsModel, any> = {
    namespaced: true,
    state: {
        currentHub: null,
        hubList: null,
    },
    mutations: {
        [HubMutations.SET_HUB_LIST_DATA](state, hubList: SetHubListData) {
            // 2014-01-03T12:30:00
            hubList.data.data = hubList.data.data.map((hubData) => {
                return {
                    ...hubData,
                    lastMessageTimestamp: hubData.lastMessageTimestamp !== '' && hubData.lastMessageTimestamp != null ?
                        convertToMomentTimezone(hubList.loggedInUser.timezone as string, hubData.lastMessageTimestamp as string) : null,
                    lastDataRefresh: hubData.lastDataRefresh !== '' && hubData.lastDataRefresh != null ?
                        moment.tz(hubData.lastDataRefresh, 'YYYY-MM-DDTHH:mm:ss', 'UTC')
                            .tz(hubList.loggedInUser.timezone as string)
                            .format('DD MMM YYYY hh:mm A z')
                        : 'N/A',
                };
            });
            Vue.set(state, 'hubList', hubList.data);
        },
        [HubMutations.SET_WIRELESS_STATE](state, request: ToggleWirelessData) {
            if (state.hubList != null) {
                for (const hub of state.hubList.data) {
                    if (hub.id === request.hubId) {
                        hub.enabled = request.wirelessEnabled;
                        break;
                    }
                }
            }

            if (state.currentHub != null && state.currentHub.id === request.hubId) {
                state.currentHub.enabled = request.wirelessEnabled;
            }
        },
        [HubMutations.SET_HUB](state, hubData: SetHubData) {
            const timeZone = hubData.loggedInUser.timezone as string;
            hubData.hub.lastMessageTimestamp = hubData.hub.lastMessageTimestamp !== ''
                && hubData.hub.lastMessageTimestamp != null ?
               convertToTimezone(timeZone, hubData.hub.lastMessageTimestamp as string) : 'N/A';

            hubData.hub.lastDataRefresh = hubData.hub.lastDataRefresh !== ''
                && hubData.hub.lastDataRefresh != null ?
                convertToTimezone(timeZone, hubData.hub.lastDataRefresh) : 'N/A';
            state.currentHub = hubData.hub;
        },
        [HubMutations.SET_HUB_MOBILE_DETAILS](state, data: any) {
            const timeZone = data.loggedInUser.timezone as string;
            if (state.currentHub != null && data.data) {
                if (data.data.sessionStartTime != null) {
                    data.data.sessionStartTime = convertToTimezone(timeZone, data.data.sessionStartTime);
                }
                state.currentHub.hubMobileDetails = data.data;
            }
        },
        [HubMutations.SET_HUB_CONNECTION_HISTORY](state, data: any) {
            const timeZone = data.loggedInUser.timezone as string;
            for (const hubSession of data.data) {
                hubSession.disconnectTime = hubSession.disconnectTime != null && hubSession.disconnectTime !== '' ?
                    convertToTimezone(timeZone, hubSession.disconnectTime) : '--';
                hubSession.connectTime = hubSession.connectTime != null && hubSession.connectTime !== '' ?
                    convertToTimezone(timeZone, hubSession.connectTime) : '--';
            }

            if (state.currentHub != null) {
                state.currentHub.sessions = data.data;
            }
        },
        [HubMutations.SET_HUB_DETAILS](state, request: any) {
            const timeZone = request.loggedInUser.timezone as string;
            if (state.currentHub != null) {
                state.currentHub.eui64 = request.data.eui64;
                state.currentHub.xbeeRadioVersion = request.data.xbeeRadioVersion;
                state.currentHub.pythonApplicationVersion = request.data.pythonApplicationVersion;
                state.currentHub.imei = request.data.imei;
                state.currentHub.iccid = request.data.iccid;
                state.currentHub.lastDataRefresh = request.data.lastDataRefresh !== ''
                && request.data.lastDataRefresh != null ?
                    convertToTimezone(timeZone, request.data.lastDataRefresh) : 'N/A';
                state.currentHub.lastDataRefreshSource = request.data.lastDataRefreshSource;
            }
        },
    },
    actions: {
        async [HubActions.LIST_HUBS](context, request: HubListRequest) {
            const loggedInUser = context.rootState.auth.loggedInUser;
            const {data} = await axios.get(process.env.VUE_APP_BACKEND_URI + '/devices/hubs', {params: request});
            context.commit(HubMutations.SET_HUB_LIST_DATA, {data, loggedInUser});
        },
        async [HubActions.ENABLE_LIST_WIRELESS](context, hubId) {
            try {
                const {data} = await axios.post(process.env.VUE_APP_BACKEND_URI + '/devices/hubs/' + hubId + '/enable');
                context.commit(HubMutations.SET_WIRELESS_STATE, {hubId, wirelessEnabled: true});
            } catch (e) {
                console.log(e);
            }
        },
        async [HubActions.DISABLE_LIST_WIRELESS](context, hubId){
            try {
                const {data} = await axios.post(process.env.VUE_APP_BACKEND_URI + '/devices/hubs/' + hubId + '/disable');
                context.commit(HubMutations.SET_WIRELESS_STATE, {hubId, wirelessEnabled: false});
            } catch (e) {
                console.log(e);
            }
        },
        async [HubActions.FETCH_HUB](context, hubId){
            const loggedInUser = context.rootState.auth.loggedInUser;
            const {data} = await axios.get(process.env.VUE_APP_BACKEND_URI + '/devices/hubs/' + hubId );
            context.commit(HubMutations.SET_HUB, {hub: data, loggedInUser});
        },
        async [HubActions.FETCH_MOBILE_DETAILS](context, hubId) {
            const loggedInUser = context.rootState.auth.loggedInUser;
            const {data} = await axios.get(process.env.VUE_APP_BACKEND_URI + '/devices/hubs/' + hubId + '/mobile-details' );
            context.commit(HubMutations.SET_HUB_MOBILE_DETAILS, {data, loggedInUser});
        },
        async [HubActions.FETCH_CONNECTION_HISTORY](context, hubId) {
            const loggedInUser = context.rootState.auth.loggedInUser;
            const {data} = await axios.get(process.env.VUE_APP_BACKEND_URI + '/devices/hubs/' + hubId + '/connection-history' );
            context.commit(HubMutations.SET_HUB_CONNECTION_HISTORY, {data, loggedInUser});
        },
        async [HubActions.UPDATE_HUB_DETAILS](context, hubId) {
            const loggedInUser = context.rootState.auth.loggedInUser;
            const {data} = await axios.post(process.env.VUE_APP_BACKEND_URI + '/devices/hubs/' + hubId + '/update' );
            context.commit(HubMutations.SET_HUB_DETAILS, {data, loggedInUser});
        }
    },
};

export default hubs;
