





























































import {Component, Prop, Vue} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import {GoalWeight, Patient} from '@/models/patients/Patient';
import {ButtonType} from '@/components/form/FormTypes';
import {formatMomentDateOnly} from '@/ts/TimezoneUtils';
import moment, {Moment} from 'moment';
import {decimal, minValue, required} from 'vuelidate/lib/validators';
import {PatientMutations} from '@/vuex/patients';
import EditWeightHistoryModal from '@/components/patients/EditWeightHistoryModal.vue';
import {ModalMutations} from '@/vuex/modal';
import {PatientHomeRouteOptions} from '@/models/patients/PatientHome';
import {MeasurementUnit} from '@/models/User';
import {AuthGetters} from '@/vuex/auth';
import GoalWeightService from '@/services/patients/GoalWeightService';

const patients = namespace('patients');
const modal = namespace('modal');
const auth = namespace('auth');

/**
 * Renders a card allowing for the management of the patient's weight and goal weight
 */
@Component({
    components: {
      EditWeightHistoryModal,
    },
    validations: {
        inProgressGoalWeight: {
            required,
            minValue: minValue(0),
            decimal
        }
    }
})
export default class WeightManagementCard extends Vue {
    @patients.State(state => state.homePage.patient) patient!: Patient;
    @patients.Mutation(PatientMutations.ADD_GOAL_WEIGHT) addGoalWeight!: (data: GoalWeight) => void;
    @patients.Mutation(PatientMutations.UPDATE_GOAL_WEIGHT) updateGoalWeight!: (data: {goalWeight: GoalWeight, timezone: string}) => void;
    @modal.Mutation(ModalMutations.CHANGE_MODAL_STATE) changeModalState!: (state: boolean) => void;
    @auth.Getter(AuthGetters.MEASUREMENT_UNIT) measurementUnit!: string;
    buttonType = ButtonType;
    editingGoalWeight = false;
    showWeightModal = false;
    inProgressGoalWeight: number | null = 0;
    goalWeightService = new GoalWeightService(this.$store.state.auth.loggedInUser.timezone);

    @Prop({default: ''}) routeOption!: string;


    mounted() {
        if(this.goalWeight !== null){
            this.inProgressGoalWeight = this.goalWeight.goalWeight;
        }

        if(this.routeOption === PatientHomeRouteOptions.WEIGHT){
            this.editWeights();
        }else if(this.routeOption === PatientHomeRouteOptions.GOAL_WEIGHT) {
            this.editingGoalWeight = true;
            this.$nextTick(() => {
              if(this.$refs.hasOwnProperty('goalWeightInput')) {
                window.scrollTo((this.$refs.goalWeightInput as Element).getBoundingClientRect().x, (this.$refs.goalWeightInput as Element).getBoundingClientRect().y)
              }
            })
        }
    }

    /**
     * Navigates the browser to the weight history report
     */
    viewWeightReport() {
        this.$router.push({name: 'patient-weight-history-report'})
    }

    /**
     * Returns the latest patient weight if one exists
     */
    get latestWeight(){
        if(this.patient.weights.length === 0){
            return null;
        }

        return this.patient.weights[0];
    }

    /**
     * Formats the latest weight date if one exists
     */
    get latestWeightDate(){
        if(this.latestWeight === null){
            return null;
        }

        return formatMomentDateOnly(this.latestWeight.date as Moment);
    }

    /**
     * Returns the patient's goal weight if it exists
     */
    get goalWeight(){
        if(this.patient.goalWeight.length === 0) {
            return null;
        }

        return this.patient.goalWeight[0];
    }

    /**
     * Formats the patient's goal weight modification date if one exists
     */
    get goalWeightDate(){
        if(this.goalWeight === null){
            return null;
        }

        return formatMomentDateOnly(this.goalWeight.modifiedDate as Moment);
    }

    /**
     * Sends the request to update the patient's goal weight
     */
    async handleGoalWeightChange(){
        if(this.goalWeight === null) {
            const response = await this.goalWeightService.createGoalWeight(this.patient.id as number, {
                goalWeight: this.inProgressGoalWeight as number,
                startDate: moment.tz(this.$store.state.auth.loggedInUser.timezone).milliseconds(0)
            });
            if (response.isSuccess()) {
                response.map((goalWeight) => {
                    this.addGoalWeight(goalWeight);
                })
            } else {
                this.$addSnackbarMessage({
                    message: 'There was an issue updating the goal weight. Try again later or contact customer support.',
                });
            }
        } else {
            const goalWeightData = {
                ...this.goalWeight
            };
            goalWeightData.goalWeight = this.inProgressGoalWeight as number;
            const response = await this.goalWeightService.updateGoalWeight(this.patient.id as number, goalWeightData);
            if (response.isSuccess()) {
                response.map((gw) => {
                    this.updateGoalWeight({
                        goalWeight: goalWeightData,
                        timezone: this.$store.state.auth.loggedInUser.timezone
                    })
                })
            }
        }
        this.editingGoalWeight = false;
    }

    /**
     * Shows the edit weight modal
     */
    editWeights() {
        this.changeModalState(true);
        this.showWeightModal = true;
    }

    /**
     * Hides the edit weight modal
     */
    hideWeightModal() {
        this.changeModalState(false);
        this.showWeightModal = false;
    }


    /**
     * Shows the edit goal weight form
     */
    editGoalWeight() {
        this.editingGoalWeight = true;
        this.inProgressGoalWeight = null;
    }

    /**
     * Hides the edit goal weight form
     */
    cancelGoalWeightEdit() {
        this.editingGoalWeight = false;
        this.inProgressGoalWeight = null;
        this.$v.$reset()
    }
}
