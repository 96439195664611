
















































import {Component, Prop, Vue} from "vue-property-decorator";
import Table from "@/components/table/Table.vue";
import {ButtonType, CheckboxStateData} from "@/components/form/FormTypes";
import {
    PageData,
    PaginatedData,
    TableCellType,
    TableHeaderData,
    TableRowData
} from "@/components/table/cells/TableCellData";
import {FirmwareJobCount, FirmwareUpgradeJobStatus, MARRSPumpWithDetails} from "@/models/Devices/MARRS/MARRSPump";
import {FilterData, FilterType, SearchData} from "@/ts/Filter";
import {Searchable} from "@/models/Searchable";
import {formatMoment} from "@/ts/TimezoneUtils";
import {Moment} from "moment";
import TextDisplay from "@/components/utility/TextDisplay.vue";
import Checkbox from "@/components/form/Checkbox.vue";
import FirmwareFile from "@/models/Devices/MARRS/FirmwareFile";
import Multiselect from "vue-multiselect";
import ConnectFirmwareUpgradeService from '@/services/devices/connect/ConnectFirmwareUpgradeService';

/**
 * Renders a table containing info about pumps and their firmware update status
 */
@Component({
    components: {
        Table,
        Multiselect
    }
})
export default class PumpFirmwareDownloadTable extends Vue {
    /**
     * Current page of pumps
     */
    @Prop({default: () => {
            return {totalElements: 0, totalPages: 0, data: []}
        }}) pumpData!: PaginatedData<MARRSPumpWithDetails>;

    /**
     * Event bus for responding to events from the child table
     */
    @Prop() eventBus!: Vue;

    /**
     * Whether the table is currently sorted ascending
     */
    @Prop() sortedAsc!: boolean;

    /**
     * Property the table is currently sorted by
     */
    @Prop() currentSortKey!: string;

    /**
     * Currently applied filters
     */
    @Prop() filterData!: any;

    /**
     * Array of selected pump IDs
     */
    selectedPumps: number[] = [];

    /**
     * State of the select all checkbox
     */
    selectAllState = CheckboxStateData.UNCHECKED;

    /**
     * Current page size of the table
     */
    pageSize = 30;
    buttonType = ButtonType;

    /**
     * List of available firmware files
     */
    @Prop() firmwareFiles!: FirmwareFile[];

    /**
     * Current value of the serach field
     */
    @Prop() initialSearch!: string;

    /**
     * Whether the request to load the firmware versions is loading
     */
    @Prop() loadingVersions!: boolean;

    /**
     * Available firmware versions for filtering
     */
    @Prop() versions!: FilterData[];

    /**
     * Currently selected firmware file
     */
    selectedFirmwareFile: FirmwareFile | null = null;

    /**
     * Whether the a firmware request is pending
     */
    isLoading = false;
    connectFirmwareUpgradeService = new ConnectFirmwareUpgradeService(this.$store.state.auth.loggedInUser.timezone);
    firmwareJobCount: FirmwareJobCount | null = null;

    created(){
        this.getFirmwareJobCount();
    }

    mounted(){
        if(this.firmwareFiles.length > 0){
            this.selectedFirmwareFile = this.firmwareFiles[0];
        }
    }

    /**
     * Table header definition
     */
    get tableHeader(): TableHeaderData[]{
        const vm = this;
        return [
            {
                sortable: false,
                sortKey: 'serialNumber',
                value: 'Serial number',
                widthClass: 'w-1/4',
                selectAll: {
                    hasSelectAll: true,
                    state: vm.selectAllState,
                    handler: () => {
                        if(vm.selectAllState == CheckboxStateData.CHECKED){
                            vm.selectedPumps = [];
                            vm.selectAllState = CheckboxStateData.UNCHECKED;
                        }else{
                            vm.pumpData.data.forEach(pump => vm.selectedPumps.push(pump.pump.id));
                            vm.selectAllState = CheckboxStateData.CHECKED
                        }
                    }
                }
            },
            {
                sortable: true,
                sortKey: 'lastConnection',
                value: 'Last connection',
                widthClass: 'w-1/4',
                filterable: true,
                filterKey: 'connectionFilter',
                filters: [
                    {
                        type: FilterType.CALENDAR,
                        key: 'dateRange',
                        active: vm.filterData.connectionStart != null,
                        enabled: true,
                        displayText: 'Filter by date',
                        data: {
                            initialRange: {
                                start: vm.filterData.connectionStart,
                                end: vm.filterData.connectionEnd,
                            }
                        }
                    }
                ]
            },
            {
                sortable: false,
                sortKey: 'swVersion',
                value: 'SW / UI version',
                widthClass: 'w-1/4',
                filterable: true,
                filterKey: 'versionFilter',
                filterLoading: this.loadingVersions,
                filters: [
                    {
                        type: FilterType.MULTISELECT,
                        key: 'swVersion',
                        active: vm.filterData.swVersions.length != 0,
                        enabled: true,
                        displayText: 'Filter by SW version',
                        data: {
                            multiselect: true,
                            activeItems: {
                                swVersion: vm.filterData.swVersions.length != 0 ? [...vm.filterData.swVersions] : []
                            },
                            items: this.versions
                        }
                    },
                    {
                        type: FilterType.MULTISELECT,
                        key: 'uiVersion',
                        active: vm.filterData.uiVersions.length != 0,
                        enabled: true,
                        displayText: 'Filter by UI version',
                        data: {
                            multiselect: true,
                            activeItems: {
                                uiVersion: vm.filterData.uiVersions.length != 0 ? [...vm.filterData.uiVersions] : []
                            },
                            items:this.versions
                        }
                    },
                ]
            },
            {
                sortable: false,
                sortKey: 'fwResult',
                value: 'Download status',
                widthClass: 'w-1/4',
                filterable: true,
                filterKey: 'downloadStatusFilter',
                filters: [
                    {
                        type: FilterType.MULTISELECT,
                        key: 'step',
                        active: vm.filterData.fwSteps.length != 0,
                        enabled: true,
                        displayText: 'Filter by step',
                        data: {
                            multiselect: true,
                            activeItems: {
                                step: vm.filterData.fwSteps.length != 0 ? [...vm.filterData.fwSteps] : []
                            },
                            items: [
                                {
                                    label: "N/A",
                                    key: "N/A",
                                    value: 0
                                },
                                {
                                    label: "Step 1",
                                    key: "Step 1",
                                    value: 1
                                },
                                {
                                    label: "Step 2",
                                    key: "Step 2",
                                    value: 2
                                },
                                {
                                    label: "Step 3",
                                    key: "Step 3",
                                    value: 3
                                },
                            ]
                        }
                    },
                    {
                        type: FilterType.MULTISELECT,
                        key: 'status',
                        active: vm.filterData.fwStatus.length != 0,
                        enabled: true,
                        displayText: 'Filter by status',
                        data: {
                            multiselect: true,
                            activeItems: {
                                status: vm.filterData.fwStatus.length != 0 ? [...vm.filterData.fwStatus] : []
                            },
                            items: [
                                {
                                    label: "Download Failed",
                                    key: "Download Failed",
                                    value: FirmwareUpgradeJobStatus.ERROR
                                },
                                {
                                    label: "Update Complete",
                                    key: "Update Complete",
                                    value: FirmwareUpgradeJobStatus.COMPLETED
                                },
                            ]
                        }
                    },
                ]

            },
        ]
    }


    /**
     * Transforms the list of pumps into an array of table rows
     */
    get tableData(): PageData{
        const vm = this;
        const rows: TableRowData[] = this.pumpData.data.map(pumpData => {
            const cells = [
                {
                    type: TableCellType.SLOT_LIST,
                    primaryValue:"",
                    components: [
                        {
                            primaryValue: Checkbox,
                            componentOptions: {
                                label: '',
                                checked: vm.selectedPumps.includes(pumpData.pump.id)
                            },
                            events: {
                                change: () => {
                                    console.log('checkbox clicked');
                                    if(vm.selectedPumps.findIndex(pump => pump == pumpData.pump.id) != -1){
                                        vm.selectedPumps.splice(vm.selectedPumps.findIndex(pump => pump == pumpData.pump.id), 1)
                                    }else {
                                        vm.selectedPumps.push(pumpData.pump.id);
                                    }
                                    console.log(vm.selectedPumps);
                                    vm.updateSelectAllCheckbox()
                                }
                            }
                        },
                        {
                            primaryValue: TextDisplay,
                            componentOptions: {
                                value: pumpData.pump.serialNumber,
                                class: 'inline-block',
                                valueClickable: true,
                            },
                            events: {
                                valueClick: () => {
                                    vm.$router.push({name: 'enteral-pump-home', params: {id: pumpData.pump.id.toString()}})
                                }
                            }
                        }
                    ]
                },
                {
                    type: TableCellType.NORMAL,
                    primaryValue: formatMoment(pumpData.pump.lastConnectionTime as Moment) as string
                },
                {
                    type: TableCellType.NORMAL,
                    primaryValue: pumpData.pump.lastVersionUpdate != null ? (pumpData.pump.softwareVersion as number).toFixed(2) + "/" + (pumpData.pump.uiVersion as number).toFixed(2) : "N/A",
                },
                {
                    type: TableCellType.RAW,
                    primaryValue: vm.getDownloadStatus(pumpData)
                },
            ];

            return {
                cells: cells,
                highlighted: vm.selectedPumps.includes(pumpData.pump.id),
                indexKey: pumpData.pump.id
            }
        });

        return {
            numPages: this.pumpData.totalPages,
            totalElements: this.pumpData.totalElements,
            pageData: rows
        }
    }

    /**
     * Emits an event to tell the parent to fetch a page of data
     * @param page
     */
    fetchNewPage(page: number){
        this.$emit('changePage', page);
    }

    /**
     * Emits an event to tell the parent the sort information has changed
     * @param message
     */
    sortData(message: any){
        this.$emit('sort', message);
    }


    /**
     * Emits an event to tell the parent the table page size has changed
     * @param newPageSize
     */
    pageSizeChanged(newPageSize: number){
        this.$emit('pageSizeChanged', newPageSize);
        this.pageSize = newPageSize;
    }

    /**
     * Emits an event when the table is searched
     * @param searchString
     */
    async searchData(searchString: any){
        this.$emit('search', searchString);
    }

    /**
     * Available search fields
     */
    get searchTypes(): SearchData[] {
        return [
            {
                key: Searchable.SERIAL_NUMBER,
                value: 'Serial Number'
            }
        ]
    }

    /**
     * Returns HTML describing the current status of a pumps firmware update job
     * @param pump
     */
    getDownloadStatus(pump: MARRSPumpWithDetails){
        const job = pump.latestFirmwareJob;
        if(job.status == FirmwareUpgradeJobStatus.ERROR){
            return `Download Failed <span class="font-semibold underline">Retry</span>`
        }

        if(job.status == FirmwareUpgradeJobStatus.CANCELLED){
            return "Download Cancelled";
        }

        if(job.step == 1){
            return job.status == FirmwareUpgradeJobStatus.PENDING ? `<span class="font-semibold">Step 1</span>: Downloading ${job.swVersion.toFixed(2)} SW` : `<span class="font-semibold">Step 1</span>: Complete`;
        }else if(job.step == 2){
            return job.status == FirmwareUpgradeJobStatus.PENDING ? `<span class="font-semibold">Step 2</span>: Downloading ${job.uiVersion.toFixed(2)} UI` : `<span class="font-semibold">Step 2</span>: Complete`;
        }else if(job.step == 3){
            return job.status == FirmwareUpgradeJobStatus.PENDING ? `<span class="font-semibold">Step 3</span>: Software downloaded. Pending reboot.` : `Software update ${job.swVersion.toFixed(2)} complete.`;
        }

        return "N/A";
    }

    /**
     * Updates the state of the select all checkbox
     */
    updateSelectAllCheckbox(){
        if(this.selectedPumps.length == 0){
            this.selectAllState = CheckboxStateData.UNCHECKED;
        }else if(this.selectedPumps.length < this.pageSize){
            this.selectAllState = CheckboxStateData.INDETERMINATE;
        }else{
            this.selectAllState = CheckboxStateData.CHECKED;
        }
    }

    /**
     * Sends the request to being the update process on the selected pumps
     */
    async updatePumps(){
        if(this.selectedFirmwareFile == null){
            return;
        }
        this.isLoading = true;
        const response = await this.connectFirmwareUpgradeService.updatePumpFirmware(this.selectedPumps, this.selectedFirmwareFile)
        response.mapErr(() => {
            this.$addSnackbarMessage({
                message: 'There was an issue starting the firmware update. Please try again later or contact customer support.'
            })
        })
        this.isLoading = false;
    }

    /**
     * Starts step 2 of the process for pumps that are ready
     */
    async goToStepTwo(){
        await this.connectFirmwareUpgradeService.sendFirmwareJobsToStep2();
    }

    /**
     * Sends a retry request for pumps with failed jobs
     */
    async retryFailedJobs(){
        await this.connectFirmwareUpgradeService.retryFailedFirmwareJobs();
    }

    /**
     * Fetches the count of step 2 and failed pumps
     */
    async getFirmwareJobCount() {
        let result = await this.connectFirmwareUpgradeService.getFirmwareJobCount();
        if(result.isSuccess()){
            result.map(firmwareJobCount => {
                this.firmwareJobCount = firmwareJobCount;
            })
        }
    }
}
