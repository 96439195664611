import {Moment} from 'moment';
import {AlertRecord} from '@/models/Devices/MARRS/Records';
import {MonitorModeRecord, SystemError} from '@/models/MARRS/MonitorMode/MonitorModeData';

/**
 * Represents a pump error report
 */
export interface PumpErrorReportData {
    startDate: Moment | string;
    endDate: Moment | string;
    alertRecords: AlertRecord[];
}

export enum ErrorType {
    WARNING,
    ERROR,
    CRITICAL_ERROR,
    SYSTEM_ERROR,
}

export interface PumpErrorFilter {
    includedScreens: number[];
}

/**
 * Maps each error type to its descriptive name
 */
const ErrorTypeNameMap = {
    [ErrorType.WARNING]: 'Notice',
    [ErrorType.ERROR]: 'Caution',
    [ErrorType.CRITICAL_ERROR]: 'Warning',
    [ErrorType.SYSTEM_ERROR]: 'System Error',
} as {[index: number]: string};

/**
 * Maps each error type to its screen numbers
 */
const ErrorTypeScreenNumberMap = {
    [ErrorType.WARNING]: [100, 101],
    [ErrorType.ERROR]: [102, 103, 104, 105, 106, 109],
    [ErrorType.CRITICAL_ERROR]: [110],
    [ErrorType.SYSTEM_ERROR]: [111],
}as {[index: number]: number[]};

/**
 * Maps each error screen number to its type
 */
const ScreenNumberToErrorTypeMap = {
    100: ErrorType.WARNING,
    101: ErrorType.WARNING,
    102: ErrorType.ERROR,
    103: ErrorType.ERROR,
    104: ErrorType.ERROR,
    105: ErrorType.ERROR,
    106: ErrorType.ERROR,
    109: ErrorType.ERROR,
    110: ErrorType.CRITICAL_ERROR,
    111: ErrorType.SYSTEM_ERROR,
} as {[index: number]: ErrorType};

/**
 * Maps each error screen number to its description
 */
const ScreenNumberToErrorDescriptionMap = {
    100: 'Pump Inactive',
    101: 'Low Battery',
    102: 'Cassette Dislodged',
    103: 'Cassette Error',
    104: 'Rotor Stuck',
    105: 'Feed Bag Empty',
    106: 'Supply Tube Blocked',
    109: 'Patient Tube Blocked',
    110: 'Dead Battery Error',
} as {[index: number]: string};

/**
 * Maps each error screen number to an example monitor mode record
 * for display on the pump error report
 */
const ErrorToScreenDataMap = {
    100: {
        id: 1,
        timestamp: '',
        serialNumber: 'AS1600002617',
        screenNumber: 100,
        language: 1,
        screenData: {
            batteryPercentage: 100,
            acPower: true,
            setUseGT24Hours: false,
            enableLockedSettings: false,
            batteryChargingFlag: false,
            alarmSilenced: false,
        },
    },
    101: {
        id: 1,
        timestamp: '',
        serialNumber: 'AS1600002617',
        screenNumber: 101,
        language: 1,
        screenData: {
            batteryPercentage: 5,
            acPower: true,
            setUseGT24Hours: false,
            enableLockedSettings: false,
            batteryChargingFlag: false,
            alarmSilenced: false,
        },
    },
    102: {
        id: 1,
        timestamp: '',
        serialNumber: 'AS1600002617',
        screenNumber: 102,
        language: 1,
        screenData: {
            batteryPercentage: 100,
            acPower: true,
            setUseGT24Hours: false,
            enableLockedSettings: false,
            batteryChargingFlag: false,
            alarmSilenced: false,
        },
    },
    103: {
        id: 1,
        timestamp: '',
        serialNumber: 'AS1600002617',
        screenNumber: 103,
        language: 1,
        screenData: {
            batteryPercentage: 100,
            acPower: true,
            setUseGT24Hours: false,
            enableLockedSettings: false,
            batteryChargingFlag: false,
            alarmSilenced: false,
        },
    },
    104: {
        id: 1,
        timestamp: '',
        serialNumber: 'AS1600002617',
        screenNumber: 104,
        language: 1,
        screenData: {
            batteryPercentage: 100,
            acPower: true,
            setUseGT24Hours: false,
            enableLockedSettings: false,
            batteryChargingFlag: false,
            alarmSilenced: false,
        },
    },
    105: {
        id: 1,
        timestamp: '',
        serialNumber: 'AS1600002617',
        screenNumber: 105,
        language: 1,
        screenData: {
            batteryPercentage: 100,
            acPower: true,
            setUseGT24Hours: false,
            enableLockedSettings: false,
            batteryChargingFlag: false,
            alarmSilenced: false,
        },
    },
    106: {
        id: 1,
        timestamp: '',
        serialNumber: 'AS1600002617',
        screenNumber: 106,
        language: 1,
        screenData: {
            batteryPercentage: 100,
            acPower: true,
            setUseGT24Hours: false,
            enableLockedSettings: false,
            batteryChargingFlag: false,
            alarmSilenced: false,
        },
    },
    109: {
        id: 1,
        timestamp: '',
        serialNumber: 'AS1600002617',
        screenNumber: 109,
        language: 1,
        screenData: {
            batteryPercentage: 100,
            acPower: true,
            setUseGT24Hours: false,
            enableLockedSettings: false,
            batteryChargingFlag: false,
            alarmSilenced: false,
        },
    },
    110: {
        id: 1,
        timestamp: '',
        serialNumber: 'AS1600002617',
        screenNumber: 110,
        language: 1,
        screenData: {
            batteryPercentage: 1,
            acPower: true,
            setUseGT24Hours: false,
            enableLockedSettings: false,
            batteryChargingFlag: false,
            alarmSilenced: false,
        },
    },
    111: {
        id: 1,
        timestamp: '',
        serialNumber: 'AS1600002617',
        screenNumber: 111,
        language: 1,
        screenData: {
            batteryPercentage: 5,
            acPower: true,
            setUseGT24Hours: false,
            enableLockedSettings: false,
            batteryChargingFlag: false,
            alarmSilenced: false,
            systemErrorCode: 73,
            alertData: 0,
        },
    },
} as {[index: number]: MonitorModeRecord};

/**
 * Returns the screen data based on the screen number
 * For system errors, the system error number will be set
 * @param record
 */
export function getErrorRecord(record: AlertRecord) {
    if (record.screen !== 111) {
        return ErrorToScreenDataMap[record.screen];
    }

    const data = ErrorToScreenDataMap[record.screen];
    (data.screenData as SystemError).systemErrorCode = record.systemErrorCode;
    return data;
}

/**
 * Gets the description for a specific screen number
 * For System errors, the error code will be appended
 * @param record
 */
export function getErrorDescription(record: AlertRecord) {
    if (record.screen !== 111) {
        return ScreenNumberToErrorDescriptionMap[record.screen];
    }

    return `System Error - #${record.systemErrorCode}`;
}

export {ErrorTypeNameMap, ErrorTypeScreenNumberMap, ScreenNumberToErrorTypeMap};
