export default function paginate(currentPage: number, pageCount: number) {
    const delta = 1;

    if (pageCount === 1) {
        return [1];
    }

    const range = [];
    for (let i = Math.max(2, currentPage - delta); i  <= Math.min(pageCount - 1, currentPage + delta); i++) {
        range.push(i);
    }

    if ((currentPage - delta > 2)) {
        range.unshift('...');
    }

    if ((currentPage + delta + 1) < (pageCount)) {
        const lastPushedNum = range[range.length - 1] as number;
        if ((lastPushedNum + delta + 1) < pageCount) {
            range.push('...');
            range.push(pageCount - 1);
        } else if ((lastPushedNum + delta) < pageCount) {
            range.push(pageCount - 1);
        } else {
            range.push('...');
        }
    }

    range.unshift(1);
    range.push(pageCount);

    return range;
}
