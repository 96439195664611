




















import {Component, Prop, Vue} from "vue-property-decorator";
import {ButtonType} from "@/components/form/FormTypes";
import {PatientDeviceAssignment} from "@/models/patients/Patient";
import {namespace} from 'vuex-class';
import {CustomerDeviceAssociation} from "@/models/customers/CustomerDeviceAssociation";
import ServiceToolsService from '@/services/ServiceToolsService';
import {MarrsPumpMutations} from '@/vuex/marrs';
import {ModalMutations} from '@/vuex/modal';
import Modal from '@/components/modal/Modal.vue';
import ConfirmationModal from '@/components/modal/ConfirmationModal.vue';
import {MARRSPump} from '@/models/Devices/MARRS/MARRSPump';

const marrs = namespace('marrsPump');
const modal = namespace('modal');

/**
 * Renders a card for performing service actions on a device
 */
@Component({
    components: {
        ConfirmationModal,
    }
})
export default class ServiceToolsCard extends Vue {

    buttonType = ButtonType;
    @marrs.State(state => state.homePage.pump) pump!: MARRSPump;
    @marrs.State(state => state.homePage.deviceAssignments) deviceAssignments!: PatientDeviceAssignment[];
    @marrs.State(state => state.homePage.customerClaims) customerClaims!: CustomerDeviceAssociation[];
    @marrs.Mutation(MarrsPumpMutations.END_ASSIGNMENT) endAssignment!: (data: {assignmentId: number, timezone: string}) => void;
    @marrs.Mutation(MarrsPumpMutations.RETURN_TO_STOCK) endPumpClaim!: (timezone: string) => void;
    @modal.Mutation(ModalMutations.CHANGE_MODAL_STATE) changeModalState!: (state: boolean) => void;
    serviceToolsService = new ServiceToolsService(this.$store.state.auth.loggedInUser.timezone);

    /**
     * Whether the modal is showing
     */
    modalShowing = false;

    /**
     * Current title of the modal
     */
    modalTitle = '';

    /**
     * Whether a patient is assigned to this device
     */
    get isPatientAssigned(){
        return this.deviceAssignments.find(assignment => assignment.endDate === null) !== undefined;
    }

    /**
     * Whether the device is claimed by a customer
     */
    get isClaimedByCustomer(){
        return this.customerClaims.find(claim => claim.endDate === null) !== undefined;
    }

    /**
     * Shows the conformation modal
     * @param title
     */
    showModal(title: string) {
        this.modalShowing = true;
        this.changeModalState(true);
        this.modalTitle = title;
    }

    /**
     * Hides the confirmation modal
     */
    hideModal() {
        this.changeModalState(false);
        this.modalShowing = false;
    }

    /**
     * Performs the modal action based on modal type
     */
    handleAction() {
        if(this.modalTitle === 'stock') {
            this.returnToStock();
        } else {
            this.returnToCustomer();
        }
    }

    /**
     * Sends the request to return the device to customer and
     * end all assignments
     */
    async returnToCustomer() {
        const assignment = this.deviceAssignments.find(assignment => assignment.endDate === null);
        if(assignment === undefined) {
            return;
        }
        const response = await this.serviceToolsService.returnSinglePumpToCustomer(Number.parseInt(this.$route.params.id));
        if(response.isSuccess()) {
            this.endAssignment({
                assignmentId: assignment.id,
                timezone: this.$store.state.auth.loggedInUser?.timezone
            });
            this.$addSnackbarMessage({
                message: 'Pump assignments successfully ended'
            });
        }
        this.hideModal();
    }

    /**
     * Sends the request to return the pump to stock
     */
    async returnToStock() {
        const response = await this.serviceToolsService.returnSinglePumpToStock(Number.parseInt(this.$route.params.id));
        if(response.isSuccess()) {
            this.endPumpClaim(this.$store.state.auth.loggedInUser.timezone);
            this.$addSnackbarMessage({
                message: 'Pump successfully returned to stock'
            });
        }
        this.hideModal();
    }

    /**
     * Returns the modal body content based on modal type
     */
    get serviceToolModalMessage() {
        if (this.modalTitle === 'stock') {
            return 'This will remove the pump from its customer and end the current patient assignment, if there is one.'
        } else {
            return "This will end the current patient assignment."
        }
    }
}
