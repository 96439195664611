






























import { Component, Vue } from 'vue-property-decorator';
import Spinner from '../../../form/Spinner.vue';
import {ButtonType} from '../../../form/FormTypes';
import {namespace} from "vuex-class";
import {CustomerDeviceAssociation} from "@/models/customers/CustomerDeviceAssociation";
import moment, {Moment} from 'moment';
import {PumpSettingsReportData} from '@/models/reports/PumpSettings';
import {formatMomentDateOnly} from "@/ts/TimezoneUtils";
import ConnectSettingsReportService from '@/services/devices/connect/reports/ConnectSettingsReportService';

const marrs = namespace('marrsPump');

/**
 * Renders a pump settings quick component for the pump home page
 */
@Component({
    components: {
        Spinner
    }
})
export default class PumpSettingsQuickLink extends Vue {
    /**
     * Whether the reuqest is loading
     */
    loading = false;
    buttonType = ButtonType;

    /**
     * Start date of the request
     */
    startDate: Moment | null = null

    /**
     * End date of the request
     */
    endDate: Moment | null = null;

    @marrs.State(state => state.homePage.customerClaims) customerClaims!: CustomerDeviceAssociation[];

    /**
     * Pump settings data for the pump
     */
    pumpSettingsData: PumpSettingsReportData | null = null;
    connectSettingsReportService: ConnectSettingsReportService | null = null;

    /**
     * Sets the start/end date of the request and fetches the data on component create
     */
    async created(){
        const timezone = this.$store.state.auth.loggedInUser.timezone;
        this.startDate = this.customerClaims.length > 0 && this.customerClaims[0].endDate == null ? this.customerClaims[0].id.startDate as Moment : null;
        this.endDate = moment.tz(timezone).set('hour', 23).set('minute', 59).set('second', 59);
        this.connectSettingsReportService = new ConnectSettingsReportService(timezone);
        if(this.startDate !== null && this.endDate !== null){
            await this.fetchData();
        }
    }

    /**
     * Sends the request to fetch the pump settings data
     */
    async fetchData(){
        if(this.connectSettingsReportService === null || !this.$isAllowed(this.$Permission.PUMP_SETTINGS_REPORT)){
            return;
        }

        this.loading = true;
        const response = await this.connectSettingsReportService.fetchSettingsReportForPump(this.startDate as Moment, this.endDate as Moment, Number.parseInt(this.$route.params.id));
        response.map((pumpSettingsData) => {
            this.pumpSettingsData = pumpSettingsData
        })

        this.loading = false;
    }

    /**
     * Gets the formatted timestamp of the latest settings change if there is one
     */
    get latestPumpSettingsChange(){
        if(this.pumpSettingsData !== null && this.pumpSettingsData.pumpSettingsRecords.length > 0){
            return formatMomentDateOnly(this.pumpSettingsData.pumpSettingsRecords[0].timestamp as Moment);
        }

        return "N/A";
    }

    /**
     * Navigates to the pump settings report
     */
    navigateToPumpSettings(){
        this.$router.push({name: 'marrs-pump-settings-report', query: {reportRange: ['0']}});
    }
}
