































































import {Component, Vue} from 'vue-property-decorator';
import {ButtonType} from '@/components/form/FormTypes';
import User, {MeasurementUnit} from '@/models/User';
import {AssignableToTeams, Role} from '@/ts/permissions/Role';
import {namespace} from 'vuex-class';
import axios from 'axios';
import GeneralUserInfo from '@/components/users/GeneralUserInfo.vue';
import UserPreferences from '@/components/users/UserPreferences.vue';
import UserPermissions from '@/components/users/UserPermissions.vue';
import TeamSelector from '@/components/users/TeamSelector.vue';
import {Team} from '@/models/Teams';
import {Customer} from '@/models/customers/Customer';
import {Attribute} from '@/ts/permissions/Attribute';

const auth = namespace('auth');


@Component({
    components: {
        GeneralUserInfo,
        UserPreferences,
        UserPermissions,
        TeamSelector
    }
})
export default class UserCreate extends Vue {
    buttonTypes = ButtonType;
    assignableToTeams = AssignableToTeams;

    user: User = {
        name: '',
        firstName: '',
        lastName: '',
        email: '',
        role: '',
        teams: [],
        username: '',
        measurementUnit: MeasurementUnit.METRIC,
        timezone: '',
        teamGroup: 0,
        customers: [],
        lastPasswordUpdate: '',
        lastTermsConditionsAcceptance: null
    };

    @auth.State('loggedInUser') loggedInUser!: User;


    selectedTimezone: any = null;
    selectedRole: any = null;
    teams: Team[] = [];
    systemTeam: Team | null = null;
    validity: any = {
        'general': false,
        'preferences': false,
        'permissions': false,
        'teams': true
    };

    customerList: Customer[] = [];
    selectedCustomer: Customer | null = null;

    disabledPermissionFields = {
        userRole: false,
        customer: false
    };

    async mounted(){
        if(this.loggedInUser.customers.length === 0){
            const {data} = await axios.get(process.env.VUE_APP_BACKEND_URI + '/customers');
            this.customerList = data;
        }else{
            this.selectedCustomer = this.loggedInUser.customers[0];
            this.customerList = [this.selectedCustomer];
            const systemTeam = this.selectedCustomer.teams?.find(team => team.type === 0);
            if(systemTeam != undefined){
                this.systemTeam = systemTeam;
            }
            this.disabledPermissionFields.customer = true;
        }
    }

    updateCustomer(data: Customer | null){
        this.selectedCustomer = data;
        const systemTeam = this.selectedCustomer?.teams?.find(team => team.type === 0);
        if(systemTeam != undefined){
            this.teams = this.selectedCustomer!.teams!.filter(team => team.type === 1);
            this.systemTeam = systemTeam;
        }
    }

    roleChanged(value: any){
        if(value == null){
            this.user.role = '';
            this.selectedRole = null;
        }else{
            this.user.role = value.id;
            this.selectedRole = value;
        }

    }

    timezoneChanged(value: any){
        if(value == null){
            this.user.timezone = '';
            this.selectedTimezone = null;
        }else{
            this.user.timezone = value.tzName;
            this.selectedTimezone = value;
        }

    }

    handleValidityChange(area: string, validity: boolean){
        this.validity[area] = !validity;
    }

    get isValid(){
        return this.validity.general
            && this.validity.preferences
            && this.validity.permissions;
    }

    async createUser(){
        const requestData = {
            user: {...this.user} as any
        } as any;
        if(this.user.role === Role.ADMIN && this.systemTeam != null){
            requestData.teams = [this.systemTeam.id]
        }else if(!this.assignableToTeams.includes(this.user.role)){
            requestData.teams = null
        } else if(requestData.user.teamGroup === 0){
            requestData.teams = [];
        }else if(requestData.user.teamGroup === 1 && this.systemTeam != null){
            requestData.teams = [this.systemTeam.id]
        }else if(requestData.user.teamGroup === 2){
            requestData.teams = this.user.teams.map(team => team.id);
        }

        if(this.selectedCustomer != null){
            requestData.customerId = this.selectedCustomer.id
        }

        delete requestData.user.teams;
        delete requestData.user.teamGroup;
        delete requestData.user.customers;
        delete requestData.user.lastPasswordUpdate;
        try{
            const {status} = await axios.post(process.env.VUE_APP_BACKEND_URI + '/users', requestData);
            if(status < 300){
                this.$router.push({name: 'user-list'});
            }
            this.$addSnackbarMessage({
                message: 'User created successfully',
            });
        }catch(e){
            this.$addSnackbarMessage({
                message: 'There was an issue creating a new user. Try again later or contact customer support.',
            });
        }

    }
}
