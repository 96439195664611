import {AsyncNullSuccess, Either, Failure, nullSuccess, Success} from '@/errors/Either';
import {Error} from '@/errors/Error';
import axios from 'axios';
import {HubListRequest} from '@/models/Devices/Hubs/Hub';
import {saveDownloadedFile} from '@/ts/utilities';

export default class WirelessHubService {

    /**
     * Attempts to register a hub to the user's customer
     * @param serialNumber Serial number of the hub
     */
    public async registerDevice(serialNumber: string): AsyncNullSuccess<Error> {
        try {
            await axios.post(process.env.VUE_APP_BACKEND_URI + '/devices/hubs/registration', {
                serialNumber,
            });
            return nullSuccess();
        } catch (e) {
            return new Failure({
                message: 'Unable to register device',
                statusCode: e.response.status,
            });
        }
    }

    /**
     * Assigns a hub to a customer with non-customer bypass
     * @param customerId ID of the customer
     * @param serialNumber Serial number of the hub
     */
    public async registerDeviceWithBypass(customerId: number, serialNumber: string): AsyncNullSuccess<Error> {
        try {
            await axios.post(process.env.VUE_APP_BACKEND_URI + '/devices/hubs/registration/' + customerId + '/bypass', {
                serialNumber,
            });
            return nullSuccess();
        } catch (e) {
            return new Failure({
                message: 'Unable to register device',
            });
        }
    }

    /**
     * Exports the list of hubs to an excel document
     * @param request
     */
    public async exportList(request: HubListRequest): AsyncNullSuccess<Error> {
        try {
            const response = await axios.get(process.env.VUE_APP_BACKEND_URI + '/devices/hubs/export', {
                params: request,
                responseType: 'blob',
            });
            saveDownloadedFile(response, 'wireless-hub-export.xlsx');
            return nullSuccess();
        } catch (e) {
            return new Failure({
                message: 'Unable to export hubs',
            });
        }
    }
}
