









































import { Component, Vue } from 'vue-property-decorator';
import {namespace} from "vuex-class";
import {ButtonType} from "@/components/form/FormTypes";
import DeviceConnectionStatus from '@/components/devices/DeviceConnectionStatus.vue';

const marrs = namespace('marrsPump');

/**
 * Renders a quick link for the pump home page to jump to the monitor pump page
 */
@Component({
    components: {
        DeviceConnectionStatus
    }
})
export default class MonitorPumpQuickLink extends Vue {
    @marrs.State(state => state.homePage.connectionPending) connectionPending!: boolean;
    @marrs.State(state => state.homePage.connected) connected!: boolean;
    buttonType = ButtonType;
}
