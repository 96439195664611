














































































import {Component, Prop, Vue} from 'vue-property-decorator';
import moment, {Moment} from 'moment';
import {NullableOptional} from '@/ts/utilities';
import {FeedPrescription} from '@/models/patients/Patient';
import {decimal, minValue, required} from 'vuelidate/lib/validators';
import {ButtonType} from '@/components/form/FormTypes';
import NutritionalPrescriptionService from '@/services/patients/NutritionalPrescriptionService';

/**
 * Renders a form for creating/editing a prescription
 */
@Component({
    validations: {
        prescription: {
            name: {
                required,
            },
            startDate: {
                required,
            },
            dailyVolume: {
                required,
                decimal,
                minValue: minValue(0)
            },
            kcal: {
                decimal,
                minValue: minValue(0)
            }
        }
    }
})
export default class PrescriptionForm extends Vue {
    /**
     * Prescription ID for editing
     */
    @Prop({default: null}) id!: number | null;

    /**
     * Prescription name for editing
     */
    @Prop({default: ''}) name!: string;

    /**
     * Prescription daily volume for editing
     */
    @Prop({default: null}) dailyVolume!: number | null;

    /**
     * Prescription notes for editing
     */
    @Prop({default: ''}) notes!: string;

    /**
     * Prescription kcal's for editing
     */
    @Prop({default: null}) kcal!: number | null;

    /**
     * Prescription start date for editing
     */
    @Prop({default: null}) startDate!: Moment | null;

    /**
     * Start dates that cannot be selected
     */
    @Prop({default: () => []}) prohibitedDates!: Date[];

    /**
     * Whether a delete button should be shown
     */
    @Prop({default: false}) showDelete!: boolean;

    /**
     * ID of the current patient
     */
    @Prop() patientId!: number;

    /**
     * Prescription data copy
     */
    prescription: NullableOptional<FeedPrescription> = {
        id: null,
        name: '',
        dailyVolume: null,
        notes: '',
        kcal: null,
        startDate: null,
    };
    buttonType = ButtonType;

    nutritionalPrescriptionService = new NutritionalPrescriptionService(this.$store.state.auth.loggedInUser.timezone);
    confirmDelete = false;

    /**
     * Start of tomorrow
     */
    tomorrow = moment().tz(this.$store.state.auth.loggedInUser.timezone)
        .add(1, 'day')
        .startOf('day')
        .toDate();

    mounted(): void {
        if(this.id !== null){
            this.prescription.id = this.id;
        }
        if(this.dailyVolume !== null){
            this.prescription.dailyVolume = this.dailyVolume;
        }
        if(this.kcal !== null){
            this.prescription.kcal = this.kcal;
        }
        if(this.startDate !== null) {
            this.prescription.startDate = this.startDate;
        }

        this.prescription.name = this.name;
        this.prescription.notes = this.notes;
    }

    /**
     * Called when the value of a field changes. Updates necessary validations
     * @param field Field that changed
     * @param data New data
     */
    updateField(field: string, data: any) {
        if(this.prescription.hasOwnProperty(field)) {
            (this.prescription as any)[field] = data
        }

        if(this.$v.prescription !== undefined && this.$v.prescription.hasOwnProperty(field)) {
            (this.$v.prescription[field] as any).$touch()
        }
    }

    /**
     * Called when the start date changes
     * @param date New start date
     */
    handleDateUpdate(date: Date) {
        this.prescription.startDate = moment.tz(date, this.$store.state.auth.loggedInUser.timezone);
    }

    /**
     * Resets the form on cancel.
     * Emits an event on cancel
     */
    handleCancel() {
       this.prescription.id = this.id;
       this.prescription.dailyVolume = this.dailyVolume;
       this.prescription.kcal = this.kcal;
       this.prescription.startDate = this.startDate;
       this.prescription.name = this.name;
       this.prescription.notes = this.notes;
       this.$emit('cancel')
    }

    /**
     * Sends the request to create a new prescription
     * Emits an event on prescription creation
     */
    public async saveNewPrescription(){
        const response = await this.nutritionalPrescriptionService.createNewPrescription(this.patientId, this.prescription);
        if(response.isSuccess()){
            response.map((prescription) => {
                this.$emit('newPrescription', prescription)
            });
        } else {
            this.$addSnackbarMessage({
                message: 'There was an issue creating a new prescription. Try again later or contact customer support.',
            });
        }
        this.handleCancel();
        this.$v.$reset();
    }

    /**
     * Sends the request to update a prescription
     * Emits an event on prescription update
     */
    public async updatePrescription() {
        const response = await this.nutritionalPrescriptionService.updatePrescription(this.patientId, this.prescription);
        if(response.isSuccess()){
            this.$emit('updatedPrescription', this.prescription)
        } else {
            this.$addSnackbarMessage({
                message: 'There was an issue updating the prescription. Try again later or contact customer support.',
            });
        }

    }

    /**
     * Sends the request to delete a prescription
     * Emits an event on prescription removal
     */
    public async deletePrescription() {
        if(this.prescription.id === null){
            return;
        }
        const response = await this.nutritionalPrescriptionService.deletePrescription(this.patientId, this.prescription.id);
        if(response.isSuccess()){
            this.$emit('removedPrescription', this.prescription.id);
        }
    }
}
